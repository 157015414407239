import { BaseClient } from '../clients/base';
import { BaseOAuth } from '../oauth/base';
import {
  ISearchResponse, IFile, IFileAny, IFileType, IFileStatus, IUploadParamsResponse, ICaptionResponse,
  IProgressStatusResponse, IProgressStatus, IFileImage, IFileModel3D, IFileStreamingVideo, IFileAudio,
  IFileTrackingImage, IJobOutputAnalysis, IJobOutputTraining, IProgressCallback, IFileTypeMap, IUploadResponse,
  IFileZPT,
  IFileImage360,
  IFileVideo360
} from './types/index';
import { sleep } from './utils';

const DEFAULT_THUMBNAILS = [
  { H: 256, W: 256, Quality: 75 },
  { H: 200, W: 385, Quality: 70 }
];

export class ZmlClient<T extends BaseOAuth> extends EventTarget {
  protected _bClient: BaseClient<T>;

  constructor (baseClient: BaseClient<T>) {
    super();
    this._bClient = baseClient;
  }

  async uploadFile(fl: Blob | File, flType: IFileType, progressCb: IProgressCallback, id?: number) {
    const uploadParams = await this.getUploadParamsForFiletype(flType, fl.type);
    // Create form data for upload
    const formData = new FormData()
    for (const [name, value] of Object.entries(uploadParams.fields)) {
      formData.append(name, value)
    }
    formData.append('file', fl) // this needs to be the last value to append

    await this._bClient.requestWithProgress(uploadParams.url, formData, progressCb, id); // fetch API has no progress event listener
    return {
      folder: uploadParams.folder,
      key: uploadParams.fields.key,
      filename: (fl as File).name || ''
    }
  }

  async getAllFiles() {
    return await this._bClient.apiRequest<ISearchResponse<IFileAny[]>>('/zml/');
  }

  async getFileById(id: string) {
    return await this._bClient.apiRequest<IFile>(`/zml/${id}/`);
  }

  async search<T extends IFileType>(fileTypes: T[], page = 1, query?: string, limit?: number, sort?: string, status?: IFileStatus, isPublic?: boolean) {
    if (typeof page === 'undefined' || (typeof page === 'number' && page < 1)) {
      page = 1;
    }

    const qs = new URLSearchParams({
      page: page.toString(),
      q: query || '',
      sort: sort || '',
      status: status || '',
      limit: limit?.toString() || '',
      public: isPublic?.toString() || '',
    });
    for (const ft of fileTypes) {
      qs.append('type', ft);
    }

    return this._bClient.apiRequest<ISearchResponse<IFileTypeMap[T][]>>(`/zml/?${qs}`);
  }

  async getTotalMediaCount(fileType: IFileType): Promise<number> {
    const res = await this.search([fileType]);
    return res.count;
  }

  async getStatus(progressUrl: string) {
    const resp = await fetch(this.prependHttps(progressUrl));
    if (resp.ok) return resp;
    throw new Error(resp.statusText)
  }

  setCaption(id: string, caption: string) {
    return this._bClient.apiRequest<ICaptionResponse>(`/zml/${id}/`, {'caption': caption || false}, "PATCH")
  }

  getUploadParamsForFiletype(fileType: IFileType, contentType?: string) {
    let url = `/upload-params/${fileType}/`;
    if (typeof contentType === 'string') {
      url = `${url}?ct=${contentType}`
    }
    return this._bClient.apiRequest<IUploadParamsResponse>(url)
  }

  prependHttps(str: string) {
		return str.startsWith('//') ? `https:${str}` : str;
	}

  async getJobResult(fileInfo: IFile, index: number, progressCb: IProgressCallback, retries=0): Promise<null | IProgressStatusResponse> {
    const resp = await fetch(this.prependHttps(fileInfo.statusURL));
    let progress = Math.min(retries, 80); // fake min progress on each retry

    if (!resp.ok && resp.status !== 403) {
      progressCb(progress, { status: IProgressStatus.error, errorCode: resp.status, fileInfo, index })
      return null;
    } else if (resp.status === 403) {
      if (retries > 200) { // 1 minute
        progressCb(progress, { status: IProgressStatus.error, errorCode: 408, fileInfo, index, desc: 'Timeout waiting for job to start' })
        return null;
      }
      progressCb(progress, { status: IProgressStatus.processing, fileInfo, index })
      await sleep(300)
      return this.getJobResult(fileInfo, index, progressCb, retries+1)
    }

    const result = await resp.json() as IProgressStatusResponse;
    if ('Status' in result) { // v1
      result.status = result.Status
      result.progress = progress = Math.min(Math.max(progress, result.PercentComplete), 100)
      result.output = result.Output
      result.error = {
        code: result.Description,
        message: result.DetailedDescription
      }
    } else {
      progress = Math.min(Math.max(progress, result.progress || 0), 100)
    }

    if (result.status === IFileStatus.Error) {
      progressCb(progress, { status: IProgressStatus.error, fileInfo, index, errorCode: 500, desc: result.error?.message })
      return null;
    } else if (progress < 100) {
      progressCb(progress, { status: IProgressStatus.processing, fileInfo, index })
      await sleep(300)
      return this.getJobResult(fileInfo, index, progressCb, retries)
    }
    return result;
  }

  async getJobOutput(fileInfo: IFile, index: number, progressCb: IProgressCallback) {
    const result = await this.getJobResult(fileInfo, index, progressCb);
    if (!result) return null;

    fileInfo.output = result.output || {};

    progressCb(100, { status: IProgressStatus.completed, fileInfo, index})
    return fileInfo.output;
  }

  async uploadFilesOfType<T extends IFile>(fileInput: FileList | File[], fileType: IFileType, progressCb: IProgressCallback): Promise<{files: T[], uploadDataByFileId: {[id: string]: IUploadResponse }}> {
    const files: T[] = [];
    const uploadDataByFileId: {[id: string]: IUploadResponse } = {};
    for (let i = 0; i < fileInput.length; i++) {
      const fileName = fileInput[i].name;
      const uploadData = await this.uploadFile(fileInput[i], fileType, progressCb, i)

      const fileInfo = await this._bClient.apiRequest<T>('/zml/', {
        folder: uploadData.folder,
        filename: fileName,
        ftype: fileType,
        thumbnails: JSON.stringify(DEFAULT_THUMBNAILS), // TODO: get these as args
        title: fileInput[i].name
      })

      this.getJobOutput(fileInfo, i, progressCb)
      uploadDataByFileId[fileInfo.id] = uploadData;
      files.push(fileInfo)
    }
    return { files, uploadDataByFileId }
  }

  async uploadImages(files: FileList | File[], progressCb: IProgressCallback): Promise<IFileImage[]> {
    const d = await this.uploadFilesOfType<IFileImage>(files, IFileType.Image, progressCb);
    return d.files;
  }

  async uploadTempImages(files: FileList | File[], progressCb: IProgressCallback): Promise<IFileImage[]> {
    const d = await this.uploadFilesOfType<IFileImage>(files, IFileType.Image, progressCb);
    return d.files;
  }

  async uploadImages360(files: FileList | File[], progressCb: IProgressCallback): Promise<IFileImage360[]> {
    const d = await this.uploadFilesOfType<IFileImage360>(files, IFileType.Image360, progressCb);
    return d.files;
  }

  async upload3dModels(files: FileList | File[], progressCb: IProgressCallback): Promise<IFileModel3D[]> {
    const d = await this.uploadFilesOfType<IFileModel3D>(files, IFileType.Model3D, progressCb);
    return d.files;
  }

  async uploadVideos(files: FileList | File[], progressCb: IProgressCallback): Promise<IFileStreamingVideo[]> {
    const d = await this.uploadFilesOfType<IFileStreamingVideo>(files, IFileType.StreamingVideo, progressCb);
    return d.files;
  }

  async upload360Videos(files: FileList | File[], progressCb: IProgressCallback): Promise<IFileVideo360[]> {
    const d = await this.uploadFilesOfType<IFileVideo360>(files, IFileType.Video360, progressCb);
    return d.files;
  }

  async uploadAudio(files: FileList | File[], progressCb: IProgressCallback): Promise<IFileAudio[]> {
    const d = await this.uploadFilesOfType<IFileAudio>(files, IFileType.Audio, progressCb);
    return d.files;
  }

  async deleteFile(fileId: string) {
    return this._bClient.apiRequest<void>(`/zml/${fileId}/`, {}, 'DELETE');
  }

  private async _analyseImage(fileId: string, uploadData: IUploadResponse, progressCb: IProgressCallback) {
    const { folder, filename } = uploadData;
    const fileInfo = await this._bClient.apiRequest<IFileTrackingImage>(`/zml/${fileId}/analysis/`, {
      folder,
      filename,
      thumbnails: JSON.stringify(DEFAULT_THUMBNAILS)
    });
    const output = await this.getJobOutput(fileInfo, 0, progressCb);
    if (!output) return null;
    return output as IJobOutputAnalysis
  }

  async uploadTrackingImage(file: File, progressCb: IProgressCallback) {
    const d = await this.uploadFilesOfType<IFileTrackingImage>([file], IFileType.TrackingImage, progressCb);
    const fileInfo = d.files[0];
    const uploadData = d.uploadDataByFileId[fileInfo.id];
    const analysisOutput = await this._analyseImage(fileInfo.id, uploadData, progressCb);
    return { file: fileInfo, analysisOutput }
  }

  async trainTrackingImage(fileId: string, progressCb: IProgressCallback) {
    const fileInfo = await this._bClient.apiRequest<IFileTrackingImage>(`/zml/${fileId}/train/`, {
      fileId: fileId
    })
    return this.getJobOutput(fileInfo, 0, progressCb) as Promise<IJobOutputTraining | null>
  }

  async uploadZPT(zpt: File, img: File, progressCb: IProgressCallback): Promise<IFileZPT[]> {
    const header = new Uint8Array(32)
    header.set(new TextEncoder().encode(`ZPT+IMG${zpt.size};${img.type}`))
    const files = new Uint8Array(header.byteLength + zpt.size + img.size)
    files.set(header, 0)
    const zptByteArray = new Uint8Array(await zpt.arrayBuffer())
    files.set(zptByteArray, header.byteLength)
    files.set(new Uint8Array(await img.arrayBuffer()), header.byteLength + zptByteArray.byteLength)
    const d = await this.uploadFilesOfType<IFileZPT>([new File([files], 'zpt')], IFileType.ZPT, progressCb);
    return d.files;
  }
}
