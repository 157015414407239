import * as Automerge from '@automerge/automerge';
import { WebSocketWrapper, WEBSOCKET_ACTIONS } from './websocket';

const MINIMUM_GAP_BETWEEN_UPDATES_MS = 30;

export class SyncDocClient<T> extends EventTarget {
	public static create<T>(socket: WebSocketWrapper): SyncDocClient<T> {
		return new SyncDocClient<T>(socket);
	}

	private _syncState = Automerge.initSyncState()
	private _doc = Automerge.init<T>();
	private _hasDoc: Promise<void>;
	private _hasDocResolve?: () => void | undefined;
	private _pendingIncomingMessages: Automerge.SyncMessage[] = [];
	private _updateTimeout: number | undefined;
	private _lastTimeoutTime = 0;

	private constructor(private _socket: WebSocketWrapper) {
		super();
		this._socket.addMessageListener<string>(WEBSOCKET_ACTIONS.AutomergeInit, this._onDocInit.bind(this));
		this._socket.addMessageListener<Array<number>>(WEBSOCKET_ACTIONS.AutomergeSync, this._onUpdateFromServer.bind(this));

		this._socket.addEventListener('close', this.disconnect.bind(this))
		this._socket.addEventListener('error', this.disconnect.bind(this))

		this._hasDoc = new Promise(resolve => {
			this._hasDocResolve = resolve;
		})
	}

	public waitForDoc(): Promise<void> {
		return this._hasDoc;
	}

	public disconnect() {
		this._socket.removeMessageListenersForAction(WEBSOCKET_ACTIONS.AutomergeInit);
		this._socket.removeMessageListenersForAction(WEBSOCKET_ACTIONS.AutomergeSync);
	}

	private _onDocInit(docStr: string) {
		const [ newSyncState, message ] = Automerge.generateSyncMessage(this._doc, this._syncState)
		this._socket.send(WEBSOCKET_ACTIONS.AutomergeSync, message)
    	this._syncState = newSyncState
	}

	private _onUpdateFromServer(msgArray: Array<number>) {
		const msg = Uint8Array.from(msgArray) as Automerge.SyncMessage
		if (typeof this._updateTimeout === 'undefined') {
			this._receiveMessage(msg)
		} else {
			this._pendingIncomingMessages.push(msg);
		}
		this.dispatchEvent(new Event('sync'));
	}

	private _receiveMessage(msg: Automerge.SyncMessage) {
		const [ newDoc, newSyncState ] = Automerge.receiveSyncMessage(this._doc, this._syncState, msg)
		this._doc = newDoc;
		this._syncState = newSyncState;
		if (this._hasDocResolve) {
			this._hasDocResolve();
			this._hasDocResolve = undefined;
		}
	}

	private _processPendingComms() {
		let msgs = this._pendingIncomingMessages;
		this._pendingIncomingMessages = [];
		for (let msg of msgs) {
			this._receiveMessage(msg)
		}
		const [ newSyncState, message ] = Automerge.generateSyncMessage(this._doc, this._syncState)
		this._socket.send(WEBSOCKET_ACTIONS.AutomergeSync, message)
    	this._syncState = newSyncState;
		this.dispatchEvent(new Event('sync'));
	}

	public getDoc(): Automerge.Doc<T> {
    	if (typeof this._doc === 'undefined') throw(`Undefined Automerge doc`)
		return this._doc;
	}

	public change(callback: Automerge.ChangeFn<T>): Automerge.Doc<T> {
		this._doc = Automerge.change(this._doc, (mut: Automerge.Extend<T>) => {
			callback(mut)
		})
		this._postUpdate();
    	if (typeof this._doc === 'undefined') throw(`Undefined Automerge doc`)
		return this._doc;
	}

	private _postUpdate() {
		if (typeof this._updateTimeout === 'number') return
		let currentTime = Date.now();
		let timeout = Math.max(MINIMUM_GAP_BETWEEN_UPDATES_MS - (currentTime - this._lastTimeoutTime), 1);
		this._updateTimeout = window.setTimeout(() => {
			if (typeof this._doc === 'undefined') throw(`Undefined Automerge doc`)
			this._lastTimeoutTime = Date.now();
			this._updateTimeout = undefined;
			this._processPendingComms();
		}, timeout);
	}
}
