export const getCursorByIndex = (index: number) => {
    switch (index) {
        case 0:
            return 'nwse-resize';
        case 1:
            return 'ns-resize';
        case 2:
            return 'nesw-resize';
        case 3:
            return 'ew-resize';
        case 4:
            return 'nwse-resize';
        case 5:
            return 'ns-resize';
        case 6:
            return 'nesw-resize';
        case 7:
            return 'ew-resize';
        default:
            return 'default';
    }
}

// TODO: fully implement in milestone 6
export const getCursorByIndexAndRotation = (index: number, rotation: number) => {
    if (rotation > 0 && rotation <= 28) {
        switch (index) {
            case 2: 
                return 'nesw-resize'
            case 3:
                return 'ew-resize'
            default:
                return 'default'
        }
        
    } else if (rotation > 28 && rotation <= 75) {
        switch (index) {
            case 2:
                return 'ns-resize'
            case 3:
                return 'nesw-resize'
            default:
                return 'default'
        }
    };
}
