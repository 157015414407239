import React, { FunctionComponent, useMemo, memo } from 'react';
import { SRGBColorSpace, TextureLoader } from 'three';
import { IImageReactProps, ITuple3, ITuple4 } from '../component-data-structure';
import { useTextureLoader } from '../hooks/useTextureLoader';
import { IUserData } from '../utils/general';
import CurvedEntity from './CurvedEntity/CurvedEntity';

const Image: FunctionComponent<IImageReactProps> = ({
	scale,
	imageUrl,
	opacity = 1,
	hasAlpha = false,
	position,
	rotation,
	onPointerUp,
	onPointerMove,
	onPointerDown,
	onDoubleClick,
	renderOrder,
	borderRadius: radius = 0,
	borderRgba: brgba = [255, 0, 0, 1],
	borderWidth: bw = 0,
	curvature,
	id = '',
	raycast,
	castShadow = false,
	receiveShadow = false,
	animationTransformGroupPrefix = ''
}) => {
	const texture = useTextureLoader(TextureLoader, imageUrl || '', true)
	if (texture) texture.colorSpace = SRGBColorSpace;
	const zeroPos = useMemo(() => [0, 0, 0] as ITuple3, []);
	const userData: IUserData = useMemo(() => ({ renderOrder, contentId: id }), [id, renderOrder]);

	return (
		<group
			key={'image_group'}
			userData={userData}
			raycast={raycast}
			name={`${animationTransformGroupPrefix}${id}`}
			position={position as ITuple3}
			rotation={rotation as ITuple3}
			onPointerUp={onPointerUp || undefined}
			onPointerDown={onPointerDown || undefined}
			onPointerMove={onPointerMove || undefined}
			onDoubleClick={onDoubleClick || undefined}
		>
			<group name={`${animationTransformGroupPrefix}inner_${id}`}>
				<CurvedEntity
					raycast={raycast}
					key={'img_bkgr'}
					cornerRadius={radius}
					opacity={opacity}
					position={zeroPos as ITuple3}
					renderOrder={renderOrder}
					border={{
						borderSize: bw,
						borderRgba: brgba as ITuple4,
					}}
					castShadow={castShadow}
					receiveShadow={receiveShadow}
					imageTexture={texture}
					scale={scale as ITuple3}
					curvature={curvature}
					hasAlpha={hasAlpha}
				/>
			</group>
		</group>
	);
};

Image.displayName = 'Image';
export default memo(Image);
