import React, { FunctionComponent, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Vector3 } from 'three';
import { IDesignerState } from '../../../../typings';
import { onSetEmitterIsStatic } from '../../../store/actions';
import { COLORS } from '../../../utils';
import SelectedEntityPositionHotspot from '../Hotspots/SelectedEntityPositionHotspot';
import { IEmitter, IEmitterCategory, IEmitterReactProps, ITuple3 } from '../r3f-components/component-data-structure';
import EmitterArea from '../r3f-components/components/emitters/r3f-wrapper/EmitterArea';
import EntityBoundaries from './EntityBoundaries/EntityBoundaries';
import { useGetHocSpatialProperties, useHocPointerDown, useHocPointerMove, useHocPointerUp } from './hocCustomHooks';
import { getEntityRenderOrder } from './hocUtils';

interface IEmitterWrapped {
	id: string;
	enabled?: boolean;
	isPreview?: boolean;
}

const withEmitterBehaviour = (WrappedEmitter: FunctionComponent<IEmitterReactProps & {category: IEmitterCategory}>): FunctionComponent<IEmitterWrapped> => {
	const FuncComp: FunctionComponent<IEmitterWrapped> = ({ enabled: entityIsEnabled = true, id, isPreview = false }) => {
		// Selectors
		const dispatch = useDispatch();
		const entity = useSelector((state: IDesignerState) => state.contentReducer.contentDoc.componentsById[id] as IEmitter);
		const transientEmitterAreaScale = useSelector((state: IDesignerState) => state.userReducer.emitterAreaScalesById[id]);
		const isStatic = useSelector((state: IDesignerState) => state.userReducer.emitterIsStaticById[id]);
      // Refs
		const pointerStageRef = useRef<'down' | 'move' | 'up' | null>(null);
		const initialPosRef = useRef<ITuple3 | null>(null); // Used to calculate pointer offset in pointerMove
		const previousPositionRef = useRef<ITuple3 | null>(null); // The previous position, used to compare with the current position
		const pointerUpHasRunRef = useRef<boolean | null>(null); // To prevent multiple dispatched actions when pointerUp runs twice this is reset onPointerMove
		
		const timeoutRef = useRef<NodeJS.Timeout>()
		const [mountEmitter, setMountEmitter] = useState(true);
        const [showEntityPositionHotspot, setShowEntityPositionHotspot] = useState(false);

		// Entity properties to pass to wrapped component
		const renderOrder = getEntityRenderOrder(entity); //TODO: check render order logic
		const { scale, position, rotation } = useGetHocSpatialProperties(entity);

		// Pointer events
		const onPointerDown = useHocPointerDown({ pointerStageRef, initialPosRef, entity, setShowEntityPositionHotspot });
		const onPointerMove = useHocPointerMove({ pointerStageRef, initialPosRef, pointerUpHasRunRef, previousPositionRef, entity });
		const onPointerUp = useHocPointerUp({ pointerStageRef, pointerUpHasRunRef, initialPosRef, entity, setShowEntityPositionHotspot });

		const staticPlayEmitter = useMemo(() => {
			const copiedEmitter = JSON.parse(JSON.stringify(entity.emitter));
			copiedEmitter.duration = null;
			return copiedEmitter;
		}, [entity])

		useEffect(() => {
			if (timeoutRef.current) clearTimeout(timeoutRef.current);
			setMountEmitter(false);
			dispatch(onSetEmitterIsStatic({[id]: false}));
			timeoutRef.current = setTimeout(() => {
				setMountEmitter(true)
			}, 
				transientEmitterAreaScale !== null && 
				typeof transientEmitterAreaScale !== 'undefined' ? 10000 : 100)
		}, [
			entity.emitterGroup,
			entity.emitter,
			transientEmitterAreaScale
		])

		return (
			<>
				<EntityBoundaries
					position={position}
					rotation={rotation}
					scale={scale}
					renderOrder={entity.renderOrder}
					onPointerDown={entityIsEnabled ? onPointerDown : undefined}
					onPointerUp={onPointerUp}
					label={`Emitter component ${id} render order ${99}`}
				>
					<group 	
						position={position}
						rotation={rotation}
					>
						{mountEmitter && <WrappedEmitter 
							id={id}
							position={[0, 0, 0]}
							rotation={[0, 0, 0]}
							scale={scale}
							isPlaying={true}
							isStatic={isStatic}
							category={entity.category}
							emitterGroup={entity.emitterGroup}
							emitter={staticPlayEmitter}
							renderOrder={renderOrder}
						/>}
						{!isPreview && <group scale={[scale[0]/2, scale[1]/2, scale[2]/2]}>
							<EmitterArea 
								id={id}
								renderOrder={renderOrder}
								color={COLORS.ocean} 
								scale={new Vector3(...(transientEmitterAreaScale || entity.emitter.position!.spread!)).multiplyScalar(2)} 
								position={new Vector3(0, 0, 0)}
								onPointerDown={onPointerDown}
								onPointerUp={onPointerUp}
							/>
						</group>}
					</group>
				</EntityBoundaries>
				{showEntityPositionHotspot && <SelectedEntityPositionHotspot onPointerMove={showEntityPositionHotspot ? onPointerMove : undefined} onPointerUp={entityIsEnabled ? onPointerUp : undefined} />}
			</>
		);
	};
	return FuncComp;
};

export default withEmitterBehaviour;
