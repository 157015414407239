export enum Environment {
  Local = "LOCAL",
  Dev = "DEVELOPMENT",
  Staging = "STAGING",
  Prod = "PRODUCTION"
}

export enum OAuthGrantType {
  AuthorizationCode = "AUTHORIZATION_CODE",
  Implicit = "IMPLICIT",
}

export interface Settings { // clientId and redirectURI should be required only if no accessToken
  clientId: string
  redirectURI?: string
  env?: Environment
  grantType?: OAuthGrantType
  debug?: boolean
}

export interface EnvSettings {
  url: string
  debug: boolean
}
