import {
  Settings, Environment, OAuthGrantType, EnvSettings, UserResponse, ProjectResponse, IProjectTemplateCategories,
  PROJECT_TEMPLATE_CATEGORIES, CreateProjectVersionResponse, ProjectTemplatesResponse, ProjectTool, ProjectFeatures, TriggerResponse, WorkspaceResponse, UARProjectVersion
} from "../specs"
import { getSettingsForEnv, prodEnvSettings } from "../settings"
import { BaseOAuth } from "../oauth/base"
import { ZmlClient } from '../zml/client'
import { IProgressCallback } from "../zml"

interface ErrorResponse {
	code: string;
	message: string;
	status: number;
}

interface APIResponse<T> {
  count: number;
  next: string | null;
  previous: string | null;
  results: T[];
}

export interface ListResponse<T> {
  count: number;
  next?: () => Promise<ListResponse<T>>;
  previous?: () => Promise<ListResponse<T>>;
  results: T[];
}

export class APIError extends Error {
	constructor(public status: number, public code: string, public message: string) {
		super(message);
	}
}

export interface ProjectFilter {
  q?: string;
  tool?: ProjectTool[];
}

async function parseErrorResponse(resp: Response): Promise<never> {
	const errorResponse = (await resp.json()) as Partial<ErrorResponse>;
	const { message = undefined, code = undefined } = errorResponse;
	const status = resp.status;
	if (!message || !code || !status) throw new Error(`${status} ${code} ${message}`);
	throw new APIError(status, code, message);
}

export class BaseClient<T extends BaseOAuth> {
  protected readonly _oauth: T
  protected _accessToken: string | null | undefined;

  public env: Environment = Environment.Prod;
  public grantType: OAuthGrantType = OAuthGrantType.AuthorizationCode;
  public debug: boolean = false;
  public envSettings: EnvSettings = prodEnvSettings;
  public zml: ZmlClient<T>;

  constructor(settings: Settings, oauth: T) {
    this._oauth = oauth
    this.config(settings)
    this._oauth.setServiceConfig(this.envSettings.url)
    this.zml = new ZmlClient(this)
  }

  config(settings: Settings) {
    this.env = typeof settings.env === "string" ? settings.env : this.env;
    this.grantType = typeof settings.grantType === "string" ? settings.grantType : this.grantType;
    this.envSettings = getSettingsForEnv(this.env);
    this.debug = typeof settings.debug === "boolean" ? settings.debug : this.envSettings.debug;
  }

  makeAuthorizationRequest(scope = '', state?: string) {
    this._oauth.makeAuthorizationRequest(scope, state);
  }

  checkForAuthorizationResponse() {
    return this._oauth.checkForAuthorizationResponse();
  }

  setAccessToken(t: string) {
    this._accessToken = t;
  }

  getAccessToken() {
    return this._accessToken ? this._accessToken : this._oauth.getAccessToken()
  }

  makeTokenRequest() {
    return this._oauth.makeTokenRequest()
  }

  requestWithProgress(url: string, body?: FormData, progressCb?: IProgressCallback, requestId: number | string = 0) {
    console.error('NOT IMPLEMENTED')
    return
  }

  protected async _authenticationSetup() {
    let token = this.getAccessToken()
    if (typeof token === "undefined") {
      this._oauth.makeAuthorizationRequest()
      token = await this._oauth.waitForAccessToken()
    }
    return {
      method: "GET",
      headers: {
        'content-type': 'application/json; charset=utf-8',
        authorization: `Bearer ${token}`
      },
      mode: "cors",
      credentials: "omit"
    } as RequestInit
  }

  cleanupStorage() {
    return this._oauth.cleanupStorage()
  }

  async getAPISVG (endpoint: string): Promise<Blob> {
    const options =  await this._authenticationSetup();
    const resp = await fetch(`${this.envSettings.url}${endpoint}`, options);
    if (resp.ok) return await <unknown>resp.blob() as Blob;
    throw await parseErrorResponse(resp);
  }

  async apiRequest<T>(endpoint: string, postData?: unknown, method?: string) {
    return await this.apiRequestWithoutHost<T>(`${this.envSettings.url}${endpoint}`, postData, method);
  }

  async apiRequestWithoutHost<T>(endpoint: string, postData?: unknown, method?: string) {
    let options = await this._authenticationSetup();
    if (typeof postData !== "undefined") {
      options.body = JSON.stringify(postData);
      options.method = method;
      if (typeof method === "undefined") {
        options.method = "POST";
      }
    }
    const req = await fetch(`${endpoint}`, options);
    let resp: any;
    if (req.status === 204) return resp as T; // no content (e.g. for DELETE requests)
    if (req.ok) return await req.json() as T;
    throw await parseErrorResponse(req);
  }

  getUser() {
    return this.apiRequest<UserResponse>('/user/')
  }

  async getWorkspacesAll(): Promise<WorkspaceResponse[]> {
    const result: WorkspaceResponse[] = [];

    let resp : ListResponse<WorkspaceResponse> | undefined = await this._wrapListRequest<WorkspaceResponse>('/workspaces/');
    do {
      result.push(...resp.results);
      resp = resp.next ? (await resp.next()) : undefined;
    } while(resp);
    
    return result;
  }

  getWorkspace(id: string): Promise<WorkspaceResponse> {
    return this.apiRequest<WorkspaceResponse>(`/workspaces/${id}/`)
  }

  createProject(ws: string, tool: ProjectTool) {
    return this.apiRequest<ProjectResponse>(`/workspaces/${ws}/projects/`, { tool });
  }

  getProject(id: string) {
    return this.apiRequest<ProjectResponse>(`/projects/${id}/`)
  }

  renameProject(projectId: string, title: string) {
    return this.apiRequest<ProjectResponse>(`/projects/${projectId}/`, { title }, 'PATCH')
  }

  preview<T>(projectId: string, data: unknown) {
    return this.apiRequest<T>(`/projects/${projectId}/preview/`, data)
  }

  publish<T>(projectId: string, data: unknown) {
    return this.apiRequest<T>(`/projects/${projectId}/publish/`, data)
  }

  getTriggerInfo(projectId: string) {
    return this.apiRequest<TriggerResponse[]>(`/projects/${projectId}/triggers/`)
  }

  createProjectVersion(projectId: string, data: {version: string, packagekey: string, enableCrossOriginIsolation?: boolean}) {
    return this.apiRequest<CreateProjectVersionResponse>(`/projects/${projectId}/uar-version/`, data)
  }

  getProjectVersions(projectId: string) {
    return this._wrapListRequest<UARProjectVersion>(`/projects/${projectId}/versions/`)
  }

  async getProjectVersionsAll(projectId: string): Promise<UARProjectVersion[]> {
    const result: UARProjectVersion[] = [];

    let resp : ListResponse<UARProjectVersion> | undefined = await this._wrapListRequest<UARProjectVersion>(`/projects/${projectId}/versions/`);
    do {
      result.push(...resp.results);
      resp = resp.next ? (await resp.next()) : undefined;
    } while(resp);
    
    return result;
  }

  getProjectTemplates(toolKey?: string, categories: IProjectTemplateCategories[] = PROJECT_TEMPLATE_CATEGORIES, page = 1, limit = 12) {
    if (typeof toolKey === 'undefined') {
      return this.apiRequest<ProjectTemplatesResponse>(`/project-templates/`)
    }
    const qs = new URLSearchParams({
      tool: toolKey,
      page: page.toString(),
      limit: limit.toString()
    })
    for (const cat of categories) {
      qs.append('cat', cat)
    }
    return this.apiRequest<ProjectTemplatesResponse>(`/project-templates/?${qs}`)
  }

  getDesignerTemplates(options: {categories?: IProjectTemplateCategories[], page?: number, limit?: number}) {
    return this.getProjectTemplates('designer-2', options.categories, options.page, options.limit)
  }

  getProjectFeatures(projectId: string) {
    return this.apiRequest<ProjectFeatures>(`/projects/${projectId}/features/`)
  }

  async postProjectScreenshot(projectId: string, image: Blob) {
    const form = new FormData();
    form.append('image', image);
    const options = {
      method: 'PUT',
      headers: {
        authorization: `Bearer ${this.getAccessToken()}`
      },
      body: form,
      keepalive: true
    }
    return await fetch(`${this.envSettings.url}/projects/${projectId}/screenshot/`, options);
  }

  async _wrapNextRequest<T>(url: string): Promise<ListResponse<T>> {
    const result = await this.apiRequestWithoutHost<APIResponse<T>>(url);
    return {
      ...result,
      next: result.next ? () => this._wrapNextRequest<T>(result.next!) : undefined,
      previous: result.previous ? () => this._wrapNextRequest<T>(result.previous!) : undefined,
    }
  }

  async _wrapListRequest<T>(url: string): Promise<ListResponse<T>> {
    const result = await this.apiRequest<APIResponse<T>>(url);
    return {
      ...result,
      next: result.next ? () => this._wrapNextRequest<T>(result.next!) : undefined,
      previous: result.previous ? () => this._wrapNextRequest<T>(result.previous!) : undefined,
    }
  }

  getProjects(workspaceId: string, filter?: ProjectFilter): Promise<ListResponse<ProjectResponse>> {
    const qs = new URLSearchParams();
    qs.set('ws', workspaceId);
    if (filter?.q) qs.set('q', filter.q);
    if (filter?.tool) {
      for (const tool of filter.tool) qs.append('tool', tool);
    }
    return this._wrapListRequest(`/projects/?${qs.toString()}`);
  }

}
