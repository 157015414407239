import React from 'react';
import { IIconParentProps } from './index';
import { COLORS } from '../../utils';

const appleStoreIcon: React.SFC<IIconParentProps> = ({
	className,
	hexFill: fill,
	viewBox,
	onClick = () => {},
}) => {
	const hexFill = fill || COLORS.midnight;
	return (
<svg
			onClick={onClick}
			className={className || ''}
			width="20px"
			height="20px"
			viewBox={viewBox ? viewBox.join(' ') : `0 0 20 20`}
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
		>
  <rect id="Rectangle_71" width="20" height="20" fill="none"/>
  <g id="Group" transform="translate(2)">
    <g id="Oval" transform="translate(4)" fill="none" stroke={hexFill} strokeMiterlimit="10" strokeWidth="1">
      <ellipse cx="4" cy="4.21" rx="4" ry="4.21" stroke="none"/>
      <ellipse cx="4" cy="4.21" rx="3.5" ry="3.71" fill="none"/>
    </g>
    <g id="Combined_Shape" transform="translate(0 9.318)" fill="none" strokeMiterlimit="10">
      <path d="M16,10.682H0C0,6.339,2.128,1.847,5.059,0A4.312,4.312,0,0,0,8,1.208a4.309,4.309,0,0,0,2.934-1.2C13.87,1.861,16,6.35,16,10.681Z" stroke="none"/>
      <path d="M 14.95956230163574 9.681577682495117 C 14.82810497283936 8.036874771118164 14.37964153289795 6.351327896118164 13.66427993774414 4.844088077545166 C 12.94917011260986 3.337392568588257 12.00687980651855 2.089041233062744 10.96768474578857 1.258272647857666 C 10.06919384002686 1.882013440132141 9.056702613830566 2.208047866821289 8 2.208047866821289 C 6.939587116241455 2.208047866821289 5.923896789550781 1.879846215248108 5.023072719573975 1.25203275680542 C 3.98702335357666 2.080355644226074 3.047377347946167 3.327047348022461 2.334009885787964 4.833257675170898 C 1.619147658348083 6.342633247375488 1.171190619468689 8.03180980682373 1.040189266204834 9.681577682495117 L 14.95956230163574 9.681577682495117 M 16 10.68157768249512 L 0 10.68157768249512 C 0 6.339277744293213 2.127830028533936 1.84746789932251 5.058529853820801 -2.112426727762795e-06 C 5.917319774627686 0.7903478741645813 6.9340500831604 1.208047866821289 8 1.208047866821289 C 9.06233024597168 1.208047866821289 10.07676982879639 0.7926678657531738 10.93365955352783 0.006837887689471245 C 13.86954021453857 1.86136794090271 15.99973964691162 6.350347995758057 16 10.68052768707275 L 16 10.68157768249512 Z" stroke="none" fill={hexFill}/>
    </g>
  </g>
</svg>
	);
};

export default appleStoreIcon;
