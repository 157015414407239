import { Size } from "@react-three/fiber";
import { OrthographicCamera } from "three";
import { IScreenAnchorPositionType, ITuple3 } from "../component-data-structure";

export const screenRelativeCameraNeedsUpdate = (
	camera: OrthographicCamera, 
	size: Size
): boolean => {
	const aspectRatio = size.width / size.height;
	return camera.left !== -aspectRatio  || 
		camera.right !== aspectRatio ||
		camera.top !== 1 ||
		camera.bottom !== -1 || 
		camera.near !== 0 ||
		camera.far !== 10 ||
		camera.zoom !== 1
}

export const updateScreenRelativeCameraProperties = (
	camera: OrthographicCamera, 
	size: Size
): void => {
	const aspectRatio = size.width / size.height;
	camera.left = - aspectRatio;
	camera.right = aspectRatio;
	camera.top = 1;
	camera.bottom = -1;
	camera.near = 0;
	camera.far = 10;
	camera.zoom = 1;
}

export const getAnchorGroupPrositionDict = (aspectRatio: number) => {
	let anchorPositionDict = {} as {[k in IScreenAnchorPositionType]: ITuple3}

	for (const key in IScreenAnchorPositionType) {
		let position: ITuple3 = [0, 0, 0];
		switch (key) {
			case IScreenAnchorPositionType.leftTop:
				position = [-aspectRatio, 1, 0];
				break;
			case IScreenAnchorPositionType.topMiddle:
				position = [0, 1, 0];
				break;
			case IScreenAnchorPositionType.rightTop:
				position = [aspectRatio, 1, 0];
				break;
			case IScreenAnchorPositionType.rightMiddle:
				position = [aspectRatio, 0, 0];
				break;
			case IScreenAnchorPositionType.rightBottom:
				position = [aspectRatio, -1, 0];
				break;
			case IScreenAnchorPositionType.bottomMiddle:
				position = [0, -1, 0];
				break;
			case IScreenAnchorPositionType.leftBottom:
				position = [-aspectRatio, -1, 0];
				break;
			case IScreenAnchorPositionType.leftMiddle:
				position = [-aspectRatio, 0, 0];
				break;
			default:
				position = [0, 0, 0]
				break;
		}
		anchorPositionDict[key as keyof typeof anchorPositionDict] = position		
	}
	return anchorPositionDict;
}