import React from 'react';
import { IIconParentProps } from './index';
import { COLORS } from '../../utils';

const upIcon: React.FunctionComponent<IIconParentProps> = ({
	className,
    hexFill: fill,
    secondHexFill: secondFill, 
	viewBox,
	onClick = () => {},
}) => {
    const hexFill = fill || COLORS.midnight;
    const secondHexFill = secondFill; 
	return (
		<svg
			onClick={onClick}
			className={className || ''}
			width="68px"
			height="70px"
			viewBox={viewBox ? viewBox.join(' ') : '0 0 68 70'}
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
		>
			<defs>
                <path d="M4.98688286,0.16531097 L62.9868829,0.0131515992 C65.7482971,0.00590720167 67.9927384,2.238603 67.9999828,5.00001725 C67.9999943,5.00438962 68,5.00876201 68,5.01313439 L68,63.1783937 C68,65.9398175 65.7614237,68.1783937 63,68.1783937 L5,68.1783937 C2.23857625,68.1783937 1.2263553e-15,65.9398175 0,63.1783937 L0,5.16529376 C3.21516397e-15,2.40899119 2.23058977,0.172541933 4.98688286,0.16531097 Z" id="path-1"></path>
                <linearGradient x1="50%" y1="-2.48949813e-15%" x2="50%" y2="100%" id="linearGradient-3">
                    <stop stopColor={secondHexFill} offset="0%"></stop>
                    <stop stopColor={secondHexFill} stopOpacity="0" offset="100%"></stop>
                </linearGradient>
            </defs>
            <g id="Components" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Illustration-/-Move-up" transform="translate(0.000000, 1.000000)">
                    <g id="Up-2" transform="translate(0.000000, -1.000000)">
                        <g id="Illustration" transform="translate(0.000000, 0.821606)">
                            <mask id="mask-2" fill="white">
                                <use href="#path-1"></use>
                            </mask>
                            <g id="Mask"></g>
                            <polygon id="Path-Copy-9" fill="url(#linearGradient-3)" fillRule="nonzero" mask="url(#mask-2)" points="16 49.4649587 30.0083493 34.1783937 49.9705882 57.4497055 49.9705882 68.6858564 39.9328401 85.4079602 16 71.3920676"></polygon>
                            <g mask="url(#mask-2)">
                                <g transform="translate(15.500000, 10.037669)">
                                    <polygon id="Path-Copy-3" fill={hexFill} fillRule="nonzero" points="0.5 39.4272899 14.5083493 24.1407249 34.4705882 47.4120367 17.4846337 61.717754 26.4702829 75.3702914 0.917384264 61.5289139 13.3002244 48.495065"></polygon>
                                    <path d="M27.7865902,9.98690995 L18.2538332,0.493367129 C17.9433833,0.177818984 17.5184624,0 17.0748703,0 C16.6312781,0 16.2063572,0.177818984 15.8959074,0.493367129 L6.36315031,9.98690995 C5.78580764,10.6483105 5.81706446,11.6408634 6.43488838,12.2649356 C7.05271231,12.8890078 8.04882151,12.9342109 8.72107609,12.3686822 L17.1433797,4.04982092 L25.5634031,12.4357743 C25.8738529,12.7513225 26.2987738,12.9291415 26.7423659,12.9291415 C27.1859581,12.9291415 27.610879,12.7513225 27.9213288,12.4357743 C28.2447179,12.1004261 28.4138235,11.6464403 28.3882798,11.1821848 C28.3627361,10.7179293 28.144835,10.2850621 27.7865902,9.98690995 Z" id="Path" fill={hexFill} fillRule="evenodd"></path>
                                    <g id="Group-3-Copy-2" strokeWidth="1" fill="none" fillRule="evenodd" transform="translate(18.000000, 53.140725) scale(-1, 1) rotate(-90.000000) translate(-18.000000, -53.140725) translate(2.000000, 39.640725)" strokeLinecap="round">
                                        <g id="Group" transform="translate(14.000000, 25.000000)">
                                            <line x1="0.5" y1="1" x2="17.5" y2="1" id="Line-9-Copy-3" stroke={COLORS.white}></line>
                                            <line x1="0.5" y1="0.5" x2="17.5" y2="0.5" id="Line-9-Copy-4" stroke={hexFill}></line>
                                        </g>
                                        <g id="Group-Copy" transform="translate(4.000000, 0.000000)">
                                            <line x1="0.5" y1="1" x2="17.5" y2="1" id="Line-9-Copy-3" stroke={COLORS.white}></line>
                                            <line x1="0.5" y1="0.5" x2="17.5" y2="0.5" id="Line-9-Copy-4" stroke={hexFill}></line>
                                        </g>
                                        <g id="Group-2" transform="translate(0.000000, 14.000000)">
                                            <line x1="0.5" y1="1" x2="17.5" y2="1" id="Line-9-Copy" stroke={COLORS.white}></line>
                                            <line x1="0.5" y1="0.5" x2="17.5" y2="0.5" id="Line-9-Copy-2" stroke={hexFill}></line>
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
		</svg>
	);
};

export default upIcon;
