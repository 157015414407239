import {
  IJobOutputAny, IJobOutput3DModel, IJobOutputAudio, IJobOutputImage, IJobOutputVideo,
  IJobOutputStreamingVideo, IJobOutputTraining, IJobProgress
} from "./response";

export enum IFileType {
  Image             = 'image',
  Video             = 'video',
  StreamingVideo    = 'streaming video',
  Audio             = 'sound',
  YouTubeThumbnail  = 'youtube thumbnail',
  VimeoThumbnail    = 'vimeo thumbnail',
  WebsiteScreenshot = 'website screenshot',
  TrackingImage     = 'tracking image',
  ZPP               = 'zpp',
  UARPackage        = 'uarzip',
  LookUpImage       = 'lookup image',
  Model3D           = '3d model',
  Image360          = '360 image',
  Video360          = '360 video',
  Temp              = 'temporary',
  ZPT               = 'zpt'
}

export enum IFileStatus {
  Pending = 'pending',
  Ready   = 'ready',
  Error   = 'error',
  Deleted = 'deleted',
  Success = 'success' // v2
}

export interface IFile {
  id: string
  url: string
  type: IFileType
  caption: string
  filename: string
  status: IFileStatus
  error: string
  date: string
  statusURL: string
  output: IJobOutputAny
  uploadedBy: string
  extension: string
	public: boolean
  allowDelete: boolean
}

export interface IFileImage extends IFile {
  type: IFileType.Image
  output: IJobOutputImage
}

export interface IFileImage360 extends IFile {
  type: IFileType.Image360
  output: IJobOutputImage
}

export interface IFileAudio extends IFile {
  type: IFileType.Audio
  output: IJobOutputAudio
}

export interface IFileTrackingImage extends IFile {
  type: IFileType.TrackingImage
  output: IJobOutputTraining
}

export interface IFileVideo extends IFile {
  type: IFileType.StreamingVideo
  output: IJobOutputVideo
}

export interface IFileVideo360 extends IFile {
  type: IFileType.Video360
  output: IJobOutputImage
}

export interface IFileStreamingVideo extends IFile {
  type: IFileType.StreamingVideo
  output: IJobOutputStreamingVideo
}

export interface IFileModel3D extends IFile {
  type: IFileType.Model3D
  output: IJobOutput3DModel
}

export interface IFileZPT extends IFile {
  type: IFileType.ZPT
  output: {
    zptUrl: string
    imageUrl: string
  }
}

export interface IThumbnail {
  W: number
  H: number
  Quality: number // 1 to 100
  FitMode?: "fit"
}

export interface IProgressCallback {
  (percentage: number, info: IJobProgress): void
}

export type IFileAny = IFileImage | IFileImage360 | IFileTrackingImage | IFileVideo | IFileVideo360 | IFileStreamingVideo | IFileModel3D | IFileAudio;

type IFileTypeMapSpec = {
  [key in IFileType] : IFileAny
}

export interface IFileTypeMap extends IFileTypeMapSpec {
  [IFileType.Image]          : IFileImage
  [IFileType.Image360]       : IFileImage360
  [IFileType.TrackingImage]  : IFileTrackingImage
  [IFileType.Video]          : IFileVideo
  [IFileType.Video360]       : IFileVideo360
  [IFileType.StreamingVideo] : IFileStreamingVideo
  [IFileType.Model3D]        : IFileModel3D
  [IFileType.Audio]          : IFileAudio
}
