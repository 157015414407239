import React from "react";
import { IIconParentProps } from "./index";
import { COLORS } from "../../utils";

const Mail16: React.FunctionComponent<IIconParentProps> = ({ width, height, className, hexFill = COLORS.midnight, viewBox, onClick = () => {} }) => {
  return (
    <svg
      onClick={onClick}
      className={className || ""}
      width={width ? width : "16px"}
      height={height ? height : "16px"}
      viewBox={viewBox ? viewBox.join(" ") : `0 0 16 16`}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.0572 2.82639H1.00057C0.829542 2.81914 0.661329 2.87308 0.526276 2.9788C0.41362 3.06699 0.330404 3.18623 0.286154 3.32073C0.268819 3.36201 0.258901 3.40512 0.256031 3.44831L0.255201 3.45291V3.46858C0.255135 3.47275 0.255136 3.47692 0.255201 3.48109V12.5479L0.261587 12.5832C0.293019 12.757 0.38722 12.9131 0.526276 13.022C0.661328 13.1277 0.829541 13.1816 1.00057 13.1744H14.9994C15.1705 13.1816 15.3387 13.1277 15.4737 13.022C15.6128 12.9131 15.707 12.757 15.7384 12.5832L15.7448 12.5479V3.45863L15.743 3.44645C15.7406 3.41271 15.7339 3.37906 15.7227 3.34632C15.6862 3.21422 15.612 3.09488 15.5083 3.00344C15.3834 2.89331 15.2237 2.83055 15.0572 2.82639ZM14.4034 3.62639H1.6181L8.10859 7.86593L14.4034 3.62639ZM1.0552 4.21426V12.3744H14.9448V4.22627L8.44041 8.60698C8.42078 8.6202 8.4004 8.63144 8.37951 8.64076C8.24798 8.76058 8.04741 8.78151 7.89153 8.67969L1.0552 4.21426Z"
        fill={hexFill}
      />
    </svg>
  );
};

export default Mail16;
