import React, { FunctionComponent, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Vector3, Euler, Object3D, PerspectiveCamera } from 'three';
import { IDesignerState, ITransformControlsMode } from '../../../../../typings';
import {
	onSetCurvatures,
	onSetEmitterAreaScales,
	onSetEmitterProperties,
	onSetMultipleComponentProps_Cn_Doc,
	onSetPositions,
	onSetPositions_Cn_Doc,
	onSetRotations,
	onSetRotations_Cn_Doc,
	onSetScales,
	onSetScales_Cn_Doc,
	onSetTransformControlsActive,
} from '../../../../store/actions';
import { maths, useIsSnappedCurvedEntitySelected } from '../../../../utils';
import { getComponentParentIdById } from '../../../../utils/pure';
import { IBaseSpatialComponent, IComponentType, ISpatialComponentUnion, ITuple3 } from '../../r3f-components/component-data-structure';
import { getSnapEntityCurvatureOnTarget } from '../../r3f-components/components/CurvedEntity/gizmoUtil';
import { getFaceLandmarkPositionsByHeadbustType } from '../../r3f-components/utils/face-tracking';
import { isCurveComponent } from '../../r3f-components/utils/general';
import TransformControlsReactWrapper from './TransformControlsReactWrapper';
import { Axis } from './TransformUtils';

interface IParentProps {
	locked: boolean;
}

const TransformControls: FunctionComponent<IParentProps> = ({ locked }) => {
	// Refs
	const transformControlsRef = useRef<any>();
	const prevScale = useRef<any>();

	// Redux
	const dispatch = useDispatch();
	const mode = useSelector((state: IDesignerState) => state.userReducer.transformControlsMode);
	const entityId = useSelector((state: IDesignerState) => state.userReducer.selectedEntityIds![0]);
	const entity = useSelector((state: IDesignerState) => state.contentReducer.contentDoc.componentsById?.[entityId]);
	const isSnappedCurvedEntitySelected = useIsSnappedCurvedEntitySelected();
	const aspectRatioIsLocked = (entity as ISpatialComponentUnion)?.aspectRatioLocked;
	const initialEntityScale = useSelector((state: IDesignerState) => (state.contentReducer.contentDoc.componentsById?.[entityId] as IBaseSpatialComponent)?.scale);
	const initialEntityRotation = useSelector((state: IDesignerState) => (state.contentReducer.contentDoc.componentsById?.[entityId] as IBaseSpatialComponent)?.rotation);
	const isHiddenEntity = useSelector((state: IDesignerState) => !!(state.contentReducer.contentDoc.componentsById[entityId] as ISpatialComponentUnion)?.isHidden);
	const transientEntityScale = useSelector((state: IDesignerState) => state.userReducer.scaleById[entityId]);
	const transientEntityRotation = useSelector((state: IDesignerState) => state.userReducer.rotationById[entityId]);
	const hideZ = entity && (entity?.type !== IComponentType.Model3d && entity?.type !== IComponentType.Emitter && entity?.type !== IComponentType.Text3d) && mode === 'scale';
	const startPosition = useSelector((state: IDesignerState) => (entityId ? (state.contentReducer.contentDoc.componentsById?.[entityId] as IBaseSpatialComponent)?.position : null));
	const componentsById = useSelector((state: IDesignerState) => state.contentReducer.contentDoc.componentsById);
	const parentId = getComponentParentIdById(entityId, componentsById);
	const parent = parentId ? componentsById[parentId] : null;
	const isParticleSizeUnlinkedEmitter = entity?.type === IComponentType.Emitter && !entity.particleSizeLinked;

	if (isHiddenEntity) return null;
	if (!entity) return null;
	if (mode == ITransformControlsMode.curve || (isSnappedCurvedEntitySelected && mode !== ITransformControlsMode.scale)) return null;

	// If selected entity in face landmark group, set wrapper group position to landmark position
	let groupPosition: ITuple3 = [0, 0, 0];
	if (parent && parent.type === IComponentType.FaceLandmarkGroup) {
		const landmarkPositionDict = getFaceLandmarkPositionsByHeadbustType('uar');
		const pos = landmarkPositionDict[parent.landmark];
		if (pos) groupPosition = [pos[0] / 2, pos[1] / 2, pos[2] / 2];
	}

	// Helpers
	const translateAction = (newPosition: Vector3, mode: 'change' | 'complete') => {
		const position = newPosition.toArray();
		const transientPositionDict = {};

		// While changing, update the editor doc for transient changes, on complete update the content doc and reset the editor doc for this entity
		if (mode === 'change') {
			transientPositionDict[entityId] = position;
			dispatch(onSetPositions(transientPositionDict));
		} else {
			transientPositionDict[entityId] = null;
			dispatch(onSetPositions_Cn_Doc({ [entityId]: position }));
			dispatch(onSetPositions(transientPositionDict));
		}
	};

	const constrainTo360 = (angle: number) => {
		if (angle >= 0 && angle <= 360) return angle;
		if (angle < 0) return 360 + angle;
	};

	const rotateAction = (newRotation: Euler, mode: 'change' | 'complete') => {
		const rotation = newRotation.toArray();

		const transientRotationDict = {};

		let updatedRotation = transientEntityRotation ? transientEntityRotation : initialEntityRotation;
		updatedRotation = [constrainTo360(maths.toDegrees(rotation[0])), constrainTo360(maths.toDegrees(rotation[1])), constrainTo360(maths.toDegrees(rotation[2]))];

		// While changing, update the editor doc for transient changes, on complete update the content doc and reset the editor doc for this entity
		if (mode === 'change') {
			transientRotationDict[entityId] = updatedRotation;
			dispatch(onSetRotations(transientRotationDict));
		} else {
			transientRotationDict[entityId] = null;
			dispatch(onSetRotations_Cn_Doc({ [entityId]: updatedRotation }));
			dispatch(onSetRotations(transientRotationDict));
		}
	};

	const scaleAction = (newScale: Vector3, axis: Axis, mode: 'change' | 'complete') => {
		const scale = newScale.toArray();

		const transientScaleDict = {};
		const transientCurvatureDict = {};
		const startScale = isParticleSizeUnlinkedEmitter ? entity.emitter.position!.spread! : initialEntityScale;
		const updatedScale = transientEntityScale ? transientEntityScale : startScale;

		// Calculate difference from previous state if we are in the middle of dragging, or use 1, 1, 1 if this is the first change
		const difference = prevScale.current ? [scale[0] - prevScale.current[0], scale[1] - prevScale.current[1], scale[2] - prevScale.current[2]] : [scale[0] - 1, scale[1] - 1, scale[2] - 1];
		const dampener = 1; // Use to adjust the speed of the scaling
		prevScale.current = scale;

		if (aspectRatioIsLocked) {
			const XYratio = startScale[0] / startScale[1];
			const XZratio = startScale[0] / startScale[2];
			const YXratio = startScale[1] / startScale[0];
			const YZratio = startScale[1] / startScale[2];
			const ZXratio = startScale[2] / startScale[0];
			const ZYratio = startScale[2] / startScale[1];

			switch (axis) {
				case 'X':
					updatedScale[0] += difference[0] * dampener;
					updatedScale[1] = updatedScale[0] / XYratio;
					updatedScale[2] = updatedScale[0] / XZratio;
					break;
				case 'Y':
					updatedScale[1] += difference[1] * dampener;
					updatedScale[0] = updatedScale[1] / YXratio;
					updatedScale[2] = updatedScale[1] / YZratio;
					break;
				case 'Z':
					updatedScale[2] += difference[2] * dampener;
					updatedScale[0] = updatedScale[2] / ZXratio;
					updatedScale[1] = updatedScale[2] / ZYratio;
					break;
				default:
					break;
			}
		} else {
			updatedScale[0] += difference[0] * dampener;
			updatedScale[1] += difference[1] * dampener;
			updatedScale[2] += difference[2] * dampener;
		}

		// While changing, update the editor doc for transient changes, on complete update the content doc and reset the editor doc for this entity
		if (mode === 'change') {
			transientScaleDict[entityId] = [updatedScale[0], updatedScale[1], updatedScale[2]];
			if (isParticleSizeUnlinkedEmitter) {
				dispatch(onSetEmitterAreaScales(transientScaleDict));
				return;
			}
			if (isSnappedCurvedEntitySelected && isCurveComponent(entity)) {
				const currentRadius = Math.sqrt(entity.position[0] ** 2 + entity.position[2] ** 2);
				const newCurvature = getSnapEntityCurvatureOnTarget(currentRadius, [updatedScale[0]*2, updatedScale[1], updatedScale[2]]);
				const transientCurvatureDict = {};
				transientCurvatureDict[entity.id] = newCurvature;
				dispatch(onSetCurvatures(transientCurvatureDict));
				dispatch(onSetScales(transientScaleDict));
			} else {
				dispatch(onSetScales(transientScaleDict));
			}
		} else {
			transientScaleDict[entityId] = null;
			transientCurvatureDict[entityId] = null;
			if (isParticleSizeUnlinkedEmitter) {
				dispatch(onSetEmitterProperties({ entityId: entity.id, settings: { position: { spread: [updatedScale[0], updatedScale[1], updatedScale[2]] } } }));
				dispatch(onSetEmitterAreaScales(transientScaleDict));
				return;
			}
			if (isSnappedCurvedEntitySelected && isCurveComponent(entity)) {
				const currentRadius = Math.sqrt(entity.position[0] ** 2 + entity.position[2] ** 2);
				const newCurvature = getSnapEntityCurvatureOnTarget(currentRadius, [updatedScale[0]*2, updatedScale[1], updatedScale[2]]);
				dispatch(
					onSetMultipleComponentProps_Cn_Doc([
						{
							id: entity.id,
							curvature: newCurvature,
						},
					])
				);
				dispatch(onSetCurvatures(transientCurvatureDict));
			}
			dispatch(onSetScales_Cn_Doc({ [entityId]: [updatedScale[0], updatedScale[1], updatedScale[2]] }));
			dispatch(onSetScales(transientScaleDict));
		}
	};

	const _onMouseUp = (e: Event) => {
		console.log('transform inactive!!')
		dispatch(onSetTransformControlsActive(false))
		switch (mode) {
			case 'translate':
				translateAction((e?.target as any).object.position, 'complete');
				break;
			case 'scale':
				scaleAction((e?.target as any).object.scale, (e?.target as any).axis, 'complete');
				break;
			case 'rotate':
				rotateAction((e?.target as any).object.rotation, 'complete');
				break;
		}
	};

	const _onMouseDown = () => {
		dispatch(onSetTransformControlsActive(true))
	}

	const _onObjectChange = (e: Event) => {
		switch (mode) {
			case 'translate':
				translateAction((e?.target as any).object.position, 'change');		
				break;
			case 'scale':
				scaleAction((e?.target as any).object.scale, (e?.target as any).axis, 'change');
				break;
			case 'rotate':
				rotateAction((e?.target as any).object.rotation, 'change');
				break;
		}
	};
	return (
		<group position={groupPosition}>
			<TransformControlsReactWrapper
				ref={transformControlsRef}
				mode={mode}
				scale={[1, 1, 1]}
				initialEntityRotation={
					transientEntityRotation
						? [maths.toRadians(transientEntityRotation[0]), maths.toRadians(transientEntityRotation[1]), maths.toRadians(transientEntityRotation[2])]
						: [maths.toRadians(initialEntityRotation[0]), maths.toRadians(initialEntityRotation[1]), maths.toRadians(initialEntityRotation[2])]
				}
				startPosition={startPosition as number[]}
				locked={locked}
				showZ={!hideZ}
				entityId={entityId}
				// @ts-ignore
				onMouseUp={!locked && _onMouseUp}
				onMouseDown={_onMouseDown}
				// @ts-ignore
				onObjectChange={!locked && _onObjectChange}
			/>
		</group>
	);
};

export default TransformControls;
