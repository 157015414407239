import { IFileType, IProgressStatus } from '@zw-api-client/src/zml';
import { ICanvasName, IEntityDrawerTypes, IEntityShelves, IOpenEntityCategoryDrawerDict } from '.';
import {
	IActionCategory,
	IChromaKey,
	IComponentType,
	IFaceLandmark,
	IImage,
	IModel3d,
	IModel3dAnimationOptions,
	ISpatialComponentUnion,
	IText3d,
	ITriggerTypes,
	ITuple2,
	ITuple3,
	IUserData,
	IVector3,
	IVector3Dict,
	IVector4,
	IVector4Dict,
	IVideo
} from '../src/components/r3f/r3f-components/component-data-structure';
import { IWebEmbedPreviewSettings } from '../src/components/r3f/r3f-components/component-data-structure/types/webEmbed';
import { ILoadedMediaTypes, ISceneSnapshotDict } from '../src/store/actions';
import { IBounds } from '../src/utils/transforms';
import { IVideoProgressDict } from '../src/components/r3f/r3f-components/component-data-structure/types/videoStatus';

export enum IToastType {
	error = 'error',
	warning = 'warning',
	info = 'info',
	success = 'success',
}
export interface IBaseToast {
	type: IToastType;
	message: string;
	timeout?: number;
	size?: IToastSize;
	contrast?: IToastContrast;
	stackToasts?: boolean;
	id?: string;
	singleton?: boolean;
}

export interface IToast extends IBaseToast {
	id: string;
}

export enum IToastSize {
	default = 'default',
	mini = 'mini',
}

export enum IToastContrast {
	undefined = 'undefined',
	default = 'default',
	high = 'high',
}

export enum ITransformControlsMode {
	translate = 'translate',
	rotate = 'rotate',
	scale = 'scale',
	curve = 'curve',
}

export enum IProjectShelves {
	targetProperty = 'targetProperty',
	backgroundSound = 'backgroundSound',
	arWebEmbed = 'arWebEmbed',
	targetNotSeen = 'targetNotSeen',
	analytics = 'analytics',
	shadows = 'shadows',
	splashScreen = 'splashScreen'
}

export enum ISceneShelves {
	properties = 'properties',
	transitions = 'transitions',
	photoUi = 'photoUi',
	screenSettings = 'screenSettings',
	sceneActions = 'sceneActions',
	content360 = 'content360'
}

export type IDragState = {
	clickOffset?: ITuple2;
	dimensions?: ITuple2;
};

export enum IModal {
	device = 'device',
	template = 'template',
	templateOverwrite = 'templateOverwrite',
	avaturn = 'avaturn',
	avaturnHelp = 'avaturnHelp',
	mediaLibrary = 'mediaLibrary',
	imageTrainer = 'imageTrainer',
	particles = 'particles',
	particlesHelp = 'particlesHelp',
	imageTrackingPreview = 'imageTrackingPreview',
	chromaKeyEdit = 'chromaKeyEdit',
	chromaKeyHelp = 'chromaKeyHelp',
	videoProcessingWarning = 'videoProcessingWarning'
}

export interface ISelectedTemplateProject {
	id: string;
	title: string;
	slug?: string;
}

export interface IActivePresetAnimationState {
	entityId: string,
	state: 'play' | 'stop' | 'pause',
	actionIndex: number,
}

export type IActivePresetAnimationData = IActivePresetAnimationState | null;
export type IActiveEditingActionData = {
	entityId: string,
	triggerType: ITriggerTypes,
	actionIndex: number;
} | null;

export type ILoadedModel3d = IModel3d & { isPublic: boolean };
export type ILoadedVideo = IVideo & { isPublic: boolean };
export type ILoadedImage = IImage & { isPublic: boolean };

export type ILoadedComponentUnion = ILoadedModel3d | ILoadedVideo | ILoadedImage;

export interface IChromaKeyEditModalData {
	videoUrl: string;
	chromaKey: IChromaKey;
	mediaLibraryLoadedEntity?: IVideo;
}

// User State
export interface IUserState {
	activeEditingActionData: IActiveEditingActionData;
	popOutIdealTopPosition: number | null;
	isPresetAnimationActive: boolean;	
	multipleEntitySelectBoundary?: IBounds | null;
	chromaKeyEditModalData: IChromaKeyEditModalData;
	selectedTemplateProject?: ISelectedTemplateProject;
	targetImageReferenceObjectPosition: number | null;
	mediaLibraryDisplay: IMediaLibraryDisplay;
	modalById: Partial<Record<IModal, boolean>>;
	isWebEmbedEditingMode: boolean;
	previewCodeSrc?: string;
	previewUrl?: string;
	webEmbed?: IWebEmbedPreviewSettings;
	userId?: string;
	fileVerifying?: boolean;
	toasts: IToast[];
	is3dMode: boolean;
	isScreenRelativeMode: boolean;
	transformControlsMode: ITransformControlsMode;
	transformControlsActive: boolean;
	socketConnected?: boolean;
	projectLoadingProgress: number;
	projectLoadingFailure: boolean;
	selectedEntityIds?: string[];
	lockedEntityIds?: string[];
	copiedEntityIds?: string[];
	activeSceneId: string | null;
	positioningIsActive: boolean;
	scaleHotspotIsEnabled: boolean;
	rotationIsActive: boolean;
	backgroundHotspotIsEnabled: boolean;
	zoomLevel: number;
	undoDisabled: boolean;
	redoDisabled: boolean;
	markerIndexPressed: number | null;
	hotKeys: string[];
	pointerOffset: IVector3;
	entityDragHotspotPosition: IVector3 | null;
	selectedObject2DPosition: IVector3 | null;
	selectionTopLeft2DPosition: IVector3 | null;
	selectionTopRight2DPosition: IVector3 | null;
	selectionBottomLeft2DPosition: IVector3 | null;
	selectionBottomRight2DPosition: IVector3 | null;
	rotationMarker2DPosition: IVector3 | null;
	entityDrag2DPosition: IVector3 | null;
	groupIsXInverted: boolean;
	groupIsYInverted: boolean;
	sceneEntityListIsVisible: boolean;
	sceneEntityListPosition: [number, number];
	currentSceneIndex: number;
	scrollSceneMenu: boolean;
	sceneSnapshots: ISceneSnapshotDict;
	sceneCarouselScrollLeft: number;
	sceneMenuIsLarge: boolean;
	sceneNameInputIsFocused: boolean;
	project: IProjectData;
	loggedInUsers: { [id: string]: IUserData };
	canvasLoaded: boolean;
	entityMenuDragActive: boolean;
	entityDragInfo: (ISpatialComponentUnion & IDragState) | null;
	loadedImages: ILoadedImage[] | null;
	loadedVideos: ILoadedVideo[] | null;
	loaded3dModels: ILoadedModel3d[] | null;
	loadedAudio: IAudio[] | null;
	loadedBackgroundContent: (IBackgroundContent & {statusUrl: string})[] | null;
	mediaLibraryAudio: IAudio | null;
	loadingMedia: boolean;
	currentUploads: IUploadInfoDict;
	numberProcessedUploads: number; //needed to calculate overall upload process
	trackImgData: ITrkImgData | null;
	trkImgIsUploading: boolean;
	pureReactInCanvasHovered: boolean; // legacy
	editSceneTitle: boolean;
	openInspectorMenuEntityShelves: IEntityShelves[] | null;
	openInspectorMenuProjectShelves: IProjectShelves[] | null;
	openInspectorMenuSceneShelves: ISceneShelves[] | null;
	openEntityMenuDrawer: IEntityDrawerTypes | null;
	openEntityCategoryDrawerDict: IOpenEntityCategoryDrawerDict;
	screenRelativeDevice: IScreenRelativeDevice;
	screenRelativeDevicePreviousCustomDimensions?: IScreenRelativeDeviceDimensions;
	tempAnimateModelAction: ({ type: IActionCategory.animateModel } & IModel3dAnimationOptions) | null;
	visibleCanvases: Record<ICanvasName, boolean>;
	positionById: IVector3Dict | { [id: string]: null };
	scaleById: IVector3Dict | { [id: string]: null };
	rotationById: IVector3Dict | { [id: string]: null };
	emitterAreaScalesById: IVector3Dict | { [id: string]: null };
	emitterIsStaticById: { [id: string]: boolean };
	curvatureById: { [id: string]: null | number };
	fillRgbaById: {[key: string]: IVector4[] | null };
	fontRgbaById: IVector4Dict | { [id: string]: null };
	borderRgbaById: IVector4Dict | { [id: string]: null };

	activeFaceLandmark: IFaceLandmark | null;
	showFaceLandmarks: boolean;
	hoveredDropdownFacelandmark: IFaceLandmark | null;
	forceUpdateDragControlsTrigger: number;
	temporaryDragRotation: ITuple3 | null;
	videoProgressById?: IVideoProgressDict;
	showPublishPopup: boolean;
	publishTrigger: number;
}

export interface IScreenRelativeDeviceDimensions {
	height: number;
	width: number;
}

export interface IScreenRelativeDevice extends IScreenRelativeDeviceDimensions {
	label: string;
}

export enum IScreenRelativeDevices {
	IPHONE12 = 'IPHONE12',
	IPHONE8 = 'IPHONE8',
	PIXEL6a = 'PIXEL6a',
	GALAXYS7 = 'GALAXYS7',
	IPAD = 'IPAD',
	HORIZONTAL = 'HORIZONTAL',
	CUSTOM = 'CUSTOM',
}

export interface IMediaLibraryDisplay {
	fileTypes: IFileType[]; // File types to include in the media library
	category?: IMediaCategory; // Default tab - if undefined then 'All'
	onSubmitCategory?: IMediaLibraryOnSubmitCategory; 
}

// TODO - This should be imported from web-components
export enum IMediaCategory {
	Audio = 'Audio',
	Image = 'Image',
	Video = 'Video',
	ThreeD = '3D',
	Three60  = '360 content'
}

export enum IMediaLibraryOnSubmitCategory {
	AddToScene = 'AddToScene',
	ReplaceEntity = 'ReplaceEntity',
	SelectChromaKeyVideo = 'SelectChromaKeyVideo',
}

export const mediaLibraryCategoryByComponentType = {
	[IComponentType.Image]: IMediaCategory.Image,
	[IComponentType.Video]: IMediaCategory.Video,
	[IComponentType.Model3d]: IMediaCategory.ThreeD,
} as { [k in IComponentType]: IMediaCategory };

export interface IUploadInfoDict {
	[id: string]: IUploadInfo;
}

export type IUploadInfo = IUploadData | null;

interface IUploadData {
	fileName?: string;
	type?: IProgressStatus;
	perc?: number | null;
	dataURl?: string | ArrayBuffer;
	errorStatus?: number;
	errorText?: string;
	mediaType?: ILoadedMediaTypes;
}

export interface IAudio {
	id: string;
	zmlFileId: string;
	url: string;
	name: string;
	status?: IAudioStatus;
}

export interface IBackgroundContent {
	id: string;
	type?: IFileType.Image360 | IFileType.Video360;
	url: string;
	name: string;
}

export enum IAudioStatus {
	playing = 'playing',
	paused = 'paused',
	loading = 'loading',
}

export interface IProjectData {
	id?: string;
	title?: string;
}

export interface ITrkImgData {
	quality?: number;
	aspectRatio?: number;
	codes?: string[];
	compositeUrl?: string;
}
