import React from "react";
import { IIconParentProps } from "./index";
import { COLORS } from "../../utils";

const copyIcon: React.FunctionComponent<IIconParentProps> = ({
  hexFill: fill,
  viewBox,
  width,
  height,
  className,
  onPointerEnter,
  onPointerLeave,
  onClick = () => {},
  title,
}) => {
  const hexFill = fill || COLORS.midnight;
  return (
    <svg
      onClick={onClick}
      className={className || ""}
      width={width}
      onPointerLeave={onPointerLeave}
      onPointerEnter={onPointerEnter}
      height={height}
      viewBox={viewBox ? viewBox.join(" ") : `0 0 21 20`}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>{title}</title>
      <g
        id="Expanded"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Copy"
          transform="translate(-10.000000, -10.000000)"
          fill={hexFill}
          fillRule="nonzero"
        >
          <path d="M23.6765201,16.0077515 L23.6765201,13.0062582 C23.6765201,11.2028277 22.0931561,10 20.5204,10 L13.1627066,10 C11.5593398,10 10,11.2276144 10,13.0062582 L10,21.0108498 C10,22.7392491 11.5398731,24 13.1627066,24 L16.3160927,24 L16.3160927,26.9982452 C16.3160927,28.8315015 17.9374047,30 19.46836,30 L26.8292602,30 C28.4030701,30 29.9887601,28.8150021 29.9887601,26.9982452 C29.9962534,22.6549511 30,19.9914548 30,19.0077565 C30,17.5322089 28.7667603,16 26.8292602,16 L23.6765201,16.0077515 Z M13.1531502,23 C11.8978169,23 11.0508039,22.0175323 11.0508039,20.9983978 L11.0508039,12.9978946 C11.0508039,11.8530579 12.038329,10.998784 13.1531502,10.998784 L20.5204,10.998784 C21.8243896,10.998784 22.6273535,12.019577 22.6273535,12.9978946 L22.6273535,16 L19.46836,16 C17.7089991,16 16.31224,17.3621063 16.31224,19.0041809 C16.31224,20.0988973 16.31224,21.430837 16.31224,23 L13.1531502,23 Z M28.9400964,27.0013233 C28.9400964,27.9887758 28.1434782,29.0124575 26.832884,29.0124575 L19.46836,29.0124575 C18.4094787,29.0124575 17.3646011,28.1756204 17.3646011,27.0013233 C17.3646011,22.4803835 17.3646011,19.8170639 17.3646011,19.0113643 C17.3646011,17.802815 18.3994657,17.0014496 19.46836,17.0014496 C20.1809563,17.0014496 22.6357976,17.0014496 26.832884,17.0014496 C27.838555,17.0014496 28.9400964,17.753937 28.9400964,19.0113643 C28.9400964,19.8496492 28.9400964,22.5129689 28.9400964,27.0013233 Z"></path>
        </g>
      </g>
    </svg>
  );
};

export default copyIcon;
