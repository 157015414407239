import { Camera, Object3D, Raycaster, Scene, Vector2, Vector3 } from 'three';
import { IDomIdSelectors } from '../../../../typings';
import { getParentAnchorGroup } from '../../../utils/general';
import { IComponentsById, ISceneComp, IScreenAnchorGroup, ITuple2, ITuple3, IVector3 } from '../../r3f/r3f-components/component-data-structure';


export const isOutsideDomRect = (clientX: number, clientY: number, domElement: HTMLElement, padding = 0) => {
	const domRect = domElement.getBoundingClientRect();
	const top = domRect.top - padding;
	const bottom = domRect.bottom + padding;
	const left = domRect.left - padding;
	const right = domRect.right + padding;

	if (clientX > right || clientX < left) return true;
	if (clientY < top || clientY > bottom) return true;
	return false;
};

export const convertPxToCanvasNormalisedCoords = (clientX: number, clientY: number, canvasEl: HTMLCanvasElement, raycastPlane: Object3D, raycastCamera: Camera, raycaster: Raycaster, offset?: ITuple2) :  ITuple3 | undefined  => {
	const canvasHeight = canvasEl.clientHeight;
	const canvasWidth = canvasEl.clientWidth;
	const canvasLeft = canvasEl.getBoundingClientRect().left;
	const canvasTop = canvasEl.getBoundingClientRect().top;

	const dragAdjustmentX = offset?.[0] ?? 0;
	const dragAdjustmentY = offset?.[1] ?? 0;

	const xPxWithinCanvas = clientX - canvasLeft - dragAdjustmentX;
	const yPxWithinCanvas = clientY - canvasTop - dragAdjustmentY;

	const xCoord = (xPxWithinCanvas / canvasWidth) * 2 - 1;
	const yCoord = -(yPxWithinCanvas / canvasHeight) * 2 + 1;

	raycaster.setFromCamera(new Vector2(xCoord, yCoord), raycastCamera);
	const intersection = raycaster.intersectObject(raycastPlane);
	if (!intersection || typeof intersection[0] === 'undefined') return;
	return [intersection[0].point.x, intersection[0].point.y, intersection[0].point.z];
};


export const getArCanvasDomEl = () => {
  const containerDiv = document.getElementById(IDomIdSelectors.zapparCanvas);
  if (!containerDiv) return;
  return containerDiv.getElementsByTagName('canvas')[0];
}

export const getSrCanvasDomEl = () => {
  const containerDiv = document.getElementById(IDomIdSelectors.screenRelativeCanvas);
  if (!containerDiv) return;
  return containerDiv.getElementsByTagName('canvas')[0];
}

export const getWorldPositionForScreenEntity = (id: string, activeSceneId: string | null, componentsById: IComponentsById, position: IVector3, scene: Scene) => {
	if (!activeSceneId) return null;
	const activeScene = componentsById[activeSceneId] as ISceneComp;
	const anchorGroupId = getParentAnchorGroup(activeScene, id, componentsById);
	if (!anchorGroupId) return null;
	const anchorGroup = componentsById[anchorGroupId] as IScreenAnchorGroup;
	const anchorGroupThree = scene.getObjectByName(anchorGroup.anchorPositionType);
	if (!anchorGroupThree) return null;
	const vecPos = anchorGroupThree?.localToWorld(new Vector3(...position));
	return [vecPos.x, vecPos.y, vecPos.z] as IVector3;
};

export const getLocalPositionForScreenEntity = (id: string, activeSceneId: string | null, componentsById: IComponentsById, position: IVector3, scene: Scene) => {
	if (!activeSceneId) return null;
	const activeScene = componentsById[activeSceneId] as ISceneComp;
	const anchorGroupId = getParentAnchorGroup(activeScene, id, componentsById);
	if (!anchorGroupId) return null;
	const anchorGroup = componentsById[anchorGroupId] as IScreenAnchorGroup;
	const anchorGroupThree = scene.getObjectByName(anchorGroup.anchorPositionType);
	if (!anchorGroupThree) return null;
	const vecPos = anchorGroupThree?.worldToLocal(new Vector3(...position));
	return [vecPos.x, vecPos.y, vecPos.z] as IVector3;
};