import {
    SkinnedMesh,
    Mesh,
    BufferGeometry,
    Material,
    Bone,
    AnimationClip
  } from "three";
import { Object3D } from "three";
import * as utils from "three/examples/jsm/utils/SkeletonUtils";
import { updateGeomWithSkinnedMeshMorphAttr } from "./geometry-utils";
import { GLTF } from "three/examples/jsm/loaders/GLTFLoader";
  
export const convertGltf = (gltf: GLTF): {
    scene: Object3D;
    animations: AnimationClip[];
    meshToSkinnedMeshUuidDict: { [id: string]: string };
} => {

    const userDataUuidToNodeUuidDict: { [id: string]: string } = {};
    const meshToSkinnedMeshUuidDict: { [id: string]: string } = {};

    gltf.scene.traverse((node: any) => {
        // add skinned mesh and bone uuid to user data for later mapping to cloned model instance
        if (node.isBone || node.isSkinnedMesh) {
        node.userData = {
            ...node.userData,
            uuid: node.uuid
        };
        }
    });

    // use Skeleton utils to safely clone skinned mesh (and other) models
    const clonedScene = utils.clone(gltf.scene);

    // map user data uuids to cloned node uuids (which change during cloning)
    clonedScene.traverse((node: any) => {
        if (node.isBone || node.isSkinnedMesh) {
        userDataUuidToNodeUuidDict[node.userData.uuid] = node.uuid;
        }
    });


    gltf.scene.updateMatrixWorld(true);

    gltf.scene.traverse((node: any) => {
        // create baked geometry from skinned mesh
        if (node.isSkinnedMesh) {
        const skinnedMesh = node as SkinnedMesh;
        skinnedMesh.skeleton.update();

        const skinnedGeom = skinnedMesh.geometry;
        const { attributes } = skinnedGeom;

        const bakedGeometry = new BufferGeometry();

        // name
        bakedGeometry.name = skinnedGeom.name;
        // index
        if (skinnedGeom.index)
            bakedGeometry.setIndex(skinnedGeom.index?.clone?.());

        // if normals are missing, compute them
        if (!attributes.normal) skinnedGeom.computeVertexNormals();

        // attributes
        for (const name in attributes) {
            if (
            name !== "position" &&
            name !== "normal" &&
            name !== "skinIndex" &&
            name !== "skinWeight"
            ) {
            const attribute = attributes[name];
            bakedGeometry.setAttribute(name, attribute.clone());
            }
        }

        // groups
        const groups = skinnedGeom.groups;
        for (let i = 0, l = groups.length; i < l; i++) {
            const group = groups[i];
            bakedGeometry.addGroup(group.start, group.count, group.materialIndex);
        }

        updateGeomWithSkinnedMeshMorphAttr(bakedGeometry, skinnedMesh);

        // draw range
        bakedGeometry.drawRange.start = skinnedGeom.drawRange.start;
        bakedGeometry.drawRange.count = skinnedGeom.drawRange.count;

        // user data
        bakedGeometry.userData = skinnedGeom.userData;



        // create new material(s)
        const { material } = skinnedMesh;
        let newMaterial: Material | Material[];

        if (material instanceof Array) {
            newMaterial = [];
            for (let i = 0; i < material.length; i++) {
            newMaterial.push(material[i].clone());
            }
        } else newMaterial = material.clone();

        // add newly created mesh to mesh parent of cloned scene
        const clonedNodeUuid = userDataUuidToNodeUuidDict[node.userData.uuid];
        const clonedSceneNode = clonedScene.getObjectByProperty(
            "uuid",
            clonedNodeUuid
        ) as SkinnedMesh;

        // create a new mesh
        const newMesh = new Mesh();
        newMesh.name = 'hello'
        clonedSceneNode.userData = { origUuid: clonedSceneNode.uuid };
        
        // fill mesh to meshToSkinnedMeshUuid dict 
        meshToSkinnedMeshUuidDict[newMesh.uuid] = clonedNodeUuid;
        newMesh.copy(clonedSceneNode);
        newMesh.userData = { origUuid: newMesh.uuid };
        newMesh.geometry = bakedGeometry;
        //newMesh.material = newMaterial;
        clonedSceneNode?.parent?.add?.(newMesh);
        newMesh.visible = false;
        }
    });

    // hide skinnedMeshes and bones model scene (needed in future milestones for animation)
    for (const userDataUuid in userDataUuidToNodeUuidDict) {
        const nodeUuid = userDataUuidToNodeUuidDict[userDataUuid];
        const redundantObject = clonedScene.getObjectByProperty(
        "uuid",
        nodeUuid
        ) as SkinnedMesh | Bone;
        redundantObject.visible = true;
    }

    return {
        scene: clonedScene,
        animations: gltf.animations,
        meshToSkinnedMeshUuidDict
    };

};
  