import * as THREE from 'three';
import { ITuple3, IVector3 } from '../../r3f-components/component-data-structure';
import {
	getRadiusFromCurvature,
	getMoveAroundTarget,
	getMoveFromTarget,
	MOVE_AWAY_COLOR,
	MOVE_AROUND_COLOR,
	MOVE_VERTICAL_COLOR,
	HELPER_CIRCLE_COLOR,
	MOVE_AROUND_COLOR_INACTIVE,
	MOVE_VERTICAL_COLOR_INACTIVE,
	MOVE_AWAY_COLOR_INACTIVE,
} from '../../r3f-components/components/CurvedEntity/gizmoUtil';

export type IGizmoMode = null | 'moveAway' | 'moveVertical' | 'moveAround';
export type IOnChangeFn = (
	mode: IGizmoMode,
	data: {
		newPosition: ITuple3;
		newRotation?: ITuple3;
		newCurvature?: number;
	}
) => unknown;

export type IOnPointerUpFn = (mode: IGizmoMode) => unknown;

export class CurvedControlsThree extends THREE.Object3D {
	private _raycaster: THREE.Raycaster;
	private _camera: THREE.Camera;
	private _domElement: HTMLCanvasElement;
	private _group: THREE.Group;
	private _entityPosition: THREE.Vector3;
	private _entityRotation: THREE.Euler;
	private _entityWidth: number;
	private _useZForVerticalAxisDrag: boolean;
	private _dampener: number;
	private _radius: number;
	private _origin: THREE.Vector3;
	private _initialAwayPosition: THREE.Vector3 | null;
	private _prevAwayPosition: THREE.Vector3 | null;
	private _initialAroundPosition: THREE.Vector3 | null;
	private _prevAroundPosition: THREE.Vector3 | null;
	private _prevVerticalPosition: number | null;
	private _helperCircle: THREE.Group;
	private _moveAwayGizmo: THREE.Group;
	private _verticalGizmo: THREE.Group;
	private _moveAroundGizmo: THREE.Group;
	private _hotspot: THREE.Group;
	private _gizmoMode: IGizmoMode;
	private _gizmoBaseMaterial: THREE.MeshBasicMaterial;
	private _invisibleMaterial: THREE.MeshBasicMaterial;
	private _initialEntityVerticalPosition: number;
	private _onChange: IOnChangeFn;
	private _onPointerUp?: IOnPointerUpFn;

	constructor(
		domElement: HTMLCanvasElement,
		camera: THREE.Camera,
		entityPosition: IVector3,
		entityRotation: IVector3,
		entityCurvature: number,
		entityWidth: number,
		useZForVerticalAxisDrag: boolean,
		onChange: IOnChangeFn,
		onPointerUp?: IOnPointerUpFn
	) {
		super();

		// Params
		this._camera = camera;
		this._domElement = domElement;
		this._entityPosition = new THREE.Vector3(...entityPosition);
		this._entityRotation = new THREE.Euler(...entityRotation);
		this._entityWidth = entityWidth;
		this._useZForVerticalAxisDrag = useZForVerticalAxisDrag;
		this._onChange = onChange;
		this._onPointerUp = onPointerUp;
		this._radius = getRadiusFromCurvature(entityCurvature, entityWidth);
		this._initialEntityVerticalPosition = entityPosition[1];
		this._gizmoMode = null;
		this._dampener = 0.5; // Slow down the movement by half as feels too jumpy otherwise

		// Three.js
		this._origin = new THREE.Vector3(0, 0, 0);
		this._raycaster = new THREE.Raycaster();
		this._group = new THREE.Group();
		this._hotspot = new THREE.Group();
		this._hotspot.name = 'Hotspot';
		this._prevAwayPosition = null;
		this._prevVerticalPosition = null;
		this._initialAwayPosition = null;
		this._prevAroundPosition = null;
		this._initialAroundPosition = null;
		this._verticalGizmo = new THREE.Group();
		this._verticalGizmo.name = 'Vertical gizmo';
		this._moveAwayGizmo = new THREE.Group();
		this._moveAwayGizmo.name = 'Away gizmo';
		this._moveAroundGizmo = new THREE.Group();
		this._moveAroundGizmo.name = 'Around gizmo';
		this._helperCircle = new THREE.Group();
		this._helperCircle.name = 'Helper circle';
		this._group.name = 'Curved Controls';
		this._group.renderOrder = Infinity;
		this._gizmoBaseMaterial = new THREE.MeshBasicMaterial({
			depthWrite: false,
			depthTest: false,
			toneMapped: false,
			transparent: true,
			polygonOffset: true,
			polygonOffsetFactor: 20,
			polygonOffsetUnits: 20,
		});
		this._invisibleMaterial = new THREE.MeshBasicMaterial({ color: 0x000000, transparent: true, visible: false, opacity: 0.0 });

		// Event listeners
		domElement.addEventListener('pointerdown', this._pointerDownHandler);
		domElement.addEventListener('pointermove', this._pointerHoverHandler);
		domElement.addEventListener('pointermove', this._pointerMoveHandler);
		domElement.addEventListener('pointerup', this._pointerUpHandler);

		// Add gizmo parts
		this._addHelperCircle();
		this._addMoveAwayGizmo();
		this._addMoveVerticalGizmo();
		this._addMoveAroundGizmo();
		this._addHotspot();

		// Return
		this.add(this._group);
		return this;
	}

	public dispose = () => {
		this._domElement.removeEventListener('pointerdown', this._pointerDownHandler);
		this._domElement.removeEventListener('pointermove', this._pointerHoverHandler);
		this._domElement.removeEventListener('pointermove', this._pointerMoveHandler);
		this._domElement.removeEventListener('pointerup', this._pointerUpHandler);
		this.traverse((child) => {
			const mesh = child as THREE.Mesh<THREE.BufferGeometry, THREE.Material>;
			if (mesh.geometry) mesh.geometry.dispose();
			if (mesh.material) mesh.material.dispose();
		});
	};

	private getCanvasNormalisedIntersectionsFromPxCoords = (clientX: number, clientY: number) => {
		const canvasHeight = this._domElement.clientHeight;
		const canvasWidth = this._domElement.clientWidth;
		const canvasLeft = this._domElement.getBoundingClientRect().left;
		const canvasTop = this._domElement.getBoundingClientRect().top;

		const xPxWithinCanvas = clientX - canvasLeft;
		const yPxWithinCanvas = clientY - canvasTop;

		const xCoord = (xPxWithinCanvas / canvasWidth) * 2 - 1;
		const yCoord = -(yPxWithinCanvas / canvasHeight) * 2 + 1;

		this._raycaster.setFromCamera(new THREE.Vector2(xCoord, yCoord), this._camera);
		return this._raycaster.intersectObject(this, true);
	};

	//** GENERAL POINTER HANDLERS **//
	private _pointerDownHandler = (event: PointerEvent) => {
		const allIntersections = this.getCanvasNormalisedIntersectionsFromPxCoords(event.clientX, event.clientY);
		this._gizmoMode = this._getGizmoMode(allIntersections);
		if (!this._gizmoMode) return;
		this._domElement.setPointerCapture(event.pointerId);
		event.stopImmediatePropagation(); // Prevent orbiting
	};

	private _pointerHoverHandler = (event: PointerEvent) => {
		if (this._gizmoMode) return; // If actively dragging
		this._hotspot.lookAt(this._camera.position);
		const allIntersections = this.getCanvasNormalisedIntersectionsFromPxCoords(event.clientX, event.clientY);
		const tempGizmoMode = this._getGizmoMode(allIntersections);

		switch (tempGizmoMode) {
			case 'moveAway':
				((this._moveAwayGizmo.children[0] as THREE.Mesh).material as THREE.LineBasicMaterial).color.set(MOVE_AWAY_COLOR);
				((this._moveAroundGizmo.children[0] as THREE.Mesh).material as THREE.LineBasicMaterial).color.set(MOVE_AROUND_COLOR_INACTIVE);
				((this._moveAroundGizmo.children[2] as THREE.Mesh).material as THREE.MeshBasicMaterial).color.set(MOVE_AROUND_COLOR_INACTIVE);
				((this._moveAroundGizmo.children[3] as THREE.Mesh).material as THREE.MeshBasicMaterial).color.set(MOVE_AROUND_COLOR_INACTIVE);
				((this._verticalGizmo.children[0] as THREE.Mesh).material as THREE.LineBasicMaterial).color.set(MOVE_VERTICAL_COLOR_INACTIVE);
				break;
			case 'moveAround':
				((this._moveAroundGizmo.children[0] as THREE.Mesh).material as THREE.LineBasicMaterial).color.set(MOVE_AROUND_COLOR);
				((this._moveAroundGizmo.children[2] as THREE.Mesh).material as THREE.MeshBasicMaterial).color.set(MOVE_AROUND_COLOR);
				((this._moveAroundGizmo.children[3] as THREE.Mesh).material as THREE.MeshBasicMaterial).color.set(MOVE_AROUND_COLOR);
				((this._moveAwayGizmo.children[0] as THREE.Mesh).material as THREE.LineBasicMaterial).color.set(MOVE_AWAY_COLOR_INACTIVE);
				((this._verticalGizmo.children[0] as THREE.Mesh).material as THREE.LineBasicMaterial).color.set(MOVE_VERTICAL_COLOR_INACTIVE);
				break;
			case 'moveVertical':
				((this._moveAwayGizmo.children[0] as THREE.Mesh).material as THREE.LineBasicMaterial).color.set(MOVE_AWAY_COLOR_INACTIVE);
				((this._moveAroundGizmo.children[0] as THREE.Mesh).material as THREE.LineBasicMaterial).color.set(MOVE_AROUND_COLOR_INACTIVE);
				((this._moveAroundGizmo.children[2] as THREE.Mesh).material as THREE.MeshBasicMaterial).color.set(MOVE_AROUND_COLOR_INACTIVE);
				((this._moveAroundGizmo.children[3] as THREE.Mesh).material as THREE.MeshBasicMaterial).color.set(MOVE_AROUND_COLOR_INACTIVE);
				((this._verticalGizmo.children[0] as THREE.Mesh).material as THREE.LineBasicMaterial).color.set(MOVE_VERTICAL_COLOR);
				break;
			case null:
				((this._moveAroundGizmo.children[0] as THREE.Mesh).material as THREE.LineBasicMaterial).color.set(MOVE_AROUND_COLOR);
				((this._moveAroundGizmo.children[2] as THREE.Mesh).material as THREE.MeshBasicMaterial).color.set(MOVE_AROUND_COLOR);
				((this._moveAroundGizmo.children[3] as THREE.Mesh).material as THREE.MeshBasicMaterial).color.set(MOVE_AROUND_COLOR);
				((this._moveAwayGizmo.children[0] as THREE.Mesh).material as THREE.LineBasicMaterial).color.set(MOVE_AWAY_COLOR);
				((this._verticalGizmo.children[0] as THREE.Mesh).material as THREE.LineBasicMaterial).color.set(MOVE_VERTICAL_COLOR);
				break;
		}
	};

	private _pointerMoveHandler = (event: PointerEvent) => {
		if (!this._gizmoMode) return;
		event.stopImmediatePropagation();

		switch (this._gizmoMode) {
			case 'moveAway':
				this._moveAwayHandler(event);
				break;
			case 'moveVertical':
				this._moveVerticalHandler(event);
				break;
			case 'moveAround':
				this._moveAroundHandler(event);
				break;
		}
	};

	private _pointerUpHandler = (event: PointerEvent) => {
		this._onPointerUp?.(this._gizmoMode);
		this._domElement.releasePointerCapture(event.pointerId);
		this._gizmoMode = null;
		this._prevAwayPosition = null;
		this._initialAwayPosition = null;
		this._prevVerticalPosition = null;
		this._prevAroundPosition = null;
		this._initialAroundPosition = null;
	};

	//** GIZMO MODE-SPECIFIC FUNCTIONS **//
	private _moveAroundHandler = (event: PointerEvent) => {
		const delta = this._getDelta(event);
		const { newPosition, newRotation } = getMoveAroundTarget(delta, this._entityPosition);
		this._entityPosition.copy(newPosition);
		this._entityRotation.copy(newRotation);
		const data = { newPosition: [newPosition.x, newPosition.y, newPosition.z] as ITuple3, newRotation: [newRotation.x, newRotation.y, newRotation.z] as ITuple3 };
		this._onChange?.('moveAround', data);
		this._repositionGizmo();
	};

	private _moveVerticalHandler = (event: PointerEvent) => {
		const delta = this._getDelta(event);
		this._entityPosition.y = this._entityPosition.y + delta;
		const data = { newPosition: [this._entityPosition.x, this._entityPosition.y, this._entityPosition.z] as ITuple3 };
		this._onChange?.('moveVertical', data);
		this._repositionGizmo();
	};

	private _moveAwayHandler = (event: PointerEvent) => {
		const delta = this._getDelta(event);
		const { newPosition, newCurvature } = getMoveFromTarget(delta, this._entityPosition, this._entityWidth);
		this._radius = getRadiusFromCurvature(newCurvature, this._entityWidth);
		this._entityPosition = newPosition;
		const data = { newPosition: [newPosition.x, newPosition.y, newPosition.z] as ITuple3, newCurvature };
		this._onChange?.('moveAway', data);
		this._repositionGizmo();
	};

	//** UTIL FUNCTONS **//
	private _getGizmoMode = (intersectionArray: THREE.Intersection[]): IGizmoMode => {
		if (intersectionArray.length == 0) return null;
		// Loop over intersection array and return key of first gizmo intersection
		for (let i = 0; i < intersectionArray.length; i++) {
			const intersection = intersectionArray[i];
			if ('gizmoKey' in intersection.object.userData) {
				return intersection.object.userData['gizmoKey'];
			}
		}
		return null;
	};

	private _getDelta = (event: PointerEvent) => {
		const allIntersections = this.getCanvasNormalisedIntersectionsFromPxCoords(event.clientX, event.clientY).filter((intersection) => {
			if ((intersection.object as THREE.Mesh).geometry && (intersection.object as THREE.Mesh).geometry.type == 'PlaneGeometry') return true;
			return false;
		});

		const intersectionPoint = allIntersections[0].point;
		if (!intersectionPoint) return 0;
		let delta = 0;

		switch (this._gizmoMode) {
			case 'moveAway':
				if (!this._prevAwayPosition) {
					this._initialAwayPosition = intersectionPoint;
					this._prevAwayPosition = intersectionPoint;
				} else if (this._initialAwayPosition) {
					// Are we dragging towards the middle or away
					const initialDistanceToOrigin = this._prevAwayPosition.distanceTo(this._origin);
					const currentDistanceToOrigin = intersectionPoint.distanceTo(this._origin);
					const directionMultiplier = initialDistanceToOrigin > currentDistanceToOrigin ? -1 : 1;

					// Calculate delta
					const distanceToInitialDragPosition = intersectionPoint.distanceTo(this._initialAwayPosition);
					const distanceToInitialDragPositionPrev = this._prevAwayPosition.distanceTo(this._initialAwayPosition);
					delta = Math.abs((distanceToInitialDragPosition - distanceToInitialDragPositionPrev) * this._dampener) * directionMultiplier;
					this._prevAwayPosition = intersectionPoint;
				}
				break;
			case 'moveAround':
				if (!this._prevAroundPosition) {
					this._initialAroundPosition = intersectionPoint;
					this._prevAroundPosition = intersectionPoint;
				} else if (this._initialAroundPosition) {
					const directionMultiplier = intersectionPoint.x > this._initialAroundPosition.x ? 1 : -1;
					const distanceToInitialDragPosition = intersectionPoint.distanceTo(this._initialAroundPosition);
					const distanceToInitialDragPositionPrev = this._prevAroundPosition.distanceTo(this._initialAroundPosition);
					delta = (distanceToInitialDragPosition - distanceToInitialDragPositionPrev) * this._dampener * directionMultiplier;
					this._prevAroundPosition = intersectionPoint;
				}
				break;
			case 'moveVertical':
				if (!this._prevVerticalPosition) {
					this._prevVerticalPosition = this._useZForVerticalAxisDrag ? intersectionPoint.z : intersectionPoint.y;
				} else {
					const directionMultiplier = this._useZForVerticalAxisDrag ? -1 : 1;
					delta = ((this._useZForVerticalAxisDrag ? intersectionPoint.z : intersectionPoint.y) - this._prevVerticalPosition) * directionMultiplier;
					this._prevVerticalPosition = (this._useZForVerticalAxisDrag ? intersectionPoint.z : intersectionPoint.y);
				}
				break;
		}
		return delta;
	};

	//** ADD GIZMO FUNCTIONS **/
	private _addHotspot = () => {
		// Materials, geometries
		const planeMaterial = new THREE.MeshBasicMaterial({ color: 'green', transparent: true, opacity: 0.5, visible: false, depthTest: false, depthWrite: false });
		const planeGeometry = new THREE.PlaneGeometry(100, 100, 1, 1);

		// Mesh
		const planeMesh = new THREE.Mesh(planeGeometry, planeMaterial);
		// planeMesh.renderOrder = Infinity;
		this._hotspot.add(planeMesh);
		this._hotspot.lookAt(this._camera.position);
		this._group.add(this._hotspot);
		this._hotspot.position.copy(this._entityPosition);
	};

	private _addMoveAwayGizmo = () => {
		// Materials, geometries
		const gizmoLineMaterial = this._gizmoBaseMaterial.clone();
		const arrowGeometry = new THREE.CylinderGeometry(0, 0.04, 0.1, 12);
		const lineGeometry2 = new THREE.CylinderGeometry(0.0075, 0.0075, 0.5, 3);
		const pointerGeometry = new THREE.CylinderGeometry(0.08, 0.04, 0.6, 12, 1);
		const pointerMaterial = this._invisibleMaterial.clone();

		// Adjustments
		gizmoLineMaterial.color.set(MOVE_AWAY_COLOR);
		arrowGeometry.translate(0, 0.5, 0);
		lineGeometry2.translate(0, 0.25, 0);
		pointerGeometry.translate(0, 0.3, 0);

		// Meshes
		const awayGizmoLine = new THREE.Mesh(lineGeometry2, gizmoLineMaterial);
		const awayGizmoArrow = new THREE.Mesh(arrowGeometry, gizmoLineMaterial);
		const awayGizmoPointer = new THREE.Mesh(pointerGeometry, pointerMaterial);
		awayGizmoArrow.userData.gizmoKey = 'moveAway';
		awayGizmoLine.userData.gizmoKey = 'moveAway';
		awayGizmoPointer.userData.gizmoKey = 'moveAway';

		// Group
		this._moveAwayGizmo.add(awayGizmoArrow, awayGizmoLine, awayGizmoPointer);
		this._moveAwayGizmo.renderOrder = Infinity;
		this._group.add(this._moveAwayGizmo);
		this._positionMoveAwayGizmo();
	};

	private _addMoveVerticalGizmo = () => {
		// Materials, geometries
		const gizmoLineMaterial = this._gizmoBaseMaterial.clone();
		const arrowGeometry = new THREE.CylinderGeometry(0, 0.04, 0.1, 12);
		const lineGeometry2 = new THREE.CylinderGeometry(0.0075, 0.0075, 0.5, 3);
		const pointerGeometry = new THREE.CylinderGeometry(0.08, 0.04, 0.6, 12, 1);
		const pointerMaterial = this._invisibleMaterial.clone();

		// Adjustments
		gizmoLineMaterial.color.set(MOVE_VERTICAL_COLOR);
		arrowGeometry.translate(0, 0.5, 0);
		lineGeometry2.translate(0, 0.25, 0);
		pointerGeometry.translate(0, 0.3, 0);

		// Meshes
		const lineMesh = new THREE.Mesh(lineGeometry2, gizmoLineMaterial);
		const arrowMesh = new THREE.Mesh(arrowGeometry, gizmoLineMaterial);
		const verticalGizmoPointer = new THREE.Mesh(pointerGeometry, pointerMaterial);
		arrowMesh.userData.gizmoKey = 'moveVertical';
		lineMesh.userData.gizmoKey = 'moveVertical';
		verticalGizmoPointer.userData.gizmoKey = 'moveVertical';

		// Group
		this._verticalGizmo.add(lineMesh, arrowMesh, verticalGizmoPointer);
		this._verticalGizmo.renderOrder = Infinity;
		this._group.add(this._verticalGizmo);
		this._positionMoveVerticalGizmo();
	};

	private _newMoveAroundPointerGeometry = () => {
		const geom = new THREE.TorusGeometry(this._radius, 0.05, 12, 48, 1 / this._radius);
		geom.rotateX(Math.PI / 2);
		const centerOffset = 1.0 / this._radius / 2;
		geom.rotateY(-Math.PI / 2 + centerOffset);
		geom.translate(0, this._initialEntityVerticalPosition, 0);
		return geom;
	};
	private _newHelperCircleGeometry = () => {
		const geom = new THREE.TorusGeometry(this._radius, 0.025, 12, 48);
		geom.rotateX(Math.PI / 2);
		const centerOffset = 1.0 / this._radius / 2;
		geom.rotateY(-Math.PI / 2 + centerOffset);
		geom.translate(0, this._initialEntityVerticalPosition, 0);
		return geom;
	};

	private _newMoveAroundGeometry = () => {
		const geom = new THREE.TorusGeometry(this._radius, 0.01, 12, 48, 1 / this._radius); // 1 is fixed length of red curvedline}
		geom.rotateX(Math.PI / 2);
		const centerOffset = 1.0 / this._radius / 2;
		geom.rotateY(-Math.PI / 2 + centerOffset);
		geom.translate(0, this._initialEntityVerticalPosition, 0);
		return geom;
	};

	private _newMoveAroundArrow1Geometry = () => {
		const geom = new THREE.CylinderGeometry(0, 0.04, 0.1, 12);
		const theta = 1.0 / this._radius + Math.PI / 2;
		const centerOffset = 1.0 / this._radius / 2;
		const arrowX = this._radius * Math.sin(theta - centerOffset);
		const arrowZ = this._radius * Math.cos(theta - centerOffset);
		geom.rotateX(Math.PI / 2);
		geom.rotateY(1.5 * Math.PI - centerOffset * 1.5);
		geom.translate(arrowZ, this._initialEntityVerticalPosition, arrowX);
		return geom;
	};
	private _newMoveAroundArrow2Geometry = () => {
		const geom = new THREE.CylinderGeometry(0, 0.04, 0.1, 12);
		const theta = 0;
		const centerOffset = 1.0 / this._radius / 2;
		const arrowX = this._radius * Math.sin(theta - centerOffset);
		const arrowZ = this._radius * Math.cos(theta - centerOffset);
		geom.rotateX(Math.PI / 2);
		geom.rotateY(Math.PI / 2 + centerOffset * 1.5);
		geom.translate(-arrowX, this._initialEntityVerticalPosition, arrowZ);
		return geom;
	};

	private _addMoveAroundGizmo = () => {
		// Materials, geometries
		const gizmoLineMaterial = this._gizmoBaseMaterial.clone();
		const torusGeom = this._newMoveAroundGeometry();
		const arrowGeometry = this._newMoveAroundArrow1Geometry();
		const arrowGeometry2 = this._newMoveAroundArrow2Geometry();
		const pointerGeometry = this._newMoveAroundPointerGeometry();
		const pointerMaterial = this._invisibleMaterial.clone();

		// Adjustments
		gizmoLineMaterial.color.set(MOVE_AROUND_COLOR);

		// Meshes
		const aroundGizmo = new THREE.Mesh(torusGeom, gizmoLineMaterial);
		const aroundPointer = new THREE.Mesh(pointerGeometry, pointerMaterial);
		const arrowMesh = new THREE.Mesh(arrowGeometry, gizmoLineMaterial);
		const arrowMesh2 = new THREE.Mesh(arrowGeometry2, gizmoLineMaterial);
		aroundGizmo.userData.gizmoKey = 'moveAround';
		aroundPointer.userData.gizmoKey = 'moveAround';

		// Group
		this._moveAroundGizmo.add(aroundGizmo, aroundPointer, arrowMesh, arrowMesh2);
		this._moveAroundGizmo.renderOrder = Infinity;
		this._group.add(this._moveAroundGizmo);
		this._positionMoveAroundGizmo();
	};

	private _addHelperCircle = () => {
		// Materials, geometries
		const circleGeom = this._newHelperCircleGeometry();
		const circleMaterial = new THREE.LineBasicMaterial({ color: HELPER_CIRCLE_COLOR, linewidth: 1 });

		// Mesh
		const circleMesh = new THREE.Mesh(circleGeom, circleMaterial);

		// Group
		this._helperCircle.add(circleMesh);
		// this._helperCircle.renderOrder = Infinity;
		this._group.add(this._helperCircle);
		this._positionHelperCircle();
	};

	//** RE-POSITION FUNCTIONS **/
	private _repositionGizmo = () => {
		this._positionMoveAroundGizmo();
		this._positionMoveAwayGizmo();
		this._positionMoveVerticalGizmo();
		this._positionHelperCircle();
	};

	private _positionHelperCircle = () => {
		// Update geometry
		((this._helperCircle.children[0] as THREE.Mesh).geometry as THREE.TorusGeometry) = this._newHelperCircleGeometry();

		// Rotation
		this._helperCircle.rotation.set(this._entityRotation.x, this._entityRotation.y, this._entityRotation.z);

		// Position
		const changeY = this._entityPosition.y - this._initialEntityVerticalPosition;
		this._helperCircle.position.set(0, changeY, 0);
		this._helperCircle.updateMatrixWorld(true);
	};

	private _positionMoveVerticalGizmo = () => {
		this._verticalGizmo.position.copy(this._entityPosition);
		this._verticalGizmo.updateMatrixWorld(true);
	};

	private _positionMoveAwayGizmo = () => {
		let theta = Math.atan(this._entityPosition.x / this._entityPosition.z);
		if (this._entityPosition.z < 0) theta = theta + Math.PI;
		this._moveAwayGizmo.rotation.set(Math.PI / 2, 0, -theta);
		this._moveAwayGizmo.position.copy(this._entityPosition);
		this._moveAwayGizmo.updateMatrixWorld(true);
	};

	private _positionMoveAroundGizmo = () => {
		// Update geometry
		((this._moveAroundGizmo.children[0] as THREE.Mesh).geometry as THREE.TorusGeometry) = this._newMoveAroundGeometry(); // torus gizmo
		((this._moveAroundGizmo.children[1] as THREE.Mesh).geometry as THREE.TorusGeometry) = this._newMoveAroundPointerGeometry(); // torus pointer
		((this._moveAroundGizmo.children[2] as THREE.Mesh).geometry as THREE.CylinderGeometry) = this._newMoveAroundArrow1Geometry(); // arrow head
		((this._moveAroundGizmo.children[3] as THREE.Mesh).geometry as THREE.CylinderGeometry) = this._newMoveAroundArrow2Geometry(); // arrow head

		// Rotation
		this._moveAroundGizmo.rotation.set(this._entityRotation.x, this._entityRotation.y, this._entityRotation.z);

		// Position
		const changeY = this._entityPosition.y - this._initialEntityVerticalPosition;
		this._moveAroundGizmo.position.set(0, changeY, 0);
		this._moveAroundGizmo.updateMatrixWorld(true);
	};
}
