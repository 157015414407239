import { useFrame } from '@react-three/fiber';
import React, {
	FunctionComponent,
	useMemo,
	memo,
	useRef,
} from 'react';
import { Group, Intersection, Object3D, Raycaster } from 'three';
import { ITuple3 } from '../component-data-structure';
import { useSRGBColors } from '../hooks/useSRGBColors';
import SpinnerSvg from './assets/spinner.svg';
import Svg from './Svg/Svg';

interface IParentProps {
	scale: ITuple3;
	opacity: number;
	position?: ITuple3;
	color?: string;
	rotation?: ITuple3;
	renderOrder?: number;
	radiansPerFrame?: number;
	depthWrite?: boolean;
	raycast?: (_: Raycaster, intersects: Intersection<Object3D<Event>>[]) => void
}

const Spinner: FunctionComponent<IParentProps> = ({
	scale,
	opacity = 1,
	position = [0, 0, 0],
	rotation = [0, 0, 0],
	color: c = '#FFFFFF',
	radiansPerFrame = 0.06,
	renderOrder,
	depthWrite = false,
	raycast
}) => {
	const groupRef = useRef<Group>(null);
	const zeroPos = useMemo(() => [0, 0, 0] as ITuple3, []);
	const color = useSRGBColors(c);

	useFrame(() => {
		if (!groupRef?.current?.rotation) return;
		groupRef.current.rotation.z += radiansPerFrame;
	});

	return (
		<group
			key={'spinner_group'}
			ref={groupRef}
			position={position as ITuple3}
			rotation={rotation as ITuple3}
			raycast={raycast}
			// renderOrder={renderOrder}
		>
			<Svg
				key={'spinner'}
				name={''}
				raycast={raycast}
				color={color}
				svgUrl={SpinnerSvg}
				opacity={opacity}
				position={zeroPos}
				scale={scale}
				renderOrder={renderOrder ? renderOrder + 5 : undefined}
				depthWrite={depthWrite}
			/>
		</group>
	);
};

export default memo(Spinner);
