import React from "react";
import { Color, DoubleSide } from "three";
import { COLORS } from "../../../utils";
import { ITuple3 } from "../r3f-components/component-data-structure";
import ShadowPlane from "../r3f-components/components/ShadowPlane";

interface IParentProps {
  size?: number;
  divisions?: number;
  rotation?: ITuple3;
  position?: ITuple3;
  color?: string;
}

const Grid: React.FunctionComponent<IParentProps> = ({
  size = 10,
  divisions = 10,
  rotation = [0, 0, 0],
  position = [0, 0, 0],
  color: c = COLORS.ocean,
}) => {

  const color = new Color(c).convertLinearToSRGB();

  return (
    <>
      <mesh position={position} rotation={[Math.PI/2, 0, 0]}>
        <circleGeometry args={[0.3,20]} />
        <meshBasicMaterial color={COLORS.ocean} opacity={0.5} transparent={true} side={DoubleSide} />
      </mesh>
      <gridHelper args={[size, divisions, color, color]} rotation={rotation} position={position} />
      <ShadowPlane rotation={[-Math.PI / 2, 0, 0]} occlusion />
    </>
  );
};

export default Grid;
