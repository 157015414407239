import React from 'react';
import { IIconParentProps } from './index';
import { COLORS } from '../../utils';

const noEffectIcon: React.FunctionComponent<IIconParentProps> = ({
	className,
    hexFill: fill,
    secondHexFill: secondFill, 
	viewBox,
	onClick = () => {},
}) => {
    const hexFill = fill || COLORS.midnight;
    const secondHexFill = secondFill; 
	return (
		<svg
			onClick={onClick}
			className={className || ''}
			width="35px"
			height="53px"
			viewBox={viewBox ? viewBox.join(' ') : '0 0 35 53'}
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
		>
			<g id="Inspect-menu-/-Image" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Image-/-Inspector-/-Transitions-A" transform="translate(-38.000000, -292.000000)" fill={hexFill}>
                    <g id="Group" transform="translate(21.000000, 284.000000)">
                        <polygon id="Path-Copy" points="17 23.8148507 31.4328447 8 52 32.0755409 34.4993196 46.875647 43.7572612 61 17.4300323 46.6802808 30.18811 33.1959974"></polygon>
                    </g>
                </g>
            </g>
		</svg>
	);
};

export default noEffectIcon;
