/* eslint-disable @typescript-eslint/no-namespace */
import * as THREE from 'three';
import { shaderMaterial } from '@react-three/drei';
import { ReactThreeFiber } from '@react-three/fiber';
import { ShaderMaterial, VideoTexture, Texture } from 'three';

type IShaderMaterial = ReactThreeFiber.MaterialNode<
	ShaderMaterial,
	typeof ShaderMaterial
>

interface IAlphaMaterial extends IShaderMaterial {
	packedTexture: Texture | VideoTexture;
}

declare global {	
	namespace JSX {
		interface IntrinsicElements {
			alphaMaterial: IAlphaMaterial;
		}
	}
}

const vertexShader = `
varying vec2 alphaUv;
varying vec2 colorUv;
void main() {
    gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
    colorUv = vec2(uv.x, uv.y * 0.5 + 0.5);
    alphaUv = vec2(uv.x, uv.y * 0.5);
}
`;

const fragmentShader = `
uniform sampler2D packedTexture;
uniform float opacity;
varying vec2 alphaUv;
varying vec2 colorUv;
void main() {
    vec4 color = texture2D(packedTexture, colorUv);
    color.a = texture2D(packedTexture, alphaUv).g * opacity;
    gl_FragColor = color;
}
`;

const AlphaMaterial = shaderMaterial(
	{ packedTexture: new THREE.Texture(), opacity: 1 },
	vertexShader,
	fragmentShader
);

export default AlphaMaterial;
