import React from "react";
import { IIconParentProps } from "./index";
import { COLORS } from "../../utils";

const Message16: React.FunctionComponent<IIconParentProps> = ({ width, height, className, style, hexFill = COLORS.midnight, viewBox, onClick = () => {} }) => {
  return (
    <svg
      style={style}
      onClick={onClick}
      className={className || ""}
      width={width ? width : "16px"}
      height={height ? height : "16px"}
      viewBox={viewBox ? viewBox.join(" ") : `0 0 16 16`}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <path
        d="M13.6 0.799988C14.9255 0.799988 16 1.8745 16 3.19999V9.59999C16 10.9255 14.9255 12 13.6 12H6.4L2.4 15.2V12C1.07452 12 0 10.9255 0 9.59999V3.19999C0 1.8745 1.07452 0.799988 2.4 0.799988H13.6ZM13.6 1.59999H2.4C1.55651 1.59999 0.865468 2.25269 0.804389 3.08058L0.8 3.19999V9.59999C0.8 10.4435 1.4527 11.1345 2.28059 11.1956L2.4 11.2C2.81027 11.2 3.14841 11.5088 3.19462 11.9067L3.2 12V13.5344L5.90024 11.3753C6.01372 11.2845 6.14922 11.2268 6.29189 11.2073L6.4 11.2H13.6C14.4435 11.2 15.1345 10.5473 15.1956 9.7194L15.2 9.59999V3.19999C15.2 2.3565 14.5473 1.66546 13.7194 1.60438L13.6 1.59999Z"
        fill={hexFill}
      />
    </svg>
  );
};

export default Message16;
