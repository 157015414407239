import { useFrame, useThree } from '@react-three/fiber';
import React, { memo, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { OrthographicCamera, Scene, WebGLRenderer } from 'three';
import { IDesignerState } from '../../../../../typings';
import { ISceneComp, IComponentType, IScreenContent } from '../../r3f-components/component-data-structure';
import { screenRelativeCameraNeedsUpdate, updateScreenRelativeCameraProperties } from '../../r3f-components/utils/screen-relative';
import AnchorGroups from './AnchorGroups/AnchorGroups';

export let screenRelativeScene: Scene;
export let screenRelativeRenderer: WebGLRenderer;

const ScreenRelativeContent: React.FunctionComponent = () => {
	const activeSceneAnchorGroupCount = useSelector((state: IDesignerState) => {
		const componentsById = state.contentReducer.contentDoc.componentsById;
		const activeSceneId = state.userReducer.activeSceneId;
		if (!activeSceneId) return;
		const screenContent = componentsById[(componentsById[activeSceneId] as ISceneComp).children.filter((id) => componentsById[id].type === IComponentType.ScreenContent)[0]] as IScreenContent;
		return screenContent.children.length;
    });
    
	// ensure that anchor groups are on the canvas edges
	useFrame(({ camera, size }) => {
		if (camera.type === 'OrthographicCamera' && screenRelativeCameraNeedsUpdate(camera as OrthographicCamera, size)) {
			updateScreenRelativeCameraProperties(camera as OrthographicCamera, size);
			camera.updateProjectionMatrix();
			// https://github.com/pmndrs/react-three-fiber/issues/178
			// Update matrix world since the renderer is a frame late
			camera.updateMatrixWorld();
		}
    });
    
	const { scene, gl } = useThree();

    useEffect(() => {
        screenRelativeScene = scene;
        screenRelativeRenderer = gl;
	}, [activeSceneAnchorGroupCount]);

	return <AnchorGroups />;
};

export default memo(ScreenRelativeContent);
