import React from "react";
import { IIconParentProps } from "./index";
import { COLORS } from "../../utils";

const hourglassIcon: React.SFC<IIconParentProps> = ({
  hexFill: fill,
  viewBox,
  className,
  onClick = () => {},
  title,
}) => {
  const hexFill = fill || COLORS.midnight;
  return (
    <svg
      width="14"
      height="18"
      viewBox="0 0 14 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_13:42)">
        <path d="M0.5 0.500038H12.936" stroke={hexFill} strokeLinecap="round" />
        <path d="M0.5 16.955H12.936" stroke={hexFill} strokeLinecap="round" />
        <path
          d="M3.06982 0.538979V6.30399L5.33105 8.68998L3.06982 10.673V16.54"
          stroke={hexFill}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6.5 7.939L4.5 5.939H8.5L6.5 7.939Z"
          fill={hexFill}
          stroke={hexFill}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9.93304 0.538979V6.30399L7.67181 8.68998L9.93304 10.673V16.54"
          stroke={hexFill}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6.5 11.939C6.77614 11.939 7 11.7151 7 11.439C7 11.1629 6.77614 10.939 6.5 10.939C6.22386 10.939 6 11.1629 6 11.439C6 11.7151 6.22386 11.939 6.5 11.939Z"
          fill={hexFill}
        />
        <path
          d="M6.5 13.939C6.77614 13.939 7 13.7151 7 13.439C7 13.1629 6.77614 12.939 6.5 12.939C6.22386 12.939 6 13.1629 6 13.439C6 13.7151 6.22386 13.939 6.5 13.939Z"
          fill={hexFill}
        />
        <path
          d="M6.5 15.939C6.77614 15.939 7 15.7151 7 15.439C7 15.1629 6.77614 14.939 6.5 14.939C6.22386 14.939 6 15.1629 6 15.439C6 15.7151 6.22386 15.939 6.5 15.939Z"
          fill={hexFill}
        />
      </g>
      <defs>
        <clipPath id="clip0_13:42">
          <rect width="13.436" height="17.455" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default hourglassIcon;
