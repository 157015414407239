import React, { memo, useEffect, useMemo } from 'react';
import { IEmitterReactProps, ITuple3 } from '../../../component-data-structure';
import { Emitter } from '../../../threejs-particle-system/src/v1/core/Emitter';
import EmitterGroupWrapper from '../r3f-wrapper/EmitterGroupWrapper';
import { convertDocToThreeEmitterGroupSettings, convertDocToThreeEmitterSettings } from '../utils';

const BasicSingleEmitter: React.FunctionComponent<IEmitterReactProps> = ({ 
    id,
    emitter: emSettings, // Constructor props
    emitterGroup: emGroupSettings, // Constructor props
    isPlaying,
    duration,
    runCounter,
    isStatic,
    scale = [1, 1, 1],
    position = [0, 0, 0],
    rotation = [0, 0, 0],
    renderOrder,
    onFinish
}) => {

    // Here we are constructing the emitter and emitterGroup based on their settings
    // We want to do this only once for performance reasons and modify their behaviour
    // with changes to runtime props (e.g. duration, isPlaying, autoplay)
    const { emitterGroupSettings, emitterSettings } = useMemo(() => {
        const emitterSettings = convertDocToThreeEmitterSettings(emSettings);
        const emitterGroupSettings = convertDocToThreeEmitterGroupSettings(emGroupSettings);
        return {emitterSettings, emitterGroupSettings}
    }, []);

    let emitter: Emitter | undefined = useMemo(() => new Emitter(emitterSettings), [emitterSettings])

    // Make sure GC frees emitter memory on unmount
    useEffect(() => {
        return () => { emitter = undefined }
    }, [])

    // Call onFinish once emitter has finished playing
    useEffect(() => {
        if (!emitter) return;
        emitter.particlesPerSecond = emitter.particleCount / emitter.maxAge.value;
        emitter.onFinish(onFinish);
    }, [emitter, onFinish])

    //// Start runtime prop logic ////
    useEffect(() => {
        if (typeof isStatic === 'undefined' || typeof emitter === 'undefined') return;
        emitter.isStatic = isStatic;
    })

   useEffect(() => {
        if (typeof duration === 'undefined' || typeof emitter === 'undefined') return;
        emitter.duration = duration;
    }, [duration, emitter, runCounter])

    // Enable and disable emitter on play state changes
    useEffect(() => {
        if (!emitter) return;
        if (isPlaying) {
            emitter?.reset(true)
            emitter?.enable();
            return;
        } 
        emitter.reset();
    }, [isPlaying, emitter, runCounter])

    return (
        <EmitterGroupWrapper 
            args={[emitterGroupSettings]} 
            id={id}
            position={position as ITuple3}
            rotation={rotation as ITuple3}
            scale={scale as ITuple3}
            renderOrder={renderOrder}
        >
            {emitter}
        </EmitterGroupWrapper>
    );
}
export default memo(BasicSingleEmitter);

