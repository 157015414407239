import { ITuple3 } from '../../r3f/r3f-components/component-data-structure';

export enum IMarkerType {
	topLeft = 'topLeft',
	topCenter = 'topCenter',
	topRight = 'topRight',
	bottomRight = 'bottomRight',
	bottomCenter = 'bottomCenter',
	bottomLeft = 'bottomLeft',
	leftCenter = 'leftCenter',
	rightCenter = 'rightCenter',
	rotation = 'rotation',
}

export interface IMarker {
	pos: ITuple3;
	type: IMarkerType;
}
