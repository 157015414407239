import React, { FunctionComponent } from 'react';
import { IIconParentProps } from './index';
import { COLORS } from '../../utils';

const moveToFrontIcon: FunctionComponent<IIconParentProps> = ({
	className,
	hexFill: fill,
	viewBox,
	onClick = () => {},
	title, 
}) => {
	const hexFill = fill || COLORS.midnight;
	return (
		<svg
			onClick={onClick}
			className={className || ''}
			width="32px"
			height="32px"
			viewBox={viewBox ? viewBox.join(' ') : '0 0 32 32'}
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
		>
			<title>{title}</title>
			  <path fill={hexFill} d="M6.5,25 C6.22385763,25 6,24.7761424 6,24.5 C6,24.2545401 6.17687516,24.0503916 6.41012437,24.0080557 L6.5,24 L14.8,24 L11.0609328,20.8841106 C10.8487943,20.7073286 10.8201323,20.3920462 10.9969143,20.1799078 C11.0919112,20.0659115 11.232635,20 11.381025,20 L12.999,20 L13,11 L7,11 C6.44771525,11 6,10.5522847 6,10 L6,7 C6,6.44771525 6.44771525,6 7,6 L25,6 C25.5522847,6 26,6.44771525 26,7 L26,10 C26,10.5522847 25.5522847,11 25,11 L19,11 L19,20 L20.618975,20 C20.8951174,20 21.118975,20.2238576 21.118975,20.5 C21.118975,20.6483899 21.0530635,20.7891137 20.9390672,20.8841106 L17.2,24 L25.5,24 C25.7761424,24 26,24.2238576 26,24.5 C26,24.7454599 25.8231248,24.9496084 25.5898756,24.9919443 L25.5,25 L6.5,25 Z M25,7 L7,7 L7,10 L14.0001111,10 L13.9988889,21 L12.763,21 L16,23.697 L19.236,21 L18,21 L18,10 L25,10 L25,7 Z M25.25,13 C25.6642136,13 26,13.373096 26,13.8333333 L26,17.1666667 C26,17.626904 25.6642136,18 25.25,18 L20,18 L20,17 L25,17 L25,14 L20,14 L20,13 L25.25,13 Z M12,13 L12,14 L7,14 L7,17 L12,17 L12,18 L6.75,18 C6.33578644,18 6,17.626904 6,17.1666667 L6,13.8333333 C6,13.373096 6.33578644,13 6.75,13 L12,13 Z" transform="matrix(1 0 0 -1 0 31)"/>
		</svg>
	);
};

export default moveToFrontIcon;
