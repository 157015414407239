import { Material, Mesh } from "three";
import { FaceBufferGeometry } from "./facebuffergeometry";
import { FaceMeshLoaderOptions } from "./facemeshloader";

type IMeshShapeType = 'face' | 'head';

interface IFaceMeshLoaderInput {
    faceMeshLoaderOptions?: FaceMeshLoaderOptions;
    material: Material;
    type: IMeshShapeType;
}

interface ICreateGLTFPromiseInput  {
    faceBufferGeometry: FaceBufferGeometry
    material: Material;
    type: IMeshShapeType;
}



const faceBufferGeometryCache: Record<IMeshShapeType, FaceBufferGeometry | undefined>= { 
    ['face']: undefined,
    ['head']: undefined
};
const faceMeshCache: Record<IMeshShapeType, Mesh | undefined> = { 
    ['face']: undefined,
    ['head']: undefined
};
const hasUpdatedDesignTimeCache: Record<IMeshShapeType, boolean> = { 
    ['face']: false,
    ['head']: false
};


const createMeshPromise = async({faceBufferGeometry, material, type}: ICreateGLTFPromiseInput): Promise<void> => {
    // throw load promise which internally sets faceMesh at suspense
    if (!faceBufferGeometry.faceMesh) {
        await faceBufferGeometry._load(type === 'head');
    }
    
    if (!hasUpdatedDesignTimeCache[type]) {
        faceBufferGeometry.updateDesignTime();
    }
    // cache mesh
    faceMeshCache[type] = new Mesh(faceBufferGeometry, material)
}

const useFaceMeshLoader = ({faceMeshLoaderOptions, material, type}: IFaceMeshLoaderInput): Mesh => {
    // if faceMesh already exists (after suspension) return mesh
    if (faceMeshCache[type]) return faceMeshCache[type]!;
    // don't re-create face buffer geometry if already created
    if (!faceBufferGeometryCache[type]) faceBufferGeometryCache[type] = new FaceBufferGeometry(faceMeshLoaderOptions);

    throw createMeshPromise({material, faceBufferGeometry: faceBufferGeometryCache[type]!, type});
}

export default useFaceMeshLoader;