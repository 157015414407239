import { IComponentsById, ISceneComp } from "../component-data-structure";

const VIDEO_PREFIX = 'video-360-'

export const return360VideoIdBySceneId = (sceneId: string) => `${VIDEO_PREFIX}${sceneId}`;

export const is360VideoId = (id: string) => id.includes(VIDEO_PREFIX);

export const returnSceneIdBy360VideoId = (videoId: string) => videoId.replace(VIDEO_PREFIX, '');

export const get360VideoIdBySceneId = (sceneId: string, componentsById: IComponentsById | null): string | null => {
    if (!componentsById) return null;
    const scene = componentsById[sceneId] as ISceneComp;
    return scene.content360?.type === 'video' ? return360VideoIdBySceneId(sceneId) : null;
}