import React, { memo } from 'react';
import { IEmitterCategory, IEmitterReactProps, ITuple3 } from '../../../component-data-structure';
import BasicSingleEmitter from '../emitterVariations/BasicSingleEmitter';
import OcclusionCoreAdditiveSingleEmitter from '../emitterVariations/OcclusionCoreAdditiveSingleEmitter';

const Emitter: React.FunctionComponent<IEmitterReactProps & {category: IEmitterCategory}> = ({ 
    isPlaying, 
    runCounter, 
    category,
    duration,  
    isStatic,
    id, 
    emitter, 
    emitterGroup,
    position = [0, 0, 0], 
    rotation = [0, 0, 0], 
    scale, 
    renderOrder,
    onFinish
}) => {

    return (
        <group name={id}>
            <group name={`inner_${id}`} >
            {category !== 'fire' ? 
                    <BasicSingleEmitter 
                        id={id}
                        emitterGroup={emitterGroup}
                        runCounter={runCounter}
                        duration={duration}
                        isPlaying={isPlaying}
                        isStatic={isStatic}
                        emitter={emitter}
                        position={position as ITuple3}
                        rotation={rotation as ITuple3}
                        scale={scale as ITuple3}
                        renderOrder={renderOrder}
                        onFinish={onFinish}
                    />  : 
                    <OcclusionCoreAdditiveSingleEmitter
                        id={id}
                        emitterGroup={emitterGroup}
                        emitter={emitter}
                        duration={duration}
                        runCounter={runCounter}
                        isPlaying={isPlaying}
                        isStatic={isStatic}
                        position={position as ITuple3}
                        rotation={rotation as ITuple3}
                        scale={scale as ITuple3}
                        renderOrder={renderOrder}
                        onFinish={onFinish}
                    />
                }
                </group>
        </group>
    );
}
export default memo(Emitter);

