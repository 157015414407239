import React from "react";
import { IIconParentProps } from "./index";
import { COLORS } from "../../utils";

const Expand16: React.FunctionComponent<IIconParentProps> = ({ width, height, className, hexFill = COLORS.midnight, viewBox, onClick = () => {} }) => {
  return (
    <svg
      onClick={onClick}
      className={className || ""}
      width={width ? width : "16px"}
      height={height ? height : "16px"}
      viewBox={viewBox ? viewBox.join(" ") : `0 0 16 16`}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g clipPath="url(#clip0_1953_9522)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.14173 0C5.36275 0 5.55713 0.14506 5.5938 0.335118L5.6 0.4L5.59262 0.470199C5.55866 0.630496 5.41116 0.762777 5.2241 0.793343L5.14173 0.8H1.4288L4.68149 4.05179C4.78154 4.0182 4.88864 4 5 4H11C11.5523 4 12 4.44772 12 5V11C12 11.1117 11.9817 11.219 11.9479 11.3193L15.2 14.5712V10.8583C15.2 10.6372 15.3451 10.4429 15.5351 10.4062L15.6 10.4L15.6702 10.4074C15.8305 10.4413 15.9628 10.5888 15.9933 10.7759L16 10.8583V15.5417L15.9987 15.5775L16 15.6L15.9926 15.6702C15.9769 15.7445 15.9367 15.8129 15.8795 15.8678C15.8215 15.9325 15.7469 15.9783 15.6638 15.994L15.6 16L15.5814 15.9993L15.5668 15.9999L15.5417 16H10.8583C10.6372 16 10.4429 15.8549 10.4062 15.6649L10.4 15.6L10.4074 15.5298C10.4413 15.3695 10.5888 15.2372 10.7759 15.2067L10.8583 15.2H14.5712L11.3193 11.9479C11.219 11.9817 11.1117 12 11 12H5C4.44772 12 4 11.5523 4 11V5C4 4.88864 4.0182 4.78154 4.05179 4.68149L0.8 1.4288V5.14173C0.8 5.36275 0.65494 5.55713 0.464882 5.5938L0.4 5.6L0.329801 5.59262C0.169504 5.55866 0.037223 5.41116 0.00665724 5.2241L0 5.14173L0.000277122 0.441543L0.001102 0.42493L0 0.4L0.00738337 0.329801C0.0231294 0.255461 0.0632975 0.187146 0.120926 0.132731C0.178581 0.0676298 0.253122 0.0217427 0.336211 0.00599187L0.4 0L5.14173 0ZM11.2 4.8H4.8V11.2H11.2V4.8Z"
          fill={hexFill}
        />
      </g>
      <defs>
        <clipPath id="clip0_1953_9522">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Expand16;
