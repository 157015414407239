import React from 'react';
import { IIconParentProps } from './index';
import { COLORS } from '../../utils';

const codeIcon: React.SFC<IIconParentProps> = ({
	className,
	style,
	hexFill: fill,
	viewBox,
	width,
	height,
	onClick = () => {},
}) => {
	const hexFill = fill || COLORS.midnight;
	return (
		<svg
			onClick={onClick}
			style={style}
			className={className || ''}
			width={width || "40px"}
			height={height || "40px"}
			viewBox={viewBox ? viewBox.join(' ') : '0 0 40 40'}
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
		>
			<path d="M14.3169837,23.2403487 L14.3892872,23.294337 L19.6288982,27.9906667 L24.7165903,23.2991122 C24.9195851,23.1119017 25.2359089,23.1246974 25.4231193,23.3276922 C25.5895286,23.5081319 25.5979083,23.7781156 25.4551464,23.9673682 L25.3945393,24.0342211 L19.6387965,29.3424141 L13.7218425,24.0389963 C13.5162105,23.8546866 13.4989254,23.5385763 13.6832352,23.3329443 C13.8470661,23.1501603 14.1150356,23.1161936 14.3169837,23.2403487 Z M17.1903444,16.7312076 L17.2645459,16.782556 L23.2645459,21.782556 C23.4766844,21.9593381 23.5053464,22.2746204 23.3285644,22.4867589 C23.1714248,22.6753264 22.9048528,22.7189249 22.698563,22.6021257 L22.6243615,22.5507773 L16.6243615,17.5507773 C16.4122231,17.3739953 16.383561,17.0587129 16.5603431,16.8465745 C16.7174827,16.658007 16.9840546,16.6144085 17.1903444,16.7312076 Z M19.4723311,10.1111065 L25.3892851,15.4145242 C25.5949171,15.598834 25.6122022,15.9149442 25.4278924,16.1205763 C25.2640615,16.3033603 24.996092,16.3373269 24.7941438,16.2131719 L24.7218404,16.1591836 L19.4832294,11.4632983 L14.3945372,16.1544084 C14.1915425,16.3416188 13.8752187,16.3288231 13.6880083,16.1258284 C13.521599,15.9453886 13.5132192,15.6754049 13.6559812,15.4861523 L13.7165882,15.4192994 L19.4723311,10.1111065 Z" fill={hexFill} transform="rotate(-90 19.556 19.727)"/>
		</svg>
	);
};

export default codeIcon;