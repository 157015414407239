import Hls, { errorData } from 'hls.js';
import { useMemo, useState } from 'react';
import * as THREE from 'three';
import { addMediaElementToAudioStreamDestination } from '../utils/audio-manager';

interface IVideoConfig {
	muted?: boolean;
	loop?: boolean;
	autoplay?: boolean;
}

export interface IHlsPreloadData extends IVideoConfig {
	url: string;
	id: string;
}

type IHlsTextureData = [THREE.VideoTexture | undefined, HTMLVideoElement | undefined, Hls | undefined];
interface IExtension extends IVideoConfig {
	// onWaiting?: (isWaiting: boolean, e: Event) => any;
	// onEnded?: (video: HTMLVideoElement, e: Event) => any;
	onError?: () => any;
	// onCanPlay?: () => any;
	errorMessage?: string;
	preventVideoLoad?: boolean;
	audioContext?: AudioContext;
	audioStreamDestination?: MediaStreamAudioDestinationNode;
	onLoadedData?: () => unknown;
}

export const autoplayableVideos: HTMLVideoElement[] = []

export const createAutoplayVideoNodes = (): void => {
	for (let index = 0; index < 10; index++) {
		const video = document.createElement('video');
		video.setAttribute('playsinline', 'true');
		video.setAttribute('webkit-playsinline', 'true');
		video.setAttribute('crossorigin', 'anonymous');
		video.crossOrigin = 'anonymous';
		video.muted = true;
		autoplayableVideos.push(video);
	}
};

export const unmuteAutoplayVideos = (): void => {
	autoplayableVideos.forEach((video) => (video.muted = false));
};

// cache for texture data
export const hlsVideoCache: { [id: string]: IHlsTextureData } = {};

let hlsVideoCacheHasBeenUnmuted = false;

export const unmuteVideosInCache = (): void => {
	if (!hlsVideoCacheHasBeenUnmuted) {
		hlsVideoCacheHasBeenUnmuted = true;	
		for (const id in hlsVideoCache) {
			const video = hlsVideoCache[id][1];
			if (video) video.muted = false
		}
	}
}

// cache for texture errors
const errorCache: { [id: string]: Error } = {};

export const useHlsLoader = (
	url: string,
	id: string,
	ext?: IExtension
): IHlsTextureData => {
	const [_, triggerRender] = useState(0);
	// if video loading prevented return early
	if (ext?.preventVideoLoad) return [undefined, undefined, undefined];

	// if async error throw error into render phase (triggered via triggerRender)
	if (errorCache[id]) {
		const error = errorCache[id];
		// delete errorCache[id]; // leads to re-render issue if not commented out
		throw error;
	}

	// use cached data if exists otherwise throw promise
	if (hlsVideoCache[id]) {
		// restart loading when cache accessed if autoplay enabled & hls exists
		if (ext?.autoplay) {
			// if (cache[id][1]) cache[id][1]!.autoplay = true;
			hlsVideoCache[id][2]?.startLoad();
		}
		return hlsVideoCache[id];
	}

	const {
		errorMessage = 'failed to load video',
		loop = false,
		// muted = false,
		autoplay = false,
		onLoadedData
	} = ext || {};

	// set up video
	console.log('useHlsLoader: setup new video texture');
	let video: HTMLVideoElement;
	if (autoplay && autoplayableVideos.length) {
		video = autoplayableVideos[autoplayableVideos.length - 1];
		autoplayableVideos.splice(-1);
	} else {
		video = document.createElement('video');
	}

	video.id = id;
	video.crossOrigin = 'anonymous';
	video.setAttribute('playsinline', 'true');
	video.setAttribute('webkit-playsinline', 'true');

	const setSize = () => {
		video.width =  video.videoWidth;
		video.height = video.videoHeight;
	}
	video.addEventListener('loadeddata', setSize)
	video.addEventListener('loadedmetadata', setSize)
	video.addEventListener('canplaythrough', setSize)

	if (onLoadedData) video.addEventListener('loadeddata', onLoadedData)

	// if (autoplay) video.autoplay = autoplay;
	if (loop) video.loop = loop;
	if (!autoplay) video.muted = true;

	const cacheError = (data?: errorData) => {
		ext?.onError?.();
		if (data) console.error('hls error: ', data);
		errorCache[id] = new Error(`hls error: ${data?.details || errorMessage}`);
		(errorCache[id] as any).hlsError = data;
		triggerRender((prevState) => prevState + 1);
	};

	// throw promise for suspense to catch
	throw new Promise<any>((res) => {
		console.log('create promise');
		let hls: Hls | undefined = undefined;
		if (Hls.isSupported() && url.endsWith('m3u8')) {
			hls = new Hls({ autoStartLoad: true });
			hls.startLevel = -1; // automatic start level selection
	
			hls.on(Hls.Events.ERROR, (e, data) => {
				if (!data.fatal) return;
				cacheError(data);
			});
			hls.once(Hls.Events.MEDIA_ATTACHED, (_name, { media }) => {
				if (ext?.audioContext && ext?.audioStreamDestination) addMediaElementToAudioStreamDestination(ext?.audioContext, ext?.audioStreamDestination, media);
			})
			hls.attachMedia(video);
			hls.loadSource(url);
		} else {
			// } else if (video.canPlayType('application/vnd.apple.mpegurl')) {
			console.info('hls not supported: using only video element');
			video.src = url;
			if (ext?.audioContext && ext?.audioStreamDestination) addMediaElementToAudioStreamDestination(ext?.audioContext, ext?.audioStreamDestination, video);
			video.addEventListener('error', () => cacheError());
		}

		// We need to 'preload' videos until they can play so that "video.play().then(() => video.muted = false)" which
		// runs later on on user interaction and allows to run multiple soundtracks (audio/video) simultaneously works reliably.
		// If videos aren't preloaded video.play() doesn't resolve immediately (async) thus unmuting the video is outside the user interaction flow
		// and violating the safari multiple soundtrack permissions. Autoplay videos have already been preloaded when the video nodes were created and need to be ommitted in
		// the preloading to avoid video sounds becoming audible for a split second (autoplay videos are unmuted on launch)
		if (!autoplay) video.play().then(() => video.pause());
		const videoTexture = new THREE.VideoTexture(video);

		hlsVideoCache[id] = [videoTexture, video, hls];

		// resolve promise when video playable, using loadedmetadata as canplay
		// event not fired in safari
		video.addEventListener('loadedmetadata', res);
	});
};

// access cached hls data
export const useGetHlsCache = (id: string): IHlsTextureData => useMemo(() => hlsVideoCache[id], [id]);

// remove cached hls data
export const useRemoveHlsCache = (id: string): void =>
	useMemo(() => {
		if (hlsVideoCache[id]) delete hlsVideoCache[id];
		if (errorCache[id]) delete errorCache[id];
	}, [id]);

export const clearHlsCacheById = (id: string): void => {
	if (hlsVideoCache[id]) delete hlsVideoCache[id];
	if (errorCache[id]) delete errorCache[id];
}
// export const useHlsPreloader = (hlsPreloadData: IHlsPreloadData[]): Promise<any> => {
// 	let promises: Promise<any>[] = [];
// 	for (let i = 0; i < hlsPreloadData.length; i++) {
// 		const { url, id, muted, loop, autoplay} = hlsPreloadData[i];

// 		let video: HTMLVideoElement;
// 		if (autoplay && autoplayableVideos.length) {
// 			video = autoplayableVideos[autoplayableVideos.length - 1];
// 			autoplayableVideos.splice(-1)
// 		} else {
// 			video = document.createElement('video')
// 		}

// 		video.id = id;
// 		video.crossOrigin = 'anonymous';
// 		video.setAttribute('playsinline', 'true');
// 		video.setAttribute('webkit-playsinline', 'true');
// 		//if (autoplay) video.autoplay = autoplay;
// 		if (loop) video.loop = true;
// 		// if (muted) video.muted = true;

// 		promises.push(new Promise<any>(res => {
// 			let hls: Hls | undefined = undefined;
// 			if (Hls.isSupported()) {
// 				hls = new Hls({ autoStartLoad: true });
// 				hls.startLevel = -1; // automatic start level selection
// 				hls.attachMedia(video);
// 				hls.loadSource(url);
// 				hls.on(Hls.Events.ERROR, (e, data) => {
// 					// // populate error cache and resolve
// 					if (!data.fatal) return;
// 					if (data) console.error('hls error: ', data)
// 					errorCache[id] = new Error(`hls error: ${data?.details || 'failed to load video'}`);
// 					(errorCache[id] as any).hlsError = data;
// 					res(true); // resolves as error saved in cache
// 				}); 
// 			} else if (video.canPlayType('application/vnd.apple.mpegurl')) {
// 				console.info('hls not supported: using only video element');
// 				// populate error cache and resolve
// 				video.src = url;
// 				video.addEventListener('error', () => {
// 					errorCache[id] = new Error('failed to load video');
// 					res(true) // resolves as error saved in cache
// 				});
// 			}
// 			hlsVideoCache[id] = [
// 				new THREE.VideoTexture(video, undefined, undefined, undefined, undefined, undefined, THREE.RGBAFormat),
// 				video, 
// 				hls
// 			];
	
// 			// resolve promise when video playable, using loadedmetadata as canplay
// 			// event not fired in safari
// 			video.addEventListener('loadedmetadata', () => {
// 				hls?.stopLoad?.();
// 				res(true);
// 			});
	
// 		}));
// 	}
// 	return Promise.all(promises)
// }
