import React from 'react';
import { IIconParentProps } from './index';
import { COLORS } from '../../utils';

const linkIcon: React.SFC<IIconParentProps> = ({
	className,
	style,
	hexFill: fill,
	viewBox,
	onClick = () => {},
}) => {
	const hexFill = fill || COLORS.midnight;
	return (
		<svg
			onClick={onClick}
			style={style}
			className={className || ''}
			width="21px"
			height="21px"
			viewBox={viewBox ? viewBox.join(' ') : '0 0 46 46'}
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
		>
			<g fill={hexFill} fillRule="evenodd">
				<path d="M38.986 28.832l-4.803-4.803a1.711 1.711 0 0 0-2.42 2.419l4.804 4.803a5.97 5.97 0 1 1-8.443 8.443l-9.06-9.059a5.967 5.967 0 0 1 3.31-10.12 1.731 1.731 0 1 0-.525-3.423 9.39 9.39 0 0 0-5.203 15.973l9.06 9.06a9.391 9.391 0 1 0 13.28-13.281v-.012z"></path>
				<path d="M8.42 13.863a5.97 5.97 0 1 1 8.443-8.443l9.06 9.059a5.967 5.967 0 0 1-3.31 10.12 1.716 1.716 0 0 0 .263 3.423h.262a9.39 9.39 0 0 0 5.203-15.973l-9.06-9.06A9.391 9.391 0 1 0 6.002 16.27l4.803 4.804a1.711 1.711 0 1 0 2.42-2.42L8.42 13.864z"></path>
			</g>
			
		</svg>
	);
};

export default linkIcon;



