import { ThreeEvent } from '@react-three/fiber';
import { Dispatch } from 'react';
import {
	IOnSetGroupInversion,
} from '../../../../store/actions';
import {
	calcBooleanMarkerIndexVariables,
	// HOTSPOT_SCALE,
	maths,
} from '../../../../utils';
import { IBounds } from '../../../../utils/transforms';
import {
	ITuple3,
	ITuple3Dict,
	IVector3Dict,
} from '../../../r3f/r3f-components/component-data-structure';
const { vec3 } = maths;

export enum scaleTypes {
	allSidedGroupScale = 'allSidedGroupScale',
	oneSidedGroupScale = 'oneSidedGroupScale',
	allSidedUniformEntityScale = 'allSidedUniformEntityScale',
	allSidedNonUniformEntityScale = 'allSidedNonUniformEntityScale',
	oneSidedNonUniformEntityScale = 'oneSidedNonUniformEntityScale',
	oneSidedUniformEntityScale = 'oneSidedUniformEntityScale',
}

export interface ITransientSpatials {
	positionDict?: IVector3Dict,
	scaleDict?: IVector3Dict,
	rotationDict?: IVector3Dict
}

export const calcScaleType = (
	groupSelected: boolean,
	allSidesScale: boolean,
	uniformScale: boolean
) => {
	if (groupSelected && allSidesScale) return scaleTypes.allSidedGroupScale;
	if (groupSelected && !allSidesScale) return scaleTypes.oneSidedGroupScale;
	if (!groupSelected && allSidesScale && uniformScale)
		return scaleTypes.allSidedUniformEntityScale;
	if (!groupSelected && allSidesScale && !uniformScale)
		return scaleTypes.allSidedNonUniformEntityScale;
	if (!groupSelected && !allSidesScale && !uniformScale)
		return scaleTypes.oneSidedNonUniformEntityScale;
	if (!groupSelected && !allSidesScale && uniformScale)
		return scaleTypes.oneSidedUniformEntityScale;
};

////// GROUP SCALING /////////////////

export const oneSidedGroupScale = (
	initialGroupScale: ITuple3,
	initialGroupBoundary: IBounds,
	e: ThreeEvent<PointerEvent>,
	markerIndexPressed: number,
	oppositeMarkerPosition: number,
	onSetGroupInversion: IOnSetGroupInversion,
	selectedEntityIds: string[],
	contentRotationDict: ITuple3Dict,
	contentScaleDict: ITuple3Dict,
	contentPositionDict: ITuple3Dict,
	dispatch: Dispatch<any>
): ITransientSpatials => {
	//const pointerPosition = vec3.multiply(e.localPosition, HOTSPOT_SCALE);
	const { x, y, z } = e.point;

	const {
		hMiddleMarkerPressed,
		topSideMarkerPressed,
		rightSideMarkerPressed,
		bottomSideMarkerPressed,
		leftSideMarkerPressed,
		topCornerPressed,
		bottomCornerPressed,
	} = calcBooleanMarkerIndexVariables(markerIndexPressed);

	// adjust inversion checks & group scale change to one-sided scaling
	const xInverted =
		(rightSideMarkerPressed && x < initialGroupBoundary[0][0]) ||
		(leftSideMarkerPressed && x > initialGroupBoundary[3][0]);

	const yInverted =
		((topCornerPressed || bottomCornerPressed) && xInverted) ||
		(markerIndexPressed === 1 && y < initialGroupBoundary[5][1]) ||
		(markerIndexPressed === 5 && y > initialGroupBoundary[1][1]);

	const currentGroupScale = [
		(x - initialGroupBoundary[oppositeMarkerPosition][0]) / 2,
		(y - initialGroupBoundary[oppositeMarkerPosition][1]) / 2,
		0,
	];

	const groupScaleChangeRatio = [
		currentGroupScale[0] / initialGroupScale[0],
		currentGroupScale[1] / initialGroupScale[1],
		0,
	];

	// enable negative entity scale values if group inverted
	const adjFactor = [
		yInverted && !xInverted && (topSideMarkerPressed || bottomSideMarkerPressed)
			? -1
			: 1,
		xInverted && !yInverted && (leftSideMarkerPressed || rightSideMarkerPressed)
			? -1
			: 1,
	];

	dispatch(onSetGroupInversion({ xInverted, yInverted }));

	const scaleDict: IVector3Dict = {};
	const positionDict: IVector3Dict = {};
	const rotationDict: IVector3Dict = {};

	for (let i = 0; i < selectedEntityIds.length; i++) {
		const id = selectedEntityIds[i];
		let rotation = contentRotationDict[id][2];

		// if group inverted flip/mirror rotation
		if ((xInverted && !yInverted) || (!xInverted && yInverted))
			rotation = 360 - rotation;

		// calculate entity scale factor
		const scale = [
			contentScaleDict[id][0] *
				Math.abs(groupScaleChangeRatio[hMiddleMarkerPressed ? 1 : 0]) *
				(xInverted ? -1 : 1),
			contentScaleDict[id][1] *
				Math.abs(groupScaleChangeRatio[hMiddleMarkerPressed ? 1 : 0]) *
				(yInverted ? -1 : 1),
			contentScaleDict[id][2] * Math.abs(groupScaleChangeRatio[2]),
		];

		const distanceFromBorder = [
			contentPositionDict[id][0] -
				initialGroupBoundary[oppositeMarkerPosition][0],
			contentPositionDict[id][1] -
				initialGroupBoundary[oppositeMarkerPosition][1],
		];

		if (
			leftSideMarkerPressed ||
			(bottomSideMarkerPressed && !rightSideMarkerPressed)
		) {
			distanceFromBorder[0] *= -1;
			distanceFromBorder[1] *= -1;
		}

		const position = [
			initialGroupBoundary[oppositeMarkerPosition][0] +
				distanceFromBorder[0] *
					groupScaleChangeRatio[hMiddleMarkerPressed ? 1 : 0] *
					adjFactor[0],
			initialGroupBoundary[oppositeMarkerPosition][1] +
				distanceFromBorder[1] *
					groupScaleChangeRatio[hMiddleMarkerPressed ? 1 : 0] *
					adjFactor[1],
			0,
		];

		scaleDict[id] = [scale[0], scale[1], 0];
		positionDict[id] = [position[0], position[1], 0];
		rotationDict[id] = [0, 0, rotation];
	}
	return { scaleDict, positionDict, rotationDict};
};

export const allSidedGroupScale = (
	initialGroupScale: ITuple3,
	initialSelectionCenter: ITuple3,
	e: ThreeEvent<PointerEvent>,
	markerIndexPressed: number,
	onSetGroupInversion: IOnSetGroupInversion,
	selectedEntityIds: string[],
	contentRotationDict: ITuple3Dict,
	contentScaleDict: ITuple3Dict,
	contentPositionDict: ITuple3Dict,
	dispatch: Dispatch<any>
): ITransientSpatials => {
	//const pointerPosition = vec3.multiply(e.localPosition, HOTSPOT_SCALE);
	const { x, y, z } = e.point;

	const {
		hMiddleMarkerPressed,
		topSideMarkerPressed,
		rightSideMarkerPressed,
		bottomSideMarkerPressed,
		leftSideMarkerPressed,
		topCornerPressed,
		bottomCornerPressed,
	} = calcBooleanMarkerIndexVariables(markerIndexPressed);

	// check if group is inverted/mirrored and calc % of scale change

	const xInverted =
		(rightSideMarkerPressed && x < initialSelectionCenter[0]) ||
		(leftSideMarkerPressed && x > initialSelectionCenter[0]);

	const yInverted =
		((topCornerPressed || bottomCornerPressed) && xInverted) ||
		(markerIndexPressed === 1 && y < initialSelectionCenter[1]) ||
		(markerIndexPressed === 5 && y > initialSelectionCenter[1]);

	const currentGroupScale = [
		leftSideMarkerPressed
			? initialSelectionCenter[0] - x
			: x - initialSelectionCenter[0],
		bottomSideMarkerPressed
			? initialSelectionCenter[1] - y
			: y - initialSelectionCenter[1],
		z - initialSelectionCenter[2],
	];

	const groupScaleChangeRatio = [
		currentGroupScale[0] / initialGroupScale[0],
		currentGroupScale[1] / initialGroupScale[1],
		0,
	];

	// enable negative entity scale values if group inverted
	const adjFactor = [
		yInverted && !xInverted && (topSideMarkerPressed || bottomSideMarkerPressed)
			? -1
			: 1,
		xInverted && !yInverted && (leftSideMarkerPressed || rightSideMarkerPressed)
			? -1
			: 1,
	];

	dispatch(onSetGroupInversion({ xInverted, yInverted }));

	const scaleDict: IVector3Dict = {};
	const positionDict: IVector3Dict = {};
	const rotationDict: IVector3Dict = {};

	for (let i = 0; i < selectedEntityIds.length; i++) {
		const id = selectedEntityIds[i];
		let rotation = contentRotationDict[id][2];

		// if group inverted flip/mirror rotation
		if ((xInverted && !yInverted) || (!xInverted && yInverted))
			rotation = 360 - rotation;

		// calculate entity scale factor
		const scale = [
			contentScaleDict[id][0] *
				groupScaleChangeRatio[hMiddleMarkerPressed ? 1 : 0] *
				adjFactor[0],
			contentScaleDict[id][1] *
				groupScaleChangeRatio[hMiddleMarkerPressed ? 1 : 0] *
				adjFactor[1],
			contentScaleDict[id][2] * groupScaleChangeRatio[2],
		];

		// let position = contentPositionDict[id];
		const adjGroupCenter: null | ITuple3 = null;

		const groupCenter = adjGroupCenter || initialSelectionCenter;
		if (!groupCenter) return;

		const position = [
			groupCenter[0] +
				(contentPositionDict[id][0] - groupCenter[0]) *
					groupScaleChangeRatio[hMiddleMarkerPressed ? 1 : 0] *
					adjFactor[0],
			groupCenter[1] +
				(contentPositionDict[id][1] - groupCenter[1]) *
					groupScaleChangeRatio[hMiddleMarkerPressed ? 1 : 0] *
					adjFactor[1],
			groupCenter[2] +
				(contentPositionDict[id][2] - groupCenter[2]) *
					groupScaleChangeRatio[2],
		];

		scaleDict[id] = [scale[0], scale[1], 0];
		positionDict[id] = [position[0], position[1], 0];
		rotationDict[id] = [0, 0, rotation];
	}
	return { scaleDict, positionDict, rotationDict};
};

////// INDIVIDUAL ENTITY SCALING /////////////////

export const oneSidedNonUniformEntityScale = (
	selectionRotation: ITuple3,
	selectionCenter: ITuple3,
	markerIndexPressed: number,
	localMarkerPosition: ITuple3,
	localPointerPosition: ITuple3,
	selectionScale: ITuple3,
	selectedEntityIds: string[]
): ITransientSpatials => {
	const {
		vMiddleMarkerPressed,
		hMiddleMarkerPressed,
	} = calcBooleanMarkerIndexVariables(markerIndexPressed);
	const scale = vec3.abs(localPointerPosition);

	const position = maths.localToWorldPosition2d(
		selectionRotation[2],
		[selectionCenter[0], selectionCenter[1]],
		[
			[
				hMiddleMarkerPressed
					? localMarkerPosition[0]
					: localMarkerPosition[0] +
					  (localPointerPosition[0] - localMarkerPosition[0]) / 2,
				vMiddleMarkerPressed
					? localMarkerPosition[1]
					: localMarkerPosition[1] +
					  (localPointerPosition[1] - localMarkerPosition[1]) / 2,
			],
		]
	)[0];

	scale[0] = (localPointerPosition[0] - localMarkerPosition[0]) / 2;
	scale[1] = (localPointerPosition[1] - localMarkerPosition[1]) / 2;
	if (markerIndexPressed > 3 && markerIndexPressed < 7) scale[1] *= -1;
	if (markerIndexPressed === 0 || markerIndexPressed > 5) scale[0] *= -1;
	if (vMiddleMarkerPressed) scale[1] = selectionScale[1];
	if (hMiddleMarkerPressed) scale[0] = selectionScale[0];

	const scaleDict: IVector3Dict = {};
	const positionDict: IVector3Dict = {};

	for (let i = 0; i < selectedEntityIds.length; i++) {
		const id = selectedEntityIds[i];
		scaleDict[id] = [scale[0], scale[1], 0]
		positionDict[id] = [position[0], position[1], 0]
	}
	return { scaleDict, positionDict };
};

export const oneSidedUniformEntityScale = (
	selectionRotation: ITuple3,
	selectionCenter: ITuple3,
	markerIndexPressed: number,
	localMarkerPosition: ITuple3,
	localPointerPosition: ITuple3,
	selectionScale: ITuple3,
	ratio: number,
	selectedEntityIds: string[],
	initialRatio: number
): ITransientSpatials => {
	const {
		vMiddleMarkerPressed,
		hMiddleMarkerPressed,
		bottomSideMarkerPressed,
		rightSideMarkerPressed,
		topSideMarkerPressed,
		leftSideMarkerPressed,
	} = calcBooleanMarkerIndexVariables(markerIndexPressed);
	const scale = vec3.abs(localPointerPosition);

	let position = maths.localToWorldPosition2d(
		selectionRotation[2],
		[selectionCenter[0], selectionCenter[1]],
		[
			[
				hMiddleMarkerPressed
					? localMarkerPosition[0]
					: localMarkerPosition[0] +
					  (localPointerPosition[0] - localMarkerPosition[0]) / 2,
				vMiddleMarkerPressed
					? localMarkerPosition[1]
					: localMarkerPosition[1] +
					  (localPointerPosition[1] - localMarkerPosition[1]) / 2,
			],
		]
	)[0];

	scale[0] = (localPointerPosition[0] - localMarkerPosition[0]) / 2;
	scale[1] = (localPointerPosition[1] - localMarkerPosition[1]) / 2;
	if (markerIndexPressed > 3 && markerIndexPressed < 7) scale[1] *= -1;
	if (markerIndexPressed === 0 || markerIndexPressed > 5) scale[0] *= -1;

	const adjFactor = [
		selectionScale[0] > 0 ? 1 : -1,
		selectionScale[1] > 0 ? 1 : -1,
	];

	if (vMiddleMarkerPressed) {
		ratio = initialRatio || ratio;
		scale[1] = Math.abs(selectionScale[0] * ratio) * adjFactor[1];
	}
	if (hMiddleMarkerPressed) {
		ratio = initialRatio || ratio;
		scale[0] = Math.abs(selectionScale[1] / ratio) * adjFactor[0];
	}

	const isOneAxisInverted = ratio < 0;

	if (
		(bottomSideMarkerPressed && rightSideMarkerPressed) ||
		(topSideMarkerPressed && leftSideMarkerPressed)
	)
		ratio *= -1;

	if (!vMiddleMarkerPressed && !hMiddleMarkerPressed) {
		scale[1] = scale[0] * Math.abs(ratio) * (isOneAxisInverted ? -1 : 1);
		position = maths.localToWorldPosition2d(
			selectionRotation[2],
			[selectionCenter[0], selectionCenter[1]],
			[
				[
					localMarkerPosition[0] +
						(localPointerPosition[0] - localMarkerPosition[0]) / 2,
					localMarkerPosition[1] +
						((localPointerPosition[0] - localMarkerPosition[0]) / 2) * ratio,
				],
			]
		)[0];
	}

	const scaleDict: IVector3Dict = {};
	const positionDict: IVector3Dict = {};

	for (let i = 0; i < selectedEntityIds.length; i++) {
		const id = selectedEntityIds[i];
		scaleDict[id] = [scale[0], scale[1], 0];
		positionDict[id] = [position[0], position[1], 0];
	}
	return { scaleDict, positionDict };
};

export const allSidedNonUniformEntityScale = (
	markerIndexPressed: number,
	localPointerPosition: ITuple3,
	selectionScale: ITuple3,
	selectedEntityIds: string[]
): ITransientSpatials => {
	const {
		vMiddleMarkerPressed,
		hMiddleMarkerPressed,
	} = calcBooleanMarkerIndexVariables(markerIndexPressed);
	const scale = localPointerPosition;
	if (markerIndexPressed > 3 && markerIndexPressed < 7) scale[1] *= -1;
	if (markerIndexPressed === 0 || markerIndexPressed > 5) scale[0] *= -1;
	if (vMiddleMarkerPressed) scale[1] = selectionScale[1];
	if (hMiddleMarkerPressed) scale[0] = selectionScale[0];

	const scaleDict: IVector3Dict = {};

	for (let i = 0; i < selectedEntityIds.length; i++) {
		const id = selectedEntityIds[i];
		scaleDict[id] = [scale[0], scale[1], 0];
	}
	return { scaleDict };
};

export const allSidedUniformEntityScale = (
	markerIndexPressed: number,
	localPointerPosition: ITuple3,
	selectionScale: ITuple3,
	ratio: number,
	selectedEntityIds: string[],
	initialRatio: number
): ITransientSpatials => {
	const {
		vMiddleMarkerPressed,
		hMiddleMarkerPressed,
		bottomSideMarkerPressed,
		rightSideMarkerPressed,
		topSideMarkerPressed,
		leftSideMarkerPressed,
	} = calcBooleanMarkerIndexVariables(markerIndexPressed);
	const scale = localPointerPosition;
	if (markerIndexPressed > 3 && markerIndexPressed < 7) scale[1] *= -1;
	if (markerIndexPressed === 0 || markerIndexPressed > 5) scale[0] *= -1;

	const adjFactor = [
		selectionScale[0] > 0 ? 1 : -1,
		selectionScale[1] > 0 ? 1 : -1,
	];

	if (vMiddleMarkerPressed) {
		ratio = initialRatio || ratio;
		scale[1] = Math.abs(selectionScale[0] * ratio) * adjFactor[1];
	}
	if (hMiddleMarkerPressed) {
		ratio = initialRatio || ratio;
		scale[0] = Math.abs(selectionScale[1] / ratio) * adjFactor[0];
	}
	const isOneAxisInverted = ratio < 0;
	if (
		(bottomSideMarkerPressed && rightSideMarkerPressed) ||
		(topSideMarkerPressed && leftSideMarkerPressed)
	)
		ratio *= -1;
	if (!vMiddleMarkerPressed && !hMiddleMarkerPressed) {
		scale[1] = scale[0] * Math.abs(ratio) * (isOneAxisInverted ? -1 : 1);
	}

	const scaleDict: IVector3Dict = {};

	for (let i = 0; i < selectedEntityIds.length; i++) {
		const id = selectedEntityIds[i];
		scaleDict[id] = [scale[0], scale[1], 0];
	}
	return { scaleDict };
};
