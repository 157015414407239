import { useMemo } from "react";

export const useCloneGLTFMaterials = (object: THREE.Object3D): THREE.Object3D => {
    return useMemo(() => {
        object.traverse((node: any) => {
            if (node?.isMesh || node?.isSkinnedMesh) {
              const materialArray = Array.isArray(node.material)
                ? node.material
                : [node.material];
              for (let i = 0; i < materialArray.length; i++) {
                const material = materialArray[i].clone();
                node.material = material;
                node.frustumCulled = false;
              }
            }
        });
        return object;
    }, [object])
}
