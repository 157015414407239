import { MathUtils } from 'three';
import { IAudio, ILoadedComponentUnion, ILoadedImage, ILoadedModel3d, ILoadedVideo, IMediaLibraryOnSubmitCategory, IModal, IToast, ITransformControlsMode, IUserState } from '../../../typings';
import { IComponentType, INumberDict, IUserData, IVector3Dict, IVector4, IVector4Dict } from '../../components/r3f/r3f-components/component-data-structure';
import { CHROMA_KEY_DEFAULT_COLOR, CHROMA_KEY_DEFAULT_SIMILARITY, CHROMA_KEY_DEFAULT_SMOOTHNESS, CHROMA_KEY_DEFAULT_SPILL, DEFAULT_SCREEN_RELATIVE_DEVICE, INITIAL_ZOOM_LEVEL, SCREEN_RELATIVE_DEVICES } from '../../utils';
import {
	IOnAddToLoadedAudioAction,
	IOnSetMediaLibraryAudioAction,
	IOnSetMediaLibraryDisplayAction,
	IOnSetOpenProjectMenuDrawerAction,
	IOnSetOpenSceneMenuDrawerAction, IOnSetScreenRelativeDeviceAction, IOnSetScreenRelativeDevicePreviousCustomDimensionsAction, IOnSetTempAnimateModelActionAction,
	IOnSetVisibleCanvasAction,
	IOnSetWebEmbedPreviewSettingsAction
} from '../actions';
import {
	IAddEntityIdsToSelectionAction, IAddMediaPositionTypes, ICopySelectedEntitiesAction, IDisableRedoAction, IDisableUndoAction, InSetDragControlsUpdateTriggerAction, IOnAddToastAction, IOnAddToLoadedMediaAction, IOnChangeZoomLevelAction, IOnCloseModalAction, IOnEditSceneTitleAction, IOnFocusSceneNameInputAction, IOnOpenModalAction, IOnRemoveLoadedMediaAction, IOnRemoveToastAction, IOnScrollSceneMenuAction, IOnSetActiveAnimationPresetData_Action, IOnSetActiveEditingActionData_Action, IOnSetActiveFaceLandmark_Action, IOnSetAudioStatusAction, IOnSetBorderRgba_Action, IOnSetCanvasLoadedAction, IOnSetChromaKeyEditModalDataAction, IOnSetCurvatures_Action, IOnSetDraggedEntityInfoAction, IOnSetDropdownHoveredFacelandmark_Action, IOnSetEmitterAreaScales_Action, IOnSetEmitterIsStatic_Action, IOnSetEnlargeSceneMenuAction, IOnSetEntityMenuDragActiveAction,
	IOnSetEntityMenuHotspotDragPositionAction, IOnSetFileVerifyingAction, IOnSetFillRgba_Action,
	IOnSetFontRgba_Action, IOnSetIs3dModeAction, IOnSetIsWebEmbedEditingModeAction, IOnSetLoadingMediaAction, IOnSetMultipleEntitySelectBoundary_Action, IOnSetOpenEntityMenuDrawerAction, IOnSetOpenEntityMenuDrawerCategoryAction, IOnSetOpenInspectorMenuEntityShelfAction, IOnSetPopOutIdealTopPosition_Action, IOnSetPositioningIsActiveAction, IOnSetPositions_Action, IOnSetPreviewCodeSrc_Action, IOnSetPreviewUrl_Action, IOnSetProjectLoadingFailureAction, IOnSetProjectLoadingProgressAction, IOnSetPublishTrigger_Action, IOnSetPureReactInCanvasHoveredAction, IOnSetReplaceEntityModeAction, IOnSetRotations_Action, IOnSetScales_Action, IOnSetSceneCarouselScrollLeftAction, IOnSetSelectedTemplateProject_Action, IOnSetSelection_Action, IOnSetShowFaceLandmarks_Action, IOnSetShowPublishPopup, IOnSetShowPublishPopup_Action, IOnSetSocketConnectedAction, IOnSetTargetReferenceObjectPosition_Action, IOnSetTemporaryInitialDragRotationAction, IOnSetTrackingImageDataAction, IOnSetTransformControlsModeAction, IOnSetVideoProgress_Action, IOnUpdateUsers_Action, IRemoveHotKeyAction, IRemoveUploadAction, ISetActiveSceneAction, ISetBackgroundHotSpotEnabledAction, ISetCurrentSceneIndexAction, ISetCurrentUploadsAction, ISetGroupInversionAction, ISetHotKeyAction, ISetLoaded360BackgroundAction, ISetLoadedAudioAction, ISetMarkerIndexPressedAction, ISetPointerOffsetAction, ISetPRojectInfoAction, ISetRotationHotSpotEnabledAction, ISetRotationMarker2DPositionAction, ISetScaleHotSpotEnabledAction, ISetSceneEntityListVisibleAction, ISetSceneSnapshotsAction, ISetSelection2DBottomLeftPositionAction, ISetSelection2DBottomRightPositionAction, ISetSelection2DPositionAction, ISetSelection2DTopLeftPositionAction,
	ISetSelection2DTopRightPositionAction, ISetTrkImgIsUploadingAction, ISetUserIdAction,
	IUserActionTypes
} from '../actions/action-types';

const setMultipleEntitySelectBoundary = (state: IUserState, action: IOnSetMultipleEntitySelectBoundary_Action): IUserState => {
	return {
		...state, 
		multipleEntitySelectBoundary: JSON.parse(JSON.stringify(action.payload))
	}
}

const setUserId = (state: IUserState, action: ISetUserIdAction) => {
	return {
		...state,
		userId: action.payload.userId,
	};
};

const setEmitterAreaScales = (state: IUserState, action: IOnSetEmitterAreaScales_Action): IUserState => {
	let emitterAreaScalesDict = action.payload as IVector3Dict | {[id: string]:  null};
	if (action.payload === null) {
		emitterAreaScalesDict = Object.keys(state.emitterAreaScalesById).reduce((acc, id) => {
			acc[id] = null;
			return acc
		}, {} as IVector3Dict | { [id: string]: null })
	}
	return {
		...state,
		emitterAreaScalesById: {
			...state.emitterAreaScalesById,
			...emitterAreaScalesDict
		}
	}
}

const setEmitterIsStatic = (state: IUserState, action: IOnSetEmitterIsStatic_Action): IUserState => {
	return {
		...state,
		emitterIsStaticById: {
			...state.emitterIsStaticById,
			...action.payload
		}
	}
}

const setPreviewCodeSrc = (state: IUserState, action: IOnSetPreviewCodeSrc_Action): IUserState => {
	return {
		...state,
		previewCodeSrc: action.payload
	}
}

const onSetPreviewUrl = (state: IUserState, action: IOnSetPreviewUrl_Action): IUserState => {
	return {
		...state,
		previewUrl: action.payload
	}
}

const setDragControlsUpdateTrigger = (state: IUserState, action: InSetDragControlsUpdateTriggerAction): IUserState => {
	return {
		...state,
		forceUpdateDragControlsTrigger: action.payload
	}
}

const setTemporaryDragRotation = (state: IUserState, action: IOnSetTemporaryInitialDragRotationAction): IUserState => {
	return {
		...state,
		temporaryDragRotation: action.payload
	}
}

const setVideoRenditionProgress = (state: IUserState, action: IOnSetVideoProgress_Action): IUserState => {
	const {id, progress} = action.payload;
	const videoProgressById = {...state.videoProgressById ?? {}}

	if (progress === null) delete(videoProgressById[id]);
	else videoProgressById[id] = progress;

	return {
		...state,
		videoProgressById
	}
}

const setPublishPopup = (state: IUserState, action: IOnSetShowPublishPopup_Action): IUserState => {
	return {
		...state,
		showPublishPopup: action.payload
	}
}

const setPublishTrigger = (state: IUserState, action: IOnSetPublishTrigger_Action): IUserState => {
	return {
		...state,
		publishTrigger: action.payload
	}
}

const setSelectedTemplateProject = (state: IUserState, action: IOnSetSelectedTemplateProject_Action): IUserState => {
	return {
		...state,
		selectedTemplateProject: {...action.payload}
	}
}

const setPositions = (state: IUserState, action: IOnSetPositions_Action): IUserState => {
	let positionDict = action.payload as IVector3Dict | {[id: string]: null};
	if (action.payload === null) {
		positionDict = Object.keys(state.positionById).reduce((acc, id) => {
			acc[id] = null;
			return acc
		}, {} as IVector3Dict | { [id: string]: null })
	}
	return {
		...state,
		positionById: {
			...state.positionById,
			...positionDict
		}
	}
}

const setCurvatures = (state: IUserState, action: IOnSetCurvatures_Action): IUserState => {
	let curvatureDict = action.payload as INumberDict | {[id: string]: null};
	if (action.payload === null) {
		curvatureDict = Object.keys(state.curvatureById).reduce((acc, id) => {
			acc[id] = null;
			return acc
		}, {} as INumberDict | { [id: string]: null })
	}
	return {
		...state,
		curvatureById: {
			...state.curvatureById,
			...curvatureDict
		}
	}
}

const setScales = (state: IUserState, action: IOnSetScales_Action): IUserState => {
	let scaleDict = action.payload as IVector3Dict | {[id: string]:  null};
	if (action.payload === null) {
		scaleDict = Object.keys(state.scaleById).reduce((acc, id) => {
			acc[id] = null;
			return acc
		}, {} as IVector3Dict | { [id: string]: null })
	}
	return {
		...state,
		scaleById: {
			...state.scaleById,
			...scaleDict
		}
	}
}

const setRotations = (state: IUserState, action: IOnSetRotations_Action): IUserState => {
	let rotationDict = action.payload as IVector3Dict | {[id: string]:  null};
	if (action.payload === null) {
		rotationDict = Object.keys(state.rotationById).reduce((acc, id) => {
			acc[id] = null;
			return acc
		}, {} as IVector3Dict | { [id: string]: null })
	}
	return {
		...state,
		rotationById: {
			...state.rotationById,
			...rotationDict
		}
	}
}

const setFillRgba = (state: IUserState, action: IOnSetFillRgba_Action): IUserState => {
	let fillRgbaDict = action.payload as {[key: string]: IVector4[] | null };

	if (action.payload === null) {
		fillRgbaDict = Object.keys(state.fillRgbaById).reduce((acc, id) => {
			acc[id] = null;
			return acc;
		}, {} as {[key: string]: IVector4[] | null});
	}
	return {
		...state,
		fillRgbaById: {
			...state.fillRgbaById,
			...fillRgbaDict,
		},
	};
};
const setFontRgba = (state: IUserState, action: IOnSetFontRgba_Action): IUserState => {
	let fontRgbaDict = action.payload as IVector4Dict | { [id: string]: null };
	if (action.payload === null) {
		fontRgbaDict = Object.keys(state.fontRgbaById).reduce((acc, id) => {
			acc[id] = null;
			return acc;
		}, {} as IVector4Dict | { [id: string]: null });
	}
	return {
		...state,
		fontRgbaById: {
			...state.fontRgbaById,
			...fontRgbaDict,
		},
	};
};
const setBorderRgba = (state: IUserState, action: IOnSetBorderRgba_Action): IUserState => {
	let borderRgbaDict = action.payload as IVector4Dict | { [id: string]: null };
	if (action.payload === null) {
		borderRgbaDict = Object.keys(state.borderRgbaById).reduce((acc, id) => {
			acc[id] = null;
			return acc;
		}, {} as IVector4Dict | { [id: string]: null });
	}
	return {
		...state,
		borderRgbaById: {
			...state.borderRgbaById,
			...borderRgbaDict,
		},
	};
};


const setActiveFaceLandmark =  (state: IUserState, action: IOnSetActiveFaceLandmark_Action): IUserState => {
	return {
		...state,
		activeFaceLandmark: action.payload,
	};

}



const addToast = (
	state: IUserState,
	action: IOnAddToastAction
) : IUserState => {
	const singleton = action.payload?.singleton || true;

	if (singleton) {
		const showingToastMessageAlready = state.toasts.filter(toast => toast.message === action.payload?.message).length !== 0;
		if (showingToastMessageAlready) return state;
	}

	const id = action.payload?.id || MathUtils.generateUUID();
	const toast = { ...action.payload, id } as IToast;
	const deepCopiedToasts = JSON.parse(JSON.stringify(state.toasts)) as IToast[];
	const newToasts = [...deepCopiedToasts, toast];

	return {
		...state,
		toasts: newToasts,
	};
};

const removeToast = (state: IUserState, action: IOnRemoveToastAction): IUserState => {
	const id = action.payload;
	const newToasts = state.toasts.filter((toast) => toast.id !== id);

	return {
		...state,
		toasts: newToasts,
	};
};

const setTrackingImageIsUploading = (state: IUserState, action: ISetTrkImgIsUploadingAction): IUserState => {
	return {
		...state,
		trkImgIsUploading: action.payload,
	};
};

const setTrackingImageData = (state: IUserState, action: IOnSetTrackingImageDataAction): IUserState => {
	return {
		...state,
		trackImgData: {
			...state.trackImgData,
			...action.payload,
		},
	};
};

const removeTrackingImageData = (state: IUserState): IUserState => {
	const trackImgData = null;
	return {
		...state,
		trackImgData,
	};
};

const setOpenInspectorMenuEntityShelf = (state: IUserState, action: IOnSetOpenInspectorMenuEntityShelfAction): IUserState => {
	const shelf = action.payload;
	const inspectorMenuShelves = shelf ? [shelf] : [];	// Empty array because when set to null the 'Content' and 'Transforms' are open for first load
	return {
		...state,
		openInspectorMenuEntityShelves: inspectorMenuShelves,
	};
};

const setOpenEntityMenuDrawer = (state: IUserState, action: IOnSetOpenEntityMenuDrawerAction): IUserState => {
	const { openEntityMenuDrawer } = action.payload;
	return {
		...state,
		openEntityMenuDrawer,
	};
};

const setOpenEntityMenuCategoryDrawer = (state: IUserState, action: IOnSetOpenEntityMenuDrawerCategoryAction): IUserState => {
	const { openEntityCategoryDrawerDict } = action.payload;
	return {
		...state,
		openEntityCategoryDrawerDict: {...state.openEntityCategoryDrawerDict, ...openEntityCategoryDrawerDict},
	};
};

const setCanvasIsLoaded = (state: IUserState, action: IOnSetCanvasLoadedAction): IUserState => {
	return {
		...state,
		canvasLoaded: action.payload,
	};
};

const setLoadedAudio = (state: IUserState, action: ISetLoadedAudioAction): IUserState => {
	return {
		...state,
		loadedAudio: action.payload,
	};
};

const setLoadedBackground360Content = (state: IUserState, action: ISetLoaded360BackgroundAction): IUserState => {
	return {
		...state,
		loadedBackgroundContent: action.payload,
	};
};

const removeLoadedMedia = (state: IUserState, action: IOnRemoveLoadedMediaAction): IUserState => {
	switch (action.payload.type) {
		case IComponentType.Model3d: {
			const loaded3dModels: ILoadedModel3d[] = [];
			return { ...state, loaded3dModels };
		}
		case IComponentType.Image: {
			const loadedImages: ILoadedImage[] = [];
			return { ...state, loadedImages };
		}
		case IComponentType.Video: {
			const loadedVideos: ILoadedVideo[] = [];
			return { ...state, loadedVideos };
		}
		default:
			return state;
	}
};

const addToLoadedMedia = (state: IUserState, action: IOnAddToLoadedMediaAction): IUserState => {
	let media = [] as ILoadedComponentUnion[];

	switch (action.payload.entity.type) {
		case IComponentType.Model3d:
			media = state.loaded3dModels ? JSON.parse(JSON.stringify(state.loaded3dModels)) : [];
			break;
		case IComponentType.Image:
			media = state.loadedImages ? JSON.parse(JSON.stringify(state.loadedImages)) : [];
			break;
		case IComponentType.Video:
			media = state.loadedVideos ? JSON.parse(JSON.stringify(state.loadedVideos)) : [];
			break;
	}

	switch (action.payload.position) {
		case IAddMediaPositionTypes.start:
			media = [...[action.payload.entity], ...media];
			break;
		case IAddMediaPositionTypes.end:
		case IAddMediaPositionTypes.date:
			media = [...media, ...[action.payload.entity]];
			break;
		default:
			media = [action.payload.entity];
			break;
	}

	switch (action.payload.entity.type) {
		case IComponentType.Image:
			return { ...state, loadedImages: media as ILoadedImage[] };
		case IComponentType.Model3d:
			return { ...state, loaded3dModels: media as ILoadedModel3d[] };
		case IComponentType.Video:
			return { ...state, loadedVideos: media as ILoadedVideo[] };
		default:
			return state;
	}
};

const addToLoadedAudio = (state: IUserState, action: IOnAddToLoadedAudioAction): IUserState => {
	let media = state.loadedAudio ? JSON.parse(JSON.stringify(state.loadedAudio)) : ([] as IAudio[]);

	switch (action.payload.position) {
		case IAddMediaPositionTypes.start:
			media = [...[action.payload.entity], ...media];
			break;
		case IAddMediaPositionTypes.end:
		case IAddMediaPositionTypes.date:
			media = [...media, ...[action.payload.entity]];
			break;
		default:
			media = [action.payload.entity];
			break;
	}

	return { ...state, loadedAudio: media as any };
};

const removeUpload = (state: IUserState, action: IRemoveUploadAction): IUserState => {
	const id = action.payload;
	const currentUploads = JSON.parse(JSON.stringify(state.currentUploads));
	let { numberProcessedUploads } = state;
	delete currentUploads[id];
	if (!Object.keys(currentUploads).length) {
		numberProcessedUploads = 0;
	} else {
		numberProcessedUploads--;
	}
	return {
		...state,
		currentUploads,
		numberProcessedUploads,
	};
};

const setCurrentUploads = (state: IUserState, action: ISetCurrentUploadsAction): IUserState => {
	const { id, progress } = action.payload;
	const currentUploads = JSON.parse(JSON.stringify(state.currentUploads));
	let { numberProcessedUploads } = state;

	// remove from uploads if payload null, else add progressInfo
	if (!progress) {
		delete currentUploads[id];
		if (!Object.keys(currentUploads).length) numberProcessedUploads = 0;
	} else {
		if (!currentUploads[id]) numberProcessedUploads += 1;
		currentUploads[id] = { ...currentUploads[id], ...progress };
	}

	return {
		...state,
		currentUploads,
		numberProcessedUploads,
	};
};

const setEntityMenuDragActive = (state: IUserState, action: IOnSetEntityMenuDragActiveAction): IUserState => {
	return {
		...state,
		entityMenuDragActive: action.payload,
	};
};

const setAudioStatus = (state: IUserState, action: IOnSetAudioStatusAction): IUserState => {
	const loadedAudio = JSON.parse(JSON.stringify(state.loadedAudio)) as IAudio[];
	loadedAudio[loadedAudio.findIndex((a) => a.id === action.payload.id)].status = action.payload.status ?? undefined;
	return {
		...state,
		loadedAudio,
	};
};

const updateUsers = (state: IUserState, action: IOnUpdateUsers_Action): IUserState => {
	const { users: syncUsers } = action.payload;
	const users: { [id: string]: IUserData } = {};

	for (let index = 0; index < syncUsers.length; index++) {
		const syncUser = syncUsers[index];
		users[syncUser.id] = {
			...syncUser,
		};
	}
	return {
		...state,
		loggedInUsers: users,
	};
};

const setProjectInfo = (state: IUserState, action: ISetPRojectInfoAction): IUserState => {
	return {
		...state,
		project: action.payload.project,
	};
};

const setEntityDragInfo = (state: IUserState, action: IOnSetDraggedEntityInfoAction): IUserState => {
	return {
		...state,
		entityDragInfo: action.payload,
	};
};

const setHotKey = (state: IUserState, action: ISetHotKeyAction): IUserState => {
	const { hotKey } = action.payload;
	return {
		...state,
		hotKeys: [...state.hotKeys, hotKey],
	};
};

const removeHotKey = (state: IUserState, action: IRemoveHotKeyAction): IUserState => {
	const { hotKey } = action.payload;
	return {
		...state,
		hotKeys: state.hotKeys.filter((key) => key !== hotKey),
	};
};

const setActiveScene = (state: IUserState, action: ISetActiveSceneAction): IUserState => {
	const { activeSceneId } = action.payload;
	return {
		...state,
		activeSceneId,
	};
};

const setSortedSceneSnapshots = (state: IUserState, action: ISetSceneSnapshotsAction): IUserState => {
	const sceneSnapshots = action.payload;
	return {
		...state,
		sceneSnapshots,
	};
};

const setCurrentSceneIndex = (state: IUserState, action: ISetCurrentSceneIndexAction): IUserState => {
	const currentSceneIndex = action.payload;
	return {
		...state,
		currentSceneIndex,
	};
};

const scrollSceneMenu = (state: IUserState, action: IOnScrollSceneMenuAction): IUserState => {
	const scrollSceneMenu = action.payload;
	return {
		...state,
		scrollSceneMenu,
	};
};

const setSceneMenuLarge = (state: IUserState, action: IOnSetEnlargeSceneMenuAction): IUserState => {
	return {
		...state,
		sceneMenuIsLarge: action.payload,
	};
};

const setPositioningActive = (state: IUserState, action: IOnSetPositioningIsActiveAction): IUserState => {
	return {
		...state,
		positioningIsActive: action.payload,
	};
};

const setScaleHotSpotEnabled = (state: IUserState, action: ISetScaleHotSpotEnabledAction): IUserState => {
	return {
		...state,
		scaleHotspotIsEnabled: action.payload,
	};
};

const setRotationActive = (state: IUserState, action: ISetRotationHotSpotEnabledAction): IUserState => {
	return {
		...state,
		rotationIsActive: action.payload,
	};
};

const setBackgroundHotSpotEnabled = (state: IUserState, action: ISetBackgroundHotSpotEnabledAction): IUserState => {
	return {
		...state,
		backgroundHotspotIsEnabled: action.payload,
	};
};

const setPointerOffset = (state: IUserState, action: ISetPointerOffsetAction): IUserState => {
	return {
		...state,
		pointerOffset: action.payload,
	};
};

const setMarkerIndexPressed = (state: IUserState, action: ISetMarkerIndexPressedAction): IUserState => {
	return {
		...state,
		markerIndexPressed: action.payload,
	};
};

const setSceneEntityListVisible = (state: IUserState, action: ISetSceneEntityListVisibleAction): IUserState => {
	return {
		...state,
		sceneEntityListIsVisible: action.payload.isVisible,
		...(action.payload.position && {
			sceneEntityListPosition: action.payload.position,
		}),
	};
};

const disableUndo = (state: IUserState, action: IDisableUndoAction): IUserState => {
	return {
		...state,
		undoDisabled: action.payload,
	};
};

const disableRedo = (state: IUserState, action: IDisableRedoAction): IUserState => {
	return {
		...state,
		redoDisabled: action.payload,
	};
};

const isLoadingMedia = (state: IUserState, action: IOnSetLoadingMediaAction): IUserState => {
	return {
		...state,
		loadingMedia: action.payload,
	};
};

const addEntityIdsToSelection = (state: IUserState, action: IAddEntityIdsToSelectionAction): IUserState => {
	const selEntities = state.selectedEntityIds || [];
	const noDuplicates = new Set([...selEntities, ...action.payload]);
	return {
		...state,
		selectedEntityIds: Array.from(noDuplicates),
	};
};

const removeLocalUserSelection = (state: IUserState, _action: any): IUserState => {
	return {
		...state,
		selectedEntityIds: [] as string[],
	};
};

const removeSelection = (state: IUserState, action: any): IUserState => {
	const entityIds = action.payload;
	let { selectedEntityIds } = state;
	if (!entityIds) {
		selectedEntityIds = [];
	} else {
		selectedEntityIds = selectedEntityIds?.filter((entity) => entityIds.indexOf(entity) === -1);
	}

	return {
		...state,
		selectedEntityIds,
	};
};

const changeZoomLevel = (state: IUserState, action: IOnChangeZoomLevelAction): IUserState => {
	return {
		...state,
		zoomLevel: action.payload,
	};
};

const setSocketConnected = (state: IUserState, action: IOnSetSocketConnectedAction): IUserState => {
	return {
		...state,
		socketConnected: action.payload,
	};
};

const setProjectLoadingProgress = (state: IUserState, action: IOnSetProjectLoadingProgressAction): IUserState => {
	return {
		...state,
		projectLoadingProgress: action.payload,
	};
};

const setProjectLoadingFailure = (state: IUserState, action: IOnSetProjectLoadingFailureAction): IUserState => {
	return {
		...state,
		projectLoadingFailure: action.payload,
	};
};

const setSelection2DPosition = (state: IUserState, action: ISetSelection2DPositionAction): IUserState => {
	if (!state.selectedObject2DPosition && !action.payload) return state;
	if (state.selectedObject2DPosition && action.payload && state.selectedObject2DPosition[0] === action.payload[0] && state.selectedObject2DPosition[1] === action.payload[1]) {
		// No change
		return state;
	}
	return {
		...state,
		selectedObject2DPosition: action.payload,
	};
};

const setSceneCarouselScrollLeft = (state: IUserState, action: IOnSetSceneCarouselScrollLeftAction): IUserState => {
	return {
		...state,
		sceneCarouselScrollLeft: action.payload,
	};
};

const focusSceneNameInput = (state: IUserState, action: IOnFocusSceneNameInputAction): IUserState => {
	return {
		...state,
		sceneNameInputIsFocused: action.payload,
	};
};

const setSelection2DTopLeftPosition = (state: IUserState, action: ISetSelection2DTopLeftPositionAction): IUserState => {
	if (!state.selectionTopLeft2DPosition && !action.payload) return state;
	if (state.selectionTopLeft2DPosition && action.payload && state.selectionTopLeft2DPosition[0] === action.payload[0] && state.selectionTopLeft2DPosition[1] === action.payload[1]) {
		// No change
		return state;
	}
	return {
		...state,
		selectionTopLeft2DPosition: action.payload,
	};
};

const setSelection2DTopRightPosition = (state: IUserState, action: ISetSelection2DTopRightPositionAction): IUserState => {
	if (!state.selectionTopRight2DPosition && !action.payload) return state;
	if (state.selectionTopRight2DPosition && action.payload && state.selectionTopRight2DPosition[0] === action.payload[0] && state.selectionTopRight2DPosition[1] === action.payload[1]) {
		// No change
		return state;
	}
	return {
		...state,
		selectionTopRight2DPosition: action.payload,
	};
};

const setSelection2DBottomLeftPosition = (state: IUserState, action: ISetSelection2DBottomLeftPositionAction): IUserState => {
	if (!state.selectionBottomLeft2DPosition && !action.payload) return state;
	if (state.selectionBottomLeft2DPosition && action.payload && state.selectionBottomLeft2DPosition[0] === action.payload[0] && state.selectionBottomLeft2DPosition[1] === action.payload[1]) {
		// No change
		return state;
	}
	return {
		...state,
		selectionBottomLeft2DPosition: action.payload,
	};
};

const setSelection2DBottomRightPosition = (state: IUserState, action: ISetSelection2DBottomRightPositionAction): IUserState => {
	if (!state.selectionBottomRight2DPosition && !action.payload) return state;
	if (state.selectionBottomRight2DPosition && action.payload && state.selectionBottomRight2DPosition[0] === action.payload[0] && state.selectionBottomRight2DPosition[1] === action.payload[1]) {
		// No change
		return state;
	}
	return {
		...state,
		selectionBottomRight2DPosition: action.payload,
	};
};

///////

const setRotationMarker2DPosition = (state: IUserState, action: ISetRotationMarker2DPositionAction): IUserState => {
	if (!state.rotationMarker2DPosition && !action.payload) return state;
	if (state.rotationMarker2DPosition && action.payload && state.rotationMarker2DPosition[0] === action.payload[0] && state.rotationMarker2DPosition[1] === action.payload[1]) {
		// No change
		return state;
	}
	return {
		...state,
		rotationMarker2DPosition: action.payload,
	};
};

const setEntityMenuHotspotDragPosition = (state: IUserState, action: IOnSetEntityMenuHotspotDragPositionAction): IUserState => {
	return {
		...state,
		entityDragHotspotPosition: action.payload,
	};
};

const setSelectedEntityIds = (state: IUserState, action: IOnSetSelection_Action): IUserState => {
	const noDuplicates = new Set(action.payload);
	return {
		...state,
		selectedEntityIds: Array.from(noDuplicates),
	};
};

const setPureReactInCanvasHovered = (state: IUserState, action: IOnSetPureReactInCanvasHoveredAction): IUserState => {
	return {
		...state,
		pureReactInCanvasHovered: action.payload,
	};
};

const copySelectedEntities = (state: IUserState, action: ICopySelectedEntitiesAction): IUserState => {
	const copiedEntityIds = action.payload;
	if (!copiedEntityIds) return state;
	return {
		...state,
		copiedEntityIds,
	};
};

const setGroupInversion = (state: IUserState, action: ISetGroupInversionAction): IUserState => {
	const { xInverted: groupIsXInverted, yInverted: groupIsYInverted } = action.payload;
	return {
		...state,
		...(typeof groupIsXInverted !== 'undefined' && { groupIsXInverted }),
		...(typeof groupIsYInverted !== 'undefined' && { groupIsYInverted }),
	};
};

const setEditSceneTitle = (state: IUserState, action: IOnEditSceneTitleAction): IUserState => {
	const editSceneTitle = action.payload;
	return {
		...state,
		editSceneTitle,
	};
};

const set3dMode = (state: IUserState, action: IOnSetIs3dModeAction): IUserState => {
	return {
		...state,
		is3dMode: action.payload,
	};
};

const setIsScreenRelativeMode = (state: IUserState, action: IOnSetIs3dModeAction): IUserState => {
	return {
		...state,
		isScreenRelativeMode: action.payload,
	};
};

const setOpenModal = (state: IUserState, action: IOnOpenModalAction): IUserState => {
	const modalById = state.modalById;
	// Close all others
	for (const modalId in modalById) {
		modalById[modalId as keyof typeof IModal] = false;
	}
	// Open this one
	modalById[action.payload] = true;

	return {
		...state,
		modalById
	}
}

const setCloseModal = (state: IUserState, _action: IOnCloseModalAction): IUserState => {
	const modalById = state.modalById;
	// Close all modals
	for (const modalId in modalById) {
		modalById[modalId as keyof typeof IModal] = false;
	}
	return {
		...state,
		modalById
	}
}

const setFileVerifying = (state: IUserState, action: IOnSetFileVerifyingAction): IUserState => {
	const { isVerifying } = action.payload;
	return {
		...state,
		fileVerifying: isVerifying,
	};
};

const setReplaceEntityMode = (state: IUserState, action: IOnSetReplaceEntityModeAction): IUserState => {
	const { replaceEntityMode } = action.payload;
	return {
		...state,
		mediaLibraryDisplay: {
			fileTypes: state.mediaLibraryDisplay.fileTypes,
			onSubmitCategory: (replaceEntityMode ? IMediaLibraryOnSubmitCategory.ReplaceEntity : IMediaLibraryOnSubmitCategory.AddToScene),
		},
	};
};

const setIsWebEmbedEditingMode = (state: IUserState, action: IOnSetIsWebEmbedEditingModeAction): IUserState => {
	const isWebEmbedEditingMode = action.payload;
	return {
		...state,
		isWebEmbedEditingMode: isWebEmbedEditingMode,
	};
};

const setTransformControlsActive = (state: IUserState, action: IOnSetIs3dModeAction): IUserState => {
	return {
		...state,
		transformControlsActive: action.payload,
	};
};

const setTransformControlsMode = (state: IUserState, action: IOnSetTransformControlsModeAction): IUserState => {
	return {
		...state,
		transformControlsMode: action.payload,
	};
};

const setScreenRelativeDevice = (state: IUserState, action: IOnSetScreenRelativeDeviceAction): IUserState => {
	return {
		...state,
		screenRelativeDevice: action.payload,
	};
};

const setScreenRelativeDevicePreviousCustomDimensions = (state: IUserState, action: IOnSetScreenRelativeDevicePreviousCustomDimensionsAction): IUserState => {
	return {
		...state,
		screenRelativeDevicePreviousCustomDimensions: action.payload,
	};
};
const setChromaKeyEditModalData = (state: IUserState, action: IOnSetChromaKeyEditModalDataAction): IUserState => {
	const chromaKeyData = state.chromaKeyEditModalData;
	if (typeof action.payload.chromaKey?.color != 'undefined') chromaKeyData.chromaKey.color = action.payload.chromaKey.color;
	if (typeof action.payload.chromaKey?.smoothness != 'undefined') chromaKeyData.chromaKey.smoothness = action.payload.chromaKey.smoothness;
	if (typeof action.payload.chromaKey?.similarity != 'undefined') chromaKeyData.chromaKey.similarity = action.payload.chromaKey.similarity;
	if (typeof action.payload.chromaKey?.spill != 'undefined') chromaKeyData.chromaKey.spill = action.payload.chromaKey.spill;
	if (typeof action.payload.videoUrl != 'undefined') chromaKeyData.videoUrl = action.payload.videoUrl;
	if (typeof action.payload.mediaLibraryLoadedEntity != 'undefined') chromaKeyData.mediaLibraryLoadedEntity = action.payload.mediaLibraryLoadedEntity;

	return {
		...state,
		chromaKeyEditModalData: chromaKeyData,
	};
}

const setWebEmbedPreviewSettings = (state: IUserState, action: IOnSetWebEmbedPreviewSettingsAction): IUserState => {
	// webEmbed settings are optional ( for projects without AR Web Embed trigger )
	// So need some default values in case an action is dispatched with partial data
	const initialValues = {
		height: 400,
		width: 600,
	};
	const webEmbed = {
		...initialValues, // Initial required values
		...state.webEmbed, // Overwritten by current state
		...action.payload, // Overwritten by the current action
	};
	return {
		...state,
		webEmbed,
	};
};

const setMediaLibraryDisplay = (state: IUserState, action: IOnSetMediaLibraryDisplayAction): IUserState => {
	const mediaLibraryDisplay = {
		...state.mediaLibraryDisplay,
		...action.payload,
	};
	return {
		...state,
		mediaLibraryDisplay,
	};
};

const setMediaLibraryAudio = (state: IUserState, action: IOnSetMediaLibraryAudioAction): IUserState => {
	return {
		...state,
		mediaLibraryAudio: action.payload,
	};
};

const setOpenProjectMenuDrawer = (state: IUserState, action: IOnSetOpenProjectMenuDrawerAction): IUserState => {
	return {
		...state,
		openInspectorMenuProjectShelves: action.payload,
	};
};

const setOpenSceneMenuDrawer = (state: IUserState, action: IOnSetOpenSceneMenuDrawerAction): IUserState => {
	return {
		...state,
		openInspectorMenuSceneShelves: action.payload,
	};
};

const setTempAnimateModelAction = (state: IUserState, action: IOnSetTempAnimateModelActionAction): IUserState => {
	return {
		...state,
		tempAnimateModelAction: action.payload.action,
	};
};

const setVisibleCanvas = (state: IUserState, action: IOnSetVisibleCanvasAction): IUserState => {
	const { canvasName, visible } = action.payload;
	const { visibleCanvases } = state;
	const visibleCanvasesCopy = { ...visibleCanvases };
	visibleCanvasesCopy[canvasName] = visible;
	return {
		...state,
		visibleCanvases: visibleCanvasesCopy,
	};
};

const setShowFaceLandmarks = (state: IUserState, action: IOnSetShowFaceLandmarks_Action): IUserState => {
	return {
		...state,
		showFaceLandmarks: action.payload,
	};
};

const setHoveredDropdownFaceLandmark = (state: IUserState, action: IOnSetDropdownHoveredFacelandmark_Action): IUserState => {
	return {
		...state,
		hoveredDropdownFacelandmark: action.payload,
	};
};

const setTargetImageReferenceObjectPosition = (state: IUserState, action: IOnSetTargetReferenceObjectPosition_Action): IUserState => {
	return {
		...state,
		targetImageReferenceObjectPosition: action.payload
	}
}

const setPopOutIdealTopPosition = (state: IUserState, action: IOnSetPopOutIdealTopPosition_Action): IUserState => {
	return {
		...state,
		popOutIdealTopPosition: action.payload
	}
}
// const setActiveAnimationPresetData = (state: IUserState, action: IOnSetActiveAnimationPresetData_Action): IUserState => {
// 	if (action.payload === null) {
// 		return {
// 			...state,
// 			activePresetAnimation: null
// 		}
// 	}
// 	if (state.activePresetAnimation === null) {
// 		return {
// 			...state,
// 			activePresetAnimation: action.payload as IActivePresetAnimationState
// 		}
// 	}
// 	return state;
// }

const setActiveAnimationPreset =  (state: IUserState, action: IOnSetActiveAnimationPresetData_Action): IUserState => {
	return {
		...state,
		isPresetAnimationActive: action.payload
	}
}

const setActiveEditingActionData = (state: IUserState, action: IOnSetActiveEditingActionData_Action): IUserState => {
	if (action.payload === null) {
		return {
			...state,
			activeEditingActionData: null
		}
	} else {
		return {
			...state,
			activeEditingActionData: {
				...state.activeEditingActionData,
				...action.payload
			}
		}
	}
}

const initialState: IUserState = {
	activeEditingActionData: null,
	popOutIdealTopPosition: 150,
	isPresetAnimationActive: false,
	multipleEntitySelectBoundary: null,
	chromaKeyEditModalData: {
		videoUrl: '',
		chromaKey: {	color: CHROMA_KEY_DEFAULT_COLOR, smoothness: CHROMA_KEY_DEFAULT_SMOOTHNESS, similarity: CHROMA_KEY_DEFAULT_SIMILARITY, spill: CHROMA_KEY_DEFAULT_SPILL }
	},
	targetImageReferenceObjectPosition: null,
	modalById: {},
	isWebEmbedEditingMode: false,
	is3dMode: false,
	isScreenRelativeMode: false,
	fileVerifying: false,
	screenRelativeDevice: SCREEN_RELATIVE_DEVICES[DEFAULT_SCREEN_RELATIVE_DEVICE],
	transformControlsMode: ITransformControlsMode.translate,
	transformControlsActive: false,
	zoomLevel: INITIAL_ZOOM_LEVEL,
	undoDisabled: true,
	redoDisabled: true,
	activeSceneId: null,
	projectLoadingProgress: 0,
	projectLoadingFailure: false,
	positioningIsActive: false,
	backgroundHotspotIsEnabled: false,
	rotationIsActive: false,
	scaleHotspotIsEnabled: false,
	markerIndexPressed: null,
	hotKeys: [],
	pointerOffset: [0, 0, 0],
	selectedEntityIds: [],
	selectedObject2DPosition: null,
	selectionBottomLeft2DPosition: null,
	selectionBottomRight2DPosition: null,
	selectionTopLeft2DPosition: null,
	selectionTopRight2DPosition: null,
	rotationMarker2DPosition: null,
	entityDrag2DPosition: null,
	groupIsXInverted: false,
	groupIsYInverted: false,
	copiedEntityIds: [],
	sceneEntityListIsVisible: false,
	sceneEntityListPosition: [0, 0],
	currentSceneIndex: 0,
	scrollSceneMenu: false,
	sceneSnapshots: {},
	sceneCarouselScrollLeft: 0,
	sceneMenuIsLarge: false,
	sceneNameInputIsFocused: false,
	project: {},
	loggedInUsers: {},
	canvasLoaded: false,
	entityMenuDragActive: false,
	entityDragHotspotPosition: null,
	entityDragInfo: null,
	loadedImages: null,
	loaded3dModels: null,
	loadedVideos: null,
	loadedAudio: null,
	loadedBackgroundContent: null,
	loadingMedia: false,
	currentUploads: {},
	numberProcessedUploads: 0,
	trackImgData: null,
	trkImgIsUploading: false,
	pureReactInCanvasHovered: false,
	toasts: [],
	editSceneTitle: false,
	openInspectorMenuEntityShelves: null,
	openInspectorMenuProjectShelves: null,
	openInspectorMenuSceneShelves: null,
	openEntityMenuDrawer: null,
	openEntityCategoryDrawerDict: {},
	mediaLibraryAudio: null,
	mediaLibraryDisplay: {
		fileTypes: [],
		onSubmitCategory: IMediaLibraryOnSubmitCategory.AddToScene,
	},
	tempAnimateModelAction: null,
	visibleCanvases: {
		selectedEntityPreview: false,
		modelAnimationPreview: false,
		modelUploadPreview: false,
		webEmbedPreview: false,
		screenRelativePreview: false,
		targetImagePreview: false,
		videoPlayer: false,
	},
	positionById: {},
	scaleById: {},
	rotationById: {},
	fillRgbaById: {},
	fontRgbaById: {},
	emitterAreaScalesById: {},
	borderRgbaById: {},
	activeFaceLandmark: null,
	showFaceLandmarks: false,
	hoveredDropdownFacelandmark: null,
	emitterIsStaticById: {},
	curvatureById: {},
	forceUpdateDragControlsTrigger: 0,
	temporaryDragRotation: [0, 0, 0],
	videoProgressById: {},
	showPublishPopup: false,
	publishTrigger: 0
};

const userReducer = (state: IUserState = initialState, action: any) => {
	switch (action.type) {
		case IUserActionTypes.SET_ACTIVE_EDITING_ACTION_DATA:
			return setActiveEditingActionData(state, action);
		case IUserActionTypes.SET_POPOUT_IDEAL_TOP_POSITION:
			return setPopOutIdealTopPosition(state, action);
		case IUserActionTypes.SET_MULTIPLE_ENTITY_SELECT_BOUNDARY:
			return setMultipleEntitySelectBoundary(state, action);
		case IUserActionTypes.SET_CHROMA_KEY_EDIT_MODAL_DATA:
			return setChromaKeyEditModalData(state, action);
		case IUserActionTypes.SET_SELECTED_TEMPLATE_PROJECT:
			return setSelectedTemplateProject(state, action);
		case IUserActionTypes.SET_TARGET_IMAGE_REFERENCE_OBJECT_POSITION:
			return setTargetImageReferenceObjectPosition(state, action);
		case IUserActionTypes.SET_ACTIVE_ANIMATION_PRESET:
			return setActiveAnimationPreset(state, action);
		case IUserActionTypes.OPEN_MODAL:
			return setOpenModal(state, action);
		case IUserActionTypes.CLOSE_MODAL:
			return setCloseModal(state, action);
		case IUserActionTypes.SET_MEDIA_LIBRARY_DISPLAY:
			return setMediaLibraryDisplay(state, action);
		case IUserActionTypes.SET_OPEN_SCENE_MENU_DRAWER:
			return setOpenSceneMenuDrawer(state, action);
		case IUserActionTypes.SET_TEMP_ANIMATE_MODEL_ACTION:
			return setTempAnimateModelAction(state, action);
		case IUserActionTypes.SET_OPEN_PROJECT_MENU_DRAWER:
			return setOpenProjectMenuDrawer(state, action);
		case IUserActionTypes.SET_WEB_EMBED_PREVIEW_SETTINGS:
			return setWebEmbedPreviewSettings(state, action);
		case IUserActionTypes.SET_WEB_EMBED_EDITING_MODE:
			return setIsWebEmbedEditingMode(state, action);
		case IUserActionTypes.SET_OPEN_ENTITY_MENU_DRAWER:
			return setOpenEntityMenuDrawer(state, action);
		case IUserActionTypes.SET_OPEN_ENTITY_MENU_CATEGORY_DRAWER:
			return setOpenEntityMenuCategoryDrawer(state, action);
		case IUserActionTypes.SET_REPLACE_ENTITY_MODE:
			return setReplaceEntityMode(state, action);
		case IUserActionTypes.SET_FILE_VERIFYING:
			return setFileVerifying(state, action);
		case IUserActionTypes.SET_OPEN_INSPECTOR_MENU_ENTITY_SHELF:
			return setOpenInspectorMenuEntityShelf(state, action);
		case IUserActionTypes.SET_USER_ID:
			return setUserId(state, action);
		case IUserActionTypes.UPDATE_USERS:
			return updateUsers(state, action);
		case IUserActionTypes.SET_TRACKING_IMG_DATA:
			return setTrackingImageData(state, action);
		case IUserActionTypes.REMOVE_TRACKING_IMG_DATA:
			return removeTrackingImageData(state);
		case IUserActionTypes.SET_TRACKING_IMG_UPLOADING:
			return setTrackingImageIsUploading(state, action);
		case IUserActionTypes.CANVAS_IS_LOADED:
			return setCanvasIsLoaded(state, action);
		case IUserActionTypes.SET_LOADED_AUDIO:
			return setLoadedAudio(state, action);
		case IUserActionTypes.SET_LOADED_360_BACKGROUND:
			return setLoadedBackground360Content(state, action);
		case IUserActionTypes.ADD_TO_LOADED_MEDIA:
			return addToLoadedMedia(state, action);
		case IUserActionTypes.ADD_TO_LOADED_AUDIO:
			return addToLoadedAudio(state, action);
		case IUserActionTypes.REMOVE_LOADED_MEDIA:
			return removeLoadedMedia(state, action);
		case IUserActionTypes.LOADING_MEDIA:
			return isLoadingMedia(state, action);
		case IUserActionTypes.REMOVE_UPLOAD:
			return removeUpload(state, action);
		case IUserActionTypes.SET_CURRENT_UPLOADS:
			return setCurrentUploads(state, action);
		case IUserActionTypes.ADD_TOAST:
			return addToast(state, action);
		case IUserActionTypes.REMOVE_TOAST:
			return removeToast(state, action);
		case IUserActionTypes.SET_PROJECT_INFO:
			return setProjectInfo(state, action);
		case IUserActionTypes.SET_HOTKEY:
			return setHotKey(state, action);
		case IUserActionTypes.SET_MARKER_INDEX_PRESSED:
			return setMarkerIndexPressed(state, action);
		case IUserActionTypes.REMOVE_HOTKEY:
			return removeHotKey(state, action);
		case IUserActionTypes.SET_POSITIONING_ACTIVE:
			return setPositioningActive(state, action);
		case IUserActionTypes.SET_ROTATION_ACTIVE:
			return setRotationActive(state, action);
		case IUserActionTypes.SET_SCALE_HOTSPOT_ENABLED:
			return setScaleHotSpotEnabled(state, action);
		case IUserActionTypes.SET_BACKGROUND_HOTSPOT_ENABLED:
			return setBackgroundHotSpotEnabled(state, action);
		case IUserActionTypes.SET_CLICK_OFFSET:
			return setPointerOffset(state, action);
		case IUserActionTypes.ADD_ENTITY_IDS_TO_SELECTION:
			return addEntityIdsToSelection(state, action);
		case IUserActionTypes.REMOVE_SELECTION:
			return removeSelection(state, action);
		case IUserActionTypes.REMOVE_LOCAL_USER_SELECTION:
			return removeLocalUserSelection(state, action);
		case IUserActionTypes.CHANGE_ZOOM_LEVEL:
			return changeZoomLevel(state, action);
		case IUserActionTypes.DISABLE_UNDO_BTN:
			return disableUndo(state, action);
		case IUserActionTypes.DISABLE_REDO_BTN:
			return disableRedo(state, action);
		case IUserActionTypes.SET_GROUP_INVERSION:
			return setGroupInversion(state, action);
		case IUserActionTypes.SET_SELECTION_2D_POSITION:
			return setSelection2DPosition(state, action);
		case IUserActionTypes.SET_SELECTION_2D_TOP_LEFT_POSITION:
			return setSelection2DTopLeftPosition(state, action);
		case IUserActionTypes.SET_SELECTION_2D_TOP_RIGHT_POSITION:
			return setSelection2DTopRightPosition(state, action);
		case IUserActionTypes.SET_SELECTION_2D_BOTTOM_LEFT_POSITION:
			return setSelection2DBottomLeftPosition(state, action);
		case IUserActionTypes.SET_SELECTION_2D_BOTTOM_RIGHT_POSITION:
			return setSelection2DBottomRightPosition(state, action);
		case IUserActionTypes.SET_ROTATION_MARKER_2D_POSITION:
			return setRotationMarker2DPosition(state, action);
		case IUserActionTypes.SET_ENTITY_MENU_DRAG_ACTIVE:
			return setEntityMenuDragActive(state, action);
		case IUserActionTypes.SET_ENTITY_MENU_DRAG_HOTSPOT_POSITION:
			return setEntityMenuHotspotDragPosition(state, action);
		case IUserActionTypes.SET_PURE_REACT_IN_CANVAS_HOVERED:
			return setPureReactInCanvasHovered(state, action);
		case IUserActionTypes.SET_ENTITY_MENU_DRAG_INFO:
			return setEntityDragInfo(state, action);
		case IUserActionTypes.SET_AUDIO_STATUS:
			return setAudioStatus(state, action);
		case IUserActionTypes.COPY_SELECTED_ENTITIES:
			return copySelectedEntities(state, action);
		case IUserActionTypes.SET_ACTIVE_SCENE:
			return setActiveScene(state, action);
		case IUserActionTypes.SET_CURRENT_SCENE_INDEX:
			return setCurrentSceneIndex(state, action);
		case IUserActionTypes.SCROLL_SCENE_MENU:
			return scrollSceneMenu(state, action);
		case IUserActionTypes.SET_SCENE_SNAPSHOTS:
			return setSortedSceneSnapshots(state, action);
		case IUserActionTypes.SET_SCENE_ENTITY_LIST_VISIBLE:
			return setSceneEntityListVisible(state, action);
		case IUserActionTypes.SET_SCENE_CAROUSEL_SCROLL_LEFT:
			return setSceneCarouselScrollLeft(state, action);
		case IUserActionTypes.SET_SCENE_MENU_LARGE:
			return setSceneMenuLarge(state, action);
		case IUserActionTypes.FOCUS_SCENE_NAME_INPUT:
			return focusSceneNameInput(state, action);
		case IUserActionTypes.SET_SOCKET_CONNECTED:
			return setSocketConnected(state, action);
		case IUserActionTypes.SET_PROJECT_LOADING_PROGRESS:
			return setProjectLoadingProgress(state, action);
		case IUserActionTypes.SET_PROJECT_LOADING_FAILURE:
			return setProjectLoadingFailure(state, action);
		case IUserActionTypes.EDIT_SCENE_TITLE:
			return setEditSceneTitle(state, action);
		case IUserActionTypes.SET_SELECTED_ENTIY_IDS:
			return setSelectedEntityIds(state, action);
		case IUserActionTypes.SET_3D_MODE:
			return set3dMode(state, action);
		case IUserActionTypes.SET_SCREEN_RELATIVE_MODE:
			return setIsScreenRelativeMode(state, action);
		case IUserActionTypes.SET_TRANSFORM_CONTROLS_ACTIVE:
			return setTransformControlsActive(state, action);
		case IUserActionTypes.SET_TRANSFORM_CONTROLS_MODE:
			return setTransformControlsMode(state, action);
		case IUserActionTypes.SET_SCREEN_RELATIVE_DEVICE:
			return setScreenRelativeDevice(state, action);
		case IUserActionTypes.SET_SCREEN_RELATIVE_DEVICE_PREVIOUS_CUSTOM_DIMENSIONS:
			return setScreenRelativeDevicePreviousCustomDimensions(state, action);
		case IUserActionTypes.SET_MEDIA_LIBRARY_AUDIO:
			return setMediaLibraryAudio(state, action);
		case IUserActionTypes.SET_VISIBLE_CANVAS:
			return setVisibleCanvas(state, action);
		case IUserActionTypes.SET_FILL_RGBA:
			return setFillRgba(state, action);
		case IUserActionTypes.SET_FONT_RGBA:
			return setFontRgba(state, action);
		case IUserActionTypes.SET_BORDER_RGBA:
			return setBorderRgba(state, action);
		case IUserActionTypes.SET_POSITIONS:
			return setPositions(state, action);
		case IUserActionTypes.SET_CURVATURES:
			return setCurvatures(state, action);
		case IUserActionTypes.SET_SCALES:
			return setScales(state, action);
		case IUserActionTypes.SET_EMITTER_AREA_SCALES:
			return setEmitterAreaScales(state, action);
		case IUserActionTypes.SET_ROTATIONS:
			return setRotations(state, action);
		case IUserActionTypes.SET_ACTIVE_FACE_LANDMARK:
			return setActiveFaceLandmark(state, action);
		case IUserActionTypes.SET_SHOW_FACE_LANDMARKS:
			return setShowFaceLandmarks(state, action);
		case IUserActionTypes.SET_HOVERED_FACE_LANDMARK_DROPDOWN:
			return setHoveredDropdownFaceLandmark(state, action);
		case IUserActionTypes.SET_EMITTER_IS_STATIC:
			return setEmitterIsStatic(state, action);
		case IUserActionTypes.SET_PREVIEW_CODE_SRC:
			return setPreviewCodeSrc(state, action);
		case IUserActionTypes.SET_PREVIEW_URL:
			return onSetPreviewUrl(state, action);
		case IUserActionTypes.SET_DRAG_CONTROLS_UPDATE_TRIGGER:
			return setDragControlsUpdateTrigger(state, action)
		case IUserActionTypes.SET_TEMP_INITIAL_ROTATION:
			return setTemporaryDragRotation(state, action)
		case IUserActionTypes.SET_VIDEO_RENDITION_PROGRESS:
			return setVideoRenditionProgress(state, action)
		case IUserActionTypes.SET_SHOW_PUBLISH_POPUP:
			return setPublishPopup(state, action)
		case IUserActionTypes.SET_PUBLISH_TRIGGER:
			return setPublishTrigger(state, action);
		default:
			return state;
	}
};

export default userReducer;
