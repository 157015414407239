import {
	IButton,
	IComponentType,
	IEmitter,
	IFaceLandmark,
	IFaceLandmarkGroup,
	IImage,
	IModel3d,
	IScreenAnchorGroup,
	IScreenAnchorPositionType,
	ISpatialComponentUnion,
	IText,
	IVideo,
} from '../../components/r3f/r3f-components/component-data-structure/index';

export function createSpatialComponent(spatialComponentType: IComponentType.Button, spatialComponentData: Partial<IButton>): IButton | null;
export function createSpatialComponent(spatialComponentType: IComponentType.Text, spatialComponentData: Partial<IText>): IText | null;
export function createSpatialComponent(spatialComponentType: IComponentType.Image, spatialComponentData: Partial<IImage>): IImage | null;
export function createSpatialComponent(spatialComponentType: IComponentType.Video, spatialComponentData: Partial<IVideo>): IVideo | null;
export function createSpatialComponent(spatialComponentType: IComponentType.Model3d, spatialComponentData: Partial<IModel3d>): IModel3d | null;
export function createSpatialComponent(spatialComponentType: IComponentType.Emitter, spatialComponentData: Partial<IEmitter>): IEmitter | null;
export function createSpatialComponent(spatialComponentType: IComponentType, spatialComponentData: Partial<ISpatialComponentUnion>): ISpatialComponentUnion | null {
	const spatialComponent = {
		type: spatialComponentType,
		...spatialComponentData,
	} as ISpatialComponentUnion;

	return spatialComponent;
}

export const createScreenAnchorGroup = (anchorPositionType: IScreenAnchorPositionType): IScreenAnchorGroup => {
	return {
		type: IComponentType.ScreenAnchorGroup,
		anchorPositionType,
		children: [],
	};
};

export const createFaceLandmarkGroup = (landmark: IFaceLandmark): IFaceLandmarkGroup => {
	return {
		type: IComponentType.FaceLandmarkGroup,
		landmark,
		children: [],
	};
};
