import * as THREE from 'three';

export const MOVE_AWAY_COLOR = 0x26b0ff;
export const MOVE_AWAY_COLOR_INACTIVE = 0xb3e3ff;
export const MOVE_VERTICAL_COLOR = 0x26ff9a;
export const MOVE_VERTICAL_COLOR_INACTIVE = 0xb3ffdb;
export const MOVE_AROUND_COLOR = 0xff264a;
export const MOVE_AROUND_COLOR_INACTIVE = 0xffb3bf;
export const HELPER_CIRCLE_COLOR = 0x26b0ff;

const toDegrees = (radians: number): number => (radians * 180) / Math.PI;

export const getSnapEntityPositionOnTarget = (radius: number, currentPosition: number[]) => {
	const x = currentPosition[0];
	const z = currentPosition[2];
	const y = currentPosition[1];

	// If x = 0 then return base case and avoid divide by zero
	if (x == 0) return [0, y, radius];

	// Otherwise calculate the appropriate position
	const theta = Math.atan(z / x);
	const newX = radius * Math.cos(theta) * Math.sign(x);
	const newZ = radius * Math.sin(theta) * Math.sign(x);
	return [newX, y, newZ];
};

export const getSnapEntityRotationOnTarget = (radius: number, currentPosition: number[], degrees = false) => {
	const x = currentPosition[0];
	const z = currentPosition[2];
	const theta = Math.acos(z / radius);
	const yRotation = x > 0 ? theta : -theta;
	return [0, degrees ? toDegrees(yRotation) : yRotation, 0] as [number, number, number];
};

export const getRadiusFromCurvature = (curvature: number, width: number) => {
	const radius = width / (2 * Math.PI * curvature);
	return Math.abs(radius);
};

export const getSnapEntityCurvatureOnTarget = (radius: number, scale: number[]) => {
	const curvature = -(scale[0] / radius) / (Math.PI * 2);
	return curvature;
};

export const getMoveFromTarget = (increment = 0.1, currentEntityPosition: THREE.Vector3, width: number) => {
	const x = currentEntityPosition.x;
	const z = currentEntityPosition.z;
	const h = Math.hypot(x, z);
	const theta = Math.abs(Math.atan(x / z));
	const newH = h + increment;
	const newX = newH * Math.sin(theta) * Math.sign(x);
	const newZ = newH * Math.cos(theta) * Math.sign(z);
	const newPosition = new THREE.Vector3(newX, currentEntityPosition.y, newZ);
	const newCurvature = -(width / newH) / (Math.PI * 2);
	return { newPosition, newCurvature };
};

export const getMoveAroundTarget = (increment = 0.1, currentPosition: THREE.Vector3, degrees = false) => {
	const x = currentPosition.x;
	const z = currentPosition.z;
	const h = Math.hypot(x, z);
	let theta = 0;

	// theta angle from 0 to 2*PI starting x=0 counter clockwise
	if (x > 0 && z > 0) {
		// Q1
		theta = Math.atan(x / z);
	} else if (x > 0 && z < 0) {
		// Q2
		theta = Math.abs(Math.atan(z / x)) + Math.PI / 2;
	} else if (x < 0 && z < 0) {
		// Q3
		theta = Math.abs(Math.atan(x / z)) + Math.PI;
	} else if (x < 0 && z > 0) {
		// Q4
		theta = Math.abs(Math.atan(z / x)) + 3 * (Math.PI / 2);
	}
	// console.log(`Theta is ${theta}`);

	const newTheta = theta + increment;
	const newX = h * Math.sin(newTheta);
	const newZ = h * Math.cos(newTheta);
	// console.log("initial theta", theta, "newTheta", newTheta, "x", x, "z", z, "h", h, "newX", newX, "newZ", newZ, "increment", increment);

	const newPosition = new THREE.Vector3(newX, currentPosition.y, newZ);
	const newRotation = new THREE.Euler(0, degrees ? toDegrees(newTheta) : newTheta, 0);
	return { newPosition, newRotation };
};
