import { BufferAttribute, BufferGeometry, Float32BufferAttribute, Vector3 } from 'three';

interface IParameters {
	width: number;
	height: number;
	widthSegments: number;
	cornerRadiusPercentage: number;
	curvature: number;
	borderSize: number;
}
class CurvedEntityGeometry extends BufferGeometry {
	parameters: IParameters;
	public curvature: number;
	readonly type: string;
	constructor(width = 1, height = 1, widthSegments = 1, cornerRadiusPercentage = 1, curvature = 0, borderSize = 0) {
		super();

		this.type = 'CurvedEntityGeometry';
		this.curvature = curvature;

		this.parameters = {
			width: width,
			height: height,
			widthSegments: widthSegments,
			cornerRadiusPercentage: cornerRadiusPercentage,
			curvature: curvature,
			borderSize: borderSize,
		};

		const indices = [] as number[];
		const vertices = [] as number[];
		const normals = [] as number[];
		const uvs = [] as number[];

		const width_half = width / 2;
		const height_half = height / 2;
		const border_half = borderSize / 2;
		const aspectRatio = Math.abs(width / height);
		let cornerRadius = Math.abs(cornerRadiusPercentage * height_half);
		if (aspectRatio < 1) cornerRadius *= aspectRatio;
		const numberOfVertices = (widthSegments + 1) * 2;

		const getUv = (x: number, y: number) => {
			const u = (x + width_half) / width;
			const v = (y + height_half) / height;
			return { u, v };
		};

		const addIndex = (v1: number, v2: number, v3: number) => {
			indices.push(v1, v2, v3);
		};

		const addVertex = (x: number, y: number, z: number, normalX: number, normalZ: number, flatX: number) => {
			const vertex = new Vector3(x, y, z);
			const normal = new Vector3(-normalX, 0, -normalZ).normalize();
			const { u, v } = getUv(flatX, vertex.y);
			vertices.push(vertex.x, vertex.y, vertex.z);
			normals.push(normal.x, normal.y, normal.z);
			uvs.push(u, v);
		};

		const interpolateX = (x: number) => {
			return Math.cos(x * Math.PI) * -0.5 + 0.5;
		};

		const clamp = (num: number, min: number, max: number) => Math.min(Math.max(num, min), max);

		const getCurvature = (x: number) => {
			if (curvature == 0) return { newX: x, newZ: 0, normalX: 0, normalZ: -1 };
			// Curvature = 1 then 2PI / full circle
			const circumference = Math.PI * 2 * curvature;
			const radius = width / circumference;
			// Linear mapping x along width to angle
			const theta = circumference * (x / width);
			// Get new X and Z along this circle arc
			const newX = radius * Math.sin(theta);
			const newZ = radius * (1 - Math.cos(theta));
			const normalX = Math.sin(theta);
			const normalZ = -Math.cos(theta);
			return { newX, newZ, normalX, normalZ };
		};

		// For inner
		const getYInner = (x: number) => {
			const x0 = x < 0 ? -Math.abs(width_half) + Math.abs(cornerRadius) : Math.abs(width_half) - Math.abs(cornerRadius);
			const y0 = Math.abs(height_half) - Math.abs(cornerRadius);
			const theta = Math.acos(clamp((x - x0) / Math.abs(cornerRadius), -1, 1));
			return Math.abs(cornerRadius) * Math.sin(theta) + y0;
		};

		// Inner vertices are required for both a border and an inner geometry
		for (let i = 0; i <= widthSegments; i++) {
			// Spread the i so that more vertices closer to the corners and less in center
			const j = interpolateX(i / widthSegments);
			const x = j * Math.abs(width) - Math.abs(width_half);
			if (Math.abs(x) > Math.abs(width_half) - Math.abs(cornerRadius)) {
				// Corner section
				const y = getYInner(x);
				const { newX, newZ, normalX, normalZ } = getCurvature(x);
				addVertex(newX, y, newZ, normalX, normalZ, x);
				addVertex(newX, -y, newZ, normalX, normalZ, x);
			} else {
				// Center section
				const y = Math.abs(height_half);
				const { newX, newZ, normalX, normalZ } = getCurvature(x);
				addVertex(newX, y, newZ, normalX, normalZ, x);
				addVertex(newX, -y, newZ, normalX, normalZ, x);
			}
		}

		// For border geometry
		const getYOuter = (x: number) => {
			const x0 = x < 0 ? -Math.abs(width_half) + Math.abs(cornerRadius) : Math.abs(width_half) - Math.abs(cornerRadius);
			const y0 = Math.abs(height_half) - Math.abs(cornerRadius);
			const theta = Math.acos(clamp((x - x0) / (Math.abs(cornerRadius) + Math.abs(border_half)), -1, 1));
			if (cornerRadius == 0) return height_half + border_half;
			return (Math.abs(cornerRadius) + Math.abs(border_half)) * Math.sin(theta) + y0;
		};

		// If there is a border size then this is a border curved geometry
		if (borderSize) {
			for (let i = 0; i <= widthSegments; i++) {
				// Spread the i so that more vertices closer to the corners and less in center
				const j = interpolateX(i / widthSegments);
				const x = j * (Math.abs(width) + Math.abs(borderSize)) - (Math.abs(width_half) + Math.abs(border_half));
				if (Math.abs(x) > Math.abs(width_half - cornerRadius)) {
					// Corner section
					const y = getYOuter(x);
					const { newX, newZ, normalX, normalZ } = getCurvature(x);
					addVertex(newX, y, newZ, normalX, normalZ, x);
					addVertex(newX, -y, newZ, normalX, normalZ, x);
				} else {
					// Center section
					const y = Math.abs(height_half) + Math.abs(border_half);
					const { newX, newZ, normalX, normalZ } = getCurvature(x);
					addVertex(newX, y, newZ, normalX, normalZ, x);
					addVertex(newX, -y, newZ, normalX, normalZ, x);
				}
			}
		}

		// If no border just add 'inner' faces
		if (!borderSize) {
			for (let i = 0; i < numberOfVertices - 2; i += 2) {
				addIndex(i, i + 1, i + 2);
				addIndex(i + 3, i + 2, i + 1);
			}
		} else {
			let outerStart = numberOfVertices;
			let innerStart = 0;
			for (let i = outerStart; i < 2 * numberOfVertices - 2; i += 2) {
				if (outerStart == numberOfVertices && innerStart == 0) {
					// Left side
					addIndex(outerStart, innerStart, outerStart + 2);
					addIndex(outerStart, outerStart + 1, innerStart);
					addIndex(outerStart + 3, innerStart + 1, outerStart + 1);
					addIndex(innerStart, innerStart + 2, outerStart + 2);
					addIndex(outerStart + 3, innerStart + 3, innerStart + 1);
					addIndex(outerStart + 1, innerStart + 1, innerStart);
				} else if (outerStart == 2 * numberOfVertices - 4) {
					// Right side
					addIndex(outerStart, innerStart, outerStart + 2);
					addIndex(innerStart, innerStart + 2, outerStart + 2);
					addIndex(innerStart + 2, outerStart + 3, outerStart + 2);
					addIndex(outerStart + 1, outerStart + 3, innerStart + 3);
					addIndex(innerStart + 3, innerStart + 1, outerStart + 1);
					addIndex(innerStart + 2, innerStart + 3, outerStart + 3);
				} else {
					// Middle
					addIndex(outerStart, innerStart, outerStart + 2);
					addIndex(innerStart + 2, outerStart + 2, innerStart);
					addIndex(innerStart + 1, outerStart + 1, innerStart + 3);
					addIndex(outerStart + 3, innerStart + 3, outerStart + 1);
				}
				outerStart += 2;
				innerStart += 2;
			}
		}

		this.setIndex(new BufferAttribute(new Uint32Array(indices), 1));
		this.setAttribute('position', new Float32BufferAttribute(vertices, 3));
		this.setAttribute('normal', new Float32BufferAttribute(normals, 3));
		this.setAttribute('uv', new Float32BufferAttribute(uvs, 2));
	}

	copy(source: CurvedEntityGeometry) {
		super.copy(source);
		this.parameters = Object.assign({}, source.parameters);
		return this;
	}

	static fromJSON(data: IParameters) {
		return new CurvedEntityGeometry(data.width, data.height, data.widthSegments, data.cornerRadiusPercentage, data.curvature, data.borderSize);
	}
}

export { CurvedEntityGeometry };
