/* eslint-disable @typescript-eslint/no-namespace */
import { BufferGeometryNode, extend, MaterialNode, Object3DNode, ThreeEvent } from '@react-three/fiber';
import React, { forwardRef, Suspense, useEffect, useMemo, useRef } from 'react';
import { Color, DoubleSide, Group, Intersection, Object3D, Raycaster, Shader, Side, Texture, WebGLRenderer } from 'three';
import { FONT_TYPES, IChromaKey, IFontTypes, ITextAlignment, ITuple3, ITuple4 } from '../../component-data-structure';
import DreiText, { IOnTextSync } from '../DreiText';
import { CurvedEntityGeometry } from './CurvedEntityGeometry';
import { getRadiusFromCurvature } from './gizmoUtil';
import { MeshChromaKeyMaterial } from './MeshChromaKeyMaterial';

const isSafari = navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1;

extend({ CurvedEntityGeometry });
extend({ MeshChromaKeyMaterial });
type Object3DProps = Object3DNode<Object3D, typeof Object3D>;
declare global {
	namespace JSX {
		interface IntrinsicElements {
			curvedEntityGeometry: BufferGeometryNode<CurvedEntityGeometry, typeof CurvedEntityGeometry>;
			meshChromaKeyMaterial: MaterialNode<MeshChromaKeyMaterial, typeof MeshChromaKeyMaterial>;
			object3D: Object3DProps;
		}
	}
}

interface ICurvedEntityText {
	value: string;
	textAlignment: ITextAlignment;
	fontSize: number;
	fontFamily: IFontTypes;
	fontRgba: ITuple4;
	side?: Side;
	preventPolygonOffset?: boolean;
}

interface ICurvedEntityBorder {
	borderSize: number;
	borderRgba: ITuple4;
}

interface IParentProps {
	position?: ITuple3;
	scale: ITuple3;
	opacity: number;
	renderOrder: number;
	cornerRadius: number;
	curvature: number;
	colorRgba?: ITuple4;
	imageTexture?: Texture;
	videoTexture?: Texture;
	isVideo?: boolean;
	hasAlpha?: boolean;
	visible?: boolean;
	border?: ICurvedEntityBorder;
	text?: ICurvedEntityText;
	stackedAlpha?: boolean;
	name?: string;
	side?: Side;
	isTextScaleLocked?: boolean;
	castShadow?: boolean;
	receiveShadow?: boolean;
	raycast?: (_: Raycaster, intersects: Intersection<Object3D<Event>>[]) => void;
	onPointerDown?: (e: ThreeEvent<PointerEvent>) => unknown;
	onPointerUp?: (e: ThreeEvent<PointerEvent>) => unknown;
	onDoubleClick?: (e: ThreeEvent<MouseEvent>) => unknown;
	chromaKey?: IChromaKey;
	onTextSync?: IOnTextSync;
}

const TEXT_ENTITY_PADDING = 0.027;

const getEntityTextPos = (text: ICurvedEntityText, scale: ITuple3) => {
	const { textAlignment } = text;
	let xPos: number;
	let anchorXPos: 'left' | 'right' | 'center' | undefined;
	switch (textAlignment) {
		case ITextAlignment.left: {
			xPos = -scale[0] / 2 + TEXT_ENTITY_PADDING;
			anchorXPos = 'left';
			break;
		}
		case ITextAlignment.right: {
			xPos = scale[0] / 2 - TEXT_ENTITY_PADDING;
			anchorXPos = 'right';
			break;
		}
		default: {
			xPos = 0;
			anchorXPos = 'center';
		}
	}
	const textPos = [xPos, 0, 0] as ITuple3;
	return { textPos, anchorXPos };
};

const getMaterial = (args: {
	stackedAlpha: boolean;
	hasAlpha: boolean;
	opacity: number;
	side: Side;
	videoTexture?: Texture;
	onBeforeCompile: (shader: Shader, renderer: WebGLRenderer) => void;
	imageTexture?: Texture;
	innerColor?: Color;
	chromaKey?: IChromaKey;
}) => {
	const { stackedAlpha, hasAlpha, opacity, side, videoTexture, onBeforeCompile, imageTexture, innerColor, chromaKey } = args;
	// if(!!chromaKey) console.log('getMaterial: stackedAlpha: ', stackedAlpha, 'hasAlpha: ', hasAlpha, '!!videoTexture: ', !!videoTexture, '!!imageTexture: ', !!imageTexture);

	// Stacked alpha video
	if (stackedAlpha && !!videoTexture) {
		// console.log('stacked alpha video');
		return <alphaMaterial packedTexture={videoTexture || new Texture()} transparent={true} opacity={opacity} depthWrite={false} side={side} />;
	}

	// Video that isn't alpha and doesn't have choma key active
	if (!hasAlpha && !!videoTexture) {
		// console.log('non alpha no chroma key video');
		return <meshBasicMaterial onBeforeCompile={onBeforeCompile} map={videoTexture} side={side} opacity={opacity} transparent={true} wireframe={false} depthWrite={false} />;
	}

	// Video that isn't alpha but does have chroma key active
	if (!!videoTexture) {
		// console.log('Video texture')
		if (hasAlpha && !!chromaKey && !!chromaKey.color) {
			// console.log('non alpha, does have chroma key video');
			return (
				// <meshBasicMaterial map={videoTexture} side={side} opacity={opacity} transparent={true} wireframe={false} depthWrite={false} />
				<meshChromaKeyMaterial
					onBeforeCompile={onBeforeCompile}
					uniforms={{
						map: { value: videoTexture },
						similarity: { value: chromaKey?.similarity },
						smoothness: { value: chromaKey?.smoothness },
						spill: { value: chromaKey?.spill },
						keyColor: { value: chromaKey?.color },
					}}
					needsUpdate={true}
					opacity={opacity}
					transparent={true}
					side={side}
					uniformsNeedUpdate={true}
					depthWrite={false}
				/>
			);
		} else {
			// console.log('NO chroma key');
			return <meshBasicMaterial onBeforeCompile={onBeforeCompile} map={videoTexture} side={side} opacity={opacity} transparent={true} wireframe={false} depthWrite={false}  />
		}
	}

	// Non video
	if (!!imageTexture) {
		// console.log('Image material');
		if (!!chromaKey && !!chromaKey.color && hasAlpha) {
			// console.log('chroma key, data: ', chromaKey)
			return <meshChromaKeyMaterial
				//onBeforeCompile={onBeforeCompile}
				uniforms={{
					map: { value: imageTexture },
					similarity: { value: chromaKey?.similarity },
					smoothness: { value: chromaKey?.smoothness },
					spill: { value: chromaKey?.spill }, 
					keyColor: { value: chromaKey?.color },
				}}
				needsUpdate={true}
				opacity={opacity}
				transparent={true}
				side={side}
				uniformsNeedUpdate={true}
				depthWrite={false}
			/>
		} else {
			// console.log('NO chroma key');
			return <meshBasicMaterial color={innerColor} map={imageTexture} side={side} opacity={opacity} transparent={true} wireframe={false} depthWrite={false} />;
		}
	}
	// console.log('No image or video, plain color material.')
	return <meshBasicMaterial color={innerColor} side={side} opacity={opacity} transparent={true} wireframe={false} depthWrite={false} />;
};

const CurvedEntity = forwardRef<Object3D, IParentProps>(({
	scale,
	visible = true,
	imageTexture,
	videoTexture,
	position,
	cornerRadius = 0,
	opacity = 1,
	colorRgba,
	curvature,
	renderOrder = 1,
	hasAlpha = false,
	stackedAlpha = false,
	raycast,
	text,
	isTextScaleLocked,
	border,
	name = 'Curved Entity',
	side = DoubleSide,
	onPointerDown,
	onPointerUp,
	onDoubleClick,
	chromaKey,
	castShadow = false,
	receiveShadow = false,
	onTextSync
}, ref) => {
	// All cases
	const width = scale[0];
	const widthSegments = Math.floor(Math.abs(width) * 80);
	const height = scale[1];
	const radius = getRadiusFromCurvature(curvature, width) * Math.sign(curvature);
	const innerColor = !!colorRgba ? new Color(`rgb(${colorRgba[0]}, ${colorRgba[1]}, ${colorRgba[2]})`) : undefined;
	const textWrapperGroupRef = useRef<Group>(null)
	const prevXScale = useRef<number>(0.32);
	const hasRunRef = useRef(false);
	//const initialScaleRef = useRef(1/scale[0]);

	const onBeforeCompile = (shader: Shader) => {
		if (!isSafari) return;
		//console.log('OnbeforeCompile video texture');
		shader.fragmentShader = shader.fragmentShader.replace(
			'#include <map_fragment>',
			`
			#ifdef USE_MAP
			
				vec4 sampledDiffuseColor = texture2D( map, vMapUv );
			
				// inline sRGB decode
				sampledDiffuseColor = vec4( mix( pow( sampledDiffuseColor.rgb * 0.9478672986 + vec3( 0.0521327014 ), vec3( 2.4 ) ), sampledDiffuseColor.rgb * 0.0773993808, vec3( lessThanEqual( sampledDiffuseColor.rgb, vec3( 0.04045 ) ) ) ), sampledDiffuseColor.a );
		
				diffuseColor *= sampledDiffuseColor;
		
			#endif
		
			`
		);
	};

	useEffect(() => {
		if (!isTextScaleLocked) return;
		prevXScale.current = scale[0];
	}, [isTextScaleLocked])

	useEffect(() => {
		hasRunRef.current = true;
		if (!isTextScaleLocked) return;
		const ratio = Math.abs(scale[0] / prevXScale.current);

		// if (ratio < 0) return;
		
		// console.log('adjust', ratio)
		prevXScale.current = scale[0]
		textWrapperGroupRef.current?.scale.multiplyScalar(ratio);
	}, [scale[0]])

	// Border related
	const borderColor = border?.borderRgba ? new Color(`rgb(${border?.borderRgba[0]}, ${border?.borderRgba[1]}, ${border?.borderRgba[2]})`) : undefined;

	// Text related
	const fontColor = text?.fontRgba ? new Color(`rgb(${text?.fontRgba[0]}, ${text?.fontRgba[1]}, ${text?.fontRgba[2]})`) : undefined;
	const filteredArray = text ? FONT_TYPES.filter((font) => font.fontFamily === text.fontFamily) : [];
	const { textPos, anchorXPos } = text ? getEntityTextPos(text, scale) : { textPos: [0, 0, 0] as ITuple3, anchorXPos: undefined };

	// Memoise geometries
	const curvedEntityGeometry = useMemo(() => {
		if (width === 0 || height === 0 || widthSegments === 0) return null;
		return <curvedEntityGeometry args={[width, height, widthSegments, cornerRadius, curvature]} />;
	}, [width, height, cornerRadius, curvature, widthSegments]);

	const outerGeometry = useMemo(() => {
		if (width === 0 || height === 0 || widthSegments === 0) return null;
		return border && border.borderSize > 0 ? <curvedEntityGeometry args={[width, height, widthSegments, cornerRadius, curvature, border.borderSize]} /> : null;
	}, [width, height, cornerRadius, curvature, border, border?.borderSize, widthSegments]);

	if (typeof curvedEntityGeometry === null) return null;
	if (width === 0 || height === 0 || widthSegments === 0) return null;

	return (
		<Suspense fallback={null}>
			<object3D name={name} ref={ref} position={position} raycast={raycast} visible={visible} onPointerDown={onPointerDown} onPointerUp={onPointerUp} onDoubleClick={onDoubleClick}>
				{/*** INNER ***/}
				{opacity === 1.0 && !hasAlpha && (
					<mesh castShadow={castShadow} raycast={raycast} renderOrder={1}>
						{curvedEntityGeometry}
						<meshBasicMaterial colorWrite={false} polygonOffset={true} polygonOffsetUnits={10} polygonOffsetFactor={10} depthWrite={true} side={side} visible={false} />
					</mesh>
				)}
				{receiveShadow && <mesh receiveShadow renderOrder={renderOrder + 6} raycast={raycast}>
					{curvedEntityGeometry}
					<shadowMaterial
						opacity={0.2}
						polygonOffset
						polygonOffsetFactor={25}
						polygonOffsetUnits={10}
						/> 
				</mesh>}
				{/* TODO - this is temporary, while working on ChromaKey */}
				<mesh castShadow={castShadow} renderOrder={renderOrder + 3} raycast={raycast}>
					{curvedEntityGeometry}
					{/* TODO: change how we handle videoTexture here. For now required to not use a clone for stacked alpha videos as they appear to dark otherwise */}
					{getMaterial({ hasAlpha, stackedAlpha, opacity, side, videoTexture: stackedAlpha ? videoTexture:  videoTexture?.clone(), onBeforeCompile, imageTexture: imageTexture?.clone(), innerColor, chromaKey })}
				</mesh>


				{/*** TEXT ***/}
				{text && (
					<group ref={textWrapperGroupRef}>
						<DreiText
							raycast={raycast}
							key={'btn_text'}
							color={fontColor}
							alpha={text.fontRgba ? text.fontRgba[3] : 1}
							fontSize={text.fontSize}
							position={textPos}
							url={filteredArray[0]?.url}
							textAlign={text.textAlignment}
							fontFamily={filteredArray[0]?.fontFamily}
							anchorY={'middle'} // always middle
							anchorX={anchorXPos} // always left side at btn x center
							maxWidth={Math.abs(scale[0])}
							renderOrder={renderOrder + 5}
							depthWrite={false}
							curveRadius={radius}
							side={typeof text.side !== 'undefined' ? text.side : DoubleSide}
							curvature={curvature}
							//onTextSync={_onTextSync}
							preventPolygonOffset={!!text.preventPolygonOffset}
						>
							{text.value}
						</DreiText>
					</group>
				)}

				{/*** OUTER ***/}
				{border && border.borderSize > 0 && (
					<mesh castShadow={castShadow} renderOrder={renderOrder + 2} raycast={raycast}>
						{outerGeometry}
						<meshBasicMaterial color={borderColor} side={side} opacity={border.borderRgba[3]} transparent={true} wireframe={false} depthWrite={false} />
					</mesh>
				)}
			</object3D>
		</Suspense>
	);
});

CurvedEntity.displayName = 'curved-entity';
export default CurvedEntity;
