import { IIconParentProps } from '../src/assets/icons';
import { IAbstractComponentChildUnion, IAbstractComponentUnion, IButtonBaseState, IComponentsById, ITrackingTypes } from '../src/components/r3f/r3f-components/component-data-structure';

export enum ITemplateCategory {
	Blank = 'Blank',
	Image = 'Image',
	Face = 'Face',
	World = 'World',
	Content360 = '360'
}

export interface ICard {
	id: string;
	backgroundImage?: string;
	title?: string;
	paragraph?: string;
	icon?: React.FunctionComponent<IIconParentProps>[];
	disabled?: boolean;
	active?: boolean;
	pills?: string[];
	pillTitle?: string;
	onClick?: () => any;
}

export interface ITemplateCard extends ICard {
	type?: ITemplateCategory[];
	trackingType?: ITrackingTypes[];
	slug?: string;
}

export enum IDomIdSelectors {
	zapparCanvas = 'zapparCanvas',
	zapparApp = 'zappar-app',
	screenRelativeCanvas = 'screenRelativeCanvas',
	smSlide = 'sm-slide',
	uploadDialog = 'uploadDialog',
	root = 'root',
	undo = 'undo',
	redo = 'redo',
	inspectorMenu = 'inspectorMenu'
}

export enum IDomClassSelector {
	scenePopupMenu = 'scene-popup-menu',
}

export enum IEntityShelves {
	transforms = 'transforms',
	previews = 'previews', // content
	actions = 'actions',
	appearances = 'appearances',
	text = 'text',
	transitions = 'transitions',
	curve = 'curve',
	chromaKey = 'chromaKey',
	shadows = 'shadows'
}

export enum IEntityDrawerTypes {
	none = 'none',
	button = 'button',
	text = 'text',
	image = 'image',
	video = 'video',
	model3d = 'model3d',
	applets = 'applets',
	entityList = 'entityList',
}

export enum IEntityDrawerCategoryTypes {
	private = 'private',
	public = 'public',
}

export type IOpenEntityCategoryDrawerDict = Partial<Record<IEntityDrawerCategoryTypes, boolean>>;

export enum ICanvasName {
	selectedEntityPreview = 'selectedEntityPreview',
	webEmbedPreview = 'webEmbedPreview',
	modelUploadPreview = 'modelUploadPreview',
	screenRelativePreview = 'screenRelativePreview',
	targetImagePreview = 'targetImagePreview',
	videoPlayer = 'videoPlayer',
	camera360RotationPreview = 'camera360RotationPreview'
}
interface ISocialBtnMetaProps {
	style: React.CSSProperties;
}

export interface ISocialBtnProperties extends IButtonBaseState, ISocialBtnMetaProps {}

export interface IOldToNewIdDict {
	[id: string]: string | null;
}

export type IAbstractComponentCopyInclChildren<T> = {
	abstractComponentCopy: { [id: string]: T } & IComponentsById;
	abstractComponentChildrenCopy: IAbstractComponentChildrenById;
};

export type IAbstractComponentChildrenById = {
	[id: string]: IAbstractComponentChildUnion | IAbstractComponentCopyInclChildren<IAbstractComponentUnion> | null;
};
