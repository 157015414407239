import React, { FunctionComponent, useMemo } from 'react';
import { COLORS, convertToButton, useIsDoubleClick } from '../../../../../../../utils';
import { IButton, IButtonBaseState, IButtonCategory, IFontTypes, ITextAlignment } from '../../../../../../r3f/r3f-components/component-data-structure';
import CSS from './DefaultBtnGrid.scss';

interface IDefaultBtnMetaProps {
	style: React.CSSProperties;
}

export interface IDefaultBtnProperties extends IButtonBaseState, IDefaultBtnMetaProps {}

interface IParentProps {
	onPointerDown?: (btnInfo: IButton, e: React.PointerEvent<HTMLDivElement>) => unknown;
	onDoubleClick?: (btnInfo: IButton, e: React.MouseEvent<HTMLDivElement, MouseEvent>) => unknown;
}
export const BASIC_BUTTON_TEXT = 'Abc';
export const sharedDefaultProps = {
	id: '',
	renderOrder: 1,
	text: BASIC_BUTTON_TEXT,
	fontFamily: IFontTypes.Roboto400,
	fontSize: 0.11,
	scale: [0.37, 0.13, 0],
	position: [0, 0, 0],
	rotation: [0, 0, 0],
	textAlignment: ITextAlignment.center,
};

export const btnSharedStyle = {
	width: '100px',
	height: '36px',
	color: 'white',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	cursor: 'pointer',
	backgroundColor: COLORS.ocean,
};

const frameStyle = {
	width: '100px',
	height: '36px',
	border: '2px solid ' + COLORS.ocean,
	backgroundColor: 'transparent',
	color: COLORS.ocean,
};

export const slightlyRoundedStyle = {
	borderRadius: '6px',
};

const defaultBtnInfo: IDefaultBtnProperties[] = [
	{
		style: {
			...btnSharedStyle,
			...slightlyRoundedStyle,
		},
		borderRadius: 0.35,
		borderWidth: 0,
		borderRgba: [74,144,226, 1],
		fontRgba: [255, 255, 255, 1],
		color: [[74,144,226, 1]],
		curvature: 0,
		category: IButtonCategory.text,
		...sharedDefaultProps,
	},
	{
		style: {
			...btnSharedStyle,
			...slightlyRoundedStyle,
			...frameStyle,
		},
		borderRadius: 0.35,
		borderWidth: 0.01,
		borderRgba: [74,144,226, 1],
		fontRgba: [74,144,226, 1],
		color: [[255, 255, 255, 0]],
		curvature: 0,
		category: IButtonCategory.text,
		...sharedDefaultProps,
	}
];

const DefaultBtnGrid: FunctionComponent<IParentProps> = ({ onPointerDown, onDoubleClick }) => {
	const isDoubleClick = useIsDoubleClick();
	const btnInfo = useMemo(
		() =>
			defaultBtnInfo.map((btnDft) => {
				return { ...btnDft, id: crypto.randomUUID() };
			}),
		[]
	);

	const DefaultElements = btnInfo.map((opt) => {
		return (
			<div
				id={opt.id}
				key={opt.id}
				style={opt.style}
				onPointerDown={(e) => {
					if (isDoubleClick()) onDoubleClick?.(convertToButton(opt), e);
					else onPointerDown?.(convertToButton(opt), e);
				}}
			>
				{opt.text}
			</div>
		);
	});

	return <div className={CSS.Grid}>{DefaultElements}</div>;
};

export default DefaultBtnGrid;
