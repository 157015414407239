import { Environment } from '@react-three/drei';
import { useThree } from '@react-three/fiber';
import React, { FunctionComponent, memo, Suspense } from 'react';
import { Scene } from 'three';
import { ITuple3 } from '../../component-data-structure/index';
import { LIGHTING_AMBIENT_INTENSITY, LIGHTING_DIRECTIONAL_INTENSITY, LIGHTING_DIRECTIONAL_POSITION } from '../../utils/constants';
import envMap from './neutral.hdr';

interface IParentProps {
	envMapFiles?: string[] | string;
	enableDefaultEnvironment?: boolean;
	environmentSceneName?: string;
	directionalLightPosition?: ITuple3;
}

const Lighting: FunctionComponent<IParentProps> = ({ envMapFiles, environmentSceneName, directionalLightPosition, enableDefaultEnvironment = true }) => {
	const { scene } = useThree();

	const hasEnvMapFiles = envMapFiles && envMapFiles?.length > 0;
	const environmentScene = !!environmentSceneName ? (scene.getObjectByName(environmentSceneName) as Scene) : undefined;
	
	return (
		<Suspense fallback={null}>
			{hasEnvMapFiles && enableDefaultEnvironment && <Environment files={envMap} scene={environmentScene} />}
			<ambientLight intensity={hasEnvMapFiles ? LIGHTING_AMBIENT_INTENSITY : 0.5} />
			<directionalLight 
				shadow-camera-top={-25}
				shadow-camera-right={25} 
				shadow-camera-left={-25} 
				shadow-camera-bottom={25}
				castShadow 
				shadow-normalBias={0.2}
				shadow-bias={-0.0001}
				position={directionalLightPosition ?? LIGHTING_DIRECTIONAL_POSITION} 
				intensity={LIGHTING_DIRECTIONAL_INTENSITY} 
			/>
		</Suspense>
	);
};

Lighting.displayName = 'Lighting';
export default memo(Lighting);
