import React, { forwardRef } from 'react';
import { Color, MeshStandardMaterial } from 'three';
import useFaceMeshLoader from './utils/useFaceMeshLoader';

const FaceMesh = forwardRef((_props, ref) => {
    const mesh = useFaceMeshLoader({
        faceMeshLoaderOptions: { }, 
        material: new MeshStandardMaterial({
            color: new Color(0.392, 0.392, 0.392),
            polygonOffset: true,
            polygonOffsetFactor: -1, // positive value pushes polygon further away
            polygonOffsetUnits: 1,
        }),
        type: 'face'
    })

    mesh.castShadow = true;
    mesh.receiveShadow = true

    return <primitive ref={ref} scale={[1.001, 1, 1]} position={[0, 0, 0.001]} object={mesh} />
});

FaceMesh.displayName = 'faceMesh';
  
export default FaceMesh;