import { Camera } from '@react-three/fiber';
import { Object3D, Raycaster, Vector3 } from 'three';

const v3 = new Vector3();

export const isObjectVisible = (objectToTest: Object3D, camera: Camera, raycaster: Raycaster, occlude: Object3D[]) => {
	const elPos = v3.setFromMatrixPosition(objectToTest.matrixWorld);
	const screenPos = elPos.clone().project(camera);
	raycaster.setFromCamera(screenPos as any, camera);
	const intersects = raycaster.intersectObjects(occlude, true);
	if (intersects.length) {
		const intersectionDistance = intersects[0].distance;
		const pointDistance = elPos.distanceTo(raycaster.ray.origin);
		return pointDistance < intersectionDistance;
	} else {
		return true;
	}
};
