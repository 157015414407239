import { ThreeEvent, useThree } from '@react-three/fiber';
import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IDesignerState } from '../../../../typings';
import { onAddEntityIdsToSelection, onRemoveSelection, onSetBackgroundHotSpotEnabled, onSetMarkerIndexPressed } from '../../../store/actions';
import { HOTSPOT_SCALE, IShapeTypes, maths, useGetScreenComponents } from '../../../utils';
import { IButtonSubCategory, IComponentType, ISceneComp, IScreenAnchorGroup, IScreenContent, ISpatialComponentUnion, ITuple3, ITuple3Area } from '../../r3f/r3f-components/component-data-structure';
import Plane from '../Plane/Plane';
import { isAbstractComponent, removeNonEntityIntersections } from '../r3f-components/utils/general';
import { getWorldPositionForScreenEntity } from './hotspotUtils';

interface IParentProps {
	namePrefix?: string;
	parentLocalSpace?: boolean;
}

const BackgroundHotspot: FunctionComponent<IParentProps> = ({ namePrefix, parentLocalSpace = false }) => {
	// Redux
	const dispatch = useDispatch();
	const activeSceneId = useSelector((state: IDesignerState) => state.userReducer.activeSceneId!);
	const selectedEntityIds = useSelector((state: IDesignerState) => state.userReducer.selectedEntityIds!);
	const backgroundHotspotIsEnabled = useSelector((state: IDesignerState) => state.userReducer.backgroundHotspotIsEnabled);
	const rotationIsActive = useSelector((state: IDesignerState) => state.userReducer.rotationIsActive);
	const rotationMarkerPressed = useSelector((state: IDesignerState) => state.userReducer.markerIndexPressed === 999);
	const contentEntityDict = useSelector((state: IDesignerState) => state.contentReducer.contentDoc.componentsById);
	const activeScene = contentEntityDict[activeSceneId] as ISceneComp;
	const isScreenRelativeMode = useSelector((state: IDesignerState) => state.userReducer.isScreenRelativeMode);
	const componentsById = useSelector((state: IDesignerState) => state.contentReducer.contentDoc.componentsById);
	const selectableScreenRelativeComponentIds = useGetScreenComponents(activeSceneId, true);

	// State
	const [initialPosition, setInitialPosition] = useState<ITuple3 | null>(null);
	const [dragAreaPosition, setDragAreaPosition] = useState<ITuple3 | null>(null);
	const [dragAreaScale, setDragAreaScale] = useState<ITuple3>([0, 0, 0]);
	const [oldPosition, setOldPosition] = useState<ITuple3 | null>(null);

	const { scene } = useThree();

	const isMountedRef = useRef(true);
	useEffect(() => {
		return () => {
			isMountedRef.current = false;
		};
	}, []);

	useEffect(() => {
		return () => {
			dispatch(onSetBackgroundHotSpotEnabled(false));
		};
	}, []);

	const pointerDownHandler = (e: ThreeEvent<PointerEvent>) => {
		// Return early if an entity is in the intersection array ( takes priority )
		if (removeNonEntityIntersections(e.intersections).length > 0) return;
		if (e.buttons === 2) return;
		(e.target as HTMLElement).setPointerCapture(e.pointerId);

		const { x, y, z } = parentLocalSpace ? e.eventObject.parent!.worldToLocal(e.point.clone()) : e.point;
		if (!rotationMarkerPressed) {
			dispatch(onRemoveSelection(selectedEntityIds));
		}
		if (!rotationMarkerPressed) dispatch(onSetBackgroundHotSpotEnabled(true));
		if (isMountedRef.current) setInitialPosition([x, y, z]);
	};

	const pointerMoveHandler = (e: ThreeEvent<PointerEvent>) => {
		if (e.buttons === 2) return;
		const { x, y, z } = parentLocalSpace ? e.eventObject.parent!.worldToLocal(e.point.clone()) : e.point;
		// console.log(x, y, z)
		if (!initialPosition || (initialPosition[0] === x && initialPosition[1] === y && initialPosition[2] === z)) return;
		// Return if position has not changed (re-render and/or continuous firing)
		if (oldPosition && maths.vec3.equal([x, y, z], oldPosition)) return;
		if (isMountedRef.current) setOldPosition([x, y, z]);

		// Calc drag area (scale, coords & position)
		const newDragAreaScale = [(x - initialPosition[0]) / 2, (y - initialPosition[1]) / 2, 0] as ITuple3;

		const dragAreaCoords = [initialPosition, [x, initialPosition[1], 0], [x, y, z], [initialPosition[0], y, 0]] as ITuple3Area;

		// Check for drag area changes (using 'old' drag component state)
		let xIncreased = false;
		let yIncreased = false;
		if (Math.abs(dragAreaScale[0]) - Math.abs(newDragAreaScale[0]) <= 0) xIncreased = true;
		if (Math.abs(dragAreaScale[1]) - Math.abs(newDragAreaScale[1]) <= 0) yIncreased = true;

		// Only then update state
		if (isMountedRef.current) {
			setDragAreaPosition([initialPosition[0] + (x - initialPosition[0]) / 2, initialPosition[1] + (y - initialPosition[1]) / 2, -0.001]);
			setDragAreaScale(newDragAreaScale);
		}

		// If drag x & y increase filter out selected entitis (no need to check if selected)
		let entityIdsToCheck = !isScreenRelativeMode ? activeScene.children : selectableScreenRelativeComponentIds;
		if (xIncreased && yIncreased) {
			entityIdsToCheck = entityIdsToCheck.filter((id) => !selectedEntityIds.includes(id) || !(contentEntityDict[id] as ISpatialComponentUnion).isLocked);
			// if drag x & y decrease only check selected entities (no need to check non-selected ones)
		} else if (!xIncreased && !yIncreased) {
			entityIdsToCheck = selectedEntityIds;
		}

		const addIdArray: string[] = [];
		const removeIdArray: string[] = [];

		for (let i = 0; i < entityIdsToCheck.length; i++) {
			const id = entityIdsToCheck[i];
			const component = contentEntityDict[id] as ISpatialComponentUnion | IScreenContent | IScreenAnchorGroup;
			if (isAbstractComponent(component)) continue;
			const { position, rotation, scale } = component;
			const isLocked = (contentEntityDict[id] as ISpatialComponentUnion).isLocked;
			if (isLocked) continue;
			const worldPosition = getWorldPositionForScreenEntity(id, activeSceneId, componentsById, position, scene) ?? position;
			const entityHotspotCoords = maths.localToWorldPosition2d(
				rotation[2],
				[worldPosition[0], worldPosition[1]],
				[
					[-scale[0], scale[1]],
					[scale[0], scale[1]],
					[scale[0], -scale[1]],
					[-scale[0], -scale[1]],
				]
			) as ITuple3Area;

			const entityIsCompletely = maths.isCompletelyInOrOutOfDragArea(entityHotspotCoords, dragAreaCoords);

			if (entityIsCompletely.inside) {
				if (!selectedEntityIds.includes(id)) addIdArray.push(id);
				return;
			}

			if (entityIsCompletely.outside) {
				if (selectedEntityIds.includes(id)) removeIdArray.push(id);
				return;
			}

			if (maths.areasAreColliding2d(entityHotspotCoords, dragAreaCoords, rotation[2], IShapeTypes.rect)) {
				if (!selectedEntityIds.includes(id)) addIdArray.push(id);
				continue;
			}
			if (selectedEntityIds.includes(id)) removeIdArray.push(id);
		}

		if (addIdArray.length > 0) {
			dispatch(onAddEntityIdsToSelection(addIdArray));
		}
		if (removeIdArray.length > 0 && selectedEntityIds.length > 0) {
			dispatch(onRemoveSelection(removeIdArray));
		}
	};

	const pointerUpHandler = (e: ThreeEvent<PointerEvent>) => {
		(e.target as HTMLElement).releasePointerCapture(e.pointerId);
		if (e.buttons === 2) return;
		dispatch(onSetBackgroundHotSpotEnabled(false));
		dispatch(onSetMarkerIndexPressed(null));
		if (isMountedRef.current) {
			setDragAreaPosition(null);
			setDragAreaScale([0, 0, 0]);
			setInitialPosition(null);
		}
	};

	return (
		<>
			<Plane
				enabled={false}
				name={`${namePrefix}Background Hotspot 1`}
				visible={backgroundHotspotIsEnabled}
				scale={[Math.abs(dragAreaScale[0]), Math.abs(dragAreaScale[1]), Math.abs(dragAreaScale[0])]}
				position={dragAreaPosition!}
				rotation={[0, 0, 0]}
				color={[74, 143, 227, 0.13]}
				renderOrder={0}
			/>
			<Plane
				visible={false}
				name={`${namePrefix}Background Hotspot 2`}
				enabled={!rotationIsActive && !rotationMarkerPressed}
				position={[0, 0, -0.001]}
				rotation={[0, 0, 0]}
				scale={HOTSPOT_SCALE}
				pointerUpHandler={pointerUpHandler}
				pointerMoveHandler={pointerMoveHandler}
				pointerDownHandler={pointerDownHandler}
			/>
		</>
	);
};

export default React.memo(BackgroundHotspot);
