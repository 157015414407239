import { glMatrix, mat3, vec3, vec2 } from 'gl-matrix';
import {
	ITuple3,
	ITuple2,
} from '../components/r3f/r3f-components/component-data-structure';

type IArea = [
	ITuple3 | ITuple2,
	ITuple3 | ITuple2,
	ITuple3 | ITuple2,
	ITuple3 | ITuple2
];

export enum IShapeTypes {
	rect = 'rect',
}

/**
 * Create 2d rotation matrix from angle in degrees.
 *
 * @param {number} rotation the rotation of an entity or group in degrees
 * @result {mat3} rotation matrix
 */

const toRotationMatrix2d = (rotation: number): mat3 => {
	let r = rotation;
	const theta = glMatrix.toRadian(r);
	const sinTheta = Math.sin(theta);
	const cosTheta = Math.cos(theta);
	return mat3.fromValues(
		cosTheta,
		sinTheta,
		0,
		-sinTheta,
		cosTheta,
		0,
		0,
		0,
		1
	);
};

/**
 * Create 2d position matrix from coordinates.
 *
 * @param {Array} position x & y coordinates of origin
 * @result {mat3} position matrix
 */

const toPositionMatrix2d = (position: ITuple2 | ITuple3): mat3 => {
	return mat3.fromValues(1, 0, 0, 0, 1, 0, position[0], position[1], 1);
};

///////

const angleFromTransformationMatrix2d = (m: mat3): number => {
	return toDegrees(Math.atan2(m[1], m[4]));
};

/**
 * Calculate 2d PR transformation matrix in which rotation is applied first and then positioning.
 *
 * @param {number} rotation the rotation of an entity or group in degrees
 * @param {[number, number]} position the x & y position coords of an entity or group
 * @result {mat3} transformation matrix
 */

const calcPRTransformationMatrix2d = (
	rotation: number,
	position: ITuple2 | ITuple3
): mat3 => {
	let r = rotation;
	if (r < 0) r = r + 360;
	const theta = glMatrix.toRadian(r);
	const sinTheta = Math.sin(theta);
	const cosTheta = Math.cos(theta);

	const rotationMatrix2d = mat3.fromValues(
		cosTheta,
		sinTheta,
		0,
		-sinTheta,
		cosTheta,
		0,
		0,
		0,
		1
	);

	const positionMatrix2d = mat3.fromValues(
		1,
		0,
		0,
		0,
		1,
		0,
		position[0],
		position[1],
		1
	);

	const transformationMatrix2d = mat3.multiply(
		mat3.create(),
		positionMatrix2d,
		rotationMatrix2d
	);

	return transformationMatrix2d;
};

/**
 * Convert radians to degrees.
 *
 * @param {number} radians the radians to be converted to degrees
 * @result {number} degrees as a result of conversion
 */

const toDegrees = (radians: number): number => (radians * 180) / Math.PI;

/**
 * Convert degrees to radians
 *
 * @param {number} degrees the degrees to be converted to radians
 * @result {number} radians as a result of conversion
 */

const toRadians = (degrees: number): number => (degrees * Math.PI) / 180;

/**
 * Calculate the angle in radians between point p1 & p3 given three points p1, p2 & p3 in a 2D space. Return angle in radians.
 * (See also https://stackoverflow.com/questions/1211212/how-to-calculate-an-angle-from-three-points)
 *
 * @param {[number, number] | [number, number, number]} P1 coordinates for position 1
 * @param {[number, number] | [number, number, number]} P2 coordinates for position 2
 * @param {[number, number] | [number, number, number]} P3 coordinates for position 3
 * @result {number} angle in radians
 */

const calcRadiansFromPoints = (
	p1: ITuple2 | ITuple3,
	p2: ITuple2 | ITuple3,
	p3: ITuple2 | ITuple3
): number => {
	return (
		Math.atan2(p3[1] - p1[1], p3[0] - p1[0]) -
		Math.atan2(p2[1] - p1[1], p2[0] - p1[0])
	);
};

/**
 * Convert local to world position(s) (e.g. on hotspot) to ensure correct conversions for rotated entities.
 *
 * @param {number} rotation the rotation of the entity or group in degrees
 * @param {[number, number]} ePosition x & y position coordinates of the entity with the local position(s) to be converted
 * @param {[number, number][]} lPositions array with x & y position coordinates to be converted
 * @result {[number, number, number][]} array of converted position coordinates with all z-values set to 0
 */

const localToWorldPosition2d = (
	rotation: number,
	ePosition: ITuple2 | ITuple3,
	lPositions: ITuple2[] | ITuple3[]
): ITuple3[] => {
	let worldPositions: ITuple3[] = [];
	// if entity is not rotated, use less resource intense position calculation
	if (rotation === 0) {
		for (let i = 0; i < lPositions.length; i++) {
			const lPosition = lPositions[i];
			worldPositions[i] = [
				ePosition[0] + lPosition[0],
				ePosition[1] + lPosition[1],
				0,
			];
		}
		return worldPositions;
	}

	// otherwise use matrix maths
	const transformationMatrix2d = calcPRTransformationMatrix2d(
		rotation,
		ePosition
	);
	for (let i = 0; i < lPositions.length; i++) {
		const worldPosition = vec3.transformMat3(
			vec3.create(),
			[lPositions[i][0], lPositions[i][1], 1],
			transformationMatrix2d
		);
		worldPositions[i] = [worldPosition[0], worldPosition[1], 0];
	}

	return worldPositions;
};

/**
 * Convert world (e.g hotspot) to local position(s) (on entity) to ensure correct conversions for rotated entities.
 *
 * @param {number} rotation the rotation of the entity in degrees
 * @param {[number, number]} entityPosition x & y position coordinates of the entity with the local position(s) to be converted
 * @param {[number, number][]} worldPositions array with x & y position coordinates to be converted
 * @result {[number, number, number][]} array of converted position coordinates with all z-values set to 0
 */

const worldToLocalPosition2d = (
	rotation: number,
	entityPosition: ITuple2 | ITuple3,
	worldPositions: ITuple2[] | ITuple3[]
): ITuple3[] => {
	const transformationMatrix2d = calcPRTransformationMatrix2d(
		rotation,
		entityPosition
	);
	const invertedTransformationMatrix2d = mat3.invert(
		mat3.create(),
		transformationMatrix2d
	);
	let localPositions: ITuple3[] = [];
	for (let i = 0; i < worldPositions.length; i++) {
		const localPosition = vec3.transformMat3(
			vec3.create(),
			[worldPositions[i][0], worldPositions[i][1], 1],
			invertedTransformationMatrix2d
		);
		localPositions[i] = [localPosition[0], localPosition[1], 0];
	}
	return localPositions;
};

/**
 * Rotate an entity around a centre point and return its new position and rotation values
 *
 * @param {[number, number, number]} point coords of the centre point around which to rotate the entity
 * @param {[number, number, number]} rotation rotation in degrees around the centre point
 * @param {[number, number, number]} entityPosition coords of the entity to be rotated around centre point
 * @param {number} entityRotation rotation in degrees of entity to be rotated around centre point
 * @result {{point: [number, number, number], rotation: number}} object with new position and rotation values of rotated entity
 */

const rotateEntityAroundPoint2d = (
	point: ITuple3,
	rotation: number,
	entityPosition: ITuple3,
	entityRotation: number
): { position: ITuple3; rotation: number } => {
	const relativeEntityPositionToPoint = [
		entityPosition[0] - point[0],
		entityPosition[1] - point[1],
		entityPosition[2] - point[2],
	] as ITuple3;
	const entityRotationMatrix2d = maths.toRotationMatrix2d(entityRotation);
	const entityPositionMatrix2d = maths.toPositionMatrix2d(
		relativeEntityPositionToPoint
	);
	const prTransformationMatrix2d = mat3.multiply(
		mat3.create(),
		entityPositionMatrix2d,
		entityRotationMatrix2d
	);
	const rotationMatrix2d = maths.toRotationMatrix2d(rotation);
	const rprTransformationMatrix2d = mat3.multiply(
		mat3.create(),
		rotationMatrix2d,
		prTransformationMatrix2d
	);

	const positionMatrix2d = maths.toPositionMatrix2d(point);
	const prprTransformationMatrix2d = mat3.multiply(
		mat3.create(),
		positionMatrix2d,
		rprTransformationMatrix2d
	);

	const newPosition = vec3.transformMat3(
		vec3.create(),
		[0, 0, 1],
		prprTransformationMatrix2d
	);

	const newRotation = maths.angleFromTransformationMatrix2d(
		prprTransformationMatrix2d
	);

	return {
		position: [newPosition[0], newPosition[1], 0],
		rotation: newRotation,
	};
};

const abs3 = (a: ITuple3): ITuple3 => {
	return [Math.abs(a[0]), Math.abs(a[1]), Math.abs(a[2])];
};

const multiply3 = (a: ITuple3, b: ITuple3): ITuple3 => {
	return [a[0] * b[0], a[1] * b[1], a[2] * b[2]];
};

const divide3 = (a: ITuple3, b: ITuple3): ITuple3 => {
	return [a[0] / b[0], a[1] / b[1], a[2] / b[2]];
};

const subtract3 = (a: ITuple3, b: ITuple3): ITuple3 => {
	return [a[0] - b[0], a[1] - b[1], a[2] - b[2]];
};

const add3 = (a: ITuple3, b: ITuple3): ITuple3 => {
	return [a[0] + b[0], a[1] + b[1], a[2] + b[2]];
};

const equal3 = (a: ITuple3, b: ITuple3): boolean => {
	return a[0] === b[0] && a[1] === b[1] && a[2] === b[2];
};

const valueInRange = (value: number, min: number, max: number): boolean =>
	value >= min && value <= max;

const areasAreColliding2d = (
	a: IArea,
	b: IArea,
	r?: number,
	t?: IShapeTypes
) => {
	// Based on Separating Axis Theorem:
	// https://gamedevelopment.tutsplus.com/tutorials/collision-detection-using-the-separating-axis-theorem--gamedev-169

	let unitVectors: vec2[];
	if (r !== 0) {
		// Calculate normal vectors for all area vectors
		let normalsA = calcNormalsFromArea(a, t);
		//const normalsB = calcNormalsFromArea(b);

		// Convert normals to unit vectors
		const unitVectorsA = calcUnitVectorsFromVectors(normalsA);
		const unitVectorsB = [vec2.fromValues(1, 0), vec2.fromValues(0, 1)];
		//const unitVectorsB = calcUnitVectorsFromVectors(normalsB);

		// Combine all unit vectors together (all axes from both areas)
		unitVectors = [...unitVectorsA, ...unitVectorsB];
	} else unitVectors = [vec2.fromValues(1, 0), vec2.fromValues(0, 1)];

	// Create vectors using the origin as start and corner point as end point
	const cornerVectorsA = calcCornerVectorsFromArea(a);
	const cornerVectorsB = calcCornerVectorsFromArea(b);

	// Check if they are separate.
	for (let i = 0; i < unitVectors.length; i++) {
		const axis = unitVectors[i];
		const dotProductsA = calcDotProductsFromVectors(cornerVectorsA, axis);
		const minDotProductsA = Math.min(...dotProductsA);
		const maxDotProductsA = Math.max(...dotProductsA);

		const dotProductsB = calcDotProductsFromVectors(cornerVectorsB, axis);
		const minDotProductsB = Math.min(...dotProductsB);
		const maxDotProductsB = Math.max(...dotProductsB);

		// If areas for any one axis are separate return false immediately
		if (maxDotProductsB < minDotProductsA || maxDotProductsA < minDotProductsB)
			return false;
	}
	// otherwise return true
	return true;
};

const calcNormalsFromArea = (a: IArea, t?: string) => {
	let normals: vec2[] = [];
	let loopLength = t === 'rect' ? 2 : a.length - 1;
	for (let i = 0; i < loopLength; i++) {
		const currVector: ITuple2 = [a[i + 1][0] - a[i][0], a[i + 1][1] - a[i][1]];
		const currLeftNormal: vec2 = vec2.fromValues(-currVector[1], currVector[0]);
		normals.push(currLeftNormal);
	}
	if (t === 'rect') return normals;

	const lastVector: ITuple2 = [
		a[0][0] - a[a.length - 1][0],
		a[0][1] - a[a.length - 1][1],
	];
	const lastCurrLeftNormal: vec2 = vec2.fromValues(
		-lastVector[1],
		lastVector[0]
	);
	normals.push(lastCurrLeftNormal);

	return normals;
};

const calcUnitVectorsFromVectors = (normals: vec2[]) => {
	const unitVectors: vec2[] = [];
	for (let i = 0; i < normals.length; i++) {
		const normalVector = normals[i];
		const unitVector = vec2.normalize(vec2.create(), normalVector);
		unitVectors.push(unitVector);
	}
	return unitVectors;
};

const calcCornerVectorsFromArea = (a: IArea) => {
	let cornerVectors: vec2[] = [];
	for (let i = 0; i < a.length; i++) {
		const coords = a[i];
		const cornerVector: vec2 = vec2.fromValues(coords[0], coords[1]);
		cornerVectors.push(cornerVector);
	}
	return cornerVectors;
};

const calcDotProductsFromVectors = (cornerVectors: vec2[], axis: vec2) => {
	const dotProducts: number[] = [];
	for (let i = 0; i < cornerVectors.length; i++) {
		const cornerVector = cornerVectors[i];
		const dotProduct: number = vec2.dot(cornerVector, axis);
		dotProducts.push(dotProduct);
	}
	return dotProducts;
};

const pointInBounds = (
	p: ITuple3,
	x1: number,
	x2: number,
	y1: number,
	y2: number
): boolean => {
	if (p[0] < x1) return false;
	if (p[0] > x2) return false;
	if (p[1] < y1) return false;
	if (p[1] > y2) return false;
};

const isCompletelyInOrOutOfDragArea = (a: IArea, b: IArea) => {
	const xValues = [a[0][0], a[1][0], a[2][0], a[3][0]];
	const yValues = [a[0][1], a[1][1], a[2][1], a[3][1]];

	const maxXVal = Math.max(...xValues);
	const minXVal = Math.min(...xValues);
	const maxYVal = Math.max(...yValues);
	const minYVal = Math.min(...yValues);

	const maxDragXVal = Math.max(b[0][0], b[1][0]);
	const minDragXVal = Math.min(b[0][0], b[1][0]);
	const maxDragYVal = Math.max(b[0][1], b[1][1]);
	const minDragYVal = Math.min(b[0][1], b[1][1]);

	return {
		inside:
			maxXVal >= maxDragXVal &&
			maxYVal >= maxDragYVal &&
			minXVal <= minDragXVal &&
			minYVal <= minDragYVal,
		outside:
			maxXVal <= maxDragXVal &&
			maxYVal <= maxDragYVal &&
			minXVal >= minDragXVal &&
			minYVal >= minDragYVal,
	};
};

let vector3 = {
	multiply: multiply3,
	divide: divide3,
	subtract: subtract3,
	add: add3,
	abs: abs3,
	equal: equal3,
};

const maths = {
	toDegrees,
	toRadians,
	valueInRange,
	toRotationMatrix2d,
	toPositionMatrix2d,
	calcRadiansFromPoints,
	localToWorldPosition2d,
	worldToLocalPosition2d,
	angleFromTransformationMatrix2d,
	vec3: vector3,
	pointInBounds,
	areasAreColliding2d,
	rotateEntityAroundPoint2d,
	isCompletelyInOrOutOfDragArea,
};

export { maths };
