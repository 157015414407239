import React from 'react';
import { IIconParentProps } from './index';
import { COLORS } from '../../utils';

const arrowLeftIcon: React.SFC<IIconParentProps> = ({
	className,
	hexFill: fill,
	viewBox,
	onClick = () => {},
}) => {
	const hexFill = fill || COLORS.midnight;
	return (
<svg
			onClick={onClick}
			className={className || ''}
			width="20px"
			height="20px"
			viewBox={viewBox ? viewBox.join(' ') : `0 0 20 20`}
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
		>
  <g id="Group">
    <rect id="Rectangle_66" data-name="Rectangle 66" width="20" height="20" fill="none"/>
    <g id="Rectangle" transform="translate(1.319 8.759)" fill="none" stroke={hexFill} strokeMiterlimit="10" strokeWidth="1">
      <path d="M0,0H16.013a2,2,0,0,1,2,2V7.6a2,2,0,0,1-2,2H2a2,2,0,0,1-2-2V0A0,0,0,0,1,0,0Z" stroke="none"/>
      <path d="M1,.5H16.013a1.5,1.5,0,0,1,1.5,1.5V7.6a1.5,1.5,0,0,1-1.5,1.5H2A1.5,1.5,0,0,1,.5,7.6V1A.5.5,0,0,1,1,.5Z" fill="none"/>
    </g>
    <g id="Rectangle_Copy_16" data-name="Rectangle Copy 16" transform="translate(0.145 5.481) rotate(-17)" fill="none" strokeMiterlimit="10">
      <path d="M0,1.6A1.592,1.592,0,0,1,1.851,0l14.8.931a1.99,1.99,0,0,1,1.92,1.672l.174,2.152L.165,3.59Z" stroke="none"/>
      <path d="M 1.712480545043945 0.999995231628418 C 1.462730407714844 0.999995231628418 1.236400604248047 1.083695411682129 1.107049942016602 1.223895311355591 C 1.026760101318359 1.310925245285034 0.9922294616699219 1.409075260162354 1.001489639282227 1.523945331573486 L 1.092018127441406 2.646454811096191 L 17.65719604492188 3.688429832458496 L 17.57648086547852 2.687645435333252 C 17.54994010925293 2.358595371246338 17.13830947875977 1.96793532371521 16.59004974365234 1.933455467224121 L 1.788619995117188 1.002415418624878 C 1.763031005859375 1.000815391540527 1.737421035766602 0.999995231628418 1.712480545043945 0.999995231628418 M 1.712478637695312 -1.430511474609375e-06 C 1.758335113525391 -1.430511474609375e-06 1.804515838623047 0.001441001892089844 1.851320266723633 0.004385471343994141 L 16.65283012390137 0.9354252815246582 C 17.65834045410156 0.9986653327941895 18.50279998779297 1.733815431594849 18.57324028015137 2.607255458831787 L 18.74678039550781 4.758945465087891 L 0.1648807525634766 3.590115308761597 L 0.004730224609375 1.604335308074951 C -0.06766128540039062 0.7068114280700684 0.7001667022705078 -1.430511474609375e-06 1.712478637695312 -1.430511474609375e-06 Z" stroke="none" fill={hexFill}/>
    </g>
    <path id="Line_4_Copy_3" data-name="Line 4 Copy 3" d="M1.327.5.5,2.605" transform="translate(15.126 1.826)" fill="none" stroke={hexFill} strokeLinecap="square" strokeMiterlimit="10" strokeWidth="1"/>
    <path id="Line_4_Copy" data-name="Line 4 Copy" d="M.91,0,0,2.191" transform="translate(11.545 3.105)" fill="none" stroke={hexFill} strokeLinecap="square" strokeMiterlimit="10" strokeWidth="1"/>
    <path id="Line_4_Copy_2" data-name="Line 4 Copy 2" d="M.851,0,0,2.053" transform="translate(7.415 4.201)" fill="none" stroke={hexFill} strokeLinecap="square" strokeMiterlimit="10" strokeWidth="1"/>
    <path id="Line_4_Copy_4" data-name="Line 4 Copy 4" d="M.838,0,0,2.163" transform="translate(3.188 5.297)" fill="none" stroke={hexFill} strokeLinecap="square" strokeMiterlimit="10" strokeWidth="1"/>
  </g>
</svg>

	);
};

export default arrowLeftIcon;
