import { Html } from '@react-three/drei';
import { ThreeEvent, useThree } from '@react-three/fiber';
import React, { useRef } from 'react';
import { Provider, useDispatch, useSelector } from 'react-redux';
import { IDesignerState, IDomIdSelectors } from '../../../../typings';
import { rotateSvgUrl } from '../../../assets/icons/index';
import { store } from '../../../store';
import {
	onSetRotationIsActive,
	onSetMarkerIndexPressed,
	onSetBackgroundHotSpotEnabled,
	IMultipleEntityProps_Cn_Doc,
	onSetMultipleComponentProps_Cn_Doc,
	onSetPositions,
	onSetRotations,
} from '../../../store/actions';
import { hotkeyIsPressed, HOTSPOT_SCALE, IHotkeyTypes, INITIAL_ZOOM_LEVEL, maths, ROTATION_MARKER_HEIGHT, ROTATION_MARKER_WIDTH } from '../../../utils';
import {
	getBoundary,
	getCenter,
	getRotationMarkerPosition,
	getScale,
	getSelectedContentDocPositions,
	getSelectedContentDocRotations,
	getSelectedEditorDocRotations,
	getSelectedTransientPositions,
	getSelectionCenter,
	getSelectionScale,
	getSpatialEntityPositions,
	getSpatialEntityRotations,
	getSpatialEntityScales,
} from '../../../utils/transforms';
import Plane from '../Plane/Plane';
import { ISpatialComponentUnion, ITuple3, IVector3Dict } from '../r3f-components/component-data-structure';

interface IParentProps {
	parentLocalSpace?: boolean;
}

const RotationMarker: React.FunctionComponent<IParentProps> = ({ parentLocalSpace = false }) => {
	const dispatch = useDispatch();
	const { scene } = useThree();
	const selectedEntityIds = useSelector((state: IDesignerState) => state.userReducer.selectedEntityIds || []);
	const transientPositions = useSelector((state: IDesignerState) => state.userReducer.positionById);
	const transientScales = useSelector((state: IDesignerState) => state.userReducer.scaleById);
	const transientRotations = useSelector((state: IDesignerState) => state.userReducer.rotationById);
	const activeSceneId = useSelector((state: IDesignerState) => state.userReducer.activeSceneId) || '';
	const hotKeys = useSelector((state: IDesignerState) => state.userReducer.hotKeys);
	const renderMarker = useSelector(
		(state: IDesignerState) =>
			!state.userReducer.rotationIsActive &&
			!state.userReducer.positioningIsActive &&
			!state.userReducer.scaleHotspotIsEnabled &&
			!state.userReducer.backgroundHotspotIsEnabled &&
			state.userReducer?.selectedEntityIds &&
			state.userReducer?.selectedEntityIds?.length > 0
	);
	const componentsById = useSelector((state: IDesignerState) => state.contentReducer.contentDoc.componentsById);
	const isScreenRelativeMode = useSelector((state: IDesignerState) => state.userReducer.isScreenRelativeMode);
	const groupInversionByAxis = useSelector((state: IDesignerState) => {
		const { groupIsXInverted, groupIsYInverted } = state.userReducer;
		return [groupIsXInverted, groupIsYInverted];
	});
	const zoomAdj = useSelector((state: IDesignerState) => (INITIAL_ZOOM_LEVEL / state.userReducer.zoomLevel) * 0.25);
	const hideRotationMarker = selectedEntityIds.filter((id) => (componentsById[id] as ISpatialComponentUnion)?.isLocked)?.length === 0;
	const zoomAdjFactor = isScreenRelativeMode ? 0.12 : zoomAdj;
	const pointerEventStateRef = useRef<'down' | 'move' | 'up' | null>(null);

	// Content Doc
	const contentPositions = getSpatialEntityPositions(componentsById);
	const contentRotations = getSpatialEntityRotations(componentsById);
	const contentScales = getSpatialEntityScales(componentsById);
	const contentPositionDict = getSelectedContentDocPositions(selectedEntityIds, contentPositions);
	const contentRotationDict = getSelectedContentDocRotations(selectedEntityIds, contentRotations);

	// Editor Doc
	const transientRotationDict = getSelectedEditorDocRotations(selectedEntityIds, transientRotations);
	const transientPositionDict = getSelectedTransientPositions(selectedEntityIds, transientPositions);

	// Calculations
	const boundary = getBoundary({
		selectedEntityIds,
		transientPositions,
		contentPositions,
		transientRotations,
		contentRotations,
		transientScales,
		contentScales,
		groupInversionByAxis,
		componentsById,
		activeSceneId,
		scene,
	});
	const selectionScale = getSelectionScale(selectedEntityIds, transientScales, contentScales, boundary!);
	const scale = getScale(selectedEntityIds, transientScales, contentScales, boundary!);
	const center = getCenter(boundary!);
	const selectionCenter = getSelectionCenter(boundary!);
	const rmp = getRotationMarkerPosition(selectionScale!, selectedEntityIds, transientRotations, contentRotations, scale!, zoomAdjFactor, center);

	// Refs
	const onPointerDownRef = useRef(false);
	const canvasRef = useRef(document.getElementById(isScreenRelativeMode ? IDomIdSelectors.screenRelativeCanvas : IDomIdSelectors.zapparCanvas));
	const firstMoveRef = useRef(true);
	const initialPosRef = useRef<ITuple3 | null>(null);
	const groupCenterRef = useRef<ITuple3 | null>(null);
	const initialCoords = useRef<ITuple3 | null>(null);

	// Check hotkeys
	const individualGroupRotation = hotkeyIsPressed(hotKeys, [IHotkeyTypes.individualGroupRotation]);

	const onPointerDownHandler = (_e: React.PointerEvent) => {
		_e.stopPropagation();
		if (onPointerDownRef.current) return;
		onPointerDownRef.current = true;
		initialCoords.current === null;
		dispatch(onSetRotationIsActive(true));
		dispatch(onSetBackgroundHotSpotEnabled(false));
		dispatch(onSetMarkerIndexPressed(999));
		canvasRef.current!.style.cursor = 'grabbing';
		pointerEventStateRef.current = 'down';
	};

	const onPointerMove = (e: ThreeEvent<PointerEvent>) => {
		e.stopPropagation();
		if (pointerEventStateRef.current !== 'down' && pointerEventStateRef.current !== 'move') return;
		pointerEventStateRef.current = 'move';
		const { x, y, z } = parentLocalSpace ? e.eventObject.parent!.worldToLocal(e.point.clone()) : e.point;
		if (initialCoords?.current !== null && initialCoords.current[0] === x && initialCoords.current[1] === y && initialCoords.current[2] === z) {
			return;
		}
		if (e.buttons === 2 || !onPointerDownRef.current) return;
		if (firstMoveRef.current) {
			(e.target as HTMLElement).setPointerCapture(e.pointerId);
			firstMoveRef.current = false;
			initialCoords.current = [x, y, z];
		}

		if (groupCenterRef.current === null) groupCenterRef.current = selectionCenter;
		if (initialPosRef.current === null) initialPosRef.current = rmp!;

		const radians = maths.calcRadiansFromPoints(groupCenterRef.current!, initialPosRef.current!, [x, y, z]);

		let rotationOffset = maths.toDegrees(radians);
		if (rotationOffset < 0) rotationOffset += 360;
		const rotationDict: IVector3Dict = {};
		const positionDict: IVector3Dict = {};

		// If entities should rotate around their center, only set rotation and return
		if (individualGroupRotation || selectedEntityIds.length === 1) {
			for (let i = 0; i < selectedEntityIds.length; i++) {
				const id = selectedEntityIds[i];
				let rotation = contentRotationDict[id][2] + rotationOffset;
				if (rotation > 360) rotation -= 360;
				rotationDict[id] = [0, 0, rotation];
			}
			dispatch(onSetRotations(rotationDict));
			return;
		}
		// else if rotated around group center re-calculate positions & rotations
		for (let i = 0; i < selectedEntityIds.length; i++) {
			const id = selectedEntityIds[i];
			const rotatedEntityValues = maths.rotateEntityAroundPoint2d(groupCenterRef.current!, rotationOffset, contentPositionDict[id], contentRotationDict[id][2]);
			positionDict[id] = rotatedEntityValues.position;
			rotationDict[id] = [0, 0, rotatedEntityValues.rotation];
		}
		dispatch(onSetPositions(positionDict));
		dispatch(onSetRotations(rotationDict));
	};

	const onPointerUp = (e: PointerEvent) => {
		if (pointerEventStateRef.current !== 'move') return;
		pointerEventStateRef.current = 'up';
		e.stopPropagation();
		if (e.target == null) return;
		(e.target as Element).releasePointerCapture(e.pointerId);
		initialCoords.current === null;
		canvasRef.current!.style.cursor = 'unset';
		firstMoveRef.current = true;
		onPointerDownRef.current = false;
		if (e.buttons === 2) return;

		const multipleEntityPropsArray_Cn_Doc: IMultipleEntityProps_Cn_Doc[] = [];

		for (let i = 0; i < selectedEntityIds.length; i++) {
			const id = selectedEntityIds[i];
			multipleEntityPropsArray_Cn_Doc.push({
				id,
				...(transientRotationDict[id] && {
					rotation: [...transientRotationDict[id]],
				}),
				...(selectedEntityIds.length > 1 &&
					!!transientPositionDict[id] && {
						position: [...transientPositionDict[id]],
					}),
			});
		}
		groupCenterRef.current = null;
		initialPosRef.current = null;
		dispatch(onSetMultipleComponentProps_Cn_Doc(multipleEntityPropsArray_Cn_Doc));
		dispatch(onSetPositions(null));
		dispatch(onSetRotations(null));
		dispatch(onSetMarkerIndexPressed(null));
		dispatch(onSetRotationIsActive(false));
		pointerEventStateRef.current = null;
	};

	return (
		<>
			{rmp && renderMarker && hideRotationMarker && (
				<Html position={[rmp[0], rmp[1], 0]} zIndexRange={[1, 0]}>
					<Provider store={store}>
						<img
							draggable={false}
							style={{
								height: `${ROTATION_MARKER_HEIGHT}px`,
								width: `${ROTATION_MARKER_WIDTH}px`,
								marginTop: `-${ROTATION_MARKER_HEIGHT / 2}px`, //adjusted for header height
								marginLeft: `-${ROTATION_MARKER_WIDTH / 2}px`,
								cursor: 'grab',
								pointerEvents: onPointerDownRef.current ? 'none' : 'auto',
							}}
							src={rotateSvgUrl}
							onPointerDown={onPointerDownHandler}
						/>
					</Provider>
				</Html>
			)}
			<Plane visible={false} rotation={[0, 0, 0]} position={[0, 0, 0]} scale={HOTSPOT_SCALE} enabled={onPointerDownRef.current} pointerMoveHandler={onPointerMove} pointerUpHandler={onPointerUp} />
		</>
	);
};

export default React.memo(RotationMarker);
