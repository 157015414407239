import { Intersection} from 'three';
import { ISpatialComponentUnion } from '../r3f-components/component-data-structure';
import {
	falseIntersect,
	containsTransformControlsGizmo,
	tieBreakCondition,
	isHighestRenderOrder,
	getUserDataFromIntersections,
	isClosestToCamera,
	containsScaleMarker,
} from '../r3f-components/utils/general';

export const isHighestPointerPriority = (id: string, rayintersects: Intersection[], siblings: string[], renderOrder: number, is3dMode: boolean, isScreenRelativeMode: boolean) => {
	if (falseIntersect(id, rayintersects)) return false;
	if (is3dMode && !isScreenRelativeMode) {
		// If the transform controls gizmo is in the intersections array then it takes priority
		if (containsTransformControlsGizmo(rayintersects)) return false;
		// Two entities have the same distance ( and it's closest to camera ) then tie break
		if (tieBreakCondition(rayintersects, siblings)) {
			// Highest render order wins, so return if lower
			if (!isHighestRenderOrder({userDataArray: getUserDataFromIntersections(rayintersects), renderOrder, sceneChildrenIds: siblings})) return false;
		} else {
			// If no tie break, closest to camera wins, so return if not closest
			if (!isClosestToCamera(getUserDataFromIntersections(rayintersects), renderOrder, siblings)) return false;
		}
	} else {
		// 2D or SR mode
		if (containsScaleMarker(rayintersects)) return false;
		if (!isHighestRenderOrder({userDataArray: getUserDataFromIntersections(rayintersects), renderOrder, sceneChildrenIds: siblings})) return false;
	}
	return true;
};

export const getEntityRenderOrder = (entity: ISpatialComponentUnion) => {
	return entity.renderOrder * 100;
}