import { ThreeEvent, useThree } from '@react-three/fiber';
import React, { FunctionComponent, useCallback, useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IDesignerState, IDomIdSelectors } from '../../../../typings';
import {
	IMultipleEntityProps_Cn_Doc, onSetGroupInversion,
	onSetMarkerIndexPressed,
	onSetMultipleComponentProps_Cn_Doc,
	onSetMultipleEntitySelectBoundary,
	onSetPositions,
	onSetRotations,
	onSetScaleHotSpotEnabled,
	onSetScales
} from '../../../store/actions';
import { hotkeyIsPressed, HOTSPOT_SCALE, IHotkeyTypes, maths } from '../../../utils';
import { getCursorByIndex } from '../../../utils/cursor';
import { getBoundary, IBounds, ISelectionData } from '../../../utils/transforms';
import { ITuple3, ITuple3Dict, IVector3Dict } from '../../r3f/r3f-components/component-data-structure';
import LineBox from '../LineBox/LineBox';
import { Marker } from '../Marker';
import Plane from '../Plane/Plane';
import { ISpatialComponentUnion } from '../r3f-components/component-data-structure';
import { useRefState } from '../r3f-components/hooks';
import { isAbstractComponent } from '../r3f-components/utils/general';
import { getWorldPositionForScreenEntity } from './hotspotUtils';
import {
	allSidedGroupScale,
	allSidedNonUniformEntityScale,
	allSidedUniformEntityScale,
	calcScaleType,
	ITransientSpatials,
	oneSidedGroupScale,
	oneSidedNonUniformEntityScale,
	oneSidedUniformEntityScale,
	scaleTypes
} from './utils/scale-utils';

const { vec3 } = maths;

interface IParentProps {
	parentLocalSpace?: boolean;
}

const ScaleHotspot: FunctionComponent<IParentProps> = ({ parentLocalSpace = false }) => {
	// Redux
	const dispatch = useDispatch();
	const hotKeys = useSelector((state: IDesignerState) => state.userReducer?.hotKeys);
	const selectedEntityIds = useSelector((state: IDesignerState) => state.userReducer?.selectedEntityIds || []);
	const markerIndexPressed = useSelector((state: IDesignerState) => state.userReducer.markerIndexPressed);
	const scaleHotspotIsEnabled = useSelector((state: IDesignerState) => state.userReducer.scaleHotspotIsEnabled);
	const transientPositions = useSelector((state: IDesignerState) => state.userReducer.positionById);
	const transientScales = useSelector((state: IDesignerState) => state.userReducer.scaleById);
	const transientRotations = useSelector((state: IDesignerState) => state.userReducer.rotationById);
	const componentsById = useSelector((state: IDesignerState) => state.contentReducer.contentDoc.componentsById);
	const groupInversionByAxis = useSelector((state: IDesignerState) => [state.userReducer.groupIsXInverted, state.userReducer.groupIsYInverted]);
	const isScreenRelativeMode = useSelector((state: IDesignerState) => state.userReducer.isScreenRelativeMode);
	const rotationIsActive = useSelector((state: IDesignerState) => state.userReducer.rotationIsActive);
	const backgroundHotspotIsEnabled = useSelector((state: IDesignerState) => state.userReducer.backgroundHotspotIsEnabled);
	const activeSceneId = useSelector((state: IDesignerState) => state.userReducer.activeSceneId || '');
	const pointerEventStateRef = useRef<'down' | 'move' | 'up' | null>(null);
	const { scene } = useThree();

	// Use below to generate selected scale/position/rotation dictonaries for content doc
	const onlySelected = useCallback(
		(property: 'scale' | 'position' | 'rotation') => {
			return (acc: IVector3Dict, entityId: string): ITuple3Dict => {
				const entity = componentsById[entityId];
				if (!isAbstractComponent(entity)) {
					acc[entityId] = (componentsById[entityId] as ISpatialComponentUnion)?.[property];
				}
				return acc as ITuple3Dict;
			};
		},
		[componentsById]
	);

	// Derived
	const selectedContentPositionDict = useMemo(() => selectedEntityIds.reduce(onlySelected('position'), {} as ITuple3Dict), [selectedEntityIds, onlySelected]);
	const selectedContentScaleDict = selectedEntityIds.reduce(onlySelected('scale'), {});
	const selectedContentRotationDict = selectedEntityIds.reduce(onlySelected('rotation'), {});
	const selectedTransientPositionDict = Object.entries(transientPositions)
		.filter(([key, _value]) => selectedEntityIds.includes(key))
		.reduce((dict, val) => {
			dict[val[0]] = val[1];
			return dict;
		}, {} as IVector3Dict);
	const selectedTransientScaleDict = Object.entries(transientScales)
		.filter(([key, _value]) => selectedEntityIds.includes(key))
		.reduce((dict, val) => {
			dict[val[0]] = val[1];
			return dict;
		}, {} as IVector3Dict);
	const selectedTransientRotationDict = Object.entries(transientRotations)
		.filter(([key, _value]) => selectedEntityIds.includes(key))
		.reduce((dict, val) => {
			dict[val[0]] = val[1];
			return dict;
		}, {} as IVector3Dict);

	const selectionData = useMemo(() => {
		const selectionData: ISelectionData = selectedEntityIds.map((id) => {
			return {
				id: id,
				rotation: selectedTransientRotationDict[id] || selectedContentRotationDict[id],
				position: selectedTransientPositionDict[id] || selectedContentPositionDict[id],
				scale: selectedTransientScaleDict?.[id] || selectedContentScaleDict[id],
			};
		});
		return selectionData;
	}, [selectedEntityIds, selectedTransientRotationDict, selectedTransientPositionDict, selectedTransientScaleDict, selectedContentRotationDict, selectedContentPositionDict, selectedContentScaleDict]);
	
	const boundary = getBoundary({
		selectedEntityIds,
		transientPositions: selectedTransientPositionDict,
		contentPositions: selectedContentPositionDict,
		transientRotations: selectedTransientRotationDict,
		contentRotations: selectedContentRotationDict,
		transientScales: selectedTransientScaleDict,
		contentScales: selectedContentScaleDict,
		groupInversionByAxis,
		componentsById,
		activeSceneId,
		scene
	});

	const selectionScale = useMemo((): ITuple3 => {
		switch (selectedEntityIds.length) {
			case 1:
				return (selectedTransientScaleDict?.[selectedEntityIds[0]] as ITuple3) || (selectedContentScaleDict[selectedEntityIds[0]] as ITuple3);
			default: {
				const scale: ITuple3 = [0, 0, 0];
				scale[0] = Math.sqrt(Math.pow(boundary[0][0] - boundary[2][0], 2) + Math.pow(boundary[0][1] - boundary[2][1], 2)) / 2;
				scale[1] = Math.sqrt(Math.pow(boundary[2][0] - boundary[4][0], 2) + Math.pow(boundary[2][1] - boundary[4][1], 2)) / 2;
				return scale as ITuple3;
			}
		}
	}, [selectedEntityIds, selectedTransientScaleDict, selectedContentScaleDict]);

	const selectionCenter: ITuple3 = useMemo(() => {
		const minimums = [Number.POSITIVE_INFINITY, Number.POSITIVE_INFINITY, Number.POSITIVE_INFINITY];
		const maximums = [Number.NEGATIVE_INFINITY, Number.NEGATIVE_INFINITY, Number.NEGATIVE_INFINITY];

		if (boundary) {
			for (const coord of boundary) {
				for (let dimension = 0; dimension < 3; dimension++) {
					maximums[dimension] = Math.max(maximums[dimension], coord[dimension]);
					minimums[dimension] = Math.min(minimums[dimension], coord[dimension]);
				}
			}
		}

		const xCenter = (maximums[0] + minimums[0]) / 2;
		const yCenter = (maximums[1] + minimums[1]) / 2;
		const zCenter = 0;
		return [xCenter, yCenter, zCenter];
	}, [boundary]);

	const selectionRotation = useMemo(() => {
		if (selectedEntityIds.length === 1) {
			return (transientRotations[selectedEntityIds[0]] as ITuple3) || (selectedContentRotationDict[selectedEntityIds[0]] as ITuple3);
		}
		return [0, 0, 0] as ITuple3; //TODO: refactor to include temporary mutli-select rotation
	}, [selectedEntityIds, selectedContentRotationDict, transientRotations, boundary]);

	// set hooks
	const initialScaleRef = useRef<ITuple3 | null>(null);
	const initialSelectionCenterRef = useRef<ITuple3 | null>(null);
	const initialRatioRef = useRef<number | null>(null);
	const initialGroupBoundaryRef = useRef<IBounds | null>(null);
	const oldPositionRef = useRef<ITuple3 | null>(null);
	const canvasRef = useRef<HTMLElement>(document.getElementById(isScreenRelativeMode ? IDomIdSelectors.screenRelativeCanvas : IDomIdSelectors.zapparCanvas));
	const [pointerIsDown, setPointerIsDown, pointerIsDownRef] = useRefState(false);

	// check for hotkeys & set uniform scale if scale locked to uniform
	const uniformScale = useMemo(() => {
		return (
			hotkeyIsPressed(hotKeys, [IHotkeyTypes.propScale]) || (selectedEntityIds && selectedEntityIds.length === 1 && (componentsById[selectedEntityIds[0]] as ISpatialComponentUnion).aspectRatioLocked)
		);
	}, [hotKeys, selectedEntityIds, componentsById]);

	const allSidesScale = useMemo(() => hotkeyIsPressed(hotKeys, [IHotkeyTypes.allSidesScale]), [hotKeys]);
	const groupSelected = selectedEntityIds.length > 1;
	const scaleType = useMemo(() => calcScaleType(groupSelected, allSidesScale, uniformScale ?? false), [groupSelected, allSidesScale, uniformScale]);
	// set scale and marker index related variables
	const ratio = selectionScale ? selectionScale[1] / selectionScale[0] : 1;
	const oppositePositionAdj = (markerIndexPressed ?? 0) > 3 ? -4 : 4;
	const oppositeMarkerPosition = (markerIndexPressed ?? 0) + oppositePositionAdj;

	const onPointerMoveHandler = (e: ThreeEvent<PointerEvent>) => {
		if (pointerEventStateRef.current !== 'down' && pointerEventStateRef.current !== 'move') return;
		pointerEventStateRef.current = 'move';
		if (!pointerIsDownRef.current) return;
		const { x, y, z } = parentLocalSpace ? e.eventObject.parent!.worldToLocal(e.point.clone()) : e.point;
		
		if (e.buttons === 2) return;
		// Return if position has not changed (re-render and/or continuous firing)
		if (oldPositionRef.current && vec3.equal([x, y, z], oldPositionRef.current)) return;


		// update hooks
		oldPositionRef.current = [x, y, 0];
		if (initialSelectionCenterRef.current === null) initialSelectionCenterRef.current = selectionCenter;
		if (initialGroupBoundaryRef.current === null) initialGroupBoundaryRef.current = boundary;
		if (initialScaleRef.current === null) initialScaleRef.current = selectionScale;
		if (!uniformScale) initialRatioRef.current = null;
		else if (initialRatioRef.current === null) initialRatioRef.current = ratio;

		// convert hotspot to entity coords & calculate scale
		if (isNaN(selectionCenter[0])) return;
		const localPointerPosition = maths.worldToLocalPosition2d(selectionRotation[2], [selectionCenter[0], selectionCenter[1]], [[x, y]])[0];

		const localMarkerPosition = maths.worldToLocalPosition2d(
			selectionRotation[2],
			[selectionCenter[0], selectionCenter[1]],
			[[boundary[oppositeMarkerPosition][0], boundary[oppositeMarkerPosition][1]]]
		)[0];

		let transientSpatialDicts: ITransientSpatials = {};

		switch (scaleType) {
			case scaleTypes.oneSidedGroupScale:
				if (initialScaleRef.current === null || initialGroupBoundaryRef.current === null) return;
				transientSpatialDicts = oneSidedGroupScale(
					initialScaleRef.current,
					initialGroupBoundaryRef.current,
					e,
					markerIndexPressed ?? 0,
					oppositeMarkerPosition,
					onSetGroupInversion,
					selectedEntityIds,
					selectedContentRotationDict,
					selectedContentScaleDict,
					selectedContentPositionDict,
					dispatch
				);
				break;
			case scaleTypes.allSidedGroupScale:
				if (initialScaleRef.current === null) return;
				transientSpatialDicts =
					allSidedGroupScale(
						initialScaleRef.current,
						initialSelectionCenterRef.current,
						e,
						markerIndexPressed ?? 0,
						onSetGroupInversion,
						selectedEntityIds,
						selectedContentRotationDict,
						selectedContentScaleDict,
						selectedContentPositionDict,
						dispatch
					) || [];
				break;
			case scaleTypes.oneSidedNonUniformEntityScale:
				transientSpatialDicts = oneSidedNonUniformEntityScale(
					selectionRotation,
					selectionCenter,
					markerIndexPressed ?? 0,
					localMarkerPosition,
					localPointerPosition,
					selectionScale,
					selectedEntityIds
				);
				break;
			case scaleTypes.oneSidedUniformEntityScale:
				transientSpatialDicts = oneSidedUniformEntityScale(
					selectionRotation,
					selectionCenter,
					markerIndexPressed ?? 0,
					localMarkerPosition,
					localPointerPosition,
					selectionScale,
					ratio,
					selectedEntityIds,
					initialRatioRef.current!
				);
				break;
			case scaleTypes.allSidedNonUniformEntityScale:
				transientSpatialDicts = allSidedNonUniformEntityScale(markerIndexPressed ?? 0, localPointerPosition, selectionScale, selectedEntityIds);
				break;
			case scaleTypes.allSidedUniformEntityScale:
				transientSpatialDicts = allSidedUniformEntityScale(markerIndexPressed ?? 0, localPointerPosition, selectionScale, ratio, selectedEntityIds, initialRatioRef.current!);
				break;
			default:
				break;
		}
		const { positionDict, scaleDict, rotationDict } = transientSpatialDicts;

		if (positionDict) dispatch(onSetPositions(positionDict));
		if (scaleDict) dispatch(onSetScales(scaleDict));
		if (rotationDict) dispatch(onSetRotations(rotationDict));
	}

	const onPointerUpHandler = useCallback(
		(e: ThreeEvent<PointerEvent>) => {
			pointerEventStateRef.current = 'up';
			(e.target as Element).releasePointerCapture(e.pointerId);
			if (e.buttons === 2) return;
			const multipleEntityPropsArray_Cn_Doc: IMultipleEntityProps_Cn_Doc[] = [];
			for (let i = 0; i < selectedEntityIds.length; i++) {
				const id = selectedEntityIds[i];
				multipleEntityPropsArray_Cn_Doc.push({
					id,
					...(selectedTransientScaleDict[id] && { scalesInverted: [selectedTransientScaleDict[id]![0] < 0, selectedTransientScaleDict[id]![1] < 0] }),
					...(selectedTransientScaleDict[id] && { scale: [...selectedTransientScaleDict[id]!] }),
					...(selectedTransientPositionDict[id] && {
						position: [...selectedTransientPositionDict[id]!],
					}),
					...(selectedTransientRotationDict[id] && {
						rotation: [...selectedTransientRotationDict[id]!],
					}),
				});
			}
			dispatch(onSetMultipleComponentProps_Cn_Doc(multipleEntityPropsArray_Cn_Doc));
			dispatch(onSetPositions(null));
			dispatch(onSetScales(null));
			dispatch(onSetMarkerIndexPressed(null));
			initialSelectionCenterRef.current = null;
			initialGroupBoundaryRef.current = null;
			initialScaleRef.current = null;
			dispatch(onSetGroupInversion({ xInverted: false, yInverted: false }));
			initialRatioRef.current = null;
			dispatch(onSetScaleHotSpotEnabled(false));
			pointerEventStateRef.current = null;
		},
		[selectedEntityIds, selectedTransientScaleDict, selectedTransientRotationDict, selectedTransientPositionDict, dispatch]
	);

	// render selection markers (except rotation marker) if
	// console.log(boundary);
	const selectionMarkers = useMemo(() => {
		return boundary && markerIndexPressed !== 999
			? boundary.map((position: ITuple3, index: number) => {
					if (
						(typeof markerIndexPressed !== 'number' || // works also for falsy 0
							markerIndexPressed === index) &&
						!(selectedEntityIds.length === 1 && (componentsById[selectedEntityIds[0]] as ISpatialComponentUnion).isLocked) &&
						!(selectedEntityIds.length > 1 && selectedEntityIds.filter((_id) => (componentsById[selectedEntityIds[0]] as ISpatialComponentUnion).isLocked).length !== 0)
					) {
						return (
							<Marker
								key={index}
								id={index}
								position={position}
								onPointerDown={(e: ThreeEvent<PointerEvent>) => {
									if (!e.target) return;
									e.stopPropagation();
									(e.target as Element).setPointerCapture(e.pointerId);
									// console.log('down')
									setPointerIsDown(true);
									pointerEventStateRef.current = 'down';
								}}
								onPointerUp={onPointerUpHandler}
								onPointerOver={() => {
									if (!canvasRef.current) return;
									canvasRef.current.style.cursor = getCursorByIndex(index);
								}}
								onPointerOut={() => {
									if (!canvasRef.current) return;
									canvasRef.current.style.cursor = 'default';
								}}
							/>
						);
					} else return null;
			  })
			: null;
	}, [boundary, markerIndexPressed, selectedEntityIds, componentsById, setPointerIsDown, onPointerUpHandler]);

	// selection outlines around selected entities within groups
	const individualSelectionLineBoxesInGroup = useMemo(() => {
		return selectionData && selectionData.length > 1
			? selectionData.map((data, index) => {
				let worldPosition = getWorldPositionForScreenEntity(data.id, activeSceneId, componentsById, data.position, scene);
				if (worldPosition === null && isScreenRelativeMode) return null;
				if (worldPosition === null && !isScreenRelativeMode) {
					worldPosition = data.position;
				}
					return (
						<LineBox
							key={index}
							enabled={false}
							color={[0, 176, 255, 1]}
							rotation={data.rotation as ITuple3}
							position={worldPosition as ITuple3}
							scale={data.scale as ITuple3}
							depthWrite={false}
						/>
					);
				})
			: null;
	}, [selectionData]);

		// selection outline around whole group selection
		const groupLineBox = useMemo(() => {
			if (
				isScreenRelativeMode &&
				selectedEntityIds.length > 1 &&
				((!!individualSelectionLineBoxesInGroup && individualSelectionLineBoxesInGroup[0] === null)
				|| individualSelectionLineBoxesInGroup === null)) return null;
			return selectedEntityIds && selectedEntityIds.length ? (
				<LineBox
					enabled={scaleHotspotIsEnabled}
					color={[0, 176, 255, 1]}
					rotation={selectionRotation}
					position={selectionCenter}
					scale={selectionScale}
					depthWrite={false}
					pointerUpHandler={onPointerUpHandler}
				/>
			) : null;
		}, [selectedEntityIds, scaleHotspotIsEnabled, selectionRotation, selectionCenter, selectionScale, onPointerUpHandler, individualSelectionLineBoxesInGroup]);

	useEffect(() => {
		if (selectedEntityIds.length == 0) {
			dispatch(onSetMultipleEntitySelectBoundary(null));
		} else {
			dispatch(onSetMultipleEntitySelectBoundary(boundary));
		}
	}, [backgroundHotspotIsEnabled, rotationIsActive, scaleHotspotIsEnabled, selectedEntityIds.length, selectedContentPositionDict]);

	return (
		<>
			<group renderOrder={10000} name="Scale Hotspot Group">
				{selectionMarkers}
				{groupLineBox}
				{individualSelectionLineBoxesInGroup}
			</group>
			{pointerIsDown && (
				<Plane
					name="Scale Hotspot"
					visible={false}
					// visible={true}
					// color={[255,0,0,0.15]}
					enabled={scaleHotspotIsEnabled}
					scale={HOTSPOT_SCALE}
					position={[0, 0, 0.01]}
					rotation={[0, 0, 0]}
					pointerMoveHandler={onPointerMoveHandler}
					depthWrite={false}
				/>
			)}
		</>
	);
};

export default React.memo(ScaleHotspot);
