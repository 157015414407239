import React from "react";
import { IIconParentProps } from "./index";
import { COLORS } from "../../utils";

const Sound16: React.FunctionComponent<IIconParentProps> = ({ width, height, className, hexFill = COLORS.midnight, viewBox, onClick = () => {} }) => {
  return (
    <svg
      onClick={onClick}
      className={className || ""}
      width={width ? width : "16px"}
      height={height ? height : "16px"}
      viewBox={viewBox ? viewBox.join(" ") : `0 0 16 16`}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <path
        d="M2.5456 3.20001C2.63411 3.20385 2.71841 3.23889 2.78357 3.29892C2.84873 3.35896 2.89054 3.44011 2.9016 3.52801L2.9088 3.60001V12.4C2.91337 12.5013 2.87763 12.6002 2.80941 12.6752C2.74119 12.7502 2.64604 12.795 2.5448 12.8C2.45601 12.7965 2.37133 12.7617 2.30584 12.7016C2.24035 12.6415 2.19831 12.5602 2.1872 12.472L2.1816 12.4V3.60001C2.17703 3.49875 2.21277 3.39981 2.281 3.32484C2.34922 3.24987 2.44436 3.20499 2.5456 3.20001V3.20001Z"
        fill={hexFill}
      />
      <path
        d="M0.364 4.79999C0.452514 4.80383 0.536812 4.83887 0.601969 4.8989C0.667127 4.95893 0.708938 5.04008 0.72 5.12799L0.7272 5.19999V10.8C0.731771 10.9011 0.696141 10.9999 0.62809 11.0749C0.56004 11.1498 0.465098 11.1948 0.364 11.2C0.275206 11.1965 0.190526 11.1616 0.12504 11.1016C0.0595533 11.0415 0.0175112 10.9602 0.00640029 10.872L2.89833e-07 10.8V5.19999C-0.00456577 5.09873 0.0311736 4.99978 0.0993945 4.92482C0.167616 4.84985 0.26276 4.80496 0.364 4.79999V4.79999Z"
        fill={hexFill}
      />
      <path
        d="M15.6368 4.79999C15.7253 4.80383 15.8096 4.83887 15.8748 4.8989C15.9399 4.95893 15.9817 5.04008 15.9928 5.12799L16 5.19999V10.8C16.0046 10.9011 15.9689 10.9999 15.9009 11.0749C15.8328 11.1498 15.7379 11.1948 15.6368 11.2C15.548 11.1965 15.4633 11.1616 15.3978 11.1016C15.3324 11.0415 15.2903 10.9602 15.2792 10.872L15.2728 10.8V5.19999C15.2682 5.09873 15.304 4.99978 15.3722 4.92482C15.4404 4.84985 15.5356 4.80496 15.6368 4.79999V4.79999Z"
        fill={hexFill}
      />
      <path
        d="M13.4552 3.20001C13.5437 3.20385 13.628 3.23889 13.6932 3.29892C13.7583 3.35896 13.8001 3.44011 13.8112 3.52801L13.8184 3.60001V12.4C13.823 12.5013 13.7872 12.6002 13.719 12.6752C13.6508 12.7502 13.5556 12.795 13.4544 12.8C13.3656 12.7965 13.2809 12.7617 13.2154 12.7016C13.15 12.6415 13.1079 12.5602 13.0968 12.472L13.0912 12.4V3.60001C13.0866 3.49875 13.1224 3.39981 13.1906 3.32484C13.2588 3.24987 13.354 3.20499 13.4552 3.20001V3.20001Z"
        fill={hexFill}
      />
      <path
        d="M11.2728 0C11.3613 0.0038404 11.4456 0.0388791 11.5108 0.0989115C11.5759 0.158944 11.6177 0.240097 11.6288 0.328L11.636 0.4V15.6C11.6406 15.7013 11.6048 15.8002 11.5366 15.8752C11.4684 15.9501 11.3732 15.995 11.272 16C11.1832 15.9965 11.0985 15.9617 11.033 15.9016C10.9676 15.8415 10.9255 15.7602 10.9144 15.672L10.9088 15.6V0.4C10.9042 0.298741 10.94 0.199797 11.0082 0.124829C11.0764 0.0498607 11.1716 0.0049758 11.2728 0V0Z"
        fill={hexFill}
      />
      <path
        d="M6.9096 4.79999C6.99811 4.80383 7.08241 4.83887 7.14757 4.8989C7.21273 4.95893 7.25454 5.04008 7.2656 5.12799L7.2728 5.19999V10.8C7.27737 10.9011 7.24174 10.9999 7.17369 11.0749C7.10564 11.1498 7.0107 11.1948 6.9096 11.2C6.82081 11.1965 6.73613 11.1616 6.67064 11.1016C6.60515 11.0415 6.56311 10.9602 6.552 10.872L6.5456 10.8V5.19999C6.54103 5.09873 6.57677 4.99978 6.64499 4.92482C6.71321 4.84985 6.80836 4.80496 6.9096 4.79999V4.79999Z"
        fill={hexFill}
      />
      <path
        d="M9.0912 2.40002C9.17971 2.40386 9.26401 2.4389 9.32917 2.49894C9.39433 2.55897 9.43614 2.64012 9.4472 2.72802L9.4544 2.80002V14C9.45897 14.1013 9.42323 14.2002 9.35501 14.2752C9.28678 14.3502 9.19164 14.395 9.0904 14.4C9.00161 14.3966 8.91693 14.3617 8.85144 14.3016C8.78595 14.2415 8.74391 14.1602 8.7328 14.072L8.7272 14V2.80002C8.72263 2.69877 8.75837 2.59982 8.82659 2.52485C8.89482 2.44989 8.98996 2.405 9.0912 2.40002V2.40002Z"
        fill={hexFill}
      />
      <path
        d="M4.728 2.40002C4.81651 2.40386 4.90081 2.4389 4.96597 2.49894C5.03113 2.55897 5.07294 2.64012 5.084 2.72802L5.0912 2.80002V14C5.09577 14.1013 5.06003 14.2002 4.99181 14.2752C4.92358 14.3502 4.82844 14.395 4.7272 14.4C4.63841 14.3966 4.55373 14.3617 4.48824 14.3016C4.42275 14.2415 4.38071 14.1602 4.3696 14.072L4.364 14V2.80002C4.35943 2.69877 4.39517 2.59982 4.46339 2.52485C4.53162 2.44989 4.62676 2.405 4.728 2.40002V2.40002Z"
        fill={hexFill}
      />
    </svg>
  );
};

export default Sound16;
