import React from "react";
import { IIconParentProps } from "./index";
import { COLORS } from "../../utils";

const User16: React.FunctionComponent<IIconParentProps> = ({ width, height, className, hexFill = COLORS.midnight, viewBox, onClick = () => {} }) => {
  return (
    <svg
      onClick={onClick}
      className={className || ""}
      width={width ? width : "16px"}
      height={height ? height : "16px"}
      viewBox={viewBox ? viewBox.join(" ") : `0 0 16 16`}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <path 
        d="M8 0.799988C9.76731 0.799988 11.2 2.23268 11.2 3.99999C11.2 5.7673 9.76731 7.19999 8 7.19999C6.23269 7.19999 4.8 5.7673 4.8 3.99999C4.8 2.23268 6.23269 0.799988 8 0.799988ZM8 1.59999C6.67452 1.59999 5.6 2.6745 5.6 3.99999C5.6 5.32547 6.67452 6.39999 8 6.39999C9.32548 6.39999 10.4 5.32547 10.4 3.99999C10.4 2.6745 9.32548 1.59999 8 1.59999ZM5.64807 7.8815C6.30823 8.45976 7.121 8.79999 8 8.79999C8.87653 8.79999 9.6872 8.46167 10.3463 7.88792C12.6552 9.27201 14.311 12.5411 14.3965 15.7407L14.4 16H1.6C1.6 12.715 3.27647 9.30069 5.64807 7.8815ZM5.6192 8.86479L5.5311 8.93154C3.93047 10.1838 2.73369 12.5305 2.45951 14.9578L2.4352 15.1992H13.5632L13.5424 14.9782C13.2711 12.5444 12.0668 10.1937 10.4634 8.93854L10.3728 8.87039L10.3596 8.8804C9.70981 9.30789 8.97677 9.5563 8.20994 9.59474L8 9.59999C7.23111 9.59999 6.49149 9.38922 5.82984 8.99798L5.6192 8.86479Z" 
        fill={hexFill}
      />
    </svg>
  );
};

export default User16;
