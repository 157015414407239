import { MAINTENANCE_URL } from ".";
import { store } from "../store";
import { IToastContrast, IToastSize, IToastType } from "../../typings";
import { IOnAddToastAction, IUserActionTypes } from "../store/actions";

export interface IMaintenanceData {
    Status: 'notplanned' | 'planned';
    Time: string;
    Duration: number;
}

export interface IProcessedMaintenanceData {
    localString: string, 
    niceTimeUntil: string, 
    niceDurationTime: string
}

const getNiceTime = (milliseconds: number): string => {
	const minutes = Math.ceil(milliseconds / (60 * 1000));
	if (minutes > 60) {
		const hours = Math.floor(minutes / 60);
		if (hours === 1) {
			return hours + " hour " + Math.ceil(minutes - hours*60) + " minutes";
		} else {
			return hours + " hours " + Math.ceil(minutes - hours*60) + " minutes";
		}
	}
	return minutes + " minutes";
}


export const performMaintenanceCheck =  async () => {
    let res: Response | undefined;
    try {
        res = await fetch(MAINTENANCE_URL)
    } catch(err) {
        return console.warn('Unable to fetch maintenance info:', err)
    }
    const data = await res.json() as IMaintenanceData;
    if (data['Status'] !== 'planned') return;

    const date = new Date(data['Time']);
    const localeString = date.toLocaleString();
    const now = new Date();
    const niceTimeUntil = getNiceTime(date.getTime() - now.getTime())
    const niceDurationTime = getNiceTime(data['Duration']);
    store.dispatch<IOnAddToastAction>({
        type: IUserActionTypes.ADD_TOAST,
        payload: {
            type: IToastType.info, 
            contrast: IToastContrast.high, 
            size: IToastSize.default, 
            message: `MAINTENANCE_${localeString}_${niceTimeUntil}_${niceDurationTime}`
        }
    }) 
}
