import { useThree } from '@react-three/fiber';
import React, { useLayoutEffect, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';
import { PerspectiveCamera } from 'three';
import { IDesignerState } from '../../../../../typings';
import { ISceneComp, ITuple3 } from '../../r3f-components/component-data-structure';
import DragControls from '../../r3f-components/DragControls.tsx/DragControls';


interface IParentProps {
    enabled: boolean;
}

const DragControlsWrapper: React.FunctionComponent<IParentProps> = ({ enabled }) => {
    const { camera, gl } = useThree();

    const activeSceneId = useSelector((state: IDesignerState) => state.userReducer.activeSceneId);
    const tempDragRotation = useSelector((state: IDesignerState) => state.userReducer.temporaryDragRotation);
    const forceUpdateDragControlsTrigger = useSelector((state: IDesignerState) => state.userReducer.forceUpdateDragControlsTrigger);
	const rootId = useSelector((state: IDesignerState) => state.contentReducer.contentDoc.rootComponentId);
	const firstSceneId = useSelector((state: IDesignerState) => (state.contentReducer.contentDoc.componentsById[rootId] as ISceneComp).children[0]);
    const componentsById = useSelector((state: IDesignerState) => state.contentReducer.contentDoc.componentsById);
    const currentSceneId = activeSceneId ?? firstSceneId;
    const currentScene = componentsById[currentSceneId] as ISceneComp;
    const transformControlsActive = useSelector((state: IDesignerState) => state.userReducer.transformControlsActive);
    const zeroMemoTuple3 = useMemo(() => [0, 0, 0] as ITuple3, [])

    return  <DragControls 
        initialRotation={(tempDragRotation ?? currentScene?.content360?.initialCameraRotation ?? zeroMemoTuple3) as ITuple3}
        forceUpdateInitialRotation={forceUpdateDragControlsTrigger} 
        enabled={enabled && !transformControlsActive} 
        zoomEnabled={false} 
        horizontalMovementFactor={0.4} 
        verticalMovementFactor={0.4} 
        canvasElement={gl.domElement} 
        camera={camera as PerspectiveCamera} 
        enableCameraResetOnSceneLoad 
        useDragGroup={false} 
    />;
};

export default DragControlsWrapper;