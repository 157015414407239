import React, { memo, Suspense, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { IDesignerState } from '../../../../../typings';
import { store } from '../../../../store';
import { updateProjectScreenshot, useGetActiveSceneBackgroundData, useGetCurrentSceneId } from '../../../../utils';
import Background360 from '../../r3f-components/components/Background360';
import Dummy360Content from './Dummy360Content';

const Background360Placeholder: React.FunctionComponent = () => {
    const activeSceneId = useSelector((state: IDesignerState) => state.userReducer.activeSceneId);
    const content360Data = useGetActiveSceneBackgroundData();
    const currentSceneId = useGetCurrentSceneId()

    const videoStatusDict = useSelector((state: IDesignerState) => state.contentReducer.contentDoc.videoStatusDict)
    const videoStatus = videoStatusDict?.[currentSceneId]?.status;

    useEffect(() => {
        if (content360Data?.url) return;
        const projectId = store?.getState?.()?.userReducer?.project?.id;
        if (projectId) {
            setTimeout(() => {
                updateProjectScreenshot(projectId, false)
            }, 500)
        }
        // if (projectId) updateProjectScreenshot(projectId, false)
    }, [content360Data?.url])

    const onTextureReady = useCallback(() => {
        const projectId = store?.getState?.()?.userReducer?.project?.id;
        if (projectId) {
            setTimeout(() => {
                updateProjectScreenshot(projectId, false)
            }, 1500)
        }
    }, [])

    return (
        <Suspense fallback={null} >
            {!content360Data?.url ? 
                <Dummy360Content radius={60} /> :
                <Background360
                    radius={60}
                    enableVideoPlay={false}
                    sceneId={activeSceneId || ''} 
                    enable360Environment={!!content360Data?.url}
                    environmentRenderPriority={-5}
                    videoStatus={videoStatus}
                    type={content360Data.type}
                    onTextureReady={onTextureReady}
                    preventVideoLoad={content360Data.type === 'image'}
                    url={content360Data.url}
                />
            }
        </Suspense>
    );
}
export default memo(Background360Placeholder);