import { Scene } from 'three';
import { getWorldPositionForScreenEntity } from '../components/r3f/Hotspots/hotspotUtils';
import { IComponentsById, IComponentType, IComponentUnion, ISpatialComponentUnion, ITuple3, ITuple3Dict, IVector3, IVector3Dict } from '../components/r3f/r3f-components/component-data-structure';
import { isAbstractComponent } from '../components/r3f/r3f-components/utils/general';
import { maths } from './maths';

interface IComponentDict {
	[id: string]: IComponentUnion;
}

export type IBounds = [ITuple3, ITuple3, ITuple3, ITuple3, ITuple3, ITuple3, ITuple3, ITuple3];

interface ISelectionEntityData {
	id: string;
	rotation: IVector3;
	position: IVector3;
	scale: IVector3;
}

export type ISelectionData = ISelectionEntityData[];

export const getSpatialEntityRotations = (componentsById: IComponentDict) => {
	const cnRotations: IVector3Dict = {};
	Object.keys(componentsById).forEach((id) => {
		const component = componentsById[id];
		if (!isAbstractComponent(component)) {
			cnRotations[id] = component.rotation;
		}
	});
	return cnRotations as ITuple3Dict;
};

export const getSpatialEntityScales = (componentsById: IComponentDict) => {
	const cnScales: IVector3Dict = {};
	Object.keys(componentsById).forEach((id) => {
		const { type } = componentsById[id];
		if (type !== IComponentType.Root && type !== IComponentType.Scene) {
			cnScales[id] = (componentsById[id] as ISpatialComponentUnion).scale;
		}
	});
	return cnScales as ITuple3Dict;
};

export const getSpatialEntityPositions = (componentsById: IComponentDict) => {
	const cnPositions: IVector3Dict = {};
	Object.keys(componentsById).forEach((id) => {
		const { type } = componentsById[id];
		if (type !== IComponentType.Root && type !== IComponentType.Scene) {
			cnPositions[id] = (componentsById[id] as ISpatialComponentUnion).position;
		}
	});
	return cnPositions;
};

export const getSelectedContentDocPositions = (selectedEntityIds: string[], contentPositions: IVector3Dict) => {
	const selectedContentPositions: IVector3Dict = {};
	for (let i = 0; i < selectedEntityIds.length; i++) {
		selectedContentPositions[selectedEntityIds[i]] = contentPositions[selectedEntityIds[i]];
	}
	return selectedContentPositions as ITuple3Dict;
};

export const getSelectedContentDocRotations = (selectedEntityIds: string[], contentRotations: IVector3Dict) => {
	const selectedContentRotations: IVector3Dict = {};
	for (let i = 0; i < selectedEntityIds.length; i++) {
		selectedContentRotations[selectedEntityIds[i]] = contentRotations[selectedEntityIds[i]];
	}
	return selectedContentRotations as ITuple3Dict;
};

export const getSelectedEditorDocRotations = (selectedEntityIds: string[], editorRotations: IVector3Dict) => {
	const selectedEditorRotations: IVector3Dict = {};
	for (let i = 0; i < selectedEntityIds.length; i++) {
		selectedEditorRotations[selectedEntityIds[i]] = editorRotations[selectedEntityIds[i]];
	}
	return selectedEditorRotations as ITuple3Dict;
};

export const getSelectedTransientPositions = (selectedEntityIds: string[], transientPositions: IVector3Dict) => {
	const selectedTransientPositions: IVector3Dict = {};
	for (let i = 0; i < selectedEntityIds.length; i++) {
		selectedTransientPositions[selectedEntityIds[i]] = transientPositions[selectedEntityIds[i]];
	}
	return selectedTransientPositions as ITuple3Dict;
};

export const getBoundary = (args: {
	selectedEntityIds: string[];
	transientPositions: IVector3Dict;
	contentPositions: IVector3Dict;
	transientRotations: IVector3Dict;
	contentRotations: IVector3Dict;
	transientScales: IVector3Dict;
	contentScales: IVector3Dict;
	activeSceneId: string;
	componentsById: IComponentsById;
	scene: Scene;
	groupInversionByAxis: boolean[];
}): IBounds => {
  const { selectedEntityIds, transientPositions, transientRotations, transientScales, activeSceneId, componentsById, scene, groupInversionByAxis, contentPositions, contentRotations, contentScales } = args;
	// if (!selectedEntityIds.length) return null;
	let accMin: IVector3 = [];
	let accMax: IVector3 = [];

	for (let i = 0; i < selectedEntityIds.length; i++) {
		const id = selectedEntityIds[i];
		const position = transientPositions[id] || contentPositions[id] || [0, 0, 0];
		const rotation = transientRotations[id] || contentRotations[id] || [0, 0, 0];
		const scale = transientScales?.[id] || contentScales[id] || [1, 1, 1];

		const worldPosition = getWorldPositionForScreenEntity(id, activeSceneId, componentsById, position, scene) ?? position;

		const hotspotCoords = maths.localToWorldPosition2d(
			rotation[2],
			[worldPosition[0], worldPosition[1]],
			[
				[-scale[0], scale[1]],
				[0, scale[1]],
				[scale[0], scale[1]],
				[scale[0], 0],
				[scale[0], -scale[1]],
				[0, -scale[1]],
				[-scale[0], -scale[1]],
				[-scale[0], 0],
			]
		);

		//TODO: refactor below priority setting
		hotspotCoords[0][2] = 0.007;
		hotspotCoords[1][2] = 0.008;
		hotspotCoords[2][2] = 0.009;
		hotspotCoords[3][2] = 0.007;
		hotspotCoords[4][2] = 0.006;
		hotspotCoords[5][2] = 0.005;
		hotspotCoords[6][2] = 0.005;
		hotspotCoords[7][2] = 0.005;

		if (selectedEntityIds.length === 1) {
			return hotspotCoords as IBounds;
		}

		const currMin = [
			Math.min(hotspotCoords[0][0], hotspotCoords[2][0], hotspotCoords[4][0], hotspotCoords[6][0]),
			Math.min(hotspotCoords[0][1], hotspotCoords[2][1], hotspotCoords[4][1], hotspotCoords[6][1]),
			0,
		];

		const currMax = [
			Math.max(hotspotCoords[0][0], hotspotCoords[2][0], hotspotCoords[4][0], hotspotCoords[6][0]),
			Math.max(hotspotCoords[0][1], hotspotCoords[2][1], hotspotCoords[4][1], hotspotCoords[6][1]),
			0,
		];

		if (i === 0) {
			accMin = currMin;
			accMax = currMax;
		} else {
			accMin = [currMin[0] < accMin[0] ? currMin[0] : accMin[0], currMin[1] < accMin[1] ? currMin[1] : accMin[1], 0];
			accMax = [currMax[0] > accMax[0] ? currMax[0] : accMax[0], currMax[1] > accMax[1] ? currMax[1] : accMax[1], 0];
		}
	}

	const inv = [...groupInversionByAxis];

	const xCenter = (accMax[0] + accMin[0]) / 2;
	const yCenter = (accMax[1] + accMin[1]) / 2;

	return [
		[inv[0] && inv[1] ? accMax[0] : accMin[0], inv[0] && inv[1] ? accMin[1] : accMax[1], 0.007], //top left
		[xCenter, inv[1] ? accMin[1] : accMax[1], 0.008], // top center
		[inv[0] && inv[1] ? accMin[0] : accMax[0], inv[0] && inv[1] ? accMin[1] : accMax[1], 0.009], //top right
		[inv[0] ? accMin[0] : accMax[0], yCenter, 0.007], // right center
		[inv[0] && inv[1] ? accMin[0] : accMax[0], inv[0] && inv[1] ? accMax[1] : accMin[1], 0.006], //bottom right
		[xCenter, inv[1] ? accMax[1] : accMin[1], 0.005], //bottom center
		[inv[0] && inv[1] ? accMax[0] : accMin[0], inv[0] && inv[1] ? accMax[1] : accMin[1], 0.005], //bottom left
		[inv[0] ? accMax[0] : accMin[0], yCenter, 0.005], //left center
	];
};

export const getSelectionScale = (selectedEntityIds: string[], editorScales: IVector3Dict, contentScales: IVector3Dict, boundary: number[][]) => {
	switch (selectedEntityIds.length) {
		case 0:
			return null;
		case 1:
			return (editorScales?.[selectedEntityIds[0]] as ITuple3) || (contentScales[selectedEntityIds[0]] as ITuple3);
		default:
			let scale: ITuple3 = [0, 0, 0];
			scale[0] = Math.sqrt(Math.pow(boundary[0][0] - boundary[2][0], 2) + Math.pow(boundary[0][1] - boundary[2][1], 2)) / 2;
			scale[1] = Math.sqrt(Math.pow(boundary[2][0] - boundary[4][0], 2) + Math.pow(boundary[2][1] - boundary[4][1], 2)) / 2;
			return scale as ITuple3;
	}
};

export const getScale = (selectedEntityIds: string[], editorScales: IVector3Dict, contentScales: IVector3Dict, boundary: number[][]) => {
	switch (selectedEntityIds.length) {
		case 0:
			return null;
		case 1:
			return (editorScales?.[selectedEntityIds[0]] as ITuple3) || (contentScales[selectedEntityIds[0]] as ITuple3);
		default:
			let scale: ITuple3 = [0, 0, 0];
			scale[0] = Math.sqrt(Math.pow(boundary[0][0] - boundary[2][0], 2) + Math.pow(boundary[0][1] - boundary[2][1], 2)) / 2;
			scale[1] = Math.sqrt(Math.pow(boundary[2][0] - boundary[4][0], 2) + Math.pow(boundary[2][1] - boundary[4][1], 2)) / 2;
			return scale as ITuple3;
	}
};

export const getCenter = (boundary: number[][]) => {
	let minimums = [Number.POSITIVE_INFINITY, Number.POSITIVE_INFINITY, Number.POSITIVE_INFINITY];
	let maximums = [Number.NEGATIVE_INFINITY, Number.NEGATIVE_INFINITY, Number.NEGATIVE_INFINITY];

	if (boundary) {
		for (let coord of boundary) {
			for (let dimension = 0; dimension < 3; dimension++) {
				maximums[dimension] = Math.max(maximums[dimension], coord[dimension]);
				minimums[dimension] = Math.min(minimums[dimension], coord[dimension]);
			}
		}
	}

	const xCenter = (maximums[0] + minimums[0]) / 2;
	const yCenter = (maximums[1] + minimums[1]) / 2;
	const zCenter = 0;
	return [xCenter, yCenter, zCenter];
};

export const getSelectionCenter = (boundary: number[][]) => {
	let minimums = [Number.POSITIVE_INFINITY, Number.POSITIVE_INFINITY, Number.POSITIVE_INFINITY];
	let maximums = [Number.NEGATIVE_INFINITY, Number.NEGATIVE_INFINITY, Number.NEGATIVE_INFINITY];

	if (boundary) {
		for (let coord of boundary) {
			for (let dimension = 0; dimension < 3; dimension++) {
				maximums[dimension] = Math.max(maximums[dimension], coord[dimension]);
				minimums[dimension] = Math.min(minimums[dimension], coord[dimension]);
			}
		}
	}

	const xCenter = (maximums[0] + minimums[0]) / 2;
	const yCenter = (maximums[1] + minimums[1]) / 2;
	const zCenter = 0;
	return [xCenter, yCenter, zCenter] as ITuple3;
};

export const getRotationMarkerPosition = (
	selectionScale: ITuple3,
	selectedEntityIds: string[],
	editorRotations: IVector3Dict,
	contentRotations: IVector3Dict,
	scale: ITuple3,
	zoomAdjFactor: number,
	center: number[]
) => {
	if (!selectionScale) return null;
	const rotation = selectedEntityIds.length === 1 ? editorRotations[selectedEntityIds[0]] || contentRotations[selectedEntityIds[0]] : [0, 0, 0]; // group selections are never rotated
	const theta = (rotation[2] * Math.PI) / 180;
	const sfX = Math.abs(scale[0]) + 0.6 * zoomAdjFactor;
	const sfY = Math.abs(scale[1]) + 0.6 * zoomAdjFactor;
	//const sfz = scale[2] + 1.2;

	const x5 = -sfX * Math.cos(theta) + sfY * Math.sin(theta) + center[0];
	const y5 = -sfX * Math.sin(theta) - sfY * Math.cos(theta) + center[1];
	return [x5, y5, 0.05] as ITuple3;
};
