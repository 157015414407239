import React, { useState } from 'react';
import { HEAD_MESH_DIMENSIONS } from '../../../../utils';
import SuspenseBoundary from '../../r3f-components/components/boundaries/EntitySuspense';
import FaceLandmarkHotspots from './FaceLandmarkHotspots';
import FaceMesh from './FaceMesh';
import HeadMesh from './HeadMesh';

const FaceTrackingPlaceholder: React.FunctionComponent = () => {
    const [headMeshRendered, setHeadMeshRendered] = useState(false);

    return (
        <SuspenseBoundary scale={HEAD_MESH_DIMENSIONS} position={[0, 0, 0]} rotation={[0, 0, 0]} renderOrder={1}>
            <>
                <FaceMesh />
                <HeadMesh ref={(_ref) => setHeadMeshRendered(true)} />
                {headMeshRendered && <FaceLandmarkHotspots />}
            </>
        </SuspenseBoundary>
    );
}
export default FaceTrackingPlaceholder;