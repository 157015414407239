import { Html } from '@react-three/drei';
import React, { useMemo, useEffect, FunctionComponent, memo } from 'react';
import { useSelector } from 'react-redux';
import { IDesignerState } from '../../../../typings';
import { IDragState } from '../../../../typings/user-state';
import { COLORS } from '../../../utils';
import { ITuple3, IComponentType, ISpatialComponentUnion } from '../../r3f/r3f-components/component-data-structure';
import CSS from './DragElement.scss';

interface IParentProps {
	position: ITuple3;
	entityDragInfo: ISpatialComponentUnion & IDragState;
}

const DragElement: FunctionComponent<IParentProps> = ({ entityDragInfo: entity, position }) => {
	const isFaceLandmarkHovered = useSelector((state: IDesignerState) => !!state.userReducer.activeFaceLandmark);
	const opacity = isFaceLandmarkHovered ? 0.4 : 0.9;
	const classes = useMemo(() => [CSS.DragElContainer], []);
	useEffect(() => {
		setTimeout(() => classes.push(CSS.DragShadow), 5);
	}, [classes]);

	const entityObj = useMemo(() => {
		const dragEl = document.getElementById(entity.id);
		if (!dragEl) return;
		const domRect = dragEl.getBoundingClientRect();
		const { width, height } = domRect;
		return { dragEl, width, height };
	}, [entity.id]);
	if (!entityObj) return null;
	let { width, height } = entityObj;
	const { dragEl } = entityObj;

	if (!entity || !document.getElementById(entity.id)) return null;
	switch (entity.type) {
		case IComponentType.Text: {
			const { text, fontFamily } = entity;
			return (
				<Html position={position}>
					<div
						className={CSS.DragElement}
						style={{
							width: Math.round(width) + 8,
							height,
							opacity,
							display: dragEl.style.display,
							alignItems: dragEl.style.alignItems,
							justifyContent: dragEl.style.justifyContent,
							fontFamily,
							color: COLORS.midnight,
							fontSize: dragEl.style.fontSize,
							borderRadius: dragEl.style.borderRadius,
							borderWidth: dragEl.style.borderWidth,
							borderColor: dragEl.style.borderColor,
							backgroundColor: 'none !important',
						}}
					>
						<span
							style={{
								color: COLORS.midnight,
								fontFamily,
								fontSize: dragEl.style.fontSize,
							}}
						>
							{text}
						</span>
					</div>
				</Html>
			);
		}
		case IComponentType.Button: {
			return (
				<Html position={position}>
					<div
						className={CSS.DragElement}
						style={{
							width: dragEl.style.width,
							height: dragEl.style.height,
							opacity,
							display: dragEl.style.display,
							alignItems: dragEl.style.alignItems,
							justifyContent: dragEl.style.justifyContent,
							fontFamily: dragEl.style.fontFamily,
							borderRadius: dragEl.style.borderRadius,
							borderWidth: dragEl.style.borderWidth,
							borderColor: dragEl.style.borderColor,
							backgroundColor: dragEl.style.backgroundColor,
							backgroundImage: dragEl.style.backgroundImage,
							backgroundSize: 'contain',
							backgroundPositionX: 'center',
							backgroundPositionY: 'center',
							backgroundRepeat: 'no-repeat',
						}}
					>
						<span style={{ color: dragEl.style.color }}>{entity.svgUrl ? '' : entity.text}</span>
					</div>
				</Html>
			);
		}
		case IComponentType.Text3d: {
			// const entityRatio = entity.scale[0] / entity.scale[1];
			// if (entityRatio > 0) height = width / entityRatio;
			//else width = height * entityRatio;

			// const standardTextureImageUrl = 'https://d1sonmy4sf0d9y.cloudfront.net/image/dev6-a2922d5ead744b75b385bc88fb352b9e/img'
			// const normalTextureImageUrl = 'https://d1sonmy4sf0d9y.cloudfront.net/image/dev6-3195f757844b4cff95a0730526b518b2/img'

			// const imgUrl = entity.material === 'standard' ? standardTextureImageUrl : normalTextureImageUrl;
			const { text, fontFamily } = entity;
			return (
				<Html position={position}>
					<div
						className={CSS.DragElement}
						style={{
							width: Math.round(width) + 8,
							height,
							opacity,
							display: dragEl.style.display,
							alignItems: dragEl.style.alignItems,
							justifyContent: dragEl.style.justifyContent,
							fontFamily,
							color: COLORS.midnight,
							fontSize: dragEl.style.fontSize,
							borderRadius: dragEl.style.borderRadius,
							borderWidth: dragEl.style.borderWidth,
							borderColor: dragEl.style.borderColor,
							backgroundColor: 'none !important',
						}}
					>
						<span
							style={{
								color: COLORS.midnight,
								fontFamily,
								fontSize: dragEl.style.fontSize,
							}}
						>
							{text}
						</span>
					</div>
				</Html>
			);
		}

		case IComponentType.Image: {
			const entityRatio = entity.scale[0] / entity.scale[1];
			if (entityRatio > 0) height = width / entityRatio;
			else width = height * entityRatio;

			return (
				<Html className={CSS.DragOuter} position={position}>
					<img
						className={CSS.DragImage}
						style={{
							width,
							height,
							opacity,
						}}
						src={entity.imageUrl}
					/>
				</Html>
			);
		}
		case IComponentType.Model3d: {
			return (
				<Html className={CSS.DragOuter} position={position}>
					<img
						className={CSS.DragImage}
						style={{
							width: entity.dimensions[0],
							height: entity.dimensions[1],
							opacity,
						}}
						src={entity.thumbnailUrl}
					/>
				</Html>
			);
		}
		case IComponentType.Video: {
			const entityRatio = entity.scale[0] / entity.scale[1];
			if (entityRatio > 0) height = width / entityRatio;
			else width = height * entityRatio;
			return (
				<Html className={CSS.DragOuter} position={position}>
					<img
						className={CSS.DragImage}
						style={{
							width: entity.dimensions?.[0],
							height: entity.dimensions?.[1],
							opacity,
						}}
						src={entity.thumbnailUrl}
					/>
				</Html>
			);
		}
		default:
			return null;
	}
};

export default memo(DragElement);
