import { Canvas } from '@react-three/fiber';
import React, { memo, Suspense, useRef } from 'react';
import { Provider, useDispatch, useSelector } from 'react-redux';
import { Camera, WebGLRenderer } from 'three';
import { IDesignerState, IDomIdSelectors } from '../../../../typings';
import { store } from '../../../store';
import { onRemoveSelection, onSetPositions, onSetRotations, onSetScales } from '../../../store/actions';
import { BackgroundHotspot } from '../Hotspots/index';
import Scalehotspot from '../Hotspots/Scalehotspot';
import Lighting from '../r3f-components/components/Lighting/Lighting';
import RotationMarker from '../RotationMarker/RotationMarker';
import ScreenRelativeContent from './ScreenRelativeContent/ScreenRelativeContent';

export let screenRelativeCamera: Camera;
export let screenRelativeRenderer: WebGLRenderer;

const ScreenRelativeCanvas: React.FunctionComponent = () => {
	const dispatch = useDispatch();
	const { screenRelativePreview: showScreenRelativeCanvas } = useSelector((state: IDesignerState) => state.userReducer.visibleCanvases);
	const canvasRef = useRef<HTMLCanvasElement | null>(null);

	return (
		<Canvas
			id={IDomIdSelectors.screenRelativeCanvas}
			style={{ pointerEvents: 'auto', width: '100%', height: '100%', touchAction: 'none' }}
			dpr={window.devicePixelRatio}
			ref={canvasRef}
			flat
			gl={{ preserveDrawingBuffer: true }} // needed for canvas snapshots (otherwise empty)
			orthographic
			onCreated={({ camera, gl }) => {
				screenRelativeCamera = camera;
				screenRelativeRenderer = gl;
			}}
			onPointerMissed={() => {
				dispatch(onRemoveSelection());
				dispatch(onSetRotations(null));
				dispatch(onSetPositions(null));
				dispatch(onSetScales(null));
				if (canvasRef.current) canvasRef.current.style.cursor = 'default';
			}}
		>
			{showScreenRelativeCanvas && (
				<Provider store={store}>
					<Suspense fallback={null}>
						<Lighting />
						<ScreenRelativeContent />
						<BackgroundHotspot  />
						<Scalehotspot  />
						<RotationMarker  />
					</Suspense>
				</Provider>
			)}
		</Canvas>
	);
};

export default memo(ScreenRelativeCanvas);
