import { useThree } from '@react-three/fiber';
import React, { FunctionComponent, memo, useEffect, useMemo, useState } from 'react';
import { FrontSide, SRGBColorSpace, Texture, TextureLoader } from 'three';
import { IButtonReactProps, IFontTypes, ITextAlignment, ITuple3, ITuple4 } from '../component-data-structure';
import { convertRgbaToHex, IUserData } from '../utils/general';
import { getGradientCanvas, getSvgCanvas } from './CurvedEntity/canvasTexture';
import CurvedEntity from './CurvedEntity/CurvedEntity';
import { IOnTextSync } from './DreiText';

const Button: FunctionComponent<IButtonReactProps> = ({
	scale: s,
	color: c,
	text,
	borderWidth: bw = 0,
	borderRgba: bCol = [255, 255, 255, 1],
	fontFamily = IFontTypes.Roboto400,
	borderRadius: radius = 0,
	fontSize = 2,
	fontRgba = [0, 0, 0, 1] as ITuple4,
	position,
	rotation,
	textAlignment = ITextAlignment.center,
	svgUrl = undefined,
	onPointerUp,
	onPointerMove,
	onPointerDown,
	onDoubleClick,
	renderOrder = 0,
	curvature,
	id = '',
	raycast,
	nonTransientScale,
	castShadow = false,
	receiveShadow = false,
	animationTransformGroupPrefix = '',
	onTextSync,
	isTextScaleLocked = false
}) => {
	// memoize reference type props
	const zeroPos = useMemo(() => [0, 0, 0], []);
	const brgba = useMemo(() => bCol, [bCol]);
	const scale = useMemo(() => s, [s]);
	const userData: IUserData = useMemo(() => ({ renderOrder, contentId: id }), [id, renderOrder]);
	const color = useMemo(() => c, [c]);
	const disableDoubleClick = svgUrl !== undefined;
	const [texture, setTexture] = useState<Texture>();
	const { gl } = useThree();

	useEffect(() => {
		const getData = async () => {
			if (!nonTransientScale) return;
			const image = await (svgUrl
				? getSvgCanvas(svgUrl)
				: getGradientCanvas({
						colors: convertRgbaToHex(color as ITuple4[]),
						width: Math.abs(nonTransientScale[0]) * 100,
						height: Math.abs(nonTransientScale[1]) * 100,
				}));
			const imageTexture = await new TextureLoader().loadAsync(image);
			setTexture(imageTexture);
		};

		// If only 1 color, use MeshBasicMaterial with color instead of map, so don't need a texture
		if (color.length === 1 && !svgUrl) {
			setTexture(undefined);
		} else {
			getData();
		}
	}, [svgUrl, color, nonTransientScale, gl]);

	if (texture) {
		texture.anisotropy = gl.capabilities.getMaxAnisotropy();
		texture.colorSpace = SRGBColorSpace; // Is this correct?
	}

	const _onTextSync: IOnTextSync = (bounds) => {
		//console.log('button cb')
		onTextSync?.(bounds);
	}
	
	return (
		<group
			raycast={raycast}
			key={'btn_group'}
			name={`${animationTransformGroupPrefix}${id}`}
			userData={userData}
			position={position as ITuple3}
			rotation={rotation as ITuple3}
			onPointerUp={onPointerUp || undefined}
			onPointerDown={onPointerDown || undefined}
			onPointerMove={onPointerMove || undefined}
			onDoubleClick={(!disableDoubleClick && onDoubleClick) || undefined}
		>
			<group name={`${animationTransformGroupPrefix}inner_${id}`}>
				{((color.length === 1 && !svgUrl) || !!texture) && (
					<CurvedEntity
						raycast={raycast}
						key={id}
						scale={scale as ITuple3}
						cornerRadius={radius}
						position={zeroPos as ITuple3}
						renderOrder={renderOrder}
						opacity={svgUrl ? 1 : color[0][3]}
						curvature={curvature}
						colorRgba={color.length === 1 && !svgUrl ? (color[0] as ITuple4) : undefined}
						imageTexture={texture}
						castShadow={castShadow}
						receiveShadow={receiveShadow}
						onTextSync={_onTextSync}
						isTextScaleLocked={false}
						//isTextScaleLocked={isTextScaleLocked}
						border={
							svgUrl
								? undefined
								: {
										borderSize: bw,
										borderRgba: brgba as ITuple4,
								}
						}
						text={
							svgUrl
								? undefined
								: {
										value: text,
										textAlignment,
										fontSize,
										fontFamily,
										fontRgba: fontRgba as ITuple4,
										side: FrontSide,
								}
						}
					/>
				)}
			</group>
		</group>
	);
};

Button.displayName = 'Button';
export default memo(Button);
