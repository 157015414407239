import React from 'react';
import { IIconParentProps } from './index';
import { COLORS } from '../../utils';

const soundIcon: React.SFC<IIconParentProps> = ({
	hexFill: fill,
	viewBox,
	className,
	onClick = () => {},
}) => {
	const hexFill = fill || COLORS.midnight;
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40">
			<g fill="none" fillRule="evenodd">
				<g fill={hexFill} fillRule="nonzero">
					<g>
						<g>
							<path d="M3.5 4c.245 0 .45.177.492.41L4 4.5v11c0 .276-.224.5-.5.5-.245 0-.45-.177-.492-.41L3 15.5v-11c0-.276.224-.5.5-.5zM.5 6c.245 0 .45.177.492.41L1 6.5v7c0 .276-.224.5-.5.5-.245 0-.45-.177-.492-.41L0 13.5v-7c0-.276.224-.5.5-.5zM21.5 6c.245 0 .45.177.492.41L22 6.5v7c0 .276-.224.5-.5.5-.245 0-.45-.177-.492-.41L21 13.5v-7c0-.276.224-.5.5-.5zM18.5 4c.245 0 .45.177.492.41L19 4.5v11c0 .276-.224.5-.5.5-.245 0-.45-.177-.492-.41L18 15.5v-11c0-.276.224-.5.5-.5zM15.5 0c.245 0 .45.177.492.41L16 .5v19c0 .276-.224.5-.5.5-.245 0-.45-.177-.492-.41L15 19.5V.5c0-.276.224-.5.5-.5zM9.5 6c.245 0 .45.177.492.41L10 6.5v7c0 .276-.224.5-.5.5-.245 0-.45-.177-.492-.41L9 13.5v-7c0-.276.224-.5.5-.5zM12.5 3c.245 0 .45.177.492.41L13 3.5v14c0 .276-.224.5-.5.5-.245 0-.45-.177-.492-.41L12 17.5v-14c0-.276.224-.5.5-.5zM6.5 3c.245 0 .45.177.492.41L7 3.5v14c0 .276-.224.5-.5.5-.245 0-.45-.177-.492-.41L6 17.5v-14c0-.276.224-.5.5-.5z" transform="translate(-18.000000, -277.000000) translate(18.000000, 277.000000) translate(9.000000, 10.000000)"/>
						</g>
					</g>
				</g>
			</g>
		</svg>
	);
};

export default soundIcon;
