import { useFrame, useThree } from '@react-three/fiber';
import React, { FunctionComponent, memo, useEffect, useLayoutEffect, useMemo, useRef } from 'react';
import { Euler, Group, MathUtils, PerspectiveCamera, Vector2 } from 'three';
import { rotateObject3DByDragInput } from './dragContolsUtils';
import { ITuple3 } from '../component-data-structure';

interface IParentProps {
    enabled: boolean;
    camera: PerspectiveCamera;
    minZoom?: number;
    maxZoom?: number;
    zoomEnabled?: boolean;
    zoomSpeed?: number;
    disableVerticalDrag?: boolean;
    enableCameraResetOnSceneLoad?: boolean;
    onDragChange?: (rotation: Euler) => unknown;
    horizontalMovementFactor?: number;
    verticalMovementFactor?: number;
    useDragGroup?: boolean;
    canvasElement?: HTMLCanvasElement;
    initialRotation?: ITuple3;
    forceUpdateInitialRotation?: number;
}

const DragControls: FunctionComponent<IParentProps> = ({ enabled, camera: dragCamera, forceUpdateInitialRotation = false, initialRotation = [0, 0, 0], zoomEnabled= true, horizontalMovementFactor = 3, verticalMovementFactor=1, canvasElement = document, useDragGroup = true, disableVerticalDrag, zoomSpeed = 2, minZoom = 10, maxZoom = 150, enableCameraResetOnSceneLoad = true }) => {
    const { scene } = useThree();
    const thetaRef = useRef(0);
    const phiRef = useRef(0);
    const lonRef = useRef(-90);
    const latRef = useRef(0);
    const isDragActiveRef = useRef(false);
    const isPinchActiveRef = useRef(false);
    const pointersRef = useRef<number[]>([]);
    const pointerPositionsRef = useRef<{[pointerId: string]: Vector2}>({});
    const pinchStartRef = useRef<Vector2>(new Vector2());
    const pinchEndRef = useRef<Vector2>(new Vector2());
    const pinchDeltaRef = useRef<Vector2>(new Vector2());
    const isPointerDownRef = useRef(false);
    const initialRotationDegrees = useMemo(() => initialRotation ?? [0, 0, 0], [initialRotation]);
    
    const dragGroup = useMemo(() => {
        const g = new Group();
        g.name = 'drag-group';
        return g;
    }, [])

    useLayoutEffect(() => {  
        if (!useDragGroup) return;
        if (!dragCamera.name) dragCamera.name = 'drag-camera';
        dragGroup.add(dragCamera);
        scene.add(dragGroup);
        return () => {
            dragGroup.remove(dragCamera);
            scene.remove(dragGroup);
        }
    }, [])

    useLayoutEffect(() => {
        isPinchActiveRef.current = false;
        isDragActiveRef.current = false;
        pointersRef.current = [];
        
        // Only reset camera if this is enabled
        if (!enableCameraResetOnSceneLoad) return;
        thetaRef.current = 0;
        phiRef.current = 0;
        lonRef.current = -90 - initialRotationDegrees[1]; 
        latRef.current = 0 + initialRotationDegrees[0];
        rotateObject3DByDragInput({latRef, lonRef, phiRef, thetaRef, disableVerticalDrag, object: useDragGroup ? dragGroup : dragCamera})
    }, [initialRotationDegrees[0], initialRotationDegrees[1], forceUpdateInitialRotation, dragCamera])


    useEffect(() => {

        const removePointer = (event: PointerEvent) => {
            delete pointerPositionsRef.current[event.pointerId];
            for ( let i = 0; i < pointersRef.current.length; i ++ ) {
				if ( pointersRef.current[ i ] == event.pointerId ) {
					pointersRef.current.splice( i, 1 );
					return;
				}
			}
        }

        const onPointerUp = (event: PointerEvent) => {
            isPointerDownRef.current = false;
            isDragActiveRef.current = false;
            if (pointersRef.current.length < 2) isPinchActiveRef.current = false
            removePointer(event);
            pinchStartRef.current.copy( pinchEndRef.current );
            
        }
        window.addEventListener('pointerup', onPointerUp);


        if (!enabled) return;

        let onPointerDownMouseX = 0;
        let onPointerDownMouseY = 0;
        let onPointerDownLat = 0;
        let onPointerDownLon = 0;

        const addPointer = (event: PointerEvent) => {
            pointersRef.current.push(event.pointerId)
            trackPointer(event);
        }

        const trackPointer = (event: PointerEvent) => {
			let position = pointerPositionsRef.current[event.pointerId];
			if ( position === undefined ) {
				position = new Vector2();
				pointerPositionsRef.current[event.pointerId] = position;
			}
			position.set( event.pageX, event.pageY );
        }

        const getSecondPointerPosition = (event: PointerEvent) => {
            const pointerId = (event.pointerId === pointersRef.current[ 0 ] ) ? pointersRef.current[ 1 ] : pointersRef.current[ 0 ];
            return pointerPositionsRef.current[pointerId];
        }

        const onPinchStart = (event: PointerEvent) => {
            if (pointersRef.current.length !== 2) return;
            handlePinchStart(event);
        }

        const handlePinchStart = (event: PointerEvent) => {
            const position = getSecondPointerPosition( event );
			const dx = event.pageX - position.x;
			const dy = event.pageY - position.y;
			const distance = Math.sqrt( dx * dx + dy * dy );
			pinchStartRef.current.set( 0, distance );
        }

        const handlePinchMove = (event: PointerEvent) => {
            trackPointer(event);
            const position = getSecondPointerPosition( event );
            if (!position) return;
            const dx = event.pageX - position.x;
			const dy = event.pageY - position.y;
			const distance = Math.sqrt( dx * dx + dy * dy );
            pinchEndRef.current.set( 0, distance );
			pinchDeltaRef.current.set( 0, Math.pow( pinchEndRef.current.y / pinchStartRef.current.y, zoomSpeed ) );
            setCameraFOV(pinchDeltaRef.current.y)
        }

        const onPointerDown = (event: Event | PointerEvent) => {
            if (!enabled) return; 
            const ev = event as PointerEvent;
            isPointerDownRef.current = true;
            addPointer(ev)
            if (ev.isPrimary !== false ) {
                onPointerDownMouseX = ev.clientX;
                onPointerDownMouseY = ev.clientY;
                onPointerDownLon = lonRef.current;
                onPointerDownLat = latRef.current;
                isPinchActiveRef.current = false;
            } else {
                isPinchActiveRef.current = true;
                onPinchStart(event as PointerEvent);
            }
        }

        const onPointerMove = (event: Event) => {
            const ev = event as PointerEvent;
            if (pointersRef.current.length === 1) {
                if (ev.isPrimary !== false && !isPinchActiveRef.current) {
                    lonRef.current = ( onPointerDownMouseX - ev.clientX ) * (0.2 * horizontalMovementFactor) + onPointerDownLon;
                    latRef.current = ( ev.clientY - onPointerDownMouseY ) * (0.2 * verticalMovementFactor) + onPointerDownLat;
                    setTimeout(() => {
                        if (!isPointerDownRef.current) return;
                        isDragActiveRef.current = true
                    }, 100);
                    
                } else isDragActiveRef.current = false;
                return;
            }
            handlePinchMove(ev)
        }

        const setCameraFOV = (delta: number) => {
            if (!zoomEnabled) return;
            const fov = dragCamera.fov + (1 - delta);
            dragCamera.fov = MathUtils.clamp(fov, minZoom, maxZoom);
            dragCamera.updateProjectionMatrix();
        }

        const onDocumentMouseWheel = (event: Event) => {
            const ev = event as WheelEvent;
            setCameraFOV(ev.deltaY * 0.05)
        }


        canvasElement.addEventListener('pointerdown', onPointerDown);
        canvasElement.addEventListener('wheel', onDocumentMouseWheel);
        canvasElement.addEventListener('pointermove', onPointerMove );

        return () => {
            canvasElement.removeEventListener('pointerdown', onPointerDown);
            canvasElement.removeEventListener( 'pointermove', onPointerMove );
            canvasElement.removeEventListener('wheel', onDocumentMouseWheel);
            window.removeEventListener( 'pointerup', onPointerUp );
        }
    }, [enabled, zoomEnabled])

    useFrame(() => {
        if (!enabled || !isDragActiveRef.current) return;
        rotateObject3DByDragInput({latRef, lonRef, phiRef, thetaRef, disableVerticalDrag, object: useDragGroup ? dragGroup : dragCamera})
    })

    return null
}

DragControls.displayName = 'dragControls';
export default DragControls;