import React from 'react';
import { COLORS } from '../../utils';
import { IIconParentProps } from './index';

const Learn20: React.FunctionComponent<IIconParentProps> = ({ hexFill: fill, viewBox, className, width, height, onClick }) => {
	const hexFill = fill || COLORS.midnight;
	return (
		<svg
			onClick={onClick ?? undefined}
			className={className || ''}
			width={width ? width : '16px'}
			height={height ? height : '16px'}
			viewBox={viewBox ? viewBox.join(' ') : `0 0 20 20`}
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
		>
            <g clipPath="url(#clip0_13837_43617)">
                <path fillRule="evenodd" clipRule="evenodd" d="M10.7108 1.96828L10.8417 2.04232L19.2971 7.38034C20.0122 7.83183 20.2152 8.7609 19.7503 9.45549C19.6693 9.5765 19.5709 9.68531 19.4583 9.77871L19.3411 9.86686L19.266 9.91699V14.048L19.7259 14.9029C19.8408 15.1166 19.7708 15.3714 19.5721 15.5098L19.4917 15.5559C19.419 15.5897 19.3388 15.6073 19.2576 15.6073H18.2457C17.9565 15.6073 17.7221 15.3894 17.7221 15.1206C17.7221 15.045 17.741 14.9705 17.7773 14.9029L18.236 14.05V10.617L15.662 12.367L15.6628 15.1073C15.6628 16.8083 13.2358 18.0355 10.2636 18.1043L9.99998 18.1073C6.99729 18.1073 4.48455 16.9287 4.34342 15.2561L4.33717 15.1073L4.337 12.367L0.658879 9.86686C-0.0398854 9.39178 -0.209823 8.45649 0.279311 7.77781C0.364528 7.65958 0.466717 7.55411 0.58257 7.46452L0.702854 7.38034L9.15829 2.04232C9.62746 1.74614 10.2209 1.72145 10.7108 1.96828ZM9.99998 13.1073C7.40465 13.1073 5.36677 14.0969 5.36677 15.1073C5.36677 16.1177 7.40465 17.1073 9.99998 17.1073C12.5953 17.1073 14.6332 16.1177 14.6332 15.1073C14.6332 14.0969 12.5953 13.1073 9.99998 13.1073ZM9.99998 9.1073C7.49116 9.1073 5.50325 10.0321 5.37351 11.0064L5.36677 11.1073L5.36524 13.3495C6.39617 12.5792 8.09999 12.1073 9.99998 12.1073C11.9 12.1073 13.6038 12.5792 14.6347 13.3495L14.6332 11.1073C14.6332 10.0969 12.5953 9.1073 9.99998 9.1073ZM9.80837 2.83589L9.71942 2.88077L1.26397 8.21879C1.2086 8.25375 1.16067 8.29873 1.12279 8.35128C0.977865 8.55237 1.00652 8.82104 1.17826 8.98948L1.24932 9.04762L4.337 11.147L4.33717 11.1073C4.33717 9.35623 6.90897 8.1073 9.99998 8.1073C13.0027 8.1073 15.5154 9.28589 15.6565 10.9585L15.6628 11.1073L15.662 11.146L18.7506 9.04762C18.8047 9.01084 18.8511 8.96429 18.887 8.9105C19.0248 8.7047 18.9866 8.43714 18.809 8.27452L18.736 8.21879L10.2805 2.88077C10.1384 2.79101 9.96195 2.77605 9.80837 2.83589Z" fill={hexFill}/>
            </g>
            <defs>
                <clipPath id="clip0_13837_43617">
                    <rect width="20" height="20" fill="none"/>
                </clipPath>
            </defs>
		</svg>
	);
};

export default Learn20;
