
export interface StreamData {
	lengthMS?: number;
}

export interface ModelData {
	type: 'model';
	morphTargets: { [id: string | number]: boolean };
	animations: { [id: string | number]: StreamData };
	nodes: { [id: string]: boolean };
}

function decode(inp: ArrayBuffer | ArrayBufferView) {
    return (new TextDecoder()).decode(inp)
}

// From https://gitlab.com/zappar/scene-tools/foundry/-/blob/master/src/fileindexer/gltf.ts

export async function indexGLTF(ab: ArrayBuffer): Promise<ModelData | undefined> {
	return parseGLB(ab) ?? parseJSON(new Uint8Array(ab));
}

export function parseGLB(ab: ArrayBuffer): ModelData | undefined {
	if (ab.byteLength < 12) return;
	let offset = 0;
	const view = new DataView(ab);

	const magic = view.getUint32(offset, true);
	offset += 4;
	if (magic !== 0x46546c67) return;

	const version = view.getUint32(offset, true);
	offset += 4;
	if (version !== 2) return;

	const length = view.getUint32(offset, true);
	offset += 4;
	if (length !== ab.byteLength) return;

	const firstChunkLength = view.getUint32(offset, true);
	offset += 4;

	const firstChunkType = view.getUint32(offset, true);
	offset += 4;
	if (firstChunkType !== 0x4e4f534a) return;

	const jsonData = new Uint8Array(ab, offset, firstChunkLength);
	return parseJSON(jsonData);
}

export function parseJSON(buffer: Uint8Array): ModelData | undefined {
	const txt = decode(buffer);
	const parsed = JSON.parse(txt);

	return {
		type: 'model',
		animations: parseAnimations(parsed),
		morphTargets: parseMorphTargets(parsed),
		nodes: parseNodes(parsed),
	};
}

export function parseMorphTargets(json: any): { [id: string | number]: boolean } {
	const morphTargets: { [id: string | number]: boolean } = {};
	if (!Array.isArray(json['nodes'])) return morphTargets;
	if (!Array.isArray(json['meshes'])) return morphTargets;
	for (let i = 0; i < json['nodes'].length; i++) {
		const node = json['nodes'][i];
		if (typeof node !== 'object') continue;
		// const nodeName = typeof node['name'] === 'string' ? node['name'] : i.toString();
		if (typeof node['mesh'] !== 'number') continue;
		const mesh = json['meshes'][node['mesh']];
		if (!Array.isArray(mesh['weights'])) continue;
		for (let j = 0; j < mesh['weights'].length; j++) {
			// morphTargets[`${nodeName}.${mesh['extras']?.['targetNames']?.[j] ?? j.toString()}`] = true;
			morphTargets[`${mesh['extras']?.['targetNames']?.[j] ?? j.toString()}`] = true;
		}
	}
	return morphTargets;
}

export function parseNodes(json: any): { [id: string | number]: boolean } {
	const ret: { [id: string | number]: boolean } = {};
	if (!Array.isArray(json['nodes'])) return ret;
	for (let i = 0; i < json['nodes'].length; i++) {
		const node = json['nodes'][i];
		if (typeof node !== 'object') continue;
		if (typeof node['name'] !== 'string') continue;
		ret[node['name']] = true;
	}
	return ret;
}

export function parseAnimations(json: any): { [id: string | number]: StreamData } {
	const animations: { [id: string | number]: StreamData } = {};

	if (!Array.isArray(json['animations'])) return animations;

	for (let i = 0; i < json['animations'].length; i++) {
		const animation = json['animations'][i];
		if (typeof animation !== 'object') continue;
		const name = typeof animation['name'] === 'string' ? animation['name'] : i;
		if (!Array.isArray(animation['samplers'])) continue;
		const toAdd: StreamData = {};
		for (const sampler of animation['samplers']) {
			if (typeof sampler !== 'object') continue;
			if (typeof sampler['input'] !== 'number') continue;
			if (!Array.isArray(json['accessors'])) continue;
			const accessor = json['accessors'][sampler['input']];
			if (typeof accessor !== 'object') continue;
			if (!Array.isArray(accessor['max']) || accessor['max'].length !== 1) continue;
			toAdd.lengthMS = Math.max(toAdd.lengthMS ?? 0, accessor['max'][0] * 1000);
		}
		animations[name] = toAdd;
	}
	return animations;
}
