import { OrbitControlsProps, OrthographicCamera, PerspectiveCamera } from '@react-three/drei';
import { Canvas, Vector3 } from '@react-three/fiber';
import * as Sentry from '@sentry/react';
import React, { FunctionComponent, Suspense, useEffect, useRef } from 'react';
import { Provider, useDispatch, useSelector } from 'react-redux';
import { Store } from 'redux';
import { Camera, Scene, WebGLRenderer } from 'three';
import { IDesignerState, IDomIdSelectors } from '../../../../typings';
import {
	onChangeZoomLevel,
	onSetCanvasLoaded,
	onAddToast,
	onSetScaleHotSpotEnabled,
	onSetBackgroundHotSpotEnabled,
	onRemoveSelection,
	onSetIs3dMode,
	onSetPositions,
	onSetRotations,
	onSetScales,
	onSetEmitterAreaScales,
	onSetActiveAnimationPreset,
} from '../../../store/actions';
import { getEditorRotation, getInitialCamera, MAX_ZOOM_LIMIT, MIN_ZOOM_LIMIT, useGetActiveSceneBackgroundData, useGetActiveSceneTrackingType, useIsArModeDisabled, useIsCurvedImageTrackedScene, useIsFlatOrientation } from '../../../utils';
import { ToastsData } from '../../../utils/toasts-data';
import { px, nx, py, ny, pz, nz } from '../../r3f/r3f-components/components/Lighting';
import CurvedControlsWrapper from '../Controls/CurvedControls/CurvedControlsWrapper';
import OrbitControls from '../Controls/OrbitControls';
import TransformControls from '../Controls/TransformControls/TransformControls';
import ViewGizmo from '../Controls/ViewGizmo';
import { BackgroundHotspot, EntityDragHotspot, ScaleHotspot } from '../Hotspots';
import { ISpatialComponentUnion, ITrackingTypes } from '../r3f-components/component-data-structure';
import Lighting from '../r3f-components/components/Lighting/Lighting';
import { LIGHTING_DIRECTIONAL_POSITION_UPRIGHT_IMAGE_TRACKED } from '../r3f-components/utils/constants';
import RotationMarker from '../RotationMarker/RotationMarker';
import SceneDefaultContent from '../SceneDefaultContent/SceneDefaultContent';
import CSS from './EditorCanvas.scss';
import DragControlsWrapper from '../Controls/DragControlsWrapper/DragControlsWrapper';

export let ARThreeScene: Scene;
export let ARRenderer: WebGLRenderer;

interface IParentProps {
	store: Store;
	children: JSX.Element;
	onInteractionEnd: (controls: OrbitControlsProps) => void;
}
const EditorCanvas: FunctionComponent<IParentProps> = ({ store, children, onInteractionEnd }) => {
	const dispatch = useDispatch();
	const is3dMode = useSelector((state: IDesignerState) => state.userReducer.is3dMode);
	const zoomLevel = useSelector((state: IDesignerState) => state.userReducer.zoomLevel);
	const projectId = useSelector((state: IDesignerState) => state.userReducer.project.id);
	const selectedEntityIds = useSelector((state: IDesignerState) => state.userReducer.selectedEntityIds!);
	const entityMenuDragActive = useSelector((state: IDesignerState) => state.userReducer.entityMenuDragActive && state.userReducer.entityDragInfo);
	const isAnimationActive = useSelector((state: IDesignerState) => !!state.userReducer.isPresetAnimationActive)
	const activeSceneTrackingType = useGetActiveSceneTrackingType();
	const isFlatOrientation = useIsFlatOrientation();
	const isCurvedImageTrackedScene = useIsCurvedImageTrackedScene();
	const isArModeDisabled = useIsArModeDisabled();
	const selectedEntityLocked = useSelector((state: IDesignerState) => !!(state.contentReducer.contentDoc.componentsById[selectedEntityIds[0]] as ISpatialComponentUnion)?.isLocked);
	const isScreenRelativeMode = useSelector((state: IDesignerState) => state.userReducer.isScreenRelativeMode);
	const orbitControlsRef = useRef<OrbitControlsProps>();
	const canvasRef = useRef<HTMLCanvasElement | null>(null);
	const somethingSelected = selectedEntityIds.length !== 0;	
	const { target: orbitTarget, position: perspectiveCameraPosition } = getInitialCamera(isFlatOrientation, activeSceneTrackingType, isCurvedImageTrackedScene);
	const editorGroupRotation = getEditorRotation(isFlatOrientation, activeSceneTrackingType)
	const directionalLightPosition = activeSceneTrackingType === ITrackingTypes.image && !isFlatOrientation ? LIGHTING_DIRECTIONAL_POSITION_UPRIGHT_IMAGE_TRACKED : undefined;
	const background360Data = useGetActiveSceneBackgroundData();


	useEffect(() => {
		if (activeSceneTrackingType === ITrackingTypes.face ||
			activeSceneTrackingType === ITrackingTypes.world || 
			isFlatOrientation
		) dispatch(onSetIs3dMode(true));
	}, [activeSceneTrackingType]);

	const onClick = () => {
		// if hotspot stuck to enabled, disable on canvas click
		dispatch(onSetScaleHotSpotEnabled(false));
		dispatch(onSetBackgroundHotSpotEnabled(false));
	};

	const _onInteractionEnd = (orbitControls: OrbitControlsProps) => {
		orbitControlsRef.current = orbitControls;
		onInteractionEnd?.(orbitControls);
	};

	const onZoomCallback = (zoomLevel: number) => {
		dispatch(onChangeZoomLevel(zoomLevel));
	};

	return (
		<Sentry.ErrorBoundary onError={() => dispatch(onAddToast(ToastsData.GeneralError))}>
			<Canvas
				className={CSS.EditorCanvas}
				// style={{ backgroundColor: '#f2f2f2' }}
				id={IDomIdSelectors.zapparCanvas}
				dpr={window.devicePixelRatio}
				ref={canvasRef}
				shadows
				flat
				gl={{ preserveDrawingBuffer: true }} // needed for canvas snapshots (otherwise empty)
				onClick={onClick}
				onCreated={({ scene, gl }) => {
					ARRenderer = gl;
					ARThreeScene = scene;
					dispatch(onSetCanvasLoaded(true));
				}}
				onPointerMissed={() => {
					dispatch(onRemoveSelection());
					dispatch(onSetRotations(null));
					dispatch(onSetPositions(null));
					dispatch(onSetScales(null));
					dispatch(onSetEmitterAreaScales(null))
					if (canvasRef.current) canvasRef.current.style.cursor = 'default';
				}}
				onPointerDown={() => {
					dispatch(onSetActiveAnimationPreset(false))
				}}
			>
				<color attach="background" args={[
					'#f2f2f2'
					// '#282828'
				]}/>
				<Lighting envMapFiles={[px, nx, py, ny, pz, nz]} enableDefaultEnvironment={!background360Data?.url} directionalLightPosition={directionalLightPosition} />
				<Provider store={store}>
					<Suspense fallback={null}>
						{activeSceneTrackingType !== ITrackingTypes.content360 ? <OrbitControls
							target={orbitTarget}
							zoomLevel={zoomLevel}
							is3dMode={is3dMode}
							minZoom={MIN_ZOOM_LIMIT}
							maxZoom={MAX_ZOOM_LIMIT}
							projectId={projectId!}
							onZoom={onZoomCallback}
							onEnd={_onInteractionEnd}
							onPanStart={(gl) => (gl.domElement.style.cursor = 'grabbing')}
							onPanEnd={(gl) => (gl.domElement.style.cursor = 'default')}
						/> : <DragControlsWrapper enabled={activeSceneTrackingType === ITrackingTypes.content360 && !entityMenuDragActive && !isScreenRelativeMode} />}
						<PerspectiveCamera name={'editor-canvas-camera'} position={perspectiveCameraPosition as Vector3} zoom={activeSceneTrackingType === ITrackingTypes.face ? 2 : 1 } makeDefault={is3dMode} />
						<OrthographicCamera position={[0, 0, 5]} zoom={zoomLevel} makeDefault={!is3dMode} />
						<group position={[0, 0, 0]} name="Editor Canvas Group" rotation={editorGroupRotation}>
							{!isAnimationActive && is3dMode && somethingSelected && !isScreenRelativeMode && <TransformControls locked={selectedEntityLocked} />}
							{!isAnimationActive && is3dMode && somethingSelected && !isScreenRelativeMode && <CurvedControlsWrapper />}
							{children}
							{!is3dMode && !isScreenRelativeMode && <BackgroundHotspot namePrefix="editorCanvas_" />}
							{!is3dMode && somethingSelected && !isScreenRelativeMode && <ScaleHotspot />}
							{entityMenuDragActive && <EntityDragHotspot />}
							<SceneDefaultContent />
						</group>
					</Suspense>
					{!is3dMode && !isScreenRelativeMode && <RotationMarker />}
					{is3dMode && !isArModeDisabled && <ViewGizmo orbitControlsRef={orbitControlsRef} />}
				</Provider>
			</Canvas>
		</Sentry.ErrorBoundary>
	);
};

export default React.memo(EditorCanvas);
