import { MaterialNode } from "@react-three/fiber";
import { Color, ShaderMaterial } from "three";


declare global {
	namespace JSX {
		interface IntrinsicElements {
			pulseMaterial: MaterialNode<ShaderMaterial, typeof ShaderMaterial>;
		}
	}
}

const VERTEX_SHADER = `
uniform float uTime;
uniform vec3 uColor;

varying vec3 vColor;
varying float vTime;
varying vec2 vUV;

void main() {
	vTime = uTime;
	vColor = uColor;
	vUV = uv;
  	gl_Position = projectionMatrix*modelViewMatrix*vec4(position,1.);
}
`;

const FRAGMENT_SHADER = `
uniform vec2 resolution;

varying vec3 vColor;
varying float vTime;
varying vec2 vUV;

void main( void ) {

	// Normalized pixel coordinates (from 0 to 1)
    vec2 position = vUV;

    float glow_length=(cos(vTime*4.+vUV.x*3.)*.03+.05) * 0.2;
    float shimmer=0.;

    shimmer=1.-vUV.y/glow_length+(1./glow_length/2.);
    if(vUV.y<.5){
      shimmer=vUV.y/glow_length-(1./glow_length/2.-1.);
    }

    // Output to screen
	float clamp_color = clamp(shimmer, 0.0, 1.0) / 5.0;
	gl_FragColor = vec4(vColor.x + clamp_color, vColor.y + clamp_color, vColor.z + clamp_color, 1.);
}
`;

export default new ShaderMaterial( {

	uniforms: {
		uTime: { value: 0.0 },
		uColor: { value: new Color('red') },
	},

	vertexShader: VERTEX_SHADER,
	fragmentShader: FRAGMENT_SHADER

} );


