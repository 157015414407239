import { MathUtils } from "three";
import { IPresetAnimationControllerOption } from "../animationControllers/presetAnimationController";
import { IVector3Dict, IComponentsById, ISpatialComponentUnion, ITuple3, IComponentType, IActionCategory, IActionDict, IModel3dAnimationOptions, ISpatialComponentActionData, ISceneComp, ITriggerTypes } from "../component-data-structure";
import { IPlayModelAnimationsData } from "../../../zustand";

export type ITransientTransformDict = IVector3Dict | {
    [id: string]: null;
}

export const getInitialEntityTransformValuesByIds = (input: {
    ids: string[], 
    componentsById?: IComponentsById,
    transientScalesById?: ITransientTransformDict,
    transientRotationsById?: ITransientTransformDict,
    transientPositionsById?: ITransientTransformDict
}) => {
    const {ids, componentsById, transientScalesById, transientRotationsById, transientPositionsById} = input
    if (!componentsById) return;
	return ids.map(id => {
		const component = componentsById[id] as ISpatialComponentUnion;

		const [sx, sy, sz] = transientScalesById?.[id] ?? component.scale;
		const scale = [sx * 2, sy * 2, sz * 2] as ITuple3;

		const [rx, ry, rz] = transientRotationsById?.[id] ?? component.rotation;
		const rotation = [MathUtils.degToRad(rx), MathUtils.degToRad(ry), MathUtils.degToRad(rz)] as ITuple3;

		const position = component.type === IComponentType.Emitter ? [0, 0, 0] : transientPositionsById?.[id] ?? component.position;

		return {
			scale: [...scale] as ITuple3,
			position: [...position] as ITuple3,
			rotation: [...rotation] as ITuple3
		};
	})
}

export const getModelAndPresetAnimationControllerOptions = (actions: IActionDict, componentId: string) => {
	let presetAnimationOptions: IPresetAnimationControllerOption[] = [];
	let modelAnimationOptions: IModel3dAnimationOptions[] = [];
	const triggers = Object.keys(actions || {});
	for (let i = 0; i < triggers.length; i++) {
		const trigger = triggers[i];
		const actionData = (actions as any)?.[trigger] as ISpatialComponentActionData[] || [];
		const presetAnimationData = actionData
			.filter(action => action.type === IActionCategory.animatePreset)
			.map(action => {
				const {type: _type, ...rest} = action;
				return {...rest, componentId, triggerType: trigger};
			}) as IPresetAnimationControllerOption[];

		const modelAnimationData = actionData
			.filter(action => action.type === IActionCategory.animateModel)
			.map(action => {
				const {type: _type, ...rest} = action;
				return {...rest, componentId, triggerType: trigger };
			}) as IModel3dAnimationOptions[];

		modelAnimationOptions = [...modelAnimationOptions, ...(modelAnimationData ?? [])];
		presetAnimationOptions = [...presetAnimationOptions, ...(presetAnimationData ?? [])];
	}
	return {model: modelAnimationOptions, preset: presetAnimationOptions};
}

export const getAllOnloadModelAnimationPlayDataBySceneId = (componentsById: IComponentsById | null | undefined, sceneId: string): IPlayModelAnimationsData[] => {
	let animationData: IPlayModelAnimationsData[] = [];
	if (!componentsById) return animationData;
	
	const scene = componentsById[sceneId] as ISceneComp;
	const sceneActions = scene.actions
	if (!sceneActions) return animationData;
	const onLoadActions = sceneActions[ITriggerTypes.onLoad];
	if (!onLoadActions) return animationData;

	for (let i = 0; i < onLoadActions.length; i++) {
		const action = onLoadActions[i];
		if (action.type !== IActionCategory.animateModel) continue;
		for (let j = 0; j < action.targetIds.length; j++) {
			const id = action.targetIds[j];
			animationData.push({
				id,
				isPlayingAnimation: true,
				animationName: action.animationName,
				animationRepetitions: action.animationRepetitions,
				isClampWhenFinished: action.isClampWhenFinished,
			})
		}
	}

	return animationData;
		
}