export enum VideoSourceTypes {
	user = 'user',
	youtube = 'youtube',
	vimeo = 'vimeo',
}

declare module ZML {
	interface BaseInfo {
		aspectRatio: number;
	}

	interface BaseEntityInfo extends BaseInfo {
		title: string;
	}

	interface TrackingImageInfo extends BaseInfo {
		imageUrl: string;
	}

	interface ImageInfo extends BaseEntityInfo {
		imageUrl: string;
	}

	interface VideoInfo extends BaseEntityInfo {
		title: string;
		videoUrl: string;
		playOnStart: boolean;
		thumbnailUrl: string;
		source: VideoSourceTypes;
	}

	interface ExternalVideoInfo extends BaseEntityInfo {
		title: string;
		duration: number;
		externalId: string;
		description: string;
		playOnStart: boolean;
		thumbnailUrl: string;
		source: VideoSourceTypes;
	}

	class BaseFile {
		getAspectRatio: (id: string) => number;
	}

	class MediaFile extends BaseFile {
		getTitle: (id: string) => string;
	}

	class Image extends MediaFile {
		imagesById: { [id: string]: ImageInfo };
		getImageUrl: (id: string) => string;
	}

	class TrackingImage extends BaseFile {
		trackingImagesById: { [id: string]: TrackingImageInfo };
		getImageUrl: (id: string) => string;
	}

	class Video extends MediaFile {
		videosById: { [id: string]: VideoInfo };
		getThumbnailUrl: (id: string) => string;
		getVideoUrl: (id: string) => string;
		getPlayOnStart: (id: string) => boolean;
		getAspectRatio: (id: string) => number;
		getSource: (id: string) => VideoSourceTypes;
	}

	class ExternalVideo extends MediaFile {
		videosById: { [id: string]: ExternalVideoInfo };
		getThumbnailUrl: (id: string) => string;
		getDescription: (id: string) => string;
		getDuration: (id: string) => number;
		getPlayOnStart: (id: string) => boolean;
		getExternalId: (id: string) => string;
	}
}

//https://d1sonmy4sf0d9y.cloudfront.net/images/104201-796a85d3327d47268b9cc0c87f9d7583/img_385_200.jpg
//https://d1sonmy4sf0d9y.cloudfront.net/images/104201-796a85d3327d47268b9cc0c87f9d7583/info.json

// {"id": 355228, "file_id": 2323416, "url": "//d1sonmy4sf0d9y.cloudfront.net/images/104201-796a85d3327d47268b9cc0c87f9d7583", "analysis_url": "//s3-eu-west-1.amazonaws.com/wizcontent/images/104201-796a85d3327d47268b9cc0c87f9d7583/imageanalysis.json", "progress_url": "https://d1sonmy4sf0d9y.cloudfront.net/job/ea901448-3e43-4ae7-8645-b21117ea0a6f/status.json"}

//https://my.zap.works/zapcode/8850091406817944442/tracking-image/info/

export class ZMLImage implements ZML.Image {
	imagesById: { [id: string]: ZML.ImageInfo } = {
		'0': {
			title: 'image 1',
			imageUrl:
				'https://d1sonmy4sf0d9y.cloudfront.net/images/4-c738383881ef454fad6d0ab791ddb4f3/img_512_512',
			aspectRatio: 1.33,
		},
		'1': {
			title: 'image 2',
			imageUrl:
				'https://d1sonmy4sf0d9y.cloudfront.net/images/104201-8a7e6c87e2a24bd6b3c6d7da382c706d/img_512_512',
			aspectRatio: 1.6,
		},
	};
	getTitle = (id: string) => this.imagesById[id].title;
	getAspectRatio = (id: string) => this.imagesById[id].aspectRatio;
	getImageUrl = (id: string) => this.imagesById[id].imageUrl;
}

export class ZMLTrackingImage implements ZML.TrackingImage {
	trackingImagesById: { [id: string]: ZML.TrackingImageInfo } = {
		'0': {
			imageUrl:
				'https://d1sonmy4sf0d9y.cloudfront.net/images/104201-796a85d3327d47268b9cc0c87f9d7583/img_512_512',
			aspectRatio: 1.6,
		},
	};
	getAspectRatio = (id: string) => this.trackingImagesById[id].aspectRatio;
	getImageUrl = (id: string) => this.trackingImagesById[id].imageUrl;
}

export class ZMLVideo implements ZML.Video {
	videosById: { [id: string]: ZML.VideoInfo } = {
		'0': {
			title: 'video 1',
			videoUrl:
				'https://d1sonmy4sf0d9y.cloudfront.net/videos/170258-da97c0f7b14f45288486e00cb83d7c61/auto.m3u8',
			playOnStart: false,
			thumbnailUrl:
				'https://d1sonmy4sf0d9y.cloudfront.net/videos/170258-da97c0f7b14f45288486e00cb83d7c61/img_512_512',
			aspectRatio: 1.5660377358490567,
			source: VideoSourceTypes.user,
		},
	};
	getTitle = (id: string) => this.videosById[id].title;
	getAspectRatio = (id: string) => this.videosById[id].aspectRatio;
	getVideoUrl = (id: string) => this.videosById[id].videoUrl;
	getPlayOnStart = (id: string) => this.videosById[id].playOnStart;
	getThumbnailUrl = (id: string) => this.videosById[id].thumbnailUrl;
	getSource = (id: string) => this.videosById[id].source;
}

export class ZMLExternalVideo implements ZML.ExternalVideo {
	videosById: { [id: string]: ZML.ExternalVideoInfo } = {
		'1': {
			title: 'video 2',
			description: 'Description 2',
			externalId: 'U5IailIzqdc',
			playOnStart: false,
			thumbnailUrl:
				'https://d1sonmy4sf0d9y.cloudfront.net/images/170258-f387dc793b2640bd97b0039f8da34270/img_512_512',
			aspectRatio: 1.77,
			duration: 0,
			source: VideoSourceTypes.youtube,
		},
		'2': {
			title: 'video 3',
			description: 'Description 2',
			externalId: '253989945',
			playOnStart: false,
			thumbnailUrl:
				'https://d1sonmy4sf0d9y.cloudfront.net/images/170258-6578491afd71486481497ad0bbad0a76/img_512_512',
			aspectRatio: 1.77,
			duration: 0,
			source: VideoSourceTypes.vimeo,
		},
	};
	getTitle = (id: string) => this.videosById[id].title;
	getAspectRatio = (id: string) => this.videosById[id].aspectRatio;
	getPlayOnStart = (id: string) => this.videosById[id].playOnStart;
	getDescription = (id: string) => this.videosById[id].description;
	getDuration = (id: string) => this.videosById[id].duration;
	getExternalId = (id: string) => this.videosById[id].externalId;
	getThumbnailUrl = (id: string) => this.videosById[id].thumbnailUrl;
	getSource = (id: string) => this.videosById[id].source;
}

// export class Video implements ZML.Video {
// 	private _imagesById: ZmlData = {
// 		'1':
// 			'https://d1sonmy4sf0d9y.cloudfront.net/images/4-c738383881ef454fad6d0ab791ddb4f3',
// 		'2':
// 			'https://d1sonmy4sf0d9y.cloudfront.net/images/104201-b1e3a9e0976a4602bf2ffc913b794da2',
// 	};
// 	private _videosById: ZmlData = {
// 		'1':
// 			'https://d1sonmy4sf0d9y.cloudfront.net/videos/170258-da97c0f7b14f45288486e00cb83d7c61',
// 		'2':
// 			'https://d1sonmy4sf0d9y.cloudfront.net/images/170258-f387dc793b2640bd97b0039f8da34270',
// 		'3':
// 			'https://d1sonmy4sf0d9y.cloudfront.net/images/170258-6578491afd71486481497ad0bbad0a76',
// 	};

// 	public getImageUrl(id: string) {
// 		return `${this._imagesById[id]}/img_512_512`;
// 	}
// 	public getImageInfoUrl(id: string) {
// 		return `${this._imagesById[id]}/info.json`;
// 	}

// 	public getVideoUrl(id: string) {
// 		switch (id) {
// 			case '1':
// 				return `${this._videosById[id]}/auto.m3u8`;
// 			case '2':
// 				return 'https://www.youtube.com/oembed?url=https://www.youtube.com/watch?v=qw9hwa_-raA';
// 			case '3':
// 				return 'https://player.vimeo.com/video/253989945';
// 			default:
// 				break;
// 		}
// 	}

// 	public getVideoInfoUrl(id: string) {
// 		return `${this._videosById[id]}/info.json`;
// 	}

// 	public getThumbnailUrl(id: string) {
// 		return `${this._videosById[id]}/img_512_512`;
// 	}
// }
