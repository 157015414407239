import React from 'react';
import { Color, Shader, Side, Texture, WebGLRenderer } from 'three';
import { IChromaKey } from '../component-data-structure';

export const getMaterial = (args: {
	stackedAlpha: boolean;
	hasAlpha: boolean;
	opacity: number;
	side: Side;
	videoTexture?: Texture;
	onBeforeCompile: (shader: Shader, renderer: WebGLRenderer) => void;
	imageTexture?: Texture;
	innerColor?: Color;
	chromaKey?: IChromaKey;
}) => {
	const { stackedAlpha, hasAlpha, opacity, side, videoTexture, onBeforeCompile, imageTexture, innerColor, chromaKey } = args;
	// if(!!chromaKey) console.log('getMaterial: stackedAlpha: ', stackedAlpha, 'hasAlpha: ', hasAlpha, '!!videoTexture: ', !!videoTexture, '!!imageTexture: ', !!imageTexture);

	// Stacked alpha video
	if (stackedAlpha && !!videoTexture) {
		// console.log('stacked alpha video');
		return <alphaMaterial packedTexture={videoTexture || new Texture()} transparent={true} opacity={opacity} depthWrite={false} side={side} />;
	}

	// Video that isn't alpha and doesn't have choma key active
	if (!hasAlpha && !!videoTexture) {
		// console.log('non alpha no chroma key video');
		return <meshBasicMaterial onBeforeCompile={onBeforeCompile} map={videoTexture} side={side} opacity={opacity} transparent={true} wireframe={false} depthWrite={false} />;
	}

	// Video that isn't alpha but does have chroma key active
	if (!!videoTexture) {
		// console.log('Video texture')
		if (hasAlpha && !!chromaKey && !!chromaKey.color) {
			// console.log('non alpha, does have chroma key video');
			return (
				// <meshBasicMaterial map={videoTexture} side={side} opacity={opacity} transparent={true} wireframe={false} depthWrite={false} />
				<meshChromaKeyMaterial
					onBeforeCompile={onBeforeCompile}
					uniforms={{
						map: { value: videoTexture },
						similarity: { value: chromaKey?.similarity },
						smoothness: { value: chromaKey?.smoothness },
						spill: { value: chromaKey?.spill },
						keyColor: { value: chromaKey?.color },
					}}
					needsUpdate={true}
					opacity={opacity}
					transparent={true}
					side={side}
					uniformsNeedUpdate={true}
					depthWrite={false}
				/>
			);
		} else {
			// console.log('NO chroma key');
			return <meshBasicMaterial onBeforeCompile={onBeforeCompile} map={videoTexture} side={side} opacity={opacity} transparent={true} wireframe={false} depthWrite={false}  />
		}
	}

	// Non video
	if (!!imageTexture) {
		// console.log('Image material');
		if (!!chromaKey && !!chromaKey.color && hasAlpha) {
			// console.log('chroma key, data: ', chromaKey)
			return <meshChromaKeyMaterial
				//onBeforeCompile={onBeforeCompile}
				uniforms={{
					map: { value: imageTexture },
					similarity: { value: chromaKey?.similarity },
					smoothness: { value: chromaKey?.smoothness },
					spill: { value: chromaKey?.spill }, 
					keyColor: { value: chromaKey?.color },
				}}
				needsUpdate={true}
				opacity={opacity}
				transparent={true}
				side={side}
				uniformsNeedUpdate={true}
				depthWrite={false}
			/>
		} else {
			// console.log('NO chroma key');
			return <meshBasicMaterial color={innerColor} map={imageTexture} side={side} opacity={opacity} transparent={true} wireframe={false} depthWrite={false} />;
		}
	}
	// console.log('No image or video, plain color material.')
	return <meshBasicMaterial color={innerColor} side={side} opacity={opacity} transparent={true} wireframe={false} depthWrite={false} />;
};