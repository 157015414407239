import { FaceMesh, FaceAnchor } from '@zappar/zappar';
import { BufferGeometry, Float32BufferAttribute, BufferAttribute, Uint16BufferAttribute } from 'three';
import { FaceMeshLoader, FaceMeshLoaderOptions } from './facemeshloader';

const defaultIdentity = new Float32Array([
	0.00005082991992821917, 0.0000825262613943778, -0.00006227332050912082, -0.00009568753011990339, 0.00004392920163809322, -0.00002985004539368674,
	0.00007082528463797644, -0.00003226186890969984, 0.000020247101929271594, -0.000032064974220702425, -0.000034272063203388825, -0.00002086049789795652,
	-0.0000025369222385052126, -0.0000803223520051688, 0.000039466303860535845, 0.000002735729140113108, 0.000006566972842847463, -0.0000019530932604538975,
	-0.0000012902354455945897, 0.0000051090978558931965, 0.00004360365346656181, 0.00003209905844414607, -0.0000055665736908849794, 0.00002697534000617452,
	-0.000014916489817551337, 0.000008153178896463942, -0.000028948452381882817, 0.000012192827853141353, -0.000044470343709690496, -0.000011951604392379522,
	0.000005610955668089446, -0.000010806014870468061, 0.00000337560072694032, 0.000011562996405700687, -0.0000051929891924373806, -0.0000036193580399412895,
	-0.0000049871682676894125, 0.00001622802301426418, -0.000005132507794769481, 0.0000031989600302040344, 0.000013423096788756084, 0.000007838467354304157,
	6.477116016867512e-7, -0.0000018067480596073437, -0.0000025145563995465636, -0.00000419465368395322, -0.00001502125451224856, -0.000010346981071052141,
	0.000006139267952676164, -0.000015484374671359546,
]);

const defaultExpression = new Float32Array([
	-0.014662845060229301, 0.0052428352646529675, -0.005468255840241909, 0.006003309041261673, 0.006824895273894072, 0.02970515564084053, 0.020419679582118988,
	0.04060492292046547, 0.01852342113852501, -0.017598802223801613, 0.008231299929320812, 0.01180630549788475, 0.005401745904237032, -0.008552968502044678,
	-0.002399259712547064, 0.01878315769135952, 0.018256133422255516, 0.0019635572098195553, -0.0013637688243761659, 0.008284845389425755, 0.010822730138897896,
	0.0018626537639647722, 0.0072355130687355995, -0.010725060477852821, 0.018334392458200455, -0.0011138368863612413, 0.016530685126781464,
	-0.00014561570424120873, 0.0031068946700543165,
]);

export class FaceBufferGeometry extends BufferGeometry {
	private _faceMesh!: FaceMesh;

	private hasSetIndices = false;

	private hasSetUVs = false;

	private vertices: Float32Array | undefined;

	private verticesAttribute: BufferAttribute | undefined;

	private normals: Float32Array | undefined;

	private normalsAttribute: BufferAttribute | undefined;

	private recalculateNormals = true;

	constructor(private options?: FaceMeshLoaderOptions) {
		super();
		this.setIndex([]);
		this.setAttribute('position', new Float32BufferAttribute([], 3));
		this.setAttribute('normal', new Float32BufferAttribute([], 3));
		this.setAttribute('uv', new Float32BufferAttribute([], 2));
	}

	public async _load(simplified = false) {
		// eslint-disable-next-line no-async-promise-executor
		return new Promise<void>(async res => {
			if (this?.options?.faceMesh) {
				this._faceMesh = this.options.faceMesh;
				res();
				return;
			}

			this._faceMesh = simplified ? new FaceMeshLoader().loadFullHeadSimplified(this.options) : new FaceMeshLoader().load(this.options);
			res();
		});
	}

	public updateDesignTime() {
		this.updateFromIdentityExpression(defaultIdentity, defaultExpression);

		if (this._faceMesh.vertices.length === 0) {
			requestAnimationFrame(this.updateDesignTime.bind(this));
		}
	}

	private _updateIndices() {
		if (this.hasSetIndices) return;
		if (this._faceMesh.indices.length === 0) return;
		this.setIndex(new Uint16BufferAttribute(this._faceMesh.indices, 1));
		this.hasSetIndices = true;
	}

	private _updateUVs() {
		if (this.hasSetUVs) return;
		if (this._faceMesh.uvs.length === 0) return;
		this.setAttribute('uv', new BufferAttribute(this._faceMesh.uvs, 2));
		this.hasSetUVs = true;
	}

	/**
	 * @ignore
	 */
	public get calculateNormals(): boolean {
		return this.recalculateNormals;
	}

	/**
	 * @ignore
	 */
	public set calculateNormals(b: boolean) {
		this.recalculateNormals = b;
		if (!this.recalculateNormals) {
			if (typeof (this as any).removeAttribute === 'function') {
				(this as any).removeAttribute('normal');
			}
			delete this.normals;
		}
	}

	public updateFromFaceAnchor(f: FaceAnchor): void {
		this.updateFromIdentityExpression(f.identity, f.expression);
	}

	public updateFromIdentityExpression(identity: Float32Array, expression: Float32Array): void {
		if (this._faceMesh.vertices.length === 0) return;
		this._updateIndices();
		this._updateUVs();
		this._faceMesh.updateFromIdentityExpression(identity, expression);
		if (!this.vertices) {
			this.vertices = new Float32Array(this._faceMesh.vertices.length);
			this.verticesAttribute = new BufferAttribute(this.vertices, 3);
			this.setAttribute('position', this.verticesAttribute);
		}
		this.vertices.set(this._faceMesh.vertices);
		if (this.verticesAttribute) this.verticesAttribute.needsUpdate = true;

		this.computeBoundingSphere();

		if (!this.calculateNormals) return;

		if (!this.normals) {
			this.normals = new Float32Array(this._faceMesh.normals.length);
			this.normalsAttribute = new BufferAttribute(this.normals, 3);
			this.setAttribute('normal', this.normalsAttribute);
		}
		this.normals.set(this._faceMesh.normals);
		if (this.normalsAttribute) this.normalsAttribute.needsUpdate = true;
	}

	dispose() {
		this._faceMesh.destroy();
		super.dispose();
	}

	get faceMesh() {
		return this._faceMesh;
	}
}
