import React from 'react';
import { IIconParentProps } from './index';
import { COLORS } from '../../utils';

const rightIcon: React.FunctionComponent<IIconParentProps> = ({
	className,
	hexFill: fill,
	secondHexFill: secondFill, 
	viewBox,
	onClick = () => {},
}) => {
	const hexFill = fill || COLORS.midnight;
	const secondHexFill = secondFill; 
	return (
		<svg
			onClick={onClick}
			className={className || ''}
			width="68px"
			height="68px"
			viewBox={viewBox ? viewBox.join(' ') : '0 0 68 68'}
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
		>
			<defs>
				<rect id="path-1" x="0" y="0" width="68" height="68" rx="5"></rect>
				<linearGradient x1="100%" y1="50%" x2="0%" y2="50%" id="linearGradient-3">
					<stop stopColor={secondHexFill} offset="0%"></stop>
					<stop stopColor={secondHexFill} stopOpacity="0" offset="100%"></stop>
				</linearGradient>
			</defs>
			<g id="Components" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
				<g id="Illustration">
					<mask id="mask-2" fill="white">
						<use href="#path-1"></use>
					</mask>
					<g id="Mask"></g>
					<polygon id="Path-Copy-9" fill="url(#linearGradient-3)" fillRule="nonzero" mask="url(#mask-2)" points="-17 22.6148507 -2.56715528 6.8 17.8328447 6.8 17.2472582 18.6534773 16.58811 31.9959974 10.7026223 45.675647 30.1572612 59.8 -2.56715528 59.8 -16.5699677 45.4802808 -3.81189004 31.9959974"></polygon>
					<path d="M63.2983051,37.6440671 L53.4766766,27.8224386 C53.1568192,27.4959855 52.7190219,27.3120213 52.2619875,27.3120213 C51.8049532,27.3120213 51.3671559,27.4959855 51.0472985,27.8224386 L41.22567,37.6440671 C40.6308321,38.3283249 40.6630361,39.3551793 41.299582,40.0008186 C41.9361278,40.646458 42.9624222,40.6932233 43.6550481,40.1081506 L52.332573,31.5017993 L61.0077486,40.1775614 C61.327606,40.5040145 61.7654033,40.6879787 62.2224376,40.6879787 C62.679472,40.6879787 63.1172692,40.5040145 63.4371267,40.1775614 C63.7703154,39.8306239 63.9445455,39.3609488 63.9182277,38.8806492 C63.8919099,38.4003496 63.6674057,37.952523 63.2983051,37.6440671 Z" id="Path" fill={hexFill} mask="url(#mask-2)" transform="translate(52.360415, 34.000000) rotate(90.000000) translate(-52.360415, -34.000000) "></path>
					<polygon id="Path-Copy" fill={hexFill} mask="url(#mask-2)" points="3.4 22.6148507 17.8328447 6.8 38.4 30.8755409 20.8993196 45.675647 30.1572612 59.8 3.83003227 45.4802808 16.58811 31.9959974"></polygon>
				</g>
			</g>
		</svg>
	);
};

export default rightIcon;
