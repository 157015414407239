import React, { FunctionComponent, memo, useMemo } from 'react';
import * as THREE from 'three';
interface IParentProps {
    name: string;
	opacity: number;
    shape: THREE.Shape;
	color: string;
    renderOrder: number;
    depthWrite: boolean;
    raycast?: (_: THREE.Raycaster, intersects: THREE.Intersection<THREE.Object3D<Event>>[]) => void
}

const Shape: FunctionComponent<IParentProps> = ({ 
    shape: shp, 
    color: lColor, 
    opacity, 
    renderOrder,
    raycast
}) => {
    const color = useMemo(() => new THREE.Color(lColor), [lColor]);
    const geometry = useMemo(() => <shapeGeometry attach="geometry" args={[shp]} />, [shp])

	return (
        <group raycast={raycast} key={`shape_mesh_group`} name={`shape_mesh_group`}>
            <mesh raycast={raycast} renderOrder={1}>
				{geometry}
				<meshBasicMaterial 
                    colorWrite={false}
                    depthWrite={true}
                    polygonOffset={true} 
                    polygonOffsetUnits={10}
                    polygonOffsetFactor={10}
                    side={THREE.DoubleSide}
                />
			</mesh>
            <mesh raycast={raycast} renderOrder={renderOrder}>
                {geometry}
                <meshBasicMaterial
                    color={color}
                    opacity={opacity}
                    depthWrite={false}
                    transparent={true} 
                    side={THREE.DoubleSide}
                />  
            </mesh>
        </group> 
	);
};

export default memo(Shape);
