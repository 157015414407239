// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Grid--v4HT5{display:grid;grid-gap:16px;padding:16px 0;grid-template-columns:auto auto;justify-items:center}.Grid--v4HT5 div{display:flex;align-items:center;justify-content:center;cursor:pointer;background-color:#96bfef}.Grid--v4HT5 .Btn_Rounded--1xJVv{border-radius:36px}.Grid--v4HT5 .Btn_Slightly_Rounded--btMYY{border-radius:6px}.Grid--v4HT5 .Frame--2dx3Q{border:2px solid #96bfef;background-color:rgba(0,0,0,0);color:#96bfef}", ""]);
// Exports
exports.locals = {
	"Grid": "Grid--v4HT5",
	"Btn_Rounded": "Btn_Rounded--1xJVv",
	"Btn_Slightly_Rounded": "Btn_Slightly_Rounded--btMYY",
	"Frame": "Frame--2dx3Q"
};
module.exports = exports;
