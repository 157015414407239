import { IComponentType, IComponentUnion, IFaceLandmark, IFaceLandmarkGroup, ISceneComp, ITuple3 } from '../component-data-structure';
import { FACE_LANDMARK_DATA } from './constants';

export const getFaceTrackingComponentIdsBySceneId = (sceneId: string, componentsById: { [id: string]: IComponentUnion }): string[] => {
	const scene = componentsById[sceneId] as ISceneComp;
	const faceLandmarkGroups = scene.children.filter((componentId) => componentsById[componentId].type == IComponentType.FaceLandmarkGroup);
	return faceLandmarkGroups.reduce((acc, faceLandmarkGroupId) => {
		const faceLandmarkGroup = componentsById[faceLandmarkGroupId] as IFaceLandmarkGroup;
		return [...acc, ...faceLandmarkGroup.children];
	}, [] as string[]);
};

export const uarTarget = (landmark: IFaceLandmark, isFrontCamera: boolean): string => {
	switch (landmark) {
		case IFaceLandmark.earLeft:
			return isFrontCamera ? 'ear-right' : 'ear-left';
		case IFaceLandmark.earRight:
			return isFrontCamera ? 'ear-left' : 'ear-right';
		case IFaceLandmark.eyeLeft:
			return isFrontCamera ? 'eye-right' : 'eye-left';
		case IFaceLandmark.eyeRight:
			return isFrontCamera ? 'eye-left' : 'eye-right';
		case IFaceLandmark.eyebrowLeft:
			return isFrontCamera ? 'eyebrow-right' : 'eyebrow-left';
		case IFaceLandmark.eyebrowRight:
			return isFrontCamera ? 'eyebrow-left' : 'eyebrow-right';
		case IFaceLandmark.chin:
			return 'chin';
		case IFaceLandmark.lipTop:
			return 'lip-top';
		case IFaceLandmark.lipBottom:
			return 'lip-bottom';
		case IFaceLandmark.noseBase:
			return 'nose-base';
		case IFaceLandmark.noseBridge:
			return 'nose-bridge';
		case IFaceLandmark.noseTip:
			return 'nose-tip';
		default:
			return 'origin';
	}
};

export const getFaceLandmarkPositionsByHeadbustType = (bustType: string): Record<IFaceLandmark, ITuple3> => {
	return Object.keys(FACE_LANDMARK_DATA[bustType]).reduce((acc, landmark) => {
		(acc as any)[landmark] = (FACE_LANDMARK_DATA as any)[bustType][landmark].position;
		return acc;
	}, {} as Record<IFaceLandmark, ITuple3>)
}
