import React from 'react';
import ChromeLogo from '../../../assets/BrowserError/chrome.png';
import FirefoxLogo from '../../../assets/BrowserError/firefox.png';
import SafariLogo from '../../../assets/BrowserError/safari.png';
import ZapworksLogo from '../../../assets/BrowserError/ZapWorks-small.png';
import CSS from './BrowserError.scss';

const BrowserError = () => {
	return (
		<div className={CSS.Outer}>
			<div className={CSS.BrowserError}>
				<img src={ZapworksLogo} className={CSS.ZapworksLogo} />
				<h1>Browser not supported</h1>
				<p>Looks like you are using a browser which is not supported by ZapWorks Designer (Beta).</p>
				<p>Please update your browser or try one of the recommended below and relaunch Designer. See you later!</p>
				<div className={CSS.BrowserList}>
					<div>
						<a rel="noreferrer" target="_blank" href="https://www.apple.com/safari/">
							<img src={SafariLogo} />
							<br />
							Safari
						</a>
					</div>
					<div>
						<a rel="noreferrer" target="_blank" href="https://www.google.com/chrome/">
							<img src={ChromeLogo} />
							<br />
							Chrome
						</a>
					</div>
					<div>
						<a rel="noreferrer" target="_blank" href="https://www.mozilla.org/firefox/new/">
							<img src={FirefoxLogo} />
							<br />
							Firefox
						</a>
					</div>
				</div>
			</div>
		</div>
	);
};

export default BrowserError;
