import React from 'react';
import { IIconParentProps } from './index';
import { COLORS } from '../../utils';

const plusIcon: React.SFC<IIconParentProps> = ({
	hexFill: fill,
	viewBox,
	className,
	onClick = () => {},
	title, 
}) => {
	const hexFill = fill || COLORS.midnight;
	return (
		<svg
			onClick={onClick}
			className={className || ''}
			width="9px"
			height="9px"
			viewBox={viewBox ? viewBox.join(' ') : `0 0 9 9`}
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
		>
			<title>{title}</title>
			<path
				fill={hexFill}
				d="M1653.50555,987.999864 C1653.7817,987.999864 1653.99944,988.251744 1653.99944,988.5 C1653.99944,988.748256 1653.72553,988.995318 1653.50555,988.995318 C1653.50555,988.995318 1652.33719,988.995318 1650.00047,988.995318 L1650.00047,992.506769 C1650.00047,992.779173 1649.77965,993 1649.50724,993 C1649.23484,993 1649.01401,992.779173 1649.01401,992.506769 L1649.01401,988.995318 L1649.01401,988.995318 L1645.50555,988.995318 C1645.22941,988.995318 1645,988.748834 1645,988.5 C1645,988.278814 1645.17831,987.999864 1645.50555,987.999864 C1645.50555,987.999864 1646.67504,987.999864 1649.01401,987.999864 L1649.01401,984.493231 C1649.01401,984.220827 1649.23484,984 1649.50724,984 C1649.77965,984 1650.00047,984.220827 1650.00047,984.493231 L1650.00047,987.999864 L1650.00047,987.999864 L1653.50555,987.999864 Z"
				transform="translate(-1645 -984)"
			/>
		</svg>
	);
};

export default plusIcon;
