import { ThreeEvent, useFrame, useThree } from '@react-three/fiber';
import React, { FunctionComponent, useMemo, useRef} from 'react';
import { DoubleSide, Object3D, TextureLoader, Vector3 } from 'three';
import { maths } from '../../../utils';
import {
	ITuple3,
	ITuple4,
} from '../../r3f/r3f-components/component-data-structure';
import { useSRGBColors } from '../r3f-components/hooks/useSRGBColors';
import { IUserData } from '../r3f-components/utils/general';

interface IParentProps {
	enabled?: boolean;
	visible?: boolean;
	rotation: ITuple3;
	position: ITuple3;
	scale: ITuple3;
	color?: ITuple4;
	depthWrite?: boolean;
	renderOrder?: number;
	stopEventPropagation?: boolean;
	pointerDownHandler?: (e: ThreeEvent<PointerEvent>) => any;
	pointerUpHandler?: (e: ThreeEvent<PointerEvent>) => any;
	pointerMoveHandler?: (e: ThreeEvent<PointerEvent>) => any;
	pointerOverHandler?: (e: ThreeEvent<PointerEvent>) => any;
	pointerOutHandler?: (e: ThreeEvent<PointerEvent>) => any;
	onUpdate?: (self: Object3D) => any;
	id?: string;
  debug?: boolean;
  isMarkerPlane?: boolean;
  name?: string;
  side?: THREE.Side;
  lookAtCamera?: boolean;
  revertAfterLookAt?: boolean;
  positionInfrontOfCamera?: boolean;
  map?: string;
}

const Plane: FunctionComponent<IParentProps> = ({
	enabled,
	visible,
	rotation: r,
	position,
	scale: s,
	color: c = [0, 0, 0, 1],
	depthWrite = true,
	stopEventPropagation: stpEventProp,
	pointerDownHandler,
	pointerUpHandler,
	pointerMoveHandler,
	pointerOverHandler, 
	pointerOutHandler, 
	onUpdate,
	renderOrder = -999,
	id = '',
  debug = false,
  isMarkerPlane = false,
  name = 'Plane',
  side = DoubleSide,
  lookAtCamera = false,
  positionInfrontOfCamera = false,
  revertAfterLookAt = false,
  map
}) => {
	const rotation = useMemo(() => [
		maths.toRadians(r[0]),
		maths.toRadians(r[1]),
		maths.toRadians(r[2]),
	] as ITuple3, [r]);
	const scale = useMemo(() => [s[0] * 2, s[1] * 2, s[2] * 2] as ITuple3, [s]);
	const initialEvent = useRef<ThreeEvent<PointerEvent>>(null)
  const color = useSRGBColors(c);
  
  const { camera } = useThree();
  const planeRef = useRef<any>();
  const texture = useMemo(() => map ? new TextureLoader().load(map) : null, [map]);

  useFrame(() => {
    if (planeRef.current && lookAtCamera) {
      if (positionInfrontOfCamera) {
        const dir = camera.getWorldDirection(new Vector3()).multiplyScalar(0.03);
        const newPos = camera.position.clone().add(dir);
        planeRef.current.position.copy(newPos);
      }

      planeRef.current.lookAt(camera.position);
    } 
    if (planeRef.current && lookAtCamera === false && revertAfterLookAt) {      
      planeRef.current.lookAt(new Vector3(0,0,10));
    }
  }); 

	const userData: IUserData = useMemo(() => {return {renderOrder, contentId: id, enabled}}, [renderOrder, id, enabled])
  const geometry = useMemo(() => <planeGeometry />, [])

	return (
    <group name={name} renderOrder={(isMarkerPlane ? renderOrder : undefined)} ref={planeRef}>
      <mesh renderOrder={1} name={`${name}-PlaneMeshDepthWriteTrue`}>
        {geometry}
        <meshBasicMaterial
          colorWrite={false}
          polygonOffset={true}
          polygonOffsetUnits={10}
          polygonOffsetFactor={10}
					depthWrite={true}
					visible={false}
          side={DoubleSide}
        />
      </mesh>
      <mesh
         name={`${name}-PlaneMeshDepthWriteFalse`}
        renderOrder={renderOrder}
        visible={visible}
        position={position}
        rotation={rotation}
        scale={scale}
        onPointerDown={(e) => {
          if (stpEventProp) e.stopPropagation();
          if (enabled) {
            if (debug) console.log("onPointerDown: ", e.point);
            pointerDownHandler?.(e);
          }
        }}
        onPointerMove={(e) => {
          if (stpEventProp) e.stopPropagation();
          if (enabled) {
            if (debug) console.log("onPointerMove: ", e.point);
            if (initialEvent.current === null) initialEvent.current = e;
            else if (
              initialEvent.current.point.x === e.point.x &&
              initialEvent.current.point.x === e.point.x &&
              initialEvent.current.point.x === e.point.x
            )
              return;
            pointerMoveHandler?.(e);
          }
        }}
        onPointerUp={(e) => {
          if (stpEventProp) e.stopPropagation();
          if (enabled) {
            if (debug) console.log("onPointerUp: ", e.point);
            pointerUpHandler?.(e);
          }
        }}
        onPointerOver={(e) => {
          if (stpEventProp) e.stopPropagation();
          if (enabled) {
            if (debug) console.log("onPointerOver: ", e.point);
            pointerOverHandler?.(e);
          }
        }}
        onPointerOut={(e) => {
          if (stpEventProp) e.stopPropagation();
          if (enabled) {
            if (debug) console.log("onPointerOut: ", e.point);
            pointerOutHandler?.(e);
          }
        }}
        onUpdate={(self) => onUpdate?.(self)}
        userData={userData}
      >
        {geometry}
        <meshBasicMaterial
          color={color}
          opacity={c ? c[3] : 1}
          transparent={true}
          map={texture || undefined}
          side={side}
          // This "should" be false but fixes an issue where entities were showing "above" the empty target image plane. As this is only on planes ( not shapes ) this may be safe to leave
					depthWrite={!isMarkerPlane} 
        />
      </mesh>
    </group>
  );
};

export default Plane;
