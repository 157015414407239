import React from 'react';
import { IIconParentProps } from './index';
import { COLORS } from '../../utils';

const AnimateParticle16: React.FunctionComponent<IIconParentProps> = ({ width, height, className, hexFill = COLORS.midnight, viewBox, onClick = () => {} }) => {
	return (
		<svg
			onClick={onClick}
			className={className || ''}
			width={width ? width : '16px'}
			height={height ? height : '16px'}
			viewBox={viewBox ? viewBox.join(' ') : `0 0 16 16`}
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
		>
			<g clipPath="url(#clip0_14265_43157)">
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M2.80005 0.535901C3.1002 0.362611 3.47779 0.402298 3.73535 0.634205L5.03118 1.80097L6.54127 0.929119C6.84141 0.755828 7.21901 0.795515 7.47657 1.02742C7.73413 1.25933 7.81307 1.63071 7.6721 1.94733L6.96288 3.54028L8.2587 4.70704C8.51626 4.93895 8.5952 5.31033 8.45423 5.62695C8.31326 5.94357 7.98445 6.1334 7.63977 6.09718L5.90562 5.91491L5.19639 7.50786C5.05543 7.82448 4.72662 8.01432 4.38193 7.97809C4.03725 7.94186 3.7551 7.68781 3.68304 7.3488L3.3205 5.6432L1.58635 5.46094C1.24167 5.42471 0.959517 5.17066 0.887458 4.83165C0.8154 4.49264 0.969828 4.14579 1.26998 3.9725L2.78007 3.10065L2.41753 1.39505C2.34547 1.05604 2.4999 0.709192 2.80005 0.535901ZM3.68343 3.50285L1.66998 4.66532L3.98218 4.90834L4.46556 7.18247L5.41119 5.05854L7.72339 5.30156L5.99563 3.74587L6.94127 1.62194L4.92781 2.78441L3.20005 1.22872L3.68343 3.50285Z"
					fill={hexFill}
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M2.80005 11.2C3.46279 11.2 4.00005 10.6627 4.00005 10C4.00005 9.33726 3.46279 8.8 2.80005 8.8C2.13731 8.8 1.60005 9.33726 1.60005 10C1.60005 10.6627 2.13731 11.2 2.80005 11.2ZM2.80005 12C3.90462 12 4.80005 11.1046 4.80005 10C4.80005 8.89543 3.90462 8 2.80005 8C1.69548 8 0.800049 8.89543 0.800049 10C0.800049 11.1046 1.69548 12 2.80005 12Z"
					fill={hexFill}
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M13.2 7.2C13.8628 7.2 14.4 6.66274 14.4 6C14.4 5.33726 13.8628 4.8 13.2 4.8C12.5373 4.8 12 5.33726 12 6C12 6.66274 12.5373 7.2 13.2 7.2ZM13.2 8C14.3046 8 15.2 7.10457 15.2 6C15.2 4.89543 14.3046 4 13.2 4C12.0955 4 11.2 4.89543 11.2 6C11.2 7.10457 12.0955 8 13.2 8Z"
					fill={hexFill}
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M5.83139 12.5052C5.44207 11.5806 4.9096 10.9397 4.30303 10.7881L4.49706 10.0119C5.4905 10.2603 6.15803 11.2194 6.5687 12.1948C6.98927 13.1936 7.20005 14.3464 7.20005 15.2H6.40005C6.40005 14.4536 6.21083 13.4064 5.83139 12.5052Z"
					fill={hexFill}
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M6.69169 7.43395C6.33378 6.71218 5.90894 6.20166 5.42116 5.95777L5.77893 5.24223C6.49116 5.59834 7.01631 6.28783 7.40841 7.07855C7.80367 7.87566 8.08732 8.82337 8.29101 9.76547C8.69847 11.65 8.80005 13.5794 8.80005 14.4H8.00005C8.00005 13.6206 7.90162 11.75 7.50908 9.93454C7.31278 9.02663 7.04643 8.14934 6.69169 7.43395Z"
					fill={hexFill}
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M10.924 10.8919C11.4646 9.46675 12.1483 8.20236 12.72 7.44L12.08 6.96C11.4518 7.79765 10.7355 9.13325 10.176 10.6081C9.61698 12.082 9.20005 13.733 9.20005 15.2H10C10 13.8671 10.3831 12.318 10.924 10.8919Z"
					fill={hexFill}
				/>
			</g>
			<defs>
				<clipPath id="clip0_14265_43157">
					<rect width="16" height="16" fill="white" />
				</clipPath>
			</defs>
		</svg>
	);
};

export default AnimateParticle16;
