import { IFileType } from "./types";

export const getContentTypeFromIFileType = (t: IFileType) => {
  switch(t) {
    case IFileType.UARPackage:
      return 'application/zip'
    default:
      return t.toString()
  }
}

export const sleep = (ms: number) => {
  return new Promise((resolve) => setTimeout(resolve, ms))
}
