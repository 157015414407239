import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import { IIconParentProps } from '../../../../assets/icons';
import { IFontTypes } from '../../../r3f/r3f-components/component-data-structure';
import Option, { IOptionOverflowType } from './Option/Option';
import CSS from './MenuList.scss';

export enum IMenuItemType {
	standard = 'standard',
	faded = 'faded',
	disabled = 'disabled',
	danger = 'danger',
	header = 'header',
}

export interface IMenuItem {
	text: string;
	val: string;
	rhsText?: string;
	fontFamily?: IFontTypes;
	fn?: () => unknown;
	iconFn?: (option: IMenuItem) => unknown;
	icon?: React.FunctionComponent<IIconParentProps>;
	type?: IMenuItemType;
	testId?: string;
	isDivider?: boolean;
	isIndented?: boolean;
}

interface IParentProps {
	style?: React.CSSProperties;
	show: boolean;
	className?: string;
	optionData: IMenuItem[];
	selectedOption?: IMenuItem | null;
	overflow?: IOptionOverflowType;
	endButtonText?: string;
	testId?: string;
	onChange: (option: IMenuItem) => unknown;
	onHoverOption?: (option: IMenuItem | null) => unknown;
	onMouseEnterOption?: (option: IMenuItem) => unknown;
	onMouseLeaveOption?: (option: IMenuItem) => unknown;
	onMouseEnterIcon?: (option: IMenuItem) => unknown;
	onMouseLeaveIcon?: (option: IMenuItem) => unknown;
}

const MenuList: FunctionComponent<IParentProps> = ({
	style,
	className,
	show,
	optionData,
	selectedOption,
	endButtonText,
	testId = 'MenuList',
	onChange,
	overflow,
	onHoverOption,
	onMouseEnterOption,
	onMouseLeaveOption,
	onMouseEnterIcon,
	onMouseLeaveIcon,
}) => {	
	const menuEl = useRef(null);
	const [hoveredOption, setHoveredOption] = useState<IMenuItem | null>(null);
	const classes = [CSS.MenuList, className ? className : ''];

	useEffect(() => {
		onHoverOption?.(hoveredOption)
	}, [hoveredOption])

	const options = optionData.map((opt, i) => {
		return (
			<Option
				key={i}
				id={opt.val}
				testId={opt.testId ? opt.testId : 'Option'}
				icon={opt.icon ? opt.icon : undefined}
				rhsText={opt.rhsText ? opt.rhsText : undefined}
				fontFamily={opt.fontFamily}
				overflow={overflow}
				endButtonText={endButtonText}
				isHovered={opt.val === hoveredOption?.val}
				type={opt.type || IMenuItemType.standard}
				isActive={!!selectedOption && selectedOption.val === opt.val}
				isIndented={opt.isIndented}
				isDivider={opt.isDivider}
				onClick={() => {
					onChange(opt);
					opt?.fn?.();
				}}
				iconOnClick={() => opt?.iconFn?.(opt)}
				onMouseEnter={() => {
					onMouseEnterOption?.(opt);
					setHoveredOption((prevOption) => {
						if (prevOption) onMouseLeaveIcon?.(prevOption);
						if (prevOption) onMouseLeaveOption?.(prevOption);
						return opt;
					});
				}}
				iconOnMouseEnter={() => onMouseEnterIcon?.(opt)}
				iconOnMouseLeave={() => onMouseLeaveIcon?.(opt)}
			>
				{opt.text}
			</Option>
		);
	});
	if (!show) return null;
	return (
		<div
			ref={menuEl}
			className={classes.join(' ')}
			data-testid={testId}
			style={style}
			onMouseLeave={() => {
				setHoveredOption((prevOption) => {
					if (prevOption) onMouseLeaveIcon?.(prevOption);
					if (prevOption) onMouseLeaveOption?.(prevOption);
					return null;
				});
			}}
		>
			{options}
		</div>
	);
};

export default MenuList;
