import { ThreeEvent, useFrame, useThree } from '@react-three/fiber';
import React, { memo, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Group, Mesh, Object3D } from 'three';
import { IDesignerState } from '../../../../../typings';
import { onSetActiveFaceLandmark } from '../../../../store/actions';
import { getActiveSceneFaceLandmarkIdByFacelandmark } from '../../../../utils/pure/get';
import { IFaceLandmark, ITuple3, IFaceLandmarkGroup } from '../../r3f-components/component-data-structure';
import { isObjectVisible } from './utils/isObjectVisible';


interface IParentProps {
    landmark: IFaceLandmark;
    position: ITuple3;
}

const FaceLandmarkHotspot: React.FunctionComponent<IParentProps> = ({ position, landmark}) => {
    const dispatch = useDispatch();
    const groupRef = useRef<Group>(null);
    const { scene } = useThree();
    const occludeRef = useRef<Object3D>(scene.getObjectByName('zappar-head-mesh')!);
    const entityDragHotspotIsActive = useSelector((state: IDesignerState) => state.userReducer.entityMenuDragActive);
    const activeLandmark = useSelector((state: IDesignerState) => state.userReducer.activeFaceLandmark);
    const hoveredDropdownLandmark = useSelector((state: IDesignerState) => state.userReducer.hoveredDropdownFacelandmark);
    const isScreenRelativeMode =  useSelector((state: IDesignerState) => state.userReducer.isScreenRelativeMode);
    const activeSceneId = useSelector((state: IDesignerState) => state.userReducer.activeSceneId);
    const showFaceLandmarks = useSelector((state: IDesignerState) => state.userReducer.showFaceLandmarks);
    const componentsById = useSelector((state: IDesignerState) => state.contentReducer.contentDoc.componentsById);
    const [ selectedEntityId ] = useSelector((state: IDesignerState) => state.userReducer.selectedEntityIds || []);
    const [isOccluded, setIsOccluded] = useState(false);
    const anchorPlaceholderRef = useRef<Mesh>(null);

    const isOriginLandmark = landmark === IFaceLandmark.origin
    const isVisible = (!isOccluded || isOriginLandmark) && !isScreenRelativeMode && (landmark === activeLandmark || showFaceLandmarks);
    const isSelected = landmark === activeLandmark || landmark === hoveredDropdownLandmark;

    useFrame(({camera, raycaster}) => {
        if (!groupRef.current) return;
        groupRef.current.lookAt(camera.position);
        if (occludeRef.current && !isOriginLandmark) {
            // camera.updateMatrixWorld()
            // groupRef.current.updateWorldMatrix(true, false)
            const occluded = !isObjectVisible(groupRef.current!, camera, raycaster, [occludeRef.current])
            if (occluded !== isOccluded)  setIsOccluded(occluded);
        }
    })

    useEffect(() => {
        if (isScreenRelativeMode) return;
        if (!selectedEntityId) {
            dispatch(onSetActiveFaceLandmark(null));
            return;
        }
        const id = getActiveSceneFaceLandmarkIdByFacelandmark(landmark, activeSceneId!, componentsById);
        if (!id) return;

        const { children: faceLandmarkChildren } = componentsById[id] as IFaceLandmarkGroup;

        if (faceLandmarkChildren.includes(selectedEntityId)) {
            dispatch(onSetActiveFaceLandmark(landmark))
            return
        }
    }, [selectedEntityId, componentsById, landmark, isScreenRelativeMode]);


    const pointerMoveHandler = (e: ThreeEvent<PointerEvent>) => {
        e.stopPropagation();
        if (landmark === activeLandmark) return;
       
        let distance = Infinity;
        let closestLandmark: IFaceLandmark | undefined = undefined;
        e.intersections.forEach(intersection => {
            if (intersection.object.name.includes('landmark-')) {
                const distanceToCenter = intersection.point.distanceTo(intersection.object.parent!.position)
                if (distanceToCenter < distance) {
                    distance = distanceToCenter;
                    closestLandmark = intersection.object.name.split('-')[1] as IFaceLandmark;
                }
            }
        })
        if (closestLandmark !== landmark) return;
        dispatch(onSetActiveFaceLandmark(landmark))
    }


    const scaleFactor = isOriginLandmark ? 0.8 : 0.5;

    return (
        <group 
            scale={1}
            ref={groupRef} 
            name={landmark} // don't remove! needed in to access three group in other parts of app
            position={position}  
            visible={isVisible}
        >
            <mesh renderOrder={9999} ref={anchorPlaceholderRef}>
                <circleGeometry args={[0.06 * scaleFactor, 20 * scaleFactor]} />
                <meshBasicMaterial color={isSelected ? '#4A90E2' : '#B4BBC3'} transparent opacity={isOriginLandmark ? 0.5 : 1} depthWrite={false} depthTest={false}/>
            </mesh>
            <mesh renderOrder={9999}>
                <ringGeometry args={[0.06 * scaleFactor, 0.075 * scaleFactor, 20, 20]} />
                <meshBasicMaterial color={'white'} transparent opacity={isOriginLandmark ? 0.5 : 1} depthWrite={false} depthTest={false}/>
            </mesh>
            <mesh
                renderOrder={2}
                name={`landmark-${landmark}`}
                onPointerMove={ isVisible && entityDragHotspotIsActive ? pointerMoveHandler : undefined }
            >
                <planeGeometry args={[0.6, 0.6]}/>
                <meshBasicMaterial visible={false} color='black'/>
            </mesh>
        </group>
    );
}

export default memo(FaceLandmarkHotspot);