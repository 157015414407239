import { IFont, IFontTypes } from './types/general';
export const ROOT_COMPNENT_ID = 'd2_root_v1';
export const FIRST_SCENE_TITLE = 'Scene 1';
export const FIRST_SCENE_ID = '16fd2706-8baf-433b-82eb-8c7fada847da';

export const FONT_TYPES: IFont[] = [
	{
		fontFamily: IFontTypes.Roboto100,
		url: 'https://fonts.gstatic.com/s/roboto/v30/KFOkCnqEu92Fr1MmgWxM.woff',
		desc: 'Standard title',
		scale: [0.26 * 2, 0.04 * 2, 0],  
	},
	
	{
		fontFamily: IFontTypes.Roboto100italic,
		url: 'https://fonts.gstatic.com/s/roboto/v30/KFOiCnqEu92Fr1Mu51QrIzQ.woff',
		desc: 'Standard title',
		scale: [0.26 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.Roboto300,
		url: 'https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmSU5vAA.woff',
		desc: 'Standard title',
		scale: [0.26 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.Roboto300italic,
		url: 'https://fonts.gstatic.com/s/roboto/v30/KFOjCnqEu92Fr1Mu51TjARc-.woff',
		desc: 'Standard title',
		scale: [0.26 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.Roboto400,
		url: 'https://fonts.gstatic.com/s/roboto/v30/KFOmCnqEu92Fr1Me5g.woff',
		jsonUrl: 'https://d1mfzu0xo6h6ih.cloudfront.net/designer/fonts/3d/roboto-400.json',
		desc: 'Standard title',
		scale: [0.26 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.Roboto400italic,
		url: 'https://fonts.gstatic.com/s/roboto/v30/KFOkCnqEu92Fr1Mu52xM.woff',
		desc: 'Standard title',
		scale: [0.26 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.Roboto500,
		url: 'https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmEU9vAA.woff',
		desc: 'Standard title',
		scale: [0.26 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.Roboto500italic,
		url: 'https://fonts.gstatic.com/s/roboto/v30/KFOjCnqEu92Fr1Mu51S7ABc-.woff',
		desc: 'Standard title',
		scale: [0.26 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.Roboto700,
		url: 'https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmWUlvAA.woff',
		jsonUrl: 'https://d1mfzu0xo6h6ih.cloudfront.net/designer/fonts/3d/roboto-700.json',
		desc: 'Standard title',
		scale: [0.26 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.Roboto700italic,
		url: 'https://fonts.gstatic.com/s/roboto/v30/KFOjCnqEu92Fr1Mu51TzBhc-.woff',
		desc: 'Standard title',
		scale: [0.26 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.Roboto900,
		url: 'https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmYUtvAA.woff',
		desc: 'Standard title',
		scale: [0.26 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.Roboto900italic,
		url: 'https://fonts.gstatic.com/s/roboto/v30/KFOjCnqEu92Fr1Mu51TLBBc-.woff',
		desc: 'Standard title',
		scale: [0.26 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.OpenSans300,
		url: 'https://fonts.gstatic.com/s/opensans/v35/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsiH0C4k.woff',
		desc: 'Open Sans',
		scale: [0.26 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.OpenSans300italic,
		url: 'https://fonts.gstatic.com/s/opensans/v35/memQYaGs126MiZpBA-UFUIcVXSCEkx2cmqvXlWq8tWZ0Pw86hd0Rk5hkaVQ.woff',
		desc: 'Open Sans',
		scale: [0.26 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.OpenSans400,
		url: 'https://fonts.gstatic.com/s/opensans/v35/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsjZ0C4k.woff',
		desc: 'Open Sans',
		scale: [0.26 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.OpenSans400italic,
		url: 'https://fonts.gstatic.com/s/opensans/v35/memQYaGs126MiZpBA-UFUIcVXSCEkx2cmqvXlWq8tWZ0Pw86hd0Rk8ZkaVQ.woff',
		desc: 'Open Sans',
		scale: [0.26 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.OpenSans500,
		url: 'https://fonts.gstatic.com/s/opensans/v35/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsjr0C4k.woff',
		desc: 'Open Sans',
		scale: [0.26 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.OpenSans500italic,
		url: 'https://fonts.gstatic.com/s/opensans/v35/memQYaGs126MiZpBA-UFUIcVXSCEkx2cmqvXlWq8tWZ0Pw86hd0Rk_RkaVQ.woff',
		desc: 'Open Sans',
		scale: [0.26 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.OpenSans600,
		url: 'https://fonts.gstatic.com/s/opensans/v35/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsgH1y4k.woff',
		desc: 'Open Sans',
		scale: [0.26 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.OpenSans600italic,
		url: 'https://fonts.gstatic.com/s/opensans/v35/memQYaGs126MiZpBA-UFUIcVXSCEkx2cmqvXlWq8tWZ0Pw86hd0RkxhjaVQ.woff',
		desc: 'Open Sans',
		scale: [0.26 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.OpenSans700,
		url: 'https://fonts.gstatic.com/s/opensans/v35/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsg-1y4k.woff',
		desc: 'Open Sans',
		scale: [0.26 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.OpenSans700italic,
		url: 'https://fonts.gstatic.com/s/opensans/v35/memQYaGs126MiZpBA-UFUIcVXSCEkx2cmqvXlWq8tWZ0Pw86hd0RkyFjaVQ.woff',
		desc: 'Open Sans',
		scale: [0.26 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.OpenSans800,
		url: 'https://fonts.gstatic.com/s/opensans/v35/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgshZ1y4k.woff',
		desc: 'Open Sans',
		scale: [0.26 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.OpenSans800italic,
		url: 'https://fonts.gstatic.com/s/opensans/v35/memQYaGs126MiZpBA-UFUIcVXSCEkx2cmqvXlWq8tWZ0Pw86hd0Rk0ZjaVQ.woff',
		desc: 'Open Sans',
		scale: [0.26 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.Montserrat100,
		url: 'https://fonts.gstatic.com/s/montserrat/v25/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCtr6Xw9aXw.woff',
		desc: 'Montserrat',
		scale: [0.26 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.Montserrat100italic,
		url: 'https://fonts.gstatic.com/s/montserrat/v25/JTUFjIg1_i6t8kCHKm459Wx7xQYXK0vOoz6jq6R8WXx0oA.woff',
		desc: 'Montserrat',
		scale: [0.26 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.Montserrat200,
		url: 'https://fonts.gstatic.com/s/montserrat/v25/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCvr6Hw9aXw.woff',
		desc: 'Montserrat',
		scale: [0.26 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.Montserrat200italic,
		url: 'https://fonts.gstatic.com/s/montserrat/v25/JTUFjIg1_i6t8kCHKm459Wx7xQYXK0vOoz6jqyR9WXx0oA.woff',
		desc: 'Montserrat',
		scale: [0.26 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.Montserrat300,
		url: 'https://fonts.gstatic.com/s/montserrat/v25/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCs16Hw9aXw.woff',
		desc: 'Montserrat',
		scale: [0.26 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.Montserrat300italic,
		url: 'https://fonts.gstatic.com/s/montserrat/v25/JTUFjIg1_i6t8kCHKm459Wx7xQYXK0vOoz6jq_p9WXx0oA.woff',
		desc: 'Montserrat',
		scale: [0.26 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.Montserrat400,
		url: 'https://fonts.gstatic.com/s/montserrat/v25/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCtr6Hw9aXw.woff',
		desc: 'Montserrat',
		scale: [0.26 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.Montserrat400italic,
		url: 'https://fonts.gstatic.com/s/montserrat/v25/JTUFjIg1_i6t8kCHKm459Wx7xQYXK0vOoz6jq6R9WXx0oA.woff',
		desc: 'Montserrat',
		scale: [0.26 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.Montserrat500,
		url: 'https://fonts.gstatic.com/s/montserrat/v25/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCtZ6Hw9aXw.woff',
		desc: 'Montserrat',
		scale: [0.26 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.Montserrat500italic,
		url: 'https://fonts.gstatic.com/s/montserrat/v25/JTUFjIg1_i6t8kCHKm459Wx7xQYXK0vOoz6jq5Z9WXx0oA.woff',
		desc: 'Montserrat',
		scale: [0.26 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.Montserrat600,
		url: 'https://fonts.gstatic.com/s/montserrat/v25/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCu173w9aXw.woff',
		desc: 'Montserrat',
		scale: [0.26 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.Montserrat600italic,
		url: 'https://fonts.gstatic.com/s/montserrat/v25/JTUFjIg1_i6t8kCHKm459Wx7xQYXK0vOoz6jq3p6WXx0oA.woff',
		desc: 'Montserrat',
		scale: [0.26 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.Montserrat700,
		url: 'https://fonts.gstatic.com/s/montserrat/v25/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCuM73w9aXw.woff',
		desc: 'Montserrat',
		scale: [0.26 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.Montserrat700italic,
		url: 'https://fonts.gstatic.com/s/montserrat/v25/JTUFjIg1_i6t8kCHKm459Wx7xQYXK0vOoz6jq0N6WXx0oA.woff',
		desc: 'Montserrat',
		scale: [0.26 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.Montserrat800,
		url: 'https://fonts.gstatic.com/s/montserrat/v25/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCvr73w9aXw.woff',
		desc: 'Montserrat',
		scale: [0.26 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.Montserrat800italic,
		url: 'https://fonts.gstatic.com/s/montserrat/v25/JTUFjIg1_i6t8kCHKm459Wx7xQYXK0vOoz6jqyR6WXx0oA.woff',
		desc: 'Montserrat',
		scale: [0.26 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.Montserrat900,
		url: 'https://fonts.gstatic.com/s/montserrat/v25/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCvC73w9aXw.woff',
		desc: 'Montserrat',
		scale: [0.26 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.Montserrat900italic,
		url: 'https://fonts.gstatic.com/s/montserrat/v25/JTUFjIg1_i6t8kCHKm459Wx7xQYXK0vOoz6jqw16WXx0oA.woff',
		desc: 'Montserrat',
		scale: [0.26 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.Lato100,
		url: 'https://fonts.gstatic.com/s/lato/v24/S6u8w4BMUTPHh30AXC-s.woff',
		desc: 'Lato',
		scale: [0.26 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.Lato100italic,
		url: 'https://fonts.gstatic.com/s/lato/v24/S6u-w4BMUTPHjxsIPx-oPCQ.woff',
		desc: 'Lato',
		scale: [0.26 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.Lato300,
		url: 'https://fonts.gstatic.com/s/lato/v24/S6u9w4BMUTPHh7USSwiPHw.woff',
		desc: 'Lato',
		scale: [0.26 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.Lato300italic,
		url: 'https://fonts.gstatic.com/s/lato/v24/S6u_w4BMUTPHjxsI9w2_Gwfr.woff',
		desc: 'Lato',
		scale: [0.26 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.Lato400,
		url: 'https://fonts.gstatic.com/s/lato/v24/S6uyw4BMUTPHjx4wWA.woff',
		jsonUrl: 'https://d1mfzu0xo6h6ih.cloudfront.net/designer/fonts/3d/lato-400.json',
		desc: 'Lato',
		scale: [0.26 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.Lato400italic,
		url: 'https://fonts.gstatic.com/s/lato/v24/S6u8w4BMUTPHjxsAXC-s.woff',
		desc: 'Lato',
		scale: [0.26 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.Lato700,
		url: 'https://fonts.gstatic.com/s/lato/v24/S6u9w4BMUTPHh6UVSwiPHw.woff',
		desc: 'Lato',
		scale: [0.26 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.Lato700italic,
		url: 'https://fonts.gstatic.com/s/lato/v24/S6u_w4BMUTPHjxsI5wq_Gwfr.woff',
		desc: 'Lato',
		scale: [0.26 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.Lato900,
		url: 'https://fonts.gstatic.com/s/lato/v24/S6u9w4BMUTPHh50XSwiPHw.woff',
		jsonUrl: 'https://d1mfzu0xo6h6ih.cloudfront.net/designer/fonts/3d/lato-900.json',
		desc: 'Lato',
		scale: [0.26 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.Lato900italic,
		url: 'https://fonts.gstatic.com/s/lato/v24/S6u_w4BMUTPHjxsI3wi_Gwfr.woff',
		desc: 'Lato',
		scale: [0.26 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.Poppins100,
		url: 'https://fonts.gstatic.com/s/poppins/v20/pxiGyp8kv8JHgFVrLPTucXtG.woff',
		desc: 'Poppins',
		scale: [0.26 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.Poppins100italic,
		url: 'https://fonts.gstatic.com/s/poppins/v20/pxiAyp8kv8JHgFVrJJLmE0tDMPQ.woff',
		desc: 'Poppins',
		scale: [0.26 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.Poppins200,
		url: 'https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLFj_Z11lEw.woff',
		desc: 'Poppins',
		scale: [0.26 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.Poppins200italic,
		url: 'https://fonts.gstatic.com/s/poppins/v20/pxiDyp8kv8JHgFVrJJLmv1pVFteI.woff',
		desc: 'Poppins',
		scale: [0.26 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.Poppins300,
		url: 'https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLDz8Z11lEw.woff',
		desc: 'Poppins',
		scale: [0.26 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.Poppins300italic,
		url: 'https://fonts.gstatic.com/s/poppins/v20/pxiDyp8kv8JHgFVrJJLm21lVFteI.woff',
		desc: 'Poppins',
		scale: [0.26 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.Poppins400,
		url: 'https://fonts.gstatic.com/s/poppins/v20/pxiEyp8kv8JHgFVrJJbedA.woff',
		desc: 'Poppins',
		scale: [0.26 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.Poppins400italic,
		url: 'https://fonts.gstatic.com/s/poppins/v20/pxiGyp8kv8JHgFVrJJLucXtG.woff',
		desc: 'Poppins',
		scale: [0.26 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.Poppins500,
		url: 'https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLGT9Z11lEw.woff',
		jsonUrl: 'https://d1mfzu0xo6h6ih.cloudfront.net/designer/fonts/3d/poppins-400.json',
		desc: 'Poppins',
		scale: [0.26 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.Poppins500italic,
		url: 'https://fonts.gstatic.com/s/poppins/v20/pxiDyp8kv8JHgFVrJJLmg1hVFteI.woff',
		desc: 'Poppins',
		scale: [0.26 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.Poppins600,
		url: 'https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLEj6Z11lEw.woff',
		desc: 'Poppins',
		scale: [0.26 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.Poppins600italic,
		url: 'https://fonts.gstatic.com/s/poppins/v20/pxiDyp8kv8JHgFVrJJLmr19VFteI.woff',
		desc: 'Poppins',
		scale: [0.26 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.Poppins700,
		url: 'https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLCz7Z11lEw.woff',
		jsonUrl: 'https://d1mfzu0xo6h6ih.cloudfront.net/designer/fonts/3d/poppins-700.json',
		desc: 'Poppins',
		scale: [0.26 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.Poppins700italic,
		url: 'https://fonts.gstatic.com/s/poppins/v20/pxiDyp8kv8JHgFVrJJLmy15VFteI.woff',
		desc: 'Poppins',
		scale: [0.26 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.Poppins800,
		url: 'https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLDD4Z11lEw.woff',
		desc: 'Poppins',
		scale: [0.26 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.Poppins800italic,
		url: 'https://fonts.gstatic.com/s/poppins/v20/pxiDyp8kv8JHgFVrJJLm111VFteI.woff',
		desc: 'Poppins',
		scale: [0.26 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.Poppins900,
		url: 'https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLBT5Z11lEw.woff',
		desc: 'Poppins',
		scale: [0.26 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.Poppins900italic,
		url: 'https://fonts.gstatic.com/s/poppins/v20/pxiDyp8kv8JHgFVrJJLm81xVFteI.woff',
		desc: 'Poppins',
		scale: [0.26 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.Inter100,
		url: 'https://fonts.gstatic.com/s/inter/v12/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuLyeMZs.woff',
		desc: 'Inter',
		scale: [0.26 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.Inter200,
		url: 'https://fonts.gstatic.com/s/inter/v12/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuDyfMZs.woff',
		desc: 'Inter',
		scale: [0.26 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.Inter300,
		url: 'https://fonts.gstatic.com/s/inter/v12/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuOKfMZs.woff',
		desc: 'Inter',
		scale: [0.26 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.Inter400,
		url: 'https://fonts.gstatic.com/s/inter/v12/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuLyfMZs.woff',
		desc: 'Inter',
		scale: [0.26 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.Inter500,
		url: 'https://fonts.gstatic.com/s/inter/v12/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuI6fMZs.woff',
		desc: 'Inter',
		scale: [0.26 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.Inter600,
		url: 'https://fonts.gstatic.com/s/inter/v12/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuGKYMZs.woff',
		desc: 'Inter',
		scale: [0.26 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.Inter700,
		url: 'https://fonts.gstatic.com/s/inter/v12/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuFuYMZs.woff',
		desc: 'Inter',
		scale: [0.26 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.Inter800,
		url: 'https://fonts.gstatic.com/s/inter/v12/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuDyYMZs.woff',
		desc: 'Inter',
		scale: [0.26 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.Inter900,
		url: 'https://fonts.gstatic.com/s/inter/v12/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuBWYMZs.woff',
		desc: 'Inter',
		scale: [0.26 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.Oswald200,
		url: 'https://fonts.gstatic.com/s/oswald/v49/TK3_WkUHHAIjg75cFRf3bXL8LICs13FvsUJiYw.woff',
		desc: 'Oswald',
		scale: [0.26 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.Oswald300,
		url: 'https://fonts.gstatic.com/s/oswald/v49/TK3_WkUHHAIjg75cFRf3bXL8LICs169vsUJiYw.woff',
		desc: 'Oswald',
		scale: [0.26 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.Oswald400,
		url: 'https://fonts.gstatic.com/s/oswald/v49/TK3_WkUHHAIjg75cFRf3bXL8LICs1_FvsUJiYw.woff',
		desc: 'Oswald',
		scale: [0.26 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.Oswald500,
		url: 'https://fonts.gstatic.com/s/oswald/v49/TK3_WkUHHAIjg75cFRf3bXL8LICs18NvsUJiYw.woff',
		desc: 'Oswald',
		scale: [0.26 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.Oswald600,
		url: 'https://fonts.gstatic.com/s/oswald/v49/TK3_WkUHHAIjg75cFRf3bXL8LICs1y9osUJiYw.woff',
		desc: 'Oswald',
		scale: [0.26 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.Oswald700,
		url: 'https://fonts.gstatic.com/s/oswald/v49/TK3_WkUHHAIjg75cFRf3bXL8LICs1xZosUJiYw.woff',
		desc: 'Oswald',
		scale: [0.26 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.Raleway100,
		url: 'https://fonts.gstatic.com/s/raleway/v28/1Ptxg8zYS_SKggPN4iEgvnHyvveLxVvao7CMPrc.woff',
		desc: 'Raleway',
		scale: [0.26 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.Raleway100italic,
		url: 'https://fonts.gstatic.com/s/raleway/v28/1Pt_g8zYS_SKggPNyCgSQamb1W0lwk4S4WjNDrcfJg.woff',
		desc: 'Raleway',
		scale: [0.26 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.Raleway200,
		url: 'https://fonts.gstatic.com/s/raleway/v28/1Ptxg8zYS_SKggPN4iEgvnHyvveLxVtaorCMPrc.woff',
		desc: 'Raleway',
		scale: [0.26 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.Raleway200italic,
		url: 'https://fonts.gstatic.com/s/raleway/v28/1Pt_g8zYS_SKggPNyCgSQamb1W0lwk4S4ejMDrcfJg.woff',
		desc: 'Raleway',
		scale: [0.26 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.Raleway300,
		url: 'https://fonts.gstatic.com/s/raleway/v28/1Ptxg8zYS_SKggPN4iEgvnHyvveLxVuEorCMPrc.woff',
		desc: 'Raleway',
		scale: [0.26 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.Raleway300italic,
		url: 'https://fonts.gstatic.com/s/raleway/v28/1Pt_g8zYS_SKggPNyCgSQamb1W0lwk4S4TbMDrcfJg.woff',
		desc: 'Raleway',
		scale: [0.26 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.Raleway400,
		url: 'https://fonts.gstatic.com/s/raleway/v28/1Ptxg8zYS_SKggPN4iEgvnHyvveLxVvaorCMPrc.woff',
		desc: 'Raleway',
		scale: [0.26 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.Raleway400italic,
		url: 'https://fonts.gstatic.com/s/raleway/v28/1Pt_g8zYS_SKggPNyCgSQamb1W0lwk4S4WjMDrcfJg.woff',
		desc: 'Raleway',
		scale: [0.26 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.Raleway500,
		url: 'https://fonts.gstatic.com/s/raleway/v28/1Ptxg8zYS_SKggPN4iEgvnHyvveLxVvoorCMPrc.woff',
		desc: 'Raleway',
		scale: [0.26 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.Raleway500italic,
		url: 'https://fonts.gstatic.com/s/raleway/v28/1Pt_g8zYS_SKggPNyCgSQamb1W0lwk4S4VrMDrcfJg.woff',
		desc: 'Raleway',
		scale: [0.26 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.Raleway600,
		url: 'https://fonts.gstatic.com/s/raleway/v28/1Ptxg8zYS_SKggPN4iEgvnHyvveLxVsEpbCMPrc.woff',
		desc: 'Raleway',
		scale: [0.26 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.Raleway600italic,
		url: 'https://fonts.gstatic.com/s/raleway/v28/1Pt_g8zYS_SKggPNyCgSQamb1W0lwk4S4bbLDrcfJg.woff',
		desc: 'Raleway',
		scale: [0.26 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.Raleway700,
		url: 'https://fonts.gstatic.com/s/raleway/v28/1Ptxg8zYS_SKggPN4iEgvnHyvveLxVs9pbCMPrc.woff',
		desc: 'Raleway',
		scale: [0.26 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.Raleway700italic,
		url: 'https://fonts.gstatic.com/s/raleway/v28/1Pt_g8zYS_SKggPNyCgSQamb1W0lwk4S4Y_LDrcfJg.woff',
		desc: 'Raleway',
		scale: [0.26 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.Raleway800,
		url: 'https://fonts.gstatic.com/s/raleway/v28/1Ptxg8zYS_SKggPN4iEgvnHyvveLxVtapbCMPrc.woff',
		desc: 'Raleway',
		scale: [0.26 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.Raleway800italic,
		url: 'https://fonts.gstatic.com/s/raleway/v28/1Pt_g8zYS_SKggPNyCgSQamb1W0lwk4S4ejLDrcfJg.woff',
		desc: 'Raleway',
		scale: [0.26 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.Raleway900,
		url: 'https://fonts.gstatic.com/s/raleway/v28/1Ptxg8zYS_SKggPN4iEgvnHyvveLxVtzpbCMPrc.woff',
		desc: 'Raleway',
		scale: [0.26 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.Raleway900italic,
		url: 'https://fonts.gstatic.com/s/raleway/v28/1Pt_g8zYS_SKggPNyCgSQamb1W0lwk4S4cHLDrcfJg.woff',
		desc: 'Raleway',
		scale: [0.26 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.Rubik300,
		url: 'https://fonts.gstatic.com/s/rubik/v28/iJWZBXyIfDnIV5PNhY1KTN7Z-Yh-WYi1Uw.woff',
		desc: 'Rubik',
		scale: [0.26 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.Rubik300italic,
		url: 'https://fonts.gstatic.com/s/rubik/v28/iJWbBXyIfDnIV7nEt3KSJbVDV49rz8sDE0Uz.woff',
		desc: 'Rubik',
		scale: [0.26 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.Rubik400,
		url: 'https://fonts.gstatic.com/s/rubik/v28/iJWZBXyIfDnIV5PNhY1KTN7Z-Yh-B4i1Uw.woff',
		desc: 'Rubik',
		scale: [0.26 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.Rubik400italic,
		url: 'https://fonts.gstatic.com/s/rubik/v28/iJWbBXyIfDnIV7nEt3KSJbVDV49rz8tdE0Uz.woff',
		desc: 'Rubik',
		scale: [0.26 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.Rubik500,
		url: 'https://fonts.gstatic.com/s/rubik/v28/iJWZBXyIfDnIV5PNhY1KTN7Z-Yh-NYi1Uw.woff',
		desc: 'Rubik',
		scale: [0.26 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.Rubik500italic,
		url: 'https://fonts.gstatic.com/s/rubik/v28/iJWbBXyIfDnIV7nEt3KSJbVDV49rz8tvE0Uz.woff',
		desc: 'Rubik',
		scale: [0.26 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.Rubik600,
		url: 'https://fonts.gstatic.com/s/rubik/v28/iJWZBXyIfDnIV5PNhY1KTN7Z-Yh-2Y-1Uw.woff',
		desc: 'Rubik',
		scale: [0.26 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.Rubik600italic,
		url: 'https://fonts.gstatic.com/s/rubik/v28/iJWbBXyIfDnIV7nEt3KSJbVDV49rz8uDFEUz.woff',
		desc: 'Rubik',
		scale: [0.26 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.Rubik700,
		url: 'https://fonts.gstatic.com/s/rubik/v28/iJWZBXyIfDnIV5PNhY1KTN7Z-Yh-4I-1Uw.woff',
		desc: 'Rubik',
		scale: [0.26 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.Rubik700italic,
		url: 'https://fonts.gstatic.com/s/rubik/v28/iJWbBXyIfDnIV7nEt3KSJbVDV49rz8u6FEUz.woff',
		desc: 'Rubik',
		scale: [0.26 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.Rubik800,
		url: 'https://fonts.gstatic.com/s/rubik/v28/iJWZBXyIfDnIV5PNhY1KTN7Z-Yh-h4-1Uw.woff',
		desc: 'Rubik',
		scale: [0.26 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.Rubik800italic,
		url: 'https://fonts.gstatic.com/s/rubik/v28/iJWbBXyIfDnIV7nEt3KSJbVDV49rz8vdFEUz.woff',
		desc: 'Rubik',
		scale: [0.26 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.Rubik900,
		url: 'https://fonts.gstatic.com/s/rubik/v28/iJWZBXyIfDnIV5PNhY1KTN7Z-Yh-ro-1Uw.woff',
		desc: 'Rubik',
		scale: [0.26 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.Rubik900italic,
		url: 'https://fonts.gstatic.com/s/rubik/v28/iJWbBXyIfDnIV7nEt3KSJbVDV49rz8v0FEUz.woff',
		desc: 'Rubik',
		scale: [0.26 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.Borel400,
		url: 'https://fonts.gstatic.com/s/borel/v2/6qLOKZsftAPisjsmaSQ.woff',
		desc: 'Borel',
		scale: [0.26 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.Creepster400,
		url: 'https://fonts.gstatic.com/s/creepster/v13/AlZy_zVUqJz4yMrniH4Rcn3_.woff',
		desc: 'Creepster',
		scale: [0.26 * 2, 0.04 * 2, 0]  
	},

	{
		fontFamily: IFontTypes.Pacifico400,
		url: 'https://fonts.gstatic.com/s/pacifico/v22/FwZY7-Qmy14u9lezJ-6H6M8.woff',
		desc: 'Pacifico',
		scale: [0.26 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.ConcertOne400,
		url: 'https://fonts.gstatic.com/s/concertone/v17/VEM1Ro9xs5PjtzCu-srDqSTijPg.woff',
		desc: 'Concert One',
		scale: [0.26 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.Slabo27px400,
		url: 'https://fonts.gstatic.com/s/slabo27px/v12/mFT0WbgBwKPR_Z4hGN2qgx8D0w.woff',
		desc: 'Slabo 27px',
		scale: [0.26 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.PressStart2P400,
		url: 'https://fonts.gstatic.com/s/pressstart2p/v15/e3t4euO8T-267oIAQAu6jDQyK0nR.woff',
		desc: 'Press Start 2P',
		scale: [0.26 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.AbrilFatface400,
		url: 'https://fonts.gstatic.com/s/abrilfatface/v19/zOL64pLDlL1D99S8g8PtiKchq-dmiw.woff',
		desc: 'Abril Fatface',
		scale: [0.26 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.BagelFatOne400,
		url: 'https://fonts.gstatic.com/s/bagelfatone/v1/hYkPPucsQOr5dy02WmQr5Zkd4BNsuQ.woff',
		jsonUrl: 'https://d1mfzu0xo6h6ih.cloudfront.net/designer/fonts/3d/bagel-fat-one-400.json',
		desc: 'Bagel Fat One',
		scale: [0.26 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.SourceCodePro200,
		url: 'https://fonts.gstatic.com/s/sourcecodepro/v22/HI_diYsKILxRpg3hIP6sJ7fM7PqPMcMnZFqUwX28DEyQhM0.woff',
		desc: 'Source Code Pro',
		scale: [0.26 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.SourceCodePro200italic,
		url: 'https://fonts.gstatic.com/s/sourcecodepro/v22/HI_jiYsKILxRpg3hIP6sJ7fM7PqlOPHYvDP_W9O7GQTT7I1rSg.woff',
		desc: 'Source Code Pro',
		scale: [0.26 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.SourceCodePro300,
		url: 'https://fonts.gstatic.com/s/sourcecodepro/v22/HI_diYsKILxRpg3hIP6sJ7fM7PqPMcMnZFqUwX28DJKQhM0.woff',
		desc: 'Source Code Pro',
		scale: [0.26 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.SourceCodePro300italic,
		url: 'https://fonts.gstatic.com/s/sourcecodepro/v22/HI_jiYsKILxRpg3hIP6sJ7fM7PqlOPHYvDP_W9O7GQTTMo1rSg.woff',
		desc: 'Source Code Pro',
		scale: [0.26 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.SourceCodePro400,
		url: 'https://fonts.gstatic.com/s/sourcecodepro/v22/HI_diYsKILxRpg3hIP6sJ7fM7PqPMcMnZFqUwX28DMyQhM0.woff',
		desc: 'Source Code Pro',
		scale: [0.26 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.SourceCodePro400italic,
		url: 'https://fonts.gstatic.com/s/sourcecodepro/v22/HI_jiYsKILxRpg3hIP6sJ7fM7PqlOPHYvDP_W9O7GQTTbI1rSg.woff',
		desc: 'Source Code Pro',
		scale: [0.26 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.SourceCodePro500,
		url: 'https://fonts.gstatic.com/s/sourcecodepro/v22/HI_diYsKILxRpg3hIP6sJ7fM7PqPMcMnZFqUwX28DP6QhM0.woff',
		desc: 'Source Code Pro',
		scale: [0.26 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.SourceCodePro500italic,
		url: 'https://fonts.gstatic.com/s/sourcecodepro/v22/HI_jiYsKILxRpg3hIP6sJ7fM7PqlOPHYvDP_W9O7GQTTXo1rSg.woff',
		desc: 'Source Code Pro',
		scale: [0.26 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.SourceCodePro600,
		url: 'https://fonts.gstatic.com/s/sourcecodepro/v22/HI_diYsKILxRpg3hIP6sJ7fM7PqPMcMnZFqUwX28DBKXhM0.woff',
		desc: 'Source Code Pro',
		scale: [0.26 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.SourceCodePro600italic,
		url: 'https://fonts.gstatic.com/s/sourcecodepro/v22/HI_jiYsKILxRpg3hIP6sJ7fM7PqlOPHYvDP_W9O7GQTTsoprSg.woff',
		desc: 'Source Code Pro',
		scale: [0.26 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.SourceCodePro700,
		url: 'https://fonts.gstatic.com/s/sourcecodepro/v22/HI_diYsKILxRpg3hIP6sJ7fM7PqPMcMnZFqUwX28DCuXhM0.woff',
		desc: 'Source Code Pro',
		scale: [0.26 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.SourceCodePro700italic,
		url: 'https://fonts.gstatic.com/s/sourcecodepro/v22/HI_jiYsKILxRpg3hIP6sJ7fM7PqlOPHYvDP_W9O7GQTTi4prSg.woff',
		desc: 'Source Code Pro',
		scale: [0.26 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.SourceCodePro800,
		url: 'https://fonts.gstatic.com/s/sourcecodepro/v22/HI_diYsKILxRpg3hIP6sJ7fM7PqPMcMnZFqUwX28DEyXhM0.woff',
		desc: 'Source Code Pro',
		scale: [0.26 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.SourceCodePro800italic,
		url: 'https://fonts.gstatic.com/s/sourcecodepro/v22/HI_jiYsKILxRpg3hIP6sJ7fM7PqlOPHYvDP_W9O7GQTT7IprSg.woff',
		desc: 'Source Code Pro',
		scale: [0.26 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.SourceCodePro900,
		url: 'https://fonts.gstatic.com/s/sourcecodepro/v22/HI_diYsKILxRpg3hIP6sJ7fM7PqPMcMnZFqUwX28DGWXhM0.woff',
		desc: 'Source Code Pro',
		scale: [0.26 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.SourceCodePro900italic,
		url: 'https://fonts.gstatic.com/s/sourcecodepro/v22/HI_jiYsKILxRpg3hIP6sJ7fM7PqlOPHYvDP_W9O7GQTTxYprSg.woff',
		desc: 'Source Code Pro',
		scale: [0.26 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.BebasNeue400,
		url: 'https://fonts.gstatic.com/s/bebasneue/v10/JTUSjIg69CK48gW7PXoo9WlhzQ.woff',
		desc: 'Bebas Neue',
		scale: [0.26 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.PlayfairDisplay400,
		url: 'https://fonts.gstatic.com/s/playfairdisplay/v30/nuFvD-vYSZviVYUb_rj3ij__anPXJzDwcbmjWBN2PKdFvXDTbtU.woff',
		desc: 'Playfair Display',
		scale: [0.26 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.PlayfairDisplay400italic,
		url: 'https://fonts.gstatic.com/s/playfairdisplay/v30/nuFRD-vYSZviVYUb_rj3ij__anPXDTnCjmHKM4nYO7KN_qiTXtXA_w.woff',
		desc: 'Playfair Display',
		scale: [0.26 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.PlayfairDisplay500,
		url: 'https://fonts.gstatic.com/s/playfairdisplay/v30/nuFvD-vYSZviVYUb_rj3ij__anPXJzDwcbmjWBN2PKd3vXDTbtU.woff',
		desc: 'Playfair Display',
		scale: [0.26 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.PlayfairDisplay500italic,
		url: 'https://fonts.gstatic.com/s/playfairdisplay/v30/nuFRD-vYSZviVYUb_rj3ij__anPXDTnCjmHKM4nYO7KN_pqTXtXA_w.woff',
		desc: 'Playfair Display',
		scale: [0.26 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.PlayfairDisplay600,
		url: 'https://fonts.gstatic.com/s/playfairdisplay/v30/nuFvD-vYSZviVYUb_rj3ij__anPXJzDwcbmjWBN2PKebunDTbtU.woff',
		desc: 'Playfair Display',
		scale: [0.26 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.PlayfairDisplay600italic,
		url: 'https://fonts.gstatic.com/s/playfairdisplay/v30/nuFRD-vYSZviVYUb_rj3ij__anPXDTnCjmHKM4nYO7KN_naUXtXA_w.woff',
		desc: 'Playfair Display',
		scale: [0.26 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.PlayfairDisplay700,
		url: 'https://fonts.gstatic.com/s/playfairdisplay/v30/nuFvD-vYSZviVYUb_rj3ij__anPXJzDwcbmjWBN2PKeiunDTbtU.woff',
		desc: 'Playfair Display',
		scale: [0.26 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.PlayfairDisplay700italic,
		url: 'https://fonts.gstatic.com/s/playfairdisplay/v30/nuFRD-vYSZviVYUb_rj3ij__anPXDTnCjmHKM4nYO7KN_k-UXtXA_w.woff',
		desc: 'Playfair Display',
		scale: [0.26 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.PlayfairDisplay800,
		url: 'https://fonts.gstatic.com/s/playfairdisplay/v30/nuFvD-vYSZviVYUb_rj3ij__anPXJzDwcbmjWBN2PKfFunDTbtU.woff',
		desc: 'Playfair Display',
		scale: [0.26 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.PlayfairDisplay800italic,
		url: 'https://fonts.gstatic.com/s/playfairdisplay/v30/nuFRD-vYSZviVYUb_rj3ij__anPXDTnCjmHKM4nYO7KN_iiUXtXA_w.woff',
		desc: 'Playfair Display',
		scale: [0.26 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.PlayfairDisplay900,
		url: 'https://fonts.gstatic.com/s/playfairdisplay/v30/nuFvD-vYSZviVYUb_rj3ij__anPXJzDwcbmjWBN2PKfsunDTbtU.woff',
		desc: 'Playfair Display',
		scale: [0.26 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.PlayfairDisplay900italic,
		url: 'https://fonts.gstatic.com/s/playfairdisplay/v30/nuFRD-vYSZviVYUb_rj3ij__anPXDTnCjmHKM4nYO7KN_gGUXtXA_w.woff',
		desc: 'Playfair Display',
		scale: [0.26 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.RobotoSlab100,
		url: 'https://fonts.gstatic.com/s/robotoslab/v25/BngbUXZYTXPIvIBgJJSb6s3BzlRRfKOFbvjojIWWaw.woff',
		desc: 'Roboto Slab',
		scale: [0.26 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.RobotoSlab200,
		url: 'https://fonts.gstatic.com/s/robotoslab/v25/BngbUXZYTXPIvIBgJJSb6s3BzlRRfKOFbvjoDISWaw.woff',
		desc: 'Roboto Slab',
		scale: [0.26 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.RobotoSlab300,
		url: 'https://fonts.gstatic.com/s/robotoslab/v25/BngbUXZYTXPIvIBgJJSb6s3BzlRRfKOFbvjo0oSWaw.woff',
		desc: 'Roboto Slab',
		scale: [0.26 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.RobotoSlab400,
		url: 'https://fonts.gstatic.com/s/robotoslab/v25/BngbUXZYTXPIvIBgJJSb6s3BzlRRfKOFbvjojISWaw.woff',
		desc: 'Roboto Slab',
		scale: [0.26 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.RobotoSlab500,
		url: 'https://fonts.gstatic.com/s/robotoslab/v25/BngbUXZYTXPIvIBgJJSb6s3BzlRRfKOFbvjovoSWaw.woff',
		desc: 'Roboto Slab',
		scale: [0.26 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.RobotoSlab600,
		url: 'https://fonts.gstatic.com/s/robotoslab/v25/BngbUXZYTXPIvIBgJJSb6s3BzlRRfKOFbvjoUoOWaw.woff',
		desc: 'Roboto Slab',
		scale: [0.26 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.RobotoSlab700,
		url: 'https://fonts.gstatic.com/s/robotoslab/v25/BngbUXZYTXPIvIBgJJSb6s3BzlRRfKOFbvjoa4OWaw.woff',
		desc: 'Roboto Slab',
		scale: [0.26 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.RobotoSlab800,
		url: 'https://fonts.gstatic.com/s/robotoslab/v25/BngbUXZYTXPIvIBgJJSb6s3BzlRRfKOFbvjoDIOWaw.woff',
		desc: 'Roboto Slab',
		scale: [0.26 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.RobotoSlab900,
		url: 'https://fonts.gstatic.com/s/robotoslab/v25/BngbUXZYTXPIvIBgJJSb6s3BzlRRfKOFbvjoJYOWaw.woff',
		desc: 'Roboto Slab',
		scale: [0.26 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.Comfortaa300,
		url: 'https://fonts.gstatic.com/s/comfortaa/v40/1Pt_g8LJRfWJmhDAuUsSQamb1W0lwk4S4TbMDrMfJg.woff',
		desc: 'Rounded title',
		scale: [0.3 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.Comfortaa400,
		url: 'https://fonts.gstatic.com/s/comfortaa/v40/1Pt_g8LJRfWJmhDAuUsSQamb1W0lwk4S4WjMDrMfJg.woff',
		desc: 'Rounded title',
		scale: [0.3 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.Comfortaa500,
		url: 'https://fonts.gstatic.com/s/comfortaa/v40/1Pt_g8LJRfWJmhDAuUsSQamb1W0lwk4S4VrMDrMfJg.woff',
		desc: 'Rounded title',
		scale: [0.3 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.Comfortaa600,
		url: 'https://fonts.gstatic.com/s/comfortaa/v40/1Pt_g8LJRfWJmhDAuUsSQamb1W0lwk4S4bbLDrMfJg.woff',
		desc: 'Rounded title',
		scale: [0.3 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.Comfortaa700,
		url: 'https://fonts.gstatic.com/s/comfortaa/v40/1Pt_g8LJRfWJmhDAuUsSQamb1W0lwk4S4Y_LDrMfJg.woff',
		desc: 'Rounded title',
		scale: [0.3 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.Cookie400,
		url: 'https://fonts.gstatic.com/s/cookie/v17/syky-y18lb0tSbf9kgqU.woff',
		desc: 'Elegant title',
		scale: [0.15 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.Philosopher400,
		url: 'https://fonts.gstatic.com/s/philosopher/v19/vEFV2_5QCwIS4_Dhez5jcWBuT0s.woff',
		desc: 'Classic title',
		scale: [0.21 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.Philosopher400italic,
		url: 'https://fonts.gstatic.com/s/philosopher/v19/vEFX2_5QCwIS4_Dhez5jcWBrf0880Q.woff',
		desc: 'Classic title',
		scale: [0.21 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.Philosopher700,
		url: 'https://fonts.gstatic.com/s/philosopher/v19/vEFI2_5QCwIS4_Dhez5jcWjValgb8tE.woff',
		desc: 'Classic title',
		scale: [0.21 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.Philosopher700italic,
		url: 'https://fonts.gstatic.com/s/philosopher/v19/vEFK2_5QCwIS4_Dhez5jcWBrd_QZwtW_XA.woff',
		desc: 'Classic title',
		scale: [0.21 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.Quicksand300,
		url: 'https://fonts.gstatic.com/s/quicksand/v30/6xK-dSZaM9iE8KbpRA_LJ3z8mH9BOJvgkKEo58a-xA.woff',
		desc: 'Modern title',
		scale: [0.24 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.Quicksand400,
		url: 'https://fonts.gstatic.com/s/quicksand/v30/6xK-dSZaM9iE8KbpRA_LJ3z8mH9BOJvgkP8o58a-xA.woff',
		desc: 'Modern title',
		scale: [0.24 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.Quicksand500,
		url: 'https://fonts.gstatic.com/s/quicksand/v30/6xK-dSZaM9iE8KbpRA_LJ3z8mH9BOJvgkM0o58a-xA.woff',
		desc: 'Modern title',
		scale: [0.24 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.Quicksand600,
		url: 'https://fonts.gstatic.com/s/quicksand/v30/6xK-dSZaM9iE8KbpRA_LJ3z8mH9BOJvgkCEv58a-xA.woff',
		desc: 'Modern title',
		scale: [0.24 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.Quicksand700,
		url: 'https://fonts.gstatic.com/s/quicksand/v30/6xK-dSZaM9iE8KbpRA_LJ3z8mH9BOJvgkBgv58a-xA.woff',
		desc: 'Modern title',
		scale: [0.24 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.Trirong100,
		url: 'https://fonts.gstatic.com/s/trirong/v12/7r3EqXNgp8wxdOdOl-gY2o5i.woff',
		desc: 'Classic title 2',
		scale: [0.27 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.Trirong100italic,
		url: 'https://fonts.gstatic.com/s/trirong/v12/7r3CqXNgp8wxdOdOn44Qub5mwuo.woff',
		desc: 'Classic title 2',
		scale: [0.27 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.Trirong200,
		url: 'https://fonts.gstatic.com/s/trirong/v12/7r3DqXNgp8wxdOdOl0QJzalB4Q.woff',
		desc: 'Classic title 2',
		scale: [0.27 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.Trirong200italic,
		url: 'https://fonts.gstatic.com/s/trirong/v12/7r3BqXNgp8wxdOdOn44QFa9x5cn8.woff',
		desc: 'Classic title 2',
		scale: [0.27 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.Trirong300,
		url: 'https://fonts.gstatic.com/s/trirong/v12/7r3DqXNgp8wxdOdOlyAKzalB4Q.woff',
		desc: 'Classic title 2',
		scale: [0.27 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.Trirong300italic,
		url: 'https://fonts.gstatic.com/s/trirong/v12/7r3BqXNgp8wxdOdOn44Qcaxx5cn8.woff',
		desc: 'Classic title 2',
		scale: [0.27 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.Trirong400,
		url: 'https://fonts.gstatic.com/s/trirong/v12/7r3GqXNgp8wxdOdOn4so3g.woff',
		desc: 'Classic title 2',
		scale: [0.27 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.Trirong400italic,
		url: 'https://fonts.gstatic.com/s/trirong/v12/7r3EqXNgp8wxdOdOn44Y2o5i.woff',
		desc: 'Classic title 2',
		scale: [0.27 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.Trirong500,
		url: 'https://fonts.gstatic.com/s/trirong/v12/7r3DqXNgp8wxdOdOl3gLzalB4Q.woff',
		desc: 'Classic title 2',
		scale: [0.27 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.Trirong500italic,
		url: 'https://fonts.gstatic.com/s/trirong/v12/7r3BqXNgp8wxdOdOn44QKa1x5cn8.woff',
		desc: 'Classic title 2',
		scale: [0.27 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.Trirong600,
		url: 'https://fonts.gstatic.com/s/trirong/v12/7r3DqXNgp8wxdOdOl1QMzalB4Q.woff',
		desc: 'Classic title 2',
		scale: [0.27 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.Trirong600italic,
		url: 'https://fonts.gstatic.com/s/trirong/v12/7r3BqXNgp8wxdOdOn44QBapx5cn8.woff',
		desc: 'Classic title 2',
		scale: [0.27 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.Trirong700,
		url: 'https://fonts.gstatic.com/s/trirong/v12/7r3DqXNgp8wxdOdOlzANzalB4Q.woff',
		desc: 'Classic title 2',
		scale: [0.27 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.Trirong700italic,
		url: 'https://fonts.gstatic.com/s/trirong/v12/7r3BqXNgp8wxdOdOn44QYatx5cn8.woff',
		desc: 'Classic title 2',
		scale: [0.27 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.Trirong800,
		url: 'https://fonts.gstatic.com/s/trirong/v12/7r3DqXNgp8wxdOdOlywOzalB4Q.woff',
		desc: 'Classic title 2',
		scale: [0.27 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.Trirong800italic,
		url: 'https://fonts.gstatic.com/s/trirong/v12/7r3BqXNgp8wxdOdOn44Qfahx5cn8.woff',
		desc: 'Classic title 2',
		scale: [0.27 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.Trirong900,
		url: 'https://fonts.gstatic.com/s/trirong/v12/7r3DqXNgp8wxdOdOlwgPzalB4Q.woff',
		desc: 'Classic title 2',
		scale: [0.27 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.Trirong900italic,
		url: 'https://fonts.gstatic.com/s/trirong/v12/7r3BqXNgp8wxdOdOn44QWalx5cn8.woff',
		desc: 'Classic title 2',
		scale: [0.27 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.Trocchi400,
		url: 'https://fonts.gstatic.com/s/trocchi/v15/qWcqB6WkuIDxDZLcPrxeuw.woff',
		desc: 'Modern title 2',
		scale: [0.3 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.AdventPro100,
		url: 'https://fonts.gstatic.com/s/adventpro/v20/V8mqoQfxVT4Dvddr_yOwrzaFxV7JtdQgFqXdUAQrGp_zgX5sWCpLQyJ_S5Av.woff',
		desc: 'Ultra modern title',
		scale: [0.29 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.AdventPro100italic,
		url: 'https://fonts.gstatic.com/s/adventpro/v20/V8mkoQfxVT4Dvddr_yOwhT-3Jr6w5kKOEbAVEvZiKGAr6BX29i1ei2CnDqArtw8.woff',
		desc: 'Ultra modern title',
		scale: [0.29 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.AdventPro200,
		url: 'https://fonts.gstatic.com/s/adventpro/v20/V8mqoQfxVT4Dvddr_yOwrzaFxV7JtdQgFqXdUAQrGp_zgX5sWCpLwyN_S5Av.woff',
		desc: 'Ultra modern title',
		scale: [0.29 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.AdventPro200italic,
		url: 'https://fonts.gstatic.com/s/adventpro/v20/V8mkoQfxVT4Dvddr_yOwhT-3Jr6w5kKOEbAVEvZiKGAr6BX29i1ei2AnD6Artw8.woff',
		desc: 'Ultra modern title',
		scale: [0.29 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.AdventPro300,
		url: 'https://fonts.gstatic.com/s/adventpro/v20/V8mqoQfxVT4Dvddr_yOwrzaFxV7JtdQgFqXdUAQrGp_zgX5sWCpLHSN_S5Av.woff',
		desc: 'Ultra modern title',
		scale: [0.29 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.AdventPro300italic,
		url: 'https://fonts.gstatic.com/s/adventpro/v20/V8mkoQfxVT4Dvddr_yOwhT-3Jr6w5kKOEbAVEvZiKGAr6BX29i1ei2D5D6Artw8.woff',
		desc: 'Ultra modern title',
		scale: [0.29 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.AdventPro400,
		url: 'https://fonts.gstatic.com/s/adventpro/v20/V8mqoQfxVT4Dvddr_yOwrzaFxV7JtdQgFqXdUAQrGp_zgX5sWCpLQyN_S5Av.woff',
		desc: 'Ultra modern title',
		scale: [0.29 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.AdventPro400italic,
		url: 'https://fonts.gstatic.com/s/adventpro/v20/V8mkoQfxVT4Dvddr_yOwhT-3Jr6w5kKOEbAVEvZiKGAr6BX29i1ei2CnD6Artw8.woff',
		desc: 'Ultra modern title',
		scale: [0.29 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.AdventPro500,
		url: 'https://fonts.gstatic.com/s/adventpro/v20/V8mqoQfxVT4Dvddr_yOwrzaFxV7JtdQgFqXdUAQrGp_zgX5sWCpLcSN_S5Av.woff',
		desc: 'Ultra modern title',
		scale: [0.29 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.AdventPro500italic,
		url: 'https://fonts.gstatic.com/s/adventpro/v20/V8mkoQfxVT4Dvddr_yOwhT-3Jr6w5kKOEbAVEvZiKGAr6BX29i1ei2CVD6Artw8.woff',
		desc: 'Ultra modern title',
		scale: [0.29 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.AdventPro600,
		url: 'https://fonts.gstatic.com/s/adventpro/v20/V8mqoQfxVT4Dvddr_yOwrzaFxV7JtdQgFqXdUAQrGp_zgX5sWCpLnSR_S5Av.woff',
		desc: 'Ultra modern title',
		scale: [0.29 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.AdventPro600italic,
		url: 'https://fonts.gstatic.com/s/adventpro/v20/V8mkoQfxVT4Dvddr_yOwhT-3Jr6w5kKOEbAVEvZiKGAr6BX29i1ei2B5CKArtw8.woff',
		desc: 'Ultra modern title',
		scale: [0.29 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.AdventPro700,
		url: 'https://fonts.gstatic.com/s/adventpro/v20/V8mqoQfxVT4Dvddr_yOwrzaFxV7JtdQgFqXdUAQrGp_zgX5sWCpLpCR_S5Av.woff',
		desc: 'Ultra modern title',
		scale: [0.29 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.AdventPro700italic,
		url: 'https://fonts.gstatic.com/s/adventpro/v20/V8mkoQfxVT4Dvddr_yOwhT-3Jr6w5kKOEbAVEvZiKGAr6BX29i1ei2BACKArtw8.woff',
		desc: 'Ultra modern title',
		scale: [0.29 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.AdventPro800,
		url: 'https://fonts.gstatic.com/s/adventpro/v20/V8mqoQfxVT4Dvddr_yOwrzaFxV7JtdQgFqXdUAQrGp_zgX5sWCpLwyR_S5Av.woff',
		desc: 'Ultra modern title',
		scale: [0.29 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.AdventPro800italic,
		url: 'https://fonts.gstatic.com/s/adventpro/v20/V8mkoQfxVT4Dvddr_yOwhT-3Jr6w5kKOEbAVEvZiKGAr6BX29i1ei2AnCKArtw8.woff',
		desc: 'Ultra modern title',
		scale: [0.29 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.AdventPro900,
		url: 'https://fonts.gstatic.com/s/adventpro/v20/V8mqoQfxVT4Dvddr_yOwrzaFxV7JtdQgFqXdUAQrGp_zgX5sWCpL6iR_S5Av.woff',
		desc: 'Ultra modern title',
		scale: [0.29 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.AdventPro900italic,
		url: 'https://fonts.gstatic.com/s/adventpro/v20/V8mkoQfxVT4Dvddr_yOwhT-3Jr6w5kKOEbAVEvZiKGAr6BX29i1ei2AOCKArtw8.woff',
		desc: 'Ultra modern title',
		scale: [0.29 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.HennyPenny400,
		url: 'https://fonts.gstatic.com/s/hennypenny/v17/wXKvE3UZookzsxz_kjGSfPQtvXQ.woff',
		desc: 'Magic title',
		scale: [0.23 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.Orbitron400,
		url: 'https://fonts.gstatic.com/s/orbitron/v29/yMJMMIlzdpvBhQQL_SC3X9yhF25-T1nyGy6BoWg1.woff',
		desc: 'Futuristic title',
		scale: [0.32 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.Orbitron500,
		url: 'https://fonts.gstatic.com/s/orbitron/v29/yMJMMIlzdpvBhQQL_SC3X9yhF25-T1nyKS6BoWg1.woff',
		desc: 'Futuristic title',
		scale: [0.32 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.Orbitron600,
		url: 'https://fonts.gstatic.com/s/orbitron/v29/yMJMMIlzdpvBhQQL_SC3X9yhF25-T1nyxSmBoWg1.woff',
		desc: 'Futuristic title',
		scale: [0.32 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.Orbitron700,
		url: 'https://fonts.gstatic.com/s/orbitron/v29/yMJMMIlzdpvBhQQL_SC3X9yhF25-T1ny_CmBoWg1.woff',
		desc: 'Futuristic title',
		scale: [0.32 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.Orbitron800,
		url: 'https://fonts.gstatic.com/s/orbitron/v29/yMJMMIlzdpvBhQQL_SC3X9yhF25-T1nymymBoWg1.woff',
		desc: 'Futuristic title',
		scale: [0.32 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.Orbitron900,
		url: 'https://fonts.gstatic.com/s/orbitron/v29/yMJMMIlzdpvBhQQL_SC3X9yhF25-T1nysimBoWg1.woff',
		desc: 'Futuristic title',
		scale: [0.32 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.Sacramento400,
		url: 'https://fonts.gstatic.com/s/sacramento/v13/buEzpo6gcdjy0EiZMBUG4C0f-w.woff',
		desc: 'Handwritten title',
		scale: [0.25 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.SnowburstOne400,
		url: 'https://fonts.gstatic.com/s/snowburstone/v20/MQpS-WezKdujBsXY3B7I-UT7SZieOA.woff',
		desc: 'Magic title 2',
		scale: [0.32 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.Syncopate400,
		url: 'https://fonts.gstatic.com/s/syncopate/v19/pe0sMIuPIYBCpEV5eFdCBfe5.woff',
		desc: 'Futuristic title 2',
		scale: [0.3 * 2, 0.09 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.Syncopate700,
		url: 'https://fonts.gstatic.com/s/syncopate/v19/pe0pMIuPIYBCpEV5eFdKvtKqBP5p.woff',
		desc: 'Futuristic title 2',
		scale: [0.3 * 2, 0.09 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.Wallpoet400,
		url: 'https://fonts.gstatic.com/s/wallpoet/v16/f0X10em2_8RnXVVdUObp58I.woff',
		desc: 'Funky title',
		scale: [0.29 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.SirinStencil400,
		url: 'https://fonts.gstatic.com/s/sirinstencil/v21/mem4YaWwznmLx-lzGfN7MdRyRc9MAQ.woff',
		desc: 'Sirin Stencil',
		scale: [0.26 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.NotoSans100,
		url: 'https://fonts.gstatic.com/s/notosans/v30/o-0OIpQlx3QUlC5A4PNjhjRG.woff',
		desc: 'Noto Sans',
		scale: [0.26 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.NotoSans100italic,
		url: 'https://fonts.gstatic.com/s/notosans/v30/o-0MIpQlx3QUlC5A4PNr4AwhQ_8.woff',
		desc: 'Noto Sans',
		scale: [0.26 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.NotoSans200,
		url: 'https://fonts.gstatic.com/s/notosans/v30/o-0NIpQlx3QUlC5A4PNjKhVlYA.woff',
		desc: 'Noto Sans',
		scale: [0.26 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.NotoSans200italic,
		url: 'https://fonts.gstatic.com/s/notosans/v30/o-0TIpQlx3QUlC5A4PNr4AyNYtyH.woff',
		desc: 'Noto Sans',
		scale: [0.26 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.NotoSans300,
		url: 'https://fonts.gstatic.com/s/notosans/v30/o-0NIpQlx3QUlC5A4PNjThZlYA.woff',
		desc: 'Noto Sans',
		scale: [0.26 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.NotoSans300italic,
		url: 'https://fonts.gstatic.com/s/notosans/v30/o-0TIpQlx3QUlC5A4PNr4AzpYdyH.woff',
		desc: 'Noto Sans',
		scale: [0.26 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.NotoSans400,
		url: 'https://fonts.gstatic.com/s/notosans/v30/o-0IIpQlx3QUlC5A4PNb4Q.woff',
		jsonUrl: 'https://d1mfzu0xo6h6ih.cloudfront.net/designer/fonts/3d/noto-sans-400.json',
		desc: 'Noto Sans',
		scale: [0.26 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.NotoSans400italic,
		url: 'https://fonts.gstatic.com/s/notosans/v30/o-0OIpQlx3QUlC5A4PNr4DRG.woff',
		desc: 'Noto Sans',
		scale: [0.26 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.NotoSans500,
		url: 'https://fonts.gstatic.com/s/notosans/v30/o-0NIpQlx3QUlC5A4PNjFhdlYA.woff',
		desc: 'Noto Sans',
		scale: [0.26 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.NotoSans500italic,
		url: 'https://fonts.gstatic.com/s/notosans/v30/o-0TIpQlx3QUlC5A4PNr4AyxYNyH.woff',
		desc: 'Noto Sans',
		scale: [0.26 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.NotoSans600,
		url: 'https://fonts.gstatic.com/s/notosans/v30/o-0NIpQlx3QUlC5A4PNjOhBlYA.woff',
		desc: 'Noto Sans',
		scale: [0.26 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.NotoSans600italic,
		url: 'https://fonts.gstatic.com/s/notosans/v30/o-0TIpQlx3QUlC5A4PNr4AydZ9yH.woff',
		desc: 'Noto Sans',
		scale: [0.26 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.NotoSans700,
		url: 'https://fonts.gstatic.com/s/notosans/v30/o-0NIpQlx3QUlC5A4PNjXhFlYA.woff',
		desc: 'Noto Sans',
		scale: [0.26 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.NotoSans700italic,
		url: 'https://fonts.gstatic.com/s/notosans/v30/o-0TIpQlx3QUlC5A4PNr4Az5ZtyH.woff',
		desc: 'Noto Sans',
		scale: [0.26 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.NotoSans800,
		url: 'https://fonts.gstatic.com/s/notosans/v30/o-0NIpQlx3QUlC5A4PNjQhJlYA.woff',
		desc: 'Noto Sans',
		scale: [0.26 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.NotoSans800italic,
		url: 'https://fonts.gstatic.com/s/notosans/v30/o-0TIpQlx3QUlC5A4PNr4AzlZdyH.woff',
		desc: 'Noto Sans',
		scale: [0.26 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.NotoSans900,
		url: 'https://fonts.gstatic.com/s/notosans/v30/o-0NIpQlx3QUlC5A4PNjZhNlYA.woff',
		jsonUrl: 'https://d1mfzu0xo6h6ih.cloudfront.net/designer/fonts/3d/noto-sans-900.json',
		desc: 'Noto Sans',
		scale: [0.26 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.NotoSans900italic,
		url: 'https://fonts.gstatic.com/s/notosans/v30/o-0TIpQlx3QUlC5A4PNr4AzBZNyH.woff',
		desc: 'Noto Sans',
		scale: [0.26 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.NotoSerif100,
		url: 'https://fonts.gstatic.com/s/notoserif/v22/ga6iaw1J5X9T9RW6j9bNVls-hfgvz8JcMofYTa32J4wsL2JAlAhZqFGjww.woff',
		desc: 'Noto Serif',
		scale: [0.26 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.NotoSerif100italic,
		url: 'https://fonts.gstatic.com/s/notoserif/v22/ga6saw1J5X9T9RW6j9bNfFIMZhhWnFTyNZIQD1-_FXP0RgnaOg9MYBNLgscM.woff',
		desc: 'Noto Serif',
		scale: [0.26 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.NotoSerif200,
		url: 'https://fonts.gstatic.com/s/notoserif/v22/ga6iaw1J5X9T9RW6j9bNVls-hfgvz8JcMofYTa32J4wsL2JAlAhZKFCjww.woff',
		desc: 'Noto Serif',
		scale: [0.26 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.NotoSerif200italic,
		url: 'https://fonts.gstatic.com/s/notoserif/v22/ga6saw1J5X9T9RW6j9bNfFIMZhhWnFTyNZIQD1-_FXP0RgnaOg9MYBPLg8cM.woff',
		desc: 'Noto Serif',
		scale: [0.26 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.NotoSerif300,
		url: 'https://fonts.gstatic.com/s/notoserif/v22/ga6iaw1J5X9T9RW6j9bNVls-hfgvz8JcMofYTa32J4wsL2JAlAhZ9lCjww.woff',
		desc: 'Noto Serif',
		scale: [0.26 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.NotoSerif300italic,
		url: 'https://fonts.gstatic.com/s/notoserif/v22/ga6saw1J5X9T9RW6j9bNfFIMZhhWnFTyNZIQD1-_FXP0RgnaOg9MYBMVg8cM.woff',
		desc: 'Noto Serif',
		scale: [0.26 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.NotoSerif400,
		url: 'https://fonts.gstatic.com/s/notoserif/v22/ga6iaw1J5X9T9RW6j9bNVls-hfgvz8JcMofYTa32J4wsL2JAlAhZqFCjww.woff',
		desc: 'Noto Serif',
		scale: [0.26 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.NotoSerif400italic,
		url: 'https://fonts.gstatic.com/s/notoserif/v22/ga6saw1J5X9T9RW6j9bNfFIMZhhWnFTyNZIQD1-_FXP0RgnaOg9MYBNLg8cM.woff',
		desc: 'Noto Serif',
		scale: [0.26 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.NotoSerif500,
		url: 'https://fonts.gstatic.com/s/notoserif/v22/ga6iaw1J5X9T9RW6j9bNVls-hfgvz8JcMofYTa32J4wsL2JAlAhZmlCjww.woff',
		desc: 'Noto Serif',
		scale: [0.26 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.NotoSerif500italic,
		url: 'https://fonts.gstatic.com/s/notoserif/v22/ga6saw1J5X9T9RW6j9bNfFIMZhhWnFTyNZIQD1-_FXP0RgnaOg9MYBN5g8cM.woff',
		desc: 'Noto Serif',
		scale: [0.26 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.NotoSerif600,
		url: 'https://fonts.gstatic.com/s/notoserif/v22/ga6iaw1J5X9T9RW6j9bNVls-hfgvz8JcMofYTa32J4wsL2JAlAhZdlejww.woff',
		desc: 'Noto Serif',
		scale: [0.26 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.NotoSerif600italic,
		url: 'https://fonts.gstatic.com/s/notoserif/v22/ga6saw1J5X9T9RW6j9bNfFIMZhhWnFTyNZIQD1-_FXP0RgnaOg9MYBOVhMcM.woff',
		desc: 'Noto Serif',
		scale: [0.26 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.NotoSerif700,
		url: 'https://fonts.gstatic.com/s/notoserif/v22/ga6iaw1J5X9T9RW6j9bNVls-hfgvz8JcMofYTa32J4wsL2JAlAhZT1ejww.woff',
		desc: 'Noto Serif',
		scale: [0.26 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.NotoSerif700italic,
		url: 'https://fonts.gstatic.com/s/notoserif/v22/ga6saw1J5X9T9RW6j9bNfFIMZhhWnFTyNZIQD1-_FXP0RgnaOg9MYBOshMcM.woff',
		desc: 'Noto Serif',
		scale: [0.26 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.NotoSerif800,
		url: 'https://fonts.gstatic.com/s/notoserif/v22/ga6iaw1J5X9T9RW6j9bNVls-hfgvz8JcMofYTa32J4wsL2JAlAhZKFejww.woff',
		desc: 'Noto Serif',
		scale: [0.26 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.NotoSerif800italic,
		url: 'https://fonts.gstatic.com/s/notoserif/v22/ga6saw1J5X9T9RW6j9bNfFIMZhhWnFTyNZIQD1-_FXP0RgnaOg9MYBPLhMcM.woff',
		desc: 'Noto Serif',
		scale: [0.26 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.NotoSerif900,
		url: 'https://fonts.gstatic.com/s/notoserif/v22/ga6iaw1J5X9T9RW6j9bNVls-hfgvz8JcMofYTa32J4wsL2JAlAhZAVejww.woff',
		desc: 'Noto Serif',
		scale: [0.26 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.NotoSerif900italic,
		url: 'https://fonts.gstatic.com/s/notoserif/v22/ga6saw1J5X9T9RW6j9bNfFIMZhhWnFTyNZIQD1-_FXP0RgnaOg9MYBPihMcM.woff',
		desc: 'Noto Serif',
		scale: [0.26 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.NotoSansJP100,
		url: 'https://fonts.gstatic.com/s/notosansjp/v52/-F6jfjtqLzI2JPCgQBnw7HFyzSD-AsregP8VFBEi35TS1Q.woff',
		desc: 'Noto Sans JP',
		scale: [0.26 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.NotoSansJP200,
		url: 'https://fonts.gstatic.com/s/notosansjp/v52/-F6jfjtqLzI2JPCgQBnw7HFyzSD-AsregP8VFJEj35TS1Q.woff',
		desc: 'Noto Sans JP',
		scale: [0.26 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.NotoSansJP300,
		url: 'https://fonts.gstatic.com/s/notosansjp/v52/-F6jfjtqLzI2JPCgQBnw7HFyzSD-AsregP8VFE8j35TS1Q.woff',
		desc: 'Noto Sans JP',
		scale: [0.26 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.NotoSansJP400,
		url: 'https://fonts.gstatic.com/s/notosansjp/v52/-F6jfjtqLzI2JPCgQBnw7HFyzSD-AsregP8VFBEj35TS1Q.woff',
		desc: 'Noto Sans JP',
		scale: [0.26 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.NotoSansJP500,
		url: 'https://fonts.gstatic.com/s/notosansjp/v52/-F6jfjtqLzI2JPCgQBnw7HFyzSD-AsregP8VFCMj35TS1Q.woff',
		desc: 'Noto Sans JP',
		scale: [0.26 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.NotoSansJP600,
		url: 'https://fonts.gstatic.com/s/notosansjp/v52/-F6jfjtqLzI2JPCgQBnw7HFyzSD-AsregP8VFM8k35TS1Q.woff',
		desc: 'Noto Sans JP',
		scale: [0.26 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.NotoSansJP700,
		url: 'https://fonts.gstatic.com/s/notosansjp/v52/-F6jfjtqLzI2JPCgQBnw7HFyzSD-AsregP8VFPYk35TS1Q.woff',
		desc: 'Noto Sans JP',
		scale: [0.26 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.NotoSansJP800,
		url: 'https://fonts.gstatic.com/s/notosansjp/v52/-F6jfjtqLzI2JPCgQBnw7HFyzSD-AsregP8VFJEk35TS1Q.woff',
		desc: 'Noto Sans JP',
		scale: [0.26 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.NotoSansJP900,
		url: 'https://fonts.gstatic.com/s/notosansjp/v52/-F6jfjtqLzI2JPCgQBnw7HFyzSD-AsregP8VFLgk35TS1Q.woff',
		desc: 'Noto Sans JP',
		scale: [0.26 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.NotoSansArabic100,
		url: 'https://fonts.gstatic.com/s/notosansarabic/v18/nwpxtLGrOAZMl5nJ_wfgRg3DrWFZWsnVBJ_sS6tlqHHFlhQ5l3sQWIHPqzCfyG2fuXqG.woff',
		desc: 'Noto Sans Arabic',
		scale: [0.26 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.NotoSansArabic200,
		url: 'https://fonts.gstatic.com/s/notosansarabic/v18/nwpxtLGrOAZMl5nJ_wfgRg3DrWFZWsnVBJ_sS6tlqHHFlhQ5l3sQWIHPqzCfSGyfuXqG.woff',
		desc: 'Noto Sans Arabic',
		scale: [0.26 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.NotoSansArabic300,
		url: 'https://fonts.gstatic.com/s/notosansarabic/v18/nwpxtLGrOAZMl5nJ_wfgRg3DrWFZWsnVBJ_sS6tlqHHFlhQ5l3sQWIHPqzCflmyfuXqG.woff',
		desc: 'Noto Sans Arabic',
		scale: [0.26 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.NotoSansArabic400,
		url: 'https://fonts.gstatic.com/s/notosansarabic/v18/nwpxtLGrOAZMl5nJ_wfgRg3DrWFZWsnVBJ_sS6tlqHHFlhQ5l3sQWIHPqzCfyGyfuXqG.woff',
		desc: 'Noto Sans Arabic',
		scale: [0.26 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.NotoSansArabic500,
		url: 'https://fonts.gstatic.com/s/notosansarabic/v18/nwpxtLGrOAZMl5nJ_wfgRg3DrWFZWsnVBJ_sS6tlqHHFlhQ5l3sQWIHPqzCf-myfuXqG.woff',
		desc: 'Noto Sans Arabic',
		scale: [0.26 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.NotoSansArabic600,
		url: 'https://fonts.gstatic.com/s/notosansarabic/v18/nwpxtLGrOAZMl5nJ_wfgRg3DrWFZWsnVBJ_sS6tlqHHFlhQ5l3sQWIHPqzCfFmufuXqG.woff',
		desc: 'Noto Sans Arabic',
		scale: [0.26 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.NotoSansArabic700,
		url: 'https://fonts.gstatic.com/s/notosansarabic/v18/nwpxtLGrOAZMl5nJ_wfgRg3DrWFZWsnVBJ_sS6tlqHHFlhQ5l3sQWIHPqzCfL2ufuXqG.woff',
		desc: 'Noto Sans Arabic',
		scale: [0.26 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.NotoSansArabic800,
		url: 'https://fonts.gstatic.com/s/notosansarabic/v18/nwpxtLGrOAZMl5nJ_wfgRg3DrWFZWsnVBJ_sS6tlqHHFlhQ5l3sQWIHPqzCfSGufuXqG.woff',
		desc: 'Noto Sans Arabic',
		scale: [0.26 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.NotoSansArabic900,
		url: 'https://fonts.gstatic.com/s/notosansarabic/v18/nwpxtLGrOAZMl5nJ_wfgRg3DrWFZWsnVBJ_sS6tlqHHFlhQ5l3sQWIHPqzCfYWufuXqG.woff',
		desc: 'Noto Sans Arabic',
		scale: [0.26 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.NotoSansThai100,
		url: 'https://fonts.gstatic.com/s/notosansthai/v20/iJWnBXeUZi_OHPqn4wq6hQ2_hbJ1xyN9wd43SofNWcd1MKVQt_So_9CdU5RslyJ0Rg.woff',
		desc: 'Noto Sans Thai',
		scale: [0.26 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.NotoSansThai200,
		url: 'https://fonts.gstatic.com/s/notosansthai/v20/iJWnBXeUZi_OHPqn4wq6hQ2_hbJ1xyN9wd43SofNWcd1MKVQt_So_9CdUxRtlyJ0Rg.woff',
		desc: 'Noto Sans Thai',
		scale: [0.26 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.NotoSansThai300,
		url: 'https://fonts.gstatic.com/s/notosansthai/v20/iJWnBXeUZi_OHPqn4wq6hQ2_hbJ1xyN9wd43SofNWcd1MKVQt_So_9CdU8ptlyJ0Rg.woff',
		desc: 'Noto Sans Thai',
		scale: [0.26 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.NotoSansThai400,
		url: 'https://fonts.gstatic.com/s/notosansthai/v20/iJWnBXeUZi_OHPqn4wq6hQ2_hbJ1xyN9wd43SofNWcd1MKVQt_So_9CdU5RtlyJ0Rg.woff',
		desc: 'Noto Sans Thai',
		scale: [0.26 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.NotoSansThai500,
		url: 'https://fonts.gstatic.com/s/notosansthai/v20/iJWnBXeUZi_OHPqn4wq6hQ2_hbJ1xyN9wd43SofNWcd1MKVQt_So_9CdU6ZtlyJ0Rg.woff',
		desc: 'Noto Sans Thai',
		scale: [0.26 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.NotoSansThai600,
		url: 'https://fonts.gstatic.com/s/notosansthai/v20/iJWnBXeUZi_OHPqn4wq6hQ2_hbJ1xyN9wd43SofNWcd1MKVQt_So_9CdU0pqlyJ0Rg.woff',
		desc: 'Noto Sans Thai',
		scale: [0.26 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.NotoSansThai700,
		url: 'https://fonts.gstatic.com/s/notosansthai/v20/iJWnBXeUZi_OHPqn4wq6hQ2_hbJ1xyN9wd43SofNWcd1MKVQt_So_9CdU3NqlyJ0Rg.woff',
		desc: 'Noto Sans Thai',
		scale: [0.26 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.NotoSansThai800,
		url: 'https://fonts.gstatic.com/s/notosansthai/v20/iJWnBXeUZi_OHPqn4wq6hQ2_hbJ1xyN9wd43SofNWcd1MKVQt_So_9CdUxRqlyJ0Rg.woff',
		desc: 'Noto Sans Thai',
		scale: [0.26 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.NotoSansThai900,
		url: 'https://fonts.gstatic.com/s/notosansthai/v20/iJWnBXeUZi_OHPqn4wq6hQ2_hbJ1xyN9wd43SofNWcd1MKVQt_So_9CdUz1qlyJ0Rg.woff',
		desc: 'Noto Sans Thai',
		scale: [0.26 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.NotoSansSC100,
		url: 'https://fonts.gstatic.com/s/notosanssc/v26/k3kJo84MPvpLmixcA63oeALZTbKv0Qw.woff',
		desc: 'Noto Sans SC',
		scale: [0.26 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.NotoSansSC300,
		url: 'https://fonts.gstatic.com/s/notosanssc/v26/k3kIo84MPvpLmixcA63oeALZhaCd0y_9.woff',
		desc: 'Noto Sans SC',
		scale: [0.26 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.NotoSansSC400,
		url: 'https://fonts.gstatic.com/s/notosanssc/v26/k3kXo84MPvpLmixcA63oeALRC4KO.woff',
		desc: 'Noto Sans SC',
		scale: [0.26 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.NotoSansSC500,
		url: 'https://fonts.gstatic.com/s/notosanssc/v26/k3kIo84MPvpLmixcA63oeALZ3aGd0y_9.woff',
		desc: 'Noto Sans SC',
		scale: [0.26 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.NotoSansSC700,
		url: 'https://fonts.gstatic.com/s/notosanssc/v26/k3kIo84MPvpLmixcA63oeALZlaed0y_9.woff',
		desc: 'Noto Sans SC',
		scale: [0.26 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.NotoSansSC900,
		url: 'https://fonts.gstatic.com/s/notosanssc/v26/k3kIo84MPvpLmixcA63oeALZraWd0y_9.woff',
		desc: 'Noto Sans SC',
		scale: [0.26 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.NotoSansTC100,
		url: 'https://fonts.gstatic.com/s/notosanstc/v26/-nFlOG829Oofr2wohFbTp9i9WxEsK_Q.woff',
		desc: 'Noto Sans TC',
		scale: [0.26 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.NotoSansTC300,
		url: 'https://fonts.gstatic.com/s/notosanstc/v26/-nFkOG829Oofr2wohFbTp9i9kwMfKNdS.woff',
		desc: 'Noto Sans TC',
		scale: [0.26 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.NotoSansTC400,
		url: 'https://fonts.gstatic.com/s/notosanstc/v26/-nF7OG829Oofr2wohFbTp9i1HCEM.woff',
		desc: 'Noto Sans TC',
		scale: [0.26 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.NotoSansTC500,
		url: 'https://fonts.gstatic.com/s/notosanstc/v26/-nFkOG829Oofr2wohFbTp9i9ywIfKNdS.woff',
		desc: 'Noto Sans TC',
		scale: [0.26 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.NotoSansTC700,
		url: 'https://fonts.gstatic.com/s/notosanstc/v26/-nFkOG829Oofr2wohFbTp9i9gwQfKNdS.woff',
		desc: 'Noto Sans TC',
		scale: [0.26 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.NotoSansTC900,
		url: 'https://fonts.gstatic.com/s/notosanstc/v26/-nFkOG829Oofr2wohFbTp9i9uwYfKNdS.woff',
		desc: 'Noto Sans TC',
		scale: [0.26 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.NotoSansKR100,
		url: 'https://fonts.gstatic.com/s/notosanskr/v27/Pby6FmXiEBPT4ITbgNA5CgmOsk7lyJE.woff',
		desc: 'Noto Sans KR',
		scale: [0.26 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.NotoSansKR300,
		url: 'https://fonts.gstatic.com/s/notosanskr/v27/Pby7FmXiEBPT4ITbgNA5CgmOelz45bIX.woff',
		desc: 'Noto Sans KR',
		scale: [0.26 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.NotoSansKR400,
		url: 'https://fonts.gstatic.com/s/notosanskr/v27/PbykFmXiEBPT4ITbgNA5CgmG237r.woff',
		desc: 'Noto Sans KR',
		scale: [0.26 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.NotoSansKR500,
		url: 'https://fonts.gstatic.com/s/notosanskr/v27/Pby7FmXiEBPT4ITbgNA5CgmOIl345bIX.woff',
		desc: 'Noto Sans KR',
		scale: [0.26 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.NotoSansKR700,
		url: 'https://fonts.gstatic.com/s/notosanskr/v27/Pby7FmXiEBPT4ITbgNA5CgmOalv45bIX.woff',
		desc: 'Noto Sans KR',
		scale: [0.26 * 2, 0.04 * 2, 0]  
	},
	
	{
		fontFamily: IFontTypes.NotoSansKR900,
		url: 'https://fonts.gstatic.com/s/notosanskr/v27/Pby7FmXiEBPT4ITbgNA5CgmOUln45bIX.woff',
		desc: 'Noto Sans KR',
		scale: [0.26 * 2, 0.04 * 2, 0]  
	},
	]