export enum WEBSOCKET_ACTIONS {
    AuthRequest = 'authentication-request',
    Authenticate = 'authenticate',
    Heartbeat = 'heartbeat',
    AutomergeInit = 'automerge-init',
    AutomergeSync = 'automerge-sync',
    UpdateUsers = 'users-update',
    InactiveSocket = 'ws-closing-inactivity',
    Ping = 'ping',
    Pong = 'pong'
}

export class WebSocketWrapper extends EventTarget {
    private _ws: WebSocket | undefined;
    private _msgListeners: { [k: string]: ((d: any) => void)[] } = {};
    private _isPongEnabled = true;

    constructor(url: string, projectId: string, env: string, token?: string) {
        super()
        this.addMessageListener(WEBSOCKET_ACTIONS.AuthRequest, () => {
            this.send(WEBSOCKET_ACTIONS.Authenticate, { projectId, env, token })
        })
        this.addMessageListener(WEBSOCKET_ACTIONS.Ping, () => {
            if (this._isPongEnabled) this.send(WEBSOCKET_ACTIONS.Pong)
        })

        this.connect(url);
    }

    connect(url: string) {
        if (typeof url === 'undefined') {
            throw('Unable to connect as socket URL is undefined');
        }
        this._ws = new WebSocket(url)
        this._ws.addEventListener('open', () => {
            console.log('Connected to WebSocket')
            this.dispatchEvent(new CustomEvent('open', { detail: this }))
        })
        this._ws.addEventListener('close', (ev) => {
            console.warn('Disconnected from WebSocket:', ev.wasClean, ev.code, ev.reason)
            this.dispatchEvent(new CustomEvent('close', { detail: ev }))
        })
        this._ws.addEventListener('error', (ev) => {
            console.warn('WebSocket error:', ev);
            this.dispatchEvent(new CustomEvent('error', { detail: ev }))
        })
        this._ws.addEventListener('message', (ev) => {
            if (!ev.data || typeof ev.data !== 'string') {
                throw(`Invalid websocket message: ${ev.data}`)
            }
            const msg = JSON.parse(ev.data.toString()) as {action: WEBSOCKET_ACTIONS, payload: unknown};
            if (typeof this._msgListeners[msg.action] !== 'undefined') {
                for (const callback of this._msgListeners[msg.action]) {
                    callback(msg.payload);
                }
            }
        })
    }

    async waitUntilReady() {
        if (this._ws?.readyState === WebSocket.OPEN) return;
        return new Promise<void>((resolve) => {
            this._ws?.addEventListener('open', () => resolve() )
        })
    }

    get isOpen() {
        return this._ws?.readyState === WebSocket.OPEN
    }

    get isClosedOrClosing() {
        return this._ws ? ([WebSocket.CLOSED, WebSocket.CLOSING] as number[]).includes(this._ws.readyState) : false
    }

    async send(action: WEBSOCKET_ACTIONS, payload?: unknown) {
        if (payload instanceof Uint8Array) {
            payload = Array.from(payload)
        }
        return this._ws?.send(JSON.stringify({ action, payload }))
    }

    async broadcast(action: WEBSOCKET_ACTIONS, payload?: unknown) {
        return this._ws?.send(JSON.stringify({ action, payload, broadcast: true }))
    }

    addMessageListener<T>(action: WEBSOCKET_ACTIONS, callback: (d: T) => void) {
        if (typeof this._msgListeners[action] === 'undefined') {
            this._msgListeners[action] = [callback];
        } else {
            this._msgListeners[action].push(callback);
        }
    }

    removeMessageListenersForAction(action: WEBSOCKET_ACTIONS) {
        delete this._msgListeners[action];
    }

    enablePong() {
        this._isPongEnabled = true
    }

    disablePong() {
        this._isPongEnabled = false
    }

    close() {
        this.disablePong()
        return new Promise<void>((resolve) => {
            if (typeof this._ws === 'undefined') return resolve()
            if (this._ws.readyState !== WebSocket.OPEN) return resolve()
            this._ws.addEventListener('close', () => resolve())
            this._ws.close()
        })
    }
}
