import React from 'react';
import { COLORS } from '../../utils/constants';
import { IIconParentProps } from './index';

const Tick: React.SFC<IIconParentProps> = ({
	className,
	hexFill: fill,
	viewBox,
	onClick = () => {},
}) => {
	const hexFill = fill || COLORS.midnight;
	return (
		<>
		<svg
			onClick={onClick}
			className={className || ''}
			width="10px"
			height="10px"
			viewBox={viewBox ? viewBox.join(' ') : '0 0 10 10'}
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
		>
			<g
				id="Components"
				stroke="none"
				strokeWidth="1"
				fill="none"
				fillRule="evenodd"
				strokeLinecap="round"
			>
				<g
					id="Pannels/Drop-down"
					transform="translate(-138.000000, -62.000000)"
					stroke={hexFill}
					strokeWidth="2"
				>
					<g id="Values" transform="translate(22.000000, 23.000000)">
						<g id="Atom/Icon/On" transform="translate(113.000000, 35.545181)">
							<path
								d="M4,9.30227345 L6.1036774,12.0005274 C6.44325366,12.4360804 7.07162007,12.5138849 7.50717306,12.1743087 C7.61092929,12.0934158 7.69770338,11.992844 7.762523,11.8783563 L12,4.39390895 L12,4.39390895"
								id="Line-6"
							></path>
						</g>
					</g>
				</g>
			</g>
			</svg>
			</>
	);
};

export default Tick;
