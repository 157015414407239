import React from "react";
import { IIconParentProps } from "./index";
import { COLORS } from "../../utils";

const Star16: React.FunctionComponent<IIconParentProps> = ({ width, height, className, hexFill = COLORS.midnight, viewBox, onClick = () => {} }) => {
  return (
    <svg
      onClick={onClick}
      className={className || ""}
      width={width ? width : "16px"}
      height={height ? height : "16px"}
      viewBox={viewBox ? viewBox.join(" ") : `0 0 16 16`}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <path
        d="M5.39647 4.78773L0.685783 5.26252L0.59842 5.27612C0.00441465 5.40242 -0.216251 6.16665 0.255267 6.58751L3.78686 9.73985L2.78298 14.367L2.76932 14.4505C2.70275 15.0564 3.36299 15.5049 3.91008 15.1859L8 12.8008L12.0899 15.1859L12.1651 15.2247C12.7208 15.4752 13.3514 14.9859 13.217 14.367L12.2124 9.73985L15.7447 6.58751L15.8074 6.52516C16.2137 6.07383 15.9431 5.32584 15.3142 5.26252L10.6028 4.78773L8.69659 0.454529C8.42992 -0.15151 7.57008 -0.15151 7.30341 0.454529L5.39647 4.78773ZM8 0.761044L10.0856 5.50087L15.238 6.01973L11.3746 9.46797L12.4733 14.5285L8 11.9198L3.52669 14.5285L4.62538 9.46797L0.762038 6.01973L5.91437 5.50087L8 0.761044Z"
        fill={hexFill}
      />
    </svg>
  );
};

export default Star16;
