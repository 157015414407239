import { colors } from '../assets/colors';
import { ITuple3 } from '../components/r3f/r3f-components/component-data-structure/index';

let colorNames = colors.map(color => color.id);
let selColors: string[] = [];
let prohibColors: string[] = [];

export const getRandomColor = () => {
	const randomIndex = Math.floor(Math.random() * colorNames.length);
	let randomColor = colorNames[randomIndex];
	while (
		(prohibColors.includes(randomColor) || selColors.includes(randomColor)) &&
		selColors.length + prohibColors.length !== colorNames.length
	) {
		const randomIndex = Math.floor(Math.random() * colorNames.length);
		randomColor = colorNames[randomIndex];
	}
	if (selColors.length + prohibColors.length === colorNames.length)
		return 'white';
	selColors.push(randomColor);
	const colorObj = colors.filter(color => color.id === randomColor)[0];
	if (colorObj.exclude) {
		const newProhibCol = colorObj.exclude.filter(
			color => !prohibColors.includes(color)
		);
		prohibColors = [...prohibColors, ...newProhibCol];
	}
	return colorObj.id;
};

export function hexToRGB(h: string) {
	let r: any = 0,
		g: any = 0,
		b: any = 0;

	// 3 digits
	if (h.length == 4) {
		r = '0x' + h[1] + h[1];
		g = '0x' + h[2] + h[2];
		b = '0x' + h[3] + h[3];

		// 6 digits
	} else if (h.length == 7) {
		r = '0x' + h[1] + h[2];
		g = '0x' + h[3] + h[4];
		b = '0x' + h[5] + h[6];
	}

	return [r / 255, g / 255, b / 255] as ITuple3;
}

function componentToHex(c: number) {
	const hex = c.toString(16);
	return hex.length == 1 ? '0' + hex : hex;
}

export function rgbToHex(r: number, g: number, b: number) {
	return '#' + componentToHex(r * 255) + componentToHex(g * 255) + componentToHex(b * 255);
}