import * as Automerge from '@automerge/automerge';
import { IContentDoc } from '../components/r3f/r3f-components/component-data-structure/index';
import * as settings from '../settings';
import { SyncDocClient } from './doc';
import { WebSocketWrapper } from "./websocket";
import { History } from './history';
import { zwClient } from './zapworks';

interface User {
	id: string;
	name: string;
	email?: string;
	avatarUrl: string | null;
}

interface Project {
	id: string;
    title: string;
    lastPublish: string | null;
    status: string;
}


class CDSClient extends EventTarget {
    private _url = settings.CDS_URL;
    private _env = settings.ZW_ENV;
    private _accessToken: string  | undefined;
    private _projectId: string  | undefined;
    private _socketReconnectTimeout: number | undefined
    private _disableWsPongTimeout: number | undefined
    private _websocket: WebSocketWrapper | undefined
    private _syncClient: SyncDocClient<IContentDoc> | undefined
    private _resolveSyncDoc: ((d: SyncDocClient<IContentDoc>) => void) | undefined;
    private _history: History | undefined

    public user: User | undefined;
    public shouldReconnect = true;
    public automergeDocsFound = false;
    public project: Project | undefined;
    public syncClientPromise: Promise<SyncDocClient<IContentDoc>>

    constructor() {
        super()
        // attach visibilitychange listener here because CDSClient is a singleton
        document.addEventListener('visibilitychange', () => {
            this.toggleWebSocketPong(!document.hidden)
        })
        this.syncClientPromise = new Promise<SyncDocClient<IContentDoc>>(resolve => {
            this._resolveSyncDoc = resolve;
        })
    }

    getWebSocket() {
        return this._websocket
    }

    setAccessToken(token: string | undefined) {
        if (typeof token === 'undefined') {
            throw('Undefined access token provided. Unable to connect to CDS.')
        }
        this._accessToken = token
    }

    setProjectId(id: string) {
        this._projectId = id
    }

    async forkProject(source: string, destination: string) {
        await this.closeWebSocket(false) // close any existing websockets before proceeding
        return fetch(`${this._url}fork`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-Authorization': `Basic ${this._accessToken}`,
                'ZW-Environment': this._env
            },
            body: JSON.stringify({
                source: source,
                destination: destination
            })
        })
    }

    async getProjectInfo() {
        if (typeof this._projectId === 'undefined') throw('No project ID provided')
        try {
            return await zwClient.getProject(this._projectId)
        } catch(err) {
            return
        }
    }

    private async _getWebSocketConfig() {
        if (typeof this._projectId === 'undefined') throw('No project ID provided')
        const resq = await fetch(`${this._url}${this._projectId}/ws-config`, {
			headers: {
				'X-Authorization': `Basic ${this._accessToken}`,
				'ZW-Environment': this._env
			}
		})
		const config = await resq.json();

        if (typeof config.error === 'string' || resq.status !== 200) {
            this.dispatchEvent(new CustomEvent('error', {
                detail: {...config, errorCode: resq.status, errorType: 'config'}
            }))
        } else {
            this.dispatchEvent(new CustomEvent('ws:config', {
                detail: config
            }))
        }
        this.user = config.user;
        this.project = config.project;

		return config as {
            url: string
            path: string
            user: User
            project: Project
            error: string
            status: number
        }
    }

    async connectToWebSocket() {
        if (typeof this._projectId === 'undefined') throw('No project ID provided')
        const config = await this._getWebSocketConfig()
        if (typeof config.error === 'string') { console.error(config); return; }
        if (typeof this._websocket !== 'undefined') await this.closeWebSocket(false)

        this._websocket = new WebSocketWrapper(config.url, config.project.id, this._env, this._accessToken)
        this._websocket.addEventListener('close', (ev: any) => {
            this.dispatchEvent(new CustomEvent('ws:close', ev));
            if (document.hidden) return;
            this.reconnectToSocket()
        })
        this._websocket.addEventListener('error', (ev: any) => {
            this.reconnectToSocket()
        })
        this._websocket.addEventListener('open', async (ev) => {
            this.dispatchEvent(new CustomEvent('ws:open', ev))
            if (this._websocket) {
                if (typeof this._syncClient !== 'undefined') this._syncClient.disconnect()
                this._syncClient = SyncDocClient.create<IContentDoc>(this._websocket)
                this.dispatchEvent(new CustomEvent('syncdoc:created', { detail: this._syncClient }))
                await this._syncClient.waitForDoc()
                this.automergeDocsFound = true;
                this._resolveSyncDoc?.(this._syncClient)
                this._history = new History(this._syncClient);
                this.dispatchEvent(new CustomEvent('syncdoc:ready', { detail: this._syncClient }))
            }
        })
        this.shouldReconnect = true;
        return this._websocket;
    }

    async reconnectToSocket(timeout = 2000) {
        if (typeof this._projectId === 'undefined') throw('No project ID provided')
        if (!this.shouldReconnect) return;
        this.dispatchEvent(new CustomEvent('ws:reconnecting'))
        clearTimeout(this._socketReconnectTimeout)
        this._socketReconnectTimeout = window.setTimeout(async () => {
            try {
                const config = await this._getWebSocketConfig()
                this._websocket?.connect(config.url)
            } catch(err) {
                console.error('Unable to reconnect:', err)
                this.reconnectToSocket()
            }
        }, timeout)
    }

    getSyncClient() {
        return this._syncClient;
    }

    getContentDoc() {
        if (typeof this._syncClient === 'undefined') throw new Error('Trying to get syncDoc but client is undefined')
        return this._syncClient.getDoc();
    }

    stopReconnecting() {
        clearTimeout(this._socketReconnectTimeout)
    }

    closeWebSocket(shouldReconnect: boolean) {
        this.shouldReconnect = shouldReconnect;
        return this._websocket?.close()
    }

    toggleWebSocketPong(on = true) {
        clearTimeout(this._disableWsPongTimeout)
        if (on) {
            if (this._websocket?.isClosedOrClosing) {
                this.shouldReconnect = true;
                this.reconnectToSocket(300)
            }
            this._websocket?.enablePong()
        } else {
            this._disableWsPongTimeout = window.setTimeout(() => {
                this._websocket?.disablePong()
            }, 600000) // 10min
        }
    }

    changeContentDoc(callback: Automerge.ChangeFn<IContentDoc>) {
        this._history?.change((doc: Automerge.Extend<IContentDoc>) => {
            callback(doc)
        })
        if (typeof this._syncClient === 'undefined') throw new Error('Tried to apply change but syncClient is undefined')
        return this._syncClient.getDoc()
    }

    canUndo() {
        return this._history?.canUndo()
    }

    canRedo() {
        return Boolean(this._history?.canRedo())
    }

    undo() {
        this._history?.undo()
    }

    redo() {
        this._history?.redo()
    }
}

// Create the instance here so that we don't create another instance somewhere else by mistake
export const cdsClient = new CDSClient();
