import React from 'react';
import { IIconParentProps } from './index';
import { COLORS } from '../../utils';

const mirrorIcon: React.SFC<IIconParentProps> = ({
	hexFill: fill,
	viewBox,
	className,
	onClick = () => {},
	title, 
}) => {
	const hexFill = fill || COLORS.midnight;
	return (
		<svg
			onClick={onClick}
			className={className || ''}
			width="16px"
			height="13px"
			viewBox={viewBox ? viewBox.join(' ') : '0 0 16 13'}
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
		>
			<title>{title}</title>
			<g
				id="XL-Work-Canvas"
				stroke="none"
				strokeWidth="1"
				fill="none"
				fillRule="evenodd"
			>
				<g
					id="🤖-Designer-/-Workspace-/-Transform"
					transform="translate(-1202.000000, -171.000000)"
					stroke={hexFill}
				>
					<g
						id="Atom-/-Icon-/-Mirror-H"
						transform="translate(1202.000000, 170.000000)"
					>
						<path
							d="M5.5,2.27648509 L1.10892962,11.7904709 C1.07861076,11.8561618 1.06290931,11.92765 1.06290931,12 C1.06290931,12.2761424 1.28676694,12.5 1.56290931,12.5 L5,12.5 C5.27614237,12.5 5.5,12.2761424 5.5,12 L5.5,2.27648509 Z"
							id="Rectangle-Copy-20"
						></path>
						<path
							d="M10.5,2.27648509 L10.5,12 C10.5,12.2761424 10.7238576,12.5 11,12.5 L14.4370907,12.5 C14.5094407,12.5 14.5809289,12.4842986 14.6466198,12.4539797 C14.8973458,12.33826 15.0067901,12.041197 14.8910704,11.7904709 L10.5,2.27648509 Z"
							id="Rectangle-Copy-27"
						></path>
						<line
							x1="8"
							y1="1.68181818"
							x2="8"
							y2="12.6818182"
							id="Line-6"
							strokeLinecap="round"
						></line>
					</g>
				</g>
			</g>
		</svg>
	);
};

export default mirrorIcon;
