import { IFaceLandmark, ISocialProvider, ITuple3 } from '../component-data-structure';

export const LIGHTING_AMBIENT_INTENSITY = 0.0;
export const LIGHTING_DIRECTIONAL_INTENSITY = 0.4;
export const LIGHTING_DIRECTIONAL_POSITION = [0, 25, 0] as [number, number, number];
export const LIGHTING_DIRECTIONAL_POSITION_UPRIGHT_IMAGE_TRACKED = [0, 0, 25] as [number, number, number];
export const FACE_TRACKING_AR_WEBEMBED_SHADOWPLANE_POSITION = [0, -1, 0] as [number, number, number];

export const SNAPSHOT_UI_ICON_URL = 'https://d1mfzu0xo6h6ih.cloudfront.net/designer/camera-button-64-v1.svg';

export const SOCIAL_PROVIDERS = [
	{
		name: ISocialProvider.facebook,
		iconUrl: 'https://d1mfzu0xo6h6ih.cloudfront.net/designer/facebook-sqr-v1.svg',
		iconW: 38,
		iconH: 38,
		largeIconUrl: 'https://d1mfzu0xo6h6ih.cloudfront.net/designer/facebook-hrz-v1.svg',
		largeIconW: 110,
		largeIconH: 39,
	},
	{
		name: ISocialProvider.twitter,
		iconUrl: 'https://d1mfzu0xo6h6ih.cloudfront.net/designer/twitter-sqr-v1.svg',
		iconW: 38,
		iconH: 38,
		largeIconUrl: 'https://d1mfzu0xo6h6ih.cloudfront.net/designer/twitter-hrz-v1.svg',
		largeIconW: 110,
		largeIconH: 39,
	},
	{
		name: ISocialProvider.instagram,
		iconUrl: 'https://d1mfzu0xo6h6ih.cloudfront.net/designer/instagram-sqr-v1.svg',
		iconW: 38,
		iconH: 38,
		largeIconUrl: 'https://d1mfzu0xo6h6ih.cloudfront.net/designer/instagram-hrz-v1.svg',
		largeIconW: 110,
		largeIconH: 39,
	},
	{
		name: ISocialProvider.linkedIn,
		iconUrl: 'https://d1mfzu0xo6h6ih.cloudfront.net/designer/linkedin-sqr-v1.svg',
		iconW: 38,
		iconH: 38,
		largeIconUrl: 'https://d1mfzu0xo6h6ih.cloudfront.net/designer/linkedin-hrz-v1.svg',
		largeIconW: 110,
		largeIconH: 39,
	},
	{
		name: ISocialProvider.spotify,
		iconUrl: 'https://d1mfzu0xo6h6ih.cloudfront.net/designer/spotify-sqr-v1.svg',
		iconW: 38,
		iconH: 38,
	},
	{
		name: ISocialProvider.twitch,
		iconUrl: 'https://d1mfzu0xo6h6ih.cloudfront.net/designer/twitch-sqr-v1.svg',
		iconW: 38,
		iconH: 38,
	},
	{
		name: ISocialProvider.tiktok,
		iconUrl: 'https://d1mfzu0xo6h6ih.cloudfront.net/designer/tiktok-sqr-v1.svg',
		iconW: 38,
		iconH: 38,
		largeIconUrl: 'https://d1mfzu0xo6h6ih.cloudfront.net/designer/tiktok-hrz-v1.svg',
		largeIconW: 110,
		largeIconH: 39,
	},
	{
		name: ISocialProvider.youtube,
		iconUrl: 'https://d1mfzu0xo6h6ih.cloudfront.net/designer/youtube-sqr-v1.svg',
		iconW: 38,
		iconH: 30,
		largeIconUrl: 'https://d1mfzu0xo6h6ih.cloudfront.net/designer/youtube-hrz-v1.svg',
		largeIconW: 110,
		largeIconH: 39,
	},
];

export const FACE_LANDMARK_DATA: { [bustType: string]: Record<Exclude<IFaceLandmark, IFaceLandmark.lipTop>, { position: ITuple3; text: string }> } = {
	uar: {
		[IFaceLandmark.origin]: { position: [0, 0, 0], text: 'Origin' },
		[IFaceLandmark.eyebrowLeft]: { position: [0.243, 0.368, 0.786], text: 'Left eyebrow' },
		[IFaceLandmark.eyebrowRight]: { position: [-0.292, 0.37, 0.763], text: 'Right eyebrow' },
		[IFaceLandmark.earLeft]: { position: [0.54, 0.203, 0.25], text: 'Left ear' },
		[IFaceLandmark.earRight]: { position: [-0.547, 0.214, 0.26], text: 'Right ear' },
		[IFaceLandmark.eyeLeft]: { position: [0.235, 0.187, 0.725], text: 'Left eye' },
		[IFaceLandmark.eyeRight]: { position: [-0.227, 0.19, 0.727], text: 'Right eye' },
		[IFaceLandmark.noseBridge]: { position: [0.002, 0.182, 0.858], text: 'Nose bridge' },
		[IFaceLandmark.noseTip]: { position: [-0.003, -0.047, 1.008], text: 'Nose tip' },
		[IFaceLandmark.noseBase]: { position: [0, -0.158, 0.882], text: 'Nose base' },
		[IFaceLandmark.lipBottom]: { position: [0.001, -0.309, 0.857], text: 'Bottom lip' },
		[IFaceLandmark.chin]: { position: [-0.01, -0.6, 0.787], text: 'Chin' },
	},
};
