import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { IDesignerState, ITransformControlsMode } from '../../../../../typings';
import { useIsSnappedCurvedEntitySelected } from '../../../../utils/custom-hooks';
import { maths } from '../../../../utils/maths';
import { ICurveComponentUnion, IImageTrackingOrientation, ITrackingTypes } from '../../r3f-components/component-data-structure';
import CurvedControls from './CurvedControls';

const CurvedControlsWrapper = () => {
	const entity = useSelector((state: IDesignerState) => {
		const id = state.userReducer.selectedEntityIds![0];
		return state.contentReducer.contentDoc.componentsById[id] as ICurveComponentUnion;
	});
	const mode = useSelector((state: IDesignerState) => state.userReducer.transformControlsMode);
	const isFlatImageTracked = useSelector((state: IDesignerState) => state.contentReducer.contentDoc.tracking?.[ITrackingTypes.image]?.orientation === IImageTrackingOrientation.flat);
	const isSnappedCurvedEntitySelected = useIsSnappedCurvedEntitySelected();
	const initialSettings = useMemo(() => {
		if (entity) {
			return {
				entityId: entity.id,
				entityPosition: entity.position,
				entityRotation: entity.rotation.map((v) => maths.toRadians(v)),
				width: entity.scale[0] *2,
				curvature: entity.curvature || 0,
				isFlatImageTracked
			};
		}
	}, [mode, isSnappedCurvedEntitySelected]);
	if (!isSnappedCurvedEntitySelected) return null;
	if (mode === ITransformControlsMode.scale && isSnappedCurvedEntitySelected) return null;
	if (!entity || !initialSettings) return null;
	return <CurvedControls initialSettings={initialSettings} />;
};

export default CurvedControlsWrapper;
