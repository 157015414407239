import { IComponentType, IEmitterCategory, ISpatialComponentUnion, ITrackingTypes, ITuple3, IVector3 } from "../../components/r3f/r3f-components/component-data-structure";

export const getEntityDropCoordinates = (args: {
	entity: ISpatialComponentUnion;
	sceneTrackingType: ITrackingTypes;
	isFlatOrientation: boolean;
	isScreenRelativeMode: boolean;
	isCurvedImageTrackedScene: boolean;
	x?: number;
	y?: number;
	z?: number;
}) => {
	const { entity, sceneTrackingType, isFlatOrientation, isScreenRelativeMode, isCurvedImageTrackedScene, x: xPos, y: yPos, z: zPos } = args;
	switch (entity.type) {
		case IComponentType.Button:
			return getButtonDropCoordinates({ scale: entity.scale, sceneTrackingType, isFlatOrientation, isScreenRelativeMode, xPos: xPos ?? 0, yPos: yPos ?? 0, zPos: zPos ?? 0 });
		case IComponentType.Text:
			return getTextDropCoordinates({ scale: entity.scale, sceneTrackingType, isFlatOrientation, isScreenRelativeMode, xPos: xPos ?? 0, yPos: yPos ?? 0, zPos: zPos ?? 0 });
		case IComponentType.Image:
			return getImageDropCoordinates({ scale: entity.scale, sceneTrackingType, isFlatOrientation, isScreenRelativeMode, xPos: xPos ?? 0, yPos: yPos ?? 0, zPos: zPos ?? 0 });
		case IComponentType.Video:
			return getVideoDropCoordinates({ scale: entity.scale, sceneTrackingType, isFlatOrientation, isScreenRelativeMode, xPos: xPos ?? 0, yPos: yPos ?? 0, zPos: zPos ?? 0 });
		case IComponentType.Text3d:
			return getModel3dDropCoordinates({ dimensions: [1.1, 0.1, 0.2], isCurvedImageTrackedScene, sceneTrackingType, isFlatOrientation, isScreenRelativeMode, xPos: xPos ?? 0, yPos: yPos ?? 0, zPos: zPos ?? 0 });
		case IComponentType.Model3d:
			return getModel3dDropCoordinates({ dimensions: entity.scale as ITuple3, isCurvedImageTrackedScene, sceneTrackingType, isFlatOrientation, isScreenRelativeMode, xPos: xPos ?? 0, yPos: yPos ?? 0, zPos: zPos ?? 0 });
		case IComponentType.Emitter: {
			return getEmitterDropCoordinates(entity.scale as ITuple3, entity.category, sceneTrackingType, isFlatOrientation, xPos, yPos, zPos);
		}
	}
};

export const getEmitterDropCoordinates = (dimensions: ITuple3, category: IEmitterCategory, sceneTrackingType: ITrackingTypes, isFlatOrientation: boolean, xPos = 0, yPos = 0, zPos = 0) => {
	// console.log('yPos', yPos)
	if (sceneTrackingType === ITrackingTypes.content360) return { position: [xPos, yPos, zPos], rotation: [0, 0, 0] }
	let position: number[] = [xPos, 0, zPos];
	let rotation: number[] = [0, 0, 0];

	if (sceneTrackingType === ITrackingTypes.face) {
		switch (category) {
			case 'rain':
				case 'confetti':
			case 'snow': {
				position = [xPos, yPos + 2, 0];
				break;
			}
			case 'fire':
			default:
				position = [xPos, 0, 1.3];
				break;
		}	
	}

	if (sceneTrackingType === ITrackingTypes.image) {
		switch (category) {
			case 'confetti':
			case 'rain':
			case 'snow': {
				if (isFlatOrientation) {
					position = [xPos, 0.025, yPos + 2.5];
				} else {
					position = [xPos, yPos + 2.5, 1.4];
				}
				break;
			}
			default:
			case 'fire': {
				position = [xPos, 0, 0.2];
				break;
			}
		}	
	}

	if (sceneTrackingType === ITrackingTypes.world) {
		switch (category) {
			case 'confetti':
			case 'rain':
			case 'snow': {
				position = [xPos, 4, 0];
				break;
			}
			default:
			case 'sparkles': {
				position = [xPos, 2.01, 0];
				break;
			}
			case 'fire': {
				position = [xPos, 0.2, 0];
				break;
			}
		}	
	}

	if (isFlatOrientation && sceneTrackingType === ITrackingTypes.image) {
		rotation = [90, 0, 0];
	}

	// console.log('return position', position, 'rotation', rotation)
	return { position, rotation };
}

const largestSide = (inputArr: number[]) => {
	if (inputArr[0] > inputArr[1] && inputArr[0] > inputArr[2]) return 0;
	if (inputArr[1] > inputArr[0] && inputArr[1] > inputArr[2]) return 1;
	if (inputArr[2] > inputArr[0] && inputArr[2] > inputArr[1]) return 2;
	return 0;
};

export const getModel3dDropCoordinates = (args: { dimensions: ITuple3, sceneTrackingType: ITrackingTypes, isCurvedImageTrackedScene: boolean; isFlatOrientation: boolean, isScreenRelativeMode: boolean, xPos: number, yPos: number, zPos: number}) => {
	const { dimensions, sceneTrackingType, isScreenRelativeMode, isCurvedImageTrackedScene, isFlatOrientation, xPos, yPos, zPos } = args;
	let position: number[] = [xPos, yPos, zPos];
	let rotation: number[] = [0, 0, 0];

	if (isScreenRelativeMode || sceneTrackingType === ITrackingTypes.face || sceneTrackingType === ITrackingTypes.content360) return { position, rotation };

	const biggestSide = largestSide(dimensions);
	// Adjust model position depending on the aspect ratio of the 3D model
	switch (biggestSide) {
		case 0:
			position = [xPos, yPos, dimensions[2] / dimensions[0] + zPos];
			break;
		case 1:
			position = [xPos, yPos, dimensions[2] / dimensions[1] + zPos];
			break;
		case 2:
			if (dimensions[0] > dimensions[1]) {
				position = [xPos, yPos, dimensions[2] / dimensions[0] - zPos];
			} else {
				position = [xPos, yPos, dimensions[2] / dimensions[1] + zPos];
			}
			break;
	}

	// If we are in flat orientation, image tracked mode then rotate before placing
	if (isFlatOrientation && sceneTrackingType === ITrackingTypes.image) {
		rotation = [90, 0, 0];
		switch (biggestSide) {
			case 0:
				position = [position[0], position[1], dimensions[1] / dimensions[0]];
				break;
			case 1:
				position = [position[0], position[1], dimensions[1]];
				break;
			case 2:
				if (dimensions[0] > dimensions[1]) {
					position = [position[0], position[1], dimensions[1] / dimensions[2]];
				} else {
					position = [position[0], position[1], dimensions[1]];
				}
				break;
			default:
		}
	}

	// If this is world tracking, adjust model position accordingly
	if (sceneTrackingType === ITrackingTypes.world) {
		position = [xPos, dimensions[1], 0];
	}

	// If this is curved scene, adjust model position accordingly
	if (isCurvedImageTrackedScene) {
		position = [xPos, dimensions[1], (zPos + dimensions[2])];
	}

	return { position, rotation };
};

export const getButtonDropCoordinates = (args: {
	scale: IVector3;
	sceneTrackingType: ITrackingTypes;
	isFlatOrientation: boolean;
	isScreenRelativeMode: boolean;
	xPos: number;
	yPos: number;
	zPos: number;
}) => {
	const { scale, sceneTrackingType, isScreenRelativeMode, isFlatOrientation, xPos, zPos } = args;
	let { yPos } = args;
	if (sceneTrackingType === ITrackingTypes.world && !isScreenRelativeMode && yPos <= 0 + scale[1]) {
		yPos = scale[1];
	}
	let position: number[] = [xPos, yPos, (isScreenRelativeMode ? 0 : zPos)];
	let rotation: number[] = [0, 0, 0];

	if (isScreenRelativeMode || sceneTrackingType === ITrackingTypes.face) return { position, rotation };

	position = [xPos, sceneTrackingType === ITrackingTypes.world ? scale[1] : yPos, sceneTrackingType === ITrackingTypes.image && isFlatOrientation ? scale[1] : zPos];

	rotation = isFlatOrientation && sceneTrackingType === ITrackingTypes.image ? [90, 0, 0] : [0, 0, 0];
	return { position, rotation };
};

export const getTextDropCoordinates = (args: {
	scale: IVector3;
	sceneTrackingType: ITrackingTypes;
	isFlatOrientation: boolean;
	isScreenRelativeMode: boolean;
	xPos: number;
	yPos: number;
	zPos: number;
}) => {
	const { scale, sceneTrackingType, isScreenRelativeMode, isFlatOrientation, xPos, zPos } = args;
	let { yPos } = args;
	if (sceneTrackingType === ITrackingTypes.world && !isScreenRelativeMode && yPos <= 0 + scale[1]) {
		yPos = scale[1];
	}
	let position: number[] = [xPos, yPos, (isScreenRelativeMode ? 0 : zPos)];
	let rotation: number[] = [0, 0, 0];

	if (isScreenRelativeMode || sceneTrackingType === ITrackingTypes.face) return { position, rotation };

	position = [xPos, sceneTrackingType === ITrackingTypes.world ? scale[1] : yPos, sceneTrackingType === ITrackingTypes.image && isFlatOrientation ? scale[1] : zPos];

	rotation = isFlatOrientation && sceneTrackingType === ITrackingTypes.image ? [90, 0, 0] : [0, 0, 0];
	return { position, rotation };
};

export const getVideoDropCoordinates = (args: {
	scale: IVector3;
	sceneTrackingType: ITrackingTypes;
	isFlatOrientation: boolean;
	isScreenRelativeMode: boolean;
	xPos: number;
	yPos: number;
	zPos: number;
}) => {
	const { scale, sceneTrackingType, isScreenRelativeMode, isFlatOrientation, xPos, zPos } = args;
	let { yPos } = args;
	if (sceneTrackingType === ITrackingTypes.world && !isScreenRelativeMode && yPos <= 0 + scale[1]) {
		yPos = scale[1];
	}

	let position: number[] = [xPos, yPos, (isScreenRelativeMode ? 0 : zPos)];
	let rotation: number[] = [0, 0, 0];

	if (isScreenRelativeMode || sceneTrackingType === ITrackingTypes.face) return { position, rotation };

	position = [position[0], sceneTrackingType === ITrackingTypes.world ? scale[1] : yPos, sceneTrackingType === ITrackingTypes.image && isFlatOrientation ? scale[1] : zPos];

	rotation = isFlatOrientation && sceneTrackingType === ITrackingTypes.image ? [90, 0, 0] : [0, 0, 0];
	return { position, rotation };
};

export const getImageDropCoordinates = (args: {
	scale: IVector3;
	sceneTrackingType: ITrackingTypes;
	isFlatOrientation: boolean;
	isScreenRelativeMode: boolean;
	xPos: number;
	yPos: number;
	zPos: number;
}) => {
	const { scale, sceneTrackingType, isScreenRelativeMode, isFlatOrientation, xPos, zPos } = args;
	const { yPos } = args;
	let position: number[] = [xPos, yPos, (isScreenRelativeMode ? 0 : zPos)];
	let rotation: number[] = [0, 0, 0];

	if (isScreenRelativeMode || sceneTrackingType === ITrackingTypes.face) return { position, rotation };

	position = [position[0], sceneTrackingType === ITrackingTypes.world ? scale[1] : yPos, sceneTrackingType === ITrackingTypes.image && isFlatOrientation ? scale[1] : zPos];

	rotation = isFlatOrientation && sceneTrackingType === ITrackingTypes.image ? [90, 0, 0] : [0, 0, 0];
	return { position, rotation };
};