import * as THREE from 'three';

const VERTEX_SHADER = `
varying vec2 vUv;

void main() {
	vUv = uv;
	gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
}
`;

const FRAGMENT_SHADER = `
uniform sampler2D map;
uniform float texWidth;
uniform float texHeight;

uniform vec3 keyColor;
uniform float similarity;
uniform float smoothness;
uniform float spill;

varying vec2 vUv;

// From https://github.com/libretro/glsl-shaders/blob/master/nnedi3/shaders/rgb-to-yuv.glsl
vec2 RGBtoUV(vec3 rgb) {
  return vec2(
    rgb.r * -0.169 + rgb.g * -0.331 + rgb.b *  0.5    + 0.5,
    rgb.r *  0.5   + rgb.g * -0.419 + rgb.b * -0.081  + 0.5
  );
}

vec4 ProcessChromaKey(vec2 texCoord) {
  vec4 rgba = texture2D(map, texCoord);
  
  float chromaDist = distance(RGBtoUV(texture2D(map, texCoord).rgb), RGBtoUV(keyColor));

  float baseMask = chromaDist - similarity;
  float fullMask = pow(clamp(baseMask / smoothness, 0., 1.), 1.5);
  rgba.a = fullMask;

  float spillVal = pow(clamp(baseMask / spill, 0., 1.), 1.5);
  float desat = clamp(rgba.r * 0.2126 + rgba.g * 0.7152 + rgba.b * 0.0722, 0., 1.);
  rgba.rgb = mix(vec3(desat, desat, desat), rgba.rgb, spillVal);

  return rgba;
}

void main(void) {
  vec2 texCoord = vUv;
  gl_FragColor = ProcessChromaKey(texCoord);
}
`;

export class MeshChromaKeyMaterial extends THREE.ShaderMaterial {

	public similarity = 0.05;
	public smoothness = 0.08;
	public spill = 0.2;
	public keyColor = [0,1,0];
	public map = new THREE.Texture();

	constructor() {
		super({
			vertexShader: VERTEX_SHADER,
			fragmentShader: FRAGMENT_SHADER,
			transparent: true,
			alphaTest: 0.1,
			toneMapped: false,
		});

		this.uniforms = {
			similarity: { value: this.similarity },
			smoothness: { value: this.smoothness },
			spill: { value: this.spill },
			keyColor: { value: this.keyColor },
			map: { value: this.map },
		}
	}


	public frame() {
		this.uniforms.similarity.value = this.similarity;
		this.uniforms.smoothness.value = this.smoothness;
		this.uniforms.spill.value = this.spill;
		this.uniforms.keyColor.value = this.keyColor;
		this.uniforms.map.value = this.map;
	}
}