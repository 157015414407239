import { BoxGeometry, BufferGeometry, Color, CylinderGeometry, DoubleSide, Euler, Float32BufferAttribute, Line, PlaneGeometry, Matrix4, Mesh, MeshBasicMaterial, Object3D, OctahedronGeometry, OrthographicCamera, PerspectiveCamera, Quaternion, SphereGeometry, TorusGeometry, Vector3 } from "three";
import {
  CircleGeometry, DEFAULT_X_AXIS_COLOR_ACTIVE, DEFAULT_X_AXIS_COLOR_INACTIVE, DEFAULT_Y_AXIS_COLOR_ACTIVE, DEFAULT_Y_AXIS_COLOR_INACTIVE, DEFAULT_Z_AXIS_COLOR_ACTIVE, DEFAULT_Z_AXIS_COLOR_INACTIVE,
  DEFAULT_XY_AXIS_COLOR_ACTIVE, DEFAULT_XY_AXIS_COLOR_INACTIVE, DEFAULT_XZ_AXIS_COLOR_ACTIVE, DEFAULT_XZ_AXIS_COLOR_INACTIVE, DEFAULT_YZ_AXIS_COLOR_ACTIVE, DEFAULT_YZ_AXIS_COLOR_INACTIVE,
  DEFAULT_XYZ_AXIS_COLOR_ACTIVE, DEFAULT_XYZ_AXIS_COLOR_INACTIVE,
  DEFAULT_XYZX_AXIS_COLOR_ACTIVE, DEFAULT_XYZX_AXIS_COLOR_INACTIVE, DEFAULT_XYZY_AXIS_COLOR_ACTIVE, DEFAULT_XYZY_AXIS_COLOR_INACTIVE, DEFAULT_XYZZ_AXIS_COLOR_ACTIVE, DEFAULT_XYZZ_AXIS_COLOR_INACTIVE,
  DEFAULT_E_AXIS_COLOR_ACTIVE, DEFAULT_E_AXIS_COLOR_INACTIVE, DEFAULT_XYZE_AXIS_COLOR_ACTIVE, DEFAULT_XYZE_AXIS_COLOR_INACTIVE,
  DEFAULT_TRANSLATE_LINE_THICKNESS, DEFAULT_SCALE_LINE_THICKNESS, DEFAULT_ROTATE_LINE_THICKNESS, DEFAULT_OVERALL_LINE_THICKNESS, LOCKED_COLOR,
  Mode, AxisColorDictionary, Axis, TransformControlsGizmoPrivateGizmos, GizmoProps
} from "./TransformUtils";
import { Line2 } from 'three/examples/jsm/lines/Line2'
import { LineGeometry } from "three/examples/jsm/lines/LineGeometry";
import { LineMaterial } from 'three/examples/jsm/lines/LineMaterial';

export default class TransformControlsGizmo extends Object3D {
  public type = "TransformControlsGizmo";
  public locked = false;

  private tempVector = new Vector3(0, 0, 0);
  private tempEuler = new Euler();
  private alignVector = new Vector3(0, 1, 0);
  private zeroVector = new Vector3(0, 0, 0);
  private lookAtMatrix = new Matrix4();
  private tempQuaternion = new Quaternion();
  private tempQuaternion2 = new Quaternion();
  private identityQuaternion = new Quaternion();

  private unitX = new Vector3(1, 0, 0);
  private unitY = new Vector3(0, 1, 0);
  private unitZ = new Vector3(0, 0, 1);

  private gizmo: TransformControlsGizmoPrivateGizmos;
  public picker: TransformControlsGizmoPrivateGizmos;
  private helper: TransformControlsGizmoPrivateGizmos;

  // these are set from parent class TransformControls
  private rotationAxis = new Vector3();
  private cameraPosition = new Vector3();
  private worldPositionStart = new Vector3();
  private worldQuaternionStart = new Quaternion();
  private worldPosition = new Vector3();
  private worldQuaternion = new Quaternion();
  private eye = new Vector3();

  public renderOrder: number = Infinity;
  private camera: PerspectiveCamera | OrthographicCamera = null!;
  private enabled = true;
  private axis: string | null = null;
  private mode!: Mode;
  private space = "local";
  private size = 1;
  private dragging = false;
  private showX = true;
  private showY = true;
  private showZ = true;

  // Base line thickness
  private lineThickness: number;

  // Base Materials
  private gizmoMaterial!: MeshBasicMaterial;
  private fatLineMaterial!: LineMaterial;

  // Base Geometries
  private fatLineGeometryTranslate!: LineGeometry;
  private fatLineGeometryScale!: LineGeometry;

  // Translate Gizmo
  private gizmoTranslateLineThickness!: number;
  private gizmoTranslateAxisColors!: AxisColorDictionary;
  private gizmoTranslateMaterialXAxisLine!: LineMaterial;
  private gizmoTranslateMaterialYAxisLine!: LineMaterial;
  private gizmoTranslateMaterialZAxisLine!: LineMaterial;
  private gizmoTranslateMaterialXAxisArrow!: MeshBasicMaterial;
  private gizmoTranslateMaterialYAxisArrow!: MeshBasicMaterial;
  private gizmoTranslateMaterialZAxisArrow!: MeshBasicMaterial;
  private gizmoTranslateMaterialXYXZArea!: MeshBasicMaterial;
  private gizmoTranslateMaterialXYPlane!: MeshBasicMaterial;
  private gizmoTranslateMaterialXZPlane!: MeshBasicMaterial;
  private gizmoTranslateMaterialYZPlane!: MeshBasicMaterial;

  // Scale Gizmo
  private gizmoScaleLineThickness!: number;
  private gizmoScaleAxisColors!: AxisColorDictionary;
  private gizmoScaleMaterialXAxisLine!: LineMaterial;
  private gizmoScaleMaterialYAxisLine!: LineMaterial;
  private gizmoScaleMaterialZAxisLine!: LineMaterial;
  private gizmoScaleMaterialXAxisHandle!: MeshBasicMaterial;
  private gizmoScaleMaterialYAxisHandle!: MeshBasicMaterial;
  private gizmoScaleMaterialZAxisHandle!: MeshBasicMaterial;
  private gizmoScaleMaterialXYAxisLine!: LineMaterial;
  private gizmoScaleMaterialXZAxisLine!: LineMaterial;
  private gizmoScaleMaterialYZAxisLine!: LineMaterial;
  private gizmoScaleMaterialXYAxisHandle!: MeshBasicMaterial;
  private gizmoScaleMaterialXZAxisHandle!: MeshBasicMaterial;
  private gizmoScaleMaterialYZAxisHandle!: MeshBasicMaterial;
  private gizmoScaleMaterialXYZXAxisHandle!: MeshBasicMaterial;
  private gizmoScaleMaterialXYZYAxisHandle!: MeshBasicMaterial;
  private gizmoScaleMaterialXYZZAxisHandle!: MeshBasicMaterial;

  // Rotate Gizmo
  private gizmoRotateLineThickness!: number;
  private gizmoRotateAxisColors!: AxisColorDictionary;
  private gizmoRotateMaterialXAxisLine!: LineMaterial;
  private gizmoRotateMaterialYAxisLine!: LineMaterial;
  private gizmoRotateMaterialZAxisLine!: LineMaterial;
  private gizmoRotateMaterialEAxisLine!: LineMaterial;
  private gizmoRotateMaterialXYZEAxisLine!: LineMaterial;
  private gizmoRotateMaterialXAxisHandle!: MeshBasicMaterial;
  private gizmoRotateMaterialYAxisHandle!: MeshBasicMaterial;
  private gizmoRotateMaterialZAxisHandle!: MeshBasicMaterial;
  private gizmoRotateMaterialEAxisHandle!: MeshBasicMaterial;

  // Pickers
  private pickerMaterial!: MeshBasicMaterial;

  // Helpers
  private helperMaterial!: MeshBasicMaterial;
  
  // Geometries
  private arrowGeometry!: CylinderGeometry;
  private scaleHandleGeometry!: BoxGeometry;
  private lineGeometry!: BufferGeometry;
  private translateHelperGeometry!: () => BufferGeometry;

  initialiseTranslateGizmoMaterials = () => {
    // Initialise lines
    this.gizmoTranslateMaterialXAxisLine = this.fatLineMaterial.clone() as LineMaterial; 
    this.gizmoTranslateMaterialYAxisLine = this.fatLineMaterial.clone() as LineMaterial;
    this.gizmoTranslateMaterialZAxisLine = this.fatLineMaterial.clone() as LineMaterial;

    // Set thickness
    if (this.gizmoTranslateLineThickness) this.gizmoTranslateMaterialXAxisLine.linewidth = this.gizmoTranslateLineThickness;
    if (this.gizmoTranslateLineThickness) this.gizmoTranslateMaterialYAxisLine.linewidth = this.gizmoTranslateLineThickness;
    if (this.gizmoTranslateLineThickness) this.gizmoTranslateMaterialZAxisLine.linewidth = this.gizmoTranslateLineThickness;

    // Initialise arrows
    this.gizmoTranslateMaterialXAxisArrow = this.gizmoMaterial.clone() as MeshBasicMaterial;
    this.gizmoTranslateMaterialYAxisArrow = this.gizmoMaterial.clone() as MeshBasicMaterial;
    this.gizmoTranslateMaterialZAxisArrow = this.gizmoMaterial.clone() as MeshBasicMaterial;

    // This is the 'all axis / free translate' in the middle of the gizmo
    this.gizmoTranslateMaterialXYXZArea = this.gizmoMaterial.clone() as MeshBasicMaterial;

    // Dual axis planes
    this.gizmoTranslateMaterialXYPlane = this.gizmoMaterial.clone() as MeshBasicMaterial;
    this.gizmoTranslateMaterialYZPlane = this.gizmoMaterial.clone() as MeshBasicMaterial;
    this.gizmoTranslateMaterialXZPlane = this.gizmoMaterial.clone() as MeshBasicMaterial;

    // Assign colours to the lines and arrows for each axis
    this.gizmoTranslateMaterialXAxisLine.color.set(this.gizmoTranslateAxisColors['X']!.active);
    this.gizmoTranslateMaterialXAxisArrow.color.set(this.gizmoTranslateAxisColors['X']!.active);
    this.gizmoTranslateMaterialYAxisLine.color.set(this.gizmoTranslateAxisColors['Y']!.active);
    this.gizmoTranslateMaterialYAxisArrow.color.set(this.gizmoTranslateAxisColors['Y']!.active);
    this.gizmoTranslateMaterialZAxisLine.color.set(this.gizmoTranslateAxisColors['Z']!.active);
    this.gizmoTranslateMaterialZAxisArrow.color.set(this.gizmoTranslateAxisColors['Z']!.active);
    this.gizmoTranslateMaterialXYXZArea.color.set(this.gizmoTranslateAxisColors['XYZ']!.active);
    this.gizmoTranslateMaterialXYPlane.color.set(this.gizmoTranslateAxisColors['XY']!.active);
    this.gizmoTranslateMaterialXZPlane.color.set(this.gizmoTranslateAxisColors['XZ']!.active);
    this.gizmoTranslateMaterialYZPlane.color.set(this.gizmoTranslateAxisColors['YZ']!.active);
  }

  initialiseRotateGizmoMaterials = () => {
    // Initialise lines
    this.gizmoRotateMaterialXAxisLine = this.fatLineMaterial.clone() as LineMaterial;
    this.gizmoRotateMaterialYAxisLine = this.fatLineMaterial.clone() as LineMaterial;
    this.gizmoRotateMaterialZAxisLine = this.fatLineMaterial.clone() as LineMaterial;
    this.gizmoRotateMaterialEAxisLine = this.fatLineMaterial.clone() as LineMaterial;
    this.gizmoRotateMaterialXYZEAxisLine = this.fatLineMaterial.clone() as LineMaterial;

    // Set thickness
    if (this.gizmoRotateLineThickness) this.gizmoRotateMaterialXAxisLine.linewidth = this.gizmoRotateLineThickness;    
    if (this.gizmoRotateLineThickness) this.gizmoRotateMaterialYAxisLine.linewidth = this.gizmoRotateLineThickness;    
    if (this.gizmoRotateLineThickness) this.gizmoRotateMaterialZAxisLine.linewidth = this.gizmoRotateLineThickness;    
    if (this.gizmoRotateLineThickness) this.gizmoRotateMaterialEAxisLine.linewidth = this.gizmoRotateLineThickness;    
    if (this.gizmoRotateLineThickness) this.gizmoRotateMaterialXYZEAxisLine.linewidth = this.gizmoRotateLineThickness;    

    // Initialise handles
    this.gizmoRotateMaterialXAxisHandle = this.gizmoMaterial.clone() as MeshBasicMaterial;
    this.gizmoRotateMaterialYAxisHandle = this.gizmoMaterial.clone() as MeshBasicMaterial;
    this.gizmoRotateMaterialZAxisHandle = this.gizmoMaterial.clone() as MeshBasicMaterial;
    this.gizmoRotateMaterialEAxisHandle = this.gizmoMaterial.clone() as MeshBasicMaterial;

    // Assign colors
    this.gizmoRotateMaterialXAxisLine.color.set(this.gizmoRotateAxisColors['X']!.active);
    this.gizmoRotateMaterialXAxisHandle.color.set(this.gizmoRotateAxisColors['X']!.active);
    this.gizmoRotateMaterialYAxisLine.color.set(this.gizmoRotateAxisColors['Y']!.active);
    this.gizmoRotateMaterialYAxisHandle.color.set(this.gizmoRotateAxisColors['Y']!.active);
    this.gizmoRotateMaterialZAxisLine.color.set(this.gizmoRotateAxisColors['Z']!.active);
    this.gizmoRotateMaterialZAxisHandle.color.set(this.gizmoRotateAxisColors['Z']!.active);
    this.gizmoRotateMaterialEAxisLine.color.set(this.gizmoRotateAxisColors['E']!.active);
    this.gizmoRotateMaterialEAxisHandle.color.set(this.gizmoRotateAxisColors['E']!.active);
    this.gizmoRotateMaterialXYZEAxisLine.color.set(this.gizmoRotateAxisColors['XYZE']!.active);
    this.gizmoRotateMaterialXYZEAxisLine.linewidth = 0.5;
  }

  initialiseScaleGizmoMaterials = () => {
    // Initialise lines
    this.gizmoScaleMaterialXAxisLine = this.fatLineMaterial.clone() as LineMaterial;
    this.gizmoScaleMaterialYAxisLine = this.fatLineMaterial.clone() as LineMaterial;
    this.gizmoScaleMaterialZAxisLine = this.fatLineMaterial.clone() as LineMaterial;
    this.gizmoScaleMaterialXYAxisLine = this.fatLineMaterial.clone() as LineMaterial;
    this.gizmoScaleMaterialXZAxisLine = this.fatLineMaterial.clone() as LineMaterial;
    this.gizmoScaleMaterialYZAxisLine = this.fatLineMaterial.clone() as LineMaterial;

    // Set thickness
    if (this.gizmoScaleLineThickness) this.gizmoScaleMaterialXAxisLine.linewidth = this.gizmoScaleLineThickness;
    if (this.gizmoScaleLineThickness) this.gizmoScaleMaterialYAxisLine.linewidth = this.gizmoScaleLineThickness;
    if (this.gizmoScaleLineThickness) this.gizmoScaleMaterialZAxisLine.linewidth = this.gizmoScaleLineThickness;
    if (this.gizmoScaleLineThickness) this.gizmoScaleMaterialXYAxisLine.linewidth = this.gizmoScaleLineThickness;
    if (this.gizmoScaleLineThickness) this.gizmoScaleMaterialXZAxisLine.linewidth = this.gizmoScaleLineThickness;
    if (this.gizmoScaleLineThickness) this.gizmoScaleMaterialYZAxisLine.linewidth = this.gizmoScaleLineThickness;

    // Initialise cubes
    this.gizmoScaleMaterialXAxisHandle = this.gizmoMaterial.clone() as MeshBasicMaterial;
    this.gizmoScaleMaterialYAxisHandle = this.gizmoMaterial.clone() as MeshBasicMaterial;
    this.gizmoScaleMaterialZAxisHandle = this.gizmoMaterial.clone() as MeshBasicMaterial;
    this.gizmoScaleMaterialXYAxisHandle = this.gizmoMaterial.clone() as MeshBasicMaterial;
    this.gizmoScaleMaterialXZAxisHandle = this.gizmoMaterial.clone() as MeshBasicMaterial;
    this.gizmoScaleMaterialYZAxisHandle = this.gizmoMaterial.clone() as MeshBasicMaterial;
    this.gizmoScaleMaterialXYZXAxisHandle = this.gizmoMaterial.clone() as MeshBasicMaterial;
    this.gizmoScaleMaterialXYZYAxisHandle = this.gizmoMaterial.clone() as MeshBasicMaterial;
    this.gizmoScaleMaterialXYZZAxisHandle = this.gizmoMaterial.clone() as MeshBasicMaterial;
      
    // Assign colors
    this.gizmoScaleMaterialXAxisLine.color.set(this.gizmoScaleAxisColors['X']!.active);
    this.gizmoScaleMaterialXAxisHandle.color.set(this.gizmoScaleAxisColors['X']!.active);
    this.gizmoScaleMaterialYAxisLine.color.set(this.gizmoScaleAxisColors['Y']!.active);
    this.gizmoScaleMaterialYAxisHandle.color.set(this.gizmoScaleAxisColors['Y']!.active);
    this.gizmoScaleMaterialZAxisLine.color.set(this.gizmoScaleAxisColors['Z']!.active);
    this.gizmoScaleMaterialZAxisHandle.color.set(this.gizmoScaleAxisColors['Z']!.active);
    this.gizmoScaleMaterialXYAxisLine.color.set(this.gizmoScaleAxisColors['XY']!.active);
    this.gizmoScaleMaterialXYAxisHandle.color.set(this.gizmoScaleAxisColors['XY']!.active);
    this.gizmoScaleMaterialXZAxisLine.color.set(this.gizmoScaleAxisColors['XZ']!.active);
    this.gizmoScaleMaterialXZAxisHandle.color.set(this.gizmoScaleAxisColors['XZ']!.active);
    this.gizmoScaleMaterialYZAxisLine.color.set(this.gizmoScaleAxisColors['YZ']!.active);
    this.gizmoScaleMaterialYZAxisHandle.color.set(this.gizmoScaleAxisColors['YZ']!.active);
    this.gizmoScaleMaterialXYZXAxisHandle.color.set(this.gizmoScaleAxisColors['XYZX']!.active);
    this.gizmoScaleMaterialXYZYAxisHandle.color.set(this.gizmoScaleAxisColors['XYZY']!.active);
    this.gizmoScaleMaterialXYZZAxisHandle.color.set(this.gizmoScaleAxisColors['XYZZ']!.active);
  }

  initialiseGeometries = () => {
    // Set up geometry
    this.fatLineGeometryTranslate = new LineGeometry(); this.fatLineGeometryTranslate.setPositions([0, 0, 0, 0, 0.75, 0]);
    this.fatLineGeometryScale = new LineGeometry(); this.fatLineGeometryScale.setPositions([0, 0, 0, 1, 0, 0]);
    this.arrowGeometry = new CylinderGeometry(0, 0.05, 0.2, 12, 1, false);
    this.scaleHandleGeometry = new BoxGeometry(0.125, 0.125, 0.125);
    this.lineGeometry = new BufferGeometry(); this.lineGeometry.setAttribute("position", new Float32BufferAttribute([0, 0, 0, 1, 0, 0], 3));

    // Special geometry for translate helper. If scaled with position vector it spans from [0,0,0] to position
    this.translateHelperGeometry = (): BufferGeometry => {
      const geometry = new BufferGeometry();
      geometry.setAttribute(
        "position",
        new Float32BufferAttribute([0, 0, 0, 1, 1, 1], 3)
      );
      return geometry;
    };
  }

  initialiseMaterials = () => {
    // For the arrows, cylinders
    this.gizmoMaterial = new MeshBasicMaterial({
      depthTest: false,
      depthWrite: false,
      transparent: true,
      side: DoubleSide,
      fog: false,
      toneMapped: false,
    });

    // For the lines
    this.fatLineMaterial = new LineMaterial({
      color: 0xff0000,
      linewidth: this.lineThickness,
      depthWrite: false,
      depthTest: false,
      transparent: true,
      side: DoubleSide,
      fog: false,
      toneMapped: false,
    });
    this.fatLineMaterial.resolution.set(window.innerWidth, window.innerHeight);
     
    // Gizmos
    this.initialiseTranslateGizmoMaterials();
    this.initialiseScaleGizmoMaterials();
    this.initialiseRotateGizmoMaterials();

    // Pickers
    this.pickerMaterial = this.gizmoMaterial.clone() as MeshBasicMaterial;
    this.pickerMaterial.opacity = 0.15;

    // Helpers
    this.helperMaterial = this.gizmoMaterial.clone() as MeshBasicMaterial;
    this.helperMaterial.opacity = 0.33;
  }

  private setupGizmoScale = (axisToShow: Axis[]) => {
    // console.log({axisToShow})
    const gizmoScaleXAxis = axisToShow.includes(Axis.X) ? [
      [ new Mesh(this.scaleHandleGeometry, this.gizmoScaleMaterialXAxisHandle), [0.8, 0, 0], [0, 0, -Math.PI / 2],],
      [ new Line2(this.fatLineGeometryScale, this.gizmoScaleMaterialXAxisLine), null, null, [0.8, 1, 1]],
    ] : [];

    const gizmoScaleYAxis = axisToShow.includes(Axis.Y) ? [
      [ new Mesh(this.scaleHandleGeometry, this.gizmoScaleMaterialYAxisHandle), [0, 0.8, 0]],
      [ new Line2(this.fatLineGeometryScale, this.gizmoScaleMaterialYAxisLine), null, [0, 0, Math.PI / 2], [0.8, 1, 1],],
    ] : [];

    const gizmoScaleZAxis = axisToShow.includes(Axis.Z) ? [
      [ new Mesh(this.scaleHandleGeometry, this.gizmoScaleMaterialZAxisHandle), [0, 0, 0.8], [Math.PI / 2, 0, 0], ],
      [ new Line2(this.fatLineGeometryScale, this.gizmoScaleMaterialZAxisLine), null, [0, -Math.PI / 2, 0], [0.8, 1, 1], ],
    ] : [];

    const gizmoScaleXYAxis = axisToShow.includes(Axis.XY) ? [
      [ new Mesh(this.scaleHandleGeometry, this.gizmoScaleMaterialXYAxisHandle), [0.85, 0.85, 0],null,[2, 2, 0.2],],
      [ new Line2(this.fatLineGeometryScale, this.gizmoScaleMaterialXYAxisLine), [0.855, 0.98, 0], null, [0.125, 1, 1],],
      [ new Line2(this.fatLineGeometryScale, this.gizmoScaleMaterialXYAxisLine), [0.98, 0.855, 0], [0, 0, Math.PI / 2], [0.125, 1, 1],],
    ] : [];

    const gizmoScaleXZAxis = axisToShow.includes(Axis.XZ) ? [
        [ new Mesh(this.scaleHandleGeometry, this.gizmoScaleMaterialXZAxisHandle),[0.850, 0, 0.85], null,[2, 0.2, 2], ],
        [ new Line2(this.fatLineGeometryScale, this.gizmoScaleMaterialXZAxisLine),[0, 0.855, 0.98], [0, 0, Math.PI / 2], [0.125, 1, 1], ],
        [ new Line2(this.fatLineGeometryScale, this.gizmoScaleMaterialXZAxisLine),[0, 0.98, 0.855], [0, -Math.PI / 2, 0], [0.125, 1, 1], ],
      ] : [];

    const gizmoScaleYZAxis = axisToShow.includes(Axis.YZ) ? [
        [ new Mesh(this.scaleHandleGeometry, this.gizmoScaleMaterialYZAxisHandle), [0.85, 0, 0.85], null, [2, 0.2, 2],],
        [ new Line2(this.fatLineGeometryScale, this.gizmoScaleMaterialYZAxisLine),[0.855, 0, 0.98], null, [0.125, 1, 1], ],
        [ new Line2(this.fatLineGeometryScale, this.gizmoScaleMaterialYZAxisLine),[0.98, 0, 0.855], [0, -Math.PI / 2, 0], [0.125, 1, 1], ],
    ] : [];
    
    const gizmoScaleXYZXAxis = axisToShow.includes(Axis.XYZX) ?
      [[new Mesh(new BoxGeometry(0.125, 0.125, 0.125), this.gizmoScaleMaterialXYZXAxisHandle), [1.1, 0, 0],],] 
      : [];
 
    const gizmoScaleXYZYAxis = axisToShow.includes(Axis.XYZY) ?
      [[new Mesh(new BoxGeometry(0.125, 0.125, 0.125), this.gizmoScaleMaterialXYZYAxisHandle), [0, 1.1, 0],],]
      : [];
    
    const gizmoScaleXYZZAxis = axisToShow.includes(Axis.XYZZ) ?
      [[new Mesh(new BoxGeometry(0.125, 0.125, 0.125), this.gizmoScaleMaterialXYZZAxisHandle), [0, 0, 1.1],],]
      : [];

    return {
      X: gizmoScaleXAxis,
      Y: gizmoScaleYAxis,
      Z: gizmoScaleZAxis,
      XY: gizmoScaleXYAxis,
      XZ: gizmoScaleXZAxis,
      YZ: gizmoScaleYZAxis,
      XYZX: gizmoScaleXYZXAxis,
      XYZY: gizmoScaleXYZYAxis,
      XYZZ: gizmoScaleXYZZAxis,
    };
  }

  private setupGizmoTranslate = (axisToShow: Axis[]) => {
    // Format is Object, Position, Rotation, Scale, Tag ( in that order ) with null to miss one
    const gizmoTranslateXAxis = axisToShow.includes(Axis.X) ? [
      [ new Mesh( this.arrowGeometry, this.gizmoTranslateMaterialXAxisArrow ), [ 0.75, 0, 0 ], [ 0, 0, - Math.PI / 2 ]],
      [ new Line2( this.fatLineGeometryTranslate, this.gizmoTranslateMaterialXAxisLine ), [ 0, 0, 0 ], [ 0, 0, - Math.PI / 2 ]],
      // [ new Mesh( arrowGeometry, matRed ), [ - 0.5, 0, 0 ], [ 0, 0, Math.PI / 2 ]],  // This is for the "opposite / floating" arrowhead - leaving in just in case..
    ] : [];

    const gizmoTranslateYAxis = axisToShow.includes(Axis.Y) ? [
      [new Mesh(this.arrowGeometry, this.gizmoTranslateMaterialYAxisArrow), [0, 0.75, 0]],
      [new Line2(this.fatLineGeometryTranslate, this.gizmoTranslateMaterialYAxisLine)],
      // [ new Mesh( arrowGeometry, matGreen ), [ 0, - 0.5, 0 ], [ Math.PI, 0, 0 ]],  // This is for the "opposite / floating" arrowhead - leaving in just in case..
    ] : [];

    const gizmoTranslateZAxis = axisToShow.includes(Axis.Z) ? [
      [new Mesh(this.arrowGeometry, this.gizmoTranslateMaterialZAxisArrow), [0, 0, 0.75], [Math.PI / 2, 0, 0]],
      [new Line2(this.fatLineGeometryTranslate, this.gizmoTranslateMaterialZAxisLine), null, [Math.PI / 2, 0, 0]]
      // [ new Mesh( arrowGeometry, matBlue ), [ 0, 0, - 0.5 ], [ - Math.PI / 2, 0, 0 ]],  // This is for the "opposite / floating" arrowhead - leaving in just in case..
    ] : [];

    const gizmoTranslateXYZAxis = axisToShow.includes(Axis.XYZ) ? [
      [new Mesh(new OctahedronGeometry(0.1, 0), this.gizmoTranslateMaterialXYXZArea), [0, 0, 0], [0, 0, 0],],
    ] : [];

    const gizmoTranslateXYAxis = axisToShow.includes(Axis.XY) ? [
      [new Mesh(new PlaneGeometry(0.295, 0.295), this.gizmoTranslateMaterialXYPlane), [0.15, 0.15, 0],],
      [new Line(this.lineGeometry, this.gizmoTranslateMaterialXYPlane), [0.18, 0.3, 0], null, [0.125, 1, 1],],
      [new Line(this.lineGeometry, this.gizmoTranslateMaterialXYPlane), [0.3, 0.18, 0], [0, 0, Math.PI / 2], [0.125, 1, 1],],
    ] : [];

    const gizmoTranslateYZAxis = axisToShow.includes(Axis.YZ) ? [
      [new Mesh(new PlaneGeometry(0.295, 0.295), this.gizmoTranslateMaterialYZPlane), [0, 0.15, 0.15], [0, Math.PI / 2, 0],],
      [new Line(this.lineGeometry, this.gizmoTranslateMaterialYZPlane), [0, 0.18, 0.3], [0, 0, Math.PI / 2], [0.125, 1, 1],],
      [new Line(this.lineGeometry, this.gizmoTranslateMaterialYZPlane), [0, 0.3, 0.18], [0, -Math.PI / 2, 0], [0.125, 1, 1],],
    ] : [];

    const gizmoTranslateXZAxis = axisToShow.includes(Axis.XZ) ? [
      [new Mesh(new PlaneGeometry(0.295, 0.295), this.gizmoTranslateMaterialXZPlane),[0.15, 0, 0.15], [-Math.PI / 2, 0, 0],],
      [ new Line(this.lineGeometry, this.gizmoTranslateMaterialXZPlane), [0.18, 0, 0.3], null, [0.125, 1, 1],],
      [ new Line(this.lineGeometry, this.gizmoTranslateMaterialXZPlane), [0.3, 0, 0.18], [0, -Math.PI / 2, 0], [0.125, 1, 1],],
    ] : [];
      
    // Gizmo definitions - custom hierarchy definitions for setupGizmo() function
    return {
			X: gizmoTranslateXAxis,
			Y: gizmoTranslateYAxis,
			Z: gizmoTranslateZAxis,
      XYZ: gizmoTranslateXYZAxis,
      XY: gizmoTranslateXYAxis,
      YZ: gizmoTranslateYZAxis,
      XZ: gizmoTranslateXZAxis
    };
  }

  private setupGizmoRotate = (axisToShow: Axis[]) => {

    const gizmoRotateXAxis = axisToShow.includes(Axis.X) ? [
      [new Line2(CircleGeometry(1, 0.5), this.gizmoRotateMaterialXAxisLine)],
      [new Mesh(new OctahedronGeometry(0.04, 0), this.gizmoRotateMaterialXAxisHandle), [0, 0, 0.99], null, [1, 3, 1],],
    ] : [];

    const gizmoRotateYAxis = axisToShow.includes(Axis.Y) ? [
      [new Line2(CircleGeometry(1, 0.5), this.gizmoRotateMaterialYAxisLine),null,[0, 0, -Math.PI / 2],],
      [new Mesh(new OctahedronGeometry(0.04, 0), this.gizmoRotateMaterialYAxisHandle),[0, 0, 0.99],null,[3, 1, 1],],
    ] : [];

    const gizmoRotateZAxis = axisToShow.includes(Axis.Z) ? [
      [new Line2(CircleGeometry(1, 0.5), this.gizmoRotateMaterialZAxisLine),null,[0, Math.PI / 2, 0],],
      [new Mesh(new OctahedronGeometry(0.04, 0), this.gizmoRotateMaterialZAxisHandle),[0.99, 0, 0],null,[1, 3, 1],],
    ] : [];

    const gizmoRotateEAxis = axisToShow.includes(Axis.E) ? [
      [new Line2(CircleGeometry(1.25, 1), this.gizmoRotateMaterialEAxisLine), null, [0, Math.PI / 2, 0],],
      [new Mesh(new CylinderGeometry(0.03, 0, 0.15, 4, 1, false),this.gizmoRotateMaterialEAxisHandle),[1.17, 0, 0],[0, 0, -Math.PI / 2],[1, 1, 0.001],],
      [new Mesh(new CylinderGeometry(0.03, 0, 0.15, 4, 1, false),this.gizmoRotateMaterialEAxisHandle),[-1.17, 0, 0],[0, 0, Math.PI / 2],[1, 1, 0.001],],
      [new Mesh(new CylinderGeometry(0.03, 0, 0.15, 4, 1, false),this.gizmoRotateMaterialEAxisHandle),[0, -1.17, 0],[Math.PI, 0, 0],[1, 1, 0.001],],
      [new Mesh(new CylinderGeometry(0.03, 0, 0.15, 4, 1, false), this.gizmoRotateMaterialEAxisHandle), [0, 1.17, 0], [0, 0, 0], [1, 1, 0.001],],
    ] : [];

    const gizmoRotateXYZEAxis = axisToShow.includes(Axis.XYZE) ? [
      [new Line2(CircleGeometry(1, 1), this.gizmoRotateMaterialXYZEAxisLine),null,[0, Math.PI / 2, 0],]
    ] : [];

    return {
      X: gizmoRotateXAxis,
      Y: gizmoRotateYAxis,
      Z: gizmoRotateZAxis,
      E: gizmoRotateEAxis,
      XYZE: gizmoRotateXYZEAxis,
    };
  }

  private setupPickerTranslate = (axisToShow: Axis[]) => {
    return {
      X: (axisToShow.includes(Axis.X) && [[ new Mesh(new CylinderGeometry(0.2, 0, 1, 4, 1, false), this.pickerMaterial),[0.6, 0, 0],[0, 0, -Math.PI / 2], ],]),
      Y: (axisToShow.includes(Axis.Y) && [[ new Mesh(new CylinderGeometry(0.2, 0, 1, 4, 1, false), this.pickerMaterial), [0, 0.6, 0], ], ]),
      Z: (axisToShow.includes(Axis.Z) && [[ new Mesh(new CylinderGeometry(0.2, 0, 1, 4, 1, false), this.pickerMaterial), [0, 0, 0.6], [Math.PI / 2, 0, 0], ], ]),
      XYZ: (axisToShow.includes(Axis.XYZ) && [[ new Mesh(new OctahedronGeometry(0.2, 0), this.pickerMaterial)]]),
      XY: (axisToShow.includes(Axis.XY) && [[ new Mesh(new PlaneGeometry(0.4, 0.4), this.pickerMaterial), [0.2, 0.2, 0]], ]),
      YZ: (axisToShow.includes(Axis.YZ) && [[ new Mesh(new PlaneGeometry(0.4, 0.4), this.pickerMaterial), [0, 0.2, 0.2], [0, Math.PI / 2, 0],], ]),
      XZ: (axisToShow.includes(Axis.XZ) && [[ new Mesh(new PlaneGeometry(0.4, 0.4), this.pickerMaterial),[0.2, 0, 0.2], [-Math.PI / 2, 0, 0], ], ]),
    };
  }

  private setupPickerScale = (axisToShow: Axis[]) => {
    return {
      X: (axisToShow.includes(Axis.X) && [[ new Mesh( new CylinderGeometry(0.2, 0, 0.8, 4, 1, false), this.pickerMaterial),[0.5, 0, 0], [0, 0, -Math.PI / 2],],]),
      Y: (axisToShow.includes(Axis.Y) && [[ new Mesh( new CylinderGeometry(0.2, 0, 0.8, 4, 1, false), this.pickerMaterial),[0, 0.5, 0], ], ]),
      Z: (axisToShow.includes(Axis.Z) && [[ new Mesh( new CylinderGeometry(0.2, 0, 0.8, 4, 1, false), this.pickerMaterial),[0, 0, 0.5], [Math.PI / 2, 0, 0],],]),
      XY: (axisToShow.includes(Axis.XY) && [[ new Mesh(this.scaleHandleGeometry, this.pickerMaterial),[0.85, 0.85, 0],null, [3, 3, 0.2],],]),
      YZ: (axisToShow.includes(Axis.YZ) && [[ new Mesh(this.scaleHandleGeometry, this.pickerMaterial), [0, 0.85, 0.85], null,[0.2, 3, 3],],]),
      XZ: (axisToShow.includes(Axis.XZ) && [[ new Mesh(this.scaleHandleGeometry, this.pickerMaterial), [0.85, 0, 0.85], null,[3, 0.2, 3],],]),
      XYZX: (axisToShow.includes(Axis.XYZX) && [[ new Mesh(new BoxGeometry(0.2, 0.2, 0.2), this.pickerMaterial), [1.1, 0, 0]],]),
      XYZY: (axisToShow.includes(Axis.XYZY) && [[ new Mesh(new BoxGeometry(0.2, 0.2, 0.2), this.pickerMaterial), [0, 1.1, 0]],]),
      XYZZ: (axisToShow.includes(Axis.XYZZ) && [[ new Mesh(new BoxGeometry(0.2, 0.2, 0.2), this.pickerMaterial), [0, 0, 1.1]],]),
    };
  }

  private setupPickerRotate = (axisToShow: Axis[]) => {
    return {
      X: (axisToShow.includes(Axis.X) && [[ new Mesh(new TorusGeometry(1, 0.1, 4, 24), this.pickerMaterial), [0, 0, 0], [0, -Math.PI / 2, -Math.PI / 2],], ]),
      Y: (axisToShow.includes(Axis.Y) && [[ new Mesh(new TorusGeometry(1, 0.1, 4, 24), this.pickerMaterial),[0, 0, 0], [Math.PI / 2, 0, 0],],]),
      Z: (axisToShow.includes(Axis.Z) && [[ new Mesh(new TorusGeometry(1, 0.1, 4, 24), this.pickerMaterial), [0, 0, 0], [0, 0, -Math.PI / 2],], ]),
      E: (axisToShow.includes(Axis.E) && [[new Mesh(new TorusGeometry(1.25, 0.1, 2, 24), this.pickerMaterial)]]),
      XYZE: (axisToShow.includes(Axis.XYZE) && [[new Mesh(new SphereGeometry(0.7, 10, 8), this.pickerMaterial)]]),
    }; 
  }

  private setupHelperTranslate = (axisToShow: Axis[]) => {
    return {
      START: [[ new Mesh(new OctahedronGeometry(0.01, 2), this.helperMaterial), null, null, null, "helper", ],],
      END: [[ new Mesh(new OctahedronGeometry(0.01, 2), this.helperMaterial), null, null, null, "helper", ], ],
      DELTA: [[ new Line(this.translateHelperGeometry(), this.helperMaterial), null, null, null, "helper",],],
      X: (axisToShow.includes(Axis.X) && [[ new Line(this.lineGeometry, this.helperMaterial.clone()),[-1e3, 0, 0], null,[1e6, 1, 1], "helper",], ]),
      Y: (axisToShow.includes(Axis.Y) && [[ new Line(this.lineGeometry, this.helperMaterial.clone()), [0, -1e3, 0], [0, 0, Math.PI / 2],[1e6, 1, 1], "helper",],]),
      Z: (axisToShow.includes(Axis.Z) && [[ new Line(this.lineGeometry, this.helperMaterial.clone()), [0, 0, -1e3], [0, -Math.PI / 2, 0], [1e6, 1, 1], "helper",],]),
    };
  }

  private setupHelperRotate = () => {
    return {
      AXIS: [[ new Line(this.lineGeometry, this.helperMaterial.clone()), [-1e3, 0, 0], null,[1e6, 1, 1], "helper",],],
    };
  }

  private setupHelperScale = (axisToShow: Axis[]) => {
    return {
      X: (axisToShow.includes(Axis.X) && [[ new Line(this.lineGeometry, this.helperMaterial.clone()),[-1e3, 0, 0],null,[1e6, 1, 1],"helper",],]),
      Y: (axisToShow.includes(Axis.Y) && [[ new Line(this.lineGeometry, this.helperMaterial.clone()),[0, -1e3, 0], [0, 0, Math.PI / 2],[1e6, 1, 1],"helper",],]),
      Z: (axisToShow.includes(Axis.Z) && [[ new Line(this.lineGeometry, this.helperMaterial.clone()),[0, 0, -1e3], [0, -Math.PI / 2, 0],[1e6, 1, 1], "helper",],]),
    };
  }

  private gizmoTranslateDefaultProps = {
    axisToShow: [Axis.X, Axis.Y, Axis.Z, Axis.XYZ],
    lineThickness: DEFAULT_TRANSLATE_LINE_THICKNESS,
    axisColors: {
      'X': {
        active: DEFAULT_X_AXIS_COLOR_ACTIVE,
        inactive: DEFAULT_X_AXIS_COLOR_INACTIVE
      },
      'Y': {
        active: DEFAULT_Y_AXIS_COLOR_ACTIVE,
        inactive: DEFAULT_Y_AXIS_COLOR_INACTIVE
      },
      'Z': {
        active: DEFAULT_Z_AXIS_COLOR_ACTIVE,
        inactive: DEFAULT_Z_AXIS_COLOR_INACTIVE
      },
      'XYZ': {
        active: DEFAULT_XYZ_AXIS_COLOR_ACTIVE,
        inactive: DEFAULT_XYZ_AXIS_COLOR_INACTIVE
      },
      'XY': {
        active: DEFAULT_XY_AXIS_COLOR_ACTIVE,
        inactive: DEFAULT_XY_AXIS_COLOR_INACTIVE
      },
      'YZ': {
        active: DEFAULT_YZ_AXIS_COLOR_ACTIVE,
        inactive: DEFAULT_YZ_AXIS_COLOR_INACTIVE
      },
      'XZ': {
        active: DEFAULT_XZ_AXIS_COLOR_ACTIVE,
        inactive: DEFAULT_XZ_AXIS_COLOR_INACTIVE
      },
    }
  };

  private gizmoScaleDefaultProps = {
    axisToShow: [Axis.X, Axis.Y, Axis.Z],
    lineThickness: DEFAULT_SCALE_LINE_THICKNESS,
    axisColors: {
      'X': {
        active: DEFAULT_X_AXIS_COLOR_ACTIVE,
        inactive: DEFAULT_X_AXIS_COLOR_INACTIVE
      },
      'Y': {
        active: DEFAULT_Y_AXIS_COLOR_ACTIVE,
        inactive: DEFAULT_Y_AXIS_COLOR_INACTIVE
      },
      'Z': {
        active: DEFAULT_Z_AXIS_COLOR_ACTIVE,
        inactive: DEFAULT_Z_AXIS_COLOR_INACTIVE
      },
      'XY': {
        active: DEFAULT_XY_AXIS_COLOR_ACTIVE,
        inactive: DEFAULT_XY_AXIS_COLOR_INACTIVE
      },
      'YZ': {
        active: DEFAULT_YZ_AXIS_COLOR_ACTIVE,
        inactive: DEFAULT_YZ_AXIS_COLOR_INACTIVE
      },
      'XZ': {
        active: DEFAULT_XZ_AXIS_COLOR_ACTIVE,
        inactive: DEFAULT_XZ_AXIS_COLOR_INACTIVE
      },
      'XYZX': {
        active: DEFAULT_XYZX_AXIS_COLOR_ACTIVE,
        inactive: DEFAULT_XYZX_AXIS_COLOR_INACTIVE
      },
      'XYZY': {
        active: DEFAULT_XYZY_AXIS_COLOR_ACTIVE,
        inactive: DEFAULT_XYZY_AXIS_COLOR_INACTIVE
      },
      'XYZZ': {
        active: DEFAULT_XYZZ_AXIS_COLOR_ACTIVE,
        inactive: DEFAULT_XYZZ_AXIS_COLOR_INACTIVE
      },
    }
  }

  private gizmoRotateDefaultProps  = {
    axisToShow: [Axis.X, Axis.Y, Axis.Z, Axis.XYZE],
    lineThickness: DEFAULT_ROTATE_LINE_THICKNESS,
    axisColors: {
      'X': {
        active: DEFAULT_X_AXIS_COLOR_ACTIVE,
        inactive: DEFAULT_X_AXIS_COLOR_INACTIVE
      },
      'Y': {
        active: DEFAULT_Y_AXIS_COLOR_ACTIVE,
        inactive: DEFAULT_Y_AXIS_COLOR_INACTIVE
      },
      'Z': {
        active: DEFAULT_Z_AXIS_COLOR_ACTIVE,
        inactive: DEFAULT_Z_AXIS_COLOR_INACTIVE
      },
      'E': {
        active: DEFAULT_E_AXIS_COLOR_ACTIVE,
        inactive: DEFAULT_E_AXIS_COLOR_INACTIVE
      },
      'XYZE': {
        active: DEFAULT_XYZE_AXIS_COLOR_ACTIVE,
        inactive: DEFAULT_XYZE_AXIS_COLOR_INACTIVE
      }
    }
  }
  // this.gizmoScaleMaterialXAxisLine.color.set(this.gizmoScaleAxisColors['X']!.active);
  public setLockColors = () => {
    // Assign colors - scale
    this.gizmoScaleMaterialXAxisLine?.color.set(LOCKED_COLOR);
    this.gizmoScaleMaterialXAxisHandle?.color.set(LOCKED_COLOR);
    this.gizmoScaleMaterialYAxisLine?.color.set(LOCKED_COLOR);
    this.gizmoScaleMaterialYAxisHandle?.color.set(LOCKED_COLOR);
    this.gizmoScaleMaterialZAxisLine?.color.set(LOCKED_COLOR);
    this.gizmoScaleMaterialZAxisHandle?.color.set(LOCKED_COLOR);
    this.gizmoScaleMaterialXYAxisLine?.color.set(LOCKED_COLOR);
    this.gizmoScaleMaterialXYAxisHandle?.color.set(LOCKED_COLOR);
    this.gizmoScaleMaterialXZAxisLine?.color.set(LOCKED_COLOR);
    this.gizmoScaleMaterialXZAxisHandle?.color.set(LOCKED_COLOR);
    this.gizmoScaleMaterialYZAxisLine?.color.set(LOCKED_COLOR);
    this.gizmoScaleMaterialYZAxisHandle?.color.set(LOCKED_COLOR);
    this.gizmoScaleMaterialXYZXAxisHandle?.color.set(LOCKED_COLOR);
    this.gizmoScaleMaterialXYZYAxisHandle?.color.set(LOCKED_COLOR);
    this.gizmoScaleMaterialXYZZAxisHandle?.color.set(LOCKED_COLOR);

    // Assign colors - translate
    this.gizmoTranslateMaterialXAxisLine?.color.set(LOCKED_COLOR);
    this.gizmoTranslateMaterialXAxisArrow?.color.set(LOCKED_COLOR);
    this.gizmoTranslateMaterialYAxisLine?.color.set(LOCKED_COLOR);
    this.gizmoTranslateMaterialYAxisArrow?.color.set(LOCKED_COLOR);
    this.gizmoTranslateMaterialZAxisLine?.color.set(LOCKED_COLOR);
    this.gizmoTranslateMaterialZAxisArrow?.color.set(LOCKED_COLOR);
    this.gizmoTranslateMaterialXYXZArea?.color.set(LOCKED_COLOR);
    this.gizmoTranslateMaterialXYPlane?.color.set(LOCKED_COLOR);
    this.gizmoTranslateMaterialXZPlane?.color.set(LOCKED_COLOR);
    this.gizmoTranslateMaterialYZPlane?.color.set(LOCKED_COLOR);

    // Assign colors - rotate
    this.gizmoRotateMaterialXAxisLine?.color.set(LOCKED_COLOR);
    this.gizmoRotateMaterialXAxisHandle?.color.set(LOCKED_COLOR);
    this.gizmoRotateMaterialYAxisLine?.color.set(LOCKED_COLOR);
    this.gizmoRotateMaterialYAxisHandle?.color.set(LOCKED_COLOR);
    this.gizmoRotateMaterialZAxisLine?.color.set(LOCKED_COLOR);
    this.gizmoRotateMaterialZAxisHandle?.color.set(LOCKED_COLOR);
    this.gizmoRotateMaterialEAxisLine?.color.set(LOCKED_COLOR);
    this.gizmoRotateMaterialEAxisHandle?.color.set(LOCKED_COLOR);
    this.gizmoRotateMaterialXYZEAxisLine?.color.set(LOCKED_COLOR);
  }

  constructor({
    lineThickness = DEFAULT_OVERALL_LINE_THICKNESS,
    gizmoTranslateProps,
    gizmoScaleProps,
    gizmoRotateProps,
    locked
  }: GizmoProps) {
    super();

    // console.log({gizmoTranslateProps})

    // Initialise props
    this.lineThickness = lineThickness;
    this.locked = locked;

    // Line Thickness
    this.gizmoTranslateLineThickness = (gizmoTranslateProps?.lineThickness ? gizmoTranslateProps.lineThickness : (lineThickness !== DEFAULT_OVERALL_LINE_THICKNESS ? lineThickness : DEFAULT_TRANSLATE_LINE_THICKNESS));
    this.gizmoScaleLineThickness = (gizmoScaleProps?.lineThickness ? gizmoScaleProps.lineThickness : (lineThickness !== DEFAULT_OVERALL_LINE_THICKNESS ? lineThickness : DEFAULT_SCALE_LINE_THICKNESS));
    this.gizmoRotateLineThickness = (gizmoRotateProps?.lineThickness ? gizmoRotateProps.lineThickness : (lineThickness !== DEFAULT_OVERALL_LINE_THICKNESS ? lineThickness : DEFAULT_ROTATE_LINE_THICKNESS));

    // Colors - any supplied take precedence but all are assigned
    this.gizmoTranslateAxisColors = Object.assign({}, this.gizmoTranslateDefaultProps.axisColors, gizmoTranslateProps?.axisColors);
    this.gizmoScaleAxisColors = Object.assign({}, this.gizmoScaleDefaultProps.axisColors, gizmoScaleProps?.axisColors);
    this.gizmoRotateAxisColors = Object.assign({}, this.gizmoRotateDefaultProps.axisColors, gizmoRotateProps?.axisColors);

    // Override colors if locked
    if (this.locked) {
      this.setLockColors();
    }

    // Axis to show
    const translateAxisToShow = (gizmoTranslateProps?.axisToShow ? gizmoTranslateProps.axisToShow : this.gizmoTranslateDefaultProps.axisToShow);
    const scaleAxisToShow = (gizmoScaleProps?.axisToShow ? gizmoScaleProps.axisToShow : this.gizmoScaleDefaultProps.axisToShow);
    const rotateAxisToShow = (gizmoRotateProps?.axisToShow ? gizmoRotateProps.axisToShow : this.gizmoRotateDefaultProps.axisToShow);

    // Set up base geometries and materials
    this.initialiseMaterials();
    this.initialiseGeometries();

    // Set up gizmo parts ( the bits that the user sees )
    const gizmoTranslate = this.setupGizmoTranslate(translateAxisToShow);
    const gizmoScale = this.setupGizmoScale(scaleAxisToShow);
    const gizmoRotate = this.setupGizmoRotate(rotateAxisToShow);

    // Set up pickers ( the areas that are actually grabbable )
    const pickerTranslate = this.setupPickerTranslate(translateAxisToShow);
    const pickerScale = this.setupPickerScale(scaleAxisToShow);
    const pickerRotate = this.setupPickerRotate(rotateAxisToShow);

    // Set up helpers ( lines that show the transform change)
    const helperTranslate = this.setupHelperTranslate(translateAxisToShow);
    const helperScale = this.setupHelperScale(scaleAxisToShow);
    const helperRotate = this.setupHelperRotate();

    // Creates an Object3D with gizmos described in custom hierarchy definition.
    const setupGizmo = (gizmoMap: any): Object3D => {
      // console.log({gizmoMap});
      const gizmo = new Object3D();
      gizmo.renderOrder = Infinity;

      for (let name in gizmoMap) {
        for (let i = gizmoMap[name].length; i--; ) {
          const object = gizmoMap[name][i][0].clone() as Mesh;
          const position = gizmoMap[name][i][1];
          const rotation = gizmoMap[name][i][2];
          const scale = gizmoMap[name][i][3];
          const tag = gizmoMap[name][i][4];

          // name and tag properties are essential for picking and updating logic.
          object.name = name;
          object.userData = {
            renderOrder: Infinity,
            contentId: 'TransformControlsGizmo' + name,
          };
          (object as any).tag = tag;

          if (position) object.position.set(position[0], position[1], position[2]);
          if (rotation) object.rotation.set(rotation[0], rotation[1], rotation[2]);
          if (scale) object.scale.set(scale[0], scale[1], scale[2]);
          object.updateMatrix();

          const tempGeometry = object.geometry.clone();
          tempGeometry.applyMatrix4(object.matrix);
          object.geometry = tempGeometry;
          if ((object as any).tag !== "helper") {
            object.renderOrder = Infinity;
          } else {
            object.renderOrder = 500000;
          }

          object.position.set(0, 0, 0);
          object.rotation.set(0, 0, 0);
          object.scale.set(1, 1, 1);
          if ((object as any).tag !== "helper") {
            gizmo.add(object);
          }
        }
      }
      return gizmo;
    };

    this.gizmo = {} as TransformControlsGizmoPrivateGizmos;
    this.picker = {} as TransformControlsGizmoPrivateGizmos;
    this.helper = {} as TransformControlsGizmoPrivateGizmos;

    this.add((this.gizmo["translate"] = setupGizmo(gizmoTranslate)));
    this.add((this.gizmo["rotate"] = setupGizmo(gizmoRotate)));
    this.add((this.gizmo["scale"] = setupGizmo(gizmoScale)));
    this.add((this.picker["translate"] = setupGizmo(pickerTranslate)));
    this.add((this.picker["rotate"] = setupGizmo(pickerRotate)));
    this.add((this.picker["scale"] = setupGizmo(pickerScale)));
    this.add((this.helper["translate"] = setupGizmo(helperTranslate)));
    this.add((this.helper["rotate"] = setupGizmo(helperRotate)));
    this.add((this.helper["scale"] = setupGizmo(helperScale)));

    // Pickers should be hidden always
    this.picker["translate"].visible = false;
    this.picker["rotate"].visible = false;
    this.picker["scale"].visible = false;
  }

  // Helper function, returns appropriate active / inactive color for a given axis and mode
  getAxisColor = (mode: Mode, axis: Axis, active: boolean) => {    
    if (this.locked) return LOCKED_COLOR;
    switch (mode) {
      case Mode.translate:
        return (active ? this.gizmoTranslateAxisColors[axis]?.active : this.gizmoTranslateAxisColors[axis]?.inactive);
      case Mode.scale:
        return (active ? this.gizmoScaleAxisColors[axis]?.active : this.gizmoScaleAxisColors[axis]?.inactive);
      case Mode.rotate:
        return (active ? this.gizmoRotateAxisColors[axis]?.active : this.gizmoRotateAxisColors[axis]?.inactive);              
    }
  }

  // updateMatrixWorld will update transformations and appearance of individual handles
  public updateMatrixWorld = (): void => {
    // console.log(this);
    let space = this.space;

    if (this.mode === "scale") {
      space = "local"; // scale always oriented to local rotation
    }

    const quaternion = space === "local" ? this.worldQuaternion : this.identityQuaternion;  // These are fromn Object3D

    // Show only gizmos for current transform mode
    this.gizmo["translate"].visible = this.mode === "translate";
    this.gizmo["rotate"].visible = this.mode === "rotate";
    this.gizmo["scale"].visible = this.mode === "scale";

    this.helper["translate"].visible = this.mode === "translate";
    this.helper["rotate"].visible = this.mode === "rotate";
    this.helper["scale"].visible = this.mode === "scale";

    // Enable this section to see the pickers
    // this.picker["translate"].visible = this.mode === "translate";
    // this.picker["rotate"].visible = this.mode === "rotate";
    // this.picker["scale"].visible = this.mode === "scale";

    // Each handle is one of the mesh / lines for the currently selected gizmo mode
    let handles: Array<Object3D & { tag?: string }> = [];
    handles = handles.concat(this.picker[this.mode].children);
    handles = handles.concat(this.gizmo[this.mode].children);
    handles = handles.concat(this.helper[this.mode].children);
    // console.log({handles});

    for (let i = 0; i < handles.length; i++) {
      const handle = handles[i];

      // hide aligned to camera

      handle.visible = true;
      handle.rotation.set(0, 0, 0);
      handle.position.copy(this.worldPosition);

      let factor;

      if ((this.camera as OrthographicCamera).isOrthographicCamera) {
        factor =
          ((this.camera as OrthographicCamera).top -
            (this.camera as OrthographicCamera).bottom) /
          (this.camera as OrthographicCamera).zoom;
      } else {
        factor =
          this.worldPosition.distanceTo(this.cameraPosition) *
          Math.min(
            (1.9 *
              Math.tan(
                (Math.PI * (this.camera as PerspectiveCamera).fov) / 360
              )) /
              this.camera.zoom,
            7
          );
      }

      handle.scale.set(1, 1, 1).multiplyScalar((factor * this.size) / 7); // !! why 7 ?? this.size is hard coded to 1 and never changed...

      // TODO: simplify helpers and consider decoupling from gizmo also 
      // console.log('HANDLE TAG', handle.tag);
      if (handle.tag === "helper") {
        handle.visible = false;

        if (handle.name === "AXIS") {
          handle.position.copy(this.worldPositionStart);
          handle.visible = !!this.axis;

          if (this.axis === "X") {
            this.tempQuaternion.setFromEuler(this.tempEuler.set(0, 0, 0));
            handle.quaternion.copy(quaternion).multiply(this.tempQuaternion);

            if (
              Math.abs(
                this.alignVector
                  .copy(this.unitX)
                  .applyQuaternion(quaternion)
                  .dot(this.eye)
              ) > 0.9
            ) {
              handle.visible = false;
            }
          }

          if (this.axis === "Y") {
            this.tempQuaternion.setFromEuler(
              this.tempEuler.set(0, 0, Math.PI / 2)
            );
            handle.quaternion.copy(quaternion).multiply(this.tempQuaternion);

            if (
              Math.abs(
                this.alignVector
                  .copy(this.unitY)
                  .applyQuaternion(quaternion)
                  .dot(this.eye)
              ) > 0.9
            ) {
              handle.visible = false;
            }
          }

          if (this.axis === "Z") {
            this.tempQuaternion.setFromEuler(
              this.tempEuler.set(0, Math.PI / 2, 0)
            );
            handle.quaternion.copy(quaternion).multiply(this.tempQuaternion);

            if (
              Math.abs(
                this.alignVector
                  .copy(this.unitZ)
                  .applyQuaternion(quaternion)
                  .dot(this.eye)
              ) > 0.9
            ) {
              handle.visible = false;
            }
          }

          if (this.axis === "XYZE") {
            this.tempQuaternion.setFromEuler(
              this.tempEuler.set(0, Math.PI / 2, 0)
            );
            this.alignVector.copy(this.rotationAxis);
            handle.quaternion.setFromRotationMatrix(
              this.lookAtMatrix.lookAt(
                this.zeroVector,
                this.alignVector,
                this.unitY
              )
            );
            handle.quaternion.multiply(this.tempQuaternion);
            handle.visible = this.dragging;
          }

          if (this.axis === "E") {
            handle.visible = false;
          }
        } else if (handle.name === "START") {
          handle.position.copy(this.worldPositionStart);
          handle.visible = this.dragging;
        } else if (handle.name === "END") {
          handle.position.copy(this.worldPosition);
          handle.visible = this.dragging;
        } else if (handle.name === "DELTA") {
          handle.position.copy(this.worldPositionStart);
          handle.quaternion.copy(this.worldQuaternionStart);
          this.tempVector
            .set(1e-10, 1e-10, 1e-10)
            .add(this.worldPositionStart)
            .sub(this.worldPosition)
            .multiplyScalar(-1);
          this.tempVector.applyQuaternion(
            this.worldQuaternionStart.clone().invert()
          );
          handle.scale.copy(this.tempVector);
          handle.visible = this.dragging;
        } else {
          handle.quaternion.copy(quaternion);

          if (this.dragging) {
            handle.position.copy(this.worldPositionStart);
          } else {
            handle.position.copy(this.worldPosition);
          }

          if (this.axis) {
            handle.visible = this.axis.search(handle.name) !== -1;
          }
        }

        // If updating helper, skip rest of the loop
        continue;
      }

      // Align handles to current local or world rotation

      handle.quaternion.copy(quaternion);

      if (this.mode === "translate" || this.mode === "scale") {
        // Hide translate and scale axis facing the camera

        const AXIS_HIDE_TRESHOLD = 0.99;
        const PLANE_HIDE_TRESHOLD = 0.2;
        const AXIS_FLIP_TRESHOLD = 0.0;

        if (handle.name === "X" || handle.name === "XYZX") {
          if (
            Math.abs(
              this.alignVector
                .copy(this.unitX)
                .applyQuaternion(quaternion)
                .dot(this.eye)
            ) > AXIS_HIDE_TRESHOLD
          ) {
            handle.scale.set(1e-10, 1e-10, 1e-10);
            handle.visible = false;
          }
        }

        if (handle.name === "Y" || handle.name === "XYZY") {
          if (
            Math.abs(
              this.alignVector
                .copy(this.unitY)
                .applyQuaternion(quaternion)
                .dot(this.eye)
            ) > AXIS_HIDE_TRESHOLD
          ) {
            handle.scale.set(1e-10, 1e-10, 1e-10);
            handle.visible = false;
          }
        }

        if (handle.name === "Z" || handle.name === "XYZZ") {
          if (
            Math.abs(
              this.alignVector
                .copy(this.unitZ)
                .applyQuaternion(quaternion)
                .dot(this.eye)
            ) > AXIS_HIDE_TRESHOLD
          ) {
            handle.scale.set(1e-10, 1e-10, 1e-10);
            handle.visible = false;
          }
        }

        if (handle.name === "XY") {
          if (
            Math.abs(
              this.alignVector
                .copy(this.unitZ)
                .applyQuaternion(quaternion)
                .dot(this.eye)
            ) < PLANE_HIDE_TRESHOLD
          ) {
            handle.scale.set(1e-10, 1e-10, 1e-10);
            handle.visible = false;
          }
        }

        if (handle.name === "YZ") {
          if (
            Math.abs(
              this.alignVector
                .copy(this.unitX)
                .applyQuaternion(quaternion)
                .dot(this.eye)
            ) < PLANE_HIDE_TRESHOLD
          ) {
            handle.scale.set(1e-10, 1e-10, 1e-10);
            handle.visible = false;
          }
        }

        if (handle.name === "XZ") {
          if (
            Math.abs(
              this.alignVector
                .copy(this.unitY)
                .applyQuaternion(quaternion)
                .dot(this.eye)
            ) < PLANE_HIDE_TRESHOLD
          ) {
            handle.scale.set(1e-10, 1e-10, 1e-10);
            handle.visible = false;
          }
        }

        // Flip translate and scale axis ocluded behind another axis
        if (handle.name.search("X") !== -1) {
          if (
            this.alignVector
              .copy(this.unitX)
              .applyQuaternion(quaternion)
              .dot(this.eye) < AXIS_FLIP_TRESHOLD
          ) {
            if (handle.tag === "fwd") {
              handle.visible = false;
            } else {
              handle.scale.x *= -1;
            }
          } else if (handle.tag === "bwd") {
            handle.visible = false;
          }
        }

        if (handle.name.search("Y") !== -1) {
          if (
            this.alignVector
              .copy(this.unitY)
              .applyQuaternion(quaternion)
              .dot(this.eye) < AXIS_FLIP_TRESHOLD
          ) {
            if (handle.tag === "fwd") {
              handle.visible = false;
            } else {
              handle.scale.y *= -1;
            }
          } else if (handle.tag === "bwd") {
            handle.visible = false;
          }
        }

        if (handle.name.search("Z") !== -1) {
          if (
            this.alignVector
              .copy(this.unitZ)
              .applyQuaternion(quaternion)
              .dot(this.eye) < AXIS_FLIP_TRESHOLD
          ) {
            if (handle.tag === "fwd") {
              handle.visible = false;
            } else {
              handle.scale.z *= -1;
            }
          } else if (handle.tag === "bwd") {
            handle.visible = false;
          }
        }
      } else if (this.mode === "rotate") {
        // Align handles to current local or world rotation
        this.tempQuaternion2.copy(quaternion);
        this.alignVector
          .copy(this.eye)
          .applyQuaternion(this.tempQuaternion.copy(quaternion).invert());

        if (handle.name.search("E") !== -1) {
          handle.quaternion.setFromRotationMatrix(
            this.lookAtMatrix.lookAt(this.eye, this.zeroVector, this.unitY)
          );
        }

        if (handle.name === "X") {
          this.tempQuaternion.setFromAxisAngle(
            this.unitX,
            Math.atan2(-this.alignVector.y, this.alignVector.z)
          );
          this.tempQuaternion.multiplyQuaternions(
            this.tempQuaternion2,
            this.tempQuaternion
          );
          handle.quaternion.copy(this.tempQuaternion);
        }

        if (handle.name === "Y") {
          this.tempQuaternion.setFromAxisAngle(
            this.unitY,
            Math.atan2(this.alignVector.x, this.alignVector.z)
          );
          this.tempQuaternion.multiplyQuaternions(
            this.tempQuaternion2,
            this.tempQuaternion
          );
          handle.quaternion.copy(this.tempQuaternion);
        }

        if (handle.name === "Z") {
          this.tempQuaternion.setFromAxisAngle(
            this.unitZ,
            Math.atan2(this.alignVector.y, this.alignVector.x)
          );
          this.tempQuaternion.multiplyQuaternions(
            this.tempQuaternion2,
            this.tempQuaternion
          );
          handle.quaternion.copy(this.tempQuaternion);
        }
      }

      // Hide disabled axes
      handle.visible = handle.visible && (handle.name.indexOf("X") === -1 || this.showX);
      handle.visible = handle.visible && (handle.name.indexOf("Y") === -1 || this.showY);
      handle.visible = handle.visible && (handle.name.indexOf("Z") === -1 || this.showZ);
      handle.visible = handle.visible && (handle.name.indexOf("E") === -1 || (this.showX && this.showY && this.showZ));

      // Highlight selected axis
      (handle as any).material.tempOpacity = (handle as any).material.tempOpacity || (handle as any).material.opacity;
      (handle as any).material.tempColor = (handle as any).material.tempColor || (handle as any).material.color.clone();
      (handle as any).material.color.copy((handle as any).material.tempColor);
      (handle as any).material.opacity = (handle as any).material.tempOpacity;


      // Locked then grey out
      if (this.locked) {
        (handle as any).material.color.set(this.getAxisColor(this.mode, this.axis as Axis, true));
      } else {
        let color = (handle as any).material.color;
        // If the gizmo is not locked but the color is LOCKED_COLOR then reset to initial values
        // This bug appears to have been fixed by setting the material colors in setLockColors method, leaving in case it resufaces this is a fail safe
        // if (color.getHex() === LOCKED_COLOR) {
        //   this.initialiseTranslateGizmoMaterials();
        //   this.initialiseRotateGizmoMaterials();
        //   this.initialiseRotateGizmoMaterials();
        // }
      }

      // Handle is of type Object3D, and both Line2 and Mesh extend from Object 3D, so .material will always be there
      if (!this.enabled) {
        (handle as any).material.opacity = 0.1;
        (handle as any).material.color.lerp(new Color(1, 1, 1), 0);
      } else if (this.axis) { // From the parent TransformControls, this is the intersection axis
        // console.log('ENABLED HANDLE AXIS?', this.axis)
        if (handle.name === this.axis) {
          //* HANDLE ACTIVE AXIS
          (handle as any).material.opacity = 1.0;
          (handle as any).material.color.set(this.getAxisColor(this.mode, this.axis as Axis, true));
        } else {
          //* HANDLE INACTIVE AXIS
          // console.log('HANDLE TO HIDE:: ', handle);
          // console.log('MODE WE ARE IN?? ', this.mode);
          (handle as any).material.color.set(this.getAxisColor(this.mode, handle.name as Axis, false));
        }
      }
    }
    
    super.updateMatrixWorld();
  };
}
