import { Settings } from "../specs";
import { IProgressCallback, IProgressStatus } from "../zml/types";
import { RedirectOAuth } from "../oauth/redirect"
import { BaseClient } from "./base"
import { prodEnvSettings } from "../settings"
import { ZmlClient } from '../zml/client'


export class BrowserClient extends BaseClient<RedirectOAuth> {
  constructor(settings: Settings) {
    super(
      settings,
      new RedirectOAuth(prodEnvSettings.url, settings.clientId, (settings.redirectURI as string))
    )
  }

  requestWithProgress(url: string, body?: string | FormData, progressCb?: IProgressCallback, requestId: number = 0) {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest(); // fetch API has no progress eventlistener
      if (progressCb) {
        xhr.upload.onprogress = e => progressCb(Math.round((e.loaded / e.total) * 100), {status: IProgressStatus.uploading, index: requestId});
      }
      xhr.onload = () => {
        if (xhr.status < 300) {
          return resolve(xhr.response)
        }
        console.error(xhr.status, url)
        throw xhr.statusText
      }
      xhr.open('POST', url, true);
      xhr.send(body);
    })
  }
}

export class BrowserZmlClient extends ZmlClient<RedirectOAuth> {

}
