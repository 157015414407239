import { Color, SRGBColorSpace, TextureLoader, Vector2, Vector3 } from 'three';
import { IEmitterGroupSettings, IEmitterSettings, IVector3 } from '../../component-data-structure';
import { IEmitterOptions, IGroupOptions } from '../../threejs-particle-system/src/v1/core/types';

export const convertDocToThreeEmitterSettings = (settings: Partial<IEmitterSettings>): IEmitterOptions => {
	const newSettings = JSON.parse(JSON.stringify(settings)) as IEmitterOptions;
	for (const k in newSettings) {
		const key = k as keyof IEmitterSettings;
		if (key === 'position' || key === 'velocity' || key === 'rotation' || key === 'acceleration') {
			const propertySettings = newSettings[key];
			if (!propertySettings) continue;
			for (const k in propertySettings) {
				const key = k as keyof typeof propertySettings;
				if (Array.isArray(propertySettings[key])) {
					(propertySettings[key] as any) = new Vector3(...propertySettings[key]);
				}
			}
		}
		if (key === 'color') {
			const colorSettings = newSettings[key] as IEmitterSettings['color'];
			const value = colorSettings?.value;
			const spread = colorSettings?.spread;
			if (value) {
				colorSettings['value'] = Array.isArray(value) ? value.map((col) => new Color(col).convertSRGBToLinear()) : new Color(value).convertSRGBToLinear();
			}
			if (spread) {
				(colorSettings['spread'] as any) = Array.isArray(spread[0]) ? (spread as IVector3[]).map((col) => new Vector3(...col)) : new Vector3(...(spread as IVector3));
			}
		}
	}
	return newSettings;
};

export const convertDocToThreeEmitterGroupSettings = (settings: Partial<IEmitterGroupSettings>): IGroupOptions => {
	const newSettings = JSON.parse(JSON.stringify(settings)) as IGroupOptions;
	if (newSettings.texture?.frames) {
		newSettings.texture.frames = new Vector2(...(newSettings.texture.frames as any));
	}
	if (newSettings.texture?.value) {
		newSettings.texture.value = new TextureLoader().load(newSettings.texture.value as any);
		newSettings.texture.value.colorSpace = SRGBColorSpace;
	}
	return newSettings;
};
