import React from "react";
import { IIconParentProps } from "./index";
import { COLORS } from "../../utils";

const Scene16: React.FunctionComponent<IIconParentProps> = ({ width, height, className, hexFill = COLORS.midnight, viewBox, onClick = () => {} }) => {
  return (
    <svg
      onClick={onClick}
      className={className || ""}
      width={width ? width : "16px"}
      height={height ? height : "16px"}
      viewBox={viewBox ? viewBox.join(" ") : `0 0 16 16`}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <path
        d="M15.1997 1.09236L15.8662 3.0832L1.124 7.2024L14.2661 7.2026C15.1497 7.2026 15.8661 7.91895 15.8661 8.8026V14.4026C15.8661 15.2863 15.1497 16.0026 14.2661 16.0026H2.26606C1.3824 16.0026 0.666057 15.2863 0.666057 14.4026L0.6656 7.2256L0.083214 5.48507C-0.197335 4.64713 0.254518 3.74042 1.09246 3.45987L1.16976 3.43614L13.2518 0.0593925C14.0726 -0.170004 14.9291 0.28422 15.1997 1.09236ZM14.2661 8.0026H1.46535L1.46606 14.4026C1.46606 14.8129 1.77489 15.151 2.17276 15.1972L2.26606 15.2026H14.2661C14.6763 15.2026 15.0145 14.8938 15.0607 14.4959L15.0661 14.4026V8.8026C15.0661 8.39233 14.7572 8.0542 14.3594 8.00799L14.2661 8.0026ZM3.272 3.68L1.34645 4.21848C0.957403 4.34873 0.73481 4.74894 0.817307 5.1409L0.841824 5.23108L1.21463 6.34684L2.4968 5.988L3.272 3.68ZM6.5192 2.772L4.2032 3.4192L3.4288 5.728L5.7408 5.0816L6.5192 2.772ZM9.756 1.8672L7.4512 2.5112L6.672 4.8216L8.9872 4.1744L9.756 1.8672ZM12.9904 0.9624L10.6864 1.6064L9.916 3.9144L12.2216 3.2704L12.9904 0.9624ZM13.8807 0.825196L13.1512 3.0112L14.8399 2.53921L14.4411 1.34635C14.3519 1.08002 14.1355 0.890567 13.8807 0.825196Z"
        fill={hexFill}
      />
    </svg>
  );
};

export default Scene16;
