// Environment settings
import * as zw from '@zw-api-client/src/browser';

declare global {
	interface Window {
		VERSION: string;
		_env: {
			ENVIRONMENT: string;
			BASE_URL: string;
			SENTRY_DSN: string;
			ZW_ENV: string;
			ZW_BASE_URL: string;
			ZW_CLIENT_ID: string;
			CDS_URL: string;
		};
	}
}

export const VERSION = window.VERSION;
export const ENVIRONMENT = window._env.ENVIRONMENT;
export const BASE_URL = window._env.BASE_URL;
export const DEBUG = ['local', 'development'].includes(ENVIRONMENT);

// Sentry
export const SENTRY_DSN = window._env.SENTRY_DSN;

// ZapWorks CMS
export const ZW_ENV = (window._env.ZW_ENV || zw.Environment.Dev).toUpperCase() as zw.Environment;
export const ZW_BASE_URL = window._env.ZW_BASE_URL;
export const ZW_CLIENT_ID = window._env.ZW_CLIENT_ID;

// CDS
export const CDS_URL = window._env.CDS_URL;

// Jute
const qs = new URLSearchParams(window.location.search)
if (qs.get('jute')) sessionStorage.setItem('jute-version', qs.get('jute') as string);

export const JUTE_VERSION = sessionStorage.getItem('jute-version') || '22.0';

export const DEFAULT_LAUNCH_SCREEN = {
    id: 'lite-branded',
    title: 'Lite Branded',
    fields: {
		title: { type: 'text', title: 'Project title', value: '', priority: 1 },
        iconUrl: { type: 'file', title: 'Launch screen icon', value: '', priority: 0 }
    }
}
