// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".GhostBtn--1GjCX{border-radius:8px;border:solid 1px #4a90e2;background-color:rgba(0,0,0,0);color:#4a90e2;cursor:pointer}.GhostBtn--1GjCX:disabled{opacity:.5;cursor:default}", ""]);
// Exports
exports.locals = {
	"GhostBtn": "GhostBtn--1GjCX"
};
module.exports = exports;
