import * as zw from '@zw-api-client/src/browser';
import * as settings from '../settings';

export interface ISyncDocUser {
	id: string;
	name: string;
	email?: string;
	avatarUrl: string | null;
}

export const zwClient = new zw.Client({
    clientId: settings.ZW_CLIENT_ID,
    redirectURI: `${settings.BASE_URL}callback/`,
    env: settings.ZW_ENV,
    debug: settings.DEBUG
});

// (window as any).zwClient = zwClient;
