export enum ITransitionCategoryTypes {
	enter = 'enter',
	exit = 'exit',
}

export enum ITransitionEffectTypes {
	noEffect = 'noEffect',
	right = 'right',
	left = 'left',
	up = 'up',
	down = 'down',
	fadeIn = 'fadeIn',
	fadeOut = 'fadeOut',
	scaleUp = 'scaleUp',
	scaleDown = 'scaleDown',
	spin = 'spin',
}

export interface ITransitionData {
	type: ITransitionEffectTypes;
	duration: number;
	delay: number;
}

export enum ITransitionTargetTypes {
	scenes = 'scenes',
	entities = 'entities',
}

export type ITransitionOptions = {
	[ITransitionCategoryTypes.enter]?: ITransitionData;
	[ITransitionCategoryTypes.exit]?: ITransitionData;
};

export interface ITransitionDict {
	[id: string]: ITransitionOptions;
}
