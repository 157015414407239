import React from "react";
import { IIconParentProps } from "./index";
import { COLORS } from "../../utils";

const Hash16: React.FunctionComponent<IIconParentProps> = ({ width, height, className, hexFill = COLORS.midnight, viewBox, onClick = () => {} }) => {
  return (
    <svg
      onClick={onClick}
      className={className || ""}
      width={width ? width : "16px"}
      height={height ? height : "16px"}
      viewBox={viewBox ? viewBox.join(" ") : `0 0 16 16`}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <path
        d="M4.21395 16C4.3421 16 4.45517 15.9661 4.55317 15.8982C4.65117 15.8304 4.71525 15.7135 4.7454 15.5477L5.70653 10.9908H9.07614L8.11501 15.5025C8.07732 15.6532 8.09428 15.7739 8.16589 15.8643C8.23751 15.9548 8.33739 16 8.46554 16H8.91784L9.98073 10.9908H12.932L12.9772 10.6516C12.9998 10.4857 12.981 10.3689 12.9207 10.3011C12.8604 10.2332 12.7699 10.1993 12.6493 10.1993H10.1051L11.0323 5.80071H13.1129C13.2712 5.80071 13.3899 5.77055 13.4691 5.71025C13.5482 5.64994 13.5953 5.55571 13.6104 5.42756L13.6783 4.99788H11.1567L12.1291 0.474912C12.1593 0.324146 12.1405 0.207303 12.0726 0.124382C12.0048 0.0414605 11.9068 0 11.7786 0H11.3037L10.2521 4.99788H6.8825L7.93409 0H7.47049C7.3348 0 7.21984 0.0358068 7.12561 0.10742C7.03138 0.179034 6.97296 0.286455 6.95035 0.429682L5.97791 4.99788H3.55812C3.24905 4.99788 3.07944 5.12226 3.04929 5.37102L2.98144 5.80071H5.85353L4.92632 10.1993H2.44999L2.40476 10.5272C2.38969 10.7006 2.41042 10.8212 2.46695 10.889C2.52349 10.9569 2.61583 10.9908 2.74399 10.9908H4.80194L3.73904 16H4.21395ZM9.20052 10.1993H5.83091L6.75812 5.80071H10.1277L9.20052 10.1993Z"
        fill={hexFill}
      />
    </svg>
  );
};

export default Hash16;
