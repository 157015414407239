import React from 'react';
import { COLORS } from '../../utils';
import { IIconParentProps } from './index';

const Camera16: React.FunctionComponent<IIconParentProps> = ({ width, height, className, hexFill = COLORS.midnight, viewBox, onClick = () => {} }) => {
	return (
		<svg
			onClick={onClick}
			className={className || ''}
			width={width ? width : '16px'}
			height={height ? height : '16px'}
			viewBox={viewBox ? viewBox.join(' ') : `0 0 16 16`}
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M1.6 4.79998H4.02462L4.47313 3.00595C4.56216 2.64981 4.88215 2.39998 5.24924 2.39998H10.7508C11.1179 2.39998 11.4378 2.64981 11.5269 3.00595L11.9754 4.79998H14.4C14.8418 4.79998 15.2 5.15815 15.2 5.59998V12.8C15.2 13.2418 14.8418 13.6 14.4 13.6H1.6C1.15817 13.6 0.8 13.2418 0.8 12.8V5.59998C0.8 5.15815 1.15817 4.79998 1.6 4.79998ZM5.24924 1.59998C4.51505 1.59998 3.87508 2.09965 3.69701 2.81192L3.4 3.99998H1.6C0.716344 3.99998 0 4.71632 0 5.59998V12.8C0 13.6836 0.716345 14.4 1.6 14.4H14.4C15.2837 14.4 16 13.6836 16 12.8V5.59998C16 4.71632 15.2837 3.99998 14.4 3.99998H12.6L12.303 2.81192C12.1249 2.09965 11.4849 1.59998 10.7508 1.59998H5.24924ZM10.4 8.79998C10.4 10.1255 9.32548 11.2 8 11.2C6.67452 11.2 5.6 10.1255 5.6 8.79998C5.6 7.47449 6.67452 6.39998 8 6.39998C9.32548 6.39998 10.4 7.47449 10.4 8.79998ZM11.2 8.79998C11.2 10.5673 9.76731 12 8 12C6.23269 12 4.8 10.5673 4.8 8.79998C4.8 7.03266 6.23269 5.59998 8 5.59998C9.76731 5.59998 11.2 7.03266 11.2 8.79998Z"
				fill={hexFill}
			/>
		</svg>
	);
};

export default Camera16;
