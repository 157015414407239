import React from 'react';

export const useRefState = <T extends unknown>(
	initialValue: T
): [T, (value: T) => void, React.MutableRefObject<T>] => {
	const [state, setState] = React.useState(initialValue);
	const reference = React.useRef(state);

	const setReferredState = (value: T) => {
		reference.current = value;
		setState(value);
	};

	return [reference.current as T, setReferredState, reference];
};
