import React, { FunctionComponent, memo, useMemo } from 'react';
import { ITuple3, ITuple4, IFontTypes, ITextAlignment, ITextReactProps } from '../component-data-structure';
import { IUserData } from '../utils/general';
import CurvedEntity from './CurvedEntity/CurvedEntity';

const TextComp: FunctionComponent<ITextReactProps> = ({
	scale: s,
	color,
	text,
	fontFamily = IFontTypes.Roboto400,
	fontSize = 2,
	fontRgba,
	position,
	rotation,
	textAlignment = ITextAlignment.left,
	onPointerUp,
	onPointerMove,
	onPointerDown,
	onDoubleClick,
	renderOrder,
	curvature,
	id = '',
	raycast,
	castShadow = false,
	receiveShadow = false,
	animationTransformGroupPrefix = ''
}) => {
	const scale = useMemo(() => s as ITuple3, [s]);
	const zeroPos = useMemo(() => [0, 0, 0], []);
	const userData: IUserData = useMemo(() => ({ renderOrder, contentId: id }), [id, renderOrder]);

	return (
		<group
			key={'btn_group'}
			userData={userData}
			name={`${animationTransformGroupPrefix}${id}`}
			raycast={raycast}
			position={position as ITuple3}
			rotation={rotation as ITuple3}
			onPointerUp={onPointerUp || undefined}
			onPointerDown={onPointerDown || undefined}
			onPointerMove={onPointerMove || undefined}
			onDoubleClick={onDoubleClick || undefined}
		>
			<group name={`${animationTransformGroupPrefix}inner_${id}`}>
				<CurvedEntity
					raycast={raycast}
					key={id}
					scale={scale as ITuple3}
					cornerRadius={0}
					position={zeroPos as ITuple3}
					renderOrder={renderOrder}
					opacity={color[3]}
					curvature={curvature}
					colorRgba={color as ITuple4}
					castShadow={castShadow}
					receiveShadow={receiveShadow}
					text={{
						value: text,
						textAlignment,
						fontSize,
						fontFamily,
						fontRgba: fontRgba as ITuple4,
						preventPolygonOffset: true
					}}
				/>
			</group>
		</group>
	);
};

export default memo(TextComp);
