import { ThreeEvent } from '@react-three/fiber';
import React from 'react';
import { useSelector } from 'react-redux';
import { IDesignerState } from '../../../../typings';
import { HOTSPOT_SCALE } from '../../../utils';
import Plane from '../Plane/Plane';

interface IParentProps {
	onPointerMove?: (e: ThreeEvent<PointerEvent>) => void;
	onPointerUp?: (e: ThreeEvent<PointerEvent>) => void;
}

const SelectedEntityPositionHotspot: React.FunctionComponent<IParentProps> = ({ onPointerMove, onPointerUp }) => {
	const is3dMode = useSelector((state: IDesignerState) => state.userReducer.is3dMode);
	const isScreenRelativeMode = useSelector((state: IDesignerState) => state.userReducer.isScreenRelativeMode);
	return !is3dMode || isScreenRelativeMode ? (
		<Plane visible={false} color={[255, 0, 0, 0.2]} enabled rotation={[0, 0, 0]} position={[0, 0, 0]} scale={HOTSPOT_SCALE} pointerMoveHandler={onPointerMove} pointerUpHandler={onPointerUp} />
	) : null;
};

export default SelectedEntityPositionHotspot;
