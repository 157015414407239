import { ISocialProvider } from "../components/r3f/r3f-components/component-data-structure/index";


export const socialRegex = {
	[ISocialProvider.facebook]: /(?:(?:http|https):\/\/)?(?:www.)?(?:facebook.com|fb.com)(?:(?:\w)*#!\/)?(?:pages\/)?(?:[?\w\-]*\/)?(?:profile.php\?id=(?=\d.*))?([\w\-]*)?/, // eslint-disable-line
	[ISocialProvider.youtube]: /(?:(?:http|https):\/\/)?(?:www.)?(?:youtube.com)(?:\/user\/|\/channel\/)?(?:(?:\w)*#!\/)?([\w\-]*)?/, // eslint-disable-line
	[ISocialProvider.reddit]: /(?:(?:http|https):\/\/)?(?:(?:www.)?(?:(?:(?:(?:reddit.com)(?:\/user|\/channel))|(?:^u|^r))(?:\/)?(?:(?:\w)*#!\/)?([\w\-]*)|(?:reddit.com(?:\/)?)))/, // eslint-disable-line
	[ISocialProvider.tiktok]: /(?:(?:http|https):\/\/)?(?:www.)?(?:tiktok.com)(?:(?:\/@)(?:(?:\w)*#!\/)?([\w\-]*))?/, // eslint-disable-line
	[ISocialProvider.soundCloud]: /(?:(?:http|https):\/\/)?(?:www.)?(?:soundcloud.com)(?:(?:\/)(?:(?:\w)*#!\/)?([\w\-]*))?/, // eslint-disable-line
	[ISocialProvider.twitch]: /(?:(?:http|https):\/\/)?(?:www.)?(?:twitch.tv)\/?(?:team\/)?(?:(?:\w)*#!\/)?([\w\-]*$)?/, // eslint-disable-line
	[ISocialProvider.linkedIn]: /(?:(?:http|https):\/\/)?(?:www.)?(?:linkedin.com)(?:(?:\/in|\/company)(?:\/)(?:(?:\w)*#!\/)?([\w\-]*))?/, // eslint-disable-line
	[ISocialProvider.vimeo]: /(?:(?:http|https):\/\/)?(?:www.)?(?:vimeo.com)\/?(?:(?:\w)*#!\/)?([\w\-]*)?/, // eslint-disable-line
	[ISocialProvider.instagram]: /(?:(?:http|https):\/\/)?(?:www.)?(?:instagram.com)\/?(?:(?:\w)*#!\/)?([\w\-]*)?/, // eslint-disable-line
	[ISocialProvider.spotify]: /(?:(?:http|https):\/\/)?(?:open.spotify.com)\/?(?:(?:user|playlist)(?:\/)(?:(?:\w)*#!\/)?([\w\-]*))?/, // eslint-disable-line
	[ISocialProvider.twitter]: /(?:(?:http|https:))?(?:(?:(?:\/\/)?(?:www)?(?:twitter.com))|(?:\/\/)t.co|^t.co)(?:(?:\/)(?:(?:\w)*#!\/)?([\w\-]*))?/, // eslint-disable-line
	[ISocialProvider.custom]: /(?:(?:http|https):\/\/)?(?:www\.)?((?:[\w\-]{1,})\.[a-zA-Z0-9()]{1,6}\b[-a-zA-Z0-9()@:%\-_\+.~#?&\/=]*)/, // eslint-disable-line
};
