// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Outer--jEQCh{height:100vh;width:100vw;display:flex;justify-content:center;align-items:center}.Outer--jEQCh .BrowserError--2w5A1{width:530px;height:530px;box-shadow:0px 3px 6px rgba(0,0,0,.1607843137);border-radius:8px;padding:80px}.Outer--jEQCh .BrowserError--2w5A1 h1{text-rendering:optimizelegibility;font-family:\"Lato\",sans-serif;font-weight:300;color:#073158}.Outer--jEQCh .BrowserError--2w5A1 p{font-size:14px;line-height:normal}.Outer--jEQCh .BrowserError--2w5A1 .ZapworksLogo--5eKPg{width:200px}.Outer--jEQCh .BrowserError--2w5A1 .BrowserList--PJYYU{display:flex;margin:20px auto 0 auto}.Outer--jEQCh .BrowserError--2w5A1 .BrowserList--PJYYU div{width:150px;text-align:center}.Outer--jEQCh .BrowserError--2w5A1 .BrowserList--PJYYU div img{width:60px;margin:20px 0 20px 0}.Outer--jEQCh .BrowserError--2w5A1 .BrowserList--PJYYU div a,.Outer--jEQCh .BrowserError--2w5A1 .BrowserList--PJYYU div a:visited{color:#344b60;text-decoration:none}.Outer--jEQCh .BrowserError--2w5A1 .BrowserList--PJYYU div a:hover{color:#4a90e2}", ""]);
// Exports
exports.locals = {
	"Outer": "Outer--jEQCh",
	"BrowserError": "BrowserError--2w5A1",
	"ZapworksLogo": "ZapworksLogo--5eKPg",
	"BrowserList": "BrowserList--PJYYU"
};
module.exports = exports;
