import React, { FunctionComponent } from 'react';
import { COLORS } from '../../utils';
import { IIconParentProps } from './index';

const textAlignCenter: FunctionComponent<IIconParentProps> = ({ className, hexFill: fill, viewBox, onClick = () => {} }) => {
	const hexFill = fill || COLORS.midnight;
	return (
		<svg
			onClick={onClick}
			className={className || ''}
			width="20px"
			height="20px"
			viewBox={viewBox ? viewBox.join(' ') : '0 0 20 20'}
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M1 0.5C0.723858 0.5 0.5 0.723858 0.5 1C0.5 1.27614 0.723858 1.5 1 1.5H19C19.2761 1.5 19.5 1.27614 19.5 1C19.5 0.723858 19.2761 0.5 19 0.5H1ZM1 11.5C0.723858 11.5 0.5 11.7239 0.5 12C0.5 12.2761 0.723858 12.5 1 12.5H19C19.2761 12.5 19.5 12.2761 19.5 12C19.5 11.7239 19.2761 11.5 19 11.5H1ZM0.5 7C0.5 6.72386 0.723858 6.5 1 6.5H13C13.2761 6.5 13.5 6.72386 13.5 7C13.5 7.27614 13.2761 7.5 13 7.5H1C0.723858 7.5 0.5 7.27614 0.5 7ZM1 17.5C0.723858 17.5 0.5 17.7239 0.5 18C0.5 18.2761 0.723858 18.5 1 18.5H13C13.2761 18.5 13.5 18.2761 13.5 18C13.5 17.7239 13.2761 17.5 13 17.5H1Z"
				fill={hexFill}
			/>
		</svg>
	);
};

export default textAlignCenter;
