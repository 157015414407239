import { Euler, useThree, ThreeEvent } from '@react-three/fiber';
import React, { useState, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Object3D, Vector3 } from 'three';
import { IDesignerState } from '../../../../typings';
import { onSetEntityMenuDragActive, onSetEntityMenuHotspotDragPosition, onSetDraggedEntityInfo, onAddEntity_Global, onSetActiveFaceLandmark, onSetShowFacelandmarks } from '../../../store/actions';
import {
	getHighestActiveRenderOrder,
	HOTSPOT_SCALE,
	useActiveSceneEntityTitles,
	finaliseEntity,
	useIsFlatOrientation,
	maths,
	useGetActiveSceneTrackingType,
	getParentIdForAddEntity,
	DEFAULT_FACE_LANDMARK,
	getModel3dAnimations,
	useIsCurvedImageTrackedScene,
	getInitialZPos
} from '../../../utils';
import { getInitialRotationAndZPositionIn360Scene } from '../../../utils/content360';
import { ITuple3, ITuple2, IComponentType, IButtonCategory, IScreenAnchorPositionType } from '../../r3f/r3f-components/component-data-structure';
import DragElement from '../DragElement/DragElement';
import Plane from '../Plane/Plane';
import { ITrackingTypes } from '../r3f-components/component-data-structure';
import { screenRelativeCamera } from '../ScreenRelativeCanvas/ScreenRelativeCanvas';
import { convertPxToCanvasNormalisedCoords, getArCanvasDomEl, getSrCanvasDomEl, isOutsideDomRect } from './hotspotUtils';

const { vec3 } = maths;

const EntityMenuDragHotspot: React.FunctionComponent = () => {
	const dispatch = useDispatch();
	const hasRunRef = useRef(false);
	const pointerUpHasRunRef = useRef(false);
	const { scene } = useThree();
	const entity = useSelector((state: IDesignerState) => state.userReducer.entityDragInfo || null);
	const entityMenuDragActive = useSelector((state: IDesignerState) => state.userReducer.entityMenuDragActive);	
	const activeSceneId = useSelector((state: IDesignerState) => state.userReducer.activeSceneId!);
	const componentsById = useSelector((state: IDesignerState) => state.contentReducer.contentDoc.componentsById);
	const entityTitles = useActiveSceneEntityTitles();
	const activeFaceLandmarkType = useSelector((state: IDesignerState) => state.userReducer.activeFaceLandmark);
	const sceneTrackingType = useGetActiveSceneTrackingType();
	const isFlatOrientation = useIsFlatOrientation();
	const hotspotRotation = (isFlatOrientation && sceneTrackingType === ITrackingTypes.image ? [Math.PI / 2, 0, 0] : [0, 0, 0]) as Euler;
	const { raycaster, camera: arCamera } = useThree();
	const isScreenRelativeMode = useSelector((state: IDesignerState) => state.userReducer.isScreenRelativeMode);
	const isCurvedImageTrackedScene = useIsCurvedImageTrackedScene();
	const curvedTrackingRadius = useSelector((state: IDesignerState) => state.contentReducer.contentDoc?.tracking?.[ITrackingTypes.image]?.topRadius || 0);
	const activeTrackingType = useGetActiveSceneTrackingType();
	// Folloging line required to trigger a re-render when re-center button is clicked to make lookAt work
	const dragControlsRenderTrigger = useSelector((state: IDesignerState) => state.userReducer.forceUpdateDragControlsTrigger);

	const [hotspotObject, setHotspotObject] = useState<Object3D>();
	const [coords, setCoords] = useState<ITuple3>();
	const arCanvasEl = getArCanvasDomEl();
	const srCanvasEl = getSrCanvasDomEl();

	const threeLandmarkHotspot = activeFaceLandmarkType ? scene.getObjectByName(activeFaceLandmarkType)! : null;
	
	// This is required to adjust the drop position to match the drag element shown on screen ( not pointer position )
	const dropOffset = useMemo(() => {
		if (entity?.clickOffset && entity.dimensions) {
			return [entity?.clickOffset[0] - entity?.dimensions[0] / 2, entity?.clickOffset[1] - entity?.dimensions[1] / 2] as ITuple2;
		} else {
			return [0, 0] as ITuple2;
		}
	}, [entity]);

	const removeDragSetup = () => {
		dispatch(onSetDraggedEntityInfo(null));
		dispatch(onSetEntityMenuHotspotDragPosition(null));
		dispatch(onSetEntityMenuDragActive(false));
		hasRunRef.current = false;
	};

	const onPointerMoveHandler = (e: ThreeEvent<PointerEvent>) => {
		if (!arCanvasEl) return;
		if (!hotspotObject || e.button === 2) return;
		pointerUpHasRunRef.current = false;

	

		const newCoords = convertPxToCanvasNormalisedCoords(e.clientX, e.clientY, arCanvasEl, hotspotObject, arCamera, raycaster, entity?.clickOffset);
		if (newCoords) {
			if (!hasRunRef.current) {
				hasRunRef.current = true;
				(e.target as Element).setPointerCapture(e.pointerId);
				dispatch(onSetShowFacelandmarks(true))
				if (sceneTrackingType === ITrackingTypes.face) dispatch(onSetActiveFaceLandmark(DEFAULT_FACE_LANDMARK))
			} else {
				if (coords && newCoords && vec3.equal(coords, newCoords)) return;
				setCoords(newCoords);
				dispatch(onSetEntityMenuHotspotDragPosition(newCoords));
			}
		}
	};

	const onPointerUp = async (e: ThreeEvent<PointerEvent>) => {
		(e.target as Element).releasePointerCapture(e.pointerId);

		dispatch(onSetShowFacelandmarks(false))

		// Don't drop if over the entity menu
		const entityMenu = document.getElementById('entityMenu');
		if (entityMenu && !isOutsideDomRect(e.clientX, e.clientY, entityMenu, 20)) {
			if (sceneTrackingType === ITrackingTypes.face) dispatch(onSetActiveFaceLandmark(null))
			removeDragSetup(); return;
		}

		// Don't drop if SR mode & outside device area
		if (isScreenRelativeMode && srCanvasEl && isOutsideDomRect(e.clientX, e.clientY, srCanvasEl, 20)) {
			removeDragSetup();
			return;
		}

		// Get AR / SR drop coords
		let coords: ITuple3 | undefined;
		if (isScreenRelativeMode && srCanvasEl && hotspotObject) {
			coords = convertPxToCanvasNormalisedCoords(e.clientX, e.clientY, srCanvasEl, hotspotObject, screenRelativeCamera, raycaster, dropOffset);
		}
		if (!isScreenRelativeMode && arCanvasEl && hotspotObject) {
			coords = convertPxToCanvasNormalisedCoords(e.clientX, e.clientY, arCanvasEl, hotspotObject, arCamera, raycaster, dropOffset);
		}

		// Add entity at coords location if successful raycast
		if (coords) {
			if (sceneTrackingType === ITrackingTypes.face && threeLandmarkHotspot && !isScreenRelativeMode) {
				coords = threeLandmarkHotspot.worldToLocal(new Vector3(...coords)).toArray() as ITuple3
			} 
			
			if (hasRunRef.current && entity) {
				let [x, y] = coords;
				const renderOrder = getHighestActiveRenderOrder(componentsById, activeSceneId, isScreenRelativeMode) + 1;
				const parentId = getParentIdForAddEntity({ activeSceneId, sceneTrackingType, isScreenRelativeMode, faceTrackingLandmark: activeFaceLandmarkType ?? undefined, screenAnchorPosition: entity.type === IComponentType.Button && entity.category === IButtonCategory.functional ? IScreenAnchorPositionType.bottomMiddle : undefined  });
				if (entity.type === IComponentType.Model3d && entity.isAnimated) entity.animations = await getModel3dAnimations(entity);
				
				let z = getInitialZPos({ curvedTrackingRadius, isCurvedImageTrackedScene, sceneTrackingType, isScreenRelativeMode, faceTrackingLandmark: activeFaceLandmarkType ?? undefined });
				let rotation: ITuple3 | undefined;
	
				if (sceneTrackingType === ITrackingTypes.content360 && !isScreenRelativeMode) {
					const data = getInitialRotationAndZPositionIn360Scene();
					if (!data) return;
					z = data.pos.z;
					x = data.pos.x;
					y = data.pos.y ;
					rotation = data.rotation
				}
			
				if (!pointerUpHasRunRef.current) {
					dispatch(
						onAddEntity_Global({
							parentId,
							entity: finaliseEntity({ entity, rotation, sceneTrackingType, isFlatOrientation, isCurvedImageTrackedScene, isScreenRelativeMode, entityTitles, renderOrder, x, y ,z }),
						})
					);
				}
				pointerUpHasRunRef.current = true;
			}
		}
		removeDragSetup();
	};

	if (!entity) return null;
	return (
		<group rotation={hotspotRotation}>
			{coords && <DragElement entityDragInfo={entity} position={[coords[0], coords[1], coords[2]]} />}
			<Plane
				lookAtCamera={isScreenRelativeMode && activeTrackingType !== ITrackingTypes.content360 ? false : true}
				revertAfterLookAt={true}
				positionInfrontOfCamera={activeTrackingType === ITrackingTypes.content360}
				visible={false}
				color={[255, 0, 0, 0.2]}
				onUpdate={(self) => {
					if (!hasRunRef.current) setHotspotObject(self);
				}}
				enabled={entityMenuDragActive}
				scale={HOTSPOT_SCALE}
				position={[0, 0, activeTrackingType !== ITrackingTypes.content360 ? 0.02 : -0.02]}
				rotation={[0, 0, 0]}
				pointerMoveHandler={onPointerMoveHandler}
				pointerUpHandler={onPointerUp}
			/>
		</group>
	);
};

export default React.memo(EntityMenuDragHotspot);
