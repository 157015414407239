import React from 'react';
import { IIconParentProps } from './index';
import { COLORS } from '../../utils';

const arrowUpIcon: React.SFC<IIconParentProps> = ({
	className,
	hexFill: fill,
	viewBox,
	onClick = () => {}
}) => {
	const hexFill = fill || COLORS.midnight;
	return (
    <svg
      onClick={onClick}
      className={className || ""}
      width="7"
      height="7"
      viewBox={viewBox ? viewBox.join(" ") : "0 0 7 7"}
      fill="none"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <path
        d="M0.350254 3.14875L3.14545 0.353554C3.19538 0.302603 3.25574 0.263049 3.32239 0.2376C3.38904 0.212151 3.4604 0.201409 3.53158 0.20611C3.60277 0.210811 3.6721 0.230843 3.73482 0.264836C3.79754 0.298828 3.85218 0.345976 3.89498 0.403051L6.623 3.1735C6.71677 3.26726 6.76944 3.39444 6.76944 3.52705C6.76944 3.65966 6.71677 3.78683 6.623 3.8806C6.57775 3.9268 6.52373 3.9635 6.46411 3.98856C6.4045 4.01361 6.34048 4.02652 6.27581 4.02652C6.21114 4.02652 6.14712 4.01361 6.08751 3.98856C6.02789 3.9635 5.97387 3.9268 5.92862 3.8806L3.49193 1.40714L1.05029 3.84878C0.954005 3.92105 0.834878 3.95615 0.714791 3.94764C0.594704 3.93913 0.481721 3.88757 0.396593 3.80244C0.311466 3.71732 0.259911 3.60433 0.251398 3.48425C0.242885 3.36416 0.277986 3.24503 0.350254 3.14875V3.14875Z"
        fill={hexFill}
      />
    </svg>
  );
};

export default arrowUpIcon;
