import React from 'react';
import { IIconParentProps } from './index';
import { COLORS } from '../../utils';

const fadeIcon: React.FunctionComponent<IIconParentProps> = ({
	className,
	hexFill: fill,
	secondHexFill: secondFill, 
	viewBox,
	onClick = () => {},
}) => {
	const hexFill = fill || COLORS.midnight;
	const secondHexFill = secondFill; 
	return (
		<svg
			onClick={onClick}
			className={className || ''}
			width="68px"
			height="68px"
			viewBox={viewBox ? viewBox.join(' ') : '0 0 68 68'}
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
		>
			<defs>
                <radialGradient cx="50%" cy="50%" fx="50%" fy="50%" r="46.1200419%" gradientTransform="translate(0.500000,0.500000),scale(1.000000,0.660377),scale(1.000000,2.233381),translate(-0.500000,-0.500000)" id="radialGradient-1">
                    <stop stopColor={hexFill} stopOpacity="0.584052666" offset="0%"></stop>
                    <stop stopColor={hexFill} stopOpacity="0.214488636" offset="100%"></stop>
                </radialGradient>
            </defs>
            <g id="Components" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Fade">
                    <rect id="Mask" x="0" y="0" width="68" height="68" rx="5"></rect>
                    <g id="Illustration" transform="translate(17.000000, 6.800000)" fill="url(#radialGradient-1)" fillRule="nonzero">
                        <polygon id="bolt" points="0 15.8148507 14.4328447 0 35 24.0755409 17.4993196 38.875647 26.7572612 53 0.430032272 38.6802808 13.18811 25.1959974"></polygon>
                    </g>
                </g>
            </g>
		</svg>
	);
};

export default fadeIcon;
