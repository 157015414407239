import React from 'react';
import { IIconParentProps } from './index';
import { COLORS } from '../../utils';

const playSelectedIcon: React.SFC<IIconParentProps> = ({
	className,
	hexFill: fill,
	viewBox,
	onClick = () => {},
}) => {
	const hexFill = fill || COLORS.midnight;
	return (
		<svg
			onClick={onClick}
			className={className || ''}
			width="32px"
			height="32px"
			viewBox={viewBox ? viewBox.join(' ') : '0 0 32 32'}
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
		>
			<g
				id="Components"
				stroke="none"
				strokeWidth="1"
				fill="none"
				fillRule="evenodd"
			>
				<g
					id="Atom/Icon/Controls/Play"
					transform="translate(-4.000000, -4.000000)"
				>
					<g id="Group-5-Copy" transform="translate(4.000000, 4.000000)">
						<g id="Group-15">
							<circle id="Oval" fill="#4A90E2" cx="16" cy="16" r="16"></circle>
							<path
								d="M18.6462556,10.0110385 C18.2522905,9.95292283 17.844905,10.1349427 17.6338144,10.5008329 L10.8769676,22.3476888 C10.8274366,22.6833494 10.9448685,22.993377 11.1585983,23.2071068 C11.3395627,23.3880712 11.5895627,23.5 11.865705,23.5 L25.4964603,23.4325431 C25.8119559,23.3077089 26.0218138,23.0510635 26.1001378,22.7591286 C26.1670831,22.5096053 26.1379363,22.2343011 25.9965275,21.993486 L18.6462556,10.0110385 Z"
								id="Triangle"
								stroke="#FFFFFF"
								transform="translate(18.500000, 16.500000) rotate(90.000000) translate(-18.500000, -16.500000) "
							></path>
						</g>
					</g>
				</g>
			</g>
		</svg>
	);
};

export default playSelectedIcon;
