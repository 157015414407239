import { MathUtils, Object3D, PerspectiveCamera } from "three";

export const rotateObject3DByDragInput = (d: {
    latRef: React.MutableRefObject<number>, 
    lonRef: React.MutableRefObject<number>,
    thetaRef: React.MutableRefObject<number>,
    phiRef: React.MutableRefObject<number>,
    disableVerticalDrag: boolean | undefined,
    object: Object3D
}) => {
    d.latRef.current = Math.max( - 85, Math.min( 85, d.latRef.current ) );
    d.phiRef.current = MathUtils.degToRad( 90 - d.latRef.current );
    d.thetaRef.current = MathUtils.degToRad( d.lonRef.current );

    const x = 5 * Math.sin( d.phiRef.current ) * Math.cos( d.thetaRef.current );
    const y = d.disableVerticalDrag ? 0 : 5 * Math.cos( d.phiRef.current );
    const z = 5 * Math.sin( d.phiRef.current ) * Math.sin( d.thetaRef.current );

    d.object.lookAt( x, y, z );
    d.object.updateMatrixWorld();

    if ((d.object as PerspectiveCamera).isCamera) {
        (d.object as PerspectiveCamera).updateProjectionMatrix()
    }
}