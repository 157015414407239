import { IComponentType, IComponentUnion, ISceneComp } from "../component-data-structure";

/**
 * Given a scene, returns the Id of that screen's ScreenContent component
 */
 export const getScreenContentIdForScene = (scene: ISceneComp, componentsById: { [k: string]: IComponentUnion }) => {
	const componentsByIdCopy = JSON.parse(JSON.stringify(componentsById));
	for (let i = 0; i < scene?.children?.length; i++) {
		const entity = componentsByIdCopy[scene?.children[i]];
		if (entity.type == IComponentType.ScreenContent) return scene?.children[i];
	}
};