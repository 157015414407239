import React from 'react';
import { IIconParentProps } from './index';
import { COLORS } from '../../utils';

const undoRedoIcon: React.FunctionComponent<IIconParentProps> = ({
	hexFill: fill,
	viewBox,
	className,
	onClick = () => {},
	title, 
}) => {
	const hexFill = fill || COLORS.midnight;
	return (
		<svg
			onClick={onClick}
			className={className || ''}
			width="23px"
			height="21px"
			viewBox={viewBox ? viewBox.join(' ') : `0 0 23 21`}
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
		>
			<title>{title}</title>
			<path
				fill={hexFill}
				d="M16.5627812,5.64814815 C20.8006053,5.64814815 23.7407407,9.08651044 23.7407407,13.6111111 C23.7407407,18.1243788 20.8083485,21.5185185 16.5627812,21.5185185 C16.2866388,21.5185185 16.0627812,21.2946609 16.0627812,21.0185185 C16.0627812,20.7423761 16.2866388,20.5185185 16.5627812,20.5185185 C20.2209351,20.5185185 22.7407407,17.6019331 22.7407407,13.6111111 C22.7407407,9.60709112 20.2105556,6.64814815 16.5627812,6.64814815 L2.82534206,6.64801392 L6.55079335,10.3744078 C6.72753288,10.5511473 6.7449451,10.8277229 6.61350439,11.0201303 L6.55725709,11.0879783 C6.36199495,11.2832405 6.04120231,11.2790303 5.84368657,11.0815146 L1.31942909,6.55725709 C1.18897969,6.4268077 1.14755911,6.24033132 1.19332859,6.07201624 C1.20767422,6.01741208 1.23095354,5.96517514 1.26318179,5.91799832 L1.31942909,5.85015031 L5.84368657,1.32589283 C6.0204261,1.1491533 6.29700169,1.13174108 6.48940909,1.26318179 L6.55725709,1.31942909 C6.75251924,1.51469123 6.7483091,1.83548387 6.55079335,2.03299961 L2.93534206,5.64801392 L16.5627812,5.64814815 L16.5627812,5.64814815 Z"
				transform="translate(-1 -1)"
			/>
		</svg>
	);
};

export default undoRedoIcon;
