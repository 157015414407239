// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".EditorCanvas--3VU0J{left:60px;width:calc(100% - 272px - 60px) !important;height:100%}", ""]);
// Exports
exports.locals = {
	"EditorCanvas": "EditorCanvas--3VU0J"
};
module.exports = exports;
