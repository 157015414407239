import * as Automerge from '@automerge/automerge';
import { Delta, diff } from 'jsondiffpatch';
import { IContentDoc } from '../components/r3f/r3f-components/component-data-structure';
import { SyncDocClient } from './doc';

export class History {
    private _historyStates: [Automerge.Doc<IContentDoc>, Automerge.Doc<IContentDoc>][] = [];
    private _nextHistoryCursor = 0;

    constructor(private _client: SyncDocClient<IContentDoc>) { }

    public change(fn: Automerge.ChangeFn<IContentDoc>) {
        if (this._nextHistoryCursor < this._historyStates.length) {
            this._historyStates.splice(this._nextHistoryCursor);
        }
        const before = JSON.parse(JSON.stringify(this._client.getDoc()));
        this._client.change(fn);
        this._historyStates.push([before, JSON.parse(JSON.stringify(this._client.getDoc()))]);
        this._nextHistoryCursor++;
    }

    public canUndo() {
        return this._nextHistoryCursor > 0 && Boolean(this._historyStates[Math.max(0, this._nextHistoryCursor - 1)])
    }

    public canRedo() {
        return this._nextHistoryCursor < this._historyStates.length && this._historyStates[this._nextHistoryCursor]
    }

    public undo() {
        this._nextHistoryCursor = Math.max(0, this._nextHistoryCursor - 1);

        const entry = this._historyStates[this._nextHistoryCursor];
        if (!entry) return;

        this._applyChange(entry[1], entry[0]);
    }

    public redo() {
        if (this._nextHistoryCursor >= this._historyStates.length) return;
        this._nextHistoryCursor++;

        const entry = this._historyStates[this._nextHistoryCursor - 1];
        if (!entry) return;

        this._applyChange(entry[0], entry[1]);
    }

    private _applyChange(before: Automerge.Doc<IContentDoc>, after: Automerge.Doc<IContentDoc>) {
        const d = diff(JSON.parse(JSON.stringify(before)), JSON.parse(JSON.stringify(after)));
        if (!d) return;
        try {
            this._client.change(draft => {
                if (!applyDelta(d, draft)) {
                    throw new Error('Unable to apply undo/redo patch');
                }
            });
        } catch (err) {
            console.warn('Undo/redo threw error', err);
        }
    }
}

function applyDelta(d: Delta, obj: any): boolean {
    if (typeof d !== 'object') return false;
    if (Array.isArray(obj) && d['_t'] === 'a') return applyArrayDelta(d, obj);
    else return applyObjectDelta(d, obj);
}

function applyObjectDelta(d: Delta, obj: any): boolean {
    for (const [key, val] of Object.entries(d)) {
        if (Array.isArray(val)) {
            if (val.length === 1) obj[key] = val[0];
            else if (val.length === 2) obj[key] = val[1];
            else if (val.length === 3 && val[1] === 0 && val[2] === 0) delete obj[key];
        } else if (typeof val === 'object') {
            if (!applyDelta(val, obj[key])) return false;
        } else return false;
    }
    return true;
}

function applyArrayDelta(d: Delta, obj: any[]): boolean {
    const toRemove: number[] = [];
    const toInsert: [number, any][] = [];
    const toModify: [number, any][] = [];
    for (const [key, val] of Object.entries(d)) {
        if (key === '_t') continue;
        if (!Array.isArray(val)) return false;
        if (key[0] === '_') {
            if (val[2] === 0 || val[2] === 3) toRemove.push(parseInt(key.slice(1)));
        } else {
            if (val.length === 1) toInsert.push([parseInt(key), val[0]]);
            else toModify.push([parseInt(key), val]);
        }
    }
    toRemove.sort();
    for (let i = toRemove.length - 1; i >= 0; i--) {
        const indx = toRemove[i];
        const indexDiff = d[`_${indx}`];
        const removed = obj.splice(indx, 1)[0];
        if (indexDiff[2] === 3) {
            toInsert.push([indexDiff[1], removed]);
        }
    }
    toInsert.sort((a, b) => a[0] - b[0]);
    for (let i = 0; i < toInsert.length; i++) {
        const insertion = toInsert[i];
        obj.splice(insertion[0], 0, insertion[1]);
    }
    for (let i = 0; i < toModify.length; i++) {
        const mod = toModify[i];
        if (!applyDelta(mod[1], obj[mod[0]])) return false;
    }
    return true;
}
