import React from 'react';
import { COLORS } from '../../utils';
import { IIconParentProps } from './index';

const View16: React.FunctionComponent<IIconParentProps> = ({ width, height, className, hexFill = COLORS.midnight, viewBox, onClick }) => {
	return (
		<svg
			onClick={onClick ?? undefined}
			className={className || ''}
			width={width ? width : '16px'}
			height={height ? height : '16px'}
			viewBox={viewBox ? viewBox.join(' ') : `0 0 20 20`}
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
		>
      <path d="M10 3C15 3 20 8 20 10C20 10.8 19.2 12.08 17.92 13.328L17.5592 13.6672C15.6364 15.4116 12.8182 17 10 17C5 17 0 12 0 10C0 8 5 3 10 3ZM10 4C6.13437 4 1 8.1075 1 10C1 11.8925 6.13437 16 10 16C13.8656 16 19 11.8925 19 10C19 8.1075 13.8656 4 10 4ZM11.1339 5.7L11.133 8.69926L14.1339 8.7V9.7C14.1339 11.9091 12.3431 13.7 10.1339 13.7C7.92478 13.7 6.13391 11.9091 6.13391 9.7C6.13391 7.49086 7.92478 5.7 10.1339 5.7H11.1339ZM10.1339 6.7L9.95764 6.70509C8.38283 6.79634 7.13391 8.10232 7.13391 9.7C7.13391 11.3569 8.47706 12.7 10.1339 12.7C11.7908 12.7 13.1339 11.3569 13.1339 9.7H10.1339V6.7Z" fill={hexFill} />
		</svg>
	);
};

export default View16;
