import React from 'react';
import { COLORS } from '../../utils';
import { IIconParentProps } from './index';

const curve3d: React.SFC<IIconParentProps> = ({ hexFill: fill, className }) => {
	const hexFill = fill || COLORS.midnight;
	return (
		<svg className={className} width="20px" height="20px" viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
			<g clipPath="url(#clip0_14335_80181)">
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M16.5891 15.5115C18.2608 14.5921 19 13.4842 19 12.4986C19 11.5131 18.2608 10.4052 16.5891 9.48575C15.3831 8.82241 13.8007 8.32875 12 8.11521V7.10865C16.5645 7.61824 20 9.83777 20 12.4986C20 15.5362 15.5228 17.9986 9.99999 17.9986C9.66249 17.9986 9.3289 17.9894 8.99999 17.9715V16.9699C9.32781 16.9889 9.66142 16.9986 9.99999 16.9986C12.6258 16.9986 14.9535 16.4111 16.5891 15.5115ZM3.41085 9.48575C2.01617 10.2528 1.27056 11.1511 1.06176 12.0001H0.0405273C0.438468 9.56423 3.7238 7.58606 7.99999 7.10865V8.11521C6.1993 8.32875 4.61693 8.82241 3.41085 9.48575Z"
					fill={hexFill}
				/>
				<path
					d="M9.06071 17.8302C8.86545 18.0255 8.54886 18.0255 8.3536 17.8302C8.15834 17.635 8.15834 17.3184 8.3536 17.1231L10.1214 15.3553C10.3166 15.1601 10.6332 15.1601 10.8285 15.3553C11.0237 15.5506 11.0237 15.8672 10.8285 16.0625L9.06071 17.8302Z"
					fill={hexFill}
				/>
				<path
					d="M8.3536 17.8302C8.15834 17.635 8.15834 17.3184 8.3536 17.1231C8.54886 16.9279 8.86545 16.9279 9.06071 17.1231L10.8285 18.8909C11.0237 19.0861 11.0237 19.4027 10.8285 19.598C10.6332 19.7933 10.3166 19.7933 10.1214 19.598L8.3536 17.8302Z"
					fill={hexFill}
				/>
				<path
					d="M9.64639 1.06077C9.45113 0.865506 9.45113 0.548923 9.64639 0.353661C9.84165 0.158399 10.1582 0.158399 10.3535 0.353661L12.1213 2.12143C12.3165 2.31669 12.3165 2.63327 12.1213 2.82853C11.926 3.0238 11.6094 3.0238 11.4142 2.82853L9.64639 1.06077Z"
					fill={hexFill}
				/>
				<path
					d="M9.49999 1.50005C9.49999 1.22391 9.72385 1.00005 9.99999 1.00005C10.2761 1.00005 10.5 1.22391 10.5 1.50005V11.5001C10.5 11.7762 10.2761 12.0001 9.99999 12.0001C9.72385 12.0001 9.49999 11.7762 9.49999 11.5001V1.50005Z"
					fill={hexFill}
				/>
				<path
					d="M9.64639 0.353661C9.84165 0.158399 10.1582 0.158399 10.3535 0.353661C10.5488 0.548923 10.5488 0.865506 10.3535 1.06077L8.58573 2.82853C8.39047 3.0238 8.07388 3.0238 7.87862 2.82853C7.68336 2.63327 7.68336 2.31669 7.87862 2.12143L9.64639 0.353661Z"
					fill={hexFill}
				/>
			</g>
			<defs>
				<clipPath id="clip0_14335_80181">
					<rect width="20" height="20" fill="white" />
				</clipPath>
			</defs>
		</svg>
	);
};

export default curve3d;
