import React from 'react';
import { IIconParentProps } from './index';
import { COLORS } from '../../utils';

const DirectionArrow: React.SFC<IIconParentProps> = ({
	className,
	hexFill: fill,
	viewBox,
	onClick = () => {},
}) => {
	const hexFill = fill || COLORS.midnight;
	return (
		<svg
			onClick={onClick}
			className={className || ''}
			width="8px"
			height="18px"
			viewBox={viewBox ? viewBox.join(' ') : '0 0 8 18'}
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
		>
			<path fillRule="evenodd" clipRule="evenodd" d="M7.53544 14.1716L4.35346 17.3536C4.15819 17.5488 3.84161 17.5488 3.64635 17.3536L0.46437 14.1716C0.269107 13.9763 0.269107 13.6597 0.46437 13.4645C0.659632 13.2692 0.976215 13.2692 1.17148 13.4645L3.4999 15.7929L3.4999 1C3.4999 0.723857 3.72376 0.5 3.9999 0.5C4.27605 0.5 4.4999 0.723857 4.4999 1L4.4999 15.7929L6.82833 13.4645C7.02359 13.2692 7.34018 13.2692 7.53544 13.4645C7.7307 13.6597 7.7307 13.9763 7.53544 14.1716Z" fill={hexFill}/>
		</svg>
	);
};

export default DirectionArrow;
