import { applyMiddleware, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import * as settings from '../settings';
// import { logCorruptContentDocToSentry } from './middleware/logCorruptContentDocToSentry';
import reducer from './reducers/index';

// Setting up redux store & middlewares
let composeEnhancers = compose;
if (settings.DEBUG) {
	composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__?.({ trace: true, traceLimit: 25 }) || compose;
}

export const store = createStore(reducer, composeEnhancers(applyMiddleware(
	thunk, 
	//logCorruptContentDocToSentry
)));
