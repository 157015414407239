import { useFrame } from '@react-three/fiber';
import { useMemo, useEffect, useRef } from 'react';
import { AnimationClip, AnimationMixer, LoopRepeat } from 'three';

interface IPlayModelAnimationsInput {
	isPlayingAnimation: boolean;
	animationName: string;
	animations: AnimationClip[];
	repetitions: number;
	modelScene: THREE.Object3D<THREE.Event>;
	runCounter?: number;
	onAnimationEnd?: () => unknown;
	idleAnimation?: string;
	idlePoseTime?: number;
	isClampWhenFinished?: boolean;
}

const getAnimationClipByName = (clips: AnimationClip[], name: string) => {
    // At times threejs prefixes animation names with 'animation_'. If no 
    // animationClip found try with that animation prefix.
    let action: AnimationClip | null = null
    action = AnimationClip.findByName(clips, name);
    if (action) return action;
    action = AnimationClip.findByName(clips, 'animation_' + name);
    return action;
}

const usePlayModelAnimations = ({
	isPlayingAnimation,
	animationName,
	animations,
	repetitions,
	modelScene,
	runCounter,
	onAnimationEnd,
	idleAnimation,
	idlePoseTime,
	isClampWhenFinished,
}: IPlayModelAnimationsInput): React.MutableRefObject<boolean> => {
	const animationPlayingRef = useRef(false);
	const mixer = useMemo(() => new AnimationMixer(modelScene), [modelScene, animations, isClampWhenFinished, animationName, runCounter, isPlayingAnimation, idleAnimation]);
	const idleMixer = useMemo(() => new AnimationMixer(modelScene), [modelScene, animations, isClampWhenFinished, animationName, runCounter, isPlayingAnimation, idleAnimation]);

	const playIdleState = () => {
        if (!idleAnimation) { idleMixer.stopAllAction(); return; }        
        if (idlePoseTime === undefined) {
            // console.log('Play idle animation', idleAnimation)
            const idleAction = getAnimationClipByName(animations, idleAnimation);
            idleMixer?.clipAction?.(idleAction)?.reset().play?.()?.setLoop?.(LoopRepeat, Infinity);
            animationPlayingRef.current = true;
        } else {
            // console.log('Show idle frame', idleAnimation);
            const idleAction = getAnimationClipByName(animations, idleAnimation);    
            idleMixer.clipAction(idleAction)?.reset()?.play();          
            idleMixer.setTime(idlePoseTime);         
        }
    };
    
    const playAnimation = () => {
        // console.log('Play animation ', animationName);
        mixer.removeEventListener('finished', mixerOnFinish);
        const action = getAnimationClipByName(animations, animationName);
        mixer.clipAction(action).reset().clampWhenFinished = !!isClampWhenFinished;
        mixer?.clipAction?.(action)?.play?.()?.setLoop?.(LoopRepeat, repetitions);
        animationPlayingRef.current = true;
        mixer.addEventListener('finished', mixerOnFinish);
    }

    useEffect(() => {
        // console.log('------------  ...useEffect running...  ------------');
        // console.log('animationName', animationName);
        // console.log('repetitions', repetitions);
        // console.log('runCounter', runCounter);
        // console.log('idleAnimation', idleAnimation);
        // console.log('idlePoseTime', idlePoseTime);
        // console.log('isClampWhenFinished', isClampWhenFinished);
        // console.log('isPlayingAnimation', isPlayingAnimation);
        // console.log('animationPlayingRef.current', animationPlayingRef.current);
        
		if (!animationName) {
			playIdleState();
		} else {
            playAnimation();
        }
        return () => {
            mixer.removeEventListener('finished', mixerOnFinish);
        }
	}, [modelScene, animationName, repetitions, runCounter, idleAnimation, idlePoseTime, isClampWhenFinished, isPlayingAnimation]);

    useFrame((_, delta) => {
        if (!isPlayingAnimation || !animationPlayingRef.current) return;
        // console.log(animationPlayingRef.current)
        mixer.update(delta);
        idleMixer.update(delta);        
    });
    
    const mixerOnFinish = () => {
        animationPlayingRef.current = false;
        if (!isClampWhenFinished) {
            playIdleState();
        }
        onAnimationEnd?.();
    }

	return animationPlayingRef;
};

export default usePlayModelAnimations;
