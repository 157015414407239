/* eslint-disable @typescript-eslint/no-namespace */
import { ReactThreeFiber, extend, useThree } from '@react-three/fiber';
import { useLayoutEffect, useMemo, useRef } from 'react';
import React from 'react';
import { useDispatch } from 'react-redux';
import { IDomIdSelectors } from '../../../../../typings';
import { onSetCurvatures, onSetMultipleComponentProps_Cn_Doc, onSetPositions, onSetRotations } from '../../../../store/actions';
import { maths } from '../../../../utils/maths';
import { ITuple3, IVector3 } from '../../r3f-components/component-data-structure';
import { CurvedControlsThree, IGizmoMode, IOnChangeFn } from './CurvedControlsThree';

extend({ CurvedControlsThree });

declare global {
	namespace JSX {
		interface IntrinsicElements {
			curvedControlsThree: ReactThreeFiber.Object3DNode<CurvedControlsThree, typeof CurvedControlsThree>;
		}
	}
}

interface IParentProps {
	initialSettings: {
		entityId: string;
		entityPosition: IVector3;
		entityRotation: IVector3;
		width: number;
		curvature: number;
		isFlatImageTracked: boolean;
	};
}

const CurvedControls = (props: IParentProps) => {
	const dispatch = useDispatch();
	const { initialSettings } = props;
	const { camera } = useThree();
	const domElement = document.getElementById(IDomIdSelectors.zapparCanvas) as HTMLCanvasElement;
	const controlsRef = useRef(null!);
	const transientPositionRef = useRef<ITuple3 | null>(null);
	const transientRotationRef = useRef<ITuple3 | null>(null);
	const transientCurvatureRef = useRef<number | null>(null);

	useLayoutEffect(() => {
		const controlsRefCopy = controlsRef.current as CurvedControlsThree;
		return () => {
			controlsRefCopy.dispose();
		};
	}, []);

	const onPointerUp = (mode: IGizmoMode) => {
		if (!mode) return;
		switch (mode) {
			case 'moveVertical':
				if (transientPositionRef.current) {
					// Update content doc position
					dispatch(
						onSetMultipleComponentProps_Cn_Doc([
							{
								id: initialSettings.entityId,
								position: transientPositionRef.current,
							},
						])
					);
					// And reset user doc state
					updateTransientPosition(null);
				}
				break;
			case 'moveAround':
				if (transientPositionRef.current && transientRotationRef.current) {
					// Update content doc
					dispatch(
						onSetMultipleComponentProps_Cn_Doc([
							{
								id: initialSettings.entityId,
								position: transientPositionRef.current,
								rotation: transientRotationRef.current,
							},
						])
					);
					// And reset user doc state
					updateTransientPosition(null);
					updateTransientRotation(null);
				}
				break;
			case 'moveAway': {
				if (transientCurvatureRef.current) {
					// Update content doc
					dispatch(
						onSetMultipleComponentProps_Cn_Doc([
							{
								id: initialSettings.entityId,
								position: transientPositionRef.current,
								curvature: transientCurvatureRef.current,
							},
						])
					);
					// And reset user doc state
					updateTransientPosition(null);
					updateTransientCurvature(null);
				}
				break;
			}
		}
	};

	const onChange: IOnChangeFn = (mode: IGizmoMode, data: { newPosition: ITuple3; newRotation?: ITuple3; newCurvature?: number }) => {
		switch (mode) {
			case 'moveVertical': {
				const { newPosition } = data;
				updateTransientPosition(newPosition);
				break;
			}
			case 'moveAway':
				{
					const { newPosition, newCurvature } = data;
					updateTransientPosition(newPosition);
					if (newCurvature) updateTransientCurvature(newCurvature);
				}
				break;
			case 'moveAround':
				{
					const { newPosition, newRotation } = data;
					updateTransientPosition(newPosition);
					if (newRotation) updateTransientRotation(newRotation);
				}
				break;
		}
	};

	const updateTransientPosition = (newPosition: ITuple3 | null) => {
		const transientPositionDict = {};
		transientPositionDict[initialSettings.entityId] = newPosition;
		dispatch(onSetPositions(transientPositionDict));
		transientPositionRef.current = newPosition;
	};

	const updateTransientRotation = (newRotation: ITuple3 | null) => {
		const transientRotationDict = {};
		transientRotationDict[initialSettings.entityId] = newRotation ? newRotation.map((v) => maths.toDegrees(v)) : newRotation;
		dispatch(onSetRotations(transientRotationDict));
		transientRotationRef.current = newRotation ? (newRotation.map((v) => maths.toDegrees(v)) as ITuple3) : newRotation;
	};

	const updateTransientCurvature = (newCurvature: number | null) => {
		const transientCurvatureDict = {};
		transientCurvatureDict[initialSettings.entityId] = newCurvature;
		dispatch(onSetCurvatures(transientCurvatureDict));
		transientCurvatureRef.current = newCurvature;
	};

	const controls = useMemo(() => {
		return [
			<curvedControlsThree
				key="CurvedControlsThree"
				renderOrder={Infinity}
				ref={controlsRef}
				args={[domElement, camera, initialSettings.entityPosition, initialSettings.entityRotation, initialSettings.curvature, initialSettings.width, initialSettings.isFlatImageTracked, onChange, onPointerUp]}
			/>,
		];
	}, []);
	return <>{controls}</>;
};

export default CurvedControls;
