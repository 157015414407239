import { DoubleSide, Group, Mesh, MeshBasicMaterial, OrthographicCamera, PlaneGeometry, Scene, ShapeGeometry, SRGBColorSpace, TextureLoader, WebGLRenderer } from 'three';
// @ts-ignore typescript struggling with this import
import { SVGLoader } from 'three/examples/jsm/loaders/SVGLoader';

interface IGetIconAndTextCanvasProps {
  dimensions: { width: number; height: number };
  colors: string[];
  iconUrl: string;
  alignment: "left" | "right" | "center";
  iconFillColor: string;
}

interface IAddSvgProps {
  scene: Scene;
  svgUrl: string;
  dimensions: { height: number; width: number };
  alignment?: "center" | "left" | "right";
  parentDimensions?: { height: number; width: number };
  iconFillColor?: string;
}

/*
 * This is used by both addSvg and getSvgCanvas to upscale the SVG from their native size ( e.g. 366x120 ) to some larger size that won't be blurry when scaled
 * The larger the size, the larger the canvas texture, but less blurry
 */
const SVG_SCALE_FACTOR = 12;
const ICON_INITIAL_HEIGHT = 0.6; // 0.6 = 60%

/*
 * 2x reusable canvases. Both are required as iconAndTextCanvas includes a call to addSvg
 */
const iconAndTextCanvas = document.createElement("canvas");
const svgCanvas = document.createElement("canvas");
const gradientCanvas = document.createElement("canvas");

/*
 * getImageDimensions is called on every render, and the dimensions never change, so lets cache them
 */
const imageDimensionsCache: { [k: string]: { height: number; width: number } } = {};
const getImageDimensions = (src: string): Promise<{ height: number; width: number }> => {
  return new Promise((resolve) => {
    if (imageDimensionsCache[src]) resolve(imageDimensionsCache[src]);
    const image = document.createElement("img");
    image.src = src;
    image.onload = () => {
      imageDimensionsCache[src] = {
        height: image.naturalHeight,
        width: image.naturalWidth,
      };
      resolve(imageDimensionsCache[src]);
    };
  });
};

export const addSvg = (args: IAddSvgProps): Promise<void> => {
  const { scene, svgUrl, dimensions, alignment, parentDimensions, iconFillColor } = args;
  // console.log(dimensions, parentDimensions);
  return new Promise((resolve) => {
    // Process SVG
    const loader = new SVGLoader();
    loader.load(
      // resource URL
      svgUrl + "?=webgl",
      // called when the resource is loaded
      function (data: any) {
        // console.log(data);
        const paths = data.paths;
        const group = new Group();
        for (let i = 0; i < paths.length; i++) {
          const path = paths[i];

          const material = new MeshBasicMaterial({
            color: iconFillColor ?? path.color,
            side: DoubleSide,
            depthWrite: false,
            transparent: true,
          });

          const shapes = SVGLoader.createShapes(path);

          for (let j = 0; j < shapes.length; j++) {
            const shape = shapes[j];
            const geometry = new ShapeGeometry(shape);
            const mesh = new Mesh(geometry, material);
            mesh.renderOrder = 2;
						mesh.scale.y = -1;
						if (!parentDimensions) {
							// Social buttons only.. 
							mesh.position.x = -dimensions.width / 2;
							mesh.position.y = dimensions.height / 2;
						}
            group.add(mesh);
          }
        }
        /**
         * We have normalised the icon ( iconDimensions ) and entity ( dimensions ) but the SVGLoader brings it in in it's non-normalised scale so
         * we need to re-scale the group to compensate for this
         */
        const scaleFactor =
          imageDimensionsCache[svgUrl].height // The original ( image.nativeHeight ) height of the SVG, as this is the size SVGLoader will load in at 
          / 
          (SVG_SCALE_FACTOR * 10);  // SVG_SCALE_FACTOR * 10 because height is set to 10 for the canvas then scaled by SVG_SCALE_FACTOR
        
				const aspectRatio = imageDimensionsCache[svgUrl].width / imageDimensionsCache[svgUrl].height;
        if (parentDimensions) {
					group.scale.set(ICON_INITIAL_HEIGHT / scaleFactor, ICON_INITIAL_HEIGHT * aspectRatio / scaleFactor, 1);
          let yPos = ((ICON_INITIAL_HEIGHT * 10) / 2) * SVG_SCALE_FACTOR;  // Center position, 4 because icon scaled to be 80% of normalised height of 10 
          let xPos = -((ICON_INITIAL_HEIGHT/2) * 10) * aspectRatio * SVG_SCALE_FACTOR;
          const padding = ( (1 - ICON_INITIAL_HEIGHT) / 2) * parentDimensions?.height;
          switch (alignment) {
            case "left":
              xPos = -parentDimensions?.width / 2 + padding;
              yPos = (ICON_INITIAL_HEIGHT * parentDimensions?.height) / 2;
              break;
            case "right":
              xPos = parentDimensions?.width / 2 - ((ICON_INITIAL_HEIGHT * 10) * SVG_SCALE_FACTOR * aspectRatio) - padding;
              yPos = (ICON_INITIAL_HEIGHT * parentDimensions?.height) / 2;
              break;
          }
          group.position.set(xPos, yPos, 0);
          // console.log(group.position);
          group.renderOrder = 2;
        } else {
          group.scale.multiplyScalar(SVG_SCALE_FACTOR);
        }
        scene.add(group);

        resolve();
      },
      // Loading..
      function (_xhr: any) {
        // console.log((_xhr.loaded / _xhr.total) * 100 + '% loaded');
      },
      // Error...
      function (_error: any) {
        // console.error('An error happened: ', _error);
      }
    );
  });
};

export const getSvgCanvas = async (svgUrl: string) => {
  // console.log('getSvgCanvas');
  const dimensions = await getImageDimensions(svgUrl);
  svgCanvas.style.backgroundColor = "rgba(0,0,0,0)";
  svgCanvas.height = dimensions.height * SVG_SCALE_FACTOR;
  svgCanvas.width = dimensions.width * SVG_SCALE_FACTOR;

  const scene = new Scene();
  const camera = new OrthographicCamera(svgCanvas.width / -2, svgCanvas.width / 2, svgCanvas.height / 2, svgCanvas.height / -2, 1, 1000);
  camera.position.set(0, 0, 10);
  scene.add(camera);

  const renderer = new WebGLRenderer({ canvas: svgCanvas, antialias: true, alpha: true });
  renderer.setSize(window.innerWidth, window.innerHeight);
  renderer.setClearColor(0x000000, 0);
  await addSvg({ scene, svgUrl, dimensions });
  renderer.render(scene, camera);
  return svgCanvas.toDataURL();
};

export const getGradientCanvas = async ({ colors, width = 200, height = 200 }: { colors: string[]; width?: number; height?: number }) => {
  if (height < 1 || width < 1) {
    height *= 11;
    width *= 11;
  }
  gradientCanvas.height = height;
  gradientCanvas.width = width;
  const ctx = gradientCanvas.getContext("2d")!;
  const gradient = ctx.createLinearGradient(0, 0, width, 0);

  // Add evenly spaced color stops for given colors
  for (let i = 0; i < colors.length; i++) {
    const divider = colors.length % 2 !== 0 ? colors.length : colors.length - 1;	
    gradient.addColorStop((1 / divider) * i, colors[i]);
  }
  ctx.save();
  ctx.fillStyle = gradient;
  ctx.fillRect(0, 0, width, height);
  ctx.restore();
  return gradientCanvas.toDataURL();
};



export const getIconAndTextCanvas = async (args: IGetIconAndTextCanvasProps) => {
  const { dimensions, colors, iconUrl, alignment, iconFillColor } = args;
  const originalDimensions = JSON.parse(JSON.stringify(dimensions));

  iconAndTextCanvas.height = 10 * SVG_SCALE_FACTOR;
  iconAndTextCanvas.width = originalDimensions.width * (10 / originalDimensions.height) * SVG_SCALE_FACTOR;

  const iconDimensions = await getImageDimensions(iconUrl);
  dimensions.height = 10 * SVG_SCALE_FACTOR;
  dimensions.width = originalDimensions.width * (10 / originalDimensions.height) * SVG_SCALE_FACTOR;

  // Setup scene
  const renderer = new WebGLRenderer({ canvas: iconAndTextCanvas, antialias: true, alpha: true });
  renderer.setClearColor(0x000000, 0);
  renderer.setPixelRatio(window.devicePixelRatio);
  renderer.domElement.style.backgroundColor = "transparent";
  const scene = new Scene();
  const camera = new OrthographicCamera(dimensions.width / -2, dimensions.width / 2, dimensions.height / 2, dimensions.height / -2, 1, 10);
  camera.position.set(0, 0, 1);
  scene.add(camera);

  // Background
  const gradientTextureImage = await getGradientCanvas({ colors, height: dimensions.height, width: dimensions.width });
  const map = await new TextureLoader().loadAsync(gradientTextureImage);
  map.anisotropy = renderer.capabilities.getMaxAnisotropy();
  map.colorSpace = SRGBColorSpace;

  // Background plane
  const geom = new PlaneGeometry(dimensions.width, dimensions.height, 100, 100);
  const mat = new MeshBasicMaterial({ map, transparent: true });
  const mesh = new Mesh(geom, mat);
  mesh.renderOrder = 1;
  scene.add(mesh);

  // Icon
  // console.log(iconDimensions, dimensions);
  await addSvg({ scene, svgUrl: iconUrl, dimensions: iconDimensions, alignment, parentDimensions: dimensions, iconFillColor });

  renderer.render(scene, camera);
  return iconAndTextCanvas.toDataURL();
};