import React from 'react';
import { IIconParentProps } from './index';
import { COLORS } from '../../utils';

const arrowLeftBigIcon: React.SFC<IIconParentProps> = ({
	className,
	hexFill: fill,
	viewBox,
	onClick = () => {},
}) => {
	const hexFill = fill || COLORS.midnight;
	return (
		<svg
			onClick={onClick}
			className={className || ''}
			width="20px"
			height="20px"
			viewBox={viewBox ? viewBox.join(' ') : '0 0 20 20'}
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
		>
			<defs id="defs3">
				<path
					id="arrow-a"
					d="M3.9361615,3.49235871 C4.18720002,3.49235871 4.39070695,3.69227377 4.39070695,3.94532909 L4.39061605,15.4093587 L15.8090728,15.4096487 C16.0540045,15.4096487 16.2577138,15.5909046 16.2999586,15.8191991 L16.303616,15.8643587 L16.30004,15.910991 C16.2582017,16.1428891 16.0562004,16.3187396 15.8090728,16.3187396 L4.07964842,16.3187396 C3.94112145,16.3187396 3.81578048,16.2607606 3.72537872,16.1690842 C3.58088272,16.0942144 3.48161605,15.9433981 3.48161605,15.7666611 L3.48161605,3.94532909 C3.48161605,3.72295697 3.64821257,3.53801054 3.85639002,3.49965667 L3.9361615,3.49235871 Z"
				/>
			</defs>
			<use
				fill={hexFill}
				fillRule="evenodd"
				transform="rotate(45 9.249 9.921)"
				xlinkHref="#arrow-a"
			/>
		</svg>
	);
};

export default arrowLeftBigIcon;
