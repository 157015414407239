import React from "react";
import { IIconParentProps } from "./index";
import { COLORS } from "../../utils";

const Phone16: React.FunctionComponent<IIconParentProps> = ({ width, height, className, hexFill = COLORS.midnight, viewBox, onClick = () => {} }) => {
  return (
    <svg
      onClick={onClick}
      className={className || ""}
      width={width ? width : "16px"}
      height={height ? height : "16px"}
      viewBox={viewBox ? viewBox.join(" ") : `0 0 16 16`}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <path
        d="M8.51495 14.5836C9.24492 14.8943 10.0168 15.0836 10.7978 15.1437C12.204 15.252 13.7046 14.5206 15.154 13.4284C15.8761 12.8843 15.6353 12.136 14.9423 11.4188C14.6266 11.092 14.1689 10.7141 13.5652 10.2793L13.1853 10.0116L12.7703 9.73003C12.6981 9.68193 12.6245 9.63323 12.5494 9.58395C12.0729 9.27113 11.4575 9.26554 10.9753 9.56965L9.86342 10.2712L9.81882 10.2615C9.26501 10.1308 8.74383 9.64635 7.93762 8.57647L7.83326 8.43646C7.23411 7.62296 7.04109 7.17987 7.1046 6.7618L7.12143 6.67597L8.21331 5.51407C8.58086 5.12314 8.70341 4.56172 8.53218 4.0533L8.35616 3.5443C8.00901 2.56824 7.69063 1.84312 7.39169 1.35751C6.88218 0.52982 6.22865 0.120028 5.52499 0.650275C4.08151 1.73801 2.95951 2.98315 2.67594 4.36253C2.51771 5.13223 2.4869 5.92903 2.58524 6.71886C2.88076 9.09247 6.07427 13.3814 8.32929 14.498L8.51495 14.5836ZM8.79984 13.9144C6.77753 13.0536 3.57873 8.81168 3.30697 6.62896C3.21818 5.91585 3.24651 5.19891 3.38835 4.50894C3.64254 3.27248 4.8587 2.11668 5.64339 1.48072L5.86446 1.30596C5.89868 1.27958 5.93148 1.25461 5.96267 1.2311C6.20965 1.04499 6.50838 1.26331 6.85887 1.88605L6.97762 2.10861C6.99773 2.1482 7.018 2.18904 7.03843 2.23113L7.16293 2.49862C7.18399 2.5457 7.20522 2.59403 7.22661 2.64361L7.35686 2.95604C7.37889 3.01061 7.40107 3.06642 7.42342 3.12349L7.55942 3.48086L7.69925 3.86819L7.84292 4.28547C7.92853 4.53969 7.86725 4.82041 7.68349 5.01585L6.47469 6.30124C6.19393 7.0955 6.45903 7.76732 7.14009 8.71966L7.35675 9.01418L7.47615 9.17136L7.71273 9.4732C8.41959 10.3517 9.10981 10.9752 10.0332 11.0236L11.3632 10.1848C11.6043 10.0328 11.912 10.0355 12.1503 10.192L12.5303 10.445L12.8814 10.6861C12.9375 10.7253 12.9924 10.7641 13.0461 10.8023L13.3537 11.0256L13.6324 11.237C13.7204 11.3055 13.8037 11.3721 13.8821 11.4366L14.1028 11.6243C14.7902 12.2301 14.9947 12.6379 14.7163 12.8476L14.6159 12.9224L14.3849 13.0883L14.1175 13.2699C13.2656 13.8309 11.987 14.5058 10.8537 14.4186C10.1536 14.3646 9.45889 14.1949 8.79984 13.9144Z"
        fill={hexFill}
      />
    </svg>
  );
};

export default Phone16;
