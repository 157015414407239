import React, { FunctionComponent, memo, useMemo } from 'react';
import * as THREE from 'three';
import { ITuple3, ITuple4 } from '../../../component-data-structure';
import { useTextureLoader } from '../../../hooks/useTextureLoader';
import RoundedRectWithBorder from '../../RoundedRectWithBorder';
import brokenImgUrl from './broken-image.png';

interface IParentProps {
	scale: ITuple3;
	renderOrder: number;
}

const BrokenIcon: FunctionComponent<IParentProps> = ({ scale, renderOrder }) => {
	const texture = useTextureLoader(THREE.TextureLoader, brokenImgUrl, false);

	// Ensure sharp png texture
	if (texture) {
		texture.anisotropy = 0;
		texture.magFilter = THREE.NearestFilter;
		// texture.minFilter = THREE.LinearFilter;
	}

	const memo = useMemo(() => {
		let scl = [Math.abs(scale[0]), Math.abs(scale[1]), 0] as ITuple3;
		const isLandscape = scl[0] > scl[1];
		if (!isLandscape) scl = [scl[0] * 0.3713, scl[0] * 0.3375, 0];
		else scl = [scl[0] * 0.1375, scl[0] * 0.125, 0];
		return {
			scale: scl,
			zeroTuple: [0, 0, 0] as ITuple3,
			fillRgba: [255, 255, 255, 0] as ITuple4,
		};
	}, [scale]);

	return <RoundedRectWithBorder opacity={1} texture={texture} scale={memo.scale} fillRgba={memo.fillRgba} rotation={memo.zeroTuple} position={memo.zeroTuple} renderOrder={renderOrder} />;
};

export default memo(BrokenIcon);
