import { IFile, IFileStatus } from "./file";

export interface ICaptionResponse {
  id: string;
  caption: string;
}

export interface IUploadParamsResponse {
  url: string;
  folder: string;
  fields: {
    bucket: string
    key: string
    acl: string
    AWSAccessKeyId: string
    "x-amz-security-token": string
    policy: string;
    signature: string
  }
}

export interface IUploadPayload {
  folder: string
  filename: string
  ftype: string
  thumbnails?: string // stringified value of IThumbnail[]
}

export interface IUploadResponse {
  folder: string
  key: string
  filename: string
}

// export interface IJobResponse {
//   statusURL: string;
// }

// export interface IJobOutput { }

// export interface IAnalysisOutput extends IJobOutput {
//   Quality: number;
//   Codes: string[];
//   CompositeURL: string;
// }

// export type IRawAnalysisResponse = IJobResponse & IAnalysisOutput;

// export interface IImageOutput extends IJobOutput {
//   AspectRatio: number
// }

// export interface IRefinedAnalysisResponse {
//   fileId: string;
//   fileName: string;
//   quality: number;
//   codes: string[];
//   compositeURL: string;
//   statusURL: string;
// }

// export interface ITrainingResponse extends IJobResponse {
//   caption: string;
//   url: string;
//   zpt: string;
// };

export interface IJobOutputImage {
  AspectRatio: number
}

export interface IJobOutputAudio {
  AspectRatio: number
}

export interface IJobOutputAnalysis {
  Codes: string[]
  CompositeURL: string
  Quality: number
}

export interface IJobOutputTraining {
  zpt: string // URL
}

export interface IJobOutputVideo {
  Destination: string // s3://wizcontent/videos/stg123-blablabla
}

export interface IJobOutputStreamingVideo {
  aspectRatio: number | null
  duration: number
  hasAlpha: boolean
  height: number
  isPortrait: boolean
  mp4URL: string
  thumbnailURL: string
  width: number
}

export interface IJobOutput3DModel {
  animations: string[]
  dimensions: { x: number, y: number, z: number }
  infoURL: string
  modelURL: string
  thumbnailURL: string
}

export type IJobOutputAny = {} | IJobOutputImage | IJobOutputAnalysis | IJobOutputTraining | IJobOutputVideo | IJobOutputStreamingVideo | IJobOutput3DModel

export enum IProgressStatus {
  uploading = 'uploading',
  processing = 'processing',
  analysing = 'analysing',
  training = 'training',
  completed = 'completed',
  trainingCompleted = 'trainingCompleted',
  imgQualityError = 'imgQualityError',
  error = 'error',
}

export interface IJobProgress {
  status: IProgressStatus
  index?: number
  id?: string
  errorCode?: number
  desc?: string  // error description
  fileInfo?: IFile
}

export interface IProgressStatusResponseV1 {
  // v1
  Status: IFileStatus;
  URL: string;
  Description: string;
  DetailedDescription: string;
  PercentComplete: number;
  Output: IJobOutputAny;
  // v2
  status?: IFileStatus
  progress?: number
  error?: {
      code: string
      message: string
  }
  output?: IJobOutputAny
}

export interface IProgressStatusResponseV2 {
  status: IFileStatus
  progress: number
  error?: {
      code: string
      message: string
  }
  output?: IJobOutputAny
  progressUrl?: string
}

export type IProgressStatusResponse = IProgressStatusResponseV1 | IProgressStatusResponseV2
