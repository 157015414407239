import { useMemo } from "react";
import * as THREE from 'three';
import { ITuple3, ITuple4, IVector3, IVector4 } from "../component-data-structure";

export const useSRGBColors = (color: string | undefined | ITuple3 | ITuple4 | IVector3 | IVector4): THREE.Color | undefined => {
    const threeColor = useMemo(() => {
        if (!color) return color; 
        const sRgbCol = typeof color !== 'string' ? 
            `rgb(${color[0]}, ${color[1]}, ${color[2]})` :
            color;
        return new THREE.Color(sRgbCol);
    }, [color])
    return threeColor as THREE.Color;
}