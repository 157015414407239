import { IAbstractComponentCopyInclChildren } from '../../../typings/general';
import { IAbstractComponentUnion, IComponentsById, IComponentType, ISceneComp } from '../../components/r3f/r3f-components/component-data-structure/index';
import { isAbstractComponent } from '../../components/r3f/r3f-components/utils/general';
import { insertSpatialComponent } from './insertSpatialComponent';

/**
 * Inserts an abstract component into a copy of componentsById which is then returned. Returns null if an error occurs.
 * @param args.abstractComponent - The component to insert, of type IAbstractComponentUnion in the simple case or IAbstractComponentCopyInclChildren in the recursive case
 * @param args.abstractComponentId - The ID under which args.abstractComponent will be added to the return value upon successful insertion
 * @param args.parentId - The ID of the component which will have args.abstractComponent as a child in the return value upon successful insertion
 * @param args.sourceSceneId - For use in copy / paste flow - the ID of the scene that the abstract component was copied from
 * @param args.sourceSceneIsAr - ? should be sourceLAYERisAr ? - For use in copy / paste flow of spatial child components, whether the copied component was on an AR layer ( false if copied from screen layer )
 * @param args.isFlatOrientation - Userdoc.isFlatOrientation, required for proper positioning of spatial child components
 * @param args.newTitle - For use when inserting IComponentType.Scene will set the title to this value
 * @param args.componentsById - The current componentsById from content doc
 * @param args.debug - If true, will log details throughout execution
 * @param args.adjustPositionToCamera - Whether to position an entity in camera direction
 * @returns A copy of args.componentsById with args.abstractComponent properly inserted along with any children ( abstract and spatial ) it may have. Or null if an error occurred.
 */
export const insertAbstractComponent = <T extends IAbstractComponentUnion>(args: {
	abstractComponent: T | IAbstractComponentCopyInclChildren<T>;
	abstractComponentId: string;
	parentId: string;
	sourceSceneIsAr: boolean;
	sourceSceneId?: string;
	isFlatOrientation: boolean;
	newTitle?: string;
	componentsById: IComponentsById;
	debug?: boolean;
	adjustPositionToCamera?: boolean;
}): IComponentsById | null => {
	const { abstractComponent, abstractComponentId, parentId, newTitle, componentsById, sourceSceneIsAr, sourceSceneId, isFlatOrientation, debug = false, adjustPositionToCamera = true } = args;
	let componentsByIdCp = JSON.parse(JSON.stringify(componentsById)) as IComponentsById | null;
	if (!componentsByIdCp) return null;
	if (debug) console.log('insertAbstractComponent > abstractComponent: ', JSON.parse(JSON.stringify(abstractComponent)));
	if (debug) console.log('insertAbstractComponent > abstractComponentId: ', abstractComponentId, 'parentId: ', parentId);

	/**
	 * Can either pass in an abstract component, or a copy of one, in which case it will be of type IAbstractComponentCopyInclChildren
	 */
	if ('abstractComponentCopy' in abstractComponent) {
		/**
		 * Copied abstract component is supplied, we need to process it's children then proceed to simple-add the abstract component
		 */
		const abstractComponentCopiedChildren = abstractComponent.abstractComponentChildrenCopy;
		const [abstractComponentIdCopy, abstractComponentCopy] = Object.entries(abstractComponent.abstractComponentCopy)[0];
		if (debug) console.log('insertAbstractComponent > recursive case, adding ', abstractComponentIdCopy);
		componentsByIdCp = insertAbstractComponent({
			abstractComponent: abstractComponentCopy,
			abstractComponentId: abstractComponentIdCopy,
			parentId,
			sourceSceneIsAr,
			isFlatOrientation,
			newTitle,
			componentsById: componentsByIdCp,
			debug,
			adjustPositionToCamera
		});
		for (const [componentId, component] of Object.entries(abstractComponentCopiedChildren)) {
			if (debug) console.log('insertAbstractComponent > looping over children > componentId: ', componentId);
			if (debug) console.log('insertAbstractComponent > looping over children > component: ', component);
			if (!component) return null;
			if ('abstractComponentCopy' in component) {
				if (debug) console.log('insertAbstractComponent > found abstractComponentCopy in  abstractComponentCopiedChildren : ', component);
				if (!componentsByIdCp) return null;
				componentsByIdCp = insertAbstractComponent({
					abstractComponent: component,
					abstractComponentId: abstractComponentIdCopy,
					parentId: abstractComponentIdCopy,
					sourceSceneIsAr,
					isFlatOrientation,
					componentsById: componentsByIdCp,
					debug,
					adjustPositionToCamera
				});
			} else {
				if (debug)
					console.log(
						'insertAbstractComponent > ',
						isAbstractComponent(component) ? `recursive case, adding abstract : ${abstractComponentIdCopy}` : `recursive case, adding spatial : ${component.id}`
					);

				// If the parent of a spatial component is scene, then it's been copied from AR scene or is part of a full-scene copy
				if (!componentsByIdCp) return null;
				const sourceSceneIsArForSpatialComponent = (componentsByIdCp[abstractComponentIdCopy] as IAbstractComponentUnion).type === IComponentType.Scene || (componentsByIdCp[abstractComponentIdCopy] as IAbstractComponentUnion).type === IComponentType.FaceLandmarkGroup;
				if (debug) {
					console.log('insertAbstractComponent > sourceSceneIsArForSpatialComponent: ', sourceSceneIsArForSpatialComponent);
				}
				componentsByIdCp = isAbstractComponent(component)
					? insertAbstractComponent({
							abstractComponent: abstractComponentCopy,
							abstractComponentId: abstractComponentIdCopy,
							parentId,
							sourceSceneIsAr,
							isFlatOrientation,
							componentsById: componentsByIdCp,
							debug,
							adjustPositionToCamera
					  })
					: insertSpatialComponent({
							spatialComponent: component,
							parentId: abstractComponentIdCopy,
							sourceSceneIsAr: sourceSceneIsArForSpatialComponent,
							sourceSceneId,
							isFlatOrientation,
							componentsById: componentsByIdCp,
							debug,
							adjustPositionToCamera
					  });
			}
		}
	} else {
		/**
		 * Simple case, just add the abstract component
		 */
		if (debug) console.log('insertAbstractComponent > simple case, adding ', abstractComponentId, ' to componentsById out');

		// If we're adding a screen content to a scene, need to remove the old one first
		if (componentsByIdCp[parentId].type === IComponentType.Scene && abstractComponent.type === IComponentType.ScreenContent) {
			(componentsByIdCp[parentId] as ISceneComp).children = (componentsByIdCp[parentId] as ISceneComp).children.filter(
				(componentId) => componentsByIdCp && componentsByIdCp[componentId]?.type !== IComponentType.ScreenContent
			);
		}

		// If we have a new title for the spatial component ( copy/paste or duplicate scenario ) then apply that now
		if (newTitle && abstractComponent.type === IComponentType.Scene) abstractComponent.title = newTitle;

		// Only add to parent.children if it's not already there
		if (!(componentsByIdCp[parentId] as IAbstractComponentUnion).children.includes(abstractComponentId)) {
			(componentsByIdCp[parentId] as IAbstractComponentUnion).children.push(abstractComponentId);
		}
		componentsByIdCp[abstractComponentId] = JSON.parse(JSON.stringify(abstractComponent));
		if (debug) console.log('componentsByIdCp ( out ): ', JSON.stringify(componentsByIdCp, null, 4));
	}

	return componentsByIdCp;
};
