import { useMemo } from "react"
import { Mesh, Object3D, SkinnedMesh } from "three"

// Applies render order to meshes with transparent materials
export const useApplyRenderOrderToModel = (object: Object3D, renderOrder: number) => {
    return useMemo(() => {
        object.traverse((node: any) => {
            if (
              (node.isSkinnedMesh || node.isMesh) &&
              typeof renderOrder !== "undefined"
            ) {
              const mesh = node as Mesh | SkinnedMesh;
              const materialArray = Array.isArray(mesh.material)
                ? mesh.material
                : [mesh.material];
              let meshIsTransparent = false;
              for (let i = 0; i < materialArray.length; i++) {
                if (materialArray[i].transparent) meshIsTransparent = true;
              }
              if (meshIsTransparent) mesh.renderOrder = renderOrder;
            }
          });

        return object;
    }, [object, renderOrder])
}