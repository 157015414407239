import React from "react";
import { BackSide, FrontSide } from "three";
import Plane from "../../../Plane/Plane";
import { DreiText } from "../../../r3f-components";
import { ITuple3 } from "../../../r3f-components/component-data-structure";

interface IParentProps {
  scale: ITuple3,
  textRenderOrder?: number;
  planeRenderOrder?: number;
}

const TrackingRearView = ({
  scale,
  textRenderOrder = 0,
  planeRenderOrder = 0,
}: IParentProps) => {
  return (
    <>
      <Plane
        name="Tracking Display Reverse"
        enabled={false}
        visible={true}
        color={[235, 235, 235, 1]}
        position={[0, 0, -0.011]}
        rotation={[0, 0, 0]}
        scale={scale}
        renderOrder={planeRenderOrder}
        side={BackSide}
      />
      <group rotation={[0, Math.PI, 0]}>
        <DreiText
          key={"tracking_reverse_text"}
          name={"tracking_reverse_text"}
          color={"#5C5C5C"}
          alpha={1.0} 
          fontSize={0.1}
          position={[0, 0, 0.015]}
          anchorY={"middle"}
          renderOrder={textRenderOrder}
          side={FrontSide}
        >
          BACK OF TARGET IMAGE
        </DreiText>
      </group>
    </>
  );
};

export default React.memo(TrackingRearView);
