// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".TextInput--3U8yO,.TextInput--3U8yO:focus{width:150px;background-color:#fff !important;transition:opacity .15s}", ""]);
// Exports
exports.locals = {
	"TextInput": "TextInput--3U8yO"
};
module.exports = exports;
