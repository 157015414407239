
import React, { FunctionComponent, memo, useLayoutEffect } from "react";
import PulseMaterial from "../materials/PulseMaterial";
import { useFrame } from "@react-three/fiber";
import { BackSide, Color } from "three";

interface IParentProps {
    radius: number;
}
const LoadingSphere: FunctionComponent<IParentProps & JSX.IntrinsicElements['mesh']>  = (props) => {
    const { radius, ...meshProps } = props;

    useLayoutEffect(() => {
        PulseMaterial.side = BackSide;
    }, [])

    useFrame(({clock}) => {
        PulseMaterial.uniforms.uTime.value = clock.getElapsedTime() / 1.5;
        PulseMaterial.uniforms.uColor.value = new Color('#f2f2f2');
    })
   return(
        <mesh {...meshProps} material={PulseMaterial}>
            <sphereGeometry args={[ radius, 60, 40]} />
        </mesh>
    )
}


export default memo(LoadingSphere);
