import React from 'react';
import { IIconParentProps } from './index';
import { COLORS } from '../../utils';

const listSmallIcon: React.SFC<IIconParentProps> = ({
	className,
	hexFill: fill,
	viewBox,
	onClick = () => {},
}) => {
	const hexFill = fill || COLORS.midnight;
	return (
		<svg
			onClick={onClick}
			className={className || ''}
			width="4px"
			height="16px"
			viewBox={viewBox ? viewBox.join(' ') : '0 0 4 16'}
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
		>
			<defs>
				<filter id="q114p3drma">
					<feColorMatrix in="SourceGraphic" values="0 0 0 0 0.290196 0 0 0 0 0.564706 0 0 0 0 0.886275 0 0 0 1.000000 0"/>
				</filter>
			</defs>
			<g fill="none" fillRule="evenodd">
				<g filter="url(#q114p3drma)" transform="translate(-541 -868)">
					<g>
						<g fill="#FFF" transform="translate(523 856) translate(18 12)">
							<circle cx="2" cy="1.5" r="1.5"/>
							<circle cx="2" cy="7.5" r="1.5"/>
							<circle cx="2" cy="13.5" r="1.5"/>
						</g>
					</g>
				</g>
			</g>
		</svg>
	);
};

export default listSmallIcon;
