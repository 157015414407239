import React, { FunctionComponent, useMemo } from 'react';
import { ThreeEvent } from '@react-three/fiber';
import * as THREE from 'three';
import { maths } from '../../../utils';
import {
	ITuple3,
	ITuple4,
} from '../../r3f/r3f-components/component-data-structure';
import { useSRGBColors } from '../r3f-components/hooks/useSRGBColors';

interface IParentProps {
	enabled?: boolean;
	visible?: boolean;
	rotation: ITuple3;
	position: ITuple3;
	scale: ITuple3;
	color: ITuple4;
	depthWrite?: boolean;
	renderOrder?: number;
	stopEventPropagation?: boolean;
	pointerDownHandler?: (e: ThreeEvent<PointerEvent>) => any;
	pointerUpHandler?: (e: ThreeEvent<PointerEvent>) => any;
}

const LineBox: FunctionComponent<IParentProps> = ({
	visible,
	rotation: [rx, ry, rz],
	position,
	scale: [sx, sy],
	enabled = true,
	color: c,
	pointerDownHandler,
	pointerUpHandler,
	depthWrite = true,
	renderOrder,
	stopEventPropagation: stpEnvProp,
}) => {
	const geom = useMemo(() => new THREE.PlaneGeometry(sx * 2, sy * 2), [
		sx,
		sy,
	]);
	const geometry = useMemo(() => <edgesGeometry attach="geometry" args={[geom]} />,[geom]);
	const rotation = [
		maths.toRadians(rx),
		maths.toRadians(ry),
		maths.toRadians(rz),
	] as any;

	const color = useSRGBColors(c);

	const conditonalProps = {} as any;
	// if (renderOrder) conditonalProps.renderOrder = renderOrder;
	if (renderOrder === undefined) renderOrder = 999995;

	return (
    <group renderOrder={renderOrder}>
			<mesh renderOrder={1}>
				<lineSegments>
					{geometry}
					<lineBasicMaterial
						attach="material"
						linewidth={4}
						colorWrite={false}
						polygonOffset={true}
						polygonOffsetUnits={10}
						polygonOffsetFactor={10}
						depthWrite={true}
						visible={false} />
				</lineSegments>
      </mesh>
      <mesh
				{...conditonalProps}
				renderOrder={renderOrder}
        position={position as any}
        rotation={rotation}
        visible={visible}
        onPointerDown={(e) => {
          if (stpEnvProp) e.stopPropagation();
          if (enabled && pointerDownHandler) pointerDownHandler(e);
        }}
        onPointerUp={(e) => {
          if (stpEnvProp) e.stopPropagation();
          if (enabled && pointerUpHandler) pointerUpHandler(e);
        }}
      >
        <lineSegments>
          {geometry}
          <lineBasicMaterial
            attach="material"
            linewidth={4}
            opacity={c[3]}
            transparent={true}
            color={color}
						depthWrite={false}
          />
        </lineSegments>
      </mesh>
    </group>
  );
};

export default LineBox;
