import React from 'react';
import { IIconParentProps } from './index';
import { COLORS } from '../../utils';

const forwardsIcon: React.SFC<IIconParentProps> = ({
	className,
	hexFill: fill,
	viewBox,
	onClick = () => {},
}) => {
	const hexFill = fill || COLORS.midnight;
	return (
		<svg
			onClick={onClick}
			className={className || ''}
			width="17px"
			height="14px"
			viewBox={viewBox ? viewBox.join(' ') : '0 0 17 14'}
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
		>
			<g
				id="Components"
				stroke="none"
				strokeWidth="1"
				fill="none"
				fillRule="evenodd"
			>
				<g
					id="Atom/Icon/Controls/Backword"
					transform="translate(1.000000, 0.000000)"
				>
					<g id="Backword">
						<g id="Group-5-Copy-3" transform="translate(13.789728, 0.000000)">
							<g id="Group-14"></g>
						</g>
						<path
							d="M10.0577569,3.32326823 C9.67858917,3.26538841 9.28339108,3.42955165 9.06358781,3.77358205 L5.27330059,9.85464533 C5.2192635,10.1947949 5.33692774,10.5095525 5.55313865,10.7257634 C5.73410306,10.9067279 5.98410306,11.0186567 6.26024544,11.0186567 L13.9452555,10.9387416 C14.2611124,10.8011698 14.4630812,10.532496 14.5288868,10.233822 C14.5848278,9.97992111 14.5423691,9.70434031 14.3878265,9.46918903 L10.0577569,3.32326823 Z"
							id="Triangle"
							stroke={hexFill}
							transform="translate(9.906277, 6.950980) rotate(90.000000) translate(-9.906277, -6.950980) "
						></path>
						<line
							x1="14.8195785"
							y1="0.5"
							x2="14.8195785"
							y2="13.5"
							id="Line-4-Copy-2"
							stroke={hexFill}
							strokeLinecap="round"
							transform="translate(14.819578, 7.000000) scale(-1, 1) translate(-14.819578, -7.000000) "
						></line>
						<path
							d="M4.2680292,3.32326823 C3.88886143,3.26538841 3.49366334,3.42955165 3.27386007,3.77358205 L-0.516427144,9.85464533 C-0.570464241,10.1947949 -0.452799999,10.5095525 -0.236589082,10.7257634 C-0.0556246753,10.9067279 0.194375325,11.0186567 0.4705177,11.0186567 L8.15552779,10.9387416 C8.47138463,10.8011698 8.6733535,10.532496 8.7391591,10.233822 C8.79510002,9.97992111 8.75264135,9.70434031 8.59809879,9.46918903 L4.2680292,3.32326823 Z"
							id="Triangle"
							stroke={hexFill}
							fill="#FFFFFF"
							transform="translate(4.116550, 6.950980) rotate(90.000000) translate(-4.116550, -6.950980) "
						></path>
					</g>
				</g>
			</g>
		</svg>
	);
};

export default forwardsIcon;
