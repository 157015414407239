import {
	IActionCategory,
	IComponentType,
	IComponentUnion,
	IContentDoc, IRoot,
	ISceneComp,
	IScreenAnchorGroup,
	IScreenAnchorPositionType,
	IScreenContent,
	ISpatialComponentUnion,
	ITrackingTypes
} from '../components/r3f/r3f-components/component-data-structure';
import { isAbstractComponent } from '../components/r3f/r3f-components/utils/general';
import { getScreenContentIdForScene } from '../components/r3f/r3f-components/utils/pure';

/**
 * Use this to finalise the content doc before preview / publish so Jute gets just what it needs
 */
export const finaliseContentDoc = (contentDoc: IContentDoc): IContentDoc => {
	const contentDocCopy: IContentDoc = JSON.parse(JSON.stringify(contentDoc));
	return removeRedundantTracking(removeUnlinkedScenes(removeAllAnimationNamesFromModel3ds(contentDocCopy)));
};

/**
 * This function removes the .tracking property of the content doc if there are no image tracked scenes present
 */
export const removeRedundantTracking = (contentDoc: IContentDoc): IContentDoc => {
	const contentDocCopy: IContentDoc = JSON.parse(JSON.stringify(contentDoc));
	// Are there any image tracked scenes?
	const imageTrackedSceneCount = Object.entries(contentDocCopy.componentsById).reduce((imageTrackSceneCount, [_key, value]) => {
		if (value.type === IComponentType.Scene && value.trackingType === ITrackingTypes.image) imageTrackSceneCount++;
		return imageTrackSceneCount;
	}, 0);
	// If so, return early ( this func just removes redundant data )
	if (imageTrackedSceneCount) return contentDocCopy;
	// Else check for image tracked doc section and remove if applicable
	if (contentDocCopy.tracking) {
		delete contentDocCopy.tracking;
	}
	return contentDocCopy;
};

/**
 * Remove any hidden spatial entities, and all references to them in AR and SR layers
 */
export const removeHiddenEntities = (contentDoc: IContentDoc): IContentDoc => {
	const contentDocCopy: IContentDoc = JSON.parse(JSON.stringify(contentDoc));
	contentDocCopy.componentsById = Object.entries(contentDocCopy.componentsById).reduce((componentsById, [entityId, entity]) => {
		if (isAbstractComponent(entity)) {
			componentsById[entityId] = entity;
		} else {
			if (!entity?.isHidden) {
				componentsById[entityId] = entity;
			}
		}

		// Remove hidden entities from list of children of scene
		if (
			entity.type === IComponentType.Scene || 
			entity.type === IComponentType.ScreenAnchorGroup || 
			entity.type === IComponentType.FaceLandmarkGroup
		) {

			entity.children = entity.children.filter((entityId) => {
				return !(contentDocCopy.componentsById[entityId] as ISpatialComponentUnion).isHidden;
			});
		}

		// Remove any actions that reference a hidden entitiy
		if (!isAbstractComponent(entity)) {
			if (entity.actions) {
				const actionsDict = entity.actions || {};
				Object.entries(actionsDict).forEach(([triggerType, actionArray]) => {
					// Remove any actions whose target ID is a hidden component
					actionsDict[triggerType] = actionArray.filter((action) => {
						if (action.type == IActionCategory.animateModel || action.type == IActionCategory.playVideo) {
							const targetId = action.targetIds[0]; // Only 1 per action
							if ((contentDocCopy.componentsById[targetId] as ISpatialComponentUnion).isHidden) {
								return false;
							}
						}
						return true;
					});
				});
				// It's possible we've removed ALL actions for a given trigger, if so remove that key from the actionsDict
				Object.keys(actionsDict).forEach((trigger) => {
					if (actionsDict[trigger].length == 0) delete actionsDict[trigger];
				});
				// It's also possible we've removed ALL actions for ALL trigggers, if so remove the actions property from the entity
				if (Object.keys(actionsDict).length == 0) delete (entity as ISpatialComponentUnion).actions;
			}
		}

		return componentsById;
	}, {} as { [id: string]: IComponentUnion });
	return contentDocCopy;
};

/**
 * Removes any scenes that cannot be reached, including all SR and AR spatial and abstract components
 */
export const removeUnlinkedScenes = (contentDoc: IContentDoc): IContentDoc => {
	const contentDocCopy: IContentDoc = JSON.parse(JSON.stringify(contentDoc));
	const rootId = contentDocCopy.rootComponentId;
	const sceneIds = getScenes(contentDocCopy, true);
	sceneIds.forEach((sceneId) => {
		if (!isSceneReferencedFromAnother(contentDocCopy.componentsById, sceneId) && !isFirstScene(contentDocCopy, sceneId)) {
			// console.log(`Scene ${sceneId} is not referenced from any other`);
			let entitiesToRemove = (contentDocCopy.componentsById[sceneId] as ISceneComp).children;
			const screenContentComponentId = getScreenContentIdForScene(contentDocCopy.componentsById[sceneId] as ISceneComp, contentDocCopy.componentsById);
			if (screenContentComponentId) {
				const anchorGroups = (contentDocCopy.componentsById[screenContentComponentId] as IScreenContent).children;
				entitiesToRemove = [...entitiesToRemove, ...anchorGroups, screenContentComponentId];
				anchorGroups.forEach((anchorGroupId) => {
					const srEntities = (contentDocCopy.componentsById[anchorGroupId] as IScreenAnchorGroup).children;
					entitiesToRemove = [...entitiesToRemove, ...srEntities];
				});
			}
			// console.log(entitiesToRemove);

			entitiesToRemove.forEach((entityId) => {
				delete contentDocCopy.componentsById[entityId];
			});
			delete contentDocCopy.componentsById[sceneId];
			(contentDocCopy.componentsById[rootId] as IRoot).children = (contentDocCopy.componentsById[rootId] as IRoot).children.filter((id) => id !== sceneId);
		}
	});
	return contentDocCopy;
};

const isFirstScene = (contentDoc: IContentDoc, sceneId: string) => {
	const rootId = contentDoc.rootComponentId;
	return (contentDoc.componentsById[rootId] as IRoot).children[0] == sceneId;
};

/**
 * Get all the scenes in the content doc
 */
function getScenes(contentDoc: IContentDoc, onlyIds: true): string[];
function getScenes(contentDoc: IContentDoc, onlyIds: false): ISceneComp[];
function getScenes(contentDoc: IContentDoc, onlyIds = false) {
	return Object.entries(contentDoc.componentsById).reduce((scenes, [entityId, entity]) => {
		if (entity.type == IComponentType.Scene && onlyIds) (scenes as string[]).push(entityId);
		if (entity.type == IComponentType.Scene && !onlyIds) (scenes as ISceneComp[]).push(entity);
		return scenes;
	}, []);
}

/**
 * Return true if sceneId is referenced from another scene ( false if it is unlinked )
 */
const isSceneReferencedFromAnother = (componentsById: { [id: string]: IComponentUnion }, sceneId: string): boolean => {
	// Only way to link to a scene is via a linkScene action
	return Object.entries(componentsById).reduce((isReferenced, [_entityId, entity]) => {
		if (isAbstractComponent(entity)) return isReferenced;
		if (hasLinkSceneActionReference(entity, sceneId)) isReferenced = true;
		return isReferenced;
	}, false);
};

/**
 * Return true if entity has a linkScene action which references sceneId
 */
const hasLinkSceneActionReference = (entity: ISpatialComponentUnion, sceneId: string) => {
	const actions = entity.actions;
	if (!actions) return false;
	return Object.entries(actions).reduce((hasLinkSceneAction, [_trigger, actionData]) => {
		if (
			actionData.reduce((isLinkScene, action) => {
				if (action.type == IActionCategory.linkScene && action.sceneId == sceneId) isLinkScene = true;
				return isLinkScene;
			}, false)
		) {
			hasLinkSceneAction = true;
		}
		return hasLinkSceneAction;
	}, false);
};

export const getAnchorGroupIdAtPosition = (position: IScreenAnchorPositionType, componentsById: { [id: string]: IComponentUnion }, screenContentId: string) => {
	const anchorGroupIds = (componentsById[screenContentId] as IScreenContent).children;
	for (let i = 0; i < anchorGroupIds.length; i++) {
		const anchorGroup = componentsById[anchorGroupIds[i]];
		if ((anchorGroup as IScreenAnchorGroup).anchorPositionType == position) {
			return anchorGroupIds[i];
		}
	}
	return '';
};

/**
 * Remove allAnimationNames property from Model3d entities
 */
export const removeAllAnimationNamesFromModel3ds = (contentDoc: IContentDoc): IContentDoc => {
	const contentDocCopy: IContentDoc = JSON.parse(JSON.stringify(contentDoc));
	for (const component of Object.values(contentDocCopy.componentsById)) {
		if (component.type !== IComponentType.Model3d) continue;
		if(component.animations) delete (component as any).animations;
	}
	return contentDocCopy;
}

export const getComponentParentIdById = (id: string, componentsById: { [k: string]: IComponentUnion }): string | null => {
	const [ parentId = null ] = Object.keys(componentsById).filter(_id => {
		const component = componentsById[_id];
		return isAbstractComponent(component) && component.children.includes(id)
	})
	return parentId;
}

export const getChildlessAbstractComponentIds = (componentsById: { [k: string]: IComponentUnion }) => {
	return Object.keys(componentsById).filter((id) => {
		const component = componentsById[id];
		return isAbstractComponent(component) && component.type !== IComponentType.ScreenContent && component.children.length === 0;
	});
};

export const getOrphanComponentIds = (componentsById: { [k: string]: IComponentUnion }) => {
	const orphanIds = [] as string[];
	const allChildren = [] as string[];

	// Get all children
	Object.entries(componentsById).forEach(([_componentId, component]) => {
		if (isAbstractComponent(component)) {
			allChildren.push(...component.children);	// This includes abstract components but thats ok ( don't want to remove them if they're referenced )
		}
	});

	// Get any orphan IDs
	Object.entries(componentsById).forEach(([componentId, _component]) => {
		if (!isAbstractComponent) {
			if (!allChildren.includes(componentId)) {
				orphanIds.push(componentId);
			}
		}
	});
	return orphanIds;
}

