import { useThree } from '@react-three/fiber';
import React, { memo, useEffect } from 'react';
import { Color, DoubleSide, LineBasicMaterial, LineSegments, SphereGeometry } from 'three';
import { COLORS } from '../../../../utils';
import { sphereToQuads } from './dummy360-utils';
interface IParentProps {
    radius: number;
}

const Dummy360Content: React.FunctionComponent<IParentProps> = ({ radius }) => {
    const  { scene } = useThree()

    useEffect(() => {
        const g = new SphereGeometry(radius, 60, 40);
        sphereToQuads(g);
        const m = new LineBasicMaterial();
        m.color = new Color(COLORS.ocean).convertLinearToSRGB();
        const o = new LineSegments(g, m);
        scene.add(o);
        return () => {scene.remove(o)};
    }, [radius, scene])

    return (
        <mesh>
            <sphereGeometry args={[radius, 60, 40]} />
            <meshBasicMaterial polygonOffset polygonOffsetFactor={10} color={'#f2f2f2'} side={DoubleSide} />
        </mesh>
    );
}
export default memo(Dummy360Content);