import { Vector3, Object3D, MathUtils } from "three";
import { ARThreeScene } from "../components/r3f/EditorCanvas/EditorCanvas";
import { ITuple3 } from "../components/r3f/r3f-components/component-data-structure";

export const getInitialRotationAndZPositionIn360Scene = () => {
    const camera = ARThreeScene.getObjectByProperty('isCamera', true);
    if (!camera) return;
    const dir = camera.getWorldDirection(new Vector3()).multiplyScalar(5);
    const pos = camera.position.clone().add(dir);
       
    const obj = new Object3D();
    obj.position.copy(pos);
    obj.lookAt(camera.position)
    const rotation = [MathUtils.radToDeg(obj.rotation.x), MathUtils.radToDeg(obj.rotation.y), MathUtils.radToDeg(obj.rotation.z)] as ITuple3;
    return {pos, rotation}
}

