export const colors = [
	{
		id: 'green',
	},
	{
		id: 'red',
		exclude: ['salmon', 'darkred'],
	},
	{
		id: 'salmon',
		exclude: ['red'],
	},
	{
		id: 'darkred',
		exclude: ['black', 'red'],
	},
	{
		id: 'black',
		exclude: ['darkred', 'darkblue'],
	},
	{
		id: 'blue',
		exclude: ['darkblue'],
	},
	{
		id: 'darkblue',
		exclude: ['blue', 'black'],
	},
];
