import { Html } from '@react-three/drei';
import { Vector3 } from '@react-three/fiber';
import React, { FunctionComponent, useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IDesignerState } from '../../../../typings';
import { IMultipleEntityProps_Cn_Doc, onSetMultipleComponentProps_Cn_Doc } from '../../../store/actions';
import { buttonTitleShouldBeUpdated, textTitleShouldBeUpdated } from '../../../utils';
import TextArea from '../../dom/Inputs/TextArea/TextArea';
import { ISpatialComponentUnion, ITuple2 } from '../r3f-components/component-data-structure';
import { IComponentType } from '../r3f-components/component-data-structure';
import CSS from './FloatingInput.scss';

interface IParentProps {
	position: Vector3;
	text: string;
	onBlur?: (e: React.FocusEvent<HTMLTextAreaElement | null>) => unknown;
	onUnmount: () => unknown;
}

const FloatingInput: FunctionComponent<IParentProps> = ({ position, text, onBlur, onUnmount }) => {
	const dispatch = useDispatch();
	const selectedEntityIds = useSelector((state: IDesignerState) => state.userReducer.selectedEntityIds!);
	const componentsById = useSelector((state: IDesignerState) => state.contentReducer.contentDoc.componentsById);
	const currentTitle = useSelector((state: IDesignerState) => (state.contentReducer.contentDoc.componentsById[selectedEntityIds[0]] as ISpatialComponentUnion).title);
	const isButton = componentsById[selectedEntityIds[0]].type === IComponentType.Button;
	const wrapperRef = useRef<HTMLDivElement | null>(null);
	const timerRef = useRef<number | null>(null);
	const [margins, setMargins] = useState<ITuple2>([0, 0]);

	useEffect(() => {
		return () => {
			if (timerRef.current) window.clearTimeout(timerRef?.current);
			onUnmount?.();
		};
	}, []);

	const onBlurHandler = (e: React.FocusEvent<HTMLTextAreaElement | null>) => {
		if (onBlur) onBlur(e);
	};

	const onMountHandler = (textAreaRef: React.MutableRefObject<HTMLTextAreaElement | null>) => {
		if (!textAreaRef?.current) return;
		setMargins([textAreaRef.current.offsetWidth / 2, textAreaRef.current.offsetHeight / 2]);
		timerRef.current = window.setTimeout(() => textAreaRef?.current?.focus?.(), 150);
		if (!ResizeObserver) return;

		function outputsize() {
			if (!textAreaRef?.current) return;
			setMargins([textAreaRef.current.offsetWidth / 2, textAreaRef.current.offsetHeight / 2]);
		}
		new ResizeObserver(outputsize).observe(textAreaRef.current);
	};

	return (
		<Html position={position} ref={wrapperRef}>
			<TextArea
				value={text}
				className={CSS.TextInput}
				style={{
					marginLeft: `-${margins[0]}px`,
					marginTop: `-${margins[1]}px`,
					opacity: !!margins[0] && !!margins[1] ? 1 : 0,
				}}
				focused={true}
				stopPropagation={true}
				onChange={(e) => {
					const multiplePropsCnDocPayload: IMultipleEntityProps_Cn_Doc = {
						id: selectedEntityIds[0],
						text: e.target.value,
					};
					if (isButton) {
						// Set the title for this button if required
						if (buttonTitleShouldBeUpdated(currentTitle, e.target.value)) {
							multiplePropsCnDocPayload.title = e.target.value;
						}
					} else {
						// Is text - set the title for this button if required
						if (textTitleShouldBeUpdated(currentTitle, e.target.value)) {
							multiplePropsCnDocPayload.title = e.target.value;
						}
					}
					dispatch(onSetMultipleComponentProps_Cn_Doc([multiplePropsCnDocPayload]));
				}}
				onBlur={onBlurHandler}
				onMount={onMountHandler}
			/>
		</Html>
	);
};

export default React.memo(FloatingInput);
