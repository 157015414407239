import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Box3, Material, Mesh } from 'three';
import { IDesignerState } from '../../../../../../typings/designer-state';
import { ITrackingTypes, ITuple3 } from '../../../r3f-components/component-data-structure/index';
import { ITargetImageReferenceObject } from '../../../r3f-components/component-data-structure/types/targetImageReferenceObject';
import { useGLTFLoader } from '../../../r3f-components/hooks/useGLTFLoader';

const REFERENCE_OBJECT_DICT = {
	[ITargetImageReferenceObject.wineBottle]: 'https://d1mfzu0xo6h6ih.cloudfront.net/designer/3d-model/wine-bottle-materials-v1.glb',
	[ITargetImageReferenceObject.sodaCan330ml]: 'https://d1mfzu0xo6h6ih.cloudfront.net/designer/3d-model/can-closed-v1.glb',
	[ITargetImageReferenceObject.sodaCan500ml]: 'https://d1mfzu0xo6h6ih.cloudfront.net/designer/3d-model/soda-can-500ml-v1.glb',
	[ITargetImageReferenceObject.sodaBottle2l]: 'https://d1mfzu0xo6h6ih.cloudfront.net/designer/3d-model/soda-bottle-2L-with-cap-v2.glb',
};

const TrackingCurvedReferenceObject: React.FC = () => {
	const referenceObjectPosition = useSelector((state: IDesignerState) => state.userReducer.targetImageReferenceObjectPosition ?? state.contentReducer.contentDoc.targetImageReferenceObject?.position);
	const referenceObject = useSelector((state: IDesignerState) => state.contentReducer.contentDoc.targetImageReferenceObject?.referenceObject || ITargetImageReferenceObject.none);
	const trackingImageRadius = useSelector((state: IDesignerState) => state.contentReducer.contentDoc?.tracking?.[ITrackingTypes.image]?.topRadius || 0);
	const trackingImageOpacity = useSelector((state: IDesignerState) => state.contentReducer.contentDoc.tracking?.[ITrackingTypes.image]?.opacity ?? 1);
	const [scaleFactor, setScaleFactor] = useState<number>(1);
	const [height, setHeight] = useState<number>(0);
	const [yPos, setYPos] = useState<number>(0);
	const { scene } = useGLTFLoader(REFERENCE_OBJECT_DICT[referenceObject]);
	scene.traverseVisible((node) => {
		if (node.type === "Mesh") {
			if ('material' in (node as Mesh)) {				
				((node as Mesh).material as Material).transparent = true;
				((node as Mesh).material as Material).opacity = trackingImageOpacity;
			}
		}
	});

  useEffect(() => {
    const nativeScale = new Box3().setFromObject(scene).max;
		if (!nativeScale) return;
		const scaleFactor = trackingImageRadius / nativeScale.x;
		setScaleFactor(scaleFactor-0.05);	// 0.05 to prevent z-fighting between target mesh and reference object 
		setHeight(scaleFactor * nativeScale.y);
	}, [scene, trackingImageRadius]);

	useEffect(() => {
    if (!referenceObjectPosition) return;
		const max = 1;
		const min = -height - 1;
		const range = Math.abs(max) + Math.abs(min);
		const newYPos = (referenceObjectPosition / 100) * range + min;
		setYPos(newYPos);
	}, [referenceObjectPosition, referenceObject, height]);

	if (trackingImageRadius == 0) return null;
	return (
		<group scale={[scaleFactor, scaleFactor, scaleFactor]} position={[0, yPos, 0] as ITuple3}>
			<primitive key={referenceObject} object={scene} />
		</group>
	);
};

export default TrackingCurvedReferenceObject;
