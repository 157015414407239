import React from 'react';
import { IIconParentProps } from './index';
import { COLORS } from '../../utils';

const arrowSmallIcon: React.SFC<IIconParentProps> = ({
	className,
	hexFill: fill,
	viewBox,
	title,
	width,
	height,
	onClick = () => {},
}) => {
	const hexFill = fill || COLORS.midnight;
	return (
    <svg
      onClick={onClick}
      className={className || ""}
      width={width ? width : "4px"}
      height={height ? height : "6px"}
      viewBox={viewBox ? viewBox.join(" ") : "0 0 4 6"}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>{title}</title>
      <path
        d="M0.333421 5.10001C0.149329 5.26569 0.149329 5.53432 0.333421 5.7C0.517512 5.86569 0.815984 5.86569 1.00008 5.7L3.66669 3.30002C3.85079 3.13434 3.85079 2.86571 3.66669 2.70002C3.4826 2.53434 3.18413 2.53434 3.00004 2.70002L0.333421 5.10001Z"
        fill={hexFill}
      />
      <path
        d="M1.00023 0.299978C0.816135 0.134294 0.517663 0.134294 0.333571 0.299978C0.14948 0.465662 0.14948 0.734289 0.333572 0.899973L3.00019 3.29996C3.18428 3.46564 3.48275 3.46564 3.66685 3.29996C3.85094 3.13427 3.85094 2.86564 3.66685 2.69996L1.00023 0.299978Z"
        fill={hexFill}
      />
    </svg>
  );
};

export default arrowSmallIcon;
