import React, { CSSProperties } from 'react';
import CSS from './GhostBtn.scss';

interface IParentProps {
	children?: JSX.Element | JSX.Element[] | string;
	className?: string;
	onClick?: (e: React.MouseEvent) => unknown;
	style?: CSSProperties;
	disabled?: boolean;
	onMouseEnter?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => unknown;
	onMouseLeave?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => unknown;
}

const GhostButton = ({ children, onClick, className, style = {}, disabled = false, onMouseEnter, onMouseLeave }: IParentProps) => {
	const classes = [CSS.GhostBtn, className ? className : ''];
	return (
		<button
			style={{ ...style }}
			className={classes.join(' ')}
			disabled={disabled}
			onMouseEnter={onMouseEnter}
			onMouseLeave={onMouseLeave}
			onClick={(e) => {
				if (onClick) onClick(e);
			}}
		>
			{children}
		</button>
	);
};

export default GhostButton;
