import React from 'react';
import { COLORS } from '../../utils';
import { IIconParentProps } from './index';

const leftIcon: React.FunctionComponent<IIconParentProps> = ({
	className,
	hexFill: fill,
	viewBox,
	onClick = () => {},
}) => {
	const hexFill = fill || COLORS.midnight;
	return (
        <svg 
            onClick={onClick}
            className={className || ''}
            width="16px" 
            height="16px" 
            viewBox={viewBox ? viewBox.join(' ') : '0 0 16 16'}
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
        >
            <rect width="16" height="16" fill="none"/>
            <rect x="5" y="8" width="6" height="1" fill={hexFill} />
        </svg>
	);
};

export default leftIcon;





