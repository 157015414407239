import { useFrame, extend } from '@react-three/fiber';
import React, { useRef, FunctionComponent, memo, useMemo, useEffect } from 'react';
import { Group } from 'three';
import {EmitterGroup} from '../../../threejs-particle-system/src/v1/core/EmitterGroup'
import { IEmitterGroupWrapperProps } from '../types';

// Extend so the reconciler will learn about it
extend({ EmitterGroup })

const EmitterGroupWrapper: FunctionComponent<IEmitterGroupWrapperProps> = ({
    args: a, children: emitter, position, rotation, scale, renderOrder
}) => {
    const threeGroupRef = useRef<Group>(null);
    const args = useMemo(() => a?.[0], [])
    const emitterGroupRef = useRef<EmitterGroup | undefined>(new EmitterGroup(args!));

    // const emitterAreaColor = emitterArea?.color;


    // Update particle group with new delta time to move particles
    useFrame((_, delta) => {
        emitterGroupRef.current?.tick(delta);
    })

    // Add emitter to group, dispose and remove before unmount
    useEffect(() => {
        const threeGroup = threeGroupRef.current;
        const emitterGroup = emitterGroupRef.current;
        if (!threeGroup || !emitterGroup) return;
        emitterGroup.mesh.renderOrder =  renderOrder;
        threeGroup.add(emitterGroup.mesh);

        return () => {
            emitterGroup.dispose();
            threeGroup.remove(emitterGroup.mesh)  
        }
    }, [renderOrder])

    useEffect(() => {
        return () => {
            emitterGroupRef.current = undefined; // Make sure GC frees emitter group memory on unmount
        } 
    }, [])

    // Add emitter to emitter group, remove before adding a new one
    useEffect(() => {
        const emitterGroup = emitterGroupRef.current;
        if (!emitterGroup || !emitter) return;  

        emitterGroup.addEmitter(emitter)
        return () => {
            emitterGroup.removeEmitter(emitter);
        }
    }, [emitter])

    return (
        <group name='emitter-wrapper-group' ref={threeGroupRef} position={position} scale={scale} rotation={rotation} />
    );
}

export default memo(EmitterGroupWrapper);