import React, { useState, useRef, useEffect } from 'react';
import CSS from './TextArea.scss';

interface IParentProps {
	style?: React.CSSProperties;
	testId?: string;
	className?: string;
	error?: boolean;
	value: string;
	focused?: boolean;
	placeholder?: string;
	stopPropagation?: boolean;
	disabled?: boolean;
	onChange?: (e: React.ChangeEvent<HTMLTextAreaElement | null>) => unknown;
	onFinalChange?: (e: React.KeyboardEvent<HTMLTextAreaElement | null> | React.FocusEvent<HTMLTextAreaElement>) => unknown;
	onBlur?: (e: React.FocusEvent<HTMLTextAreaElement | null>) => unknown;
	onFocus?: (e: React.FocusEvent<HTMLTextAreaElement | null>) => unknown;
	onKeyUp?: (e: React.KeyboardEvent<HTMLTextAreaElement | null>) => unknown;
	onKeyDown?: (e: React.KeyboardEvent<HTMLTextAreaElement | null>) => unknown;
	onPointerDown?: (e: React.PointerEvent<HTMLTextAreaElement | null>) => unknown;
	onMount?: (ref: React.MutableRefObject<HTMLTextAreaElement | null>) => unknown;
}

const TextArea: React.FunctionComponent<IParentProps> = ({
	style,
	testId,
	className,
	value,
	onChange,
	onFinalChange,
	onFocus,
	focused = false,
	stopPropagation = false,
	disabled = false,
	onBlur,
	onKeyUp,
	onKeyDown,
	onPointerDown,
	onMount,
	error,
	placeholder,
}) => {
	const textAreaRef = useRef<HTMLTextAreaElement | null>(null);
	const [userInput, setUserInput] = useState<boolean>(false);
	const [tempValue, setTempValue] = useState<string>(value);
	const classes = [CSS.TextArea, className ? className : '', disabled ? CSS.Disabled : ''];

	useEffect(() => {
		if (!userInput && tempValue !== value) setTempValue(value);
	}, [userInput, tempValue, value]);

	useEffect(() => {
		if (focused && textAreaRef.current) textAreaRef.current.focus();
	}, [focused, textAreaRef]);

	useEffect(() => {
		if (onMount && textAreaRef.current !== null) onMount(textAreaRef);
	}, [textAreaRef]);

	const _onFocus = (e: React.FocusEvent<HTMLTextAreaElement>) => onFocus?.(e);

	const _onBlur = (e: React.FocusEvent<HTMLTextAreaElement>) => {
		setUserInput(false);
		onBlur?.(e);
		if (onFinalChange && tempValue !== value) onFinalChange(e);
	};

	const _onChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
		setUserInput(true);
		onChange?.(e);
		setTempValue(e.target.value);
	};

	const _onKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
		if (stopPropagation) e.stopPropagation();
		if (e.key === 'Enter' && !(e.metaKey || e.shiftKey) && textAreaRef.current) {
			e.preventDefault();
			textAreaRef.current.blur();
		}
		if (onKeyDown) onKeyDown(e);
	};

	const _onPointerDown = (e: React.PointerEvent<HTMLTextAreaElement>) => {
		if (stopPropagation) e.stopPropagation();
		onPointerDown?.(e);
	};

	return (
		<textarea
			data-testid={testId ? testId : 'TextArea'}
			ref={textAreaRef}
			style={{ ...style, borderColor: error ? 'red' : '' }}
			className={classes.join(' ')}
			value={tempValue}
			placeholder={placeholder}
			disabled={disabled || undefined}
			onFocus={_onFocus}
			onBlur={_onBlur}
			onChange={_onChange}
			onKeyDown={_onKeyDown}
			onKeyUp={(e) => {
				if (onKeyUp) onKeyUp(e);
			}}
			onPointerDown={_onPointerDown}
		/>
	);
};

export default TextArea;
