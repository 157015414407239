import React, { forwardRef } from 'react';
import { Color, MeshBasicMaterial } from 'three';
import useFaceMeshLoader from './utils/useFaceMeshLoader';

const HeadMesh = forwardRef((_props, ref) => {
    const mesh = useFaceMeshLoader({
        faceMeshLoaderOptions: { }, 
        material: new MeshBasicMaterial({color: new Color(0.549, 0.549, 0.549) }),
        type: 'head'
    })

    return <primitive ref={ref} name={'zappar-head-mesh'} object={mesh} />

})
HeadMesh.displayName = 'headMesh'
  
export default HeadMesh;