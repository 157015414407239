import React from "react";
import { IIconParentProps } from "./index";
import { COLORS } from "../../utils";

const Globe16: React.FunctionComponent<IIconParentProps> = ({ width, height, className, hexFill = COLORS.midnight, viewBox, onClick = () => {} }) => {
  return (
    <svg
      onClick={onClick}
      className={className || ""}
      width={width ? width : "16px"}
      height={height ? height : "16px"}
      viewBox={viewBox ? viewBox.join(" ") : `0 0 16 16`}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <path
        d="M8 0C12.4183 0 16 3.58172 16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0ZM3.67318 11.201L1.54885 11.2009C2.50055 13.1153 4.27933 14.546 6.41762 15.0256C5.09521 14.0221 4.18034 12.7475 3.67318 11.201ZM14.4511 11.2009L12.3268 11.201C11.8197 12.7475 10.9048 14.0221 9.58222 15.0249C11.7207 14.546 13.4994 13.1153 14.4511 11.2009ZM7.6 11.2L4.51935 11.2004C5.08245 12.7324 6.10564 13.9464 7.59965 14.8642L7.6 11.2ZM11.4804 11.2011L8.4 11.2L8.40086 14.8639C9.89436 13.9463 10.9173 12.7326 11.4804 11.2011ZM3.45238 5.6001L1.20958 5.60033C0.944328 6.35091 0.8 7.1586 0.8 8C0.8 8.8417 0.944429 9.64966 1.20986 10.4005L3.45256 10.4007C3.28418 9.65659 3.2 8.85636 3.2 8C3.2 7.14395 3.28413 6.34398 3.45238 5.6001ZM11.7259 5.60005L8.4 5.6V10.4L11.7257 10.4007C11.8887 9.74672 11.9788 9.04152 11.9967 8.28377L12 8C12 7.13413 11.9089 6.3348 11.7259 5.60005ZM14.7904 5.60033L12.5476 5.6001C12.7159 6.34398 12.8 7.14395 12.8 8C12.8 8.85636 12.7158 9.65659 12.5474 10.4007L14.7901 10.4005C15.0556 9.64966 15.2 8.8417 15.2 8C15.2 7.1586 15.0557 6.35091 14.7904 5.60033ZM7.6 5.6L4.27415 5.60005C4.09106 6.3348 4 7.13413 4 8C4 8.86618 4.09113 9.66578 4.27434 10.4007L7.6 10.4V5.6ZM6.41778 0.975052L6.34911 0.990157C4.24106 1.48471 2.48988 2.9056 1.54845 4.79993L3.67291 4.79977C4.18003 3.25297 5.09498 1.97806 6.41778 0.975052ZM8.40116 1.13629L8.4 4.8L11.4809 4.80045C10.918 3.2683 9.89499 2.05415 8.40116 1.13629ZM9.58238 0.974439L9.76726 1.11926C10.9907 2.09933 11.844 3.32616 12.3271 4.79977L14.4515 4.79993C13.4999 2.88512 11.721 1.45404 9.58238 0.974439ZM4.51905 4.80045L7.6 4.8L7.59965 1.1358C6.10538 2.05372 5.08209 3.26803 4.51905 4.80045Z"
        fill={hexFill}
      />
    </svg>
  );
};

export default Globe16;
