import React, { memo } from 'react';
import { IFaceLandmark } from '../../r3f-components/component-data-structure';
import { getFaceLandmarkPositionsByHeadbustType } from '../../r3f-components/utils/face-tracking';
import FaceLandmarkHotspot from './FaceLandmarkHotspot';

const FaceLandmarkHotspots: React.FunctionComponent = () => {
    const positionDict = getFaceLandmarkPositionsByHeadbustType('uar')

    const hotspots = Object.entries(positionDict).map(([landmark, position]) => {
        return <FaceLandmarkHotspot key={landmark} landmark={landmark as IFaceLandmark} position={position}/>
    })

    return (
        <>
            {hotspots}
        </>
    );
}
export default memo(FaceLandmarkHotspots);