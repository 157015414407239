import { IOldToNewIdDict } from '../../../typings/general';
import {
	IAbstractComponentUnion,
	IComponentsById,
	IComponentType,
	IComponentUnion,
	ISceneComp,
	ISceneScopedAbstractComponentUnion,
	ISpatialComponentUnion,
	ROOT_COMPNENT_ID,
} from '../../components/r3f/r3f-components/component-data-structure/index';
import { isAbstractComponent } from '../../components/r3f/r3f-components/utils/general';
import { getAbstractComponentId, getIsComponentHasTargetActions, getSceneIdByComponentId, getTargetIdsForSpatialComponent, getValidTargetIdsOnScene } from './get';

/**
 * Use this function to generate dictionary that maps old IDs to new IDs for use when copying components
 * @param args.componentToCopy - The component that is being copied
 * @param args.componentsById - The current state of componentsById in content doc ( won't be mutated )
 * @param args.targetSceneId - The ID of the scene that arg.componentToCopy is being pasted on ( not required if ISceneComp )
 * @param args.parentId - The ID of the parent component of args.componentToCopy, only required if it is an abstract component
 * @param args.debug -  If true, will log details throughout execution
 */
export function generateOldToNewIdDict(args: { componentToCopy: ISceneComp; componentsById: IComponentsById; parentId: string; debug?: boolean }): IOldToNewIdDict | null;
export function generateOldToNewIdDict(args: { componentToCopy: ISpatialComponentUnion; componentsById: IComponentsById; targetSceneId: string; debug?: boolean }): IOldToNewIdDict | null;
export function generateOldToNewIdDict(args: {
	componentToCopy: ISceneScopedAbstractComponentUnion;
	componentsById: IComponentsById;
	targetSceneId: string;
	parentId: string;
	debug?: boolean;
}): IOldToNewIdDict | null;
export function generateOldToNewIdDict(args: {
	componentToCopy: IComponentUnion;
	componentsById: IComponentsById;
	targetSceneId?: string;
	parentId?: string;
	debug?: boolean;
}): IOldToNewIdDict | null {
	// console.log(args);
	const { componentToCopy, componentsById, targetSceneId, parentId, debug = false } = args;
	const componentsByIdCp = JSON.parse(JSON.stringify(componentsById)) as IComponentsById;
	const componentToCopyCp = JSON.parse(JSON.stringify(componentToCopy)) as IComponentUnion;

	const componentToCopyType = componentToCopyCp.type;
	if (!componentToCopyType) {
		if (debug) console.log('generateOldToNewIdDict -> componentToCopyCp.type is falsey. componentToCopyCp', componentToCopyCp);
		return null;
	}

	switch (componentToCopyType) {
		// Spatial
		case IComponentType.Button:
		case IComponentType.Image:
		case IComponentType.Text:
		case IComponentType.Video:
		case IComponentType.Model3d:
		case IComponentType.Text3d:
		case IComponentType.Emitter:
			if (!targetSceneId) {
				if (debug) console.log('generateOldToNewIdDict -> no target scene id for spatial component: ', componentToCopyCp);
				return null;
			}
			return generateOldToNewIdDictForSpatialComponent({ componentToCopy: componentToCopyCp, componentsById: componentsByIdCp, targetSceneId });

		// Abstract, Parent
		case IComponentType.FaceLandmarkGroup:
		case IComponentType.ScreenAnchorGroup:
		case IComponentType.ScreenContent:
			if (!parentId) {
				if (debug) console.log('generateOldToNewIdDict -> No source scene ID for abstract component');
				return null;
			} // Required to look up componentToCopyCp.id as not stored on abstract components
			return generateOldToNewIdDictForAbstractComponent({ componentToCopy: componentToCopyCp, componentsById: componentsByIdCp, parentId, targetSceneId: targetSceneId! });
		case IComponentType.Scene:
			return generateOldToNewIdDictForAbstractComponent({ componentToCopy: componentToCopyCp, componentsById: componentsByIdCp, parentId: ROOT_COMPNENT_ID });

		// Invalid
		case IComponentType.Root:
			if (debug) {
				console.log('generateOldToNewIdDict -> Received Root component type - invalid.');
			}
			return null;
	}
}

export function generateOldToNewIdDictForAbstractComponent(args: { componentToCopy: ISceneComp; componentsById: IComponentsById; parentId: string; debug?: boolean }): IOldToNewIdDict | null;
export function generateOldToNewIdDictForAbstractComponent(args: {
	componentToCopy: ISceneScopedAbstractComponentUnion;
	componentsById: IComponentsById;
	parentId: string;
	targetSceneId: string;
	existingDict?: IOldToNewIdDict;
	debug?: boolean;
}): IOldToNewIdDict | null;
export function generateOldToNewIdDictForAbstractComponent(args: {
	componentToCopy: ISceneScopedAbstractComponentUnion | ISceneComp;
	componentsById: IComponentsById;
	parentId: string;
	targetSceneId?: string;
	existingDict?: IOldToNewIdDict;
	debug?: boolean;
}): IOldToNewIdDict | null {
	const { componentToCopy, componentsById, parentId, existingDict, debug = false } = args;
	let { targetSceneId } = args;
	let retDict = {} as IOldToNewIdDict;
	const componentsByIdCp = JSON.parse(JSON.stringify(componentsById)) as IComponentsById;
	const componentToCopyCp = JSON.parse(JSON.stringify(componentToCopy)) as IAbstractComponentUnion;

	const sourceSceneId = componentToCopy.type === IComponentType.ScreenAnchorGroup ? getSceneIdByComponentId(parentId, componentsByIdCp) : parentId;
	if (!sourceSceneId) {
		if (debug) console.log('generateOldToNewIdDictForAbstractComponent -> Unable to trace source scene');
		return null;
	}
	const abstractComponentId = getAbstractComponentId(componentToCopy, sourceSceneId, componentsByIdCp);
	if (!abstractComponentId) {
		if (debug) console.log('generateOldToNewIdDictForAbstractComponent -> no abstract component id');
		return null;
	}
	retDict[abstractComponentId] = crypto.randomUUID();

	if (!targetSceneId) {
		// copying a scene
		targetSceneId = retDict[abstractComponentId] as string;
	}

	for (const childId of componentToCopyCp.children) {
		const child = componentsByIdCp[childId];
		if (child.type === IComponentType.Root || child.type === IComponentType.Scene) continue; // Should never happen ( scenes cannot be children of other scenes )
		const childDict = isAbstractComponent(child)
			? generateOldToNewIdDictForAbstractComponent({
					componentToCopy: child,
					componentsById: componentsByIdCp,
					parentId: abstractComponentId,
					targetSceneId,
					existingDict: { ...existingDict, ...retDict },
			  })
			: generateOldToNewIdDictForSpatialComponent({ componentToCopy: child, componentsById: componentsByIdCp, targetSceneId, existingDict: { ...existingDict, ...retDict } });
		retDict = { ...retDict, ...childDict };
	}
	return retDict;
}

export const generateOldToNewIdDictForSpatialComponent = (args: {
	componentToCopy: ISpatialComponentUnion;
	componentsById: IComponentsById;
	targetSceneId: string;
	existingDict?: IOldToNewIdDict;
	debug?: boolean;
}): IOldToNewIdDict | null => {
	const { componentToCopy, componentsById, targetSceneId, existingDict, debug = false } = args;
	if (debug) console.log('generateOldToNewIdDictForSpatialComponent -> targetSceneId', args.targetSceneId);
	if (debug) console.log('generateOldToNewIdDictForSpatialComponent -> componentToCopy', args.componentToCopy);

	const retDict = {} as IOldToNewIdDict;
	const componentsByIdCp = JSON.parse(JSON.stringify(componentsById)) as IComponentsById;
	const componentToCopyCp = JSON.parse(JSON.stringify(componentToCopy)) as ISpatialComponentUnion;

	// Assign new ID for component
	retDict[componentToCopyCp.id] = crypto.randomUUID();
	
	const targetIds = getTargetIdsForSpatialComponent(componentToCopyCp);
	const isSelfReferrentialTargetAction = targetIds.length === 1 && targetIds[0] === componentToCopyCp.id;

	// If this component has no target actions or target action only referring to itself, then just return
	if (!getIsComponentHasTargetActions(componentToCopyCp) || isSelfReferrentialTargetAction) return retDict;

	// Deal with target action IDs
	const validTargetIdsOnTargetScene = getValidTargetIdsOnScene(componentsByIdCp, targetSceneId);
	const componentCurrentTargetIds = getTargetIdsForSpatialComponent(componentToCopyCp);
	for (const potentialTargetId of componentCurrentTargetIds) {
		if (validTargetIdsOnTargetScene?.includes(potentialTargetId)) {
			retDict[potentialTargetId] = potentialTargetId;
		} else {
			if (!existingDict || !existingDict[potentialTargetId]) {
				retDict[potentialTargetId] = null;
			}
		}
	}
	return retDict;
};
