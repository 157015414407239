import { IComponentsById, IComponentType, ISceneComp, ISpatialComponentUnion, ITrackingTypes, ITuple3 } from '../../components/r3f/r3f-components/component-data-structure/index';
import { store } from '../../store';
import { SCREEN_RELATIVE_CANVAS_SIZE_FACTOR } from '../constants';
import { getInitialRotationAndZPositionIn360Scene } from '../content360';
import { getSceneIdByComponentId, getSREntityScale } from './get';
import { getEntityDropCoordinates } from './getEntityDropCoordinates';

// Returns componentsById with spatialComponent added to parentId ( including any magic )
export const insertSpatialComponent = <T extends ISpatialComponentUnion>(args: {
	spatialComponent: T;
	parentId: string;
	sourceSceneIsAr: boolean;
	sourceSceneId?: string;
	isFlatOrientation: boolean;
	componentsById: IComponentsById;
	offset?: ITuple3;
	newTitle?: string;
	newRenderOrder?: number;
	debug?: boolean;
	adjustPositionToCamera?: boolean;
}): IComponentsById | null => {
	const { spatialComponent, parentId, componentsById, sourceSceneIsAr, sourceSceneId, isFlatOrientation, newTitle, newRenderOrder, offset, debug = false, adjustPositionToCamera = true } = args;
	if (debug) console.log('spatialComponent: ', spatialComponent);
	if (debug) console.log('sourceSceneIsAr: ', sourceSceneIsAr);
	const componentsByIdCp = JSON.parse(JSON.stringify(componentsById)) as IComponentsById;
	let spatialComponentCp = JSON.parse(JSON.stringify(spatialComponent));

	// We can only insert a spatial component into a Scene, ScreenAnchorGroup or FaceLandmarkGroup
	const parentComponent = componentsByIdCp[parentId];
	if (parentComponent.type !== IComponentType.Scene && parentComponent.type !== IComponentType.ScreenAnchorGroup && parentComponent.type !== IComponentType.FaceLandmarkGroup) {
		if (debug) {
			console.log('insertSpatialComponent - debug - return null because parent not a scene, face landmarkgroup or screen anchor group');
		}
		return null;
	}

	// What type of scene are we pasting onto - this is only relevant on AR layer
	const targetSceneId = getSceneIdByComponentId(parentId, componentsByIdCp);
	if(debug) console.log('insertSpatialComponent -> targetSceneId is : ', targetSceneId, ' looked up with parentId: ', parentId);
	if (!targetSceneId) {
		if (debug) {
			console.log('insertSpatialComponent - debug - target scene id is null. parentId is: ', parentId);
		}
		return null;
	}

	const sourceSceneTrackingType = sourceSceneId ? (componentsByIdCp[sourceSceneId] as ISceneComp).trackingType : ITrackingTypes.world; //TODO - verify this is safe
	const targetSceneTrackingType = (componentsByIdCp[targetSceneId] as ISceneComp).trackingType;
	const arFaceToArImageOrWorld = sourceSceneTrackingType === ITrackingTypes.face && targetSceneTrackingType !== ITrackingTypes.face && sourceSceneIsAr;

	const arToSr = parentComponent.type === IComponentType.ScreenAnchorGroup && sourceSceneIsAr;
	const srToAr = parentComponent.type !== IComponentType.ScreenAnchorGroup && !sourceSceneIsAr;
	if (debug) console.log('arToSr: ', arToSr, 'srToAr', srToAr);

	if (arToSr) {
		if (debug) console.log('AR to SR');
		spatialComponentCp = adjustSpatialComponentArToSr(spatialComponentCp);
	}
	if (srToAr || arFaceToArImageOrWorld) {
		if (debug) console.log('SR to AR  ||  arFaceToArImageOrWorld');
		spatialComponentCp = adjustSpatialComponentSrToAr(spatialComponentCp, componentsByIdCp, targetSceneId, parentId, isFlatOrientation);
	}
	if ((componentsByIdCp[targetSceneId] as ISceneComp).trackingType === ITrackingTypes.image) {
		if (debug) console.log('Target scene is image');
		spatialComponentCp = adjustSpatialComponentForImageTrackedInsert(spatialComponentCp);
	}
	// If we have a new title for the spatial component ( copy/paste or duplicate scenario ) then apply that now
	if (newTitle) spatialComponentCp.title = newTitle;

	// If we have a new render order then apply that now
	if (newRenderOrder) spatialComponentCp.renderOrder = newRenderOrder;

	// If we have an offset ( copy / paste scenario ) then apply this now
	const { position } = spatialComponentCp;
	if (position && offset && sourceSceneId === targetSceneId) {
		if (debug) console.log('insertSpatialComponent -> add offset: sourceSceneId: ', sourceSceneId, ' targetSceneId: ', targetSceneId);
		spatialComponentCp.position = [position[0] + offset[0], position[1] + offset[1], position[2] + offset[2]];
	}

	// Set position and rotation to face camera for 360 scenes
	if ((componentsByIdCp[targetSceneId] as ISceneComp).trackingType === ITrackingTypes.content360 && adjustPositionToCamera) {
		if (debug) console.log('Target scene is 360');
		spatialComponentCp = adjustSpatialComponentFor360SceneInsert(spatialComponentCp);
	}

	// Add the component to it's parent and componentsById
	if (!parentComponent.children.includes(spatialComponentCp.id)) parentComponent.children.push(spatialComponentCp.id);
	componentsByIdCp[spatialComponentCp.id] = spatialComponentCp;
	return componentsByIdCp;
};

// Make adjustments required when inserting a spatial component that came from an AR scene to a SR scene
const adjustSpatialComponentArToSr = (spatialComponent: ISpatialComponentUnion) => {
	const spatialComponentCp = JSON.parse(JSON.stringify(spatialComponent));

	// Need to set position to [0,0] in Screen mode
	spatialComponentCp.position = [0, 0, 0];

	// Retain z-rotation, reset other axis
	spatialComponentCp.rotation = [0, 0, spatialComponentCp.rotation[2]];

	// Resize entities copied from AR to SR to fit
	const originalScale = [...spatialComponentCp.scale];
	spatialComponentCp.scale = getSREntityScale(spatialComponentCp, SCREEN_RELATIVE_CANVAS_SIZE_FACTOR); // NB this isn't pure

	// Resize border width if applicable
	if ('borderWidth' in spatialComponentCp && spatialComponentCp.borderWidth) {
		const originalBorderWidth = spatialComponentCp.borderWidth;
		const adjustmentRatio = originalScale[0] / spatialComponentCp.scale[0];
		spatialComponentCp.borderWidth = adjustmentRatio < 1 ? adjustmentRatio * originalBorderWidth : originalBorderWidth / adjustmentRatio;
	}

	// Resize font size if applicable
	if ('fontSize' in spatialComponentCp && spatialComponentCp.fontSize) {
		const originalFontSize = spatialComponentCp.fontSize;
		const adjustmentRatio = originalScale[0] / spatialComponentCp.scale[0];
		spatialComponentCp.fontSize = adjustmentRatio < 1 ? adjustmentRatio * originalFontSize : originalFontSize / adjustmentRatio;
	}

	// Remove curvature if applicable
	if ('curvature' in spatialComponentCp && spatialComponentCp.curvature) {
		spatialComponentCp.curvature = 0;
		spatialComponentCp.isSnappedToTarget = false;
	}
	return spatialComponentCp;
};

const adjustSpatialComponentForImageTrackedInsert = (spatialComponent: ISpatialComponentUnion) => {
	const spatialComponentCp = JSON.parse(JSON.stringify(spatialComponent));
	if ('isSnappedToTarget' in spatialComponentCp && !!spatialComponentCp.isSnappedToTarget) {
		// If the source component WAS snapped to target, reset curvature to 0, as it may have been > 100% when wrapped
		spatialComponentCp.curvature = 0;
		spatialComponentCp.isSnappedToTarget = false;
	}
	return spatialComponentCp;
};

const adjustSpatialComponentFor360SceneInsert = (spatialComponent: ISpatialComponentUnion) => {
	const spatialComponentCp = JSON.parse(JSON.stringify(spatialComponent)) as ISpatialComponentUnion; 
	const {pos, rotation} = getInitialRotationAndZPositionIn360Scene() || {};
	if (!pos || !rotation) return;
	spatialComponentCp.position = [pos.x, pos.y, pos.z]
	spatialComponentCp.rotation = rotation;
	return spatialComponentCp;
}

const adjustSpatialComponentSrToAr = (spatialComponent: ISpatialComponentUnion, componentsById: IComponentsById, targetSceneId: string, parentId: string, isFlatOrientation: boolean) => {
	const spatialComponentCp = JSON.parse(JSON.stringify(spatialComponent));
	const sceneTrackingType = (componentsById[targetSceneId] as ISceneComp).trackingType;
	const isScreenRelativeMode = componentsById[parentId].type === IComponentType.ScreenAnchorGroup;
	if (!sceneTrackingType) return null;
	// Place at 'wherever double clicking from entity menu' if it comes from a screen scene
	const { position, rotation } = getEntityDropCoordinates({ entity: spatialComponentCp, sceneTrackingType, isFlatOrientation, isScreenRelativeMode, isCurvedImageTrackedScene: false });
	spatialComponentCp.position = position;
	spatialComponentCp.rotation = rotation;
	return spatialComponentCp;
};
