import React from 'react';
import { IIconParentProps } from './index';
import { COLORS } from '../../utils';

const minusIcon: React.SFC<IIconParentProps> = ({
	hexFill: fill,
	viewBox,
	className,
	onClick = () => {},
}) => {
	const hexFill = fill || COLORS.midnight;
	return (
		<svg
			onClick={onClick}
			className={className || ''}
			width="9px"
			height="1px"
			viewBox={viewBox ? viewBox.join(' ') : `0 0 9 1`}
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
		>
			<path
				fill={hexFill}
				d="M1706.5,988 C1706.77614,988 1707,988.223858 1707,988.5 C1707,988.74546 1706.82312,988.949608 1706.58988,988.991944 L1706.5,989 L1698.5,989 C1698.22386,989 1698,988.776142 1698,988.5 C1698,988.25454 1698.17688,988.050392 1698.41012,988.008056 L1698.5,988 L1706.5,988 Z"
				transform="translate(-1698 -988)"
			/>
		</svg>
	);
};

export default minusIcon;
