import { useMemo, useState } from "react";
import { AnimationClip, Mesh, Object3D, WebGLRenderer } from "three";
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader';
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { KTX2Loader } from "three/examples/jsm/loaders/KTX2Loader";
import * as utils from "three/examples/jsm/utils/SkeletonUtils";
import { convertGltf } from "../utils/convertGltf";


// It is recommended to always pull your Draco JavaScript and WASM decoders
// from this URL. Users will benefit from having the Draco decoder in cache
// as more sites start using the static URL.
// const dracoLoader = new DRACOLoader().setDecoderPath('https://www.gstatic.com/draco/versioned/decoders/1.4.1/');
// const gltfLoader = new GLTFLoader().setCrossOrigin('anonymous').setDRACOLoader(dracoLoader);
const dracoLoader = new DRACOLoader();
dracoLoader.setDecoderPath('https://d1mfzu0xo6h6ih.cloudfront.net/designer/draco/1.5.6/');
dracoLoader.setCrossOrigin('anonymous');
dracoLoader.preload();
const gltfLoader = new GLTFLoader();
gltfLoader.setDRACOLoader(dracoLoader);
(gltfLoader as any).setCrossOrigin('anonymous');

const ktx2Loader = new KTX2Loader();
ktx2Loader.setTranscoderPath('https://d1mfzu0xo6h6ih.cloudfront.net/designer/ktx2/7ce26e9/');
const renderer = new WebGLRenderer();
ktx2Loader.detectSupport( renderer );
gltfLoader.setKTX2Loader(ktx2Loader);

interface IGltfData {
    scene: Object3D;
    animations: AnimationClip[];
    meshToSkinnedMeshUuidDict: { [id: string]: string };
}

type IPendingData = {
  status: 'pending';
  promise: Promise<any>;
}

type ICompleteData = {
  status: 'complete';
  gltfData: IGltfData;
}

type IErrorData = {
  status: 'error';
  error: Error;
}

// global cache outside of react
const modelCache: {
  [url: string]: IPendingData | ICompleteData | IErrorData
} = {};

// fill the global model cache or model error cache if not yet filled for url
const createModelLoadPromise = (
  url: string,
  onError?: () => any
): Promise<any> => {
  
  
  const modelData = modelCache[url];
  
  if (modelData?.status === 'pending') return modelData.promise;
  if (modelData?.status === 'complete') return Promise.resolve();
  
  // we are using the custom gltfloader which always uses textureLoader instead of 
  // the imageBitmapLoader. Latter does not always release memory coorectly leading resulting in app crashing
  // (see https://discourse.threejs.org/t/error-with-the-gltfloader-unable-to-load-texture-blob/32465)
  const loadPromise = new Promise(res => {
    gltfLoader
      .load(
        url,
        function (gltf) {
          if (modelCache[url]?.status !== 'complete') {
            modelCache[url] = {
              status: 'complete',
              gltfData: convertGltf(gltf)
            };
          }
          res(true);
        },
        undefined,
        function () {
          modelCache[url] = {
            status: 'error',
            error: new Error("model load error")
          }
          onError?.();
          //rej(false)
        }
      );
  });
  modelCache[url] = {
    status: 'pending',
    promise: loadPromise
  }
  return loadPromise;
};


export const preloadGltf = (urls: string[]): Promise<any> => {
  const promises: Promise<any>[] = [];
  // create array of promises
  // console.log("run preloadGltf");
  for (let i = 0; i < urls.length; i++) {
    const url = urls[i];
    if (!!modelCache[url]?.status) continue;
    const modelLoadPromise = createModelLoadPromise(url);
    promises.push(modelLoadPromise);
  }
  return promises.length
    ? Promise.all([...promises])
    : new Promise((res) => res(true));
};

interface IGLTFLoaderOptions {
  castShadow?: boolean;
  receiveShadow?: boolean;
}

export const useGLTFLoader = (url: string, options?: IGLTFLoaderOptions): {
    scene: Object3D;
    animations: AnimationClip[];
    skinnedMeshUuidToMesh: { [id: string]: Mesh };
} => {
  const {castShadow, receiveShadow} = options ?? {};

  // state used to trigger rerender in order to throw async errors into render phase
  const [_, triggerRender] = useState(0);


  const modelData = modelCache[url];

  // return model immediately if in cache
  if (modelData?.status === 'complete') {
    const {
        scene: modelScene,
        meshToSkinnedMeshUuidDict,
        animations
    } = modelData.gltfData;

    return useMemo(() => {
        // use Skeleton utils to safely clone skinned mesh (and other) models
        const clonedScene = (utils as any).clone(modelScene) as Object3D;
        const oldToNewUuidDict: { [id: string]: string } = {};

        // add render order to mesh if material(s) are transparent
        clonedScene.traverse((node: any) => {
            if (node.isSkinnedMesh || node.isMesh) {
              // map old uuid to new uuids of clone
              oldToNewUuidDict[node.userData.origUuid] = node.uuid;
              if (castShadow) node.castShadow = true;
              if (receiveShadow) node.receiveShadow = true;
            }
        });

        // fill skinnedMeshUUidToMesh dict to map source skinned mesh uuids to derived meshes
        // this is needed to update meshes with skinned mesh morph attribues during animations
        const skinnedMeshUuidToMesh: { [id: string]: Mesh } = {};
        for (const meshUuid in meshToSkinnedMeshUuidDict) {
            const newMeshUuid = oldToNewUuidDict[meshUuid];
            const oldSkinnedMeshUuid = meshToSkinnedMeshUuidDict[meshUuid];
            const newSkinnedMeshUuid = oldToNewUuidDict[oldSkinnedMeshUuid];
            const mesh = clonedScene.getObjectByProperty("uuid", newMeshUuid) as Mesh;
            if (newSkinnedMeshUuid) skinnedMeshUuidToMesh[newSkinnedMeshUuid] = mesh;
        }

        return {scene: clonedScene, animations, skinnedMeshUuidToMesh}
    }, [url, modelScene, animations, castShadow, receiveShadow])

  }

  // if async error throw error into render phase (triggered via triggerRender)
  if (modelData?.status === 'error') {
    throw modelData.error;
    // delete globalErrorCache[url];
    // throw error;
  }

  // throw promise for suspense to catch and also trigger re-render once error
  // occurs to throw async error in new render phase
  throw createModelLoadPromise(url, () => {
    console.log("prelading error");
    triggerRender((prevState) => prevState + 1);
  });
};

export const getAnimationsOfLoadedModel = (url: string): AnimationClip[] => {
  const modelData = modelCache[url] as ICompleteData;
  const { animations } = modelData?.gltfData ?? {};

  return animations;
}
