import { Dispatch } from 'redux';
import { MathUtils } from 'three';
import { IDesignerState, IToastSize, IToastType } from '../../../typings';
import {
	IAbstractComponentUnion,
	IButtonCategory,
	IComponentType,
	IContentDoc,
	IFaceLandmark,
	IFaceLandmarkGroup,
	IRoot,
	ISceneComp,
	IScreenAnchorGroup,
	IScreenAnchorPositionType,
	IScreenContent,
	ISpatialComponentUnion,
	ITrackingTypes,
	ROOT_COMPNENT_ID,
} from '../../components/r3f/r3f-components/component-data-structure';
import { getFaceLandmarkIdsBySceneId } from '../../components/r3f/r3f-components/hooks/useGetScreenRelativeComponentIds';
import { store } from '../../store';
import { DEFAULT_SCENE_NAME } from '../../utils/constants';
import { getSnapshotDictFromLStorage, symmetricDifference, updateLStorageWithSnapshot } from '../../utils/general';
import { getChildlessAbstractComponentIds } from '../../utils/pure';
import { getEntityDropTitle, getSceneTitles } from '../../utils/pure/get';
import {
	IAddEntityIdsToSelectionAction,
	IContentDocActionTypes,
	IDisableUndoAction,
	IOnAddEntity_Cn_Doc_Action,
	IOnAddEntity_Global,
	IOnAddScenes_Global,
	IOnAddToastAction,
	IOnCopyScenesAtIndex_Cn_Doc_Action,
	IOnCopyScenesAtIndex_Global,
	IOnPasteCopiedEntities_Global,
	IOnRemoveEntities_Cn_Doc_Action,
	IOnRemoveEntities_Global,
	IOnRemoveImageTracking_Cn_Doc_Action,
	IOnRemoveLocalUserSelectionAction,
	IOnRemoveSceneReferences_Cn_Doc_Action,
	IOnRemoveScenes_Global,
	IOnSetEnlargeSceneMenuAction,
	IOnSetIs3dModeAction,
	IOnSetMultipleComponentProps_Cn_Doc_Action,
	IPasteCopiedEntities_Cn_Doc_Action,
	IReplaceEntity_Cn_Doc_Action,
	ISetActiveSceneAction,
	ISetCurrentSceneIndexAction,
	ISetSceneSnapshotsAction,
	IUserActionTypes,
} from './action-types';

export const onRemoveEntities_Global: IOnRemoveEntities_Global = (entityIds: string[], onlyAbstract = false) => {
	return (dispatch: Dispatch) => {
		if (!onlyAbstract) {
			// If only abstract then don't deselect the spatial entity if there is one selected
			dispatch<IOnRemoveLocalUserSelectionAction>({
				type: IUserActionTypes.REMOVE_LOCAL_USER_SELECTION,
			});
		}
		dispatch<IDisableUndoAction>({
			type: IUserActionTypes.DISABLE_UNDO_BTN,
			payload: false,
		});
		dispatch<IOnRemoveEntities_Cn_Doc_Action>({
			type: IContentDocActionTypes.REMOVE_ENTITIES_CN_DOC,
			payload: { ids: entityIds },
		});

		const { componentsById } = store.getState().contentReducer.contentDoc;
		const childlessAbstractComponentIds = getChildlessAbstractComponentIds(componentsById);

		dispatch<IOnRemoveEntities_Cn_Doc_Action>({
			type: IContentDocActionTypes.REMOVE_ENTITIES_CN_DOC,
			payload: { ids: childlessAbstractComponentIds },
		});
	};
};

export const onReplaceEntity_Global = (currentlySelectedEntityId: string, replacementEntity: ISpatialComponentUnion) => {
	const entity = store.getState().contentReducer.contentDoc.componentsById[currentlySelectedEntityId] as ISpatialComponentUnion;
	entity.id = currentlySelectedEntityId;
	return (dispatch: Dispatch) => {
		dispatch<IReplaceEntity_Cn_Doc_Action>({
			type: IContentDocActionTypes.REPLACE_ENTITY_CN_DOC,
			payload: {
				entity,
				replacementEntity,
			},
		});
	};
};

export const onAddEntity_Global: IOnAddEntity_Global = ({ parentId, entity, disableAutoSelection }) => {
	return (dispatch: Dispatch) => {
		const activeSceneId = store.getState().userReducer.activeSceneId!;
		const { componentsById } = store.getState().contentReducer.contentDoc;

		// If parentId is a ScreenAnchorPositionType then we're adding to a SR layer
		const isScreenRelative = Object.values(IScreenAnchorPositionType).includes(parentId as IScreenAnchorPositionType);
		// If parentId is a FaceLandmark then we're adding to an AR layer on a Face Tracked scene
		const isFaceTrackingAr = Object.values(IFaceLandmark).includes(parentId as IFaceLandmark);

		if (isScreenRelative) {
			const screenContentId = (componentsById[activeSceneId] as ISceneComp).children.filter((id) => componentsById[id].type === IComponentType.ScreenContent)[0];
			const existingScreenContent = componentsById[screenContentId] as IScreenContent;
			const isScreenRelativeAnchorGroupExists =
				(componentsById[screenContentId] as IScreenContent).children.filter(
					(id) => componentsById[id].type === IComponentType.ScreenAnchorGroup && (componentsById[id] as IScreenAnchorGroup).anchorPositionType === parentId
				).length > 0;

			if (isScreenRelativeAnchorGroupExists) {
				// Get tht group's ID and add to that group
				const existingAnchorGroupId = (componentsById[screenContentId] as IScreenContent).children.filter(
					(id) => componentsById[id].type === IComponentType.ScreenAnchorGroup && (componentsById[id] as IScreenAnchorGroup).anchorPositionType === parentId
				)[0];
				const existingAnchorGroup = componentsById[existingAnchorGroupId] as IScreenAnchorGroup;
				dispatch<IOnSetMultipleComponentProps_Cn_Doc_Action>({
					type: IContentDocActionTypes.SET_MULTIPLE_COMPONENT_PROPS_CN_DOC,
					payload: [
						{
							...existingAnchorGroup,
							id: existingAnchorGroupId,
							children: [...existingAnchorGroup.children, entity.id],
						},
						{
							...(entity as any),
							id: entity.id,
						},
					],
				});
			} else {
				const newId = MathUtils.generateUUID();
				const newAnchorGroup: IScreenAnchorGroup = {
					anchorPositionType: parentId as IScreenAnchorPositionType,
					type: IComponentType.ScreenAnchorGroup,
					children: [entity.id],
				};

				dispatch<IOnSetMultipleComponentProps_Cn_Doc_Action>({
					type: IContentDocActionTypes.SET_MULTIPLE_COMPONENT_PROPS_CN_DOC,
					payload: [
						{
							...existingScreenContent,
							id: screenContentId,
							children: [...existingScreenContent.children, newId],
						},
						{
							...newAnchorGroup,
							id: newId,
						},
						{
							...(entity as any),
							id: entity.id,
						},
					],
				});
			}
		}

		if (isFaceTrackingAr) {
			const isFaceLandmarkExists =
				(componentsById[activeSceneId] as IScreenContent).children.filter(
					(id) => componentsById[id].type === IComponentType.FaceLandmarkGroup && (componentsById[id] as IFaceLandmarkGroup).landmark === parentId
				).length > 0;

			if (isFaceLandmarkExists) {
				// Get tht group's ID and add to that group
				const existingLandmarkGroupId = (componentsById[activeSceneId] as IScreenContent).children.filter(
					(id) => componentsById[id].type === IComponentType.FaceLandmarkGroup && (componentsById[id] as IFaceLandmarkGroup).landmark === parentId
				)[0];
				const existingLandmarkGroup = componentsById[existingLandmarkGroupId] as IFaceLandmarkGroup;
				dispatch<IOnSetMultipleComponentProps_Cn_Doc_Action>({
					type: IContentDocActionTypes.SET_MULTIPLE_COMPONENT_PROPS_CN_DOC,
					payload: [
						{
							...existingLandmarkGroup,
							id: existingLandmarkGroupId,
							children: [...existingLandmarkGroup.children, entity.id],
						},
						{
							...(entity as any),
							id: entity.id,
						},
					],
				});
			} else {
				const newId = MathUtils.generateUUID();
				const existingScene = JSON.parse(JSON.stringify(componentsById[activeSceneId] as ISceneComp));
				const newLandmarkGroup: IFaceLandmarkGroup = {
					landmark: parentId as IFaceLandmark,
					type: IComponentType.FaceLandmarkGroup,
					children: [entity.id],
				};

				dispatch<IOnSetMultipleComponentProps_Cn_Doc_Action>({
					type: IContentDocActionTypes.SET_MULTIPLE_COMPONENT_PROPS_CN_DOC,
					payload: [
						{
							...existingScene,
							id: activeSceneId,
							children: [...existingScene.children, newId],
						},
						{
							...newLandmarkGroup,
							id: newId,
						},
						{
							...(entity as any),
							id: entity.id,
						},
					],
				});
			}
		}

		if (!isFaceTrackingAr && !isScreenRelative) {
			// If not face tracking or SR just add entity to parent id
			dispatch<IOnAddEntity_Cn_Doc_Action>({
				type: IContentDocActionTypes.ADD_ENTITY_CN_DOC,
				payload: {
					parentId,
					entity,
				},
			});
		}

		dispatch<IOnRemoveLocalUserSelectionAction>({
			type: IUserActionTypes.REMOVE_LOCAL_USER_SELECTION,
		});

		if (!disableAutoSelection) {
			setTimeout(() => {
				dispatch<IAddEntityIdsToSelectionAction>({
					type: IUserActionTypes.ADD_ENTITY_IDS_TO_SELECTION,
					payload: [entity.id],
				});
			}, 700);
		}
	};
};

export const onCopyScenesAtIndex_Global: IOnCopyScenesAtIndex_Global = ({ projectId, sceneIds, index }) => {
	return (dispatch: Dispatch, getState: () => IDesignerState) => {
		dispatch<ISetSceneSnapshotsAction>({
			type: IUserActionTypes.SET_SCENE_SNAPSHOTS,
			payload: getSnapshotDictFromLStorage(localStorage, projectId),
		});

		const rootChildrenBefore = ((getState().contentReducer.contentDoc as IContentDoc).componentsById[ROOT_COMPNENT_ID] as IRoot).children as string[];
		dispatch<IOnCopyScenesAtIndex_Cn_Doc_Action>({
			type: IContentDocActionTypes.COPY_SCENES_AT_INDEX_CN_DOC,
			payload: { sceneIds, index, adjustPositionToCamera: false },
		});

		setTimeout(() => {
			const rootChildrenAfter = ((getState().contentReducer.contentDoc as IContentDoc).componentsById[ROOT_COMPNENT_ID] as IRoot).children as string[];
			const newSceneId = Array.from(symmetricDifference(new Set(rootChildrenBefore), new Set(rootChildrenAfter)))[0];

			dispatch<ISetActiveSceneAction>({
				type: IUserActionTypes.SET_ACTIVE_SCENE,
				payload: { activeSceneId: newSceneId },
			});
			updateLStorageWithSnapshot(newSceneId, projectId);
		}, 0);
	};
};

export const onAddScenes_Global: IOnAddScenes_Global = ({ sceneTrackingType }) => {
	return (dispatch: Dispatch, getState: () => IDesignerState) => {
		const componentDict = getState().contentReducer?.contentDoc?.componentsById;
		const rootId = getState().contentReducer?.contentDoc?.rootComponentId;
		const rootChildren = [...((componentDict?.[rootId] as IRoot)?.children || [])];
		const isFirstLoad = rootChildren.length === 0;
		const index = isFirstLoad ? getState().userReducer.currentSceneIndex : getState().userReducer.currentSceneIndex + 1;
		const projectId = getState().userReducer.project.id;
		const sceneTitles = getSceneTitles(JSON.parse(JSON.stringify(componentDict)));
		const prospectiveTitle = getEntityDropTitle(sceneTitles, `${DEFAULT_SCENE_NAME} 1`, true);
		// Deal with this edge case here rather than confuse the getEntityDropTitle further
		const title = prospectiveTitle == 'Scene' ? 'Scene 1' : prospectiveTitle;

		const newScreenContentId = MathUtils.generateUUID();
		const newSceneId = MathUtils.generateUUID();
		rootChildren.splice(index, 0, newSceneId);

		dispatch<IOnSetMultipleComponentProps_Cn_Doc_Action>({
			type: IContentDocActionTypes.SET_MULTIPLE_COMPONENT_PROPS_CN_DOC,
			payload: [
				{
					id: rootId,
					children: rootChildren,
				},
				{
					id: newSceneId,
					type: IComponentType.Scene,
					title,
					trackingType: sceneTrackingType,
					children: [newScreenContentId],
				},
				{
					id: newScreenContentId,
					type: IComponentType.ScreenContent,
					children: [],
				},
			],
		});

		if (sceneTrackingType === ITrackingTypes.world) {
			dispatch<IOnSetIs3dModeAction>({
				type: IUserActionTypes.SET_3D_MODE,
				payload: true,
			});
		}
		// Set current scene to new one
		dispatch<ISetActiveSceneAction>({
			type: IUserActionTypes.SET_ACTIVE_SCENE,
			payload: {
				activeSceneId: newSceneId,
			},
		});
		// Increment for next add scene action
		dispatch<ISetCurrentSceneIndexAction>({
			type: IUserActionTypes.SET_CURRENT_SCENE_INDEX,
			payload: index,
		});
		if (!isFirstLoad) {
			//	Remove anything selected as we'll be on new scene
			dispatch({
				type: IUserActionTypes.REMOVE_SELECTION,
			});
			// Only applicable when adding additional scene
			dispatch<IOnSetEnlargeSceneMenuAction>({
				type: IUserActionTypes.SET_SCENE_MENU_LARGE,
				payload: true,
			});
			if (projectId) {
				setTimeout(() => {
					updateLStorageWithSnapshot(newSceneId, projectId, sceneTrackingType === ITrackingTypes.noTrackingScreen); // Add snapshot to local storage
					dispatch<ISetSceneSnapshotsAction>({
						type: IUserActionTypes.SET_SCENE_SNAPSHOTS,
						payload: getSnapshotDictFromLStorage(localStorage, projectId),
					});
				}, 1000);
			}
		}
	};
};

export const onRemoveScenes_Global: IOnRemoveScenes_Global = (sceneIds: string[]) => {
	const rootId = store.getState().contentReducer.contentDoc.rootComponentId;
	const componentDict = store.getState().contentReducer.contentDoc.componentsById;
	const rootComponent = componentDict[rootId] as IAbstractComponentUnion;

	return (dispatch: Dispatch, getState: () => IDesignerState) => {
		if (rootComponent.children.length === 1) return;
		let componentIdsToDelete = sceneIds; // delete scenes
		for (let i = 0; i < sceneIds.length; i++) {
			let screenRelativeComponents: string[] = [];
			const sceneChildren = (componentDict[sceneIds[i]] as IAbstractComponentUnion).children;
			const sceneSpatialChildren = sceneChildren.filter((id) => componentDict[id].type !== IComponentType.ScreenContent && componentDict[id].type !== IComponentType.FaceLandmarkGroup);
			const sceneScreenContentId = sceneChildren.filter((id) => componentDict[id].type === IComponentType.ScreenContent)[0]; // Only 1
			const anchorGroupIds = (componentDict[sceneScreenContentId] as IScreenContent).children;
			anchorGroupIds.forEach((anchorGroupId) => {
				const children = (componentDict[anchorGroupId] as IScreenAnchorGroup).children;
				screenRelativeComponents = [...screenRelativeComponents, ...children];
			});
			screenRelativeComponents = [...screenRelativeComponents, ...anchorGroupIds, sceneScreenContentId];

			// Face tracking scenes
			const isFaceTrackingScene = componentDict[sceneIds[i]].type === IComponentType.Scene && (componentDict[sceneIds[i]] as ISceneComp).trackingType === ITrackingTypes.face;
			let faceTrackingComponents: string[] = [];
			if (isFaceTrackingScene) {
				const faceLandmarkGroupIds = getFaceLandmarkIdsBySceneId(sceneIds[i], componentDict);
				faceLandmarkGroupIds.forEach((faceLandmarkId) => {
					const children = (componentDict[faceLandmarkId] as IFaceLandmarkGroup).children;
					faceTrackingComponents = [...faceTrackingComponents, ...children];
				});
				faceTrackingComponents = [...faceTrackingComponents, ...faceLandmarkGroupIds];
			}

			componentIdsToDelete = [...componentIdsToDelete, ...sceneSpatialChildren, ...screenRelativeComponents, ...faceTrackingComponents]; // delete entities in scenes
		}

		const firstSceneSelected = getState().userReducer.activeSceneId === rootComponent.children[0];
		const indexOfFirstDeletedScene = rootComponent.children.indexOf(sceneIds[0]);
		const newActiveSceneIndex = firstSceneSelected ? 1 : indexOfFirstDeletedScene - 1;

		const allScenes = rootComponent.children;
		const numImageTrackedScenesInProject = allScenes.reduce((imageTrackedSceneCount, sceneId) => {
			const scene = componentDict[sceneId] as ISceneComp;
			if (scene.trackingType === ITrackingTypes.world) return imageTrackedSceneCount;
			if (scene.trackingType === ITrackingTypes.image) return imageTrackedSceneCount + 1;
			return imageTrackedSceneCount;
		}, 0);

		const numImageTrackedScenesToRemove = sceneIds.reduce((imageTrackedScenesToRemoveCount, sceneId) => {
			const scene = componentDict[sceneId] as ISceneComp;
			if (scene.trackingType === ITrackingTypes.world) return imageTrackedScenesToRemoveCount;
			if (scene.trackingType === ITrackingTypes.image) return imageTrackedScenesToRemoveCount + 1;
			return imageTrackedScenesToRemoveCount;
		}, 0);

		if (numImageTrackedScenesInProject === numImageTrackedScenesToRemove) {
			dispatch<IOnRemoveImageTracking_Cn_Doc_Action>({
				type: IContentDocActionTypes.REMOVE_IMAGE_TRACKING_CN_DOC,
			});
		}

		dispatch<IOnRemoveLocalUserSelectionAction>({
			type: IUserActionTypes.REMOVE_LOCAL_USER_SELECTION,
		});
		dispatch<IDisableUndoAction>({
			type: IUserActionTypes.DISABLE_UNDO_BTN,
			payload: false,
		});
		dispatch<ISetActiveSceneAction>({
			type: IUserActionTypes.SET_ACTIVE_SCENE,
			payload: { activeSceneId: rootComponent.children[newActiveSceneIndex] },
		});
		dispatch<ISetCurrentSceneIndexAction>({
			type: IUserActionTypes.SET_CURRENT_SCENE_INDEX,
			payload: newActiveSceneIndex,
		});
		dispatch<IOnRemoveEntities_Cn_Doc_Action>({
			type: IContentDocActionTypes.REMOVE_ENTITIES_CN_DOC,
			payload: { ids: componentIdsToDelete },
		});
		dispatch<IOnRemoveSceneReferences_Cn_Doc_Action>({
			type: IContentDocActionTypes.REMOVE_SCENE_REFERENCES_CN_DOC,
			payload: { sceneIds },
		});
	};
};

export const onPasteCopiedEntities_Global: IOnPasteCopiedEntities_Global = (pld) => {
	const { copiedIds } = pld;

	// If user has copied a 3D model to a Screen scene, this is invalid
	const isScreenRelativeMode = store.getState().userReducer.isScreenRelativeMode;
	const componentsById = store.getState().contentReducer.contentDoc.componentsById;
	const activeSceneId = store.getState().userReducer.activeSceneId;
	const isTargetSceneImageTracked2d =
		!store.getState().userReducer.is3dMode && (store.getState().contentReducer.contentDoc.componentsById[String(activeSceneId)] as ISceneComp).trackingType === ITrackingTypes.image;
	const isPasteContains3dContent = copiedIds.reduce((acc, id) => {
		return acc || componentsById[id].type === IComponentType.Model3d || componentsById[id].type === IComponentType.Emitter || componentsById[id].type === IComponentType.Text3d;
	}, false);

	// Loop over all IDs and if SR mode and TYPE is Model3d then filter out
	const filteredCopiedIds = isScreenRelativeMode
		? copiedIds.filter((id) => {
				return componentsById[id].type !== IComponentType.Model3d && componentsById[id].type !== IComponentType.Emitter && componentsById[id].type !== IComponentType.Text3d;
		  })
		: copiedIds;

	if (filteredCopiedIds.length == 0) {
		// If this leaves us with an empty array ( all models - in practice just 1 anyway ) then return a thunk that pops a toast ONLY
		return (dispatch: Dispatch) => {
			dispatch<IOnAddToastAction>({
				type: IUserActionTypes.ADD_TOAST,
				payload: {
					type: IToastType.info,
					message: 'Cannot paste a 3D model, 3D Text or particle emitter onto a screen relative scene.',
					size: IToastSize.default,
					timeout: 5000,
				},
			});
		};
	}

	const cnDocPayload = { ...pld, isScreenRelativeMode };
	return (dispatch: Dispatch) => {
		dispatch<IPasteCopiedEntities_Cn_Doc_Action>({
			type: IContentDocActionTypes.PASTE_COPIED_ENTITIES_CN_DOC,
			payload: cnDocPayload,
		});
		if (isTargetSceneImageTracked2d && isPasteContains3dContent) {
			dispatch<IOnSetIs3dModeAction>({
				type: IUserActionTypes.SET_3D_MODE,
				payload: true,
			});
		}
	};
};
