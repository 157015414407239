import { ThreeEvent } from '@react-three/fiber';
import React, { FunctionComponent, memo, useMemo } from 'react';
import { ITuple3, ITuple4 } from '../../component-data-structure';
import { IUserData } from '../../utils/general';
import RoundedRectWithBorder from '../RoundedRectWithBorder';

interface IParentProps {
	scale: ITuple3;
	fillRgba: ITuple4;
	position: ITuple3;
	rotation: ITuple3;
	renderOrder: number;
	depthWrite?: boolean;
	children?: JSX.Element;
	onPointerDown?: (e: ThreeEvent<PointerEvent>) => unknown;
	onPointerUp?: (e: ThreeEvent<PointerEvent>) => unknown;
}

const PlaceholderEntity: FunctionComponent<IParentProps> = ({ scale, fillRgba, depthWrite = false, children = null, renderOrder, onPointerDown, onPointerUp, ...props }) => {
	const userData: IUserData = useMemo(() => ({ renderOrder, contentId: 'Placeholder' }), [renderOrder]);

	return (
		<group {...props} userData={userData}>
			<RoundedRectWithBorder
				renderOrder={renderOrder}
				scale={scale}
				rotation={[0, 0, 0]}
				position={[0, 0, 0]}
				opacity={1}
				fillRgba={fillRgba}
				depthWrite={depthWrite}
				onPointerUp={onPointerUp || undefined}
				onPointerDown={onPointerDown || undefined}
			/>
			{children}
		</group>
	);
};

export default memo(PlaceholderEntity);
