import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import { IDesignerState } from '../../../../typings';
import { useGetActiveSceneTrackingType } from '../../../utils';
import Grid from '../Grid/Grid';
import { IRoot, ITrackingTypes } from '../r3f-components/component-data-structure';
import ShadowPlane from '../r3f-components/components/ShadowPlane';
import Background360Placeholder from './Background360Placeholder/Background360Placeholder';
import FaceTrackingPlaceholder from './FaceTrackingPlaceholder/FaceTrackingPlaceholder';
import ImageTrackingPlaceholder from './ImageTrackingPlaceholder/ImageTrackingPlaceholder';

const SceneDefaultContent: React.FunctionComponent = () => {
	const isSceneInContentDoc = useSelector((state: IDesignerState) => (state.contentReducer.contentDoc.componentsById[state.contentReducer.contentDoc.rootComponentId] as IRoot)?.children?.length > 0);
	const activeSceneTrackingType = useGetActiveSceneTrackingType();
	const curvedTrackingRadius = useSelector((state: IDesignerState) => state.contentReducer.contentDoc?.tracking?.[ITrackingTypes.image]?.topRadius || 0);

	if (!isSceneInContentDoc) return null;

	switch (activeSceneTrackingType) {
		case ITrackingTypes.image:
			return <>
				<ImageTrackingPlaceholder />
			 	{!!curvedTrackingRadius ? null : <ShadowPlane position={[0, 0, 0]}/>}
			</>;
		case ITrackingTypes.face:
			return <FaceTrackingPlaceholder />;
		case ITrackingTypes.world:
			return <Grid />;
		case ITrackingTypes.content360:
			return <Background360Placeholder />
		case ITrackingTypes.noTrackingScreen:
			return null;

		default:
	}

	return null;
};
export default memo(SceneDefaultContent);
