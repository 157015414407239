import { Center, Text3D } from '@react-three/drei';
import React, { FunctionComponent, memo, useEffect, useMemo, useRef, useState } from 'react';
import { Box3, BufferGeometry, Color, Material, Mesh, NormalBufferAttributes, Vector3 } from 'three';
import { ITuple3, IFontTypes, ITextAlignment, FONT_TYPES, IText3dReactProps } from '../component-data-structure';
import { getBboxScaleByFont, getBboxScaleByTextGeometry, getFontData, getFontJsonUrlByFontFamily, IUserData } from '../utils/general';
import { TextGeometry } from 'three/examples/jsm/geometries/TextGeometry';
import { Font } from 'three/examples/jsm/loaders/FontLoader';

const Text3d: FunctionComponent<IText3dReactProps> = ({
	scale: s,
	fontRgba: rgba,
	text,
	fontFamily = IFontTypes.Roboto400,
	fontSize = 2,
	position,
	rotation,
	textAlignment = ITextAlignment.center,
	onPointerUp,
	onPointerMove,
	onPointerDown,
	onDoubleClick,
	renderOrder,
	depth = 0,
	curveSegments = 12,
	bevelEnabled = false,
	material = 'standard',
	bevelThickness,
	bevelSize,
	bevelOffset,
	bevelSegments = 3,
	id = '',
	raycast,
	castShadow = false,
	receiveShadow = false,
	animationTransformGroupPrefix = '',
    onTextGeometryChange,
    onTextGeometryMount,
    origScale
}) => {
	const scale = useMemo(() => s as ITuple3, [s]);
	const userData: IUserData = useMemo(() => ({ renderOrder, contentId: id }), [id, renderOrder]);
	const fontJsonUrl = getFontJsonUrlByFontFamily(fontFamily);
	const color = rgba ? new Color(`rgb(${rgba[0]}, ${rgba[1]}, ${rgba[2]})`) : undefined;
    const [normScale, setNormScale] = useState<ITuple3>([0, 0, 0])

    const meshRef = useRef<Mesh<BufferGeometry<NormalBufferAttributes>, Material | Material[]>>()
    const fontData = useRef<Font>();
    const oldFontFamily = useRef(fontFamily);


    useEffect(() => {
        const getFontSize = async (fetchFontData: boolean) => {
            oldFontFamily.current = fontFamily
            const jsonUrl = getFontJsonUrlByFontFamily(fontFamily);
            if (!jsonUrl) return
            if (fetchFontData) {
                fontData.current = await getFontData(jsonUrl);
            }
            
            const scale = getBboxScaleByFont(text, fontData.current!, fontSize, depth);
            if (!scale) return;
            const _normScale = (origScale ? [origScale[0] / scale.x, origScale[1] / scale.y, 1] : [1, 1, 1]) as ITuple3;
            if (!text) return;
            setNormScale(_normScale)
            onTextGeometryChange?.(scale);
        }
        getFontSize(!fontData.current || oldFontFamily.current !== fontFamily)
    }, [text, fontFamily, origScale])

	return (
		<group
			key={'text_3d_group'}
			userData={userData}
			name={`${animationTransformGroupPrefix}${id}`}
			raycast={raycast}
			position={position as ITuple3}
			rotation={rotation as ITuple3}
			onPointerUp={onPointerUp || undefined}
			onPointerDown={onPointerDown || undefined}
			onPointerMove={onPointerMove || undefined}
			onDoubleClick={onDoubleClick || undefined}
		>
			<group name={`${animationTransformGroupPrefix}inner_${id}`}>
				<Center 
					left={textAlignment === ITextAlignment.right ? true : undefined}
					right={textAlignment === ITextAlignment.left ? true : undefined}
					cacheKey={`${fontSize}${depth}${text}${scale}${normScale[0]}${normScale[1]}${normScale[2]}`}
				>
                    <group scale={scale} >
                        <group scale={normScale}>
                            <Text3D 
                                font={fontJsonUrl!}
                                ref={self => {
                                    if (!self?.geometry) return;
                                    onTextGeometryMount?.(self.geometry as TextGeometry)
                                }}
                                onUpdate={mesh => {
                                    meshRef.current = mesh;
                                    const s = getBboxScaleByTextGeometry(mesh.geometry as TextGeometry);
                                    const boxScale = origScale ?? [s?.x, s?.y, s?.z]
                                    if (!boxScale || isNaN(boxScale[0])) return;

                                    const x = 1 / boxScale[0];
                                    const y = x * (boxScale[0] / boxScale[1]);
                                    mesh.scale.set(x, y, 1);
                                }}
                                size={fontSize} 
                                height={1} 
                                curveSegments={curveSegments}
                                bevelEnabled={bevelEnabled}
                                bevelThickness={bevelThickness}
                                bevelSize={bevelSize}
                                bevelOffset={bevelOffset}
                                bevelSegments={bevelSegments}
                                castShadow={castShadow}
                                receiveShadow={receiveShadow}
                            >
                                {text}
                                {material === 'normal' ? <meshNormalMaterial /> : <meshStandardMaterial color={color} metalness={0.5} roughness={0.5} />}
                            </Text3D>
                        </group>
                    </group>
				</Center> 
			</group>
		</group>
	);
};

export default memo(Text3d);
