import {
	IAbstractComponentUnion, IComponentsById, IComponentType,
	IComponentUnion, IFaceLandmark, IFaceLandmarkGroup, IRoot, ISceneComp, ISceneScopedAbstractComponentUnion, IScreenAnchorGroup, IScreenAnchorPositionType, IScreenContent,
	ISpatialComponentUnion,
	ITargetActionComponentUnion, ITrackingTypes, ROOT_COMPNENT_ID
} from '../../components/r3f/r3f-components/component-data-structure/index';
import { getScreenContentIdForScene } from '../../components/r3f/r3f-components/utils/pure';

export const getFaceLandmarkIdByFacelandmarkAndSceneId = (landmark: IFaceLandmark | null, sceneId: string, componentsById: IComponentsById): string | null => {
	if (!landmark) return null;
	const scene = componentsById[sceneId] as ISceneComp;
	const [landmarkGroupId = null] = scene.children.filter((id) => {
		const component = componentsById[id];
		return component.type === IComponentType.FaceLandmarkGroup && component.landmark === landmark;
	});
	return landmarkGroupId;
};

export const getScreenAnchorGroupIdByAnchorPositionAndSceneId = (anchorPosition: IScreenAnchorPositionType | null, sceneId: string, componentsById: IComponentsById): string | null => {
	if (!anchorPosition) return null;
	const sceneScreenContentId = getScreenContentIdBySceneId(sceneId, componentsById);
	if (!sceneScreenContentId) return null;
	const anchorGroupId = (componentsById[sceneScreenContentId] as IScreenContent).children.filter((anchorGroupId) => {
		const anchorGroup = componentsById[anchorGroupId];
		return anchorGroup.type === IComponentType.ScreenAnchorGroup && anchorGroup.anchorPositionType === anchorPosition;
	})[0];
	return anchorGroupId;
};

export const getScreenContentIdBySceneId = (sceneId: string, componentsById: IComponentsById): string | null => {
	const scene = componentsById[sceneId] as ISceneComp;
	const screenContentId = scene.children.filter((sceneChildId) => {
		return componentsById[sceneChildId].type === IComponentType.ScreenContent;
	})[0];
	return screenContentId ?? null;
};

export const getSceneId = (scene: ISceneComp, componentsById: IComponentsById): string | null => {
	for (const componentId in componentsById) {
		const prospectiveComponent = componentsById[componentId];
		if (prospectiveComponent.type === IComponentType.Scene && prospectiveComponent.children.includes(scene.children[0])) {
			return componentId;
		}
	}
	// If there is only 1 scene in the project, must be that one if we haven't found one yet
	if ((componentsById[ROOT_COMPNENT_ID] as IRoot).children.length === 1) {
		return (componentsById[ROOT_COMPNENT_ID] as IRoot).children[0];
	}

	// We have no way to figure out which scene this is ( hopefully never reach here )
	return null;
};

export const getAbstractComponentId = (component: IAbstractComponentUnion, sceneId: string, componentsById: IComponentsById): string | null => {
	// console.log('sceneId debug: ', sceneId)
	// console.log('component.type debug: ', component.type)
	switch (component.type) {
		case IComponentType.FaceLandmarkGroup:
			return getFaceLandmarkIdByFacelandmarkAndSceneId(component.landmark, sceneId, componentsById);
		case IComponentType.ScreenAnchorGroup:
			return getScreenAnchorGroupIdByAnchorPositionAndSceneId(component.anchorPositionType, sceneId, componentsById);
		case IComponentType.ScreenContent:
			return getScreenContentIdBySceneId(sceneId, componentsById);
		case IComponentType.Scene:
			return getSceneId(component, componentsById);
	}
	return null;
};

export const getTargetActionIdsForFaceLandmarkGroup = (faceLandmarkGroup: IFaceLandmarkGroup, componentsByIdCp: IComponentsById): string[] => {
	const retArr = [] as string[];
	for (const componentId of faceLandmarkGroup.children) {
		if (getIsValidTargetActionComponentType(componentsByIdCp[componentId])) retArr.push((componentsByIdCp[componentId] as ITargetActionComponentUnion).id);
	}
	return retArr;
};
export const getTargetActionIdsForScreenAnchorGroup = (anchorGroup: IScreenAnchorGroup, componentsByIdCp: IComponentsById): string[] => {
	const retArr = [] as string[];
	for (const componentId of anchorGroup.children) {
		if (getIsValidTargetActionComponentType(componentsByIdCp[componentId])) retArr.push((componentsByIdCp[componentId] as ITargetActionComponentUnion).id);
	}
	return retArr;
};
export const getTargetActionIdsForScreenContentGroup = (screenContentGroup: IScreenContent, componentsByIdCp: IComponentsById): string[] => {
	let retArr = [] as string[];
	for (const componentId of screenContentGroup.children) {
		const anchorGroup = componentsByIdCp[componentId] as IScreenAnchorGroup;
		retArr = [...getTargetActionIdsForScreenAnchorGroup(anchorGroup, componentsByIdCp), ...retArr];
	}
	return retArr;
};

export const getTargetIdsForSpatialComponent = (component: ISpatialComponentUnion): string[] => {
	let retArr = [] as string[];
	for (const triggerType in component.actions) {
		const actionArray = component.actions[triggerType as keyof typeof component.actions];
		if (!actionArray) return retArr;
		for (let i = 0; i < actionArray.length; i++) {
			const action = actionArray[i];
			if ('targetIds' in action) {
				retArr = [...action.targetIds, ...retArr];
			}
		}
	}
	return retArr;
};

export const getValidTargetIdsOnScene = (componentsById: IComponentsById, targetSceneId: string): string[] | null => {
	let retArr = [] as string[];
	const componentsByIdCp = JSON.parse(JSON.stringify(componentsById)) as IComponentsById;
	const scene = componentsByIdCp[targetSceneId];
	if (!scene || scene.type !== IComponentType.Scene) return null;

	// Scene.children
	for (const componentId of scene.children) {
		if (getIsValidTargetActionComponentType(componentsByIdCp[componentId])) retArr.push(componentId);
		const component = componentsByIdCp[componentId];
		if (component.type === IComponentType.FaceLandmarkGroup) {
			retArr = [...getTargetActionIdsForFaceLandmarkGroup(component, componentsByIdCp), ...retArr];
		}
		if (component.type === IComponentType.ScreenContent) {
			retArr = [...getTargetActionIdsForScreenContentGroup(component, componentsByIdCp), ...retArr];
		}
	}
	return retArr;
};

export const getIsComponentHasTargetActions = (component: ISpatialComponentUnion): boolean => {
	if (!('actions' in component)) return false;
	for (const triggerType in component.actions) {
		const actionArray = component.actions[triggerType as keyof typeof component.actions];
		if (!actionArray) return false;
		for (let i = 0; i < actionArray.length; i++) {
			if ('targetIds' in actionArray[i]) return true;
		}
	}
	return false;
};

export const getIsValidTargetActionComponentType = (component: IComponentUnion): boolean => {
	if (!component.type) return false;
	return component.type === IComponentType.Emitter || component.type === IComponentType.Video || component.type === IComponentType.Model3d;
};

export const getSceneIdByComponentId = (needleComponentId: string, componentsById: IComponentsById): string | null => {
	// If needle is itself a scene, return this id
	if (componentsById[needleComponentId].type === IComponentType.Scene) return needleComponentId;
	for (const [componentId, component] of Object.entries(componentsById)) {
		// If component is scene and the needle we're looking for is in it's children array, we've found our sceneId
		if ('children' in component && component.type === IComponentType.Scene && component.children.includes(needleComponentId)) {
			return componentId;
		}
		// If component is face landmark or anchor group and the needle we're looking for is in it's children array, recursively get the sceneId from this group
		if (
			'children' in component &&
			(component.type === IComponentType.FaceLandmarkGroup || component.type === IComponentType.ScreenAnchorGroup || component.type === IComponentType.ScreenContent) &&
			component.children.includes(needleComponentId)
		) {
			return getSceneIdByComponentId(componentId, componentsById);
		}
	}
	return null;
};

export const isAbstractComponentType = (type?: IComponentType): boolean => {
	return !!type && (type === IComponentType.Scene || type === IComponentType.ScreenContent || type === IComponentType.ScreenAnchorGroup || type === IComponentType.FaceLandmarkGroup);
};

export const isSceneOrSceneScopedAbstractComponent = (component: IComponentUnion): component is ISceneScopedAbstractComponentUnion | ISceneComp => {
	return isAbstractComponentType(component.type);
};

export const getAnchorGroupIdAtPositionBySceneId = (sceneId: string, position: IScreenAnchorPositionType, componentsById: IComponentsById) => {
	const scene = componentsById[sceneId] as ISceneComp;
	const screenContentId = getScreenContentIdForScene(scene, componentsById);
	if (!screenContentId) return undefined;
	const anchorGroupIds = (componentsById[screenContentId] as IScreenContent).children;
	for (let i = 0; i < anchorGroupIds.length; i++) {
		const anchorGroup = componentsById[anchorGroupIds[i]];
		if ((anchorGroup as IScreenAnchorGroup).anchorPositionType == position) {
			return anchorGroupIds[i];
		}
	}
};
export const getSREntityScale = (entity: ISpatialComponentUnion, adjustmentFactor: number) => {
	if (entity.scale[0] < adjustmentFactor) {
		// Don't scale up if the entity is smaller than adjustmentFactor
		return [entity.scale[0], entity.scale[1], 0];
	} else {
		const aspectRatio = entity.scale[0] / entity.scale[1];
		return [adjustmentFactor, adjustmentFactor / aspectRatio, 0];
	}
};

export const getActiveSceneFaceLandmarkIdByFacelandmark = (landmark: IFaceLandmark | null, activeSceneId: string, componentsById: IComponentsById): string | null => {
	if (!landmark) return null;
	const activeScene = componentsById[activeSceneId] as ISceneComp;
	const [landmarkId = null] = activeScene.children.filter((id) => {
		const component = componentsById[id];
		return component.type === IComponentType.FaceLandmarkGroup && component.landmark === landmark;
	});
	return landmarkId;
};

export const getActiveSceneAnchorGroupIdByAnchorPositionType = (
	anchorPositionType: IScreenAnchorPositionType | null,
	activeSceneId: string,
	componentsById: { [k: string]: IComponentUnion }
): string | null => {
	if (!anchorPositionType) return null;
	const activeSceneScreenContentId = (componentsById[activeSceneId] as ISceneComp).children.filter((id) => componentsById[id].type === IComponentType.ScreenContent)[0];
	const activeSceneScreenContent = componentsById[activeSceneScreenContentId] as IScreenContent;
	const [anchorGroupId = null] = activeSceneScreenContent.children.filter((id) => {
		const component = componentsById[id];
		return component.type === IComponentType.ScreenAnchorGroup && component.anchorPositionType === anchorPositionType;
	});
	return anchorGroupId;
};

/**
 * Retrieves the abstract parent id for use when pasting a spatial entity. If none already exists returns null
 * Specifically if sceneId scene type is World or Image - returns the sceneId
 * If it is AR / Face then returns the noseTip face landmark group
 * If is is SR then returns the center anchor group if it exists
 */
export const getParentIdForSpatialComponentOnPaste = (args: { sceneId: string; componentsById: IComponentsById; isScreenRelativeMode: boolean, faceLandmark?: IFaceLandmark, screenAnchorGroupPosition?: IScreenAnchorPositionType }) => {
	const { sceneId, componentsById, isScreenRelativeMode, faceLandmark = IFaceLandmark.noseTip, screenAnchorGroupPosition = IScreenAnchorPositionType.center } = args;
	const componentsByIdCp = JSON.parse(JSON.stringify(componentsById)) as IComponentsById;
	const sceneTrackingType = (componentsByIdCp[sceneId] as ISceneComp).trackingType;

	// Non-face, AR mode - use scene id
	if (!isScreenRelativeMode && sceneTrackingType !== ITrackingTypes.face) {
		return sceneId;
	}
	// AR mode, Face scene and there exists a face landmark group to insert into
	if (!isScreenRelativeMode && sceneTrackingType === ITrackingTypes.face && getActiveSceneFaceLandmarkIdByFacelandmark(faceLandmark, sceneId, componentsByIdCp)) {
		return getActiveSceneFaceLandmarkIdByFacelandmark(faceLandmark, sceneId, componentsByIdCp);
	}
	// SR mode, and there exists a screen anchor group to insert into
	if (isScreenRelativeMode && getAnchorGroupIdAtPositionBySceneId(sceneId, screenAnchorGroupPosition, componentsByIdCp)) {
		return getAnchorGroupIdAtPositionBySceneId(sceneId, screenAnchorGroupPosition, componentsByIdCp);
	}

	return null;
};

/**
 *  When a user adds an entity to a scene, use this function to get the 'correct' title for that entity, taking into account other entity titles
 *
 *  @param entityTitles - The current titles for this scene ( haystack )
 *	@param entityTitle - The proposed title for this entity
 *  @param isSceneAddition - If true, then results in different behavior - 'Scene 1' becomes 'Scene 2' instead of 'Scene 1 (1)' but set to true for scene duplication
 *  TODO - Better name for isSceneAddition
 */
export const getEntityDropTitle = (entityTitles: { [id: string]: string }, entityTitle: string, isSceneAddition = false) => {
	let titleWithoutBrackets = entityTitle;
	// If this is already a copy - eg. Button (2) - then we need to count all Button instances so that copying Button (2) would become
	// Button (5) if there already existed a Button (3) and Button (4) in the scene
	if (endsWithBracketedNumber(entityTitle, true)) {
		titleWithoutBrackets = entityTitle.slice(0, entityTitle.length - 4); // -4 assumes it ends " (x)" with a space before the brackets
	} else {
		// If it doesn't end with brackets and IS a sceneAddition then remove the last 2 characters which eg. is 'Scene 1' becomes 'Scene'
		if (isSceneAddition) titleWithoutBrackets = entityTitle.slice(0, entityTitle.length - 2);
	}

	// Filter out entity titles that don't include titleWithoutBrackets, leaving just the duplicates
	const duplicateTitles = Object.entries(entityTitles).reduce((acc, [_key, value]) => {
		if (value?.includes(titleWithoutBrackets)) acc.push(value);
		return acc;
	}, [] as string[]);

	let dropTitle = isSceneAddition ? entityTitle : titleWithoutBrackets;
	let i = isSceneAddition ? 1 : 2; // Because entityTitle would be 'Scene' and we want 'Scene 1'
	while (duplicateTitles.includes(dropTitle)) {
		const incrementedSuffix = isSceneAddition ? `${i}` : `(${i})`;
		dropTitle = `${titleWithoutBrackets} ${incrementedSuffix}`;
		i++;
	}
	return dropTitle;
};

/**
 * Tests to see if a string ends with "(x)"" where x is an Integer. If includeLeadingSpace is true, then also tests for a space before the (x) so " (x)"
 */
const endsWithBracketedNumber = (str: string, includeLeadingSpace = true) => {
	return str[str.length - 3] === '(' && str[str.length - 1] === ')' && Number.isInteger(+str[str.length - 2]) && ((includeLeadingSpace && str[str.length - 4] === ' ') || !includeLeadingSpace);
};

export const getSceneTitles = (componentsById: { [id: string]: IComponentUnion }): { [id: string]: string } => {
	return Object.keys(componentsById).reduce((sceneTitles, entityId) => {
		if (componentsById[entityId].type !== IComponentType.Scene) return sceneTitles;
		sceneTitles[entityId] = (componentsById[entityId] as ISpatialComponentUnion).title;
		return sceneTitles;
	}, {} as { [id: string]: string });
};
