import React from 'react';
import { IIconParentProps } from './index';
import { COLORS } from '../../utils';

const binIcon: React.SFC<IIconParentProps> = ({
	hexFill: fill,
	viewBox,
	className,
	onClick = () => {},
}) => {
	const hexFill = fill || COLORS.midnight;
	return (
<svg
			onClick={onClick}
			className={className || ''}
			width="20px"
			height="20px"
			viewBox={viewBox ? viewBox.join(' ') : `0 0 20 20`}
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
		>
  <g id="Atom_Icon_Social_Copy_5" data-name="Atom/Icon/Social Copy 5" transform="translate(1)">
    <rect id="Rectangle_70" data-name="Rectangle 70" width="20" height="20" transform="translate(-1)" fill="none"/>
    <g id="Group_290" data-name="Group 290" transform="translate(-6.181 -3.967)">
      <g id="Image" transform="translate(6 8)">
        <g id="Artboard-1-copy-20">
          <path id="Shape" d="M.419,0A.4.4,0,0,0,0,.328V11.607a.4.4,0,0,0,.419.328H17.943a.4.4,0,0,0,.419-.328V.328A.386.386,0,0,0,17.99,0Z" transform="translate(0)" fill="none" stroke={hexFill} strokeWidth="1"/>
        </g>
      </g>
      <g id="Group_289" data-name="Group 289" transform="translate(6 8.311)">
        <path id="Path_133" data-name="Path 133" d="M6538,8.339l9.319,6.087" transform="translate(-6538 -8.339)" fill="none" stroke={hexFill} strokeLinecap="round" strokeWidth="1"/>
        <path id="Path_134" data-name="Path 134" d="M6546.609,8.339l-8.909,6" transform="translate(-6528.248 -8.339)" fill="none" stroke={hexFill} strokeLinecap="round" strokeWidth="1"/>
      </g>
    </g>
  </g>
</svg>

	);
};

export default binIcon;
