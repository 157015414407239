import { IActionCategory, IEasing, ITuple3 } from '../index';
import { IEmitterAnimationState, IModel3dAnimationState } from './components';

// Spatial union
export type ISpatialComponentActionData = IBtnAndTxtActionData | IModel3dActionData | IImageActionData | IVideoActionData | INoActionData;

// Base Actions
export type INoActionData = { type: IActionCategory.noAction };
export type IPlaySoundAction = { type: IActionCategory.playSound } & ISoundOptions;
export type IPresetAnimationAction = { type: IActionCategory.animatePreset } & IPresetAnimationOptions;
export type IModel3dAnimationAction = { type: IActionCategory.animateModel } & IModel3dAnimationOptions;
export type ILinkSceneAction = { type: IActionCategory.linkScene } & ILinkedSceneId;
export type ILinkSocialAction = { type: IActionCategory.linkSocial } & ISocialUrlOptions;
export type ILinkPageAction = { type: IActionCategory.linkPage } & IPageComponentLinkOptions;
export type IPlayVideoAction = { type: IActionCategory.playVideo } & IPlayVideoOptions;
export type IAnimateParticleAction = { type: IActionCategory.animateParticle } & IEmitterAnimationOptions;
export type ITakePhotoAction = { type: IActionCategory.takePhoto };
export type IDialNumberAction = { type: IActionCategory.dialNumber } & IDialOptions;
export type IComposeEmailAction = { type: IActionCategory.composeEmail } & IEmailOptions;
export type ISaveContactAction = { type: IActionCategory.saveContact } & IContactOptions;
export type IEnlargeAction = { type: IActionCategory.enlarge } & IEnlargeOptions;
export type IToggleVisibilityAction = { type: IActionCategory.toggleVisibility } & IToggleVisibilityOptions;
export type IScormAction = { type: IActionCategory.scorm } & IScormOptions;
export type ITargetActionUnion = IPlayVideoAction | IAnimateParticleAction | IModel3dAnimationAction | IToggleVisibilityAction;

export type IBaseSpatialActionData =
	| IScormAction
	| IToggleVisibilityAction
	| ILinkSocialAction
	| ILinkPageAction
	| ILinkSceneAction
	| IPlayVideoAction
	| IModel3dAnimationAction
	| IAnimateParticleAction
	| ITakePhotoAction
	| IPresetAnimationAction;

// Button, Text
export type IBtnAndTxtActionData = IBaseSpatialActionData | IDialNumberAction | IComposeEmailAction | ISaveContactAction | IPlaySoundAction;

// Image
export type IImageActionData = IBtnAndTxtActionData | IEnlargeAction;

// Video, Model 3d alias for BaseSpatial
export type IVideoActionData = IBaseSpatialActionData;
export type IModel3dActionData = IBaseSpatialActionData;
export type IEmitterActionData = IBaseSpatialActionData;

export type IPresetAnimationOptions = IPresetAnimationSpinOptions | IPresetAnimationOrbitOptions | IPresetAnimationScaleOptions | IPresetAnimationMoveOptions;

export interface IPresetAnimationBaseOptions {
	duration: number;
	delay: number
	loopCount: number | null;
	easing: IEasing;
	backAndForth: boolean;
}

export interface IPresetAnimationSpinOptions extends IBaseTargetAction, IPresetAnimationBaseOptions {
	entityAnimation: IPresetAnimation.spin;
	rotation: ITuple3;
}

export interface IPresetAnimationOrbitOptions extends IBaseTargetAction, IPresetAnimationBaseOptions {
	entityAnimation: IPresetAnimation.orbit;
	positionEntity?: string; // the entity ID for displaying in the drop down
	pivot: ITuple3 | string; // The underlying position that we're orbiting
	angle: number;
	axis: ITuple3;
} 

export interface IPresetAnimationScaleOptions extends IBaseTargetAction, IPresetAnimationBaseOptions {
	entityAnimation: IPresetAnimation.scale;
	multiplier: number;
	resetOnFinish: boolean; 
}

export interface IPresetAnimationMoveOptions extends IBaseTargetAction, IPresetAnimationBaseOptions {
	entityAnimation: IPresetAnimation.move;
	isOffset?: boolean;
	position: ITuple3;
	resetOnFinish: boolean; 
}

export type IPresetAnimationOptionUnion = IPresetAnimationSpinOptions | IPresetAnimationScaleOptions | IPresetAnimationMoveOptions | IPresetAnimationOrbitOptions;

// Action Options
export enum IPresetAnimation {
	spin = 'Spin',
	orbit = 'Orbit',
	scale = 'Scale',
	move = 'Move'
}
export enum ISocialProvider {
	noSocial = 'noSocial',
	facebook = 'Facebook',
	instagram = 'Instagram',
	linkedIn = 'LinkedIn',
	twitter = 'Twitter',
	youtube = 'Youtube',
	spotify = 'Spotify',
	soundCloud = 'SoundCloud',
	reddit = 'Reddit',
	tiktok = 'TikTok',
	twitch = 'Twitch',
	vimeo = 'Vimeo',
	custom = 'custom',
}
export interface ISocialUrlOptions {
	[ISocialProvider.facebook]?: string;
	[ISocialProvider.instagram]?: string;
	[ISocialProvider.linkedIn]?: string;
	[ISocialProvider.reddit]?: string;
	[ISocialProvider.soundCloud]?: string;
	[ISocialProvider.spotify]?: string;
	[ISocialProvider.tiktok]?: string;
	[ISocialProvider.twitch]?: string;
	[ISocialProvider.twitter]?: string;
	[ISocialProvider.vimeo]?: string;
	[ISocialProvider.youtube]?: string;
	[ISocialProvider.noSocial]?: string;
	[ISocialProvider.custom]?: string;
}

export interface ILinkedSceneId {
	sceneId: string | null;
}
interface IEnlargeOptions {
	entityId: string;
}
export interface IDialOptions {
	tel: string;
}

export enum IScormEventType {
	progress = 'progress',
	completion = 'completion',
}

export interface IScormProgressOption {
	event: IScormEventType.progress;
	progress: number;
}

interface IScormCompletionOption {
	event: IScormEventType.completion;
}

export type IScormOptions = IScormProgressOption | IScormCompletionOption;

export interface IBaseTargetAction {
	targetIds: string[];
}

export interface IModel3dAnimationOptions extends IModel3dAnimationState, IBaseTargetAction {}

export interface IToggleVisibilityOptions extends IBaseTargetAction {
	behaviour: 'show' | 'hide' | 'toggle';
}

export interface IEmitterAnimationOptions extends IEmitterAnimationState, IBaseTargetAction {
	behaviour: 'start' | 'stop' | 'toggle';
}

// eslint-disable-next-line
export interface IPlayVideoOptions extends IBaseTargetAction {}

export interface IBkgrSound {
	url: string;
	loop: boolean;
	title: string;
}

export interface ISoundOptions extends IBkgrSound {
	stopMedia: boolean;
}

export interface IPageComponentLinkOptions {
	pageUrl: string;
}

export interface IEmailOptions {
	recipient: string;
	subject: string;
	body: string;
}

export interface IContactOptions {
	name: string;
	phone: string;
	company?: string;
	email?: string;
	website?: string;
	phone2?: string;
	phone3?: string;
	street1?: string;
	street2?: string;
	city?: string;
	county?: string;
	zip?: string;
	country?: string;
	imgUrl?: string;
}
