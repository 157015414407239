import { ISocialProvider } from './actions';

export type IVector2 = number[];
export type IVector3 = number[];
export type IVector4 = number[];
export type ITuple2 = [number, number];
export type ITuple3 = [number, number, number];
export type ITuple4 = [number, number, number, number];
export type ITuple3Area = [ITuple3, ITuple3, ITuple3, ITuple3];
export type ITuple2Area = [ITuple3, ITuple3, ITuple3, ITuple3];

export interface IVector3Dict {
	[id: string]: IVector3 | null;
}

export interface IVector4Dict {
	[id: string]: IVector4 | null;
}

export interface ITuple3Dict {
	[id: string]: ITuple3;
}

export interface INumberDict {
	[id: string]: number;
}

export interface IFont {
	fontFamily: IFontTypes;
	url: string;
	desc: string;
	scale: ITuple3;
	ptToXCoord?: number;
	jsonUrl?: string;
}

export enum IFontTypes {
	Roboto100 = 'Roboto Thin',
	Roboto100italic = 'Roboto Thin Italic',
	Roboto300 = 'Roboto Light',
	Roboto300italic = 'Roboto Light Italic',
	Roboto400 = 'Roboto Regular',
	Roboto400italic = 'Roboto Regular Italic',
	Roboto500 = 'Roboto Medium',
	Roboto500italic = 'Roboto Medium Italic',
	Roboto700 = 'Roboto Bold',
	Roboto700italic = 'Roboto Bold Italic',
	Roboto900 = 'Roboto Black',
	Roboto900italic = 'Roboto Black Italic',
	OpenSans300 = 'Open Sans Light',
	OpenSans300italic = 'Open Sans Light Italic',
	OpenSans400 = 'Open Sans Regular',
	OpenSans400italic = 'Open Sans Regular Italic',
	OpenSans500 = 'Open Sans Medium',
	OpenSans500italic = 'Open Sans Medium Italic',
	OpenSans600 = 'Open Sans SemiBold',
	OpenSans600italic = 'Open Sans SemiBold Italic',
	OpenSans700 = 'Open Sans Bold',
	OpenSans700italic = 'Open Sans Bold Italic',
	OpenSans800 = 'Open Sans ExtraBold',
	OpenSans800italic = 'Open Sans ExtraBold Italic',
	Montserrat100 = 'Montserrat Thin',
	Montserrat100italic = 'Montserrat Thin Italic',
	Montserrat200 = 'Montserrat ExtraLight',
	Montserrat200italic = 'Montserrat ExtraLight Italic',
	Montserrat300 = 'Montserrat Light',
	Montserrat300italic = 'Montserrat Light Italic',
	Montserrat400 = 'Montserrat Regular',
	Montserrat400italic = 'Montserrat Regular Italic',
	Montserrat500 = 'Montserrat Medium',
	Montserrat500italic = 'Montserrat Medium Italic',
	Montserrat600 = 'Montserrat SemiBold',
	Montserrat600italic = 'Montserrat SemiBold Italic',
	Montserrat700 = 'Montserrat Bold',
	Montserrat700italic = 'Montserrat Bold Italic',
	Montserrat800 = 'Montserrat ExtraBold',
	Montserrat800italic = 'Montserrat ExtraBold Italic',
	Montserrat900 = 'Montserrat Black',
	Montserrat900italic = 'Montserrat Black Italic',
	Lato100 = 'Lato Thin',
	Lato100italic = 'Lato Thin Italic',
	Lato300 = 'Lato Light',
	Lato300italic = 'Lato Light Italic',
	Lato400 = 'Lato Regular',
	Lato400italic = 'Lato Regular Italic',
	Lato700 = 'Lato Bold',
	Lato700italic = 'Lato Bold Italic',
	Lato900 = 'Lato Black',
	Lato900italic = 'Lato Black Italic',
	Poppins100 = 'Poppins Thin',
	Poppins100italic = 'Poppins Thin Italic',
	Poppins200 = 'Poppins ExtraLight',
	Poppins200italic = 'Poppins ExtraLight Italic',
	Poppins300 = 'Poppins Light',
	Poppins300italic = 'Poppins Light Italic',
	Poppins400 = 'Poppins Regular',
	Poppins400italic = 'Poppins Regular Italic',
	Poppins500 = 'Poppins Medium',
	Poppins500italic = 'Poppins Medium Italic',
	Poppins600 = 'Poppins SemiBold',
	Poppins600italic = 'Poppins SemiBold Italic',
	Poppins700 = 'Poppins Bold',
	Poppins700italic = 'Poppins Bold Italic',
	Poppins800 = 'Poppins ExtraBold',
	Poppins800italic = 'Poppins ExtraBold Italic',
	Poppins900 = 'Poppins Black',
	Poppins900italic = 'Poppins Black Italic',
	Inter100 = 'Inter Thin',
	Inter200 = 'Inter ExtraLight',
	Inter300 = 'Inter Light',
	Inter400 = 'Inter Regular',
	Inter500 = 'Inter Medium',
	Inter600 = 'Inter SemiBold',
	Inter700 = 'Inter Bold',
	Inter800 = 'Inter ExtraBold',
	Inter900 = 'Inter Black',
	Oswald200 = 'Oswald ExtraLight',
	Oswald300 = 'Oswald Light',
	Oswald400 = 'Oswald Regular',
	Oswald500 = 'Oswald Medium',
	Oswald600 = 'Oswald SemiBold',
	Oswald700 = 'Oswald Bold',
	Raleway100 = 'Raleway Thin',
	Raleway100italic = 'Raleway Thin Italic',
	Raleway200 = 'Raleway ExtraLight',
	Raleway200italic = 'Raleway ExtraLight Italic',
	Raleway300 = 'Raleway Light',
	Raleway300italic = 'Raleway Light Italic',
	Raleway400 = 'Raleway Regular',
	Raleway400italic = 'Raleway Regular Italic',
	Raleway500 = 'Raleway Medium',
	Raleway500italic = 'Raleway Medium Italic',
	Raleway600 = 'Raleway SemiBold',
	Raleway600italic = 'Raleway SemiBold Italic',
	Raleway700 = 'Raleway Bold',
	Raleway700italic = 'Raleway Bold Italic',
	Raleway800 = 'Raleway ExtraBold',
	Raleway800italic = 'Raleway ExtraBold Italic',
	Raleway900 = 'Raleway Black',
	Raleway900italic = 'Raleway Black Italic',
	Rubik300 = 'Rubik Light',
	Rubik300italic = 'Rubik Light Italic',
	Rubik400 = 'Rubik Regular',
	Rubik400italic = 'Rubik Regular Italic',
	Rubik500 = 'Rubik Medium',
	Rubik500italic = 'Rubik Medium Italic',
	Rubik600 = 'Rubik SemiBold',
	Rubik600italic = 'Rubik SemiBold Italic',
	Rubik700 = 'Rubik Bold',
	Rubik700italic = 'Rubik Bold Italic',
	Rubik800 = 'Rubik ExtraBold',
	Rubik800italic = 'Rubik ExtraBold Italic',
	Rubik900 = 'Rubik Black',
	Rubik900italic = 'Rubik Black Italic',
	Borel400 = 'Borel Regular',
	Creepster400 = 'Creepster Regular',
	Pacifico400 = 'Pacifico Regular',
	ConcertOne400 = 'Concert One Regular',
	Slabo27px400 = 'Slabo 27px Regular',
	PressStart2P400 = 'Press Start 2P Regular',
	AbrilFatface400 = 'Abril Fatface Regular',
	BagelFatOne400 = 'Bagel Fat One Regular',
	SourceCodePro200 = 'Source Code Pro ExtraLight',
	SourceCodePro200italic = 'Source Code Pro ExtraLight Italic',
	SourceCodePro300 = 'Source Code Pro Light',
	SourceCodePro300italic = 'Source Code Pro Light Italic',
	SourceCodePro400 = 'Source Code Pro Regular',
	SourceCodePro400italic = 'Source Code Pro Regular Italic',
	SourceCodePro500 = 'Source Code Pro Medium',
	SourceCodePro500italic = 'Source Code Pro Medium Italic',
	SourceCodePro600 = 'Source Code Pro SemiBold',
	SourceCodePro600italic = 'Source Code Pro SemiBold Italic',
	SourceCodePro700 = 'Source Code Pro Bold',
	SourceCodePro700italic = 'Source Code Pro Bold Italic',
	SourceCodePro800 = 'Source Code Pro ExtraBold',
	SourceCodePro800italic = 'Source Code Pro ExtraBold Italic',
	SourceCodePro900 = 'Source Code Pro Black',
	SourceCodePro900italic = 'Source Code Pro Black Italic',
	BebasNeue400 = 'Bebas Neue Regular',
	PlayfairDisplay400 = 'Playfair Display Regular',
	PlayfairDisplay400italic = 'Playfair Display Regular Italic',
	PlayfairDisplay500 = 'Playfair Display Medium',
	PlayfairDisplay500italic = 'Playfair Display Medium Italic',
	PlayfairDisplay600 = 'Playfair Display SemiBold',
	PlayfairDisplay600italic = 'Playfair Display SemiBold Italic',
	PlayfairDisplay700 = 'Playfair Display Bold',
	PlayfairDisplay700italic = 'Playfair Display Bold Italic',
	PlayfairDisplay800 = 'Playfair Display ExtraBold',
	PlayfairDisplay800italic = 'Playfair Display ExtraBold Italic',
	PlayfairDisplay900 = 'Playfair Display Black',
	PlayfairDisplay900italic = 'Playfair Display Black Italic',
	RobotoSlab100 = 'Roboto Slab Thin',
	RobotoSlab200 = 'Roboto Slab ExtraLight',
	RobotoSlab300 = 'Roboto Slab Light',
	RobotoSlab400 = 'Roboto Slab Regular',
	RobotoSlab500 = 'Roboto Slab Medium',
	RobotoSlab600 = 'Roboto Slab SemiBold',
	RobotoSlab700 = 'Roboto Slab Bold',
	RobotoSlab800 = 'Roboto Slab ExtraBold',
	RobotoSlab900 = 'Roboto Slab Black',
	Comfortaa300 = 'Comfortaa Light',
	Comfortaa400 = 'Comfortaa Regular',
	Comfortaa500 = 'Comfortaa Medium',
	Comfortaa600 = 'Comfortaa SemiBold',
	Comfortaa700 = 'Comfortaa Bold',
	Cookie400 = 'Cookie Regular',
	Philosopher400 = 'Philosopher Regular',
	Philosopher400italic = 'Philosopher Regular Italic',
	Philosopher700 = 'Philosopher Bold',
	Philosopher700italic = 'Philosopher Bold Italic',
	Quicksand300 = 'Quicksand Light',
	Quicksand400 = 'Quicksand Regular',
	Quicksand500 = 'Quicksand Medium',
	Quicksand600 = 'Quicksand SemiBold',
	Quicksand700 = 'Quicksand Bold',
	Trirong100 = 'Trirong Thin',
	Trirong100italic = 'Trirong Thin Italic',
	Trirong200 = 'Trirong ExtraLight',
	Trirong200italic = 'Trirong ExtraLight Italic',
	Trirong300 = 'Trirong Light',
	Trirong300italic = 'Trirong Light Italic',
	Trirong400 = 'Trirong Regular',
	Trirong400italic = 'Trirong Regular Italic',
	Trirong500 = 'Trirong Medium',
	Trirong500italic = 'Trirong Medium Italic',
	Trirong600 = 'Trirong SemiBold',
	Trirong600italic = 'Trirong SemiBold Italic',
	Trirong700 = 'Trirong Bold',
	Trirong700italic = 'Trirong Bold Italic',
	Trirong800 = 'Trirong ExtraBold',
	Trirong800italic = 'Trirong ExtraBold Italic',
	Trirong900 = 'Trirong Black',
	Trirong900italic = 'Trirong Black Italic',
	Trocchi400 = 'Trocchi Regular',
	AdventPro100 = 'Advent Pro Thin',
	AdventPro100italic = 'Advent Pro Thin Italic',
	AdventPro200 = 'Advent Pro ExtraLight',
	AdventPro200italic = 'Advent Pro ExtraLight Italic',
	AdventPro300 = 'Advent Pro Light',
	AdventPro300italic = 'Advent Pro Light Italic',
	AdventPro400 = 'Advent Pro Regular',
	AdventPro400italic = 'Advent Pro Regular Italic',
	AdventPro500 = 'Advent Pro Medium',
	AdventPro500italic = 'Advent Pro Medium Italic',
	AdventPro600 = 'Advent Pro SemiBold',
	AdventPro600italic = 'Advent Pro SemiBold Italic',
	AdventPro700 = 'Advent Pro Bold',
	AdventPro700italic = 'Advent Pro Bold Italic',
	AdventPro800 = 'Advent Pro ExtraBold',
	AdventPro800italic = 'Advent Pro ExtraBold Italic',
	AdventPro900 = 'Advent Pro Black',
	AdventPro900italic = 'Advent Pro Black Italic',
	HennyPenny400 = 'Henny Penny Regular',
	Orbitron400 = 'Orbitron Regular',
	Orbitron500 = 'Orbitron Medium',
	Orbitron600 = 'Orbitron SemiBold',
	Orbitron700 = 'Orbitron Bold',
	Orbitron800 = 'Orbitron ExtraBold',
	Orbitron900 = 'Orbitron Black',
	Sacramento400 = 'Sacramento Regular',
	SnowburstOne400 = 'Snowburst One Regular',
	Syncopate400 = 'Syncopate Regular',
	Syncopate700 = 'Syncopate Bold',
	Wallpoet400 = 'Wallpoet Regular',
	SirinStencil400 = 'Sirin Stencil Regular',
	NotoSans100 = 'Noto Sans Thin',
	NotoSans100italic = 'Noto Sans Thin Italic',
	NotoSans200 = 'Noto Sans ExtraLight',
	NotoSans200italic = 'Noto Sans ExtraLight Italic',
	NotoSans300 = 'Noto Sans Light',
	NotoSans300italic = 'Noto Sans Light Italic',
	NotoSans400 = 'Noto Sans Regular',
	NotoSans400italic = 'Noto Sans Regular Italic',
	NotoSans500 = 'Noto Sans Medium',
	NotoSans500italic = 'Noto Sans Medium Italic',
	NotoSans600 = 'Noto Sans SemiBold',
	NotoSans600italic = 'Noto Sans SemiBold Italic',
	NotoSans700 = 'Noto Sans Bold',
	NotoSans700italic = 'Noto Sans Bold Italic',
	NotoSans800 = 'Noto Sans ExtraBold',
	NotoSans800italic = 'Noto Sans ExtraBold Italic',
	NotoSans900 = 'Noto Sans Black',
	NotoSans900italic = 'Noto Sans Black Italic',
	NotoSerif100 = 'Noto Serif Thin',
	NotoSerif100italic = 'Noto Serif Thin Italic',
	NotoSerif200 = 'Noto Serif ExtraLight',
	NotoSerif200italic = 'Noto Serif ExtraLight Italic',
	NotoSerif300 = 'Noto Serif Light',
	NotoSerif300italic = 'Noto Serif Light Italic',
	NotoSerif400 = 'Noto Serif Regular',
	NotoSerif400italic = 'Noto Serif Regular Italic',
	NotoSerif500 = 'Noto Serif Medium',
	NotoSerif500italic = 'Noto Serif Medium Italic',
	NotoSerif600 = 'Noto Serif SemiBold',
	NotoSerif600italic = 'Noto Serif SemiBold Italic',
	NotoSerif700 = 'Noto Serif Bold',
	NotoSerif700italic = 'Noto Serif Bold Italic',
	NotoSerif800 = 'Noto Serif ExtraBold',
	NotoSerif800italic = 'Noto Serif ExtraBold Italic',
	NotoSerif900 = 'Noto Serif Black',
	NotoSerif900italic = 'Noto Serif Black Italic',
	NotoSansJP100 = 'Noto Sans JP Thin',
	NotoSansJP200 = 'Noto Sans JP ExtraLight',
	NotoSansJP300 = 'Noto Sans JP Light',
	NotoSansJP400 = 'Noto Sans JP Regular',
	NotoSansJP500 = 'Noto Sans JP Medium',
	NotoSansJP600 = 'Noto Sans JP SemiBold',
	NotoSansJP700 = 'Noto Sans JP Bold',
	NotoSansJP800 = 'Noto Sans JP ExtraBold',
	NotoSansJP900 = 'Noto Sans JP Black',
	NotoSansArabic100 = 'Noto Sans Arabic Thin',
	NotoSansArabic200 = 'Noto Sans Arabic ExtraLight',
	NotoSansArabic300 = 'Noto Sans Arabic Light',
	NotoSansArabic400 = 'Noto Sans Arabic Regular',
	NotoSansArabic500 = 'Noto Sans Arabic Medium',
	NotoSansArabic600 = 'Noto Sans Arabic SemiBold',
	NotoSansArabic700 = 'Noto Sans Arabic Bold',
	NotoSansArabic800 = 'Noto Sans Arabic ExtraBold',
	NotoSansArabic900 = 'Noto Sans Arabic Black',
	NotoSansThai100 = 'Noto Sans Thai Thin',
	NotoSansThai200 = 'Noto Sans Thai ExtraLight',
	NotoSansThai300 = 'Noto Sans Thai Light',
	NotoSansThai400 = 'Noto Sans Thai Regular',
	NotoSansThai500 = 'Noto Sans Thai Medium',
	NotoSansThai600 = 'Noto Sans Thai SemiBold',
	NotoSansThai700 = 'Noto Sans Thai Bold',
	NotoSansThai800 = 'Noto Sans Thai ExtraBold',
	NotoSansThai900 = 'Noto Sans Thai Black',
	NotoSansSC100 = 'Noto Sans SC Thin',
	NotoSansSC300 = 'Noto Sans SC Light',
	NotoSansSC400 = 'Noto Sans SC Regular',
	NotoSansSC500 = 'Noto Sans SC Medium',
	NotoSansSC700 = 'Noto Sans SC Bold',
	NotoSansSC900 = 'Noto Sans SC Black',
	NotoSansTC100 = 'Noto Sans TC Thin',
	NotoSansTC300 = 'Noto Sans TC Light',
	NotoSansTC400 = 'Noto Sans TC Regular',
	NotoSansTC500 = 'Noto Sans TC Medium',
	NotoSansTC700 = 'Noto Sans TC Bold',
	NotoSansTC900 = 'Noto Sans TC Black',
	NotoSansKR100 = 'Noto Sans KR Thin',
	NotoSansKR300 = 'Noto Sans KR Light',
	NotoSansKR400 = 'Noto Sans KR Regular',
	NotoSansKR500 = 'Noto Sans KR Medium',
	NotoSansKR700 = 'Noto Sans KR Bold',
	NotoSansKR900 = 'Noto Sans KR Black',
}
export enum IUnitTypes {
	pt = 'pt',
	mm = 'mm',
	cm = 'cm',
	m = 'm',
	inch = 'inch',
	ft = 'ft',
	coords = 'coords',
}

export enum ITextAlignment {
	center = 'center',
	right = 'right',
	left = 'left',
}

export enum IAreaTypes {
	a5 = 'a5',
	a4 = 'a4',
	a3 = 'a3',
	ltr = 'ltr',
	b5 = 'b5',
	b4 = 'b4',
	pstc = 'pstc',
	bsnc = 'bsnc',
	pstr = 'pstr',
	tbl = 'tbl',
	lghtBx = 'lghtBx',
	blbrd = 'blbrd',
	custom = 'custom',
}

export enum IEventType {
	connect = 'connect',
	connection = 'connection',
	disconnect = 'disconnect',
	pos_doc_changed = 'pos_doc_changed',
	pos_doc_change = 'pos_doc_change',
	selection_changed = 'selection_changed',
	selection_change = 'selection_change',
	selection_removed = 'selection_removed',
	selection_remove = 'selection_remove',
	automerge_update = 'automerge_update',
	users_update = 'users-update',
	users_get = 'users-get',
}

export enum IDocType {
	content_doc = 'content_doc',
}

export enum ILocalStorageKeys {
	displayUnit = 'displayUnit',
}

export enum ITransformProps {
	scale = 'scale',
	position = 'position',
	rotation = 'rotation',
	scalesInverted = 'scalesInverted',
	aspectRatioLocked = 'aspectRatioLocked',
}

export interface IProjectColor {
	id: string;
	hex: string;
	rgba: IVector4;
}

export interface ISocialOptions {
	selectedProvider?: ISocialProvider;
	[ISocialProvider.facebook]?: string;
	[ISocialProvider.instagram]?: string;
	[ISocialProvider.linkedIn]?: string;
	[ISocialProvider.twitter]?: string;
	[ISocialProvider.youtube]?: string;
	[ISocialProvider.spotify]?: string;
	[ISocialProvider.soundCloud]?: string;
	[ISocialProvider.reddit]?: string;
	[ISocialProvider.tiktok]?: string;
	[ISocialProvider.twitch]?: string;
	[ISocialProvider.vimeo]?: string;
	[ISocialProvider.custom]?: string;
}

export interface IUserData {
	id: string;
	name: string;
	avatarUrl: string | null;
	profileUrl?: string;
}

export interface IPublishStatus {
	status: IPublishTypes;
	dateString: string;
}

export enum IPublishTypes {
	published = 'published',
	unpublishedChanges = 'unpublishedChanges',
	notPublished = 'notPublished',
	unpublished = 'unpublished',
}

export enum IActionCategory {
	noAction = 'noAction',
	animatePreset = 'animatePreset',
	animateModel = 'animateModel',
	animateParticle = 'animateParticle',
	linkSocial = 'linkSocial',
	linkPage = 'linkPage',
	linkScene = 'linkScene',
	dialNumber = 'dialNumber',
	composeEmail = 'composeEmail',
	saveContact = 'saveContact',
	playSound = 'playSound',
	playVideo = 'playVideo',
	enlarge = 'enlarge',
	takePhoto = 'takePhoto',
	toggleVisibility = 'toggleVisibility',
	scorm = 'scorm'
}

export enum IBtnAndTxtActionCategory {
	noAction = 'noAction',
	linkSocial = 'linkSocial',
	linkPage = 'linkPage',
	linkScene = 'linkScene',
	dialNumber = 'dialNumber',
	composeEmail = 'composeEmail',
	saveContact = 'saveContact',
	playSound = 'playSound',
	playVideo = 'playVideo',
}

export enum IEasing {
	linear = 'linear',
	tie = 'tie',
	smooth = 'smooth',
	accelerating = 'accelerating',
	decelerating = 'decelerating',
	easeInQuad = 'easeInQuad',
	easeOutQuad = 'easeOutQuad',
	easeInOutQuad = 'easeInOutQuad',
	easeInCubic = 'easeInCubic',
	easeOutCubic = 'easeOutCubic',
	easeInOutCubic = 'easeInOutCubic',
	easeInQuart = 'easeInQuart',
	easeOutQuart = 'easeOutQuart',
	easeInOutQuart = 'easeInOutQuart',
	easeInQuint = 'easeInQuint',
	easeOutQuint = 'easeOutQuint',
	easeInOutQuint = 'easeInOutQuint',
	easeInSine = 'easeInSine',
	easeOutSine = 'easeOutSine',
	easeInOutSine = 'easeInOutSine',
	easeInCirc = 'easeInCirc',
	easeOutCirc = 'easeOutCirc',
	easeInOutCirc = 'easeInOutCirc',
	easeInExpo = 'easeInExpo',
	easeOutExpo = 'easeOutExpo',
	easeInOutExpo = 'easeInOutExpo',
	easeInElastic = 'easeInElastic',
	easeOutElastic = 'easeOutElastic',
	easeInOutElastic = 'easeInOutElastic',
	easeInBack = 'easeInBack',
	easeOutBack = 'easeOutBack',
	easeInOutBack = 'easeInOutBack',
	easeOutBounce = 'easeOutBounce',
	easeInBounce = 'easeInBounce',
	easeInOutBounce = 'easeInOutBounce',
}