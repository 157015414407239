import { IComponentsById, IScreenAnchorPositionType, IFaceLandmark, ISpatialComponentUnion, IComponentType, IScreenAnchorGroup, IFaceLandmarkGroup, ISceneComp } from '../../components/r3f/r3f-components/component-data-structure/index';
import { getComponentParentIdById } from '../pure';
import { createScreenAnchorGroup, createFaceLandmarkGroup } from './create';
import { getParentIdForSpatialComponentOnPaste, getScreenContentIdBySceneId } from './get';
import { insertAbstractComponent } from './insertAbstractComponent';

/**
 * This function is to be used as part of the copy / paste flow.
 * It will attempt to find:
 * - a screen anchor group with IScreenAnchorPositionType.center if isScreenRelativeMode is true
 * - a face landmark group with IFaceLandmark.noseTip if the targetSceneId scene is face tracking
 * if neither of these are found then it will create the appropriate component and return a copy of componentsById with it added.
 *
 * If isScreenRelativeMode is false and the targetSceneId scene is not face tracking, then it will return a copy of componentsById with no modification.
 * @param args.spatialComponent - the spatial component that we are copying
 * @param args.isScreenRelativeMode - from user state, whether we are currently in screen relative mode
 * @param args.isFlatOrientation - if on an image tracked scene, if we're currently flat oriented
 * @param args.sourceSceneIsAr - whether the spatial component being copied was copied from an AR layer
 * @param args.sourceSceneId - the scene ID of the scene that the spatial component being copied was on originally
 * @param args.componentsById - the current componentsById from content doc
 * @param args.targetSceneId - the ID of the scene that we're copying the spatial component to
 * @param args.debug - whether to log debug info
 */
export const insertParentForSpatialComponentIfRequired = (args: {
	spatialComponent: ISpatialComponentUnion;
	isScreenRelativeMode: boolean;
	isFlatOrientation: boolean;
	sourceSceneIsAr: boolean;
	sourceSceneId: string;
	componentsById: IComponentsById;
	targetSceneId: string;
	debug?: boolean;
}) => {
	const { spatialComponent, isScreenRelativeMode, componentsById, targetSceneId, sourceSceneId, sourceSceneIsAr, isFlatOrientation, debug = false } = args;
	const componentsByIdCp = JSON.parse(JSON.stringify(componentsById)) as IComponentsById;

	const sourceParentId = getComponentParentIdById(spatialComponent.id, componentsByIdCp);
	if (!sourceParentId) {
		if (debug) console.log('Unable to find source component parent ID: ', spatialComponent);
		return null;
	}
	const sourceParentComponent = componentsByIdCp[sourceParentId] as ISceneComp | IScreenAnchorGroup | IFaceLandmarkGroup;
	const faceLandmark = sourceParentComponent.type === IComponentType.FaceLandmarkGroup ? sourceParentComponent.landmark : undefined;
	const screenAnchorGroupPosition = sourceParentComponent.type === IComponentType.ScreenAnchorGroup ? sourceParentComponent.anchorPositionType : undefined;

	const parentId = getParentIdForSpatialComponentOnPaste({ sceneId: targetSceneId, componentsById: componentsByIdCp, isScreenRelativeMode, faceLandmark, screenAnchorGroupPosition });
	if (parentId) {
		if (debug) console.log('insertParentForSpatialComponentIfRequired -> parentId found', parentId);
		return componentsByIdCp;
	}

	// We're creating a parent component - need to figure out what screen or face group it came from
	const spatialComponentParentId = getComponentParentIdById(spatialComponent.id, componentsByIdCp);
	if (!spatialComponentParentId) {
		if (debug) {
			console.log('insertParentForSpatialComponentIfRequired -> spatialComponentParent not found', spatialComponent);
		}
		return null;
	}

	// Load in the source anchor / landmark and fallback to defaults of noseTip and center
	const spatialComponentParent = componentsByIdCp[spatialComponentParentId];
	const anchorPosition = spatialComponentParent.type === IComponentType.ScreenAnchorGroup ? spatialComponentParent.anchorPositionType : IScreenAnchorPositionType.center;
	const faceLandmarkPosition = spatialComponentParent.type === IComponentType.FaceLandmarkGroup ? spatialComponentParent.landmark : IFaceLandmark.noseTip;

	// If there was no parentId found, we need to create one
	const abstractComponent = isScreenRelativeMode
		? createScreenAnchorGroup(anchorPosition)
		: createFaceLandmarkGroup(faceLandmarkPosition);
	const abstractComponentParentId = isScreenRelativeMode ? getScreenContentIdBySceneId(targetSceneId, componentsByIdCp) : targetSceneId;
	if (debug) {
		console.log('insertParentForSpatialComponentIfRequired -> abstractComponent : ', abstractComponent);
		console.log('insertParentForSpatialComponentIfRequired -> abstractComponentParentId : ', abstractComponentParentId);
	}
	if (!abstractComponentParentId) return null;
	const abstractComponentId = crypto.randomUUID();
	return insertAbstractComponent({
		abstractComponent,
		abstractComponentId,
		sourceSceneIsAr,
		sourceSceneId,
		parentId: abstractComponentParentId,
		isFlatOrientation,
		componentsById: componentsByIdCp,
	});
};
