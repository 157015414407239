import React, { useMemo } from 'react';
import { NormalBlending } from 'three';
import { IEmitterGroupSettings, IEmitterSettings, IEmitterReactProps, ITuple3 } from '../../../component-data-structure';
import BasicEmitter from './BasicSingleEmitter'

const OcclusionCoreAdditiveEmitter: React.FunctionComponent<IEmitterReactProps> = ({ 
    isPlaying, 
    runCounter, 
    duration, 
    isStatic,
    id,
    emitter: emitterSettings, 
    emitterGroup: emitterGroupSettings, 
    position, 
    rotation, 
    scale, 
    renderOrder,
    onFinish
}) => {

    const occlusionGroupEmitterSettings = useMemo(() => {
        const settings = JSON.parse(JSON.stringify(emitterGroupSettings)) as Partial<IEmitterGroupSettings>
        settings.blending = NormalBlending;
        return settings;
    }, [emitterGroupSettings])


    const occlusionEmitterSettings = useMemo(() => {
        const settings = JSON.parse(JSON.stringify(emitterSettings)) as Partial<IEmitterSettings>
        settings.particleCount = Math.floor((settings?.particleCount || 1000) / 2);
        if (!settings.position) settings.position = {}
        const s = settings.position.spread!;
        settings.position!.spread! = [s[0] - 0.1, s[1] - 0.11, s[2] - 0.1];
        settings.maxAge!.value = settings.maxAge!.value! * 0.95;
        return settings;
    }, [emitterSettings])

    return (
        <group position={position as ITuple3} rotation={rotation as ITuple3}>
            <BasicEmitter 
                id={id}
                position={[0, 0, 0]}
                rotation={[0, 0, 0]}
                scale={scale}
                renderOrder={renderOrder}
                isStatic={isStatic}
                duration={duration}
                isPlaying={isPlaying}
                runCounter={runCounter}
                onFinish={onFinish}
                emitter={occlusionEmitterSettings}
                emitterGroup={occlusionGroupEmitterSettings}
            />
            <BasicEmitter 
                id={id}
                position={[0, 0, 0]}
                rotation={[0, 0, 0]}
                scale={scale}
                renderOrder={renderOrder}
                isStatic={isStatic}
                duration={duration}
                isPlaying={isPlaying}
                runCounter={runCounter}
                onFinish={onFinish}
                emitter={emitterSettings}
                emitterGroup={emitterGroupSettings}
            />
            
        </group>
    );
}
export default OcclusionCoreAdditiveEmitter;