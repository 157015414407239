import React, { FunctionComponent } from 'react';
import { COLORS } from '../../utils';
import { IIconParentProps } from './index';

const textAlignCenter: FunctionComponent<IIconParentProps> = ({ className, hexFill: fill, viewBox, onClick = () => {} }) => {
	const hexFill = fill || COLORS.midnight;
	return (
		<svg
			onClick={onClick}
			className={className || ''}
			width="20px"
			height="20px"
			viewBox={viewBox ? viewBox.join(' ') : '0 0 20 20'}
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M1 0.5C0.723858 0.5 0.5 0.723858 0.5 1C0.5 1.27614 0.723858 1.5 1 1.5H19C19.2761 1.5 19.5 1.27614 19.5 1C19.5 0.723858 19.2761 0.5 19 0.5H1ZM1 11.5C0.723858 11.5 0.5 11.7239 0.5 12C0.5 12.2761 0.723858 12.5 1 12.5H19C19.2761 12.5 19.5 12.2761 19.5 12C19.5 11.7239 19.2761 11.5 19 11.5H1ZM3.52234 7C3.52234 6.72386 3.7462 6.5 4.02234 6.5H16.0223C16.2985 6.5 16.5223 6.72386 16.5223 7C16.5223 7.27614 16.2985 7.5 16.0223 7.5H4.02234C3.7462 7.5 3.52234 7.27614 3.52234 7ZM4.02234 17.5C3.7462 17.5 3.52234 17.7239 3.52234 18C3.52234 18.2761 3.7462 18.5 4.02234 18.5H16.0223C16.2985 18.5 16.5223 18.2761 16.5223 18C16.5223 17.7239 16.2985 17.5 16.0223 17.5H4.02234Z"
				fill={hexFill}
			/>
		</svg>
	);
};

export default textAlignCenter;
