/* eslint-disable @typescript-eslint/no-namespace */
import { BufferGeometryNode, Object3DNode, extend, useLoader } from '@react-three/fiber';
import { image_target_type_t } from '@zappar/zappar-cv';
import React, { FunctionComponent, useMemo, memo, Suspense } from 'react';
import { useSelector } from 'react-redux';
import { BackSide, FrontSide, Object3D, TextureLoader } from 'three';
import { IDesignerState } from '../../../../../../typings';
import { ITuple3 } from '../../../r3f-components/component-data-structure';
import { ITargetImageReferenceObject } from '../../../r3f-components/component-data-structure/types/targetImageReferenceObject';
import { CurvedEntityGeometry } from '../../../r3f-components/components/CurvedEntity/CurvedEntityGeometry';
import DreiText from '../../../r3f-components/components/DreiText';
import { TargetImagePreviewBufferGeometry, getPreviewMesh } from '../TargetImagePreviewBufferGeometry/TargetImagePreviewBufferGeometry';

extend({ TargetImagePreviewBufferGeometry });

type Object3DProps = Object3DNode<THREE.Object3D, typeof Object3D>;
declare global {
	namespace JSX {
		interface IntrinsicElements {
			targetImagePreviewBufferGeometry: BufferGeometryNode<CurvedEntityGeometry, typeof TargetImagePreviewBufferGeometry>;
			object3D: Object3DProps;
		}
	}
}

interface IParentProps {
	opacity?: number;
	referenceObject?: ITargetImageReferenceObject;
}

const TrkImage: FunctionComponent<IParentProps> = ({ opacity = 1, referenceObject = ITargetImageReferenceObject.none }) => {
	const zeroTuple3 = useMemo(() => [0, 0, 0], []) as ITuple3;
	const imageTrackingData = useSelector((state: IDesignerState) => state.contentReducer.contentDoc.tracking?.image);
	const isMode3d = useSelector((state: IDesignerState) => state.userReducer.is3dMode);
	const projectShadowsEnabled = useSelector((state: IDesignerState) => state.contentReducer.contentDoc.shadowsEnabled ?? true);

	const hasReferenceObject = referenceObject !== ITargetImageReferenceObject.none;

	const texture = useLoader(TextureLoader, imageTrackingData?.previewUrl || '');
	const geom = useMemo(() => {
		if (!imageTrackingData) return null;
		const trainedHeight = imageTrackingData?.targetImageHeight || imageTrackingData?.scale?.[1] || 1;
		const trainedWidth = imageTrackingData?.targetImageWidth || imageTrackingData?.scale?.[0] || 1;
		const bottomRadius = imageTrackingData.bottomRadius || 0;
		const topRadius = imageTrackingData.topRadius || 0;
		const type = imageTrackingData.targetType || image_target_type_t.IMAGE_TRACKER_TYPE_PLANAR;
		return (
			<targetImagePreviewBufferGeometry
				args={[
					getPreviewMesh({
						trainedHeight,
						trainedWidth,
						bottomRadius,
						topRadius,
						type,
						sideLength: imageTrackingData.sideLength || 0,
						physicalScaleFactor: 1,
					}),
				]}
			/>
		);
	}, [texture, imageTrackingData?.targetType]);
	if (!imageTrackingData || !imageTrackingData.previewUrl) return null;
	const position = imageTrackingData?.targetType !== image_target_type_t.IMAGE_TRACKER_TYPE_PLANAR ? ([0, 0, 0] as ITuple3) : ([0, 0, isMode3d ? 0.0001 : -0.01] as ITuple3);
	const textPosition = imageTrackingData?.targetType !== image_target_type_t.IMAGE_TRACKER_TYPE_PLANAR ? ([0, 0, Number(imageTrackingData.topRadius)] as ITuple3) : ([0, 0, 0] as ITuple3);

	// Old image tracked templates need to have 2x scaling applied (hotfix) 
	const adjustedTargetImageScale: ITuple3 = typeof imageTrackingData?.targetImageHeight === 'undefined' ? [2, 2, 2] : [1, 1, 1];
	return (
		<Suspense fallback={null}>
			{projectShadowsEnabled && <mesh receiveShadow >
				{geom}
				<shadowMaterial
					opacity={0.15}
					depthWrite={false}
					polygonOffset
					polygonOffsetFactor={-10} // positive value pushes polygon further away
					polygonOffsetUnits={10}
				/>
			</mesh>}
			<mesh scale={adjustedTargetImageScale} position={position} rotation={zeroTuple3} renderOrder={!hasReferenceObject ? -1 : 1}>
				{geom}
				<meshBasicMaterial
					map={texture}
					side={FrontSide}
					opacity={opacity}
					depthWrite={!hasReferenceObject}
					transparent={true}
					polygonOffset={!hasReferenceObject}
					polygonOffsetFactor={1}
					polygonOffsetUnits={100}
				/>
			</mesh>
			{projectShadowsEnabled && <mesh receiveShadow >
				{geom}
				<shadowMaterial
					opacity={0.15}
					depthWrite={false}
					polygonOffset
					polygonOffsetFactor={10} // positive value pushes polygon further away
					polygonOffsetUnits={10}
				/>
			</mesh>}
			{(!hasReferenceObject || !imageTrackingData.topRadius) && (
				<mesh scale={adjustedTargetImageScale} position={position} rotation={zeroTuple3} renderOrder={-1}>
					{geom}
					<meshBasicMaterial color={'rgba(235, 235, 235, 1)'} side={BackSide} opacity={opacity} transparent={true} polygonOffset polygonOffsetFactor={1} polygonOffsetUnits={-600} />
				</mesh>
			)}
			<group rotation={[0, Math.PI, 0]} position={textPosition}>
				<DreiText
					curveRadius={imageTrackingData.topRadius}
					key={'tracking_reverse_text'}
					name={'tracking_reverse_text'}
					color={'#5C5C5C'}
					alpha={1.0}
					fontSize={0.1}
					position={[0, 0, 0.015]}
					anchorY={'middle'}
					renderOrder={1}
					side={FrontSide}
				>
					BACK OF TARGET IMAGE
				</DreiText>
			</group>
		</Suspense>
	);
};

export default memo(TrkImage);
