import React from 'react';
import { IIconParentProps } from './index';
import { COLORS } from '../../utils';

const arrowLeftIcon: React.SFC<IIconParentProps> = ({
	className,
	hexFill: fill,
	viewBox,
	title,
	width,
	height,
	onClick = () => {},
}) => {
	const hexFill = fill || COLORS.midnight;
	return (
		<svg
			onClick={onClick}
			className={className || ''}
      width={width ? width : "7px"}
      height={height ? height : "7px"}
			viewBox={viewBox ? viewBox.join(' ') : '0 0 7 7'}
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
		>
			<title>{title}</title>
			<path
				fill={hexFill}
				fillRule="evenodd"
				d="M-0.502301216,6 L-0.525,5.998 L-0.589875632,5.99224424 C-0.823124839,5.9514173 -1,5.75372791 -1,5.50230122 L-1,1.49769878 C-1,1.25336861 -0.816743827,1.05015971 -0.587748628,1.00801859 L-0.5,1 C-0.223857625,1 0,1.21484375 0,1.49769878 L0,5 L3.50230122,5 C3.74663139,5 3.94984029,5.18325617 3.99198141,5.41225137 L4,5.5 C4,5.77614237 3.78515625,6 3.50230122,6 L-0.502301216,6 Z"
				transform="rotate(-136 1.5 3.5)"
			/>
		</svg>
	);
};

export default arrowLeftIcon;
