import React, { FunctionComponent } from 'react';
import { IIconParentProps } from './index';
import { COLORS } from '../../utils';

const hashIcon: FunctionComponent<IIconParentProps> = ({
	className,
	hexFill: fill,
	viewBox,
	onClick = () => {},
}) => {
	const hexFill = fill || COLORS.midnight;
	return (
<svg
			onClick={onClick}
			className={className || ''}
			width="20px"
			height="20px"
			viewBox={viewBox ? viewBox.join(' ') : `0 0 20 20`}
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
		>
		<g id="Atom_Icon_Social" data-name="Atom/Icon/Social" transform="translate(-3)">
		<path id="_" data-name="#" d="M8.09,20H7.529a.451.451,0,0,1-.372-.17.506.506,0,0,1-.063-.453l1.193-5.639H4.1l-1.193,5.7a.643.643,0,0,1-.239.437A.718.718,0,0,1,2.251,20H1.662l1.32-6.262H.427a.426.426,0,0,1-.344-.127.643.643,0,0,1-.077-.453l.056-.409H3.135l1.151-5.5H.722l.084-.537c.038-.31.25-.467.632-.467h3L5.648.537a.6.6,0,0,1,.217-.4A.686.686,0,0,1,6.294,0H6.87L5.564,6.247H9.747L11.052,0h.589a.444.444,0,0,1,.365.155.506.506,0,0,1,.07.438L10.87,6.247H14l-.085.537a.5.5,0,0,1-.175.353.724.724,0,0,1-.443.113H10.716l-1.151,5.5h3.158a.42.42,0,0,1,.336.127.552.552,0,0,1,.07.438l-.056.424H9.41L8.09,20ZM5.409,7.251l-1.151,5.5H8.442l1.151-5.5Z" transform="translate(3 0)" fill={hexFill}/>
		</g>
		</svg>
	);
};

export default hashIcon;
