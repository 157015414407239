import React from 'react';
import { IIconParentProps } from './index';
import { COLORS } from '../../utils';

const scaleUpIcon: React.FunctionComponent<IIconParentProps> = ({
	className,
	hexFill: fill,
	secondHexFill: secondFill, 
	viewBox,
	onClick = () => {},
}) => {
	const hexFill = fill || COLORS.midnight;
	const secondHexFill = secondFill; 
	return (
		<svg
			onClick={onClick}
			className={className || ''}
			width="56px"
			height="58px"
			viewBox={viewBox ? viewBox.join(' ') : '0 0 56 58'}
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
		>
			 <defs>
				<radialGradient cx="50%" cy="48.7357475%" fx="50%" fy="48.7357475%" r="76.1877111%" gradientTransform="translate(0.500000,0.487357),scale(1.000000,0.666667),rotate(90.000000),translate(-0.500000,-0.487357)" id="radialGradient-1">
					<stop stopColor={hexFill} stopOpacity="0.63" offset="0%"></stop>
					<stop stopColor={COLORS.white} stopOpacity="0" offset="100%"></stop>
				</radialGradient>
			</defs>
			<g id="Components" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
				<g id="Illustration-/-Scale-up" transform="translate(-6.000000, -5.000000)">
					<g id="Scale-up-2" transform="translate(6.000000, 5.000000)">
						<g id="Group" transform="translate(0.821606, 0.000000)">
							<g id="Bolt" transform="translate(11.178394, 4.000000)" fillRule="nonzero">
								<polygon id="Path-Copy-5" fill={hexFill} points="6.4 18.1937302 14.3174462 9.6 25.6 22.6825581 15.9996267 30.7248799 21.078269 38.4 6.63590342 30.6187186 13.6346203 23.2914099"></polygon>
								<polygon id="Path-Copy-5" fill="url(#radialGradient-1)" points="0 14.3228836 13.1957437 0 32 21.8042635 15.9993779 35.2081331 24.4637816 48 0.393172363 35.0311977 12.0577005 22.8190165"></polygon>
							</g>
							<g id="Arrow" transform="translate(7.000000, 50.700000) scale(-1, 1) translate(-7.000000, -50.700000) translate(0.000000, 43.700000)" fill={hexFill}>
								<path d="M12.7255205,8.44362392 L7.81925426,3.59729065 C7.65946696,3.4362141 7.44102867,3.34517896 7.21318696,3.34470754 C6.98534525,3.34423977 6.76728036,3.43437526 6.60815569,3.59479452 L1.72182495,8.42094472 C1.42597957,8.75726707 1.44307611,9.26293078 1.76106351,9.58150237 C2.07905091,9.90007396 2.59072874,9.92415604 2.93542427,9.63677359 L7.25262748,5.40785922 L11.5861988,9.68878154 C11.7459861,9.84985809 11.9644244,9.94089324 12.1922661,9.94136465 C12.4201078,9.94183242 12.6381727,9.85169693 12.7972973,9.69127768 C12.9630472,9.52078545 13.049428,9.28969305 13.0358206,9.05316296 C13.0222132,8.81663286 12.9098384,8.59588912 12.7255205,8.44362392 Z" id="Path-Copy-8" transform="translate(7.273994, 6.643036) scale(1, -1) rotate(45.000000) translate(-7.273994, -6.643036) "></path>
							</g>
							<g id="Arrow" transform="translate(40.291400, 43.700000)" fill={hexFill}>
								<path d="M12.7255205,8.44362392 L7.81925426,3.59729065 C7.65946696,3.4362141 7.44102867,3.34517896 7.21318696,3.34470754 C6.98534525,3.34423977 6.76728036,3.43437526 6.60815569,3.59479452 L1.72182495,8.42094472 C1.42597957,8.75726707 1.44307611,9.26293078 1.76106351,9.58150237 C2.07905091,9.90007396 2.59072874,9.92415604 2.93542427,9.63677359 L7.25262748,5.40785922 L11.5861988,9.68878154 C11.7459861,9.84985809 11.9644244,9.94089324 12.1922661,9.94136465 C12.4201078,9.94183242 12.6381727,9.85169693 12.7972973,9.69127768 C12.9630472,9.52078545 13.049428,9.28969305 13.0358206,9.05316296 C13.0222132,8.81663286 12.9098384,8.59588912 12.7255205,8.44362392 Z" id="Path-Copy-8" transform="translate(7.273994, 6.643036) scale(1, -1) rotate(45.000000) translate(-7.273994, -6.643036) "></path>
							</g>
							<g id="Arrow" transform="translate(7.000000, 7.000000) scale(-1, -1) translate(-7.000000, -7.000000) " fill={hexFill}>
								<path d="M12.7255205,8.44362392 L7.81925426,3.59729065 C7.65946696,3.4362141 7.44102867,3.34517896 7.21318696,3.34470754 C6.98534525,3.34423977 6.76728036,3.43437526 6.60815569,3.59479452 L1.72182495,8.42094472 C1.42597957,8.75726707 1.44307611,9.26293078 1.76106351,9.58150237 C2.07905091,9.90007396 2.59072874,9.92415604 2.93542427,9.63677359 L7.25262748,5.40785922 L11.5861988,9.68878154 C11.7459861,9.84985809 11.9644244,9.94089324 12.1922661,9.94136465 C12.4201078,9.94183242 12.6381727,9.85169693 12.7972973,9.69127768 C12.9630472,9.52078545 13.049428,9.28969305 13.0358206,9.05316296 C13.0222132,8.81663286 12.9098384,8.59588912 12.7255205,8.44362392 Z" id="Path-Copy-8" transform="translate(7.273994, 6.643036) scale(1, -1) rotate(45.000000) translate(-7.273994, -6.643036) "></path>
							</g>
							<g id="Arrow" transform="translate(47.291400, 7.000000) scale(1, -1) translate(-47.291400, -7.000000) translate(40.291400, 0.000000)" fill={hexFill}>
								<path d="M12.7255205,8.4436247 L7.81925426,3.59728934 C7.65946696,3.43621272 7.44102867,3.34517753 7.21318696,3.34470611 C6.98534525,3.34423834 6.76728036,3.43437387 6.60815569,3.5947932 L1.72182495,8.42094549 C1.42597957,8.75726799 1.44307611,9.26293191 1.76106351,9.58150364 C2.07905091,9.90007537 2.59072874,9.92415747 2.93542427,9.63677489 L7.25262748,5.40785868 L11.5861988,9.68878286 C11.7459861,9.84985948 11.9644244,9.94089466 12.1922661,9.94136608 C12.4201078,9.94183385 12.6381727,9.85169832 12.7972973,9.691279 C12.9630472,9.52078669 13.049428,9.2896942 13.0358206,9.053164 C13.0222132,8.8166338 12.9098384,8.59588996 12.7255205,8.4436247 Z" id="Path-Copy-8" transform="translate(7.273994, 6.643036) scale(1, -1) rotate(45.000000) translate(-7.273994, -6.643036) "></path>
							</g>
						</g>
					</g>
				</g>
			</g>
		</svg>
	);
};

export default scaleUpIcon;
