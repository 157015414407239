import React, { memo, useState, useEffect, FC } from 'react';
import { FrontSide, Intersection, LinearFilter, Object3D, Texture, TextureLoader, Raycaster } from 'three';
import { ITuple3, ITuple4 } from '../../component-data-structure/index';
import pause from '../assets/pause.svg';
import play from '../assets/play.svg';
import { getSvgCanvas } from '../CurvedEntity/canvasTexture';
import CurvedEntity from '../CurvedEntity/CurvedEntity';
import { getRadiusFromCurvature, getSnapEntityCurvatureOnTarget } from '../CurvedEntity/gizmoUtil';

interface IParentProps {
	scale: ITuple3;
	entityWidth: number;
	showPlayIcon: boolean;
	renderOrder: number;
	curvature: number;
	visible: boolean;
	raycast: ((_: Raycaster, intersects: Intersection<Object3D<Event>>[]) => void) | undefined;
}

const PlayPauseButton: FC<IParentProps> = ({ scale, showPlayIcon, entityWidth, renderOrder, raycast, curvature, visible }) => {
	const [texture, setTexture] = useState<Texture>();

	const svgUrl = showPlayIcon ? play : pause;
	useEffect(() => {
		if (!svgUrl) return;
		const getData = async () => {
			const image = await getSvgCanvas(svgUrl);
			const imageTexture = new TextureLoader().load(image);
			imageTexture.minFilter = imageTexture.magFilter = LinearFilter;
			setTexture(imageTexture);
		};
		getData();
	}, [svgUrl]);

	// The play/pause button needs a different curvature than the parent entity, because it has different dimensions
	const radiusForEntity = getRadiusFromCurvature(curvature, entityWidth);
	let buttonCurvature = getSnapEntityCurvatureOnTarget(radiusForEntity, [entityWidth * 0.2, 1, 1]);
	buttonCurvature = Math.abs(buttonCurvature) * Math.sign(curvature);

	// Adjustments for the play / pause SVG parts
	const svgPos: ITuple3 = showPlayIcon
		? [scale[0]*0.1, -scale[1]*0.15, 0]
		:  [scale[0]*0.05, -scale[1]*0.075, 0];

	return (
		<>
			<CurvedEntity
				name="Play Button ( Background )"
				raycast={raycast}
				key={'video_center_btn_bkgr'}
				scale={scale}
				cornerRadius={0.2}
				renderOrder={renderOrder}
				opacity={0.4}
				colorRgba={[0, 0, 0, 1] as ITuple4}
				curvature={buttonCurvature}
				hasAlpha={true}
				visible={visible}
				side={FrontSide}
			/>
			{texture && (
				<CurvedEntity
					name="Play Button ( SVG )"
					raycast={raycast}
					position={svgPos}
					key={'video_center_btn_play'}
					scale={showPlayIcon ? scale : [scale[0]/2,scale[1]/2,scale[2]/2]}
					renderOrder={renderOrder + 1}
					opacity={1}
					curvature={buttonCurvature}
					imageTexture={texture ? texture : undefined}
					cornerRadius={0}
					hasAlpha={true}
					visible={visible}
					side={FrontSide}
					/>
			)}
		</>
	);
};

export default memo(PlayPauseButton);
