import { ThreeEvent } from '@react-three/fiber';
import React, { memo, useMemo } from 'react';
import { BackSide, BoxGeometry, ColorRepresentation, Vector3 } from 'three';
import { IUserData } from '../../../utils/general';


interface IParentProps {
    color: ColorRepresentation,
    scale: Vector3;
    position?: Vector3;
    wireframe?: boolean;
    renderOrder: number;
    id: string;
    onPointerDown?: (e: ThreeEvent<PointerEvent>) => unknown;
    onPointerUp?: (e: ThreeEvent<PointerEvent>) => unknown;
}

const EmitterArea: React.FunctionComponent<IParentProps> = ({ color, scale: s, position, renderOrder, id, wireframe = false, onPointerDown, onPointerUp }) => {
    const userData: IUserData = useMemo(() => ({ renderOrder, contentId: id }), [id, renderOrder]);
    const scale = s.clone()
    if (scale.x === 0 && scale.y === 0 && scale.z === 0) {
        scale.set(0.5, 0.5, 0.5)
    }

    return (
        <>
            {color ? (
                <group userData={userData} >
                    <mesh 
                        name='emitterAreaMesh'
                        renderOrder={renderOrder - 1}
                        scale={scale} 
                        position={position}
                    > 
                        <boxGeometry />
                        <meshBasicMaterial 
                            color={color} 
                            opacity={wireframe ? 0 :  0.3} 
                            transparent
                            polygonOffset={true}
                            polygonOffsetFactor={1} // positive value pushes polygon further away
                            polygonOffsetUnits={1}
                            side={BackSide}
                        />
                        <lineSegments name='emitterAreaLine' >
                            <edgesGeometry args={[new BoxGeometry()]}/>
                            <lineBasicMaterial color={color}/>
                        </lineSegments>
                    </mesh>
                    <mesh
                        scale={scale} 
                        position={position}  
                        renderOrder={renderOrder}
                        onPointerDown={onPointerDown}
                        onPointerUp={onPointerUp}
                    >
                        <boxGeometry />
                        <meshBasicMaterial visible={false}/>
                    </mesh>
                </group>
            ) : null}
        </>
    );
}
export default memo(EmitterArea);