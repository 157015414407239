import { ThreeEvent } from '@react-three/fiber';
import React, { FunctionComponent, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { IDesignerState, IDomIdSelectors } from '../../../../typings';
import FloatingInput from '../FloatingInput/FloatingInput';
import SelectedEntityPositionHotspot from '../Hotspots/SelectedEntityPositionHotspot';
import { IText, ITextReactProps, ITuple3 } from '../r3f-components/component-data-structure';
import { getUserDataFromIntersections, isHighestRenderOrder } from '../r3f-components/utils/general';
import EntityBoundaries from './EntityBoundaries/EntityBoundaries';
import { useGetHocCurvedProperties, useGetHocSpatialProperties, useGetHocTextProperties, useHocPointerDown, useHocPointerMove, useHocPointerUp } from './hocCustomHooks';
import { getEntityRenderOrder } from './hocUtils';

interface ITextWrapped {
	id: string;
	enabled?: boolean;
}

const withTextBehaviour = (WrappedText: FunctionComponent<ITextReactProps>): FunctionComponent<ITextWrapped> => {
	const FuncComp: FunctionComponent<ITextWrapped> = ({ enabled: entityIsEnabled = true, id }) => {
		// Selectors
		const showFloatingInput = useSelector((state: IDesignerState) => state.userReducer.selectedEntityIds?.length);
		const entity = useSelector((state: IDesignerState) => state.contentReducer.contentDoc.componentsById[id] as IText);
		const projectShadowsEnabled = useSelector((state: IDesignerState) => state.contentReducer.contentDoc.shadowsEnabled ?? true);

		// Refs
		const pointerStageRef = useRef<'down' | 'move' | 'up' | null>(null);
		const shiftLockedRef = useRef<number | null>(null);
		const initialPosRef = useRef<ITuple3 | null>(null); // Used to calculate pointer offset in pointerMove
		const previousPositionRef = useRef<ITuple3 | null>(null); // The previous position, used to compare with the current position
		const pointerUpHasRunRef = useRef<boolean | null>(null); // To prevent multiple dispatched actions when pointerUp runs twice this is reset onPointerMove
		const canvasRef = useRef<HTMLCanvasElement>(document.getElementById(IDomIdSelectors.zapparCanvas) as HTMLCanvasElement);
		const [doubleClicked, setDoubleClicked] = useState(false); // For text / button entities, to display FloatingInput
		const [showEntityPositionHotspot, setShowEntityPositionHotspot] = useState(false);

		// Entity properties to pass to wrapped component
		const renderOrder = getEntityRenderOrder(entity);
		const { opacity, text, fontFamily, fontSize, textAlignment, castShadow = false, receiveShadow = false } = entity;
		const { scale, position, rotation } = useGetHocSpatialProperties(entity);
		const { fontRgba, color } = useGetHocTextProperties(entity);
		const { curvature } = useGetHocCurvedProperties(entity);

		// Pointer events
		const onPointerDown = useHocPointerDown({ pointerStageRef, initialPosRef, entity, setShowEntityPositionHotspot });
		const onPointerMove = useHocPointerMove({ pointerStageRef, initialPosRef, pointerUpHasRunRef, previousPositionRef, entity, shiftLockedRef });
		const onPointerUp = useHocPointerUp({ pointerStageRef, pointerUpHasRunRef, initialPosRef, entity, setShowEntityPositionHotspot, shiftLockedRef });
		const onPointerOver = () => (canvasRef.current.style.cursor = 'text');
		const onPointerOut = () => (canvasRef.current.style.cursor = 'default');
		const onDoubleClick = (e: ThreeEvent<MouseEvent>) => {
			if (!isHighestRenderOrder({userDataArray: getUserDataFromIntersections(e.intersections), renderOrder})) return;
			setDoubleClicked(true);
		};

		const floatingInput =
			showFloatingInput && doubleClicked ? (
				<FloatingInput position={position} text={entity.text} onBlur={() => setDoubleClicked(false)} onUnmount={() => setDoubleClicked(false)} />
			) : undefined;

		return (
			<>
				<EntityBoundaries
					position={position}
					rotation={rotation}
					scale={scale}
					renderOrder={renderOrder}
					onPointerDown={entityIsEnabled ? onPointerDown : undefined}
					onPointerUp={onPointerUp}
					label={`Text component ${id} render order ${renderOrder}`}
					floatingInput={floatingInput}
				>
					<WrappedText
						id={id}
						scale={scale}
						rotation={rotation}
						position={position}
						renderOrder={renderOrder}
						opacity={opacity}
						textAlignment={textAlignment}
						fontRgba={fontRgba}
						fontFamily={fontFamily}
						fontSize={fontSize}
						text={text}
						color={color as any}
						curvature={curvature}
						castShadow={castShadow && projectShadowsEnabled}
						receiveShadow={receiveShadow && projectShadowsEnabled}
						onPointerDown={entityIsEnabled ? onPointerDown : undefined}
						onPointerUp={entityIsEnabled ? onPointerUp : undefined}
						onPointerOver={onPointerOver}
						onPointerOut={onPointerOut}
						onDoubleClick={onDoubleClick}
					/>
				</EntityBoundaries>
				{showEntityPositionHotspot && <SelectedEntityPositionHotspot onPointerMove={showEntityPositionHotspot ? onPointerMove : undefined} onPointerUp={entityIsEnabled ? onPointerUp : undefined} />}
			</>
		);
	};
	return FuncComp;
};

export default withTextBehaviour;
