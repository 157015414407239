
export enum ProjectTool {
  Designer = 'designer-2',
  Mattercraft = 'mattercraft-beta',
  UniversalAR = 'uar',
}

export enum IProjectTemplateCategories {
  Image = 'image',
  World = 'world',
  Face = 'face',
  Content360 = '360'
}

export const PROJECT_TEMPLATE_CATEGORIES = [IProjectTemplateCategories.Image, IProjectTemplateCategories.World, IProjectTemplateCategories.Face, IProjectTemplateCategories.Content360];
