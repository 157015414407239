import React, { FunctionComponent } from 'react';
import { Color } from 'three';
import { ITuple3 } from '../component-data-structure';
import { useThree } from '@react-three/fiber';

interface IParentProps {
	occlusion?: boolean;
	rotation?: ITuple3;
	position?: ITuple3;
	opacity?: number;
}

const ShadowPlane: FunctionComponent<IParentProps> = ({ occlusion = false, rotation, position, opacity = 0.15 }) => {
	return (
		<group name={'shadow-plane-group'} rotation={rotation} position={position}>
			<mesh receiveShadow >
				<planeGeometry args={[1000, 1000]} />
				{/* <meshBasicMaterial color={new Color('red')} /> */}
				<shadowMaterial
					opacity={opacity}
					depthWrite={false}
					polygonOffset
					polygonOffsetFactor={-10} // positive value pushes polygon further away
					polygonOffsetUnits={10}
				/>
			</mesh>
			{occlusion && <mesh>
				<planeGeometry args={[1000, 1000]} />
				<meshBasicMaterial
					colorWrite={false}
					depthWrite={true}
					polygonOffset
					polygonOffsetFactor={20} // positive value pushes polygon further away
					polygonOffsetUnits={20}
				/>
			</mesh>}
		</group>
	);
};

export default ShadowPlane;
