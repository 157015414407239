import { Box3, Vector3, Group } from 'three';
import { IVector3 } from '../component-data-structure';

//Adjust position and normalize scale of model
export const normalizeScaleAndCenterModel = (
	innerGroup: Group,
	originalBBox: IVector3 | undefined,
	modelIsNormalizedRef: React.MutableRefObject<boolean>,
	sceneWithClonedMaterial: THREE.Object3D,
	centerModelToBBox: boolean
) => {
	if (modelIsNormalizedRef.current) return;
	modelIsNormalizedRef.current = true;

	const sceneBBoxScale = originalBBox ? new Vector3(...originalBBox) : new Box3().setFromObject(sceneWithClonedMaterial).getSize(new Vector3());

	innerGroup.scale.set(...new Vector3(1, 1, 1).divide(sceneBBoxScale).toArray());

	if (!centerModelToBBox) return;

	const center = new Box3().setFromObject(innerGroup).getCenter(new Vector3());

	innerGroup.position.x += innerGroup.position.x - center.x;
	innerGroup.position.y += innerGroup.position.y - center.y;
	innerGroup.position.z += innerGroup.position.z - center.z;
};
