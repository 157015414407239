import { Text } from '@react-three/drei';
import { ThreeEvent } from '@react-three/fiber';
import React, { FunctionComponent, useRef, memo, useCallback, useLayoutEffect, Suspense } from 'react';
import { Material, Mesh, DoubleSide, Intersection, Object3D, Raycaster } from 'three';
import { IFontTypes, ITextAlignment, ITuple3, ITuple4 } from '../component-data-structure';

export type IOnTextSync = (visibleBounds: ITuple4) => unknown;
export interface ITextParentProps {
	children: string;
	curveRadius?: number;
	name?: string;
	color?: string | THREE.Color;
	visible?: boolean;
	fontSize?: number;
	fontFamily?: IFontTypes;
	lineHeight?: number;
	letterSpacing?: number;
	textAlign?: ITextAlignment;
	anchorX?: 'left' | 'right' | 'center';
	anchorY?: 'middle' | 'top' | 'top-baseline' | 'bottom-baseline' | 'bottom';
	url?: string;
	maxWidth?: number;
	onTextSync?: IOnTextSync;
	alpha: number;
	position: ITuple3;
	scale?: ITuple3;
	depthWrite?: boolean;
	curvature?: number;
	renderOrder?: number;
	onPointerOver?: (e: ThreeEvent<PointerEvent>) => unknown;
	onPointerOut?: (e: ThreeEvent<PointerEvent>) => unknown;
	side?: THREE.Side;
	raycast?: (_: Raycaster, intersects: Intersection<Object3D<Event>>[]) => void;
	preventPolygonOffset?: boolean;
}

const TroikaText: FunctionComponent<ITextParentProps> = ({
	children,
	color,
	fontSize,
	lineHeight,
	letterSpacing,
	textAlign,
	url: fontUrl,
	anchorX,
	anchorY,
	fontFamily,
	visible = true,
	curvature = 0,
	alpha,
	position,
	maxWidth,
	depthWrite,
	renderOrder,
	onPointerOver,
	onPointerOut,
	side = DoubleSide,
	curveRadius = 0,
	preventPolygonOffset = false,
	raycast,
	onTextSync
}) => {
	const meshRef = useRef<Mesh & { textRenderInfo: {blockBounds: ITuple4} }>();
	useLayoutEffect(() => {
		if (!meshRef.current) return;
		const material = meshRef.current.material;
		(material as Material).depthWrite = false;
		(material as Material).side = side;
	}, [meshRef, side]);

	const _onSync = useCallback(() => {
		if (!meshRef?.current?.textRenderInfo || !onTextSync) return;
		//console.log('text render info from mesh', meshRef.current.textRenderInfo);
		const { blockBounds } = meshRef.current.textRenderInfo; 
		onTextSync?.(blockBounds);
	}, [onTextSync]);

	if (fontSize === 0) return null;
	return (
		<group>
			<Suspense fallback={null}>
				<Text
					raycast={raycast}
					ref={meshRef}
					color={color}
					position={position}
					fontSize={fontSize}
					lineHeight={lineHeight}
					letterSpacing={letterSpacing}
					textAlign={textAlign}
					font={fontUrl}
					maxWidth={maxWidth}
					anchorX={anchorX}
					// @ts-expect-error drei text types don't include % though they do work
					anchorY={fontFamily?.toLowerCase().includes('roboto') ? '42%' : anchorY}
					material-opacity={alpha}
					material-transparent={true}
					material-depthWrite={depthWrite}
					renderOrder={(renderOrder || 0) + 2}
					onPointerOver={onPointerOver}
					onPointerOut={onPointerOut}
					onSync={_onSync}
					visible={visible}
					// eslint-disable-next-line @typescript-eslint/ban-ts-comment
					// @ts-ignore
					curveRadius={curveRadius}
					depthOffset={curvature !== 0 && !preventPolygonOffset ? -750 : 0}
				>
					{children}
				</Text>
			</Suspense>
		</group>
	);
};

TroikaText.displayName = 'Text';
export default memo(TroikaText);
