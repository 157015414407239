import { ThreeEvent } from '@react-three/fiber';
import React, { FunctionComponent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IDesignerState } from '../../../../typings';
import { onSetMarkerIndexPressed, onSetScaleHotSpotEnabled } from '../../../store/actions';
import { INITIAL_ZOOM_LEVEL, SELECTION_MARKER_HEIGHT, SELECTION_MARKER_WIDTH } from '../../../utils';
import { ITuple3 } from '../../r3f/r3f-components/component-data-structure';
import LineBox from '../LineBox/LineBox';
import Plane from '../Plane/Plane';

interface IParentProps {
	position: ITuple3;
	id: number;
	onPointerDown?: (e: ThreeEvent<PointerEvent>) => unknown;
	onPointerUp?: (e: ThreeEvent<PointerEvent>) => unknown;
	onPointerOver?: (e: ThreeEvent<PointerEvent>) => unknown;
	onPointerOut?: (e: ThreeEvent<PointerEvent>) => unknown;
}

const Marker: FunctionComponent<IParentProps> = ({ id, position: [x, y], onPointerDown, onPointerUp, onPointerOver, onPointerOut }) => {
	const dispatch = useDispatch();
	const zoomAdjFactor = useSelector((state: IDesignerState) => (INITIAL_ZOOM_LEVEL / state.userReducer.zoomLevel) * 0.25);
	const backgroundHotspotIsEnabled = useSelector((state: IDesignerState) => state.userReducer.backgroundHotspotIsEnabled);
	const isScreenRelativeMode = useSelector((state: IDesignerState) => state.userReducer.isScreenRelativeMode);
	const scale = (isScreenRelativeMode ? [0.018, 0.018, 0.01] : [SELECTION_MARKER_HEIGHT * zoomAdjFactor, SELECTION_MARKER_WIDTH * zoomAdjFactor, 0.025 * zoomAdjFactor]) as ITuple3;

	const pointerDownHandler = (e: ThreeEvent<PointerEvent>) => {
		onPointerDown?.(e);
		e.stopPropagation();
		dispatch(onSetMarkerIndexPressed(id));
		dispatch(onSetScaleHotSpotEnabled(true));
	};

	const pointerUpHandler = (e: ThreeEvent<PointerEvent>) => {
		onPointerUp?.(e);
		e.stopPropagation();
		dispatch(onSetMarkerIndexPressed(null));
	};

	return (
		<>
			<LineBox
				enabled={!backgroundHotspotIsEnabled}
				color={[0, 176, 255, 1]}
				rotation={[0, 0, 0]}
				position={[x, y, 0.005]}
				scale={scale}
				// name={"MarkerLineBox"}
				depthWrite={false}
				renderOrder={999995}
			/>
			<Plane
				scale={scale}
				name={'MarkerPlane'}
				id={`ScaleMarker${id}`}
				isMarkerPlane={true}
				enabled={true}
				position={[x, y, 0]}
				depthWrite={false}
				rotation={[0, 0, 0]}
				color={[255, 255, 255, 1]}
				pointerDownHandler={(e) => {
					pointerDownHandler(e);
				}}
				pointerUpHandler={(e) => {
					pointerUpHandler(e);
				}}
				pointerOverHandler={onPointerOver || undefined}
				pointerOutHandler={onPointerOut || undefined}
				renderOrder={999999}
			/>
		</>
	);
};

export default Marker;
