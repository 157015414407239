export enum ITargetImageReferenceObject {
	wineBottle = 'wineBottle',
	sodaCan330ml = 'sodaCan330ml',
	sodaCan500ml = 'sodaCan500ml',
	sodaBottle2l = 'sodaBottle2l',
	none = 'none'
}

export interface ITargetImageReferenceObjectData {
	referenceObject: ITargetImageReferenceObject;
	position: number;
}