import React from "react";
import { IIconParentProps } from "./index";
import { COLORS } from "../../utils";

const padlockOpenIcon: React.SFC<IIconParentProps> = ({
  hexFill: fill,
  viewBox,
  className,
  width,
  height,
  onClick = () => {},
  title,
}) => {
  const hexFill = fill || COLORS.midnight;
  return (
    <svg
      onClick={onClick}
      className={className || ""}
      width={width ? width : "14px"}
      height={height ? height : "18px"}
      viewBox={viewBox ? viewBox.join(" ") : `0 0 14 18`}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>{title}</title>
      <g
        id="Expanded"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Unlocked-2"
          transform="translate(-13.000000, -11.000000)"
          fill={hexFill}
          fillRule="nonzero"
        >
          <path d="M19.7408576,11.006258 C22.4033188,10.873866 24.6989524,12.8573325 24.9516892,15.5085052 L24.9516892,15.5085052 L23.9500975,15.5085052 C23.6777451,13.4268948 21.8378272,11.9118751 19.7405272,12.0422734 C17.6432271,12.1726717 16.0055357,13.9039096 15.9936333,16.0031934 L15.9936333,16.0031934 L15.9936333,18.8813789 L25.1992736,18.8813789 C26.2160512,18.9412054 27.0076413,19.7863958 26.9999443,20.8039169 L26.9999443,20.8039169 L26.9999443,27.201134 C26.9999443,27.6782229 26.8101808,28.1357715 26.4725002,28.4731244 C26.1348195,28.8104772 25.6768262,29 25.1992736,29 L25.1992736,29 L14.8007264,29 C13.8062746,29 13.0000557,28.1946203 13.0000557,27.201134 L13.0000557,27.201134 L13.0000557,20.8039169 C12.9923587,19.7863958 13.7839488,18.9412054 14.8007264,18.8813789 L14.8007264,18.8813789 L15.0032955,18.8813789 L15.0032955,16.0031934 C14.9912618,13.3400515 17.0783963,11.1386501 19.7408576,11.006258 Z M25.1092429,20.0056702 L14.9132648,20.0056702 C14.4719768,20.0056702 14.1142422,20.3630574 14.1142422,20.8039169 L14.1142422,20.8039169 L14.1142422,27.201134 C14.1142422,27.6419936 14.4719768,27.9993808 14.9132648,27.9993808 L14.9132648,27.9993808 L25.1092429,27.9993808 C25.5505309,27.9993808 25.9082655,27.6419936 25.9082655,27.201134 L25.9082655,27.201134 L25.9082655,20.8039169 C25.9082655,20.3630574 25.5505309,20.0056702 25.1092429,20.0056702 L25.1092429,20.0056702 Z M20,23.0075277 C20.131327,23.0075277 20.2572751,23.0596465 20.3501373,23.1524185 C20.4429995,23.2451906 20.4951689,23.3710164 20.4951689,23.5022159 L20.4951689,25.5034543 C20.4951689,25.6801893 20.4007903,25.8434993 20.2475845,25.9318668 C20.0943787,26.0202343 19.9056213,26.0202343 19.7524155,25.9318668 C19.5992097,25.8434993 19.5048311,25.6801893 19.5048311,25.5034543 L19.5048311,23.5022159 C19.5048311,23.2290072 19.7265258,23.0075277 20,23.0075277 Z"></path>
        </g>
      </g>
    </svg>
  );
};

export default padlockOpenIcon;
