import { useThree } from '@react-three/fiber';
import React, { FunctionComponent, memo, useCallback, useEffect, useMemo, useRef } from 'react';
import * as THREE from 'three';
import { BufferAttribute, Group, Intersection, SkinnedMesh } from 'three';
import { ITuple3, IModel3dReactProps, IVector3 } from '../component-data-structure';
import { useApplyRenderOrderToModel } from '../hooks/useApplyRenderOrderToModel';
import { useCloneGLTFMaterials } from '../hooks/useCloneGLTFMaterials';
import { useGLTFLoader } from '../hooks/useGLTFLoader';
import usePlayModelAnimations from '../hooks/usePlayModelAnimations';
import { IUserData } from '../utils/general';
import { getPointerObjectIntersections, updateGeomWithSkinnedMeshMorphAttr } from '../utils/geometry-utils';
import { normalizeScaleAndCenterModel } from '../utils/normalizeAndCenterModel';


const Model3d: FunctionComponent<IModel3dReactProps> = ({
	model3dUrl = '',
	scale: s,
	position: p,
	rotation: r,
	animationName = '',
	isPlayingAnimation = false,
	animationRepetitions: animationRep,
	animationRunCounter: runCounter,
	onPointerUp,
	onPointerDown,
	onPointerMove,
	onDoubleClick,
	onAnimationEnd,
	activePointerEvent,
	renderOrder = 0,
	centerModelToBBox = true,
	originalBBox,
	id = '',
	raycast,
	idleAnimation,
	idlePoseTime,
	isClampWhenFinished,
	castShadow,
	receiveShadow,
	animationTransformGroupPrefix = ''
}) => {
	const animationRepetitions = animationRep ?? Infinity;
	const outerGroupRef = useRef<THREE.Group>(null);
	const scaleGroupRef = useRef<THREE.Group>(null);
	const modelSceneRef = useRef<any>(null);
	const scale = useMemo(() => s as ITuple3, [s]);
	const position = useMemo(() => p as ITuple3, [p]);
	const rotation = useMemo(() => r as ITuple3, [r]);
	const intersectionsRef = useRef<Intersection[] | null>(null);
	const userData: IUserData = useMemo(() => ({ renderOrder, contentId: id }), [id, renderOrder]);
	const { camera, raycaster } = useThree();
	const modelIsNormalizedRef = useRef(false)

	useEffect(() => {
		modelIsNormalizedRef.current = false;
	},[scale])

	// custom loader that converts skinned meshes into meshes (and adds a render order to meshes with transparent materials)
	const { scene, animations, skinnedMeshUuidToMesh } = useGLTFLoader(model3dUrl, {castShadow, receiveShadow});

	// Add render order to meshes with transparent materials
	useApplyRenderOrderToModel(scene, renderOrder);

	// clone materials in model so that changes to materials don't affect other models from same cache
	const sceneWithClonedMaterial = useCloneGLTFMaterials(scene);

	const meshUuids = useMemo(() => {
		return Object.keys(skinnedMeshUuidToMesh).map((skinnedUuid) => {
			return skinnedMeshUuidToMesh[skinnedUuid].uuid;
		});
	}, [skinnedMeshUuidToMesh]);

	// Function updates mesh geometries generated to enable click detection
	// with the position attributes of corresponding skinned meshes
	const updateMeshesWithSkinnedMeshes = useCallback(() => {
		for (const skinnedMeshUuid in skinnedMeshUuidToMesh) {
			const mesh = skinnedMeshUuidToMesh[skinnedMeshUuid];
			const skinnedMesh = sceneWithClonedMaterial.getObjectByProperty('uuid', skinnedMeshUuid) as SkinnedMesh;

			// update mesh with updated skinnedMesh morphed attributes
			if (!skinnedMesh) continue;
			updateGeomWithSkinnedMeshMorphAttr(mesh.geometry, skinnedMesh, true);
		}
	}, [sceneWithClonedMaterial, skinnedMeshUuidToMesh]);

	const _onAnimationEnd = () => {
		onAnimationEnd?.();
		updateMeshesWithSkinnedMeshes();
	};

	const animationPlayingRef = usePlayModelAnimations({
		isPlayingAnimation,
		animationName,
		animations,
		repetitions: animationRepetitions,
		modelScene: sceneWithClonedMaterial,
		runCounter,
		onAnimationEnd: _onAnimationEnd,
		idleAnimation,
		idlePoseTime,
		isClampWhenFinished
	});

	useEffect(() => {
		if (!scaleGroupRef.current || !activePointerEvent) return;
		if (!activePointerEvent?.target) return;
		if (!animationPlayingRef.current) return;
		switch (activePointerEvent.type) {
			case 'pointerdown': {
				// update mesh geometry
				updateMeshesWithSkinnedMeshes();
				//  raycast to get intersections
				const intersections = getPointerObjectIntersections(activePointerEvent, scaleGroupRef.current, raycaster, camera);
				if (!intersections) break;
				const meshIntersectionFound = intersections.some(({ object }) => meshUuids.includes(object.uuid));
				if (meshIntersectionFound) {
					intersectionsRef.current = intersections;
					(activePointerEvent as any).intersections = intersections;
					onPointerDown?.(activePointerEvent as any);
				}
				break;
			}
			case 'pointerup': {
				if (intersectionsRef.current) {
					(activePointerEvent as any).intersections = intersectionsRef.current;
					onPointerUp?.(activePointerEvent as any);
					intersectionsRef.current = null;
					// adjust each vertex position to 0
					for (const skinnedMeshUuid in skinnedMeshUuidToMesh) {
						const mesh = skinnedMeshUuidToMesh[skinnedMeshUuid];
						const newZeroPositionArray = new Float32Array(1).fill(0);
						mesh.geometry.setAttribute('position', new BufferAttribute(newZeroPositionArray, 3));
						mesh.geometry.attributes.position.needsUpdate = true;
					}
				}
				break;
			}

			default:
				break;
		}
	}, [activePointerEvent, scaleGroupRef, meshUuids, camera, raycaster, skinnedMeshUuidToMesh, updateMeshesWithSkinnedMeshes]);
	
	return (
		<group
			raycast={raycast}
			key={`outer_group-${sceneWithClonedMaterial.uuid}`}
			name={`${animationTransformGroupPrefix}${id}`}
			userData={userData}
			position={position}
			rotation={rotation}
			ref={outerGroupRef}
			onPointerUp={!activePointerEvent && onPointerUp ? onPointerUp : undefined}
			onPointerDown={!activePointerEvent && onPointerDown ? onPointerDown : undefined}
			onPointerMove={onPointerMove || undefined}
			onDoubleClick={onDoubleClick || undefined}
		>	
			<group name={`${animationTransformGroupPrefix}inner_${id}`}>
				<group ref={scaleGroupRef} scale={scale} >
					<primitive 
						name={`${id}-primitive`}
						onUpdate={(self: THREE.Group) => {
							normalizeScaleAndCenterModel(
								self, 
								originalBBox, 
								modelIsNormalizedRef,
								sceneWithClonedMaterial,
								centerModelToBBox
							)
						}} 
						raycast={raycast} 
						key={sceneWithClonedMaterial.uuid} 
						ref={modelSceneRef} 
						object={sceneWithClonedMaterial} 
					/>
				</group>
			</group>
		</group>
	);
};

Model3d.displayName = 'Model3d';
export default memo(Model3d);
